import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import technicianApi from '../../api/techAPi';
import AddDetails from '../../components/AddUser/AddDetails';
import HandleLoad from '../../components/Loader/HandleLoad';
import ModalForm from '../../components/ModalForm';
import OverlayContainer from '../../components/OverlayContainer';
import DualPasword from '../../components/Password/DualPassword';
import { toast } from '../../components/Toast/CustomToast';
import { UserDetailsModel } from '../../models/UserDetailsModel';
import { editfieldDetails, editschema, schema } from '../common/Schemas';

const AddTechnician = (props: UserDetailsModel) => {
  const handleLoader = HandleLoad();
  const { edit } = props;
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<any>({
    mode: 'all',
    resolver: yupResolver(edit ? editschema : schema),
  });

  useEffect(() => {
    if (edit) {
      setValue('Name*', edit?.tech_name || edit?.technician_name);
      setValue('Email ID*', edit.technician_email);
      setValue('Phone Number*', edit.technician_phone);
    } else {
      setValue('Name*', '');
      setValue('Email ID*', '');
      setValue('Password*', '');
      setValue('Confirm Password*', '');
    }
  }, [edit]);

  const handleClose = () => {
    if (props?.handleAdd) {
      if (props?.isTech) {
        props.handleAdd({ type: 'closeTech' });
      } else {
        props.handleAdd({ type: 'close' });
      }
    }
    props?.handleClose?.();
  };

  const addTechnician = useMutation('addTechnician', technicianApi.add, {
    onSuccess: (response: any) => {
      if (response.status >= 200 && response.status <= 210) {
        props?.handleAdd && props?.handleAdd({ type: 'add', el: response.data.data.account_id });
        props?.handleClose?.();
        props?.refetch?.();
        toast.success('Technician Added Successfully!');
      } else {
        toast.error(response?.response?.data?.message);
      }
      handleLoader(false);
    },
    onError: (error: Error) => {
      toast.error('Something went wrong');
    },
  });

  const SubmitHandler = () => {
    const formData = getValues();
    const payload = {
      name: formData['Name*']?.trim(),
      phone: formData['Phone Number*'],
      email: formData['Email ID*'],
    };
    if (edit) {
      props.handleAdd?.({ type: 'update', el: payload });
    } else {
      handleLoader(true);
      addTechnician.mutate({ info: { ...payload, password: formData['Password*'] } });
    }
  };

  const fieldDetails = [
    ...editfieldDetails,
    {
      boxType: 'Password',
      box: (
        <DualPasword
          boxSx={'gap-y-4'}
          control={control}
          errors={errors}
          boxes={[
            {
              label: 'Password*',
              type: 'password',
            },
            {
              label: 'Confirm Password*',
              type: 'password',
            },
          ]}
        />
      ),
    },
  ];

  return (
    <OverlayContainer show={true} z='z-50'>
      <ModalForm title={'Add '} onClose={handleClose} noLabel width={'w-[25rem]'}>
        <div className='flex flex-row w-full h-full gap-10'>
          <AddDetails
            control={control}
            errors={errors}
            lable={`${edit ? 'Edit' : 'Add'} Technician`}
            handleSubmit={handleSubmit(SubmitHandler)}
            edit={edit}
            fieldDetails={edit ? editfieldDetails : fieldDetails}
          />
        </div>
      </ModalForm>
    </OverlayContainer>
  );
};

export default AddTechnician;
