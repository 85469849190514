import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import ServiceStarted from '../../../../../assets/ServiceStarted';
import UploadWorkProof from './UploadWorkProof';
import CompletedButton from './CompletedButton';
import { StepIconProps } from '@mui/material/StepIcon';
import moment from 'moment';
import { usePmLogs } from '../../../../common/requestDetail/MaintainanceLogsModel';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.root}`]: {
    marginLeft: '0.5rem',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#4378C8',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#4378C8',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderLeftWidth: '0.2rem',
    minHeight: '2rem',
    marginLeft: '-0.2rem',
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: '0.8rem',
  height: '0.8rem',
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: '#4378C8',
  }),
  ...(ownerState.completed && {
    backgroundColor: '#4378C8',
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}></ColorlibStepIconRoot>
  );
}

export default function VerticalLinearStepper({ item }: { item: any }) {
  const [progress, setProgress] = React.useState([] as any);
  const { logs, getPmLogs } = usePmLogs();
  React.useEffect(() => {
    const progressArr = [];
    const sortedArr = logs?.preventive_request_id
      ?.slice()
      ?.sort((a: any, b: any) => moment(b?.created_at).valueOf() - moment(a?.created_at).valueOf());
    const getActionTime = (actionType: string[]) => {
      return sortedArr?.find((el: any) => actionType.includes(el?.log_message?.action))?.created_at;
    };

    if (logs) {
      progressArr.push({
        label: 'To-Do',
        logo: (
          <i
            className={`pi pi-exclamation-circle ${
              +item?.status_id >= 5 ? 'text-primary' : 'text-slate-200'
            }`}
            style={{ fontSize: '1rem' }}></i>
        ),
        time:
          +item?.status_id >= 5
            ? getActionTime(['re-assigned technician', 'technician assigned'])
            : null,
      });
      progressArr.push({
        label: 'Service Started',
        logo: <ServiceStarted active={+item?.status_id >= 6} />,
        time: +item?.status_id >= 6 ? getActionTime(['service started']) : null,
      });
      progressArr.push({
        label: 'Upload Work Proof',
        logo: <UploadWorkProof active={+item?.status_id >= 8} />,
        time:
          +item?.status_id >= 8
            ? getActionTime([
                'work proof submitted',
                'work proof submitted by csr',
                'work proof submitted by vendor',
              ])
            : null,
      });
      progressArr.push({
        label: 'Approved / Rejected',
        logo: (
          <i
            className={`pi pi-times-circle ${
              +item?.status_id >= 9 ? 'text-primary' : 'text-[#C0C0C0]'
            }`}
            style={{ fontSize: '1rem' }}></i>
        ),
        time:
          +item?.status_id >= 9
            ? getActionTime(['work proof accepted', 'work proof rejected'])
            : null,
      });

      progressArr.push({
        label: 'Completed',
        logo: (
          <CompletedButton
            active={['sent', 'amount_submitted', 'amount_submitted_by_csr', 'updated'].includes(
              item?.invoice?.invoice_status
            )}
          />
        ),
        time:
          +item?.status_id === 12
            ? getActionTime(['payment done', 'invoice sent to vendor'])
            : null,
      });

      setProgress(progressArr);
    }
  }, [logs, item]);

  React.useEffect(() => {
    getPmLogs(item);
  }, []);

  const getActiveStep = () => {
    const { status_id } = item;
    if (+status_id === 5) {
      return 0;
    } else if (+status_id === 6) {
      return 1;
    } else if (+status_id === 7 || +status_id === 8) {
      return 2;
    } else if (+status_id === 9) {
      return 3;
    } else if (+status_id === 10) {
      return 3;
    } else if (+status_id === 11) {
      return 3;
    } else if (+status_id === 12) {
      return 4;
    } else {
      return 0;
    }
  };

  return (
    <div className='p-2 extraCSS'>
      <Stepper activeStep={getActiveStep()} connector={<QontoConnector />} orientation='vertical'>
        {progress.map(
          (
            step: {
              label: string;
              logo: JSX.Element;
              time: string;
            },
            index: number
          ) => (
            <Step
              key={step.label}
              sx={{
                '& .MuiStepLabel-root': {
                  padding: 0,
                },
              }}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <div className='flex justify-between items-center'>
                  <div className='flex items-center gap-2 ml-3'>
                    {step.logo}
                    <div
                      className={`font-bold p-1 text-[0.9rem] ${
                        getActiveStep() >= index ? 'text-primary' : 'text-[#C0C0C0]'
                      }`}>
                      {step.label}
                    </div>
                  </div>
                  {step?.time && (
                    <div className='mx-2 text-[0.7rem] font-bold'>
                      {moment(step?.time).format('lll')}
                    </div>
                  )}
                </div>
              </StepLabel>
            </Step>
          )
        )}
      </Stepper>
    </div>
  );
}
