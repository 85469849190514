import { useSelector } from 'react-redux';
import { RootState } from '../../../state/reducers';
import CSRVehicleInfo from './csr/Vehicle';
import VendorVehicleInfo from './vendor/Vehicle';

const VechileInfo = ({ item, active, tab, setSelectedVehicle }: any) => {
  const { user }: any = useSelector((state: RootState) => state.userState);
  return user.role === 'fleetmax_csr' ? (
    <CSRVehicleInfo item={item} active={active} tab={tab} setSelectedVehicle={setSelectedVehicle} />
  ) : (
    <VendorVehicleInfo item={item} active={active} setSelectedVehicle={setSelectedVehicle} />
  );
};

export default VechileInfo;
