import { useState, useCallback, useEffect } from 'react';
import { toast } from '../../../../../components/Toast/CustomToast';
import VendorAPI from '../../../../../api/vendorApi';
import { getQueryParamsRevenue } from '../../../../vender/utils';
import { useSearchParams } from 'react-router-dom';
import { RevenueHistory } from '../../types/types';
import { RootState } from '../../../../../state/reducers';
import { useSelector } from 'react-redux';

const useFetchRevenue = () => {
  const [loader, setLoader] = useState(false);
  const [offsetLoader, setOffsetLoader] = useState(false);
  const [urlParams, setSearchParams] = useSearchParams();
  const [totalItems, setTotalItems] = useState(0);
  const [revenueHistoryData, setRevenueHistoryData] = useState<any>([]);
  const [requests, setRequests] = useState<any[]>([]);
  const [timeoutActive, setTimeoutActive] = useState(false);
  const { user }: any = useSelector((state: RootState) => state.userState);
  const reset = useCallback(() => {
    urlParams.set('page_number', '1');
    setSearchParams(urlParams);
  }, [urlParams, requests]);
  useEffect(() => {
    urlParams.set('page_number', '1');
    setSearchParams(urlParams);
  }, []);
  const pageNum = urlParams?.get('page_number');
  const fetchAllRevenueData = async (reset?: any) => {
    if (reset) {
      urlParams?.set('page_number', '1');
      setSearchParams(urlParams);
      setRequests([]);
    }
    if (Number(pageNum) > 1) {
      setOffsetLoader(true);
    } else {
      setLoader(true);
    }
    const vendorParam: string | null = urlParams?.get('vendor_ids') || null;
    const vendor_id_array = vendorParam ? JSON?.parse(vendorParam) : [];
    const partnerAsVendor: string =
      +vendor_id_array?.[0] === +user?.account_id && user?.role === 'fleetmax_partner'
        ? 'partner_as_vendor=true'
        : '';
    const query: string = `${String(getQueryParamsRevenue(urlParams))}${partnerAsVendor}`;
    try {
      const res: { status: number; data: { data: RevenueHistory } } =
        await VendorAPI.getAllRevenueData({
          params: query,
        });
      if (res.status === 200) {
        setRevenueHistoryData(res.data.data);
        if (Number(pageNum) > 1) {
          if (reset) {
            setRequests([...res.data.data?.requests]);
          } else {
            setRequests([...requests, ...res.data.data?.requests]);
          }
        } else {
          setRequests([...res.data.data?.requests]);
        }
        setTotalItems(res?.data?.data?.total_records || 0);
      } else {
        toast.error('Failed to fetch revenue data');
      }
    } catch (error) {
      toast.error('Error fetching revenue data');
    }
    setLoader(false);
    setOffsetLoader(false);
  };
  return {
    loader,
    revenueHistoryData,
    fetchAllRevenueData,
    totalItems,
    setTotalItems,
    requests,
    reset,
    offsetLoader,
    timeoutActive,
    setTimeoutActive,
    setOffsetLoader,
    setRequests,
  };
};

export default useFetchRevenue;
