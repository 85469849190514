import { bindActionCreators } from 'redux';
import HandleLoad from '../../components/Loader/HandleLoad';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators } from '../../state';
import VendorAPI from '../../api/vendorApi';
import { useEffect, useState } from 'react';
import { RootState } from '../../state/reducers';

const useFetchTechnician = () => {
  const loader = HandleLoad();
  const dispatch = useDispatch();
  const { setVendorTechnicianData } = bindActionCreators(actionCreators, dispatch);
  const { technicianData, user }: any = useSelector((state: RootState) => state.userState);
  const [technicians, setTechnicians] = useState<any[]>([]);
  const fetchTechnicians = (item?: any) => {
    loader(true);
    if (user?.role === 'fleetmax_partner' && item?.vendor?.id) {
      VendorAPI.getVendorTechnicianbyCSR({ id: item?.vendor?.id }).then((res: any) => {
        if (res.status === 200) {
          setTechnicians(res.data.data.technicians_details);
          setVendorTechnicianData({ technicianData: res.data.data.technicians_details });
        } else {
          setTechnicians([]);
        }
        loader(false);
      });
    } else {
      VendorAPI.getVendorsTechnician().then((res: any) => {
        if (res.status === 200) {
          setTechnicians(res.data.data.technicians_details);
          setVendorTechnicianData({ technicianData: res.data.data.technicians_details });
        } else {
          setTechnicians([]);
        }
        loader(false);
      });
    }
  };

  const handleSearch = (e: { target: { value: string } }) => {
    const target = e?.target?.value?.toLowerCase()?.trim();
    if (target === '') {
      setTechnicians(technicianData);
    } else {
      setTechnicians(
        technicianData.filter((item: any) =>
          item?.technician_name?.toLowerCase()?.trim()?.includes(target)
        )
      );
    }
  };

  useEffect(() => {
    if (user?.role !== 'fleetmax_partner') {
      fetchTechnicians();
    }
  }, []);

  return { technicians, setTechnicians, handleSearch, fetchTechnicians };
};

export default useFetchTechnician;
