import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state';

function HandleLoad() {
  const dispatch = useDispatch();
  const { handleLoader } = bindActionCreators(actionCreators, dispatch);
  return handleLoader;
}

export default HandleLoad;
