import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ModalForm from '../../../../components/ModalForm';
import OverlayContainer from '../../../../components/OverlayContainer';
import { RootState } from '../../../../state/reducers';
import useFetchInvoiceDetails from '../../../csr/primaryComponents/Invoice/components/useFetchInvoiceDetails';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state';
import { DisputeLists } from '../../../csr/primaryComponents/DisputeLists';
import InvoiceTemplateVendorNew from '../../../csr/primaryComponents/Invoice/invoiceTemplate/InvoiceTemplateVendor';
import axios from 'axios';
import { Environment } from '../../../../constants/environment';
import DownloadInvoiceButton from '../../../../assets/DownloadInvoiceButton';
import { EditableServices } from './components/EditableServices';
import { toast } from '../../../../components/Toast/CustomToast';

const Invoice = ({ handleClose, item, fetchRequestData }: any) => {
  const dispatch = useDispatch();
  const baseUrl = Environment.baseUrl;
  const { setDispute, setInvoicesLoader } = bindActionCreators(actionCreators, dispatch);
  const { dispute, user, invoiceLoader }: any = useSelector((state: RootState) => state.userState);
  const { invoiceDetails, disputeHistory, fetchInvoiceDetails, fetchDisputeHistory } =
    useFetchInvoiceDetails();
  const isApprovedPage =
    (+item?.status_id === 12 && !item?.sub_stage) || [9, 11]?.includes(+item?.status_id);
  const isDisputePage =
    +item?.status_id === 11 && ['raised', 'declined']?.includes(item?.vendor_invoice_dispute);
  const showEditFeild =
    (isDisputePage || item?.invoice?.invoice_status !== 'amount_submitted') &&
    (isApprovedPage || isDisputePage) &&
    user.account_id !== item?.invoice?.invoice_created_by;
  const downloadReport = () => {
    if (!invoiceLoader) {
      setInvoicesLoader(true);
      toast.info('Downloading PDF!');
      axios
        .get(`${baseUrl}csr/v1/download_invoice?ids_arr=${JSON.stringify([item?.invoice?.id])}`, {
          responseType: 'arraybuffer',
          headers: {
            'x-access-token': user?.token || '',
            'x-access-user': user?.account_id || '',
            'app-type': '9',
          },
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
          var link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Invoice.pdf`);
          document.body.appendChild(link);
          link.click();
          setInvoicesLoader(false);
        });
    } else {
      toast.error('Please Wait until the previous is downloading');
    }
  };
  useEffect(() => {
    fetchInvoiceDetails(item?.invoice?.id);
    fetchDisputeHistory(item);
  }, [item?.preventive_request_id]);
  return (
    <OverlayContainer show={true}>
      <ModalForm
        title={'Add CSR'}
        onClose={() => {
          handleClose();
          setDispute({
            ...dispute,
            open: false,
          });
        }}
        width={'w-[80vw]'}
        noLabel>
        <div className={`flex flex-col gap-4 ${!showEditFeild ? ' h-[41rem]' : ' h-[45rem]'}`}>
          <div>
            <p className='text-primary font-semibold text-sm'>{item.title}</p>
          </div>
          {showEditFeild ? (
            <div className='flex justify-between gap-2 w-full h-full '>
              <div className='h-[100%] w-[57%] flex flex-col'>
                {dispute?.open ? (
                  <div className='w-full flex'>
                    <DisputeLists item={item} />
                  </div>
                ) : (
                  <InvoiceTemplateVendorNew
                    invoiceDetails={invoiceDetails}
                    item={item}
                    height='40rem'
                  />
                )}
              </div>
              <div className='flex flex-col items-center gap-3 mt-1 w-[43%] h-[90%] mx-3'>
                {showEditFeild && (
                  <EditableServices
                    item={item}
                    handleClose={handleClose}
                    fetchRequestData={() => {
                      fetchRequestData?.();
                      fetchInvoiceDetails?.(item?.invoice?.id);
                      fetchDisputeHistory?.(item);
                    }}
                    invoiceDetails={{ ...invoiceDetails, disputeHistory: disputeHistory }}
                  />
                )}
                {!showEditFeild && item?.final_payment && (
                  <div className='flex  justify-between items-center'>
                    <div className='p-2 px-4 bg-stone-100 rounded-lg font-bold'>
                      <p className='text-mg'>$ {item?.final_payment}</p>
                    </div>
                    <div className='p-2 px-4 bg-stone-100 rounded-lg font-bold'>
                      <p className='text-mg'>$ {item?.final_payment}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <>
              <div className='h-full w-full '>
                <InvoiceTemplateVendorNew
                  invoiceDetails={invoiceDetails}
                  item={item}
                  height='35rem'
                />
              </div>
              <div className='flex flex-col justify-center items-center gap-3 mt-1 w-full h-[10%] mx-3'>
                {!showEditFeild && item?.final_payment && (
                  <div className='flex gap-2  items-center'>
                    <div className='p-2 px-4 bg-stone-100 rounded-lg font-bold'>
                      <p className='text-mg'>$ {item?.final_payment}</p>
                    </div>
                    <button
                      disabled={invoiceLoader}
                      className={`py-2 px-4 mr-3 rounded-[0.5rem]  text-[#4378C8] disabled:border-[#808080] disabled:text-[#808080] disabled:text-[#808080] flex justify-between text-bold items-center bg-primary-light ${
                        !invoiceLoader && 'hover:bg-primary-lighter'
                      }  border-transparent`}
                      style={{ fontWeight: 'bold' }}
                      onClick={downloadReport}>
                      <DownloadInvoiceButton height='1rem' width='1rem' active={!invoiceLoader} />
                      <p className='ml-3'>Download </p>
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </ModalForm>
    </OverlayContainer>
  );
};
export default Invoice;
