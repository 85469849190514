import { Avatar } from 'primereact/avatar';
import styled from 'styled-components';
import UseSvg from '../../assets/user';
import { stringAvatar, stringToColor } from './Functions';
import Tooltip from './Tootltip';

const StyledAvatar = styled(Avatar).attrs((props: { nam: any; haveBGColor: any }) => props)`
  color: white;
  border-radius: 50% !important;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.3rem;
  // font-weight: bold !important;
  text-transform: uppercase !important;
  background-color: ${(props) =>
    props?.haveBGColor ? props?.haveBGColor : stringToColor(props?.nam)} !important;
`;

const User = () => {
  return (
    <div className='rounded-full bg-slate-100 w-[2.7rem] h-[2.7rem] flex items-center justify-center'>
      <UseSvg />
    </div>
  );
};

function UserDetail(props: {
  position: string;
  label: string;
  AvatarSx?: any;
  showIcon?: boolean;
  bgColor?: any;
  labelComp?: any;
  labelSx?: string;
  circleSx?: string;
}) {
  const { position, label, labelComp } = props;
  const ShowIcon = props?.showIcon || false;
  const haveBGColor = props?.bgColor || false;

  return (
    <div className={`flex gap-2 items-center ${position === 'bottom' && 'flex-col'} w-full`}>
      {ShowIcon ? (
        <User />
      ) : (
        <StyledAvatar
          label={stringAvatar(label)}
          shape='circle'
          className={`${props?.AvatarSx}`}
          nam={label}
          haveBGColor={haveBGColor}
        />
      )}
      {!labelComp && (
        <Tooltip title={label} placement={'bottom'}>
          <p className={`${props?.labelSx} capitalize font-[500] relative`}>{label}</p>
        </Tooltip>
      )}
      {labelComp ? labelComp : ''}
    </div>
  );
}

export default UserDetail;
