import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import VendorAPI from '../../api/vendorApi';
import HandleLoad from '../../components/Loader/HandleLoad';
import { actionCreators } from '../../state';
import { RootState } from '../../state/reducers';

const useFetchCheckList = () => {
  const handleLoad = HandleLoad();
  const dispatch = useDispatch();
  const { requestServices }: any = useSelector((state: RootState) => state.userState);
  const { setServicesInfo } = bindActionCreators(actionCreators, dispatch);
  const [checkList, setCheckList] = useState([] as any);
  const [selectedReqId, setSelectedReqId] = useState(null as number | null);
  const getCheckList = useCallback(
    async (data: any, newData: boolean = false, noLoader?: boolean) => {
      const id = data?.preventive_request_id || data.id;
      if (selectedReqId !== id) {
        setSelectedReqId(+id);
      }
      if (requestServices[+id] && !newData) {
        setCheckList(requestServices[+id]);
      } else {
        if (!noLoader) {
          handleLoad(true);
        }
        let apiCallFailed = false;
        try {
          const response = await VendorAPI.getCheckList({
            preventive_request_id: id,
          });
          if (response.status === 200) {
            setCheckList(response.data.data);
            setServicesInfo({
              ...requestServices,
              [+id]: response.data.data,
            });
          } else {
            apiCallFailed = true;
            setCheckList([]);
          }
        } catch (error) {
          console.error('Error fetching checklist:', error);
          apiCallFailed = true;
          setCheckList([]);
        } finally {
          if (!apiCallFailed) {
            if (!noLoader) {
              handleLoad(false);
            }
          }
        }
      }
    },
    [requestServices]
  );

  const updatecheckListBulk = (item: any) => {
    const currentService = requestServices;
    item?.bulkSchedules?.forEach((schedule: any) => {
      const id = schedule?.preventive_request_id || schedule?.id || 0;
      currentService[id] = null;
    });
    setServicesInfo(currentService);
  };
  useEffect(() => {
    if (selectedReqId) {
      setCheckList(requestServices[+selectedReqId]);
    }
  }, [requestServices]);

  return { checkList, setCheckList, getCheckList, updatecheckListBulk };
};

export default useFetchCheckList;
