import { request } from './base';

const mainRoute = 'info_alert';
export default class NotificationApi {
  static getNotification(data: {
    query?: string
  }) {
    return request({
      url: `${mainRoute}/v1/all_info_alert${data?.query}`,
      method: 'get',
    });
  }

  static getInspNotification(data: {
    query?: string
  }) {
    return request({
      url: `${mainRoute}/v1/all_info_alert_inspection${data?.query}`,
      method: 'get',
    });
  }

  static readNotfication(data: any) {
    return request({
      url: `${mainRoute}/v1/info_alert/read/${data.id}`,
      method: 'patch',
      data: data.payload,
    });
  }
  
  static deleteNotification(data: any) {
    return request({
      url: `${mainRoute}/v1/info_alert/${data.id}`,
      method: 'patch',
      data: data.payload,
    });
  }


  static getCounts() {
    return request({
      url: `${mainRoute}/v1/notification_count`,
      method: 'get',
    });
  }
}
