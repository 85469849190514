import { useEffect, useRef } from 'react';

type OnInactivityCallback = () => void;

const useInactivityTimer = (onInactivity: OnInactivityCallback, timeout: number = 8 * 60 * 60 * 1000): void => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const resetTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(onInactivity, timeout);
  };

  useEffect(() => {
    const events = ['mousemove', 'keydown', 'scroll', 'touchstart'];
    
    const handleEvent = () => {
      resetTimer();
    };

    events.forEach(event => window.addEventListener(event, handleEvent));

    resetTimer();
    return () => {
      events.forEach(event => window.removeEventListener(event, handleEvent));
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [timeout, onInactivity]);

  return;
};

export default useInactivityTimer;
