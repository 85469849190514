import React from 'react';

function ChangeTime({ props }: any) {
  const { selectedMonth, setSelectRange, handleMonthChange } = props;
  return (
    <div className='flex justify-between items-center mb-4'>
      <button
        className='px-6 py-1 flex items-center justify-center  text-[1.1rem] rounded-[0.5rem] border-transparent bg-primary-lighter hover:bg-primary hover:text-white flex-items-center  rounded-[0.5rem] font-bold text-primary'
        onClick={() => handleMonthChange(selectedMonth.subtract(1, 'month'))}>
        <i className='pi pi-angle-left text-[1.5rem]'></i>
      </button>
      <span
        className='hover:bg-primary hover:text-white p-1 px-4 rounded-[0.5rem] cursor-pointer'
        onClick={() => {
          setSelectRange({ show: true, year: null, month: null });
        }}>
        {selectedMonth.format('MMMM YYYY')}
      </span>
      <button
        className='px-6 py-1 flex items-center justify-center text-[1.1rem] rounded-[0.5rem] border-transparent bg-primary-lighter hover:bg-primary hover:text-white flex-items-center  rounded-[0.5rem] font-bold text-primary'
        onClick={() => handleMonthChange(selectedMonth.add(1, 'month'))}>
        <i className='pi pi-angle-right text-[1.5rem]'></i>
      </button>
    </div>
  );
}

export default ChangeTime;
