import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import React from 'react';
import { apiKey } from '../../../location/ApiKey';

interface MapProps {
  coordinates: any;
  onLocationSelect: (coordinates: any) => void;
  onLocationInfo: any;
}

const Map: React.FC<MapProps> = ({ coordinates, onLocationSelect, onLocationInfo }) => {
  const onMapClick = (event: any) => {
    const clickedLocation: any = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };

    onLocationSelect(clickedLocation);
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: event.latLng }, (results: any, status) => {
      if (status === 'OK' && results[0]) {
        const place = results[0];
        onLocationInfo({
          ...place,
          coordinates: clickedLocation,
        });
      }
    });
  };
  return (
    <div>
      <LoadScript googleMapsApiKey={apiKey}>
        <GoogleMap
          mapContainerStyle={{ height: '17rem', width: '100%' }}
          zoom={12}
          center={coordinates}
          options={{
            streetViewControl: false,
            mapTypeControl: false,
            zoomControl: false,
          }}
          onClick={onMapClick}>
          {coordinates && <Marker position={coordinates} />}
        </GoogleMap>
      </LoadScript>
      <style>{`
        .zoom-control-small {
          transform: scale(0.1); // Adjust the scale factor as needed to make it smaller
        }
      `}</style>
    </div>
  );
};

export default Map;
