const startYear = 2009;
const endYear = 2054;
export const dummyYears = Array.from({ length: endYear - startYear + 1 }, (_, i) =>
  (startYear + i).toString()
);

export const dummyMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
