import { useState } from 'react';

const useFilterState = () => {
  const initialState = {
    open: false,
    isOpenVendor: false,
    isOpenCsr: false,
    isOpenPartner: false,
    isOpenStatus: false,
    isOpenServices: false,
    isOpenStationCode: false,
    isOpenTechnician: false,
    isOpenDsp: false,
    checkData: {},
    checkCsrData: {},
    checkPartnerData: {},
    checkStatusData: {},
    checkServicesData: {},
    checkStationCode: {},
    checkTechnicianData: {},
    checkDspData: {},
    allVendorData: [],
    allServicesData: [],
    allStationCodeData: [],
    allTechnicianData: [],
    allDspData: [],
    allCsrData: [],
    allPartnerData: [],
  };

  const [state, setState] = useState(initialState);

  // Generic function to update a part of the state
  const updateState = (key: keyof typeof initialState, value: any) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  // Individual setter functions for each state key
  const setOpen = (value: boolean) => updateState('open', value);
  const setIsOpenVendor = (value: boolean) => updateState('isOpenVendor', value);
  const setIsOpenCsr = (value: boolean) => updateState('isOpenCsr', value);
  const setIsOpenPartner = (value: boolean) => updateState('isOpenPartner', value);
  const setIsOpenStatus = (value: boolean) => updateState('isOpenStatus', value);
  const setIsOpenServices = (value: boolean) => updateState('isOpenServices', value);
  const setIsOpenStationCode = (value: boolean) => updateState('isOpenStationCode', value);
  const setIsOpenTechnician = (value: boolean) => updateState('isOpenTechnician', value);
  const setIsOpenDsp = (value: boolean) => updateState('isOpenDsp', value);

  const setCheckData = (value: any) => updateState('checkData', value);
  const setCheckCsrData = (value: any) => updateState('checkCsrData', value);
  const setCheckPartnerData = (value: any) => updateState('checkPartnerData', value);
  const setCheckStatusData = (value: any) => updateState('checkStatusData', value);
  const setCheckServicesData = (value: any) => updateState('checkServicesData', value);
  const setCheckStationCode = (value: any) => updateState('checkStationCode', value);
  const setCheckTechnicianData = (value: any) => updateState('checkTechnicianData', value);
  const setCheckDspData = (value: any) => updateState('checkDspData', value);

  const setAllVendorData = (value: any[]) => updateState('allVendorData', value);
  const setAllServicesData = (value: any[]) => updateState('allServicesData', value);
  const setAllStationCodeData = (value: any[]) => updateState('allStationCodeData', value);
  const setAllTechnicianData = (value: any[]) => updateState('allTechnicianData', value);
  const setAllDspData = (value: any[]) => updateState('allDspData', value);
  const setAllCsrData = (value: any[]) => updateState('allCsrData', value);
  const setAllPartnerData = (value: any) => updateState('allPartnerData', value);

  // Reset state to the initial values
  const resetState = () => {
    setState(initialState);
  };

  return {
    open: state?.open,
    isOpenVendor: state?.isOpenVendor,
    isOpenCsr: state?.isOpenCsr,
    isOpenPartner: state?.isOpenPartner,
    isOpenStatus: state?.isOpenStatus,
    isOpenServices: state?.isOpenServices,
    isOpenStationCode: state?.isOpenStationCode,
    isOpenTechnician: state?.isOpenTechnician,
    isOpenDsp: state?.isOpenDsp,
    checkData: state?.checkData,
    checkCsrData: state?.checkCsrData,
    checkPartnerData: state?.checkPartnerData,
    checkStatusData: state?.checkStatusData,
    checkServicesData: state?.checkServicesData,
    checkStationCode: state?.checkStationCode,
    checkTechnicianData: state?.checkTechnicianData,
    checkDspData: state?.checkDspData,
    allVendorData: state?.allVendorData,
    allServicesData: state?.allServicesData,
    allStationCodeData: state?.allStationCodeData,
    allTechnicianData: state?.allTechnicianData,
    allDspData: state?.allDspData,
    allCsrData: state?.allCsrData,
    allPartnerData: state?.allPartnerData,
    state: state,
    setOpen,
    setIsOpenVendor,
    setIsOpenCsr,
    setIsOpenPartner,
    setIsOpenStatus,
    setIsOpenServices,
    setIsOpenStationCode,
    setIsOpenTechnician,
    setIsOpenDsp,
    setCheckData,
    setCheckCsrData,
    setCheckPartnerData,
    setCheckStatusData,
    setCheckServicesData,
    setCheckStationCode,
    setCheckTechnicianData,
    setCheckDspData,
    setAllVendorData,
    setAllServicesData,
    setAllStationCodeData,
    setAllTechnicianData,
    setAllDspData,
    setAllCsrData,
    setAllPartnerData,
    resetState,
  };
};

export default useFilterState;
