import { useEffect, useRef } from 'react';
import ExpandPopupAppointment from './requestDetail/ExpandPopupAppointment';
import AppointmentTime from '../csr/primaryComponents/AppointmentTime';
import { v4 } from 'uuid';
let parentWidth = 0;
let parentHeight = 0;
export default function MultipleAppointmentExpand({
  item,
  variant,
}: {
  item: any;
  variant?: string;
}) {
  const parentRef: any = useRef(null);
  useEffect(() => {
    if (parentRef.current) {
      parentWidth = parentRef.current.clientWidth;
      parentHeight = parentRef.current.clientHeight;
    }
  }, [parentRef]);

  const title = item?.appointments
    ?.slice(0, 1)
    ?.map?.((el: { date: string; time: string }) => (
      <AppointmentTime item={el} key={v4()} noInfo multiple />
    ));
  if (!item) return null;
  return (
    <div className='w-full'>
      <ExpandPopupAppointment
        showOnExpand={
          <div className='text-primary mb-0.3 py-3 h-full  ml rounded-b-[0.5rem] bg-[#FFFFFF] max-h-[9rem] overflow-hidden hover:overflow-auto'>
            {item?.appointments?.map((el: { date: string; time: string }) => (
              <AppointmentTime item={el} key={v4()} noInfo multiple />
            ))}
          </div>
        }
        title={title}
        item={item}
        variant={variant}
      />
    </div>
  );
}
