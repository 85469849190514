import { useState, useEffect, useRef } from 'react';

export const useParentDimensions = (parentWidth: any, parentHeight: any) => {
  const parentRef = useRef<any>(null);
  useEffect(() => {
    if (parentRef.current) {
      parentWidth = parentRef.current.clientWidth;
      parentHeight = parentRef.current.clientHeight;
    }
  }, [parentRef]);
  return parentRef;
};
export const usePopover = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenPopup = (event: any) => {
    setAnchorEl((prev) => (prev ? null : event.currentTarget));
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
  };

  return { anchorEl, handleOpenPopup, handleClosePopup };
};
export const getBorderRadiusStyles = (expandDown: any) => {
  return expandDown
    ? { borderBottomLeftRadius: '0.5rem', borderBottomRightRadius: '0.5rem' }
    : { borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem' };
};
