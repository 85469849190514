import { request } from './base';
import {
  IPreventiveAddChecklist,
  IPreventiveAssignVendor,
  IPreventiveAssignVendorBulk,
  IPreventiveBulkScheduleDsp,
  IPreventiveBulkScheduleVendor,
  IPreventiveCancelSchedule,
  IPreventiveDeleteImage,
  IPreventiveFinalAmountSubmit,
  IPreventiveRemoveBulkSchdeule,
  IPreventiveRemoveTechnician,
  IPreventiveReschedule,
  IPreventiveScheduleDsp,
  IPreventiveScheduleVendor,
  IPreventiveSendInvoice,
  IPreventiveStatusUpdate,
  IPreventiveUpdateStatus,
  IUpdateCheckList,
  IUpdateLastPreventive,
  IAddTimeAndChecklist,
  IAssignVendorDsp,
} from './types';

export default class PreventiveAPI {
  static getVehicleList(companies: string[]) {
    return request({
      url: `preventive/v1/maintenance_ready?companies=${JSON.stringify(companies)}`,
      method: 'get',
    });
  }

  static getPreventiveRequestList() {
    return request({
      url: `pm-request/v1/preventive/requests`,
    });
  }

  static updateStatus(data: IPreventiveUpdateStatus) {
    return request({
      url: `pm-request/v1/preventive/` + data.id,
      method: 'put',
      data: {
        status_id: data.status,
      },
    });
  }

  static sendInvoice(data: IPreventiveSendInvoice) {
    const formData = new FormData();
    formData.append('final_payment', data.final_payment);
    formData.append('final_invoice', data.final_invoice);
    formData.append('preventive_request_id', data.preventive_request_id);

    return request({
      url: `pm-request/v1/preventive/send_invoice`,
      method: 'post',
      data: formData,
    });
  }

  static getPMData(params: any) {
    return request({
      url: `pm-request/v1/preventive/maintenance_csr?${params}`,
      method: 'get',
    });
  }

  static scheduleDsp(data: IPreventiveScheduleDsp) {
    return request({
      url: `pm-request/v1/preventive/schedule`,
      method: 'post',
      data: data,
    });
  }
  static bulkScheduleDsp(data: IPreventiveBulkScheduleDsp) {
    return request({
      url: `pm-request/v1/preventive/bulk_schedule`,
      method: 'post',
      data: data,
    });
  }

  static bulkScheduleVendor(data: IPreventiveBulkScheduleVendor) {
    return request({
      url: `pm-request/v1/preventive/vendor/bulk_schedule`,
      method: 'post',
      data: data,
    });
  }

  static scheduleVendor(data: IPreventiveScheduleVendor) {
    return request({
      url: `pm-request/v1/preventive/vendor/schedule`,
      method: 'post',
      data: data,
    });
  }

  static getScheduleForCsr(params: any) {
    return request({
      url: `pm-request/v1/preventive/requests_csr?${params}`,
      method: 'get',
    });
  }

  static assignVendors(data: IPreventiveAssignVendor) {
    return request({
      url: `pm-request/v1/preventive/assign_vendor`,
      method: 'post',
      data: data,
    });
  }

  static assignVendorsBulk(data: IPreventiveAssignVendorBulk) {
    return request({
      url: `pm-request/v1/preventive/assign_vendor_bulk`,
      method: 'post',
      data: data,
    });
  }

  static getVendorRequestData(data: any) {
    return request({
      url: `pm-request/v1/preventive/assigned_requests?vendor_account_id=${data.vendor_account_id}&${data.params}`,
      method: 'get',
    });
  }

  static assignTechniciansBulk(data?: any) {
    return request({
      url: `vendor_actions/v1/assign_technician_bulk`,
      method: 'post',
      data: data,
    });
  }

  static addChecklist(data: IPreventiveAddChecklist) {
    return request({
      url: `pm-request/v1/preventive/checklist`,
      method: 'post',
      data: data,
    });
  }

  static rescheduleAppointment(data: IPreventiveReschedule) {
    return request({
      url: `pm-request/v1/reschedule_appointment`,
      method: 'post',
      data: data,
    });
  }

  static cancelScheduleAppointment(data: IPreventiveCancelSchedule) {
    return request({
      url: `pm-request/v1/cancel_schedule`,
      method: 'delete',
      data: data,
    });
  }

  static uploadImages(data: any) {
    return request({
      url: `checklist_proof/v1/checklist_proof`,
      method: 'post',
      data: data,
    });
  }

  static deleteImages(data: IPreventiveDeleteImage) {
    return request({
      url: `checklist_proof/v1/checklist_proof`,
      method: 'delete',
      data: data,
    });
  }

  static statusUpdate(data: IPreventiveStatusUpdate) {
    return request({
      url: `pm-request/v1/preventive/${data?.preventive_request_id}`,
      method: 'put',
      data: { status_id: data.status_id, note: data.note },
    });
  }

  static createLeasePlan(data: any) {
    return request({
      url: `lease_plan/v1/lease_plan`,
      method: 'post',
      data: data,
    });
  }

  static updateLeasePlan(data: any) {
    return request({
      url: `lease_plan/v1/lease_plan/${data?.id}`,
      method: 'patch',
      data: data.data,
    });
  }

  static removeLeasePlanAttachment(data: any) {
    return request({
      url: `lease_plan/v1/remove_lease_plan/${data?.id}`,
      method: 'patch',
      data: data.payload,
    });
  }

  static finalAmountSubmit(data: IPreventiveFinalAmountSubmit) {
    return request({
      url: `pm-request/v1/preventive/final_amount`,
      method: 'patch',
      data: data,
    });
  }
  static removeTechnician(data: IPreventiveRemoveTechnician) {
    return request({
      url: `pm-request/v1/preventive/remove_assign_technician`,
      method: 'patch',
      data: data,
    });
  }

  static updateCheckList(data: IUpdateCheckList) {
    return request({
      url: `pm-request/v1/preventive/checklist`,
      method: 'patch',
      data: data,
    });
  }

  static updateLastPreventiveMaintainenceMiles(data: IUpdateLastPreventive) {
    return request({
      url: `preventive/v1/preventive`,
      method: 'post',
      data: data,
    });
  }

  static removeBulkSchedule(data: IPreventiveRemoveBulkSchdeule) {
    return request({
      url: `pm-request/v1/preventive/remove_pm_request`,
      method: 'patch',
      data: data,
    });
  }

  static addTimeAndChecklist(data: IAddTimeAndChecklist) {
    return request({
      url: `csr/v1/accept_appointment/singleVehicle`,
      method: 'post',
      data: data,
    });
  }

  static assignVendorFromDspProcess(data: IAssignVendorDsp) {
    return request({
      url: `csr/v1/accept_appointment`,
      method: 'post',
      data: data,
    });
  }
  static postCancelRequest(data: any) {
    return request({
      url: `csr/v1/cancel_request`,
      method: 'post',
      data: data,
    });
  }
  static approveInspection(data: any) {
    return request({
      url: `/pm-request/v1/preventive/final_amount`,
      method: 'patch',
      data: data,
    });
  }
  static addVendorCheckList(data: any) {
    return request({
      url: `/vendor/v1/fleetmax_vendor/${data?.id}`,
      method: 'patch',
      data: data?.payload,
    });
  }
  static addPaymentInfo(data: any) {
    return request({
      url: `/vendor_actions/v1/final_amount`,
      method: 'patch',
      data: data,
    });
  }
  static schedulePartner(data: any) {
    return request({
      url: `/partner/v1/schedule`,
      method: 'post',
      data: data,
    });
  }
  static scheduleTechnician(data: any) {
    return request({
      url: `/csr/v1/schedule_to_technician`,
      method: 'post',
      data: data,
    });
  }
}
