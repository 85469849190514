import { Button, Menu, Tooltip } from '@mui/material';
import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import Tootltip from '../BarData/Tootltip';
import { RootState } from '../../state/reducers';
import { useSelector } from 'react-redux';
import { toast } from '../Toast/CustomToast';
function StationCodeDropDown({ options }: { options: any }) {
  const [urlParams, setSearchParams] = useSearchParams();
  const { assignTechBulk }: any = useSelector((state: RootState) => state.userState);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedData, setSelectedData] = React.useState<any>([]);
  const [selectedCompanyIds, setSelectedCompanyIds] = React.useState<string[]>([]);
  const [prevId, setPrevId] = React.useState<string>('');
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    setAnchorEl(null);
  };

  const idInParams =
    typeof urlParams.get('companies') === 'string'
      ? JSON.parse(urlParams.get('companies') || '')
      : null;

  const existingCompanies = urlParams.get('companies');
  //finding the station of the codes in urlParams
  const selectedStationCode = useMemo(() => {
    for (const option of options) {
      for (const companyDetails of option.company_details) {
        if (existingCompanies?.includes(companyDetails.company_id || companyDetails.id)) {
          return option?.station_code;
        }
      }
    }
    return null;
  }, [options, existingCompanies]);

  React.useEffect(() => {
    const companiesString = urlParams.get('companies');
    if (companiesString) {
      const companiesArray = JSON.parse(companiesString);
      setSelectedCompanyIds(companiesArray);
    }
  }, [urlParams]);

  const handleCheckboxClick = (item: any, company_id: string) => {
    const companyId = item?.company_id || item?.id;
    const companyIdString = companyId.toString();
    const isCompanySelected = selectedCompanyIds.includes(companyIdString);
    if (company_id !== prevId) {
      setSelectedCompanyIds([companyIdString]);
    } else {
      setSelectedCompanyIds((prevIds: string[]) => {
        if (isCompanySelected) {
          return selectedCompanyIds.filter((id) => id !== companyIdString);
        } else {
          return [...selectedCompanyIds, companyIdString];
        }
      });
    }
    setPrevId(company_id);
  };

  React.useEffect(() => {
    if (!urlParams.get('companies')) {
      setSelectedData('All');
      return;
    }
    if (idInParams && selectedStationCode) {
      const selectedOption = options.find(
        (option: any) => option?.station_code === selectedStationCode
      );
      setSelectedData(selectedOption);
    }
  }, [open]);

  const handleCheckboxClickAll = () => {
    const allCompanyIds = selectedData.company_details.map((item: any) =>
      (item?.company_id || item?.id).toString()
    );
    const allSelected = allCompanyIds.every((id: string) => selectedCompanyIds.includes(id));
    if (allSelected) {
      setSelectedCompanyIds([]);
    } else {
      setSelectedCompanyIds(allCompanyIds);
    }
  };

  const selectedCompanyIdsUnderOneStation = useMemo(() => {
    if (selectedCompanyIds.length === 0) return false;
    for (const option of options) {
      const allIdsIncluded = selectedCompanyIds.every((id) =>
        option.company_details.some(
          (company: any) => (company?.company_id || JSON.stringify(company?.id)) === id
        )
      );
      if (allIdsIncluded) {
        return true;
      }
    }
    return false;
  }, [selectedCompanyIds, options]);

  const handleSave = () => {
    setSearchParams((prevParams) => {
      const params = new URLSearchParams(prevParams);
      if (selectedCompanyIds.length === 0) {
        params.delete('companies');
      } else {
        params.set('companies', JSON.stringify(selectedCompanyIds));
      }
      params.set('offset', '1');
      return params.toString();
    });
  };
  const applyFilterButton = () => {
    return (
      <div className='flex justify-between items-center gap-2 mb-1'>
        <Tooltip
          title={
            selectedCompanyIds.length === 0
              ? 'Please select a station code to Apply Filter'
              : !selectedCompanyIdsUnderOneStation &&
                'Cannot select different companies under different stations'
          }>
          {(selectedData?.company_details || selectedData === 'All') && (
            <Button
              variant='contained'
              className='font-bold  whitespace-nowrap  w-[50%]'
              disabled={
                JSON?.stringify(selectedCompanyIds) === JSON?.stringify(idInParams) ||
                !selectedCompanyIdsUnderOneStation
              }
              onClick={handleSave}>
              Apply Filter
            </Button>
          )}
        </Tooltip>
        <Button
          disabled={!urlParams.get('companies')}
          className={`cursor-pointer font-bold whitespace-nowrap w-[50%] hover:bg-primary-light`}
          onClick={(e: any) => {
            if (assignTechBulk?.show) {
              toast.error('Cannot reset filter while assigning multiple technician ');
              return;
            }
            if (urlParams.get('companies')) {
              setSearchParams((prevParams) => {
                const params = new URLSearchParams(prevParams);
                params.delete('companies');
                params.set('offset', '1');
                return params.toString();
              });
            }
          }}>
          Clear Filter
        </Button>
      </div>
    );
  };
  const optionHeads = () => {
    return (
      <div className='flex flex-col p-1 h-full overflow-auto gap-1'>
        <div
          className={`cursor-pointer child:text-gray-600 hover:bg-[#E8EEF8] p-2 rounded-xl text-gray-500 ${
            selectedData === 'All' || (Array.isArray(selectedData) && selectedData.length === 0)
              ? 'bg-[#E8EEF8]'
              : ''
          }`}
          onClick={(e: any) => {
            setSelectedData('All');
          }}>
          All
        </div>
        {options.map((item: any) => (
          <div
            key={item?.station_code}
            className={`cursor-pointer child:text-gray-600 hover:bg-[#E8EEF8] p-2 rounded-xl text-[1.1rem] text-gray-500 ${
              selectedData?.station_code === item?.station_code && 'bg-[#E8EEF8]'
            }`}
            onClick={(e: any) => {
              setSelectedData(item);
            }}>
            {item?.station_code}
          </div>
        ))}
      </div>
    );
  };
  return (
    <>
      <button
        className='w-fit border-transparent bg-white rounded-full '
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <div className='flex justify-between items-center'>
          <div className='flex justify-between items-center border-r-[0.1rem] border-gray-300 p-2'>
            <p>Station</p>
            <i
              className={`pi pi-angle-up ${
                open ? 'rotate-0' : 'rotate-180'
              } transition-transform duration-300 transform  flex justify-between items-center ml-1 mt-[0.2rem]  text-primary `}></i>
          </div>
          <Tootltip
            title={
              idInParams?.length < 1 || idInParams?.[0] === 'All'
                ? `All`
                : `${selectedStationCode || 'All'}`
            }
            placement={'bottom'}>
            <p className='max-w-[4rem] text-ellipsis overflow-hidden p-2 font-normal whitespace-nowrap text-gray-500 text-[1.1rem] font-bold'>
              {idInParams?.length < 1 || idInParams?.[0] === 'All'
                ? `All`
                : `${selectedStationCode || 'All'}`}
            </p>
          </Tootltip>
        </div>
      </button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          sx: {
            width: '23rem',
            marginTop: '0.5rem',
          },
        }}>
        <div className='flex  h-[20rem] '>
          <div className='flex flex-col h-full w-[40%] overflow-auto border-r-[0.1rem] border-gray-300 pr-1 gap-2 text-[1.1rem]'>
            <p className='text-gray-500 text-[0.9rem] mt-1 ml-1'>Station</p>
            {optionHeads()}
          </div>
          <div className='flex flex-col h-[90%] w-[60%]  pl-1 gap-2 text-[1.1rem]'>
            <p className='text-gray-500 text-[0.9rem] mt-1 ml-1'>DSPs in this station </p>
            <div className='flex flex-col justify-between h-full gap-1'>
              <div className='flex flex-col p-1 h-[18rem] overflow-auto'>
                {selectedData?.company_details ? (
                  <>
                    {selectedData?.company_details?.length > 1 && (
                      <div
                        className='flex items-center gap-2'
                        onClick={() => handleCheckboxClickAll()}>
                        <input
                          type='checkbox'
                          checked={
                            selectedData?.company_details &&
                            selectedData.company_details.every(
                              (item: any) =>
                                selectedCompanyIds.includes(item.company_id?.toString()) ||
                                selectedCompanyIds.includes(item.id?.toString())
                            )
                          }
                        />
                        <span className='cursor-pointer child:text-gray-600'>All</span>
                      </div>
                    )}
                    {selectedData?.company_details?.map((item: any) => {
                      const selectedItemId = item?.company_id || item?.id;
                      const isChecked = selectedCompanyIds.includes(selectedItemId.toString());
                      return (
                        <div
                          key={item?.company_id || item?.id}
                          className='flex items-center gap-2'
                          onClick={(e: any) => {
                            handleCheckboxClick(item, selectedData?.station_code);
                          }}>
                          <input type='checkbox' checked={isChecked} />
                          <span className='cursor-pointer child:text-gray-600 '>
                            <Tootltip title={item?.company_name} placement={'bottom'}>
                              <p
                                className='max-w-[10.5rem] text-ellipsis overflow-hidden'
                                style={{ whiteSpace: 'nowrap' }}>
                                {item?.company_name}
                              </p>
                            </Tootltip>
                          </span>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {(selectedData === 'All' ||
                      (Array.isArray(selectedData) && selectedData.length === 0)) && (
                      <>
                        <div className='flex flex-col gap-2'>
                          {options?.map((option: any, index: number) => {
                            return (
                              <>
                                {option?.company_details?.map((item: any, companyIndex: number) => {
                                  const selectedItemId = item?.company_id || item?.id;
                                  const isChecked = selectedCompanyIds.includes(
                                    selectedItemId.toString()
                                  );
                                  const { dsp_short_code } = item;
                                  return (
                                    <div
                                      key={item?.company_id || item?.id}
                                      className='flex items-center gap-2'
                                      onClick={(e: any) => {
                                        handleCheckboxClick(item, 'All');
                                      }}>
                                      <input type='checkbox' checked={isChecked} />
                                      <span className='cursor-pointer child:text-gray-600 flex break-words items-center gap-1'>
                                        <Tootltip title={item?.company_name} placement={'bottom'}>
                                          <p
                                            className='max-w-[8rem] text-ellipsis overflow-hidden'
                                            style={{ whiteSpace: 'nowrap' }}>
                                            {item?.company_name}
                                          </p>
                                        </Tootltip>
                                        <div className='px-1 rounded-[0.4rem] border border-1 border-gray-300 w-fit h-fit text-[0.8rem] flex justify-center items-center'>
                                          <Tootltip
                                            title={dsp_short_code || '---'}
                                            placement={'bottom'}>
                                            <p
                                              className='max-w-[3rem] text-ellipsis overflow-hidden'
                                              style={{ whiteSpace: 'nowrap' }}>
                                              {dsp_short_code || '---'}
                                            </p>
                                          </Tootltip>
                                        </div>
                                      </span>
                                    </div>
                                  );
                                })}
                              </>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
              {applyFilterButton()}
            </div>
          </div>
        </div>
      </Menu>
    </>
  );
}

export default StationCodeDropDown;
