import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import PartnerApi from '../../api/partnerSpecificApi';
import { actionCreators } from '../../state';
import { RootState } from '../../state/reducers';
import HandleLoad from '../../components/Loader/HandleLoad';
import { toast } from '../../components/Toast/CustomToast';

const usePartnerAsVendor = () => {
  const dispatch = useDispatch();
  const { user }: any = useSelector((state: RootState) => state.userState);
  const { partner }: any = useSelector((state: RootState) => state.mainState);
  const loader = HandleLoad();

  const { setPartner, setUser, deleteUsers, deletePartner } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const partnerAccessingVendorProfile = partner;

  const goToVendorProfile = async (vendor: { email: string; account_id: string }) => {
    loader(true);
    try {
      const response = await PartnerApi.getVendorAccess({
        vendor: {
          email: vendor.email,
          vendor_account_id: vendor.account_id,
        },
      });
      if (response.status === 200) {
        const vendoData = response.data.data;
        const capitalizedVendorName = vendoData?.name
          .toLowerCase()
          .split(' ')
          .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
        toast.info(`Logged in as Vendor (${capitalizedVendorName})`);
        setUser(response.data.data);
        setPartner({
          ...user,
          id: response?.data?.partner_id,
        });
      }
    } catch (error) {
      console.error('Error fetching checklist:', error);
    }
    loader(false);
  };

  const handleLogOut = () => {
    if (partner) {
      // log out from vendor
      setUser(partner);
      deletePartner();
      return;
    }
    deleteUsers();
    deletePartner();
  };

  return { goToVendorProfile, handleLogOut, partnerAccessingVendorProfile };
};

export default usePartnerAsVendor;
