import { Button, IconButton, Tooltip } from '@mui/material';
import HandleLoad from '../../../components/Loader/HandleLoad';
import { useEffect, useMemo, useState } from 'react';
import { toast } from '../../../components/Toast/CustomToast';
import PreventiveAPI from '../../../api/preventiveApi';
import Tootltip from '../../../components/BarData/Tootltip';
import ModalForm from '../../../components/ModalForm';
import OverlayContainer from '../../../components/OverlayContainer';
import useFetchVendorServices from '../../hooks/useFetchVendorServices';

function AddNewServices({ handleClose, setNewService }: any) {
  const loader = HandleLoad();
  const { allDetail, fetchVenderServices } = useFetchVendorServices();
  const initialInputData = {
    serviceName: '',
    cargoVan: '',
    rental: '',
    stepVan: '',
    cvd: '',
    boxTrucks: '',
    edv: '',
    serviceImages: [{ title: '', new: true }],
  };
  const serviceItems = ['Cargo Van', 'Rental', 'Step Van', 'CVD', 'Box Trucks', 'EDV'];
  const serviceAttributes = [
    'serviceName',
    'cargoVan',
    'rental',
    'stepVan',
    'cvd',
    'boxTrucks',
    'edv',
  ];
  const [inputData, setInputData] = useState<any>(initialInputData);
  const [enableService, setEnableService] = useState<any>({ show: false, data: {} });
  const [showNew, setShowNew] = useState<boolean>(true);
  const [mapInputData, setMapInputData] = useState<any>([]);

  const saveDisabledLogic =
    inputData?.serviceName?.trim() === '' || inputData?.serviceName?.trim()?.length > 50;
  const selectedVendor = {
    vendor_id: allDetail?.info?.id,
    vendor_business_id: allDetail?.business?.id || '',
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    if (e?.target?.value?.length > 50) {
      toast.error('Service name cannot be more than 50 characters');
    }
    setInputData({ ...inputData, [key]: e.target.value });
  };
  const serviceItemMap = (removeBtn?: any) => {
    return (
      <div className='flex gap-2 border-b border-gray-300 px-2'>
        <div className='w-[40%] p-2 flex items-center '>{removeBtn}</div>
        {serviceItems.map((item: string, index: number) => (
          <div
            key={item}
            className='w-[10%] font-bold p-2 flex items-center justify-center whitespace-nowrap'>
            {item}
          </div>
        ))}
      </div>
    );
  };

  const [_, setProofTitle] = useState<any>(false);
  const [editIndex, setEditIndex] = useState(null);
  const [serviceImages, setServiceImages] = useState([{ title: '', new: true }]);
  const [newImageTitle, setNewImageTitle] = useState('');
  const [error, setError] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);

  const handleDeleteImage = (index: any) => {
    const updatedImages = serviceImages.filter((_, i) => i !== index);
    setServiceImages(updatedImages);
    setInputData({ ...inputData, serviceImages: updatedImages });
  };
  const handleProofTitleChange = (e: any, index: any) => {
    const titleExists = serviceImages.some(
      (image: any) => image.title === e.target.value.toLowerCase()
    );
    if (titleExists) {
      if (serviceImages[index].title === e.target.value.toLowerCase()) {
        setErrorUpdate(false);
      } else {
        setErrorUpdate(true);
      }
      if (e.target.value === '') {
        setErrorUpdate(false);
      }
    } else {
      setErrorUpdate(false);
    }
    const updatedImages: any = serviceImages.map((image: any, i: any) =>
      i === index ? { ...image, title: e.target.value, new: false } : image
    );
    if (e.target.value?.length === 35) {
      toast.info('Maximum 35 charecters allowed!');
    }
    setServiceImages(updatedImages);
    setInputData({ ...inputData, serviceImages: updatedImages });
  };
  const addProofTitleRow = () => {
    const newImages: any = [...serviceImages, { title: newImageTitle, new: false }];
    setServiceImages(newImages);
    setInputData({ ...inputData, serviceImages: newImages });
    setNewImageTitle('');
  };
  const handleBlur = (e: any, index: any) => {
    if (!e.relatedTarget || !e.relatedTarget.closest('.service-image-container')) {
      setEditIndex(null);
    }
  };
  const addProofTitleRow1 = (errorUpdate: any) => {
    const newImages = [...serviceImages];
    setServiceImages(newImages);
    setInputData({ ...inputData, serviceImages: newImages });
    setEditIndex(null);
  };
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const handleMouseEnter = (index: any) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  const capitalizeFirstLetter = (str: any) => {
    return str.replace(/\b\w/g, (char: any) => char.toUpperCase());
  };
  const renderInputs = (attributes: any) => {
    return (
      <div>
        <div className='flex gap-2 h-[4rem] px-2'>
          {attributes.map((attr: any, index: any) => (
            <div
              key={attr}
              className={`${index === 0 ? 'w-[40%]' : 'w-[10%]'} flex items-center justify-center`}>
              <div className='relative w-full'>
                <input
                  className='w-full h-[3rem] rounded-xl pl-2 p-1  order transition-colors duration-300 outline-none focus:border-blue-500 border-1'
                  type={`${index === 0 ? 'text' : 'number'}`}
                  value={inputData[attr]}
                  placeholder={`${index === 0 ? 'Enter Service Name' : ''}`}
                  onChange={(e) => handleChange(e, attr)}
                />
              </div>
            </div>
          ))}
        </div>
        <>
          <div className='flex items-center w-full pb-[0.6rem]'>
            <div className='mx-[0.65rem] text-primary'>
              Service Image Title <span className='text-grey'>(Optional)</span>
            </div>
            <div className='h-[0.1rem] flex-grow bg-gray-300 mr-[0.4rem]'></div>
          </div>
          <div>
            <div
              className={`relative w-[16rem]
               h-[3rem] pl-[0.5rem] ${error ? 'mb-[0.4rem]' : 'mb-[0.8rem]'}`}>
              <input
                className='w-full h-[3rem] rounded-xl pl-2 p-1 order transition-colors duration-300 outline-none focus:border-blue-500 border-1 pr-[3.2rem] ml-[0.4rem]'
                type='text'
                maxLength={35}
                value={newImageTitle}
                placeholder='Enter Service Image Title'
                onChange={(e) => {
                  const titleExists = serviceImages.some(
                    (image: any) => image.title === e.target.value
                  );
                  if (e.target.value?.length === 35) {
                    toast.info('Maximum 35 charecters allowed!');
                  }
                  if (titleExists) {
                    setError(true);
                  } else {
                    setError(false);
                  }
                  if (e.target.value === '') {
                    setError(false);
                  }
                  setNewImageTitle(e.target.value);
                }}
              />
              {newImageTitle?.trim() !== '' && !error && (
                <IconButton
                  className={`absolute right-2 top-1/2 transform -translate-y-1/2 
                     bg-blue-500
                     text-white h-[1rem] w-[1rem] rounded-full`}
                  onClick={() => {
                    if (!error) {
                      addProofTitleRow();
                      setError(false);
                    }
                  }}>
                  <i className='pi pi-check text-[0.6rem]'></i>
                </IconButton>
              )}
            </div>
            {error && (
              <div className='text-red-500 ml-[1.2rem] pb-[0.6rem] text-[0.8rem]'>
                Similar service titles not allowed
              </div>
            )}
          </div>
          <div className={`flex pl-[0.6rem] ${errorUpdate ? 'mb-[0.6rem]' : ''} flex-wrap`}>
            {serviceImages.map((image: any, index: any) => (
              <div
                key={image.title}
                className={`flex items-center ${error ? 'mb-[0.3rem]' : 'mb-[0.5rem]'}`}>
                {editIndex === index || image.length > 0 ? (
                  <div className={`relative w-full h-[3rem] ${error ? 'mb-[0.6rem]' : ''}`}>
                    <input
                      className={` w-[14rem] h-[3rem] rounded-xl pl-2 p-1 order transition-colors duration-300 outline-none focus:border-blue-500 border-1 pr-[3rem] ${
                        index !== 0 ? 'ml-[0.3rem]' : ''
                      }`}
                      type='text'
                      value={image.title}
                      placeholder={`Enter Service Title`}
                      onChange={(e) => {
                        handleProofTitleChange(e, index);
                      }}
                      onBlur={(e) => handleBlur(e, index)}
                      onFocus={() => setEditIndex(index)}
                      maxLength={35}
                      onMouseLeave={handleMouseLeave}
                    />
                    {image.title !== '' && !errorUpdate && (
                      <IconButton
                        className={`absolute right-2 top-1/2 transform -translate-y-1/2 ${
                          image.title === '' ? `bg-grey` : `bg-blue-500`
                        } text-white px-[0.6rem] rounded-full`}
                        onClick={() => {
                          addProofTitleRow1(errorUpdate);
                        }}>
                        <i className='pi pi-check text-[0.6rem]'></i>
                      </IconButton>
                    )}
                    {errorUpdate && (
                      <div className='text-red-500 ml-[0.5rem] pb-[0.6rem] text-[0.8rem]'>
                        Similar Service Titles Not Allowed
                      </div>
                    )}
                  </div>
                ) : (
                  image.title !== '' && (
                    <div
                      className={`flex items-center bg-gray-100 px-[1rem] h-[3rem] gap-2 rounded-[0.5rem] hover:bg-primary-light hover:shadow-md ${
                        hoveredIndex === index ? 'py-[0.88rem]' : 'py-[1.2rem]'
                      }  ${index !== 0 ? 'mx-[0.7rem]' : ''} transition-all delay-100`}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}>
                      <Tootltip
                        title={`${capitalizeFirstLetter(String(image?.title || ''))}`}
                        placement={'bottom'}>
                        <p
                          className={`max-w-[15rem] text-ellipsis whitespace-nowrap overflow-hidden capitalize`}>
                          {image.title}
                        </p>
                      </Tootltip>
                      <div>
                        <div className='w-[3.5rem] flex items-center gap-1 h-[3rem] transition-opacity duration-300 ease-in-out'>
                          <IconButton
                            className={`text-white px-[0.6rem] rounded-full  hover:bg-primary hover:shadow-md h-[1.8rem] w-[1.8rem] ${
                              hoveredIndex === index ? 'bg-primary' : 'bg-[#A0A0A0]'
                            }`}
                            onClick={() => setEditIndex(index)}>
                            <i className='pi pi-pencil text-[0.7rem]'></i>
                          </IconButton>{' '}
                          <IconButton
                            className={`text-white px-[0.6rem] rounded-full hover:bg-primary  hover:shadow-md h-[1.8rem] w-[1.8rem] ${
                              hoveredIndex === index ? 'bg-primary' : 'bg-[#A0A0A0]'
                            }`}
                            onClick={() => handleDeleteImage(index)}>
                            <i className='pi pi-trash text-[0.7rem]'></i>
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            ))}
          </div>
        </>
      </div>
    );
  };

  const renderaddedInputs = (data: any) => {
    const newData = (({ serviceImages, ...rest }) => rest)(data);
    const { serviceImages } = data;
    const proofElement = serviceImages.filter((item: any) => item.title !== '');
    return (
      <div>
        <div className='flex gap-2 h-[4rem] px-2'>
          {Object.entries(newData).map(([key, value]: any, index: number) => (
            <div
              key={value}
              className={` ${
                index === 0 ? 'w-[40%]' : 'w-[10%]'
              } flex items-center justify-center `}>
              <div className='w-full h-full flex justify-center items-center '>
                <p className='w-[100%] h-[3rem] flex rounded-[0.9rem] justify-center items-center break-words border-[0.1rem] border-gray-150 '>
                  <Tooltip
                    title={`${capitalizeFirstLetter(String(value || ''))}`}
                    placement={'bottom'}>
                    <p
                      className={`${
                        index === 0 ? 'max-w-[20rem]' : 'max-w-[5rem]'
                      } text-ellipsis whitespace-nowrap overflow-hidden capitalize`}>
                      {String(value) || '0'}
                    </p>
                  </Tooltip>
                </p>
              </div>
            </div>
          ))}
        </div>

        {proofElement.length > 0 && (
          <div className='flex items-center w-full'>
            <div className='mx-[0.65rem] text-primary flex gap-2 items-center text-[1.1rem]'>
              Service Image Title <span className='text-gray-400 text-[0.8rem]'> (Optional)</span>
            </div>
            <div className='h-[0.1rem] flex-grow bg-gray-300 mr-[0.4rem]'></div>
          </div>
        )}
        {proofElement.length > 0 && (
          <div className='flex gap-2 px-2 flex-wrap'>
            {proofElement.map((proof: any, index: any) => (
              <div
                key={proof?.title}
                className='flex items-center bg-gray-100 px-[1rem] rounded-[0.5rem] py-[0.6rem] mb-[0.6rem] mt-[0.4rem] border-[0.05rem] border-gray-200'>
                <Tootltip
                  title={`${capitalizeFirstLetter(String(proof?.title || ''))}`}
                  placement={'bottom'}>
                  <p
                    className={`max-w-[17rem] text-ellipsis whitespace-nowrap overflow-hidden capitalize`}>
                    {String(proof?.title) || '0'}
                  </p>
                </Tootltip>
                <p>{}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const handleDelete = (index: number) => {
    if (mapInputData.length === 1) {
      setShowNew(true);
    }
    setMapInputData((prevData: any) => prevData.filter((ele: any, idx: number) => idx !== index));
  };
  const handleSave = () => {
    const trimmedServiceName = inputData?.serviceName?.trim();
    const disabledService: any[] = [];
    allDetail.services?.forEach((item: any, index: number) => {
      if (item?.name === inputData?.serviceName && item?.is_enabled === false) {
        disabledService.push(item);
      }
    });
    if (disabledService.length > 0) {
      setEnableService({ show: true, data: disabledService });
      return;
    }
    if (
      allDetail.services?.some(
        (item: any) => item.name?.trim() === trimmedServiceName && item.is_enabled === true
      ) ||
      mapInputData.some((item: any) => item.serviceName?.trim() === trimmedServiceName)
    ) {
      toast.error('Service Name already exists. Please enter a different Service Name.');
      return;
    }
    setShowNew(false);
    setProofTitle(false);
    setMapInputData([...mapInputData, inputData]);
    setInputData(initialInputData);
    setServiceImages([{ title: '', new: true }]);
    setNewImageTitle('');
  };

  useEffect(() => {
    setInputData((prev: any) => ({ ...prev, serviceImages }));
  }, [serviceImages]);

  const handleCancel = () => {
    setShowNew(false);
    setProofTitle(false);
    setInputData(initialInputData);
    setServiceImages([{ title: '', new: true }]);
  };

  const handleBack = () => {
    handleClose();
    setMapInputData([]);
    setProofTitle(false);
    setInputData(initialInputData);
  };

  const payloadData = useMemo(() => {
    const result: any = [];
    mapInputData.forEach((item: any) => {
      result.push({
        name: item?.serviceName || '',
        vt1_amount: item?.cargoVan?.length > 0 ? item?.cargoVan : 0,
        vt2_amount: item?.rental || null,
        vt3_amount: item?.stepVan || null,
        vt4_amount: item?.cvd || null,
        vt5_amount: item?.boxTrucks || null,
        vt6_amount: item?.edv || null,
        is_enabled: true,
      });
    });
    return result;
  }, [mapInputData]);
  const titles = useMemo(() => {
    const result: any = {};
    mapInputData.forEach((item: any) => {
      const extractProofTitles = (titles: any[]) => {
        return titles.map((title: any) => {
          if (title.title !== '') {
            return {
              name: item.serviceName,
              title: title.title,
            };
          }
        });
      };
      if (item.serviceImages && item.serviceImages.length > 0) {
        const proofTitleData = extractProofTitles(
          item.serviceImages.filter((item: any) => item.title !== '')
        );
        if (!result['Proof Titles']) {
          result['Proof Titles'] = proofTitleData;
        } else {
          result['Proof Titles'] = [...result['Proof Titles'], ...proofTitleData];
        }
      }
    });
    const groupedData = result['Proof Titles']?.reduce((acc: any, { name, title }: any) => {
      acc[name] = acc[name] || [];
      acc[name].push(title);
      return acc;
    }, {});

    const outputData = Object.keys(groupedData || {}).map((name) => ({
      service_name: name,
      service_titles: groupedData[name],
    }));

    return outputData;
  }, [mapInputData]);
  const updateChecklist = () => {
    loader(true);
    const payload = {
      id: allDetail?.info?.id || '',
      payload: {
        services_new: {
          vendor_company: allDetail?.business?.id || '',
          services: payloadData,
        },
        ...(titles?.length > 0 && { titles: titles }),
      },
    };
    PreventiveAPI.addVendorCheckList(payload).then((res) => {
      if (res.status === 200) {
        fetchVenderServices(selectedVendor);
        handleBack();
        setNewService?.(payloadData?.map((item: any) => item?.name));
      } else {
        toast.error('Something Went Wrong');
      }
      loader(false);
    });
  };

  useEffect(() => {
    if (selectedVendor?.vendor_id === '') {
      fetchVenderServices(selectedVendor);
    }
  }, []);
  return (
    <div className='h-[40rem] flex flex-col'>
      <div className='flex w-full '>
        <Button
          className='text-[1.05rem] font-bold mr-5 flex gap-2 items-center'
          onClick={handleBack}>
          <i className='pi pi-chevron-left'></i> Back
        </Button>
      </div>
      <div className='flex flex-col h-full overflow-auto '>
        <div className='flex flex-col h-full overflow-auto'>
          {showNew && (
            <div className='flex flex-col gap-3 pr-2'>
              <div className='border border-gray-300 m-2 rounded-xl '>
                {serviceItemMap()}
                {renderInputs(serviceAttributes)}
              </div>
              <div className='flex justify-end w-full gap-2'>
                <Button
                  variant='contained'
                  disabled={saveDisabledLogic}
                  onClick={handleSave}
                  className='text-[1.05rem] font-bold mr-5 flex h-[2.5rem] gap-2 items-center mb-[0.8rem] shadow-none px-9'>
                  Save
                </Button>
                {mapInputData.length > 0 && (
                  <Button
                    onClick={handleCancel}
                    className='text-[#C55] bg-rose-100 text-[1.05rem] h-[2.5rem] font-bold mr-5 flex gap-2 items-center mb-[0.8rem] px-6 '>
                    Cancel
                  </Button>
                )}
              </div>
            </div>
          )}
          <div className='flex flex-col gap-2  pr-2'>
            {!showNew && mapInputData.length > 0 && (
              <div className='flex w-full'>
                <Button
                  onClick={() => {
                    setShowNew(true);
                  }}
                  className='font-bold mr-5 flex gap-2 items-center'>
                  + Add New
                </Button>
              </div>
            )}
            {mapInputData &&
              mapInputData.map((data: any, index: number) => {
                const removeBtn = (
                  <div
                    className='cursor-pointer flex gap-2 items-center py-1 px-2 w-fit hover:bg-gray-100 rounded-[0.5rem]  text-gray-400 '
                    onClick={() => {
                      handleDelete(index);
                    }}>
                    <i className='pi pi-trash'></i>
                    Remove
                  </div>
                );
                return (
                  <div
                    className='border border-gray-300 m-2 rounded-xl relative'
                    key={data?.id || ''}>
                    {serviceItemMap(removeBtn)}
                    {renderaddedInputs(data)}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className='flex justify-center items-center mt-4 '>
        <Button
          variant='contained'
          disabled={mapInputData?.length === 0 || showNew}
          onClick={updateChecklist}
          className='text-[1.05rem] font-bold mr-5 flex gap-2 items-center shadow-none'>
          Save Services
        </Button>
      </div>
      {enableService.show && (
        <EnableServicesModel
          handleClose={() => {
            setEnableService({ show: false, data: {} });
          }}
          data={{
            disabledServiceData: enableService?.data,
            inputData: inputData,
            selectedVendor: selectedVendor,
            ...(setNewService
              ? {
                  setNewService: setNewService?.(payloadData?.map((item: any) => item?.name)),
                }
              : { setNewService: () => {} }),
          }}
          refetch={() => {
            fetchVenderServices(selectedVendor);
            setInputData(initialInputData);
          }}
        />
      )}
    </div>
  );
}
export default AddNewServices;

function EnableServicesModel({ handleClose, data, refetch }: any) {
  const { selectedVendor, inputData, disabledServiceData, setNewService } = data;
  const loader = HandleLoad();
  const updateChecklist = () => {
    loader(true);
    const updatedServices = disabledServiceData.map((serviceDataItem: any) => {
      let amount = '';
      amount = serviceDataItem.amount || '';
      return {
        ...serviceDataItem,
        is_enabled: true,
        amount: amount,
      };
    });
    const finalPayload = {
      id: selectedVendor?.vendor_id,
      payload: {
        services: updatedServices,
      },
    };
    PreventiveAPI.addVendorCheckList(finalPayload).then((res) => {
      if (res.status === 200) {
        refetch?.();
        setNewService?.();
        handleClose?.();
      } else {
        toast.error('Something Went Wrong');
      }
      loader(false);
    });
  };
  return (
    <OverlayContainer show={true}>
      <ModalForm title={''} onClose={handleClose} width={'w-[28rem]'} noLabel>
        <>
          <p className='w-full flex justify-center items-center text-[1.5rem] mb-3'>
            Enable Service
          </p>
          <div className='w-full h-fit flex flex-col p-2 gap-6 justify-center items-center '>
            <p className='text-[1.1rem]'>
              <span className='text-primary'>{inputData?.serviceName}</span> is disabled do you want
              to enable it ?
            </p>
            <div className='w-full flex justify-center gap-3 px-7'>
              <Button className='text-gray-400' onClick={handleClose}>
                Cancel
              </Button>
              <Button variant='contained' onClick={updateChecklist}>
                Yes, Enable
              </Button>
            </div>
          </div>
        </>
      </ModalForm>
    </OverlayContainer>
  );
}

export function AddNewServicesModel({ handleClose, setNewService }: any) {
  return (
    <OverlayContainer show={true}>
      <ModalForm title={''} onClose={handleClose} width={'w-[70rem]'} noLabel>
        <AddNewServices handleClose={handleClose} setNewService={setNewService} />
      </ModalForm>
    </OverlayContainer>
  );
}
