import { Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/reducers';
import { useSearchParams } from 'react-router-dom';
import { PartnerVendorData } from '../types/types';

export function SelectvendorDropdown() {
  const [urlParams, setSearchParams] = useSearchParams();
  const { partnerVendors, user } = useSelector((state: RootState) => state.userState);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const vendorParam = urlParams.get('vendor_ids');
  const vendorIDs: string[] = vendorParam ? JSON.parse(vendorParam) : [];

  const loggedInUser: PartnerVendorData = {
    vendor: {
      account_id: user?.account_id ?? '',
      id: user?.id ?? '',
      name: user?.name ?? '',
      phone: user?.phone ?? '',
      email: user?.email ?? '',
      b_name: '',
      address: '',
      city: '',
      state: '',
      zipcode: '',
    },
  };

  const selectedVendorID = vendorIDs[0] || null;

  const selectedVendorName = !vendorParam
    ? 'All'
    : [loggedInUser, ...partnerVendors].find((item) => item.vendor.account_id === vendorIDs[0])
        ?.vendor.name || 'Select Vendor';

  const setVendor = (item: string) => {
    if (item === vendorIDs[0]) {
      urlParams.set('vendor_ids', JSON.stringify([user?.account_id]));
    } else {
      urlParams.set('vendor_ids', JSON.stringify([item]));
    }
    setSearchParams(urlParams);
  };

  const handleMenuItemClick = (item: PartnerVendorData) => {
    setVendor(item.vendor.account_id);
    handleClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <button
        className={`flex items-center ${
          selectedVendorName ? 'border-primary' : ''
        } border-[0.1rem] px-3 py-1 rounded-full w-fit justify-between hover:bg-primary-light hover:text-primary-dark transition-all capitalize`}
        style={{
          backgroundColor: selectedVendorName ? 'rgb(232,239,248)' : 'rgba(193, 193, 193, 0.12)',
        }}
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <p
          className={` ${
            selectedVendorName ? 'text-primary' : 'text-[#C9C9C9]'
          } font-normal text-[0.9rem] font-bold capitalize`}>
          {selectedVendorName}
        </p>
        <i
          className={`pi pi-angle-${
            open ? 'up' : 'down'
          } transition-transform duration-300 transform ml-2 ${
            selectedVendorName ? 'text-primary' : 'text-[#C9C9C9]'
          }`}></i>
      </button>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
        PaperProps={{ sx: { width: 'fit-content', marginTop: '0.5rem' } }}>
        {[loggedInUser, ...partnerVendors].map((item: PartnerVendorData) => (
          <MenuItem
            className='capitalize'
            key={item.vendor.account_id}
            onClick={() => handleMenuItemClick(item)}
            selected={selectedVendorID === item.vendor.account_id}>
            {item.vendor.name} {user?.account_id === item.vendor.account_id && '(Me)'}
          </MenuItem>
        ))}
        <MenuItem
          onClick={() => {
            urlParams.delete('vendor_ids');
            setSearchParams(urlParams);
            handleClose();
          }}
          selected={!urlParams.get('vendor_ids')}>
          All
        </MenuItem>
      </Menu>
    </>
  );
}
