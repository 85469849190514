import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import { object, string } from 'yup';
import AuthAPI from '../../../api/usersApi';
import HandleLoad from '../../../components/Loader/HandleLoad';
import DualPasword from '../../../components/Password/DualPassword';
import PasswordField from '../../../components/Password/Password';
import { toast } from '../../../components/Toast/CustomToast';

const InfoBox = (props: any) => {
  const { control, errors, name } = props;
  const mainBoxClass = props?.mainSx ? props?.mainSx : 'w-full';

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value } }) => (
        <div className={`flex ${mainBoxClass} flex-col gap-2`}>
          {props.type === 'Password' && (
            <PasswordField
              label={name}
              sx={{ width: '100%' }}
              id='outlined-basic'
              size='small'
              variant='outlined'
              helperText={errors[name]?.message}
              onChange={onChange}
              value={value}
              type='password'
              error={!!errors[name]?.message}
              onBlur={onBlur}
            />
          )}
        </div>
      )}
    />
  );
};

const DIV = styled.div`
  input[type='password'] {
    -webkit-text-security: none; /* Disable the default dot display */
    font-family: monospace; /* Use a monospace font for consistent character spacing */
  }

  input[type='password']::-webkit-text-security-disc {
    visibility: hidden; /* Hide the default dot character */
  }

  input[type='password']::before {
    content: '●' !important; /* Replace the dot character with a custom symbol */
    visibility: visible;
    position: absolute;
    left: 0;
    color: #666; /* Set the color of the custom symbol */
  }
`;

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

const PasswordSchema = object({
  'New Password': string()
    .matches(passwordRules, { message: 'Please create a stronger password' })
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters'),
  'Confirm Password': string()
    .oneOf([yup.ref('New Password'), null], 'Passwords must match')
    .required()
    .min(8, 'Password must be at least 8 characters'),
}).required();

const Process: any = {
  1: {
    info: 'An OTP will be sent to your Email',
    btnName: 'Send OTP',
    label: 'Reset Password',
  },
  2: {
    info: 'To Proceed,  Please Enter OTP sent to your Email',
    btnName: 'Verify OTP',
    label: 'Reset Password',
  },
  3: {
    info: 'Login again with your new Password',
    btnName: 'Confirm & Login',
    label: 'Set New Password',
  },
};

const emailchema = object({
  'Email ID': yup.string().min(5).email('Invalid email format').required(),
}).required();

const DataBox = (props: any) => {
  return (
    <>
      <TextField
        sx={{
          width: '100%',
        }}
        size={'small'}
        {...props}
        autoComplete={'off'}
        label={'Email'}
      />
    </>
  );
};

export default function ForgotPassword() {
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm<any>({
    mode: 'all',
    resolver: yupResolver(emailchema),
  });

  const {
    handleSubmit: submit_1,
    control: control_1,
    formState: formState_1,
    getValues: getValues_1,
  } = useForm<any>({
    mode: 'all',
    resolver: yupResolver(PasswordSchema),
  });
  const loader = HandleLoad();

  const { errors: errors_1 } = formState_1;

  const [state, setState] = useState(1 as number);
  const [token, setToken] = useState('');
  const [otp, setotp] = useState(null as any);

  const handleSendOtp = () => {
    const formData: any = getValues();
    handleSubmit(() => {
      loader(true);
      AuthAPI.sendOtp({
        identifier: formData['Email ID'],
      }).then((res: any) => {
        loader(false);
        if (res.status === 200) {
          setToken(res?.data?.data?.token);
          toast.success(res?.data?.message || 'otp sent');
          setState(2);
        } else {
          toast.error(res?.data?.message || 'Please input registered email');
        }
      });
    })();
  };

  const handleVerifyOtp = () => {
    if (otp.toString().length !== 6) {
      toast.error('Please enter otp of length 6');
      return;
    }
    loader(true);
    AuthAPI.verifyOtp({
      otp: otp,
      token: token,
    }).then((res: any) => {
      loader(false);
      if (res.status >= 200 && res.status < 210) {
        toast.success(res?.data?.message || 'otp verified');
        setState(3);
      } else {
        toast.error(res?.response?.data?.message || 'Something went wrong!');
      }
    });
  };

  const handleChangePassword = () => {
    const formData1 = getValues_1();
    loader(true);
    AuthAPI.changePassword({
      otp: otp,
      token: token,
      new_password: formData1['New Password'],
    }).then((res: any) => {
      loader(false);
      if (res.status >= 200 && res.status < 210) {
        toast.success(res?.data?.message || 'Password changed successfully');
        navigate('/auth/signin');
      } else {
        toast.error(res?.response?.data?.message || 'Something went wrong!');
      }
    });
  };

  const handleBtnClick = () => {
    if (!otp && state === 2) {
      toast.error('Type the OTP first');
      return;
    }
    if (state === 1) {
      handleSendOtp();
    }
    if (state === 2) {
      handleVerifyOtp();
    }
    if (state === 3) {
      submit_1(handleChangePassword)();
    }
  };

  return (
    <div className='w-full h-full flex px-10 bg-[url("/public/images/bgReducedSize.jpg")] bg-no-repeat bg-cover bg-right items-center justify-center'>
      <div className=' bg-light-sign-in m-auto shadow-customShadow rounded-2xl px-[3rem] py-14  w-[27%] min-h-[45%] min-w-[20rem] flex justify-between flex-col gap-2'>
        <div
          className={`min-h-[${state === 3 ? '75%' : '60%'}] flex flex-col justify-between gap-4`}>
          <div className='flex flex-col gap-3 items-center'>
            <p className='text-2xl'>{Process[state].label}</p>
            <p className='mb-[2rem] text-center'>{Process[state].info}</p>
          </div>
          <div className='flex h-fit w-full my-auto justify-center flex-col gap-[2rem] items-center'>
            {state === 1 && (
              // <TextField size='small' sx={{ width: "100%" }} label={"Email"} />
              <Controller
                control={control}
                name={'Email ID'}
                render={({ field: { onChange, onBlur, value } }) => (
                  <DataBox
                    lable={'Email ID'}
                    {...{
                      onChange,
                      onBlur,
                      value,
                      helperText: errors['Email ID']?.message,
                      error: !!errors['Email ID']?.message,
                    }}
                  />
                )}
              />
            )}
            {state === 2 && (
              <>
                <TextField
                  size='small'
                  placeholder='******'
                  value={otp}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6);
                  }}
                  inputProps={{
                    type: 'number',
                    maxlength: '4',
                  }}
                  onChange={(e) => setotp(e?.target?.value)}
                  sx={{
                    width: '10rem',
                    '& input': {
                      textAlign: 'center',
                      letterSpacing: '0.5rem',
                      height: '1.5rem',
                      fontSize: '1.5rem',
                    },
                  }}
                />
              </>
            )}
            {state === 3 && (
              <>
                <DualPasword
                  control={control_1}
                  errors={errors_1}
                  noplabel
                  boxSx={''}
                  boxes={[
                    {
                      label: 'New Password',
                      type: 'Password',
                    },
                    {
                      label: 'Confirm Password',
                      type: 'Password',
                    },
                  ]}
                />
              </>
            )}
          </div>
        </div>
        <div className='flex h-[25%] items-center justify-center flex-col gap-3'>
          {state === 2 && (
            <Button
              sx={{
                height: 'fit-content',
                width: '45%',
                padding: '0.5rem 0rem',
                textTransform: 'capitalize',
                fontWeight: 600,
              }}
              variant='text'
              onClick={handleSendOtp}>
              {'Resend OTP'}
            </Button>
          )}

          <Button
            sx={{
              height: 'fit-content',
              width: '45%',
              padding: '0.5rem 0rem',
              textTransform: 'capitalize',
              fontWeight: 600,
              marginTop: state === 3 ? 4 : '',
            }}
            variant='contained'
            onClick={handleBtnClick}>
            {Process[state].btnName}
          </Button>
          {state === 2 && (
            <Button
              sx={{
                height: 'fit-content',
                width: '45%',
                padding: '0.5rem 0rem',
                textTransform: 'capitalize',
                fontWeight: 600,
              }}
              variant='text'
              onClick={() => {
                setState((prev) => prev - 1);
              }}>
              {'Back'}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
