import { Popover } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { getStatus } from '../../csr/primaryComponents/Invoice/index';
let parentWidth = 0;
let parentHeight = 0;

export default function InvoicingExpandPopUp({
  item,
  variant,
  handleClick,
  serviceByPartner,
}: {
  item: any;
  noPopUp?: boolean;
  variant?: string;
  icon?: JSX.Element;
  handleClick?: any;
  serviceByPartner?: boolean;
}) {
  const parentRef: any = useRef(null);

  useEffect(() => {
    if (parentRef.current) {
      parentWidth = parentRef.current.clientWidth;
      parentHeight = parentRef.current.clientHeight;
    }
  }, [parentRef]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenPopup = (event: any) => {
    if (item?.lease_plan && !serviceByPartner) {
      if (anchorEl) {
        setAnchorEl(null);
      } else {
        setAnchorEl(event.currentTarget);
      }
    }
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
  };

  const expandDown = variant === 'expandDown';

  const upDirection = expandDown ? !anchorEl : anchorEl;
  const showPopUp: boolean =
    (!['sent', 'updated'].includes(item?.dsp_invoice_status) ||
      !['amount_submitted'].includes(item?.vendor_invoice_status)) &&
    !['raised'].includes(item?.vendor_invoice_dispute);

  if (!item) return null;
  if (showPopUp) {
    return (
      <div className='w-full'>
        <button
          className={`text-[#FFF] relative w-full  flex justify-between font-bold border-transparent rounded-[0.4rem]  ${
            anchorEl && (upDirection ? 'rounded-t-none' : 'rounded-b-none')
          } h-[3.2rem]`}
          onClick={handleOpenPopup}
          ref={parentRef}>
          <div
            className={`flex gap-2 rounded-[0.4rem] items-center   w-full h-full p-4 rounded-r-none ${
              !item?.lease_plan || serviceByPartner ? 'bg-slate-400' : 'bg-[#4378C8]'
            }`}>
            {'Invoicing'}
          </div>
          <div className='bg-primary-light h-full w-[4rem] rounded-[0.4rem] rounded-l-none'>
            <i
              className={`pi pi-angle-down ${
                upDirection ? 'rotate-0' : 'rotate-180'
              } ease-in-out duration-200 text-grey text-primary mt-[1rem]`}></i>
          </div>
        </button>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClosePopup}
          PaperProps={{
            sx: {
              width: parentRef.current?.clientWidth,
              boxShadow: 'none',
              borderRadius: 0,
              ...(expandDown
                ? {
                    borderBottomLeftRadius: '0.5rem',
                    borderBottomRightRadius: '0.5rem',
                  }
                : {
                    borderTopLeftRadius: '0.5rem',
                    borderTopRightRadius: '0.5rem',
                  }),
            },
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
            ...(expandDown && {
              vertical: 'bottom',
              horizontal: 'center',
            }),
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            ...(expandDown && {
              vertical: 'top',
              horizontal: 'center',
            }),
          }}>
          <div
            className={`relative  w-full bg-primary-light p-1`}
            style={{
              ...(expandDown
                ? {
                    borderBottomLeftRadius: '0.5rem',
                    borderBottomRightRadius: '0.5rem',
                  }
                : { borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem' }),
            }}>
            {
              <div
                className={`container overflow-auto  mb-2 p-2  h-[6.5rem]`}
                style={{
                  backgroundColor: 'white',
                  borderRadius: '0.6rem',
                }}>
                <div>
                  <div
                    className='w-full text-left p-2 border-transparent flex justify-between font-bold hover:bg-primary-light rounded-lg cursor-pointer'
                    onClick={() => {
                      handleClick('DSP');
                      setAnchorEl(null);
                    }}>
                    DSP Invoice
                    {getStatus(item, false)}
                  </div>
                  <div
                    className='w-full text-left p-2 border-transparent flex justify-between font-bold hover:bg-primary-light  rounded-lg  cursor-pointer'
                    onClick={() => {
                      handleClick('Vendor');
                      setAnchorEl(null);
                    }}>
                    Vendor Invoice
                    {getStatus(item, true)}
                  </div>
                </div>
              </div>
            }
          </div>
        </Popover>
      </div>
    );
  } else {
    return null;
  }
}
