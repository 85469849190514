import { CheckisSameID } from '../hooks/csr/useFetchSingleRequest';

export const groupTabs = (tempData: any[], mapOne: any, individualRequest: boolean = false) => {
  let finalData: any[] = [];
  tempData.forEach((request: any) => {
    const finalRequestObj: any = { ...request };
    finalRequestObj['id'] = request?.preventive_request_id || request.id;
    if (request?.bulk_id) {
      if (individualRequest) {
        finalRequestObj['title'] = `Bulk-${finalRequestObj.bulk_id}/${finalRequestObj.id}`;
      } else {
        finalRequestObj['title'] = `Bulk-${finalRequestObj.bulk_id}`;
        finalRequestObj['id'] = null;
        finalRequestObj['preventive_request_id'] = null;
      }
      finalRequestObj['bulkSchedules'] = mapOne.get(finalRequestObj.bulk_id);
      const bulkremarks = getBulkRemarks(request);
      const updatedRemarks = bulkremarks.concat(request.remarks);
      finalRequestObj['remarks'] = updatedRemarks;
    } else {
      finalRequestObj['title'] = `Request-${finalRequestObj.id}`;
    }
    finalData.push(finalRequestObj);
  });
  return finalData;
};

export const makeData = (
  tempData: any[],
  individualRequest: boolean = false,
  data: any,
  user?: any
) => {
  const isValidUser = ['fleetmax_partner', 'fleetmax_vendor'].includes(user?.role);
  const selectedOne = data?.preventive_request_id || data.id;
  let selectedData = null;
  let finalData: any[] = [];
  tempData.forEach((request: any) => {
    const finalRequestObj: any = { ...request };
    const isSameIdinRejected = CheckisSameID(user, request);
    finalRequestObj['id'] = request?.preventive_request_id || request.id;
    if (request?.bulk_id) {
      if (individualRequest) {
        finalRequestObj['title'] = `Bulk-${finalRequestObj.bulk_id}/${finalRequestObj.id}`;
      } else {
        finalRequestObj['title'] = `Bulk-${finalRequestObj.bulk_id}`;
      }
      const bulkremarks = getBulkRemarks(request);
      const updatedRemarks = bulkremarks.concat(request.remarks);
      finalRequestObj['remarks'] = updatedRemarks;
      if (!isSameIdinRejected && isValidUser) {
        finalRequestObj['status_id'] = user?.role === 'fleetmax_partner' ? '-1' : '4';
        finalRequestObj['sub_stage'] = '3';
        finalRequestObj['vendor_invoice_dispute'] = null;
        finalRequestObj['vendor_invoice_status'] = null;
        finalRequestObj['invoice'] = null;
        finalRequestObj['final_payment'] = null;
      }
    } else {
      finalRequestObj['title'] = `Request-${finalRequestObj.id}`;
    }
    if (+selectedOne === (+finalRequestObj['id'] || +finalRequestObj['preventive_request_id'])) {
      selectedData = finalRequestObj;
    }
    finalData.push(finalRequestObj);
  });
  return [finalData, selectedData];
};

export const processUniqueBulkData = (d: any, map: any, arr: any) => {
  if (d.bulk_id) {
    map = map.set(d.bulk_id, d);
  } else {
    arr.push(d);
  }
  return [map, arr];
};

export const groupBulkId = (d: any, map: any) => {
  const newBulkData = { ...d };
  newBulkData['remarks'] = getBulkRemarks(d).concat(d.remarks);
  const foundData = map.get(d.bulk_id);
  if (foundData) {
    map.set(d.bulk_id, foundData.concat([newBulkData]));
  } else {
    map.set(d.bulk_id, [newBulkData]);
  }
  return map;
};

export const getBulkRemarks = (request: any) => {
  return (
    (request.bulk_remarks &&
      request.bulk_remarks.length > 0 &&
      request.bulk_remarks[0] &&
      request.bulk_remarks[0]?.remarks) ||
    []
  );
};
