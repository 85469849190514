import React, { useState } from 'react';
import { DSPTextProps } from './components/Types';
import { Model } from './components/Model';
import { RootState } from '../../../../../state/reducers';
import { useSelector } from 'react-redux';

function DSPText(props: DSPTextProps) {
  const {
    vehicles,
    selectedDSP,
    setSelectedDSP,
    data,
    setAllData,
    isSaved,
    setIsSaved,
    adminList,
    setAdminList,
  } = props;

  const [showModel, setShowModel] = useState<boolean>(false);
  const isCheckboxSelected = adminList.length > 0 || showModel;
  const dspNames = adminList.map((item) => item.dsp).join(', ') || 'None selected';
  const { user } = useSelector((state: RootState) => state.userState);
  if (user?.role !== 'fleetmax_csr') return null;
  return (
    <React.Fragment>
      <div className='flex items-start gap-2'>
        <input
          type='checkbox'
          className='w-4 h-4 mt-1'
          checked={isCheckboxSelected}
          onChange={() => {
            setShowModel(true);
          }}
        />
        <div>
          <p className={`${isCheckboxSelected ? 'text-black' : 'text-gray-400'} font-medium`}>
            Notify DSP via text{' '}
            {adminList.length > 0 && (
              <span
                className='text-primary ml-2 underline cursor-pointer font-bold'
                onClick={() => setShowModel(true)}>
                Select
              </span>
            )}
          </p>
          {adminList.length > 0 && <div className='text-sm text-gray-600 flex'>{dspNames}</div>}
        </div>
      </div>
      {showModel && (
        <Model
          handleClose={() => setShowModel(false)}
          disabled={false}
          vehicles={vehicles}
          selectedDSP={selectedDSP}
          setSelectedDSP={setSelectedDSP}
          data={data}
          setAllData={setAllData}
          isSaved={isSaved}
          setIsSaved={setIsSaved}
          setAdminList={setAdminList}
        />
      )}
    </React.Fragment>
  );
}

export default DSPText;
