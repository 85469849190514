import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import * as React from 'react';

export default function CustomConfirm({
  comp,
  title,
  info,
  accept,
  reject,
  anchorOrigin,
  transformOrigin,
  acceptText,
  style,
  bottomBarStyle,
  width,
  disable,
}: {
  // comp: JSX.Element,
  comp: any;
  title: string;
  info: string;
  accept: () => void;
  reject?: () => void;
  anchorOrigin?: any;
  transformOrigin?: any;
  style?: any;
  acceptText?: string;
  bottomBarStyle?: string;
  width?: string;
  disable?: any;
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [popOpen, setPopOpen] = React.useState(null as any);
  const handleClick = (event: any) => {
    if (!disable) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    reject && reject();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  React.useEffect(() => {
    if (popOpen === 'callAccept') {
      accept();
      setPopOpen(null);
    }
  }, [popOpen]);

  React.useEffect(() => {
    setPopOpen(null);
  }, []);

  return (
    <>
      <div onClick={handleClick} className='w-full'>
        {comp}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
          ...anchorOrigin,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
          ...transformOrigin,
        }}
        style={{ ...style }}>
        <div className='flex flex-col gap-3 ' style={{ padding: '1.2rem' }}>
          <p className='text-lg'>{title}</p>
          <p className='text-[#A0A0A0] max-w-[21rem]'>{info}</p>
          <div className={`flex justify-between ${bottomBarStyle}`}>
            <Button
              variant='text'
              className={`font-bold hover:bg-primary-light w-[8rem] `}
              onClick={() => {
                setAnchorEl(null);
                reject && reject();
              }}>
              Cancel
            </Button>
            <Button
              variant='contained'
              color='error'
              className='bg-[#CC5555] whitespace-nowrap'
              onClick={() => {
                setPopOpen('callAccept');
                setAnchorEl(null);
              }}>
              {acceptText || 'Yes, Delete'}
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
}
