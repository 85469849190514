import { useEffect, useState } from 'react';
import VendorAPI from '../../../../../api/vendorApi';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { RootState } from '../../../../../state/reducers';
import { useSelector } from 'react-redux';

function UsefetchDateRequest({
  selectedDate,
  ishighlighted,
}: {
  selectedDate: any;
  ishighlighted: boolean;
}) {
  const [requests, setRequests] = useState<any>([]);
  const [urlParams] = useSearchParams();
  const [loading, setLoading] = useState<any>(false);
  const vendorParam: string | null = urlParams?.get('vendor_ids');
  const { user }: any = useSelector((state: RootState) => state.userState);

  const fetchRequests = async () => {
    setLoading(true);
    const vendor_id_array = vendorParam ? JSON?.parse(vendorParam) : [];
    const partnerAsVendor: string =
      +vendor_id_array?.[0] === +user?.account_id && user?.role === 'fleetmax_partner'
        ? '&partner_as_vendor=true'
        : '';
    const vendors = vendorParam ? `${String(vendorParam)}${partnerAsVendor}` : null;
    const formattedDate = dayjs(selectedDate).format('YYYY-MM-DD');
    const response = await VendorAPI?.requestPerDate(formattedDate, vendors);
    if (response.status === 200) {
      const data = response?.data?.data;
      setRequests(data);
      setLoading(false);
    } else {
      setLoading(false);
      setRequests([]);
    }
  };
  useEffect(() => {
    if (ishighlighted) fetchRequests();
  }, [selectedDate, ishighlighted, vendorParam]);
  return {
    fetchRequests,
    requests,
    loading,
  };
}

export default UsefetchDateRequest;
