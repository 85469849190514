import { XIcon } from '@heroicons/react/solid';
import { Fade } from '@mui/material';
import { ModalFormModel } from '../../models/ModalFormModel';

const CrossComp = ({
  nothing,
  noLabel,
  children,
  onClose,
  title,
  width,
  titleComp,
}: {
  nothing?: boolean;
  noLabel?: boolean;
  titleComp?: any;
  children: JSX.Element;
  onClose: () => void;
  title?: string;
  width: any;
}) => {
  if (nothing) return <div className='px-6 py-5 h-auto'>{children}</div>;

  if (noLabel) {
    return (
      <>
        <div className='text-primary-2 text-md  flex items-center justify-between px-6 py-5 relative z-30'>
          <div
            onClick={onClose}
            className='rounded-full p-2 cursor-pointer hover:bg-primary-light absolute right-3 top-3'>
            <XIcon className='h-6 w-6 text-gray-600' />
          </div>
          <div className='w-full'>{children}</div>
        </div>
      </>
    );
  }

  return (
    <>
      {titleComp ? titleComp : null}
      {!titleComp && (
        <div className='text-primary-2 text-md  flex items-center justify-between px-6 py-5 relative  z-30'>
          {<h1 className='text-center flex-1 text-xl'>{title}</h1>}
          <div
            onClick={onClose}
            className='rounded-full p-2 cursor-pointer hover:bg-primary-light absolute right-3 top-3'>
            <XIcon className='h-6 w-6 6' />
          </div>
        </div>
      )}
      <div className='pt-0'>{children}</div>
    </>
  );
};

const ModalForm = ({
  children,
  title,
  onClose,
  width,
  noLabel,
  nothing,
  titleComp,
}: ModalFormModel) => {
  return (
    <Fade in={true} timeout={300}>
      <div className='h-full w-full flex items-center justify-center overlayDiv'>
        <div
          className={`bg-white rounded-[2.2rem] border-[1px] border-gray-primary-1 ${
            width ? width : 'w-[470px] overlayClass'
          } h-auto shadow-modelShadow`}>
          <CrossComp {...{ children, title, onClose, width, noLabel, nothing, titleComp }} />
        </div>
      </div>
    </Fade>
  );
};

export default ModalForm;
