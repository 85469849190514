import Tootltip from './Tootltip';

function TitleTooltip({
  title,
  width,
  palcement,
  style,
}: {
  title: string;
  width?: string;
  palcement?: string;
  style?: string;
}) {
  const tooltipTitle = title || '---';
  const tooltipPlacement = palcement || 'bottom';
  const tooltipMaxWdith = width || '5rem';
  return (
    <Tootltip title={tooltipTitle} placement={tooltipPlacement}>
      <p
        className={`max-w-[${tooltipMaxWdith}] text-ellipsis whitespace-nowrap overflow-hidden capitalize ${
          style ? style : ''
        }`}>
        {tooltipTitle}
      </p>
    </Tootltip>
  );
}

export default TitleTooltip;
