import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import CsrAPI from '../../../api/csrApi';
import ViewLogsIcon from '../../../assets/ViewLogsIcon';
import Calender from '../../../assets/calender';
import HandleLoad from '../../../components/Loader/HandleLoad';
import ModalForm from '../../../components/ModalForm';
import OverlayContainer from '../../../components/OverlayContainer';
import CrossButtonsCalender from '../../../assets/CrossButtonCalender';
import { SortCreated } from '../SortData';
import { StepConnector, stepConnectorClasses, Button } from '@mui/material';
import { useSelector } from 'react-redux';

import styled from 'styled-components';
import { RootState } from '../../../state/reducers';

function QontoStepIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='2rem'
      height='2rem'
      viewBox='0 0 32 32'
      fill='none'>
      <g filter='url(#filter0_d_5075_9062)'>
        <circle cx='16' cy='12' r='12' fill='#4378C8' />
        <circle cx='16' cy='12' r='10.5' stroke='white' stroke-width='3' />
      </g>
      <defs>
        <filter
          id='filter0_d_5075_9062'
          x='0'
          y='0'
          width='32'
          height='32'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'>
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_5075_9062' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_5075_9062'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
}
export const usePmLogs = () => {
  const handleLoad = HandleLoad();
  const [logs, setLogs] = useState([] as any);
  const getPmLogs = useCallback((data: any) => {
    handleLoad(true);
    CsrAPI.getPmLogs({
      preventive_request_id: data?.preventive_request_id || data.id,
      ...(data?.query && { query: data?.query }),
    }).then((response: any) => {
      if (response.status === 200) {
        setLogs(response.data.data);
      } else {
        setLogs([]);
      }
      handleLoad(false);
    });
  }, []);
  return { logs, setLogs, getPmLogs };
};
function Logs({ item, type, iconSX }: { item?: any; type?: string; iconSX?: string }) {
  const { user }: any = useSelector((state: RootState) => state.userState);
  const [maintainanceLogModel, setMaintainanceLogModel] = useState(false);
  const handelClick = () => {
    setMaintainanceLogModel((prev) => !prev);
  };
  return (
    <>
      {type === 'partner_logs' && user.role === 'fleetmax_vendor' && (
        <Button
          sx={{ background: '#F3F3F3', width: '100%' }}
          className='bg-primary-light h-[2.5rem]'
          onClick={handelClick}>
          <div className='font-bold text-primary border-transparent cursor-pointer'>
            <button className='border-transparent text-[0.9rem]'>View Partner Actions </button>
          </div>
        </Button>
      )}
      {['bulk', 'single'].includes(type || '') && (
        <>
          {type === 'bulk' && (
            <div
              className='font-bold text-primary border-transparent cursor-pointer w-full'
              onClick={handelClick}>
              <button className='border-transparent text-[1rem]'>View Logs </button>
            </div>
          )}
          <div>
            {type === 'single' && (
              <button
                className={`bg-white rounded-[0.8rem] py-2 px-3 text-primary flex justify-between border-transparent items-center  ${
                  iconSX ? iconSX : ''
                }`}
                onClick={handelClick}>
                <ViewLogsIcon />
                <p className='ml-3'>View Logs</p>
              </button>
            )}
          </div>
        </>
      )}
      {maintainanceLogModel && (
        <MaintainanceLogsModel handleClick={handelClick} item={item} type={type} />
      )}
    </>
  );
}
export default Logs;
function convertAndCapitalize(inputString: string) {
  let stringWithSpaces = inputString.replace(/_/g, ' ');
  return stringWithSpaces;
}
function renderLogTitle(
  step: {
    log_message: {
      sub_heading: any;
      action: any;
      schedule_type:
        | string
        | number
        | boolean
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | React.ReactFragment
        | null
        | undefined;
    };
    created_at: moment.MomentInput;
  },
  logs: any,
  item?: any
) {
  switch (step.log_message.action) {
    case 'request created by csr':
      return <div className='font-bold text-[1.3rem] capitalize'>Request created by CSR</div>;
    case 'payment done':
      return <div className='font-bold text-[1.3rem] capitalize'>Payment Confirmed</div>;
    case 'technician_reached_destination':
      return (
        <div className='font-bold text-[1.3rem] capitalize'>Technician Reached Destination</div>
      );
    case 'technician_started_journey':
      return <div className='font-bold text-[1.3rem] capitalize'>Technician Started Journey</div>;
    case 'edited_request':
      return <div className='font-bold text-[1.3rem] capitalize'> Request Edited</div>;
    case 'invoice amount_submitted_by_csr for vendor':
      return (
        <div className='font-bold text-[1.3rem] capitalize'>Vendor Invoice Amount Submitted</div>
      );
    default: {
      return (
        <div className='font-bold text-[1.3rem] capitalize'>
          {convertAndCapitalize(step.log_message.action)}
        </div>
      );
    }
  }
}
function renderLogMessage(
  step: {
    log_message: {
      sub_heading: any;
      action: any;
      schedule_type:
        | string
        | number
        | boolean
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | React.ReactFragment
        | null
        | undefined;
    };
    created_at: moment.MomentInput;
  },
  logs: any,
  item?: any
) {
  const subData = logs?.sub_title_data;
  const appointmentDate = `${moment(
    `${subData?.appointment_date} ${subData?.appointment_time}`,
    'YYYY-MM-DD hh:mm:ss'
  ).format("DD MMM'YY")} ${','}
  ${moment(
    `${subData?.appointment_date} ${subData?.appointment_time}`,
    'YYYY-MM-DD hh:mm:ss'
  ).format('hh:mm A')}`;
  switch (step.log_message.action) {
    case 'schedule to vendor':
      return (
        <div className='flex'>
          <p>
            Scheduled to the Vendor{' '}
            <span className='capitalize ml-1'>{logs?.sub_title_data?.vendor_name}</span> at
          </p>
          <p style={{ color: 'blue', marginLeft: '0.3rem' }}>{appointmentDate}</p>
        </div>
      );
    case 'schedule to dsp':
      return <></>;
    case 'technician assigned':
      return (
        <div className='flex'>
          <p>
            Technician :
            <span className='capitalize ml-1'>
              {step?.log_message?.sub_heading?.technician || ''}
            </span>
          </p>
        </div>
      );
    case 'vendor assigned':
      return (
        <div className='flex'>
          <p>
            Vendor <span className='capitalize ml-1'>{logs?.sub_title_data?.vendor_name}</span>{' '}
            Assigned{' '}
            {step?.log_message?.schedule_type && (
              <>
                {'('}
                {step?.log_message?.schedule_type || ''}
                {')'}
              </>
            )}
          </p>
        </div>
      );
    case 'work proof accepted':
      return (
        <div className='flex'>
          <p>
            Work Proof Accepted for the technician
            <span className='capitalize ml-1'>{logs?.sub_title_data?.technician_name}</span> (
            <span className='capitalize ml-1'>{logs?.sub_title_data?.vendor_name}</span>)
          </p>
        </div>
      );
    case 'work proof approved':
      return (
        <div className='flex'>
          <p>
            Work Proof Approved for the technician
            <span className='capitalize ml-1'>{logs?.sub_title_data?.technician_name}</span> (
            <span className='capitalize ml-1'>{logs?.sub_title_data?.vendor_name}</span>)
          </p>
        </div>
      );
    case 'final amount':
      return (
        <div className='flex'>
          <p>
            {' '}
            Vendor <span className='capitalize ml-1'>{logs?.sub_title_data?.vendor_name}</span>{' '}
            Added the Confirmed Payment
          </p>
        </div>
      );
    case 'lease plan uploaded':
      return (
        <div className='flex'>
          <p>Lease Plan Data Added</p>
        </div>
      );
    case 'lease plan updated':
      return (
        <div className='flex'>
          <p>Lease Plan Updated</p>
        </div>
      );
    case 'invoice generated':
      return (
        <div className='flex'>
          {step.log_message.schedule_type === 'invoice generated for vendor' ? (
            <p>
              Invoice Generated for Vendor{' '}
              <span className='capitalize ml-1'>{logs?.sub_title_data?.vendor_name}</span>
            </p>
          ) : (
            <p>Invoice Generated for DSP {item?.dsp?.name}</p>
          )}
        </div>
      );
    case 'invoice generated for dsp':
      return (
        <div className='flex'>
          <p>Invoice Generated for the DSP {item?.dsp?.name}</p>
        </div>
      );
    case 'invoice sent':
      return (
        <div className='flex'>
          <p>
            Invoice Sent to the Vendor{' '}
            <span className='capitalize ml-1'>{logs?.sub_title_data?.vendor_name}</span>
          </p>
        </div>
      );
    case 'invoice sent to vendor':
      return (
        <div className='flex'>
          <p>
            Invoice Sent to the Vendor{' '}
            <span className='capitalize ml-1'>{logs?.sub_title_data?.vendor_name}</span>{' '}
          </p>
        </div>
      );
    case 'invoice generated for vendor':
      return (
        <div className='flex'>
          <p>
            Invoice Generated For the Vendor{' '}
            <span className='capitalize ml-1'>{logs?.sub_title_data?.vendor_name}</span>
          </p>
        </div>
      );
    case 'invoice sent to dsp':
      return (
        <div className='flex'>
          <p>Invoice Sent to the DSP {item?.dsp?.name}</p>
        </div>
      );
    case 'request accepted by vendor':
      return (
        <div className='flex'>
          <p>
            Request Accepted by the Vendor
            <span className='capitalize ml-1'>{logs?.sub_title_data?.vendor_name}</span>
          </p>
        </div>
      );
    case 'vendor reassigned':
      return (
        <div className='flex'>
          <p>
            Reassigned from Vendor {step?.log_message?.sub_heading?.old_vendor} to{' '}
            {step?.log_message?.sub_heading?.new_vendor}
          </p>
        </div>
      );
    case 're-assigned technician':
      return (
        <div className='flex'>
          <p>
            Reassigned from Technician {step?.log_message?.sub_heading?.old_technician} to{' '}
            {step?.log_message?.sub_heading?.new_technician}
          </p>
        </div>
      );
    case 'work proof rejected':
      return (
        <div className='flex'>
          <p>
            Work Proof Rejected for the technician
            <span className='capitalize ml-1'>{logs?.sub_title_data?.technician_name}</span> (
            <span className='capitalize ml-1'>{logs?.sub_title_data?.vendor_name}</span>)
          </p>
        </div>
      );
    case 'remove vendor':
      return (
        <div className='flex'>
          <p>
            Vendor <span className='capitalize ml-1'>{logs?.sub_title_data?.vendor_name}</span>{' '}
            removed
          </p>
        </div>
      );
    case 'payment done':
      return (
        <div className='flex'>
          <p>
            Vendor: <span className='capitalize ml-1'>{logs?.sub_title_data?.vendor_name}</span> has
            confirmed Payment of ${logs?.sub_title_data?.final_amount}
          </p>
        </div>
      );
    case 'request rejected by vendor':
      return (
        <div className='flex'>
          <p>
            Request Rejected by Vendor{' '}
            <span className='capitalize ml-1'>{logs?.sub_title_data?.vendor_name}</span>
          </p>
        </div>
      );
    case 'service started':
      return (
        <div className='flex'>
          <p>
            Service Started by Technician:
            <span className='capitalize ml-1'>{logs?.sub_title_data?.technician_name}</span>
          </p>
        </div>
      );
    case 'completed by technician':
      return (
        <div className='flex'>
          <p>
            Completed by the Technician:
            <span className='capitalize ml-1'>{logs?.sub_title_data?.technician_name}</span>
          </p>
        </div>
      );
    case 'bulk action preventive appointment':
      return (
        <div className='flex'>
          <p>Bulk Action Preventive Appointment Initiated</p>
        </div>
      );
    case 'schedule approved by dsp':
      return (
        <div className='flex'>
          <p>Schedule Approved by the DSP {item?.dsp?.name} at </p>
          {step?.log_message?.sub_heading && Array.isArray(step?.log_message.sub_heading) ? (
            <>
              {step.log_message.sub_heading.map((subHeadingItem: any, index: any) => {
                const preventiveRequestId = Number(subHeadingItem?.preventive_request_id);
                if (
                  preventiveRequestId ===
                  Number(logs?.preventive_request_id[index]?.preventive_request_id)
                ) {
                  const formattedTime = moment(subHeadingItem.appointment_date).format('LL');
                  const formattedDate = moment(subHeadingItem.appointment_time, 'HH:mm:ss').format(
                    'LT'
                  );
                  return (
                    <div key={subHeadingItem} style={{ color: 'blue', marginLeft: '0.3rem' }}>
                      {formattedTime} {formattedDate}
                    </div>
                  );
                }
                return null;
              })}
            </>
          ) : (
            <div style={{ color: 'blue' }}>
              {moment(step?.log_message?.sub_heading?.appointment_date).format('LL')}
              {moment(step?.log_message?.sub_heading?.appointment_time, 'HH:mm:ss').format('LT')}
            </div>
          )}
        </div>
      );
    case 'lease plan data added':
      return (
        <div className='flex'>
          <p>Lease Plan Data has been added </p>
        </div>
      );
    case 'update checklist':
      return (
        <div className='flex'>
          <p>Checklist Has been Updated</p>
        </div>
      );
    case 'technician removed':
      return (
        <div className='flex'>
          <p>Tehnician {step?.log_message?.sub_heading?.old_technician} has been removed</p>
        </div>
      );
    case 'request created by csr':
      return (
        <div className='flex'>
          <p>Vehicle Name: {logs?.sub_title_data?.vehicle_name}</p>
        </div>
      );
    case 'work proof skipped':
      return (
        <div className='flex'>
          <p>
            Work Proof Skipped by the Technician
            <span className='capitalize ml-1'>{logs?.sub_title_data?.technician_name}</span>
          </p>
        </div>
      );
    case 'work proof skipped by vendor':
      return (
        <div className='flex'>
          <p>Vendor: {step?.log_message?.sub_heading?.skipped_by}</p>
        </div>
      );
    case 'work proof submitted':
      return (
        <div className='flex'>
          <p>
            Work Proof Submitted by the Technician
            <span className='capitalize ml-1'>{logs?.sub_title_data?.technician_name}</span>
          </p>
        </div>
      );
    case 'work proof submitted by vendor':
      return (
        <div className='flex'>
          <p>Vendor: {step?.log_message?.sub_heading?.added_by}</p>
        </div>
      );
    case 'work proof submitted by csr':
      return (
        <div className='flex'>
          <p>CSR: {logs?.sub_title_data?.csr_name}</p>
        </div>
      );
    case 'technician_started_journey':
      return (
        <div className='flex'>
          <p>
            Technician :{' '}
            <span className='capitalize mx-1'>{logs?.sub_title_data?.technician_name}</span>has
            Started Journey{' '}
          </p>
        </div>
      );
    case 'technician_reached_destination':
      return (
        <div className='flex'>
          <p>
            Technician :{' '}
            <span className='capitalize mx-1'>{logs?.sub_title_data?.technician_name}</span>has
            Reached Destination
          </p>
        </div>
      );
    case 'schedule accepted by dsp':
      return (
        <div className='flex'>
          <p>Scheduled For the DSP {item?.dsp?.name}</p>
          <p style={{ color: 'blue', width: '12rem', display: 'flex' }}>
            <div className='ml-1'>
              {moment(step?.log_message?.sub_heading?.appointment_date).format('LL')}
            </div>
            <div className='ml-1'>
              {moment(step?.log_message?.sub_heading?.appointment_time, 'HH:mm:ss').format('LT')}
            </div>
          </p>
        </div>
      );
    case 'invoice updated for vendor':
      return (
        <div className='flex'>
          <p>Invoice Updated for Vendor {logs?.sub_title_data?.vendor_name}</p>
        </div>
      );
    case 'invoice amount_submitted_by_csr for vendor':
      return (
        <div className='flex'>
          <p>
            Vendor Invoice Amount Submitted by csr for Vendor {logs?.sub_title_data?.vendor_name}
          </p>
        </div>
      );
    default:
      return <div>{step.log_message.schedule_type}</div>;
  }
}
function ButtonField(props: any) {
  const { setOpen, label, InputProps: { ref } = { ref: null }, onSetNull }: any = props;
  const handleSetNull = () => {
    onSetNull();
  };
  const showCrossButton = props.value !== null;
  return (
    <div className={'w-full cursor-pointer flex'} ref={ref}>
      <div className='flex justify-between text-primary bg-white p-1 rounded-[0.5rem] pl-4 pr-1 cursor-pointer'>
        <div className='flex justify-between' onClick={() => setOpen?.((prev: any) => !prev)}>
          <div className='mr-2 mt-2'>
            <Calender />
          </div>
          <div className='flex flex-col items-start'>
            <div className='flex justify-between font-bold text-[0.7rem]'>
              <div>Select Date</div>
            </div>
            <div className='font-bold text-black'>
              {moment(label).isValid() ? moment(label).format('MM-DD-YYYY') : 'MM/DD/YYY'}
            </div>
          </div>
        </div>
        {showCrossButton && (
          <button
            className='font-bold font-red border border-transparent ml-[1.1rem]'
            onClick={handleSetNull}>
            <CrossButtonsCalender />
          </button>
        )}
      </div>
    </div>
  );
}
function ButtonDatePicker(
  props: Omit<DatePickerProps<Dayjs>, 'open' | 'onOpen' | 'onClose'> & { onSetNull: () => void }
) {
  const { onSetNull, ...otherProps } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <DatePicker
        disableFuture
        slots={{ field: ButtonField, ...props.slots }}
        slotProps={{
          field: { setOpen, onSetNull, open } as any,
          popper: {
            placement: 'bottom',
          },
        }}
        {...otherProps}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      />
    </>
  );
}
function PickerWithButtonField({ value, onChange, onSetNull }: any) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ButtonDatePicker
        label={`${!value ? 'MM/DD/YYYY' : value?.format('MM/DD/YYYY')}`}
        value={value}
        onChange={(newValue) => {
          onChange({ date: newValue });
        }}
        onSetNull={onSetNull}
      />
    </LocalizationProvider>
  );
}
const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.root}`]: {
    marginLeft: '0.85rem',
  },
  [`& .${stepConnectorClasses.line}`]: {
    display: 'block',
    borderColor: '#8C8C8C',
    borderLeftStyle: 'dashed',
    borderLeftWidth: '0.2rem',
    minHeight: '4rem',
    marginLeft: '0.05rem',
  },
}));
function MaintainanceLogsModel({
  item,
  handleClick,
  type,
}: {
  item?: any;
  handleClick?: any;
  type?: string;
}) {
  const [date, setDate] = useState(null as any);
  const { logs, getPmLogs } = usePmLogs();

  useEffect(() => {
    if (type === 'partner_logs') {
      getPmLogs({
        preventive_request_id: item?.preventive_request_id || item.id,
        query: `&log_type=partner_as_vendor`,
      });
    } else {
      getPmLogs(item);
    }
  }, [type]);

  const activeStep = logs?.preventive_request_id?.length - 1;
  let currentDate = '';
  const onChange = (value: any) => {
    setDate(value?.date);
  };
  return (
    <OverlayContainer show={true}>
      <ModalForm onClose={handleClick} noLabel width={'w-[60vw]'}>
        <div className='w-full '>
          <div className='flex font-bold text-xl'>Maintenance Logs</div>
          <div className='flex justify-between bg-[#F5F5F5] my-5 p-3 rounded-[1.3rem]'>
            <div className='ml-2'>
              <p className='flex text-primary font-bold'>{item?.dsp?.name || '----'}</p>
              <p className='flex text-black text-[1.3rem]'>{item?.vehicle?.name || '----'}</p>
            </div>
            <div className='flex items-center'>
              <PickerWithButtonField
                value={date}
                onChange={onChange}
                onSetNull={() => setDate(null)}
              />
            </div>
          </div>
          <div className='w-full'>
            <div
              className={`overflow-auto  mb-2 p-2  h-[35rem]`}
              style={{
                backgroundColor: 'white',
                borderRadius: '0.6rem',
              }}>
              <Box sx={{ width: '100%' }}>
                <Stepper
                  connector={<QontoConnector />}
                  activeStep={activeStep}
                  orientation='vertical'
                  className='w-full'
                  sx={{
                    position: 'relative',
                    top: '3rem',
                    '& .MuiStep-root': {
                      marginBottom: '-2.2rem',
                      marginTop: '-1.8rem',
                    },
                  }}>
                  {SortCreated(
                    logs?.preventive_request_id?.filter((step: any) =>
                      date ? moment(step.created_at).isSame(date?.$d, 'day') : true
                    )
                  ).map((step: any, index: any) => {
                    const visible: boolean =
                      currentDate !== moment(step.created_at).format('LL') ? true : false;
                    currentDate =
                      currentDate !== moment(step.created_at).format('LL')
                        ? moment(step.created_at).format('LL')
                        : currentDate;
                    return (
                      <Step key={step.id}>
                        <StepLabel StepIconComponent={QontoStepIcon}>
                          <div>
                            {visible && (
                              <div
                                className='flex justify-center h-[1.5rem] w-fit bg-primary rounded-[0.4rem] text-bold text-white px-[0.4rem] pt-[0.2rem] font-bold'
                                style={{ marginTop: '-1.3rem' }}>
                                {moment(step.created_at).format('LL')}
                              </div>
                            )}
                            <div className='flex'>
                              <div className='mr-5 pt-5 w-[8rem] text-[#4378C8] flex justify-center mt-[0.1rem]'>
                                {moment(step.created_at).format('LT')}
                              </div>
                              <div
                                className='w-full my-2 pb-1'
                                style={{ borderBottom: '0.12rem solid #EAEAEA' }}>
                                {renderLogTitle(step, logs, item)}
                                {renderLogMessage(step, logs, item)}
                              </div>
                            </div>
                          </div>
                        </StepLabel>
                      </Step>
                    );
                  })}

                  {logs?.preventive_request_id
                    ?.filter((step: any) =>
                      date ? moment(step.created_at).isSame(date?.$d, 'day') : true
                    )
                    .slice()
                    .reverse().length === 0 && <p className='mx-auto my-[10rem]'>No Logs Found</p>}
                </Stepper>
              </Box>
            </div>
          </div>
        </div>
      </ModalForm>
    </OverlayContainer>
  );
}
