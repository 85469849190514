import React from 'react';
interface OverViewData {
  completed_count: number;
  incomplete_count: number;
  completion_rate: number;
}

interface AllData {
  total_summary: OverViewData;
}
interface Props {
  allData: AllData;
}
function BotomBarPerfomance({ allData }: Props) {
  return (
    <>
      <div className='flex items-center gap-2'>
        <i className='pi pi-chart-bar text-gray-500'></i>
        <p className='text-[0.8rem] text-gray-500'>Completion Rate</p>
      </div>
      <div className='h-full w-full flex justify-between'>
        <div className='h-full w-[50%] flex justify-between'>
          <div className='flex gap-2 items-center '>
            <div className='flex gap-2 items-center bg-white p-1 rounded-[0.5rem] px-4 py-2 shadow'>
              <p className='h-[2.5rem] w-[1rem] bg-primary-light'></p>
              <p className='flex flex-col gal-2'>
                <p className='text-gray-400 font-bold'>{allData?.total_summary?.completed_count}</p>
                <p className='text-[0.8rem]'>Complete</p>
              </p>
            </div>
          </div>
          <div className='flex gap-2 items-center '>
            <div className='flex gap-2 items-center bg-white p-1 rounded-[0.5rem] px-4 py-2 shadow'>
              <p className='h-[2.5rem] w-[1rem] bg-[#6CB4EE]'></p>
              <p className='flex flex-col gal-2'>
                <p className='text-gray-400 font-bold'>
                  {allData?.total_summary?.incomplete_count}
                </p>
                <p className='text-[0.8rem]'>Incomplete</p>
              </p>
            </div>
          </div>
        </div>
        <div className='flex gap-2 justify-center items-center w-[33.33%] border-l-[0.1rem] border-gray-300'>
          <div className='flex gap-2 items-center bg-white p-1 rounded-[0.5rem] px-4 shadow'>
            <div className='flex gap-2 items-center '>
              <p className='flex flex-col gal-2'>
                <p className='text-gray-500 font-bold text-[1.2rem]'>
                  {allData?.total_summary?.completion_rate}%
                </p>
                <p>Completion rate</p>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BotomBarPerfomance;
