const EditIcon = (props: { height?: string; width?: string; active?: boolean }) => {
  const height = props?.height || '1.7rem';
  const width = props?.width || '1.7rem';
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.4 12.8009H5.792C5.89728 12.8015 6.00166 12.7814 6.09912 12.7416C6.1966 12.7018 6.28525 12.6431 6.36 12.569L11.896 7.0266L14.168 4.80325C14.243 4.7289 14.3025 4.64044 14.3431 4.54298C14.3837 4.44552 14.4046 4.34099 14.4046 4.23541C14.4046 4.12983 14.3837 4.0253 14.3431 3.92784C14.3025 3.83038 14.243 3.74192 14.168 3.66758L10.776 0.23657C10.7016 0.161609 10.6131 0.102111 10.5157 0.0615077C10.4182 0.0209045 10.3136 0 10.208 0C10.1024 0 9.99783 0.0209045 9.90034 0.0615077C9.80285 0.102111 9.71437 0.161609 9.64 0.23657L7.384 2.49991L1.832 8.04231C1.75785 8.11704 1.69919 8.20566 1.65938 8.30311C1.61957 8.40055 1.59939 8.50489 1.6 8.61014V12.0012C1.6 12.2133 1.68429 12.4167 1.83431 12.5667C1.98434 12.7167 2.18783 12.8009 2.4 12.8009ZM10.208 1.93208L12.472 4.19542L11.336 5.33109L9.072 3.06775L10.208 1.93208ZM3.2 8.93805L7.944 4.19542L10.208 6.45877L5.464 11.2014H3.2V8.93805ZM15.2 14.4005H0.8C0.587827 14.4005 0.384344 14.4847 0.234315 14.6347C0.0842854 14.7847 0 14.9881 0 15.2002C0 15.4123 0.0842854 15.6158 0.234315 15.7658C0.384344 15.9157 0.587827 16 0.8 16H15.2C15.4122 16 15.6157 15.9157 15.7657 15.7658C15.9157 15.6158 16 15.4123 16 15.2002C16 14.9881 15.9157 14.7847 15.7657 14.6347C15.6157 14.4847 15.4122 14.4005 15.2 14.4005Z'
        fill={props?.active ? '#4378C8' : '#9B9B9B'}
      />
    </svg>
  );
};
export default EditIcon;
