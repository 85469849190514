import { Tooltip } from '@mui/material';
import ApprovedSvg from '../../../../assets/Approved';
import AwaitingAcceptence from '../../../../assets/AwaitingAcceptence';
import UploadProof from '../../../../assets/JobCompletedOrUploadProofs';
import PendingApprovalSvg from '../../../../assets/PendingApproval';
import TechAssigned from '../../../../assets/TechAssigned';
import OnTheWayIcon from '../../../../assets/OnTheWayIcon';

export const GetInfoIconCSR = ({ item }: any) => {
  return (
    <>
      {item.status_id === '4' && item?.sub_stage === '1' && (
        <Tooltip title='Awaiting Acceptance'>
          <div className='bg-[#F8F4E8] flex items-center justify-center  rounded-lg h-[2rem] w-[3.3rem]'>
            <AwaitingAcceptence />
          </div>
        </Tooltip>
      )}
      {item.status_id === '4' && (item?.sub_stage ? item?.sub_stage === '2' : true) && (
        <Tooltip title='Assigned To Vendor'>
          <div className='w-[3.3rem] bg-lime-200 flex items-center justify-center h-[2rem] rounded-full'>
            <i className='pi pi-user-plus' style={{ fontSize: '1rem' }}></i>
          </div>
        </Tooltip>
      )}

      {/* Track Location  */}
      {item.status_id === '5' && ['1', '2', '3'].includes(item?.sub_stage) && (
        <Tooltip title={item?.sub_stage === '1' ? 'Technion En-Route' : 'Technician Reached'}>
          <div className='w-[3.3rem] bg-[#3E9983] flex items-center justify-center h-[2rem] rounded-lg'>
            {+item?.sub_stage === 1 ? (
              <OnTheWayIcon fill='#E5F8BD' />
            ) : (
              <i className={`pi pi-map-marker text-white`}></i>
            )}{' '}
          </div>
        </Tooltip>
      )}
      {item.status_id === '5' && item?.sub_stage === undefined && (
        <Tooltip title='Assigned To Technician'>
          <div>
            <TechAssigned />
          </div>
        </Tooltip>
      )}
      {item.status_id === '6' && (
        <Tooltip title='Technician started working'>
          <div className=' bg-sky-200 flex items-center justify-center  rounded-lg w-full py-2'>
            <i className='pi pi-cog' style={{ fontSize: '1rem' }}></i>
          </div>
        </Tooltip>
      )}
      {/** to do */}
      {item.status_id === '7' && (
        <Tooltip title='Job Completed/ Upload Proof'>
          <div className=' bg-[#E5F8BD] flex items-center justify-center  rounded-lg w-full'>
            <UploadProof height='2.2rem' />
          </div>
        </Tooltip>
      )}
      {/** approval pending */}
      {item.status_id === '8' && (
        <Tooltip title='Approval Pending'>
          <div className='bg-[#F8F4E8] flex items-center justify-center rounded-lg py-0 w-full'>
            <PendingApprovalSvg />
          </div>
        </Tooltip>
      )}
      {/** approved */}
      {item.status_id === '9' && (
        <Tooltip title='Approved'>
          <div className='flex items-center justify-center  rounded-lg w-full bg-[#EAF8E8]'>
            <ApprovedSvg />
          </div>
        </Tooltip>
      )}
      {/* Rejected */}
      {item.status_id === '10' && (
        <Tooltip title='Rejected'>
          <div className=' bg-red-200 flex items-center justify-center  rounded-lg w-full py-2'>
            <i className='pi pi-times' style={{ fontSize: '1rem' }}></i>
          </div>
        </Tooltip>
      )}
      {item.status_id === '11' && (
        <Tooltip title='Invoice Sent'>
          <div className=' bg-teal-200 flex items-center justify-center  rounded-lg w-full py-2'>
            <i className='pi pi-money-bill' style={{ fontSize: '1rem' }}></i>
          </div>
        </Tooltip>
      )}
      {item.status_id === '12' && (
        <Tooltip title='Payment Completed'>
          <div className='w-full bg-green-200 flex items-center justify-center h-[2rem] rounded-lg'>
            <i className='pi pi-dollar' style={{ fontSize: '1rem' }}></i>
          </div>
        </Tooltip>
      )}
    </>
  );
};
