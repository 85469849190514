import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function SimpleBackdrop() {
  return (
    <Backdrop
      open={true}
      sx={{zIndex: 100}}
    >
      <CircularProgress  sx={{color:"#b0e1ff"}} size={"3.5rem"} />
    </Backdrop>
  );
}