export default function (props: { height?: string; width?: string; active?: boolean }) {
  const height = props?.height || 16;
  const width = props?.width || 27;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect width='10.45' height='15.2' rx='1.9' fill='#4378C8' />
      <rect
        x='4.27578'
        y='4.27578'
        width='10.45'
        height='14.25'
        rx='1.425'
        stroke='#4378C8'
        stroke-width='0.95'
        stroke-dasharray='1.9 1.9'
      />
      <path
        d='M1.90234 5.69922V5.69922C2.57568 7.23828 4.09625 8.23275 5.77616 8.23275H7.44445'
        stroke='white'
        stroke-width='0.95'
      />
      <path d='M6.33594 6.9668L7.6027 8.23356L6.33594 9.65868' stroke='white' stroke-width='0.95' />
    </svg>
  );
}
