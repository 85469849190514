export default function (props: {
  height?: string;
  width?: string;
  active?: boolean;
  color?: string;
}) {
  const height = props?.height || '1.5rem';
  const width = props?.width || '1.2rem';
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.4286 1.85143H20.5714V0.925714C20.5714 0.370286 20.2286 0 19.7143 0C19.2 0 18.8571 0.370286 18.8571 0.925714V1.85143H15.4286V0.925714C15.4286 0.370286 15.0857 0 14.5714 0C14.0571 0 13.7143 0.370286 13.7143 0.925714V1.85143H10.2857V0.925714C10.2857 0.370286 9.94286 0 9.42857 0C8.91429 0 8.57143 0.370286 8.57143 0.925714V1.85143H5.14286V0.925714C5.14286 0.370286 4.8 0 4.28571 0C3.77143 0 3.42857 0.370286 3.42857 0.925714V1.85143H2.57143C1.11429 1.85143 0 3.05486 0 4.62857V23.1429C0 24.7166 1.11429 25.92 2.57143 25.92H21.4286C22.8857 25.92 24 24.7166 24 23.1429V4.62857C24 3.05486 22.8857 1.85143 21.4286 1.85143ZM2.57143 3.70286H3.42857V4.62857C3.42857 5.184 3.77143 5.55429 4.28571 5.55429C4.8 5.55429 5.14286 5.184 5.14286 4.62857V3.70286H8.57143V4.62857C8.57143 5.184 8.91429 5.55429 9.42857 5.55429C9.94286 5.55429 10.2857 5.184 10.2857 4.62857V3.70286H13.7143V4.62857C13.7143 5.184 14.0571 5.55429 14.5714 5.55429C15.0857 5.55429 15.4286 5.184 15.4286 4.62857V3.70286H18.8571V4.62857C18.8571 5.184 19.2 5.55429 19.7143 5.55429C20.2286 5.55429 20.5714 5.184 20.5714 4.62857V3.70286H21.4286C21.9429 3.70286 22.2857 4.07314 22.2857 4.62857V7.40571H1.71429V4.62857C1.71429 4.07314 2.05714 3.70286 2.57143 3.70286ZM21.4286 24.0686H2.57143C2.05714 24.0686 1.71429 23.6983 1.71429 23.1429V9.25714H22.2857V23.1429C22.2857 23.6983 21.9429 24.0686 21.4286 24.0686Z'
        fill={props.color ? props.color : '#4378C8'}
      />
    </svg>
  );
}
