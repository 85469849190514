import { MainStateModel, IvehicleData } from '../../models/MainStateModel';
import { ActionType } from '../action-types';

const initialState: MainStateModel = {
  bulkVehicles: [],
  allBulkVehicles: [],
  addVendorPageData: {},
  bulkAddService: [],
  moveToCompleteData: [],
  selectedQuestionnaire: [],
  singleScheduleVehicle: {} as IvehicleData,
  partner: null,
};

const reducer = (state: MainStateModel = initialState, action: any): MainStateModel => {
  switch (action.type) {
    case ActionType.SET_BULK_VEHICLES:
      return {
        ...state,
        ...action?.payload,
        bulkVehicles: Array.isArray(action?.payload?.bulkVehicles)
          ? action?.payload?.bulkVehicles
          : [],
      };
    case ActionType.SET_ADD_VENDOR_PAGE_DATA:
      return { ...state, ...action.payload };
    case ActionType.SET_ADD_SERVICE_BULK:
      return { ...state, bulkAddService: action.payload };
    case ActionType.SET_COMPLETE_DATA:
      return { ...state, moveToCompleteData: action.payload };
    case ActionType.SET_SELECTED_QUESTIONNAIRE:
      return { ...state, selectedQuestionnaire: action.payload };
    case ActionType.SET_SINGLE_SCHEDULE_VEHICLE:
      return { ...state, singleScheduleVehicle: action.payload };
    case ActionType.SET_PARTNER:
      return { ...state, partner: action.payload };
    case ActionType.SET_ALL_BULK_VEHICLES:
      return { ...state, allBulkVehicles: action.payload };
    default:
      return state;
  }
};

export default reducer;
