export default function (props: { height?: string; width?: string; active?: boolean }) {
  const height = props?.height || 16;
  const width = props?.width || 27;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 36 36'
      fill='none'>
      <path
        d='M28.0778 0.0878906H28.068L35.2286 7.55438V35.9107H8.5V35.9993H35.3172V7.64297L28.0778 0.0878906Z'
        fill='#949494'
      />
      <path d='M27.9827 0H8.41406V35.9114H35.232V7.55508L27.9827 0Z' fill='#F1F1F1' />
      <path d='M8.21602 1.92188H0V10.7004H8.21602H25.8413V1.92188H8.21602Z' fill='#808080' />
      <path d='M25.9797 1.77539H0.167969V10.5631H25.9797V1.77539Z' fill='#DC1D00' />
      <path
        d='M8.48164 6.20281C8.44227 6.20281 8.40289 6.20281 8.36352 6.20281V4.29945H8.61031C8.89649 4.29945 9.07367 4.38805 9.20234 4.53641C9.35984 4.71359 9.4393 5.0293 9.4393 5.25641C9.4393 5.57211 9.4393 5.85828 9.15313 6.065C9.00406 6.17328 8.75727 6.20281 8.48164 6.20281ZM8.72774 3.25391C8.71789 3.25391 8.6982 3.25391 8.68836 3.25391C8.56039 3.25391 8.47109 3.25391 8.42188 3.25391H7.05078V9.42805H8.37266V7.3468L8.66867 7.36649C8.97453 7.36649 9.25086 7.29758 9.49695 7.21883C9.74375 7.14008 9.95047 7.00156 10.1284 6.84406C10.3055 6.68656 10.4736 6.48899 10.5622 6.26188C10.7 5.91664 10.7295 5.43359 10.7 5.0982C10.6606 4.76281 10.6508 4.48649 10.5523 4.26992C10.4539 4.05266 10.3252 3.87547 10.1776 3.73766C10.0201 3.59984 9.85203 3.5007 9.67485 3.4318C9.48711 3.36289 9.31977 3.32352 9.14258 3.29398C8.98438 3.26375 8.84656 3.25391 8.72774 3.25391Z'
        fill='#4D4D4D'
      />
      <path
        d='M12.9694 8.30383C12.93 8.30383 12.8906 8.30383 12.8414 8.30383V4.36844C12.8513 4.36844 12.8513 4.36844 12.8611 4.36844C13.1276 4.36844 13.463 4.36844 13.6303 4.50625C13.8075 4.64406 13.946 4.82195 14.0445 5.01883C14.1429 5.22625 14.2118 5.45266 14.2216 5.69945C14.2315 5.98563 14.2216 6.21203 14.2216 6.40961C14.2216 6.59735 14.2216 6.8336 14.1823 7.07055C14.1429 7.3075 14.0641 7.51422 13.965 7.72164C13.8567 7.91922 13.669 8.05703 13.5016 8.185C13.354 8.2743 13.1768 8.30383 12.9694 8.30383ZM13.2063 3.24414C13.0685 3.24414 12.9202 3.25398 12.8316 3.25398C12.6642 3.26383 12.5651 3.26383 12.5257 3.26383H11.5195V9.43797H12.7029C13.2253 9.43797 13.6598 9.35922 14.0248 9.21086C14.3897 9.06321 14.6759 8.84594 14.9023 8.57946C15.1195 8.31297 15.2869 7.98742 15.3853 7.61266C15.4838 7.23789 15.533 6.8336 15.533 6.38992C15.533 5.81758 15.533 5.37391 15.4247 4.94008C15.3263 4.54563 15.1188 4.22992 14.9023 3.99297C14.6948 3.75602 14.4586 3.58867 14.2118 3.48039C13.965 3.37211 13.7288 3.29266 13.5016 3.25328C13.4032 3.24414 13.3048 3.24414 13.2063 3.24414Z'
        fill='#4D4D4D'
      />
      <path
        d='M19.4979 3.26367H16.3711V9.43782H17.693V6.9818H19.3601V5.83781H17.693V4.40766H19.4979V3.26367Z'
        fill='#4D4D4D'
      />
      <path
        d='M30.3974 24.2927C30.3875 24.1745 30.2792 22.7444 27.7346 22.8034C25.1999 22.8625 24.5783 23.0207 24.5783 23.0207C24.5783 23.0207 22.6848 21.0977 21.9943 19.6084C21.9943 19.6084 22.8324 17.1524 22.7931 15.614C22.7537 14.0755 22.3888 13.1875 21.2054 13.1973C20.022 13.2072 19.854 14.2429 20.0023 15.7813C20.1402 17.1623 20.8602 18.7893 20.8602 18.7893C20.8602 18.7893 20.3173 20.4859 19.5875 22.1819C18.8675 23.8785 18.3746 24.7659 18.3746 24.7659C18.3746 24.7659 15.9284 25.5843 14.873 26.5708C13.8177 27.5573 13.3838 28.3167 13.9358 29.076C14.4188 29.7271 16.0958 29.8748 17.6047 27.9025C19.1038 25.9302 19.7844 24.697 19.7844 24.697C19.7844 24.697 22.0822 24.0656 22.7923 23.8982C23.5025 23.7309 24.3702 23.5924 24.3702 23.5924C24.3702 23.5924 26.4613 25.7031 28.4827 25.6244C30.5063 25.5456 30.4072 24.4115 30.3974 24.2927ZM14.4589 28.7413C13.2066 27.9918 17.0921 25.6736 17.8023 25.5949C17.8023 25.5949 15.7808 29.5302 14.4589 28.7413ZM20.4362 15.2287C20.4362 14.0059 20.8306 13.6804 21.1365 13.6804C21.4423 13.6804 21.7876 13.828 21.7974 14.8834C21.8073 15.9388 21.1365 18.0102 21.1365 18.0102C20.9094 17.7634 20.4362 16.4423 20.4362 15.2287ZM22.0632 23.5832C20.8004 23.8891 20.1697 24.2146 20.1697 24.2146C20.1697 24.2146 20.1697 24.2146 20.6823 23.0608C21.1948 21.907 21.7278 20.3284 21.7278 20.3284C22.438 21.6602 23.8583 23.2281 23.8583 23.2281C23.8583 23.2281 23.326 23.2675 22.0632 23.5832ZM25.0916 23.4742C25.0916 23.4742 29.1943 22.7345 29.1943 24.1352C29.1943 25.5259 26.6497 24.9536 25.0916 23.4742Z'
        fill='#DC1D00'
      />
      <path d='M27.8828 0.0976562V7.65274H35.1327L27.8828 0.0976562Z' fill='#949494' />
      <path d='M27.9805 0V7.55508H35.2297L27.9805 0Z' fill='#F1F1F1' />
      <path
        d='M8.38328 6.10516C8.34391 6.10516 8.30453 6.10516 8.26516 6.10516V4.2018H8.51195C8.79813 4.2018 8.97531 4.29039 9.10399 4.43875C9.26149 4.61594 9.34094 4.93164 9.34094 5.15875C9.34094 5.47445 9.34094 5.76063 9.05477 5.96735C8.90641 6.07563 8.65961 6.10516 8.38328 6.10516ZM8.63008 3.15625C8.62024 3.15625 8.60055 3.15625 8.5907 3.15625C8.46274 3.15625 8.37344 3.15625 8.32422 3.15625H6.95312V9.33039H8.275V7.24914L8.57102 7.26883C8.87688 7.26883 9.1532 7.19992 9.3993 7.12117C9.6461 7.04242 9.85281 6.90391 10.0307 6.74641C10.2079 6.58891 10.3759 6.39133 10.4645 6.16422C10.6023 5.81899 10.6319 5.33594 10.6023 5.00055C10.563 4.66516 10.5531 4.38883 10.4547 4.17227C10.3563 3.955 10.2276 3.77781 10.0799 3.64C9.92242 3.50219 9.75438 3.40305 9.57719 3.33414C9.38945 3.26523 9.22211 3.22586 9.04492 3.19633C8.88672 3.16609 8.7482 3.15625 8.63008 3.15625Z'
        fill='#FFFEFE'
      />
      <path
        d='M12.8724 8.20617C12.833 8.20617 12.7937 8.20617 12.7445 8.20617V4.27078C12.7543 4.27078 12.7543 4.27078 12.7641 4.27078C13.0306 4.27078 13.366 4.27078 13.5334 4.40859C13.7105 4.54641 13.8491 4.7243 13.9475 4.92117C14.0459 5.12859 14.1148 5.355 14.1247 5.6018C14.1345 5.88797 14.1247 6.11438 14.1247 6.31195C14.1247 6.49969 14.1247 6.73594 14.0853 6.97289C14.0459 7.20985 13.9672 7.41656 13.868 7.62399C13.7598 7.82157 13.572 7.95938 13.4047 8.08735C13.257 8.17664 13.0791 8.20617 12.8724 8.20617ZM13.1087 3.14648C12.9709 3.14648 12.8225 3.15633 12.7339 3.15633C12.5666 3.16617 12.4674 3.16617 12.428 3.16617H11.4219V9.34032H12.6052C13.1277 9.34032 13.5622 9.26157 13.9271 9.11321C14.292 8.96555 14.5782 8.74828 14.8046 8.4818C15.0219 8.21531 15.1892 7.88977 15.2877 7.515C15.3861 7.14024 15.4353 6.73594 15.4353 6.29227C15.4353 5.71992 15.4353 5.27625 15.327 4.84242C15.2286 4.44797 15.0212 4.13227 14.8046 3.89531C14.5972 3.65836 14.3609 3.49102 14.1141 3.38273C13.8673 3.27445 13.6311 3.195 13.404 3.15563C13.3063 3.14648 13.2078 3.14648 13.1087 3.14648Z'
        fill='#FFFEFE'
      />
      <path
        d='M19.4002 3.16602H16.2734V9.34016H17.5953V6.88414H19.2617V5.74016H17.5953V4.31H19.4002V3.16602Z'
        fill='#FFFEFE'
      />
    </svg>
  );
}
