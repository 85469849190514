import { IconButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import VendorAPI from '../../../../../api/vendorApi';
import { toast } from '../../../../../components/Toast/CustomToast';
import { IFile, UserStateModel } from '../../../../../models/UserModel';
import { actionCreators } from '../../../../../state';
import { RootState } from '../../../../../state/reducers';
import Tootltip from '../../../../../components/BarData/Tootltip';

const MAX_FILE_SIZE_BYTES = 20 * 1024 * 1024; // 20 MB in bytes

function FileAttach() {
  const dispatch = useDispatch();
  const fileInputRef: any = useRef(null);
  const [current, setCurrent] = useState({} as File);
  const { attachments }: UserStateModel = useSelector((state: RootState) => state.userState);
  const { setAttachments } = bindActionCreators(actionCreators, dispatch);
  const [loader, setLoader] = useState<boolean>(true);
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) {
      return;
    }

    const newFile = files[0];
    if (
      attachments?.find((data: IFile) => {
        return data?.name === newFile?.name;
      })
    ) {
      toast.error('The file with the same name has already been uploaded.');
      return;
    }

    const fileListArray: File[] = Array.from(files);
    const invalidFiles = fileListArray.filter(
      (file: File) => file.size === 0 || file.size > MAX_FILE_SIZE_BYTES
    );
    if (invalidFiles.length > 0) {
      toast.error('File Size should be in between 0 to 20 MB.');
      clearFileInput();
      return;
    }

    if (fileListArray.length > 0) {
      setLoader(true);
      const formData = new FormData();
      formData.append('file', fileListArray[0]);
      setCurrent(fileListArray[0]);
      setLoader(true);
      VendorAPI.addDisputeDoc(formData).then((res: any) => {
        setLoader(false);
        if (res.status === 201 || res.status === 200) {
          const file: IFile = res.data.data;
          setAttachments([file, ...attachments]);
          setCurrent({} as File);
          clearFileInput();
        } else {
          toast.error('Something went wrong');
        }
      });
    }
  };
  const clearFileInput = () => {
    if (fileInputRef?.current) {
      fileInputRef.current.value = '';
    }
  };
  return (
    <div className='flex flex-col justify-end w-full p-2 relative'>
      {attachments.length > 0 ? (
        <>
          {attachments.length > 0 && (
            <p className='text-primary font-bold'>Selected Files ({attachments?.length})</p>
          )}
          <div className='flex flex-col gap-2 h-[5rem] overflow-auto pr-2 w-full'>
            {current?.name && loader && (
              <FileData
                file={{
                  name: current?.name,
                  url: '',
                  file_type: '',
                }}
                type='loader'
              />
            )}
            {attachments.map((file) => (
              <FileData file={file} key={file?.url || file?.name || ''} />
            ))}
          </div>
        </>
      ) : (
        <div className='h-[6.4rem]'></div>
      )}
      <label>
        <input ref={fileInputRef} type='file' onChange={handleFileUpload} hidden />
        <div className='bg-primary-light flex w-full gap-2 justify-center items-center h-[2.5rem] rounded-[0.5rem] text-primary text-[0.9rem] font-bold cursor-pointer mt-[0.1rem]'>
          <i className='pi pi-image text-[1.1rem]'></i>
          Upload Files
        </div>
      </label>
    </div>
  );
}

const FileData = ({ type, file }: { type?: string; file: IFile }) => {
  const dispatch = useDispatch();
  const { attachments }: UserStateModel = useSelector((state: RootState) => state.userState);
  const { setAttachments } = bindActionCreators(actionCreators, dispatch);
  const onRemove = (file: IFile) => {
    VendorAPI.deleteDisputeDoc({ url: file.url }).then((res: any) => {
      if (res.status === 201 || res.status === 200) {
        setAttachments(attachments.filter((ele) => ele.url !== file.url));
      } else {
        toast.error('something went wrong');
      }
    });
  };
  return (
    <div className='flex items-center justify-start  gap-2 w-full h-[1.5rem]' key={file.url}>
      <Tootltip title={file.name} placement={'bottom'} style={{ zIndex: 10 }}>
        <p className='max-w-[13rem] text-ellipsis overflow-hidden whitespace-nowrap'>{file.name}</p>
      </Tootltip>
      {type === 'loader' ? (
        <CircularProgress size={'1rem'} />
      ) : (
        <IconButton size={'small'} onClick={() => onRemove(file)}>
          <i className='pi pi-times-circle hover:text-[1.1rem] text-red-400 hover:shadow-md hover:text-[#C55] rounded-full'></i>
        </IconButton>
      )}
    </div>
  );
};

export default FileAttach;
