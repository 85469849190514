import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import ModalForm from '../../../components/ModalForm';
import OverlayContainer from '../../../components/OverlayContainer';
import { actionCreators } from '../../../state';
import { RootState } from '../../../state/reducers';
import useFetchSingleRequest, { CheckisSameID } from '../../hooks/csr/useFetchSingleRequest';
import WorkProofData from '../../csr/bulkInfoModal/components/WorkProofData';
import useFetchCheckList from '../../hooks/useFetchCheckList';
import RejectBox from '../PrimaryComponents/requestComponents/RejectBox';
import useFetchVehicleChecklist from '../../hooks/useFetchVehicleChecklist';
import {
  LeftSection,
  PropsComponent,
  RequestDetailHeader,
  RightTopSection,
} from './SingleRequestComps';

export const RequestDetail = ({
  handleClose,
  item,
  type,
  body5,
  body6,
  status,
  refetch,
  fullbody2,
}: any) => {
  const dispatch = useDispatch();
  const { setAttachments } = bindActionCreators(actionCreators, dispatch);
  const { checkList, getCheckList } = useFetchCheckList();
  const { user }: any = useSelector((state: RootState) => state.userState);
  const [rejecting, setRejecting] = useState(false);
  const { vehicleChecklist, getVehicleCheckList } = useFetchVehicleChecklist();
  useEffect(() => {
    if (item) {
      getCheckList(item, true);
      getVehicleCheckList(item);
    }
  }, [item?.id]);
  const updatedItem = useMemo(
    () => ({
      ...item,
      ...vehicleChecklist,
      setRejecting,
      refetch,
      rejecting,
      handleClose,
      ...(!CheckisSameID(user, item) && { viewOnly: true }),
    }),
    [item, vehicleChecklist]
  );
  const { handleAssignVednor }: any = useFetchSingleRequest();
  const [workProofData, setWorkProofData] = useState(false as boolean);
  const headerprops = { item, updatedItem, handleClose, refetch };
  const topRightProps = { item, updatedItem, checkList, user, setRejecting, handleAssignVednor };
  const propsComponent = { item, updatedItem, type, fullbody2, body5, rejecting };
  const bottomBar = () => {
    return (
      <>
        {body6 && (
          <div className='w-full text-center flex flex-col gap-3'>
            {body6.length > 0 &&
              body6.map((elem: any) => (typeof elem === 'string' ? elem : elem(item)))}
          </div>
        )}
      </>
    );
  };
  return (
    item && (
      <OverlayContainer show={true}>
        <ModalForm
          title={'Add CSR'}
          onClose={() => {
            handleClose();
            setAttachments([]);
          }}
          width={'w-[59vw] '}
          noLabel>
          {workProofData ? (
            <WorkProofData
              data={{
                checklistData: checkList || {},
                item: item,
              }}
              setWorkProofData={setWorkProofData}
            />
          ) : (
            <div className='flex flex-col gap-3 h-full pb-2'>
              <RequestDetailHeader {...{ ...headerprops }} />
              <div className='divide-x-2 divide-slate-100 flex h-full'>
                <section className='w-1/2 mr-3 flex flex-col justify-between h-[37.2rem] '>
                  <LeftSection
                    item={item}
                    checkList={checkList}
                    updatedItem={updatedItem}
                    setWorkProofData={setWorkProofData}
                  />
                </section>
                <section className='w-1/2 pl-3 h-[37rem]'>
                  <div className='flex flex-col justify-between h-full overflow-auto'>
                    {rejecting ? (
                      <div className='bg-primary-light p-1 rounded-lg'>
                        <RejectBox item={updatedItem} />
                      </div>
                    ) : (
                      <RightTopSection {...{ ...topRightProps }} />
                    )}
                    {!updatedItem?.viewOnly && <PropsComponent {...{ ...propsComponent }} />}
                  </div>
                </section>
              </div>
              {bottomBar()}
            </div>
          )}
        </ModalForm>
      </OverlayContainer>
    )
  );
};
