import EditIcon from '../../../../../assets/EditIcon';
import { Button, TextField } from '@mui/material';
import Tootltip from '../../../../../components/BarData/Tootltip';

export function ShowTotalTax({ props }: { props?: any }) {
  const { services, showEdit, setShowEdit } = props;
  const subtotal = services
    .reduce((acc: number, service: any) => {
      const totalPrice = Number(service.rate || 0) * (service.qty || 0);
      return acc + totalPrice;
    }, 0)
    .toFixed(2);

  const taxRate = Number(showEdit?.defaultValue) / 100;
  const estimatedTotal = services
    .reduce((acc: number, service: any) => {
      const totalPriceWithoutTax = Number(service.rate || 0) * (service.qty || 0);
      const taxAmount = service.tax ? totalPriceWithoutTax * taxRate : 0;
      const totalPriceWithTax = totalPriceWithoutTax + taxAmount;
      return acc + totalPriceWithTax;
    }, 0)
    .toFixed(2);
  const handleValueChange = (event: any) => {
    const newValue = event.target.value;
    const isValidInput = /^(\d{1,2}(\.\d{0,3})?)?$/.test(newValue);
    if (newValue === '' || isValidInput) {
      setShowEdit({
        ...showEdit,
        value: newValue,
      });
    }
  };
  return (
    <div className='flex flex-col gap-3 mt-[-0.5rem]'>
      <p className='flex justify-between'>
        <span>Sub Total</span>
        <Tootltip title={Number(subtotal)?.toFixed(2)} placement={'bottom'}>
          <p className='max-w-[6rem] text-ellipsis overflow-hidden'>
            <span>
              <span className='font-bold text-gray-400 mr-1'>$</span>
              {Number(subtotal)?.toFixed(2)}
            </span>
          </p>
        </Tootltip>
      </p>
      <p className='flex justify-between border-b-[0.07rem] border-b-gray-300'>
        {showEdit.show ? (
          <div className=' flex justify-between w-full'>
            <TextField
              size='small'
              label='Tax Percent'
              className='w-full my-1 '
              value={showEdit.value}
              onChange={(e) => {
                handleValueChange(e);
              }}
            />
            <div className='flex items-center'>
              <Button
                onClick={() => {
                  setShowEdit({ ...showEdit, show: false, defaultValue: showEdit.value });
                }}>
                Save
              </Button>
              <Button
                onClick={() => {
                  setShowEdit({ ...showEdit, show: false });
                }}>
                Cancel
              </Button>
            </div>
          </div>
        ) : (
          <>
            <span className='flex gap-2 items-center'>
              Sales tax (NYC {showEdit.defaultValue}
              %)
              <p
                className='flex items-center cursor-pointer'
                onClick={() => {
                  setShowEdit({
                    ...showEdit,
                    show: true,
                    value: showEdit?.defaultValue,
                  });
                }}>
                <EditIcon height='1rem' width='1rem' />
              </p>
            </span>
            <span>
              <Tootltip title={Number(estimatedTotal - subtotal).toFixed(2)} placement={'bottom'}>
                <p className='max-w-[6rem] text-ellipsis overflow-hidden'>
                  <span>
                    <span className='font-bold text-gray-400 mr-1'>$</span>
                    {(estimatedTotal - subtotal).toFixed(2)}
                  </span>
                </p>
              </Tootltip>
            </span>
          </>
        )}
      </p>
      <p className='flex justify-between'>
        <span>Estimated Total</span>
        <span>
          <Tootltip title={Number(estimatedTotal).toFixed(2)} placement={'bottom'}>
            <p className='max-w-[6rem] text-ellipsis overflow-hidden'>
              <span>
                <span className='font-bold text-gray-400 mr-1'>$</span>
                {Number(estimatedTotal).toFixed(2)}
              </span>
            </p>
          </Tootltip>
        </span>
      </p>
    </div>
  );
}
