import { Button } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import moment from 'moment';
import { useMemo, useState } from 'react';
import Modal from '../../../../../components/OverlayContainer/Model';
import TimePicker from '../../../../../components/Picker/Timepicker';
import { v4 as uuidv4 } from 'uuid';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../../state';
import { useDispatch } from 'react-redux';

const commonid = uuidv4();
function DateTime({
  data,
  handleClose,
  singleSchedule,
  setBulkVehicles,
  bulkVehicles,
  addForSingleReq,
  setSingleSchedule,
}: any) {
  const dispatch = useDispatch();
  const [date, setDate] = useState(null);
  const [time, setTime] = useState({ hr: '00', min: '00', period: 'AM' } as TimeType);
  const { hr, min, period } = time;
  const isInCorrect = `${hr}:${min} ${period}`.startsWith('00:');
  const isEditMode = useMemo(() => {
    return Object.keys(data?.info || {}).includes('edit');
  }, [data]);
  const { setAppointment } = bindActionCreators(actionCreators, dispatch);

  return (
    <Modal heightClass={'h-auto'} widthclass={'w-auto'} handleClose={handleClose} noLabel>
      <div className='flex flex-col gap-5 items-center justify-center'>
        <p className='font-bold text-primary'>{isEditMode ? 'Edit' : 'Set'} Slot</p>
        <div className='flex gap-6'>
          <div className='flex flex-col'>
            <p className='text-primary mb-5'>Select Date</p>
            <div className='border-border-color border-[0.15rem] overflow-hidden rounded-xl'>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  disablePast
                  onChange={(date: any) => {
                    setDate(date.$d);
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className='flex flex-col'>
            <p className='text-primary mb-5'>Select Time</p>
            <TimePickerBtn
              time={time}
              value={`${hr}:${min} ${period}`}
              isInCorrect={isInCorrect}
              onChange={(time: any) => {
                setTime(time);
              }}
            />
          </div>
        </div>
        <Button
          variant='contained'
          disabled={!!(isInCorrect || !date)}
          className='px-10 font-bold'
          onClick={() => {
            if (addForSingleReq) {
              setSingleSchedule({
                id: commonid,
                date: moment(date).format('DD MMM YYYY'),
                time: `${hr}:${min} ${period}`,
              });
              setAppointment({
                id: commonid,
                date: moment(date).format('DD MMM YYYY'),
                time: `${hr}:${min} ${period}`,
              });
              handleClose();
            } else {
              setBulkVehicles(
                bulkVehicles.map((obj: any) => {
                  if (obj.id === data.info.id || singleSchedule) {
                    const tempObj = {
                      ...obj,
                    };
                    const newtime: any = {
                      date: moment(date).format("DD MMM'YY"),
                      time: `${hr}:${min} ${period}`,
                    };

                    let newtimeArr = [];
                    if (isEditMode) {
                      newtime['id'] = data.info.id;
                      const previousArr = tempObj['timeSlots'];
                      previousArr[data.info.edit] = newtime;
                      newtimeArr = previousArr;
                    } else {
                      newtime['id'] = !singleSchedule ? uuidv4() : commonid;
                      tempObj['customAdded'] = true;
                      newtimeArr = [...(tempObj['timeSlots'] ? tempObj['timeSlots'] : []), newtime];
                    }
                    tempObj['timeSlots'] = newtimeArr;

                    return tempObj;
                  }
                  return obj;
                })
              );
              handleClose();
            }
          }}>
          Save
        </Button>
      </div>
    </Modal>
  );
}

export default DateTime;

interface TimeType {
  hr: string;
  min: string;
  period: string;
}
function TimePickerBtn({
  time,
  onChange,
  value,
  isInCorrect,
}: {
  value: string;
  time: TimeType;
  onChange: ({ time }: { time: TimeType }) => void;
  isInCorrect: boolean;
}) {
  return (
    <div className='flex flex-col gap-5'>
      <div
        className={`py-2 flex flex-row ${
          isInCorrect ? 'bg-rose-50' : 'bg-gray-50'
        }  items-center gap-4 rounded-xl px-4 py-4 transition duration-500 h-fit`}>
        <div className='w-full'>
          <div className='flex gap-1 items-center'>
            <p className='text-lg  font-bold'>{value}</p>
          </div>
        </div>
      </div>
      <TimePicker
        time={time}
        setTime={(value: any) => {
          onChange(value);
        }}
      />
    </div>
  );
}
