
export function stringToColor(string: string) {
  if (!string) return null;

  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color:string= '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name: string) {
  if (!name) return "NA";
  const splitted = name.split(' ');
  if (splitted.length >= 2)
    return `${splitted[0][0] || ""}${splitted[1][0] || ""}`
  else {
    return name.split(' ')[0][0];
  }
}