import axios from 'axios';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import OnTheWayIcon from '../../../assets/OnTheWayIcon';
import ModalForm from '../../../components/ModalForm';
import OverlayContainer from '../../../components/OverlayContainer';
import { toast } from '../../../components/Toast/CustomToast';
import useFetchSingleRequest from '../../hooks/csr/useFetchSingleRequest';
import DirectionsMap from '../../location/RoutingMap';
import { apiKey } from '../../location/ApiKey';
import CsrAPI from '../../../api/csrApi';

async function geocodeAddress(address: string) {
  try {
    const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
      params: {
        address,
        key: apiKey,
      },
    });
    const { results } = response.data;
    if (results && results.length > 0) {
      const location = results[0].geometry.location;
      const lat = location.lat;
      const lng = location.lng;
      return { lat, lng };
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
}
function getStatusMessage(status: number) {
  return status === 1 ? ' is on the way towards station' : ' has reached at service location';
}
function TrackLocation({ item, hide, refetch }: { item: any; hide?: boolean; refetch?: any }) {
  const [showLocation, setShowLocation] = useState(false);
  return (
    <div>
      {+item.status_id === 5 && item?.sub_stage && (
        <div className='pt-3' style={{ borderTop: '1px solid #B6B6B6' }}>
          <p className='flex items-center'>
            <div className='mr-2 capitalize'>
              {+item?.sub_stage === 1 ? (
                <OnTheWayIcon />
              ) : (
                <i
                  className={`pi pi-map-marker text-primary
            `}></i>
              )}
            </div>
            <p className=''>
              Technician <span className='capitalize'>{item?.technician?.name || '----'}</span>
              {getStatusMessage(+item?.sub_stage)}
            </p>
          </p>
          {+item?.sub_stage === 1 && !hide && (
            <p
              className='flex items-center text-primary mt-2 cursor-pointer font-bold hover:bg-primary-light rounded-lg w-fit px-2 py-1 ml-[-0.6rem]'
              onClick={() => {
                setShowLocation(!showLocation);
              }}>
              <i className='pi pi-map-marker mr-1 text-[1.2rem]'></i> Track Live Location
            </p>
          )}
          {showLocation && (
            <Model
              handleShowLocation={() => {
                setShowLocation(!showLocation);
              }}
              refetch={refetch}
              item={item}
            />
          )}
        </div>
      )}
    </div>
  );
}

const getAddress = async (data: any) => {
  const info: any = await CsrAPI.getRemarks({
    id: data?.preventive_request_id || data?.[0]?.id,
  });
  const totalRemarks = [...info?.data?.data];
  const savedData =
    totalRemarks
      ?.slice()
      ?.sort((a: any, b: any) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf())
      .find((el: any) =>
        ['service_location', 'schedule_appointment_bulk_location'].includes(el.remark_source)
      )?.remark || '';

  if (typeof savedData === 'string') {
    if (savedData) {
      const coordinates = await geocodeAddress(savedData);
      return {
        address: savedData,
        coordinates,
      };
    }
  } else {
    return {
      address: savedData?.locationInfo,
      coordinates: savedData?.coordinates,
    };
  }
};

export default TrackLocation;

const calculateNewTime = (durationInMinutes: any) => {
  const duration = moment.duration(durationInMinutes, 'minutes');
  const newTime = moment().add(duration);
  return newTime;
};

export const Model = ({
  handleShowLocation,
  item,
  refetch,
}: {
  handleShowLocation: any;
  item: any;
  refetch?: any;
}) => {
  const [routeData, setRouteData] = useState<any>(null);
  const [destinationAddress, setDestinationAddress] = useState<any>({});
  const { fetchRequestData }: any = useFetchSingleRequest();
  const [pathDetail, setPath] = useState(null as any);

  useEffect(() => {
    if (item) {
      fetchRequestData(item).then((res: any) => {
        if (res) {
          setRouteData(res);
        }
      });
    }
  }, [item?.id]);
  const remakrs = useMemo(() => {
    const info: any = CsrAPI.getRemarks({
      id: item?.preventive_request_id || item?.[0]?.id,
    });
    return info?.length > 0 ? info?.data?.data : null;
  }, [routeData]);
  useEffect(() => {
    if (routeData) {
      getAddress(routeData).then((data) => {
        setDestinationAddress(data);
      });
    }
  }, [routeData]);

  const handleCopyNumber = () => {
    const phoneNumber = routeData.technician?.phone;
    navigator.clipboard.writeText(phoneNumber);
    toast.success('Phone number copied to clipboard');
  };

  const startedLocation = useMemo(() => {
    return (
      remakrs
        ?.slice()
        ?.sort((a: any, b: any) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf())
        .find((el: any) => ['navigation_start'].includes(el.remark_source)) || ''
    );
  }, [remakrs]);

  const requestDetails = useMemo(
    () => ({ startedLocation, destinationAddress, ...routeData }),
    [startedLocation, routeData, destinationAddress]
  );

  const closeAndUpdate = () => {
    handleShowLocation?.();
    refetch?.();
  };
  return (
    <OverlayContainer show={true}>
      <ModalForm width={'w-[63rem]'} onClose={handleShowLocation}>
        <div className='w-full'>
          <div className='px-[2rem] capitalize'>
            <div
              className='flex items-center px-3 pt-[-1.2rem] cursor-pointer z-500'
              onClick={handleShowLocation}>
              <i className='pi pi-arrow-left text-primary mr-3 text-[1.5rem]'></i>
              <p className='text-[1.5rem]'>Live Location Tracker</p>
            </div>
            <div>
              {!pathDetail && (
                <p className='text-center text-green-600'>Fetching technician location ...</p>
              )}
            </div>
            <div>
              <div className='flex justify-between gap-4 p-4 w-full'>
                <div className='w-1/2 capitalize'>
                  <p className='text-primary text-[0.9rem] font-bold'>Technician</p>
                  <p>{routeData?.technician?.name || '----'}</p>
                </div>
                <div className='w-1/2'>
                  <p className='text-primary text-[0.9rem] font-bold'>Contact Techinican</p>
                  <p className='flex items-center'>
                    <p>{routeData?.technician?.phone || '----'}</p>
                    <i
                      className='pi pi-copy mr-1 text-primary text-[1.2rem] cursor-pointer hover:bg-primary hover:text-[#ffffff] rounded-lg w-fit px-1 py-1 ml-1 mb-1'
                      onClick={handleCopyNumber}></i>
                  </p>
                </div>
              </div>
              <div className='flex justify-between gap-4 px-4 pb-4'>
                <div className='w-1/2'>
                  <p className='text-primary text-[0.9rem] font-bold'>
                    Started {moment(startedLocation?.created_at).format('LT')}
                  </p>
                  <p>{startedLocation?.remark?.start_location_details || 'Not Available'}</p>
                </div>
                <div className='w-1/2'>
                  <p className='text-primary text-[0.9rem] font-bold'>
                    To Reach
                    {pathDetail?.duration &&
                      ` ${moment(calculateNewTime(pathDetail?.duration || 12)).format(
                        'LT'
                      )} ETA (${calulateDuration(pathDetail?.duration)})`}{' '}
                    {}
                  </p>
                  <p className='max-h-[3rem] max-w-full overflow-auto'>
                    {destinationAddress?.address}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='flex justify-center h-[30rem] rounded-b-[2rem]'>
              <DirectionsMap
                item={requestDetails}
                pathDetail={pathDetail}
                setPath={setPath}
                closeAndUpdate={closeAndUpdate}
              />
            </div>
          </div>
        </div>
      </ModalForm>
    </OverlayContainer>
  );
};

const calulateDuration = (durationInMinutes: any) => {
  // Convert minutes to moment duration
  const duration = moment.duration(durationInMinutes, 'minutes');

  // Calculate hours and remaining minutes
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  // Format the duration in the desired way
  let formattedDuration = '';

  if (hours > 0) {
    formattedDuration += `${hours} hour${hours > 1 ? 's' : ''}`;
  }

  if (minutes > 0) {
    if (formattedDuration !== '') {
      formattedDuration += ' and ';
    }
    formattedDuration += `${minutes} minute${minutes > 1 ? 's' : ''}`;
  }

  return formattedDuration;
};
