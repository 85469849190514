const SVG = (props: { height?: string; width?: string; active?: boolean }) => {
  const height = props?.height || '1.5rem';
  const width = props?.width || '1.5rem';

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g id='Group 39659'>
        <g id='5'>
          <path
            id='Vector'
            d='M19.7274 2.08704C18.7151 2.08704 17.7256 2.3872 16.884 2.94958C16.0423 3.51195 15.3863 4.31128 14.999 5.24647C14.6116 6.18167 14.5102 7.21073 14.7077 8.20353C14.9052 9.19632 15.3926 10.1083 16.1084 10.824C16.8242 11.5398 17.7361 12.0272 18.7289 12.2247C19.7217 12.4222 20.7508 12.3208 21.686 11.9335C22.6212 11.5461 23.4205 10.8901 23.9829 10.0485C24.5452 9.20681 24.8454 8.2173 24.8454 7.20505C24.8454 6.53294 24.713 5.86742 24.4558 5.24647C24.1986 4.62552 23.8216 4.06132 23.3464 3.58607C22.8711 3.11082 22.3069 2.73383 21.686 2.47662C21.065 2.21942 20.3995 2.08704 19.7274 2.08704ZM19.7274 11.2995C18.9176 11.2995 18.126 11.0593 17.4527 10.6094C16.7793 10.1595 16.2545 9.52007 15.9446 8.77191C15.6348 8.02376 15.5537 7.20051 15.7117 6.40627C15.8696 5.61203 16.2596 4.88248 16.8322 4.30986C17.4048 3.73725 18.1344 3.3473 18.9286 3.18931C19.7228 3.03133 20.5461 3.11241 21.2943 3.42231C22.0424 3.7322 22.6819 4.25699 23.1318 4.93032C23.5817 5.60364 23.8218 6.39525 23.8218 7.20505C23.8218 8.29096 23.3904 9.33239 22.6226 10.1002C21.8547 10.8681 20.8133 11.2995 19.7274 11.2995Z'
            fill='#4378C8'
          />
          <path
            id='Vector_2'
            d='M19.7281 4.1344C19.5923 4.1344 19.4622 4.18832 19.3662 4.2843C19.2702 4.38028 19.2163 4.51046 19.2163 4.6462V6.99537L17.8293 8.37723C17.7757 8.42311 17.7322 8.47958 17.7015 8.54307C17.6708 8.60657 17.6535 8.67573 17.6508 8.74621C17.648 8.8167 17.6599 8.88699 17.6857 8.95266C17.7114 9.01834 17.7504 9.07799 17.8003 9.12787C17.8502 9.17774 17.9098 9.21677 17.9755 9.2425C18.0412 9.26823 18.1115 9.28011 18.1819 9.27739C18.2524 9.27467 18.3216 9.2574 18.3851 9.22668C18.4486 9.19596 18.505 9.15245 18.5509 9.09887L20.0863 7.56347C20.1828 7.46897 20.2379 7.34021 20.2399 7.20521V4.6462C20.2399 4.51046 20.186 4.38028 20.09 4.2843C19.994 4.18832 19.8638 4.1344 19.7281 4.1344Z'
            fill='#4378C8'
          />
        </g>
        <path
          id='Vector_3'
          d='M11.9652 4.49993C11.9652 2.09097 9.88028 0 7.47826 0C5.07624 0 2.9913 2.09097 2.9913 4.49993C2.9913 6.90889 5.07624 8.99986 7.47826 8.99986C9.88028 8.99986 11.9652 6.90889 11.9652 4.49993ZM7.47826 10.4998C3.54769 10.4998 0 14.7523 0 20.0367C0 25.3211 14.9565 25.3211 14.9565 20.0367C14.9565 14.7523 11.4088 10.4998 7.47826 10.4998Z'
          fill='#4378C8'
        />
      </g>
    </svg>
  );
};
export default SVG;
