import VanType from '../vender/PrimaryComponents/requestComponents/vehicle/VanType';
import MilageListExpand from './MilageListExpand';

export const vehicleInfo = [
  (item: any) => (
    <div className='w-full'>
      <div className='w-full flex justify-between pl-[0.5rem]'>
        <div className='text-[1.4rem]' style={{ color: 'black' }}>
          {item?.vehicle?.name}
          <div className='text-[#A0A0A0] text-[1.3rem]'>{item?.vehicle?.vin}</div>
        </div>

        <div className='flex justify-right items-end mb-[1rem]'>
          <div className='text-wrap text-[#4378C8] rounded-[0.5rem] border-[0.1rem] border-primary px-[1rem] py-[0.5rem] '>
            <p className='text-ellipsis whitespace-nowrap w-full '>
              {item?.vehicle?.plate || '----'}
            </p>
          </div>
        </div>
      </div>
      {/* Van Type Component */}
      <VanType item={item} />
      <MilageListExpand item={item} variant='expandDown' />
    </div>
  ),
];
