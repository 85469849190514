import { Zoom } from '@mui/material';
import { createTheme } from '@mui/material/styles';
export const outerTheme = createTheme({
  palette: {
    primary: {
      main: '#4378C8',
      light: 'rgba(67, 120, 200, 0.12)',
    },
    secondary: {
      main: '#F5F5F5',
    },
    error: {
      main: '#C55',
      light: '#eec3c3',
    },
  },
  typography: {
    fontFamily: 'inherit',
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '.MuiInputBase-root': {
            // fontSize: "1.2rem",
            // '& fieldset': {
            //   borderColor: "rgba(221, 221, 221, 0.62)",
            // },
            // '&:hover fieldset': {
            //   borderColor: 'white',
            // },
            // '&.Mui-focused fieldset': {
            //   borderColor: 'yellow',
            // },
          },
          '& input': {
            // padding: "0.7rem 1.3rem",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          transition: 'transform 0.2s ease-in-out', // Add transition property
          transform: 'rotate(0deg)', // Initial rotation
        },
        iconOpen: {
          transform: 'rotate(180deg)', // Rotation when the select is open
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        TransitionComponent: Zoom,
      },
      styleOverrides: {
        paper: {
          minWidth: '7rem',
          // transitionDuration: "0ms !important",
          boxShadow: '0px 0px 9px -1px rgba(0, 0, 0, 0.25)',
          '& .MuiMenu-list': {
            padding: '0.2rem',
          },
          '& li:not(:last-child)': {
            marginBottom: '0.2rem',
          },
          '& li:not(:first-child)': {
            marginTop: '0.2rem',
          },
          '& li': {
            padding: '0.3rem 0.5rem',
            borderRadius: '0.5rem',
          },
          '& li:hover': {
            background: '#E8EEF8',
          },
          borderRadius: '0.5rem',
          '& .Mui-selected': {
            backgroundColor: '#E8EEF8 !important',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '0.5rem',
          textTransform: 'capitalize',
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        TransitionComponent: Zoom,
      },
      styleOverrides: {
        paper: {
          boxShadow: '0px 0px 9px -1px rgba(0, 0, 0, 0.25)',
          borderRadius: '0.5rem',
          // transitionDuration: "0ms !important",
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        TransitionComponent: Zoom,
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: 'white',
          color: 'black',
          boxShadow: '0px 0px 9px -1px rgba(0, 0, 0, 0.25)',
          maxHeight: '80vh',
          maxWidth: '30vw',
          overflow: 'auto',
          fontSize: '0.8rem',
          borderRadius: '0.5rem',
        },
        arrow: {
          // color: "white",
        },
      },
    },
  },
});
