import moment from 'moment-timezone';
import React from 'react';

interface Props {
  date: string;
}

const TimezoneExtractor: React.FC<Props> = ({ date }) => {
  const extractTimezone = (dateString: string): string => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let date = moment(dateString);
    return date.tz(timezone).format('z');
  };

  const timezone = extractTimezone(date);

  return <> {timezone}</>;
};

export default TimezoneExtractor;
