import * as React from 'react';
import moment from 'moment-timezone';
import Tootltip from '../../../../../components/BarData/Tootltip';
import 'dayjs/locale/en';
import OpenModel from '../../../openModel/OpenVendorModel';
import { ThreeDots } from 'react-loader-spinner';
import UsefetchDateRequest from './usefetchDateRequest';

export function DateDetails({
  data,
  selectedDate,
  refetch,
  isSameMonth,
  loader,
  ishighlighted,
}: any) {
  const [showModel, setShowModel] = React.useState<any>({ open: false, data: {} });
  const { loading, requests, fetchRequests } = UsefetchDateRequest({ selectedDate, ishighlighted });
  const load: boolean = loading || loader;
  return (
    <div className='w-[63%]  flex flex-col border-[0.1rem] border-gray-200 rounded-[0.5rem] p-2'>
      <p
        className='flex justify-center items-center text-primary border-b-[0.2rem] border-gray-200 h-[3rem] font-bold text-[1.1rem]'
        style={{ minHeight: '3rem' }}>
        {selectedDate ? (
          <>{moment(selectedDate?.toDate()).format('LL')}</>
        ) : (
          <span className='text-[0.9rem] font-normal text-gray-400'>Select a Date</span>
        )}
      </p>
      {load && (
        <div className='w-full h-full flex justify-center items-center '>
          <ThreeDots
            height='60'
            width='60'
            radius='9'
            color={'#4378C8'}
            ariaLabel='three-dots-loading'
            wrapperStyle={{}}
            visible={true}
          />
        </div>
      )}
      {!load && (
        <>
          <div className='p-1 px-2 flex flex-col gap-2 max-h-[23.5rem] overflow-auto mt-2'>
            {isSameMonth &&
              data?.date &&
              requests?.length > 0 &&
              [...requests]?.map((ele: any) => {
                const isAceepted: boolean =
                  (+ele.status_id === 4 || +ele.status_id === -1) && +ele?.sub_stage === 2;
                const isTechAssigned: boolean = +ele.status_id === 5 && !ele?.sub_stage;
                const parsedTime = moment(ele?.time, 'HH:mm:ss');
                const time = moment(parsedTime).format('LT');
                return (
                  <p
                    className='p-2 flex justify-between items-center border-[0.1rem] border-gray-200 rounded-[0.5rem] flex flex-col'
                    key={ele?.request_id || ele?.bulk_id || ''}>
                    <p className='w-full flex justify-between items-center rounded-[0.5rem] '>
                      <div className='flex items-center'>
                        <div className='flex items-center gap-3 border-r-[0.2rem] border-gray-300 p-1 w-[18rem]'>
                          <p className='text-primary text-[0.9rem] font-bold  px-1 flex items-start text-primary flex-col justify-start'>
                            <Tootltip
                              title={`Request ${ele?.request_id || ''}`}
                              placement={'bottom'}>
                              <p
                                onClick={() => {
                                  setShowModel({
                                    show: true,
                                    data: { ...ele, id: ele?.request_id },
                                  });
                                }}
                                className='max-w-[8rem] text-ellipsis overflow-hidden cursor-pointer'
                                style={{ whiteSpace: 'nowrap' }}>
                                Request {ele?.request_id || ''}
                              </p>
                            </Tootltip>
                            {ele?.vendor_name && (
                              <Tootltip title={`${ele?.vendor_name || ''}`} placement={'bottom'}>
                                <p
                                  onClick={() => {
                                    setShowModel({
                                      show: true,
                                      data: { ...ele, id: ele?.vendor_name },
                                    });
                                  }}
                                  className='max-w-[8rem] text-ellipsis overflow-hidden cursor-pointer text-[0.9rem] font-normal text-gray-400 flex justify-start'
                                  style={{ whiteSpace: 'nowrap' }}>
                                  {ele?.vendor_name || ''}
                                </p>
                              </Tootltip>
                            )}
                          </p>
                          <i className='pi pi-circle-fill text-[0.4rem] text-primary'></i>

                          <Tootltip title={`${ele?.vehicle || ''}`} placement={'bottom'}>
                            <p
                              className='max-w-[8rem] text-ellipsis overflow-hidden font-bold text-black text-[0.9rem] text-primary'
                              style={{ whiteSpace: 'nowrap' }}>
                              {ele?.vehicle || ''}
                            </p>
                          </Tootltip>
                        </div>
                        {isAceepted && (
                          <p className='flex gap-2 items-center text-[#51994F] p-1 text-[0.8rem] pl-3'>
                            <i className='pi pi-check-circle text-[0.8rem]'></i> Accepted
                          </p>
                        )}
                        {isTechAssigned && (
                          <p className='flex gap-2 items-center text-[#51994F] p-1 text-[0.8rem] pl-3'>
                            <i className='pi pi-check-circle text-[0.8rem]'></i> Technician Assigned
                          </p>
                        )}
                        {!isAceepted && !isTechAssigned && (
                          <p className='whitespace-nowrap p-1 text-[0.8rem] pl-3 '>Not Accepted</p>
                        )}
                      </div>
                      <p className='text-gray-400 text-[0.8rem]'>{time}</p>
                    </p>
                  </p>
                );
              })}
          </div>
          {isSameMonth && (!data?.date || requests?.length === 0) && (
            <div className='w-full h-full flex justify-center items-center gap-2 text-gray-500 '>
              <i className='pi pi-info-circle mt-0.5'></i>
              No active bookings!
            </div>
          )}
        </>
      )}
      {showModel?.show && (
        <OpenModel
          item={showModel.data}
          handleClose={() => setShowModel({ show: false, data: {} })}
          refetch={() => {
            refetch();
            fetchRequests();
          }}
        />
      )}
    </div>
  );
}
