import { request } from './base';

export default class TechAPI {
  static get(data: any) {
    return request({
      url: '/technicians/v1/all_technicians',
      method: 'get',
      data: data,
    });
  }

  static add(data: any) {
    return request({
      url: '/technicians/v1/signup_technician',
      method: 'post',
      data: data,
    });
  }

  static update(data: any) {
    return request({
      url: `/technicians/v1/technician_edit/${data.id}`,
      method: 'patch',
      data: data,
    });
  }

  static delete(data: any) {
    return request({
      url: `/technicians/v1/technician/${data.id}?email=${data.email}&phone=${data.phone}`,
      method: 'delete',
    });
  }
}
