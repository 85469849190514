import { SearchIcon } from '@heroicons/react/solid';
import { Checkbox, Divider } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import PreventiveAPI from '../../../../api/preventiveApi';
import HandleLoad from '../../../../components/Loader/HandleLoad';
import { toast } from '../../../../components/Toast/CustomToast';
import useFetchTechnician from '../../../hooks/useFetchTechnician';
import AddTechnician from '../../../technician/AddTechnician';
import ServiceLocation from '../../../csr/primaryComponents/ServiceLocation';
import UserDetail from '../../../../components/BarData/UserDetail';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../state/reducers';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state';
import OverlayContainer from '../../../../components/OverlayContainer';
import ModalForm from '../../../../components/ModalForm';
import { Technician } from './Technician';

const BulkAssignTechnician = ({ handleClose, item, fetchRequestData }: any) => {
  const dispatch = useDispatch();
  const { assignTechBulk, user }: any = useSelector((state: RootState) => state.userState);
  const { setAssignTechBulk } = bindActionCreators(actionCreators, dispatch);
  const [selectedTechnician, setSelectedTechnician] = useState<any>({});
  const [checkedItems, setCheckedItems] = useState([] as any[]);
  const [addTechnician, setAddTechnician] = useState(false);
  const [notes, setNotes] = useState('');
  const { technicians, handleSearch, fetchTechnicians } = useFetchTechnician();
  const loader = HandleLoad();
  const isSingleRequest: boolean = !item?.bulkSchedules && !assignTechBulk?.show;
  const firstItemofSchedules: any = assignTechBulk?.schedules?.[0] || [];
  const [assignTechinicanModal, setAssignTechinicanModal] = useState({
    open: false,
    data: {},
  });
  useEffect(() => {
    if (user?.role === 'fleetmax_partner') {
      fetchTechnicians(item);
    } else {
      fetchTechnicians();
    }
  }, [item, assignTechBulk]);
  const singleReqIds: any =
    checkedItems.length > 0
      ? checkedItems
          ?.filter((item) => !item.bulkSchedules)
          ?.map((item) => ({ preventive_request_id: item.preventive_request_id }))
      : [];

  const bulkIdsWithBulkSchedules: any =
    checkedItems.length > 0 &&
    checkedItems?.filter((item) => item.bulkSchedules)?.map((item) => parseInt(item.bulk_id));

  const handleSubmit = () => {
    loader(true);
    let payload: any;
    if (assignTechBulk.show) {
      payload = {
        ...(notes?.length > 0 && { note: notes }),
        technician_account_id: selectedTechnician.technician_account_id,
      };
      if (bulkIdsWithBulkSchedules.length > 0) {
        payload.bulk_ids = bulkIdsWithBulkSchedules;
      }
      if (singleReqIds.length > 0) {
        payload.single_requests = singleReqIds;
      }
    }
    if (!assignTechBulk.show) {
      if (isSingleRequest) {
        payload = {
          single_requests: [{ preventive_request_id: item?.preventive_request_id }],
          ...(notes?.length > 0 && { note: notes }),
          technician_account_id: selectedTechnician.technician_account_id,
        };
      } else {
        payload = {
          single_requests: singleReqIds,
          ...(notes?.length > 0 && { note: notes }),
          technician_account_id: selectedTechnician.technician_account_id,
        };
      }
    }
    payload.vendor_account_id = item?.vendor?.account_id;
    PreventiveAPI.assignTechniciansBulk(payload).then((res) => {
      if (res.status === 200) {
        fetchRequestData();
        handleClose();
        fetchTechnicians();
        setAssignTechBulk({ show: false, schedules: [] });
      } else {
        toast.error('Something Went Wrong');
      }
      loader(false);
    });
  };
  const bulkSchedules = useMemo(() => {
    let combinedSchedules = [];
    if (isSingleRequest) {
      combinedSchedules = item;
      return [combinedSchedules];
    }
    if (!assignTechBulk.show) {
      combinedSchedules = item?.bulkSchedules?.filter(
        (d: any) => d?.status_id === '4' && d?.sub_stage === '2'
      );
    }
    if (assignTechBulk.show) {
      combinedSchedules = assignTechBulk?.schedules;
    }
    return combinedSchedules;
  }, [item, assignTechBulk]);
  useEffect(() => {
    //making the item as checked in the case of singleRequest
    if (isSingleRequest) {
      setCheckedItems(bulkSchedules?.[0] || []);
    }
  }, [item]);
  useEffect(() => {
    if (assignTechBulk.show) {
      setCheckedItems(assignTechBulk?.schedules || []);
    }
  }, []);
  return (
    <>
      <OverlayContainer show={true} z='z-50'>
        <ModalForm
          title={'Add CSR'}
          onClose={handleClose}
          noLabel
          width={`${isSingleRequest ? 'w-[65vw]' : 'w-[75vw]'}`}>
          <>
            {isSingleRequest && (
              <p className='text-blue-500 font-semibold text-lg py-1.5 px-3'>
                Request {item?.id || item?.preventive_request_id}
              </p>
            )}
            <div className={`flex flex-col gap-5 h-[30rem] w-full`}>
              <div className='flex gap-4 h-0 flex-1'>
                {!isSingleRequest && (
                  <>
                    <div className='flex gap-2 h-full w-[20rem] flex-col py-1'>
                      <div>
                        <p className='text-blue-500 font-semibold text-lg py-1.5 px-3'>
                          {assignTechBulk.show ? (
                            <>Assign Technician</>
                          ) : (
                            <>{item?.title || item?.id}</>
                          )}
                        </p>
                      </div>
                      {assignTechinicanModal.open ? (
                        <>
                          <div className='overflow-y-auto flex-1'>
                            <div className='pr-4 grid grid-cols-1 gap-y-5 gap-x-60'>
                              {checkedItems.length > 0 &&
                                checkedItems.map((item: any) => {
                                  return (
                                    <div
                                      key={item.preventive_request_id}
                                      className={`px-2 p-2 border border-[0.1rem] border-slate-200 flex items-center rounded-lg cursor-pointer gap-2`}>
                                      <div className='w-[1rem]'></div>
                                      {assignTechBulk.show ? (
                                        <div className='flex flex-col py-3'>
                                          {item?.bulkSchedules ? (
                                            <>{item?.title}</>
                                          ) : (
                                            <>
                                              {item?.bulk_id && `Bulk-${item?.bulk_id} ${' '}/`}{' '}
                                              {`Request ${item?.preventive_request_id || item?.id}`}
                                            </>
                                          )}
                                        </div>
                                      ) : (
                                        <div className='flex flex-col'>
                                          <p className='select-none text-md'>
                                            {typeof item === 'string' ? item : item.vehicle.name}
                                          </p>
                                          <div className='bg-primary-light text-primary px-2 rounded-lg h-fit'>
                                            <p className='text-[0.7rem] font-bold'>
                                              {item.vehicle.vin}
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {bulkSchedules.length > 0 && (
                            <div className='overflow-y-auto flex-1'>
                              <div className='pr-4 grid grid-cols-1 gap-y-5 gap-x-60'>
                                {bulkSchedules.length > 0 &&
                                  bulkSchedules.map((item: any) => {
                                    const found = checkedItems?.find((el: any) => {
                                      if (el.bulkSchedules && item.bulkSchedules) {
                                        return el.bulk_id === item.bulk_id;
                                      } else {
                                        return (
                                          el.preventive_request_id === item.preventive_request_id
                                        );
                                      }
                                    });
                                    return (
                                      <div
                                        key={item.preventive_request_id}
                                        className={`px-2 p-2 border border-[0.1rem] border-slate-200 flex items-center rounded-lg cursor-pointer gap-2`}
                                        onClick={() => {
                                          if (found) {
                                            setCheckedItems(
                                              checkedItems.filter((el: any) => {
                                                if (el.bulkSchedules) {
                                                  return el.bulk_id !== item.bulk_id;
                                                } else {
                                                  return (
                                                    el.preventive_request_id !==
                                                    item.preventive_request_id
                                                  );
                                                }
                                              })
                                            );
                                          } else {
                                            setCheckedItems([...checkedItems, item]);
                                          }
                                        }}>
                                        {!assignTechinicanModal.open ? (
                                          <Checkbox disableRipple checked={!!found} />
                                        ) : (
                                          <div className='w-[1rem]'></div>
                                        )}

                                        {assignTechBulk.show ? (
                                          <div className='flex flex-col py-3'>
                                            {item?.bulkSchedules ? (
                                              <>{item?.title}</>
                                            ) : (
                                              <>
                                                {item?.bulk_id && `Bulk-${item?.bulk_id} ${' '}/`}{' '}
                                                {`Request ${
                                                  item?.preventive_request_id || item?.id
                                                }`}
                                              </>
                                            )}
                                          </div>
                                        ) : (
                                          <div className='flex flex-col'>
                                            <p className='select-none text-md'>
                                              {typeof item === 'string' ? item : item.vehicle.name}
                                            </p>
                                            <div className='bg-primary-light text-primary px-2 rounded-lg h-fit'>
                                              <p className='text-[0.7rem] font-bold'>
                                                {item.vehicle.vin}
                                              </p>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          )}
                          {bulkSchedules.length === 0 && (
                            <div className='overflow-y-auto h-[34rem] flex'>
                              <p className='m-auto'>No Services available</p>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <Divider
                      orientation='vertical'
                      sx={{ height: '100%', borderWidth: '0.10rem', borderColor: '#DDDDDD' }}
                    />
                  </>
                )}
                {!assignTechinicanModal.open && (
                  <div
                    className={`flex flex-col justify-between h-full flex-1 px-1 py-6  ${
                      !isSingleRequest && 'pt-[3rem]'
                    }`}>
                    <div className='flex flex-col gap-5 h-0 flex-1'>
                      <div className='flex justify-between items-center'>
                        <div className='relative flex items-center w-[22rem]'>
                          <SearchIcon
                            style={{ width: '17px', position: 'absolute', marginLeft: '8px' }}
                          />
                          <input
                            type='text'
                            placeholder='Search...'
                            onChange={handleSearch}
                            className='border-solid border-2 border-gray p-1.5 pl-8 pt-2 rounded-xl w-full focus:outline-none focus:border-stone-400 transition ease-in duration-300 '
                          />
                        </div>
                        <button
                          className='border-blue-400 border-2 py-2 px-4 bg-slate-50 text-blue-400 rounded-lg hover:bg-slate-100 disabled:text-slate-300 disabled:border-slate-300'
                          disabled={
                            !selectedTechnician?.technician_id || checkedItems?.length === 0
                          }
                          onClick={() =>
                            setAssignTechinicanModal({ open: true, data: selectedTechnician })
                          }>
                          Assign Technician
                        </button>
                      </div>
                      <div className='grid grid-cols-4 gap-5 overflow-y-auto h-0 flex-1 pr-2'>
                        {technicians.length > 0 ? (
                          technicians.map((elem: any) => (
                            <div className='' id={elem?.technician_id} key={elem?.technician_id}>
                              <Technician
                                handleSelected={(id: string) => {
                                  if (selectedTechnician?.technician_id === id) {
                                    setSelectedTechnician({});
                                  } else {
                                    setSelectedTechnician(elem);
                                  }
                                }}
                                selectedTechnician={selectedTechnician}
                                item={elem}
                              />
                            </div>
                          ))
                        ) : (
                          <div className='w-full h-full grid place-items-center'>
                            <p>no data found</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='flex justify-end'>
                      <button
                        className='border-none py-2 px-7 bg-slate-50 text-blue-400 rounded-lg disabled:text-slate-300 hover:bg-slate-100'
                        onClick={() => setAddTechnician(true)}>
                        Add Technician
                      </button>
                    </div>
                  </div>
                )}
                {assignTechinicanModal.open && (
                  <div className='flex flex-col justify-between gap-5 flex-1 px-1 py-6 pt-[3rem]'>
                    <div className='flex flex-col gap-5'>
                      <div className='flex gap-8 divide-slate-100'>
                        <div className='flex flex-col gap-3'>
                          <p className='text-slate-600'>Assigned Technician</p>
                          <section
                            className={`relative flex flex-col justify-center items-center gap-1 w-[10rem] h-[7rem] p-2 rounded-lg cursor-pointer border-transparent border-2 hover:border-slate-50 shadow-sm `}>
                            <UserDetail
                              label={selectedTechnician.technician_name}
                              position='bottom'
                              labelSx='whitespace-nowrap overflow-hidden text-ellipsis w-[9rem] flex-1 text-center'
                            />
                          </section>
                        </div>

                        <div className='pl-3 w-full'>
                          <div className='p-5 rounded-[1rem] border-[0.1rem] border-[#AAAAAA] mb-4'>
                            <div className='flex text-primary font-bold items-center mb-2'>
                              <i className='pi pi-map-marker mr-1 pb-[0.1rem]'></i>
                              Station Address{' '}
                            </div>
                            <p className='ml-4'>
                              {ServiceLocation(item || firstItemofSchedules || [])}
                            </p>
                          </div>
                          <textarea
                            value={notes}
                            onChange={(e: { target: { value: string } }) =>
                              setNotes(e.target.value)
                            }
                            className='w-full h-[15rem] rounded-lg p-2 border-2 border-slate-100 resize-none'
                            placeholder='Add Notes'></textarea>
                        </div>
                      </div>
                    </div>

                    <div className='flex justify-between'>
                      <button
                        className='border-transparent border-2 px-3 py-2 text-blue-400 bg-slate-50 rounded-lg hover:border-slate-100'
                        onClick={() => {
                          setAssignTechinicanModal({
                            open: false,
                            data: {},
                          });
                        }}>
                        Back
                      </button>
                      <button
                        className='border-transparent border-2 px-3 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-500 transition duration-200 w-[12rem]'
                        onClick={handleSubmit}>
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        </ModalForm>
      </OverlayContainer>

      {addTechnician && (
        <AddTechnician handleClose={() => setAddTechnician(false)} refetch={fetchTechnicians} />
      )}
    </>
  );
};

export default BulkAssignTechnician;
