import { Button, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state';
import { RootState } from '../../../../state/reducers';
import { getStatus } from '../../primaryComponents/Invoice';
import Tootltip from '../../../../components/BarData/Tootltip';

const DropdownBulkMove = () => {
  const dispatch = useDispatch();
  const { moveToCompleteData } = useSelector((state: RootState) => state.mainState);
  const data = moveToCompleteData?.selected;
  const isVendor = moveToCompleteData?.isVendor;
  const { setCompleteData } = bindActionCreators(actionCreators, dispatch);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloses = () => {
    setAnchorEl(null);
  };

  if (!moveToCompleteData?.bulkSchedules)
    return (
      <div className='flex justify-center items-center w-[20rem]'>
        <Button
          className='border-transparent px-3 py-2 rounded-xl w-full justify-between hover:bg-primary-light hover:text-primary-dark transition-all first-letter:
          cursor-auto
          '
          style={{
            backgroundColor: 'rgba(193, 193, 193, 0.12)',
          }}
          value={data.id}
          id='basic-button'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}>
          <p
            className={`flex flex-col text-primary justify-between font-bold ${
              !data.id && 'pt-2 mb-[-2rem]'
            } ${!data.id ? 'text-[1rem]' : 'text-[0.8rem]'}`}>
            <p className='w-full flex'>{data?.vehicle?.name}</p>
            <div>{getStatus(data, isVendor)}</div>
          </p>
        </Button>
      </div>
    );

  return (
    <div className='flex justify-center items-center w-[20rem]'>
      <Button
        className='border-transparent px-3 py-2 rounded-xl w-full justify-between hover:bg-primary-light hover:text-primary-dark transition-all'
        style={{
          backgroundColor: 'rgba(193, 193, 193, 0.12)',
        }}
        value={data.id}
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <p
          className={`flex flex-col text-primary justify-between font-bold ${
            !data.id && 'pt-2 mb-[-2rem]'
          } ${!data.id ? 'text-[1rem]' : 'text-[0.8rem]'}`}>
          <p className='w-full flex'>{data?.vehicle?.name}</p>
          <p className='w-full flex'>{getStatus(data, isVendor)}</p>
        </p>
        <div className='flex justify-between items-center h-full '>
          <i
            className={`pi pi-angle-${
              open ? 'up' : 'down'
            } transition-transform duration-300 transform ml-2  text-[#C9C9C9]  `}></i>
        </div>
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloses}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          sx: {
            width: '20rem',
            marginTop: '0.5rem',
          },
        }}>
        {moveToCompleteData?.bulkSchedules?.map((ele: any) => {
          return (
            <MenuItem
              key={ele}
              onClick={() => {
                setCompleteData({
                  ...moveToCompleteData,
                  selected: ele,
                });
                handleCloses();
              }}
              selected={data?.id === ele?.id}>
              {/* <div className='w-full flex justify-between gap-3 flex-start'>
                <p>{ele?.vehicle?.name}</p>
                <p>{getStatus(data, isVendor)}</p>
              </div> */}
              <div className='flex justify-between w-full'>
                <Tootltip placement={'bottom'}>
                  <p className='text-ellipsis whitespace-nowrap overflow-hidden w-[8rem] font-bold'>
                    {ele?.vehicle?.name}
                  </p>
                </Tootltip>
                {getStatus(ele, isVendor)}
              </div>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default DropdownBulkMove;
