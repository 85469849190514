import { SearchIcon } from '@heroicons/react/solid';
import { Avatar, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import SubmitAssignModal from '../SubmitAssignModal';
import Modal from '../../../../../components/OverlayContainer/Model';
import { useQuery } from 'react-query';
import PartnerAPI from '../../../../../api/partnerApi';
import { toast } from '../../../../../components/Toast/CustomToast';

const AssignPartnerModel = ({ data, handleClose, refetch }: any) => {
  const [technicianList, setTechnicianList] = useState([]);
  const [selectedTechnician, setSelectedTechnician] = useState<any>({});
  const [assignTechModal, setAssignTechModal] = useState({
    open: false,
    data: {},
  });
  const { data: ALL_PARTNER_DATA } = useQuery(
    ['ALL_PARTNER_DATA'],
    async (data) => await PartnerAPI.getAllPartnerData(data),
    {
      onSuccess: (response: any) => {
        if (!(response.status >= 200 && response.status <= 210)) {
          toast.error(response?.data?.message);
        }
      },
      onError: (error: Error) => {
        console.log(error);
        toast.error('Something went wrong please reload this page!');
      },
    }
  );
  useEffect(() => {
    if (ALL_PARTNER_DATA?.data?.data) {
      setTechnicianList(ALL_PARTNER_DATA?.data?.data);
      if (data?.suggested_partner && ALL_PARTNER_DATA?.data?.data?.length > 0) {
        const selectedOne = ALL_PARTNER_DATA?.data?.data?.find((d: any) => {
          return String(d?.partner.account_id) === String(data.suggested_partner);
        });
        setSelectedTechnician(selectedOne);
      }
    } else {
      setTechnicianList([]);
    }
  }, [ALL_PARTNER_DATA?.data?.data]);

  const handleSearch = (e: { target: { value: string } }) => {
    const target = e?.target?.value?.toLowerCase()?.trim();
    if (target === '') {
      setTechnicianList(ALL_PARTNER_DATA?.data?.data);
    } else {
      setTechnicianList(
        ALL_PARTNER_DATA?.data?.data?.filter((item: any) =>
          item?.partner?.name?.toLowerCase()?.trim()?.includes(target)
        )
      );
    }
  };
  return (
    <>
      <Modal widthclass='auto' heightClass='' handleClose={handleClose} noLabel>
        <div className='flex flex-col gap-5 h-[35rem]'>
          <div>
            <p className='text-lg'>Partners</p>
          </div>

          <div className='flex justify-between items-center'>
            <div className='relative flex items-center w-[22rem]'>
              <SearchIcon style={{ width: '17px', position: 'absolute', marginLeft: '8px' }} />
              <input
                type='text'
                placeholder='Search...'
                onChange={handleSearch}
                className='border-solid border-2 border-gray p-1.5 pl-8 pt-2 rounded-xl w-full focus:outline-none focus:border-stone-400 transition ease-in duration-300'
              />
            </div>
            <button
              className='outline-none border-blue-400 py-2 px-4 text-blue-400 bg-slate-50 rounded-lg w-1/4 disabled:border-slate-100 disabled:text-stone-400'
              disabled={!selectedTechnician?.partner?.account_id}
              onClick={() => setAssignTechModal({ open: true, data: selectedTechnician })}>
              Assign Partners
            </button>
          </div>

          <div className='flex flex-wrap gap-5 w-[45rem] max-h-[30rem] overflow-y-auto'>
            {technicianList.length > 0 ? (
              technicianList.map((item: any) => (
                <Technicians
                  key={item.account_id}
                  handleSelected={() => {
                    if (selectedTechnician?.partner?.account_id === item?.partner?.account_id) {
                      setSelectedTechnician({});
                    } else {
                      setSelectedTechnician(item);
                    }
                  }}
                  item={item}
                  data={data}
                  selectedTechnician={selectedTechnician}
                />
              ))
            ) : (
              <div className='w-full h-full grid place-items-center'>
                <p>no data found</p>
              </div>
            )}
          </div>
        </div>
      </Modal>
      {assignTechModal.open && (
        <SubmitAssignModal
          handleClose={() => {
            setAssignTechModal({ open: false, data: {} });
            handleClose();
          }}
          handleBack={() => setAssignTechModal({ open: false, data: {} })}
          handleDone={() => {
            setSelectedTechnician({});
            refetch();
          }}
          item={{
            ...assignTechModal.data,
            ...data,
            isPartnerReassign: true,
            partnerDetails: {
              name: selectedTechnician?.partner?.name,
              new_partner_account_id: selectedTechnician?.partner?.account_id,
              previous_partner_account_id: data?.partner?.account_id,
            },
          }}
          vendorData={data}
        />
      )}
    </>
  );
};

const Technicians = ({ handleSelected, item, selectedTechnician, data }: any) => {
  const checkboxRef = useRef<any>(null);
  const handleClick = () => {
    if (!data.suggested_partner) {
      handleSelected();
    }
  };
  const ifExist =
    data.suggested_partner === item?.partner?.account_id
      ? 'shadow-sm bg-slate-100'
      : 'border-transparent border-2 border-slate-50 text-gray-400';
  const disabledState = data.suggested_partner
    ? ifExist
    : 'cursor-pointer border-transparent border-2 hover:border-slate-50 shadow-sm';
  const ifExistInput = data.suggested_partner === item?.partner?.account_id ? false : true;
  const inputDisabled = data.suggested_partner ? ifExistInput : false;
  return (
    <div
      className={`relative flex flex-col justify-center items-center gap-1 w-[10rem] h-[7rem] p-2 hover:bg-primary-light rounded-lg ${disabledState}`}
      onClick={handleClick}>
      <Avatar>{item?.partner?.name.slice(0, 2).toUpperCase()}</Avatar>
      <Typography className='w-full text-center' noWrap>
        {item?.partner?.name}
      </Typography>
      <input
        ref={checkboxRef}
        disabled={inputDisabled}
        checked={selectedTechnician?.partner?.account_id === item?.partner?.account_id}
        type='checkbox'
        className='absolute top-[8px] right-[8px] w-[1.1rem] h-[1.1rem] rounded-full'
      />
    </div>
  );
};

export default AssignPartnerModel;
