import { useEffect, useState } from 'react';
import RevenueTrend from './graph';
import useFetchGraph from './useFetchGraph';
import { ThreeDots } from 'react-loader-spinner';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../state/reducers';

function RevenueGraph() {
  const [urlParams]: any = useSearchParams();
  const [targetWeek] = useState('W4');
  const [targetValue] = useState(245);
  const { loader: graphLoader, fetchAllGraphData, revenueGraphData } = useFetchGraph();
  const { user }: any = useSelector((state: RootState) => state.userState);
  useEffect(() => {
    if (urlParams?.get('weeks')?.length > 0 || urlParams?.get('months')?.length > 0) {
      fetchAllGraphData();
    }
  }, [urlParams?.get('weeks'), urlParams?.get('months')]);
  return (
    <div className='w-full h-82 flex flex-col justify-betweeen border-[0.2rem] border-gray-200 p-2 rounded-[0.5rem] shadow-md'>
      <p
        className='p-2  font-[500] flex gap-2 items-center'
        style={{
          color: 'rgba(81, 81, 81, 1)',
          fontSize: '1.2rem',
        }}>
        Revenue Trend
        {user?.role === 'fleetmax_partner' && (
          <p className='flex items-center bg-[#F6EFD3] text-[#AC9059] gap-2 py-1 px-2 ml-2 rounded-full text-[0.8rem]'>
            <i className='pi pi-info-circle'></i> {'  '}
            "Vendor" filter does not apply here!
          </p>
        )}
      </p>
      {graphLoader ? (
        <div className='h-[17rem] w-full flex justify-center items-center'>
          <ThreeDots
            height='10'
            width='50'
            radius='9'
            color={'#4378C8'}
            ariaLabel='three-dots-loading'
            wrapperStyle={{}}
            visible={true}
          />
        </div>
      ) : (
        <>
          {revenueGraphData.length > 0 ? (
            <div className='flex h-full  w-full items-center gap-2 mb-2'>
              <div
                className='mb-[5rem] w-[3%]'
                style={{
                  writingMode: 'vertical-rl',
                  textAlign: 'center',
                  transform: 'rotate(180deg)',
                }}>
                Revenue (in Dollars)
              </div>
              <div className='w-[97%]'>
                <RevenueTrend
                  data={revenueGraphData}
                  targetWeek={targetWeek}
                  targetValue={targetValue}
                />
              </div>
            </div>
          ) : (
            <>
              {!urlParams?.get('weeks') && !urlParams?.get('months') ? (
                <div className='flex justify-center items-center h-[17rem]'>
                  {' '}
                  <img
                    src='../../svg/info.svg'
                    alt='infoIcon'
                    className='inline-block h-[1.5rem] w-[1.7rem] pr-[0.5rem] pb-[0.1rem]'
                  />
                  Please select a time range
                </div>
              ) : (
                <div className='flex justify-center items-center h-[17rem]'>
                  {' '}
                  <img
                    src='../../svg/info.svg'
                    alt='infoIcon'
                    className='inline-block h-[1.5rem] w-[1.7rem] pr-[0.5rem] pb-[0.1rem]'
                  />
                  No data Found
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default RevenueGraph;
