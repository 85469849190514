import { lazy, useEffect } from 'react';
import Toggle from './toggle';
import { useSearchParams } from 'react-router-dom';
import Alerts from './alerts/Alerts';
import { DateCalendarServerRequest } from './upcomingRequest/Calender';
import Perfomance from './perfomance/Perfomance';
import { RootState } from '../../../../state/reducers';
import { useSelector } from 'react-redux';
import PartnersVendors from './partnersvendors/PartnersVendors';

const Vendor_ = lazy(() => import('../../../vender'));

function VendorDashboard() {
  const { user }: any = useSelector((state: RootState) => state.userState);
  const isPartner: boolean = user?.role === 'fleetmax_partner';
  const [urlParams, setSearchParams]: any = useSearchParams();
  useEffect(() => {
    urlParams.set('offset', '1');
    setSearchParams(urlParams);
  }, [urlParams.get('type')]);

  useEffect(() => {
    if (!urlParams.get('type')) {
      urlParams.set('type', 'requests');
      setSearchParams(urlParams);
    }
  }, [urlParams]);
  useEffect(() => {
    if (!urlParams.get('vendor_ids') && isPartner) {
      urlParams.set('vendor_ids', JSON.stringify([user?.account_id]));
      setSearchParams(urlParams);
    }
  }, []);
  return (
    <div className='flex flex-col gap-1 h-full w-full overflow-auto'>
      <div
        className='h-[3rem] text-[1.3rem] border-b-[0.2rem] border-gray-100 flex justify-between'
        style={{ minHeight: '3rem' }}>
        {Toggle(urlParams)}
      </div>
      <Vendor_ />
      <div
        className='h-fit flex gap-4 m-3 mx-4 overflow-x-auto  p-1'
        style={{ minHeight: '32rem' }}>
        <DateCalendarServerRequest />
        {isPartner ? <Perfomance /> : <Alerts />}
      </div>
      {isPartner && (
        <div
          className='h-fit flex gap-4 m-3 mx-4 overflow-x-auto  p-1'
          style={{ minHeight: '32rem' }}>
          <PartnersVendors />
          <Alerts />
        </div>
      )}
    </div>
  );
}

export default VendorDashboard;
