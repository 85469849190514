import dayjs from 'dayjs';
import { Button } from '@mui/material';
import 'dayjs/locale/en';
import { dummyMonths, dummyYears } from './dummyData';
function SelectRange({ props }: any) {
  const { selectRange, setSelectRange, handleMonthChange } = props;
  return (
    <div className=' w-[37%] p-3 border-[0.1rem] border-gray-200 rounded-[0.5rem]'>
      {!selectRange?.year && (
        <div className='flex flex-col'>
          <div className='w-full flex justify-between'>
            <p>Year</p>
            <Button
              className='text-[#C55] font-bold'
              onClick={() => {
                setSelectRange({ show: false, year: null, month: null });
              }}>
              Cancel
            </Button>
          </div>
          <div className='grid grid-cols-3 gap-3 max-h-[25rem] overflow-auto'>
            {[...dummyYears].map((ele) => {
              return (
                <Button
                  key={ele || ''}
                  className='font-bold text-[1rem] rounded-[0.5rem] hover:bg-primary hover:text-white'
                  onClick={() => {
                    setSelectRange({ ...selectRange, year: ele });
                  }}>
                  {ele}{' '}
                </Button>
              );
            })}
          </div>
        </div>
      )}
      {selectRange?.year && !selectRange?.month && (
        <div className='flex flex-col '>
          <div className='w-full flex justify-between'>
            <p>Month</p>
            <Button
              className='text-[#C55] font-bold'
              onClick={() => {
                setSelectRange({ show: false, year: null, month: null });
              }}>
              Cancel
            </Button>
          </div>
          <div className='flex flex-col gap-3 max-h-[25rem] overflow-auto'>
            {[...dummyMonths].map((ele, index) => (
              <Button
                key={ele || ''}
                className='font-bold p-3 text-[1.05rem] rounded-[0.5rem] w-full hover:bg-primary hover:text-white hover:border-transparent'
                onClick={() => {
                  const date = dayjs(`${selectRange.year}-${index + 1}-01`);
                  setSelectRange({ ...selectRange, month: ele });
                  handleMonthChange(date);
                  setSelectRange({ show: false, year: null, month: null });
                }}>
                {ele}
              </Button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default SelectRange;
