const SVG = (props: { height?: string; width?: string; active?: boolean }) => {
  const height = props?.height || '2rem';
  const width = props?.width || '3.5rem';
  return (
    <div className={`w-[${width}] h-[${height}] rounded-[0.6rem] bg-[#F8F4E8]`}>
      <svg
        width={width}
        height={height}
        viewBox='0 0 61 34'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        {/* <rect width={61} height={34} rx='17' fill='#F8F4E8' /> */}
        <path
          d='M29 28C26.8244 28 24.6977 27.3549 22.8887 26.1462C21.0798 24.9375 19.6699 23.2195 18.8373 21.2095C18.0048 19.1995 17.7869 16.9878 18.2114 14.854C18.6358 12.7202 19.6835 10.7602 21.2218 9.22183C22.7602 7.68345 24.7202 6.6358 26.854 6.21137C28.9878 5.78693 31.1995 6.00477 33.2095 6.83733C35.2195 7.66989 36.9375 9.07979 38.1462 10.8887C39.3549 12.6977 40 14.8244 40 17C40 19.9174 38.8411 22.7153 36.7782 24.7782C34.7153 26.8411 31.9174 28 29 28ZM29 7.57143C27.1352 7.57143 25.3123 8.12441 23.7618 9.16043C22.2113 10.1965 21.0028 11.669 20.2891 13.3918C19.5755 15.1147 19.3888 17.0105 19.7526 18.8394C20.1164 20.6684 21.0144 22.3484 22.333 23.667C23.6516 24.9856 25.3316 25.8836 27.1606 26.2474C28.9895 26.6112 30.8853 26.4245 32.6082 25.7109C34.331 24.9972 35.8035 23.7888 36.8396 22.2382C37.8756 20.6877 38.4286 18.8648 38.4286 17C38.4286 14.4994 37.4352 12.1012 35.667 10.333C33.8988 8.5648 31.5006 7.57143 29 7.57143ZM27.9471 20.7321L35.0186 14.4464L33.9814 13.2679L27.4286 19.0664L24.0579 15.6564L22.9421 16.7721L26.8707 20.7007C26.9441 20.7735 27.0312 20.8312 27.1269 20.8703C27.2227 20.9094 27.3252 20.9292 27.4286 20.9286C27.6196 20.9283 27.8039 20.8585 27.9471 20.7321Z'
          fill='#AC9059'
        />
        <path
          d='M39.5 3C36.475 3 34 5.475 34 8.5C34 11.525 36.475 14 39.5 14C42.525 14 45 11.525 45 8.5C45 5.475 42.525 3 39.5 3ZM40.6 10.535C40.325 10.7 39.995 10.59 39.83 10.315L39.005 8.775C38.95 8.665 38.95 8.61 38.95 8.5V5.75C38.95 5.42 39.17 5.2 39.5 5.2C39.83 5.2 40.05 5.42 40.05 5.75V8.335L40.82 9.765C40.93 10.04 40.875 10.37 40.6 10.535Z'
          fill='#AC9059'
        />
      </svg>
    </div>
  );
};
export default SVG;
