import React from 'react';
import BtnPopUp from '../../../.././src/pages/csr/bulkInfoModal/components/BtnPopUp';
import RedoSvg from '../../../.././src/assets/Redo';
import { RootState } from '../../../state/reducers';
import { useSelector } from 'react-redux';
import { useRedirectToSchedule } from '../../csr/primaryComponents/requestComponents/ScheduledInfo';

function AssignUnAssignPopUp({ item, type }: any) {
  const { user }: any = useSelector((state: RootState) => state.userState);
  const isCSR: boolean = user.role === 'fleetmax_csr';
  const redirectToSchedule = useRedirectToSchedule();
  const title = () => {
    if (type === 'Technician') {
      return 'Technician';
    }
    if (type === 'Vendor') {
      return 'Vendor';
    }
    if (type === 'Partner') {
      return 'Partner';
    } else return '';
  };
  const readOnlyCondition = () => {
    if (type === 'Technician') {
      return !['5', '10'].includes(item.status_id);
    }
    if (type === 'Vendor') {
      return !['4'].includes(item.status_id);
    }
    if (type === 'Partner') {
      return !['-1'].includes(item.status_id);
    } else return true;
  };
  const value = () => {
    if (type === 'Technician') {
      return item?.technician?.name;
    }
    if (type === 'Vendor') {
      return item?.vendor?.name;
    }
    if (type === 'Partner') {
      return item?.partner?.name;
    } else return 'N/A';
  };
  const reassignLogic = () => {
    if (type === 'Technician') {
      if (
        (item?.id || item?.preventive_request_id) &&
        +item?.status_id === 5 &&
        !item?.sub_stage &&
        isCSR
      ) {
        return () => {
          item?.setTechnicianModel({
            open: true,
            data: {
              ...item,
              suggested_technician: null,
              previous_technician_account_id: item?.technician?.account_id || '',
              previous_vendor_account_id: item?.vendor?.account_id || '',
            },
          });
        };
      } else
        return () => {
          item?.setAssignTechnicianModal({
            open: true,
            data: item,
          });
        };
    }
    if (type === 'Vendor') {
      return () => {
        redirectToSchedule(item);
      };
    }
    if (type === 'Partner') {
      return () => {
        if (
          isCSR &&
          (item?.sub_stage === '3' || item?.sub_stage === '1' || item?.sub_stage === '2')
        ) {
          redirectToSchedule(item);
        } else {
          item?.setPartnerModel({
            open: true,
            data: {
              ...item,
              suggested_partner: null,
            },
          });
        }
      };
    } else return () => {};
  };
  const unAssignLogic = () => {
    if (type === 'Technician') {
      if (
        (item?.id || item?.preventive_request_id) &&
        +item?.status_id === 5 &&
        !item?.sub_stage &&
        isCSR
      ) {
        return () => {
          item?.setRejecting(true);
        };
      } else
        return () => {
          item?.handleRemoveTechnician(item);
        };
    }
    if (type === 'Vendor') {
      return () => {
        item?.setRejecting(true);
      };
    }
    if (type === 'Partner') {
      return () => {
        item?.setRejecting(true);
      };
    } else return () => {};
  };
  const buttonTitle: string = String(title());
  return (
    <>
      <BtnPopUp
        title={buttonTitle}
        readOnly={Boolean(readOnlyCondition())}
        value={String(value())}
        options={[
          {
            head: (
              <div className='flex gap-2 items-center'>
                <RedoSvg />
                <p className='text-sm'>Re-Assign</p>
              </div>
            ),
            event: reassignLogic(),
          },
          {
            head: (
              <div className='flex gap-2 items-center text-sm'>
                <i className='pi pi-times-circle text-rose-300 text-sm'></i>
                <p className=''>Un-Assign</p>
              </div>
            ),
            event: unAssignLogic(),
          },
        ]}
      />
    </>
  );
}

export default AssignUnAssignPopUp;
