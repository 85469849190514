import { useState, useCallback } from 'react';
import NotificationApi from '../../../../../api/notification';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../state/reducers';
import { actionCreators } from '../../../../../state';
import { DashBoardAlerts } from '../../types/types';
const usePartnerAlertsQuery = (user?: any) => {
  const params = new URLSearchParams(window.location.search);
  const vendor_ids = params.get('vendor_ids') || '';
  let query = '';
  if (vendor_ids) {
    const vendor_id_array = JSON.parse(vendor_ids);
    if (vendor_id_array?.[0] !== user?.account_id) {
      query += `&alert_list=${vendor_ids}`;
    }
  }
  if (!vendor_ids) {
    query += `&alert_list_type=all`;
  }
  return query;
};
const useNotificationFetcher = () => {
  const [loader, setLoader] = useState(false);
  const [alertData, setAlertData] = useState<DashBoardAlerts[]>([]);
  const dispatch = useDispatch();
  const [vendorIds, setVendorIds] = useState<string | null>(null);
  const { setAltertsOffset: setOffset } = bindActionCreators(actionCreators, dispatch);
  const { alertsOffset: offset, user }: any = useSelector((state: RootState) => state.userState);
  const partnerAlertsQuery = usePartnerAlertsQuery(user);
  const refetchAlert = useCallback(
    async (offset = 0) => {
      setLoader(true);
      const vendorQuery = `?request_type=dashboard_alert&offset=0&limit=${10 + offset}`;
      const partnerQuery = `?request_type=dashboard_alert&offset=0&limit=${
        10 + offset
      }${partnerAlertsQuery}`;
      const response: { status: number; data: { data: DashBoardAlerts[] } } =
        await NotificationApi.getNotification({
          query: user?.role === 'fleetmax_vendor' ? vendorQuery : partnerQuery,
        });

      if (response.status >= 200 && response.status <= 210) {
        setAlertData([...response.data.data]);
      }

      setLoader(false);
    },
    [offset, vendorIds]
  );
  return {
    loader,
    alertData,
    // alertData: [
    //   ...alertData,
    //   ...alertData,
    //   ...alertData,
    //   ...alertData,
    //   ...alertData,
    //   ...alertData,
    //   ...alertData,
    //   ...alertData,
    //   ...alertData,
    //   ...alertData,
    // ],
    refetchAlert,
    offset,
    setOffset,
    vendorIds,
    setVendorIds,
  };
};

export default useNotificationFetcher;
