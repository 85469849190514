export default function (props: { height?: string; width?: string; fill?: string }) {
  const height = props?.height || '1.4rem';
  const width = props?.width || '1.4rem';
  const fill = props?.fill || '#4378C8';
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 26 27'
      fill='none'>
      <mask
        id='mask0_11330_5770'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width={width}
        height={height}>
        <rect x='0.079834' y='0.869141' width='25.8402' height='25.8402' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_11330_5770)'>
        <path
          d='M15.9639 22.4036L18.1173 24.5569H6.543C5.95083 24.5569 5.44389 24.3461 5.0222 23.9244C4.6005 23.5027 4.38965 22.9958 4.38965 22.4036V5.17679C4.38965 4.58462 4.6005 4.07768 5.0222 3.65598C5.44389 3.23429 5.95083 3.02344 6.543 3.02344H16.2331L21.6164 9.48349V22.4036C21.6164 22.7625 21.5402 23.09 21.3877 23.3861C21.2351 23.6821 21.0243 23.9289 20.7551 24.1263L15.1564 18.5814C14.8513 18.7788 14.5194 18.9268 14.1605 19.0255C13.8016 19.1242 13.4158 19.1736 13.003 19.1736C11.8187 19.1736 10.8048 18.7519 9.96144 17.9085C9.11805 17.0651 8.69635 16.0512 8.69635 14.8669C8.69635 13.6825 9.11805 12.6687 9.96144 11.8253C10.8048 10.9819 11.8187 10.5602 13.003 10.5602C14.1874 10.5602 15.2013 10.9819 16.0447 11.8253C16.8881 12.6687 17.3097 13.6825 17.3097 14.8669C17.3097 15.2796 17.2604 15.6654 17.1617 16.0243C17.063 16.3832 16.915 16.7152 16.7176 17.0202L19.4631 19.8196V10.2372L15.2102 5.17679H6.543V22.4036H15.9639ZM13.003 17.0202C13.5952 17.0202 14.1022 16.8094 14.5239 16.3877C14.9456 15.966 15.1564 15.459 15.1564 14.8669C15.1564 14.2747 14.9456 13.7678 14.5239 13.3461C14.1022 12.9244 13.5952 12.7135 13.003 12.7135C12.4109 12.7135 11.9039 12.9244 11.4822 13.3461C11.0605 13.7678 10.8497 14.2747 10.8497 14.8669C10.8497 15.459 11.0605 15.966 11.4822 16.3877C11.9039 16.8094 12.4109 17.0202 13.003 17.0202Z'
          fill={fill}
        />
      </g>
    </svg>
  );
}
