import { SearchIcon } from '@heroicons/react/solid';
import { Avatar, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import SubmitAssignModal from '../SubmitAssignModal';
import { RootState } from '../../../../../state/reducers';
import Modal from '../../../../../components/OverlayContainer/Model';
import VendorAPI from '../../../../../api/vendorApi';
import HandleLoad from '../../../../../components/Loader/HandleLoad';

const AssignTechModel = ({ data, handleClose, refetch }: any) => {
  const loader = HandleLoad();
  const [partnerList, setTechnicianList] = useState([]);
  const [selectedTechnician, setSelectedTechnician] = useState<any>({});
  const [techs, setTechs] = useState<any>({});
  const [assignTechnicianModal, setAssignTechnicianModal] = useState({
    open: false,
    data: {},
  });
  const { user }: any = useSelector((state: RootState) => state.userState);
  const fetchTechnicians = () => {
    loader(true);
    VendorAPI.getVendorTechnicianbyCSR({ id: data?.vendor?.id }).then((res: any) => {
      if (res.status === 200) {
        setTechs(res?.data?.data);
      }
      loader(false);
    });
  };
  useEffect(() => {
    if (techs?.technicians_details) {
      setTechnicianList(techs?.technicians_details);
      if (data?.suggested_technician && techs?.technicians_details?.length > 0) {
        const selectedOne = techs?.technicians_details?.find((d: any) => {
          return String(d?.partner.account_id) === String(data.suggested_technician);
        });
        setSelectedTechnician(selectedOne);
      }
    } else {
      setTechnicianList([]);
    }
  }, [techs]);
  const handleSearch = (e: { target: { value: string } }) => {
    const target = e?.target?.value?.toLowerCase()?.trim();
    if (target === '') {
      setTechnicianList(techs?.technicians_details);
    } else {
      setTechnicianList(
        techs?.technicians_details?.filter((item: any) =>
          item?.technician_name?.toLowerCase()?.trim()?.includes(target)
        )
      );
    }
  };
  useEffect(() => {
    fetchTechnicians();
  }, []);
  return (
    <>
      <Modal widthclass='auto' heightClass='' handleClose={handleClose} noLabel>
        <div className='flex flex-col gap-5 h-[35rem]'>
          <div>
            <p className='text-lg'>Technicians</p>
          </div>

          <div className='flex justify-between items-center'>
            <div className='relative flex items-center w-[22rem]'>
              <SearchIcon style={{ width: '17px', position: 'absolute', marginLeft: '8px' }} />
              <input
                type='text'
                placeholder='Search...'
                onChange={handleSearch}
                className='border-solid border-2 border-gray p-1.5 pl-8 pt-2 rounded-xl w-full focus:outline-none focus:border-stone-400 transition ease-in duration-300'
              />
            </div>
            <button
              className='outline-none border-blue-400 py-2 px-4 text-blue-400 bg-slate-50 rounded-lg w-1/4 disabled:border-slate-100 disabled:text-stone-400'
              disabled={!selectedTechnician?.technician_account_id}
              onClick={() => {
                setAssignTechnicianModal({
                  open: true,
                  data: {
                    ...selectedTechnician,
                    ...(!data?.bulk_id &&
                      user.role === 'fleetmax_partner' && { handleClose: data?.handleClose?.() }),
                  },
                });
              }}>
              Assign Technicians
            </button>
          </div>
          <div className='flex flex-wrap gap-5 w-[45rem] max-h-[30rem] overflow-y-auto'>
            {partnerList.length > 0 ? (
              partnerList.map((item: any) => (
                <Technicians
                  key={item.account_id}
                  handleSelected={() => {
                    if (selectedTechnician?.technician_account_id === item?.technician_account_id) {
                      setSelectedTechnician({});
                    } else {
                      setSelectedTechnician(item);
                    }
                  }}
                  item={item}
                  data={data}
                  selectedTechnician={selectedTechnician}
                />
              ))
            ) : (
              <div className='w-full h-full grid place-items-center'>
                <p>no data found</p>
              </div>
            )}
          </div>
        </div>
      </Modal>
      {assignTechnicianModal.open && (
        <SubmitAssignModal
          handleClose={() => {
            setAssignTechnicianModal({ open: false, data: {} });
            handleClose();
          }}
          handleBack={() => setAssignTechnicianModal({ open: false, data: {} })}
          handleDone={() => {
            setSelectedTechnician({});
            refetch();
          }}
          item={{
            ...assignTechnicianModal.data,
            ...data,
            isTechnicianReassign: true,
            technicianDetails: {
              name: selectedTechnician?.technician_name,
              new_technician_account_id: selectedTechnician?.technician_account_id,
              technician_account_id: data?.technician?.name,
            },
            vendorDetailsForTech: { ...data?.vendor },
          }}
          vendorData={data}
        />
      )}
    </>
  );
};

const Technicians = ({ handleSelected, item, selectedTechnician, data }: any) => {
  const checkboxRef = useRef<any>(null);
  const handleClick = () => {
    if (!data.suggested_technician) {
      handleSelected();
    }
  };
  const ifExist =
    data.suggested_technician === item?.technician_account_id
      ? 'shadow-sm bg-slate-100'
      : 'border-transparent border-2 border-slate-50 text-gray-400';
  const disabledState = data.suggested_technician
    ? ifExist
    : 'cursor-pointer border-transparent border-2 hover:border-slate-50 shadow-sm';
  const ifExistInput = data.suggested_technician === item?.technician_account_id ? false : true;
  const inputDisabled = data.suggested_technician ? ifExistInput : false;
  return (
    <div
      className={`relative flex flex-col justify-center items-center gap-1 w-[10rem] h-[7rem] p-2 hover:bg-primary-light rounded-lg ${disabledState}`}
      onClick={handleClick}>
      <Avatar>{item?.technician_name?.slice(0, 2).toUpperCase()}</Avatar>
      <Typography className='w-full text-center' noWrap>
        {item?.technician_name}
      </Typography>
      <input
        ref={checkboxRef}
        disabled={inputDisabled}
        checked={selectedTechnician?.technician_account_id === item?.technician_account_id}
        type='checkbox'
        className='absolute top-[8px] right-[8px] w-[1.1rem] h-[1.1rem] rounded-full'
      />
    </div>
  );
};

export default AssignTechModel;
