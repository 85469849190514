import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import mySaga from '../sagas';
import reducers from './reducers';

const persistConfig = {
  key: 'root',
  storage: storage,
  debug: false,
  whitelist: ['mainState'],
};

const middleware: any[] = [];
const sagaMiddleware = createSagaMiddleware();
middleware.push(sagaMiddleware);

const persistedReducers = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middleware),
});

// Then run the saga
sagaMiddleware.run(mySaga);

let persistor = persistStore(store);

export { persistor, store };
