import { Avatar, Typography } from '@mui/material';
export const Technician = ({ item, selectedTechnician, handleSelected }: any) => {
  return (
    <div
      className={`relative flex flex-col justify-center items-center gap-1 w-[10rem] h-[7rem] p-2 rounded-lg cursor-pointer border-transparent border-2 hover:border-slate-50  hover:bg-primary-light shadow-sm capitalize ${
        selectedTechnician?.technician_id === item?.technician_id ? 'bg-slate-100' : 'bg-white'
      }`}
      onClick={() => handleSelected(item?.technician_id)}>
      <Avatar>{item.technician_name.slice(0, 2).toUpperCase()}</Avatar>
      <Typography className='w-full text-center' noWrap>
        {item.technician_name}
      </Typography>
    </div>
  );
};
