import { useSearchParams } from 'react-router-dom';
import Toggle from '../requests/toggle';
import { useEffect, useState } from 'react';
import Invoices from './invoices/Invoices';
import RevenueHistory from './revenueHistory/RevenueHistory';
import RevenueGraph from './graph/RevenueGraph';
import RevenueSources from './revenueSources/RevenueSources';
import { RootState } from '../../../../state/reducers';
import { useSelector } from 'react-redux';
import useFetchPartnerVendors from '../hooks/useFetchPartnerVendors';

function Revenue() {
  const [urlParams, _]: any = useSearchParams();
  const { user }: any = useSelector((state: RootState) => state.userState);
  const newSearchParams = new URLSearchParams(urlParams);
  const [showModel, setShowModel] = useState<any>({ open: false, data: {}, isIndex: false });
  const isPartner: boolean = user?.role === 'fleetmax_partner';
  const { fetchPartnerVendors } = useFetchPartnerVendors();
  useEffect(() => {
    if (isPartner) fetchPartnerVendors();
  }, []);
  return (
    <div className='flex flex-col gap-1 h-[95%] w-full'>
      <div
        className='w-full flex justify-between h-[3rem] text-[1.3rem] border-b-[0.2rem] border-gray-100'
        style={{ minHeight: '3rem' }}>
        {Toggle(newSearchParams)}
      </div>
      <div className='w-full h-full flex gap-2 justify-center items-center p-2'>
        <div className='w-[65%] h-full flex flex-col justify-betweeen'>
          <RevenueGraph />
          <RevenueHistory props={{ setShowModel }} />
        </div>
        <div className='w-[35%] h-full flex flex-col gap-3 justify-between'>
          <Invoices showModel={showModel} setShowModel={setShowModel} />
          {isPartner && <RevenueSources />}
        </div>
      </div>
    </div>
  );
}

export default Revenue;
