import { useState } from 'react';
import EditRequestIcon from '../../../assets/EditRequestIcon';
import EditRequestModelBulk from '../bulkInfoModal/components/EditModel';
import { actionCreators } from '../../../state';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

function EditRequestLogic({ item }: { item: any }) {
  const dispatch = useDispatch();
  const { setSelectedData } = bindActionCreators(actionCreators, dispatch);
  const [showEditModel, setShoweditModel] = useState(false);
  const preSchedules = item?.bulkSchedules;
  const schedules: any[] =
    preSchedules?.length > 0
      ? preSchedules.filter(
          (ele: any) =>
            ([-1, 4].includes(+ele?.status_id) && +ele?.sub_stage !== 3) ||
            [5, 6].includes(+ele?.status_id)
        )
      : [];
  const filtedEditSchedules: any =
    preSchedules?.length > 0
      ? {
          bulkSchedules: schedules,
        }
      : {};
  const editSchedules =
    schedules.length > 0
      ? {
          ...filtedEditSchedules?.bulkSchedules?.[0],
          ...filtedEditSchedules,
          refetch: item?.refetch,
        }
      : {};
  return (
    <>
      <div
        className='w-full flex bg-primary-light items-center p-2 px-3 rounded-b-xl cursor-pointer'
        onClick={() => {
          setShoweditModel(true);
        }}>
        <EditRequestIcon /> <p className='ml-2 text-[1.1rem] text-primary'>Edit Request</p>
      </div>
      {showEditModel && (
        <EditRequestModelBulk
          handleClose={() => {
            setShoweditModel(false);
            setSelectedData([]);
          }}
          item={editSchedules}
        />
      )}
    </>
  );
}

export default EditRequestLogic;
