import { Button, ButtonBase, Checkbox, Divider, Radio } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import CalenderSvg from '../../../../../assets/calender';
import Tootltip from '../../../../../components/BarData/Tootltip';
import Modal from '../../../../../components/OverlayContainer/Model';
import { actionCreators } from '../../../../../state';
import { RootState } from '../../../../../state/reducers';
import DateTime from './DateTime';
import { useNavigate } from 'react-router-dom';

const AssignVendor = ({
  data,
  handleClose,
  requestInfo,
}: {
  data: any;
  handleClose: any;
  requestInfo: any;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [oneScheule, setOneScheule] = useState(false);
  const { setAppointment, setBulkVehicles: bulkState } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const [bulkVehicles, setBulkVehicles] = useState([] as any[]);
  const [addTimeSlot, setAddTimeSlot] = useState({
    open: false,
    info: null,
  });
  const handleSchedule = () => {
    const updateVehicle = (vehicle: any) => {
      const services = vehicle?.checklists?.map((checklist: any) => checklist.name);
      const timeSlots = vehicle?.selectedTimeSlot;

      return {
        ...vehicle,
        hasAdded: true,
        isSingleSchedule: true,
        services: services,
        customServices: services,
        timeSlots: [{ ...timeSlots }],
        scheduleFromDSP: true,
        scheduleTo: 'Schedule to Vendor',
      };
    };

    const updatedVehicles = bulkVehicles.map(updateVehicle);
    bulkState({
      bulkVehicles: updatedVehicles,
    });

    navigate('/scheduleRequest');
  };

  useEffect(() => {
    const mapArr: any[] = [];
    if (data?.bulkSchedules && !oneScheule) {
      data?.bulkSchedules?.forEach((v: any) => {
        mapArr.push({
          ...v,
          timeSlots: getTimeSlots(v),
          handleTimeSlot: (item: any) =>
            setAddTimeSlot({
              open: true,
              info: item,
            }),

          selectedAppointMentTime: (
            selectedVehicle: any,
            selectedTimeSlot: any,
            oneScheule: boolean
          ) => {
            setBulkVehicles((prev) =>
              prev?.map((obj: any) => {
                if (+obj.id === +selectedVehicle.id || oneScheule) {
                  return {
                    ...obj,
                    selectedTimeSlot:
                      obj?.selectedTimeSlot?.id === selectedTimeSlot?.id ? null : selectedTimeSlot,
                  };
                }
                return obj;
              })
            );
          },
        });
      });
      setBulkVehicles(mapArr);
    }
  }, [data?.bulkSchedules, oneScheule]);

  useEffect(() => {
    if (oneScheule) {
      setBulkVehicles((prev: any) =>
        prev?.map((obj: any) => ({
          ...obj,
          timeSlots: [],
          customAdded: false,
        }))
      );
    }
  }, [oneScheule]);

  const seVendor = false;
  return (
    <Modal handleClose={handleClose} widthclass='w-[70vw]' heightClass='h-[80vh]' noLabel>
      <div className='w-full flex flex-col gap-2 justify-between h-full'>
        <div className='w-full flex flex-col gap-1 justify-between'>
          <p className='text-[2rem] font-medium'>Assign Vendor</p>
          <p className=''>
            {data?.bulk_id ? 'Bulk-' : 'Request-'}
            {data?.bulk_id}
          </p>
          <p className='flex items-center'>
            <div className='flex items-center mt-3'>
              <Checkbox
                checked={oneScheule}
                style={{
                  cursor: 'pointer',
                }}
                className='p-0'
                onChange={() => {
                  setOneScheule((prev) => !prev);
                }}
                size='small'
              />
              <p className='ml-2'>Apply Single Schedule to all Vehicles</p>
            </div>
          </p>
        </div>
        <div className='overflow-auto flex flex-col gap-4 flex-1 h-0 mt-2 pr-2'>
          {bulkVehicles.map((item: any, index: any) => (
            <VehicleRow data={item} {...{ oneScheule, seVendor, index }} key={item.id} />
          ))}
        </div>
        <div className='w-full flex  justify-center'>
          <Button
            className=' text-white rounded-lg w-[20rem] text-[1.1rem]'
            variant={'contained'}
            disabled={
              bulkVehicles.filter((d) => d?.selectedTimeSlot).length !== bulkVehicles?.length
            }
            onClick={() => {
              setAppointment({
                appointments: bulkVehicles.map((d) => ({
                  date: d?.selectedTimeSlot?.date,
                  time: d?.selectedTimeSlot?.time,
                  preventive_maintenance_id: d?.preventive_maintenance_id,
                  preventive_request_id: d?.id,
                })),
                common_slots: oneScheule,
              });
              handleSchedule();
              handleClose();
            }}>
            Proceed
          </Button>
        </div>
        {addTimeSlot.open && (
          <DateTime
            data={addTimeSlot}
            singleSchedule={oneScheule}
            bulkVehicles={bulkVehicles}
            setBulkVehicles={setBulkVehicles}
            handleClose={() => {
              setAddTimeSlot({
                open: false,
                info: null,
              });
            }}
          />
        )}
      </div>
    </Modal>
  );
};

const VehicleRow = (props: any) => {
  const { data } = props;
  const { vehicle, handleTimeSlot, timeSlots, customAdded } = props.data;
  const { oneScheule, seVendor, index } = props;

  const AddTimeSlotConditions = oneScheule ? index === 0 : false;
  return (
    <div className='border-border-color border-[0.15rem] rounded-xl w-full flex ' key={vehicle.id}>
      <div className='flex p-4 py-2 gap-5 flex-1 items-center h-[5.2rem]'>
        <div className='flex w-[10rem] items-center gap-2'>
          <Tootltip>
            <p className='text-md whitespace-nowrap overflow-hidden text-ellipsis w-full text-left font-bold flex justify-center'>
              {vehicle.name}
            </p>
          </Tootltip>
        </div>
        <Divider
          orientation='vertical'
          sx={{ height: '80%', borderWidth: '0.10rem', borderColor: '#DDDDDD' }}
        />
        <TimeSlot {...{ oneScheule, seVendor, index, timeSlots, data }} />
        {AddTimeSlotConditions && !customAdded && (
          <AddTimeSlot
            onClick={() => {
              handleTimeSlot(props.data);
            }}
            {...{ oneScheule, seVendor: true }}
          />
        )}
      </div>
    </div>
  );
};

const TimeSlot = (props: any) => {
  const { selectedTimeSlot } = props.data;
  const { oneScheule, seVendor, index, timeSlots, data } = props;
  const colordisabled = oneScheule && index !== 0;
  const getColor: string = colordisabled ? 'text-[#A0A0A0]' : 'text-primary';
  const generateClassName = (seVendor: any, getColor: any, colordisabled: any) => {
    let className = 'text-sm flex ';

    if (seVendor) {
      className += getColor;
    } else {
      className += colordisabled ? 'text-bulkTextColor' : 'text-primary';
    }

    return className;
  };

  return (
    <>
      {timeSlots && timeSlots?.length > 0 && (
        <div className='flex overflow-x-auto flex-1 w-0 gap-5 p-1 px-2 h-full'>
          {timeSlots.map((timeSlot: any, index: any) => (
            <ButtonBase
              className={'flex rounded-xl gap-4 pr-6 relative'}
              style={{
                boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.13)',
                cursor: colordisabled ? 'auto' : 'pointer',
              }}
              key={timeSlot?.id}
              onClick={() => {
                if (!colordisabled) {
                  data?.selectedAppointMentTime(props.data, timeSlot, oneScheule);
                }
              }}>
               {' '}
              <Radio
                style={{
                  cursor: colordisabled ? 'auto' : 'pointer',
                  width: '0.8rem',
                  height: '0.8rem',
                }}
                size='small'
                className={`p-0 ${colordisabled ? 'text-[#A0A0A0]' : 'hover:text-primary'}`}
                checked={selectedTimeSlot?.id === timeSlot?.id}></Radio>
              <div
                style={{
                  cursor: colordisabled ? 'auto' : 'pointer',
                }}
                key={uuidv4()}
                className=' flex flex-col whitespace-nowrap h-[3.4rem] relative m-auto'>
                <p className={generateClassName(seVendor, getColor, colordisabled)}>
                  {timeSlot.time}
                </p>
                <p className={`text-md font-bold  ${colordisabled ? 'text-[#A0A0A0]' : ''}`}>
                  {timeSlot.date}
                </p>
              </div>
              <i
                className={`pi pi-pencil absolute -top-1 -right-1 text-[0.8rem]   ${
                  colordisabled ? 'text-[#A0A0A0]' : 'hover:text-primary'
                }`}
                style={{
                  cursor: colordisabled ? 'auto' : 'pointer',
                }}
                onClick={(e: any) => {
                  e?.stopPropagation();
                  if (!colordisabled) {
                    data?.handleTimeSlot({
                      ...props.data,
                      edit: index,
                    });
                  }
                }}></i>
            </ButtonBase>
          ))}
        </div>
      )}
    </>
  );
};

const AddTimeSlot = (props: { oneScheule: boolean; seVendor: boolean; onClick: () => void }) => {
  const { seVendor, onClick } = props;

  return (
    <Button
      className={`rounded-xl flex flex-row p-2 px-4 items-center justify-center gap-1 text-center h-[3.7rem] ${
        seVendor ? 'text-primary cursor-pointer' : 'text-bulkTextColor'
      } `}
      style={{
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.13)',
      }}
      onClick={() => {
        seVendor && onClick();
      }}>
      <i className='pi pi-plus text-sm'></i>
      <p className='font-bold whitespace-nowrap'> Add Time Slot</p>
    </Button>
  );
};

export const AddTimeSlotSingle = () => {
  const dispatch = useDispatch();
  const [addTimeSlot, setAddTimeSlot] = useState({
    open: false,
    info: null,
  });
  const [singleSchedule, setSingleSchedule] = useState(null as any);
  const { appointmentTime }: any = useSelector((state: RootState) => state.userState);
  const { setAppointment } = bindActionCreators(actionCreators, dispatch);

  return (
    <div>
      <div
        className={`flex flex-row items-center justify-start  gap-1 text-center pb-3 border-b-[0.1rem] mb-2`}>
        {singleSchedule ? (
          <div className={'flex gap-2 items-center'}>
            <div
              style={{
                cursor: 'pointer',
              }}
              key={uuidv4()}
              onClick={() => {
                appointmentTime?.id === singleSchedule?.id
                  ? setAppointment(null)
                  : setAppointment(singleSchedule);
              }}
              className='flex flex-row whitespace-nowrap items-center relative gap-4 '>
              <input
                type='radio'
                name='appointmentRadio'
                checked={appointmentTime?.id === singleSchedule?.id}
              />
              <label
                className={`w-full inline-flex items-center gap-4  whitespace-nowrap font-bold  cursor-pointer text-[1rem]`}>
                <CalenderSvg width='0.8rem' height='0.8rem' color='currentColor' />
                {singleSchedule.date ? moment(singleSchedule?.date).format("DD MMM'YY") : 'N/A'}
                <i className='pi pi-clock text-inherit text-sm'></i>
                {singleSchedule.date ? singleSchedule?.time : 'N/A'}
              </label>
            </div>

            <i
              className={'pi pi-pencil text-[0.8rem]'}
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                setAddTimeSlot({
                  open: true,
                  info: singleSchedule,
                });
              }}></i>
          </div>
        ) : (
          <Button className={'flex text-primary items-center gap-2'}>
            <CalenderSvg width='0.8rem' height='0.8rem' color='currentColor' />
            <p
              className='font-bold whitespace-nowrap'
              onClick={() => {
                setAddTimeSlot({
                  open: true,
                  info: null,
                });
              }}>
              Select From Calendar
            </p>
          </Button>
        )}
      </div>
      {addTimeSlot?.open && (
        <DateTime
          addForSingleReq
          data={addTimeSlot}
          handleClose={() => {
            setAddTimeSlot({
              open: false,
              info: null,
            });
          }}
          setSingleSchedule={setSingleSchedule}
        />
      )}
    </div>
  );
};

export default AssignVendor;

const convertTimeSlot = (t: any) => ({
  id: uuidv4(),
  date: moment(`${t.date} ${t.time}`, 'YYYY-MM-DD hh:mm:ss').format("DD MMM'YY"),
  time: moment(`${t.date} ${t.time}`, 'YYYY-MM-DD hh:mm:ss').format('hh:mm a'),
});
const getTimeSlots = (v: any) => {
  const formatTimeSlots: any[] = [];
  v.appointments.forEach((d: any) => {
    formatTimeSlots.push(convertTimeSlot(d));
  });
  return formatTimeSlots;
};
