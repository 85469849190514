const CrossButtons = (props: { height?: string; width?: string; active?: boolean }) => {
  const height = props?.height || '1.8rem';
  const width = props?.width || '1.8rem';
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 31 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <circle cx='15.5' cy='15.5' r='15.5' fill='#CC5555' fill-opacity='0.13' />
      <path
        d='M17.9477 16.0656L21.6069 12.4C22.131 11.875 22.131 11.0266 21.6069 10.5016C21.0829 9.97656 20.2359 9.97656 19.7118 10.5016L16.055 14.1672L12.2882 10.3937C11.7641 9.86875 10.9171 9.86875 10.3931 10.3937C9.86898 10.9187 9.86898 11.7672 10.3931 12.2922L14.1599 16.0656L10.5217 19.7102C9.99766 20.2352 9.99766 21.0836 10.5217 21.6086C10.7838 21.8687 11.1254 22 11.4693 22C11.8132 22 12.1548 21.8688 12.4168 21.6063L16.055 17.9617L19.5855 21.4984C19.8475 21.7609 20.1891 21.8922 20.533 21.8922C20.877 21.8922 21.2186 21.7609 21.4806 21.4984C22.0047 20.9734 22.0047 20.125 21.4806 19.6L17.9477 16.0656Z'
        fill='#CC5555'
      />
    </svg>
  );
};
export default CrossButtons;
