import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../state/reducers';
import { toast } from '../../../../../components/Toast/CustomToast';
import { useSearchParams } from 'react-router-dom';
interface PaginationProps {
  currentPage: number;
  totalItems: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
}
let debounceTimeout: any;
const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [urlParams]: any = useSearchParams();
  const [pageNumberInput, setPageNumberInput] = useState<string>(String(currentPage));
  const handlePrev = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };
  const { customizeView }: any = useSelector((state: RootState) => state.userState);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (!/^\d*$/.test(inputValue)) {
      return;
    }
    setPageNumberInput(inputValue);
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      const pageNumber = Number(inputValue);
      if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
        onPageChange(pageNumber || 1);
      } else {
        if (inputValue?.trim()) toast.error('Enter a valid page number');
      }
    }, 500);
  };
  const handleInputBlur = () => {
    if (!pageNumberInput && urlParams.get('page_number')) {
      setPageNumberInput(urlParams.get('page_number') || '1');
    }
  };
  useEffect(() => {
    setPageNumberInput(String(currentPage));
  }, [currentPage, urlParams.get('page_number')]);
  return (
    <nav
      className={`flex items-center flex-column flex-wrap md:flex-row justify-between ${
        customizeView ? 'pt-[0.3rem]' : 'pt-3'
      }`}
      aria-label='Table navigation'>
      <span></span>
      <ul className='inline-flex -space-x-px rtl:space-x-reverse text-sm h-8 items-center  gap-3 font-bold'>
        <li>
          <button
            onClick={handlePrev}
            className='flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border-transparent rounded-s-lg hover:bg-gray-100 hover:text-gray-700 cursor-pointer font-bold disabled:text-gray-400 disabled:bg-gray-100'
            disabled={currentPage === 1}>
            <i className='pi pi-angle-left text-[1.3rem]'></i>
          </button>
        </li>
        <li>Page</li>
        <li>
          <input
            type='number'
            className='w-8 h-7 px-1 transition-colors duration-300 outline-none focus:border-blue-500 border-1 rounded-[0.2rem] font-bold'
            value={pageNumberInput}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
          />
        </li>
        <li>of {totalPages || 1}</li>
        <li>
          <button
            onClick={handleNext}
            className='flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border-transparent rounded-e-lg hover:bg-gray-100 hover:text-gray-700 font-bold disabled:text-gray-400 disabled:bg-gray-100'
            disabled={currentPage === totalPages}>
            <i className='pi pi-angle-right text-[1.3rem]'></i>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
