import { Tooltip } from '@mui/material';
import ApprovedSvg from '../../../assets/Approved';
import AwaitingAcceptence from '../../../assets/AwaitingAcceptence';
import UploadProof from '../../../assets/JobCompletedOrUploadProofs';
import OnTheWayIcon from '../../../assets/OnTheWayIcon';
import PendingApprovalSvg from '../../../assets/PendingApproval';
import TechAssigned from '../../../assets/TechAssigned';
import PriorityIcons from '../scheduleRequest/components/PriorityIcons';

import { toast } from '../../../components/Toast/CustomToast';
import { CustomTooltip } from '../components/Complete';

function InProcessFilter({
  item,
  handleClick,
  fetchTooltipData,
  tooltipData,
}: {
  item: any;
  handleClick: () => void;
  fetchTooltipData: (item: any) => void;
  tooltipData: any;
}) {
  return (
    <div className='flex items-center gap-3 w-[15rem]'>
      <div className='w-[2rem] h-[2rem]'>
        <PriorityIcons iconType={item?.priority || '2'} />
      </div>
      {item.status_id === '4' && item?.sub_stage === '1' && (
        <Tooltip title='Awaiting Acceptance'>
          <div>
            <AwaitingAcceptence />
          </div>
        </Tooltip>
      )}
      {item.status_id === '4' && (item?.sub_stage ? item?.sub_stage === '2' : true) && (
        <Tooltip title='Assigned To Vendor'>
          <div className='w-[3.5rem] bg-lime-200 flex items-center justify-center h-[2rem] rounded-[0.6rem]'>
            <i className='pi pi-user-plus' style={{ fontSize: '1rem' }}></i>
          </div>
        </Tooltip>
      )}
      {/* Track Location  */}
      {item.status_id === '5' && ['1', '2', '3'].includes(item?.sub_stage) && (
        <Tooltip title={item?.sub_stage === '1' ? 'Technion En-Route' : 'Technician Reached'}>
          <div className='w-[3.5rem] bg-[#3E9983] flex items-center justify-center h-[2rem] rounded-[0.6rem]'>
            {+item?.sub_stage === 1 ? (
              <OnTheWayIcon fill='#E5F8BD' />
            ) : (
              <i className={`pi pi-map-marker text-white`}></i>
            )}
          </div>
        </Tooltip>
      )}
      {item.status_id === '5' && item?.sub_stage === undefined && (
        <Tooltip title='Assigned To Technician'>
          <div>
            <TechAssigned />
          </div>
        </Tooltip>
      )}
      {item.status_id === '6' && (
        <Tooltip title='Technician Started Working'>
          <div className='w-[3.5rem] bg-sky-200 flex items-center justify-center h-[2rem] rounded-[0.6rem]'>
            <i className='pi pi-cog' style={{ fontSize: '1rem' }}></i>
          </div>
        </Tooltip>
      )}
      {/** Job Completed/ Upload Proof */}
      {item.status_id === '7' && (
        <Tooltip title='Job Completed/ Upload Proof'>
          <div className='w-[3.5rem] bg-slate-200 flex items-center justify-center h-[2rem] rounded-[0.6rem]'>
            {/* <i className='pi pi-exclamation-circle' style={{ fontSize: '1rem' }}></i> */}
            <UploadProof />
          </div>
        </Tooltip>
      )}
      {/** approval pending */}
      {item.status_id === '8' && (
        <Tooltip title='Approval Pending'>
          <div>
            <PendingApprovalSvg />
          </div>
        </Tooltip>
      )}
      {/** approved */}
      {item.status_id === '9' && (
        <Tooltip title='Approved'>
          <div>
            <ApprovedSvg />
          </div>
        </Tooltip>
      )}
      {/* Rejected */}
      {item.status_id === '10' && (
        <Tooltip title='Rejected'>
          <div className='w-[3.5rem] bg-red-200 flex items-center justify-center h-[2rem] rounded-[0.6rem]'>
            <i className='pi pi-times' style={{ fontSize: '1rem' }}></i>
          </div>
        </Tooltip>
      )}
      {item.status_id === '11' && (
        <Tooltip title='Invoice Sent'>
          <div className='w-[3.5rem] bg-teal-200 flex items-center justify-center h-[2rem] rounded-[0.6rem]'>
            <i className='pi pi-money-bill' style={{ fontSize: '1rem' }}></i>
          </div>
        </Tooltip>
      )}
      {item?.bulk_id ? (
        <CustomTooltip
          title={
            <div className='capitalize'>
              {tooltipData?.[item?.bulk_id]?.length > 0
                ? tooltipData[item?.bulk_id]?.map((dataItem: any) => (
                    <div key={dataItem.vehicle_name}>{dataItem.vehicle_name}</div>
                  ))
                : 'loading...'}
            </div>
          }>
          {RequestViewBtn(item, handleClick, async () => {
            try {
              if (!tooltipData[item?.bulk_id]) {
                if (item?.bulk_id) {
                  fetchTooltipData(item?.bulk_id);
                }
              }
            } catch (error) {
              toast.error('Failed to fetch tooltip data');
            }
          })}
        </CustomTooltip>
      ) : (
        <div className='flex flex-col'>{RequestViewBtn(item, handleClick, () => {})}</div>
      )}
    </div>
  );
}

export default InProcessFilter;

export const RequestViewBtn = (item: any, handleClick: any, onMouseEnter: any) => (
  <div className='flex flex-col justify-start'>
    <button
      className='text-blue-500 font-semibold text-sm cursor-pointer border-none py-0.5 text-start  hover:bg-slate-100 rounded-[0.6rem] w-fit px-2'
      onClick={handleClick}
      onMouseEnter={onMouseEnter}>
      {item?.title}
    </button>
  </div>
);
