const TickButton = (props: {
  height?: string;
  width?: string;
  active?: boolean;
  color?: any;
  colorTick?: any;
}) => {
  const height = props?.height || '2.1rem';
  const width = props?.width || '3.3rem';
  const fill = props?.color || '#E8EEF8';
  const fillTick = props?.colorTick || '#4378C8';
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 31 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <circle cx='15.5' cy='15.5' r='15.5' fill={fill} />
      <path
        d='M13.4279 22C13.0859 22 12.7439 21.8695 12.4815 21.6052L7.391 16.4763C6.86967 15.9511 6.86967 15.0979 7.391 14.5727C7.91234 14.0474 8.75911 14.0474 9.28045 14.5727L13.4279 18.7514L21.7196 10.3939C22.2409 9.86868 23.0877 9.86868 23.609 10.3939C24.1303 10.9192 24.1303 11.7724 23.609 12.2976L14.371 21.6052C14.112 21.8662 13.7699 22 13.4279 22Z'
        fill={fillTick}
      />
    </svg>
  );
};
export default TickButton;
