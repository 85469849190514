export default function (props: { height?: string; width?: string; fill?: string }) {
  const height = props?.height || '2rem';
  const width = props?.width || '2rem';
  const fill = props?.fill || '#595959';
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 26 26'
      fill='none'>
      <mask id='mask0_11099_10754' maskUnits='userSpaceOnUse' x='0' y='0' width='26' height='26'>
        <rect x='0.695312' y='0.839844' width='24.6096' height='24.6096' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_11099_10754)'>
        <path
          d='M6.84867 21.3478C5.99417 21.3478 5.26785 21.0487 4.6697 20.4505C4.07155 19.8524 3.77248 19.1261 3.77248 18.2716H1.72168V6.9922C1.72168 6.42823 1.92249 5.94544 2.3241 5.54383C2.72572 5.14221 3.20851 4.94141 3.77248 4.94141H18.128V9.043H21.2042L24.2804 13.1446V18.2716H22.2296C22.2296 19.1261 21.9306 19.8524 21.3324 20.4505C20.7343 21.0487 20.0079 21.3478 19.1534 21.3478C18.2989 21.3478 17.5726 21.0487 16.9745 20.4505C16.3763 19.8524 16.0773 19.1261 16.0773 18.2716H9.92486C9.92486 19.1261 9.62579 19.8524 9.02764 20.4505C8.42949 21.0487 7.70317 21.3478 6.84867 21.3478ZM6.84867 19.297C7.1392 19.297 7.38273 19.1987 7.57927 19.0022C7.7758 18.8056 7.87407 18.5621 7.87407 18.2716C7.87407 17.981 7.7758 17.7375 7.57927 17.541C7.38273 17.3444 7.1392 17.2462 6.84867 17.2462C6.55814 17.2462 6.31461 17.3444 6.11807 17.541C5.92154 17.7375 5.82327 17.981 5.82327 18.2716C5.82327 18.5621 5.92154 18.8056 6.11807 19.0022C6.31461 19.1987 6.55814 19.297 6.84867 19.297ZM19.1534 19.297C19.444 19.297 19.6875 19.1987 19.884 19.0022C20.0806 18.8056 20.1788 18.5621 20.1788 18.2716C20.1788 17.981 20.0806 17.7375 19.884 17.541C19.6875 17.3444 19.444 17.2462 19.1534 17.2462C18.8629 17.2462 18.6194 17.3444 18.4228 17.541C18.2263 17.7375 18.128 17.981 18.128 18.2716C18.128 18.5621 18.2263 18.8056 18.4228 19.0022C18.6194 19.1987 18.8629 19.297 19.1534 19.297ZM18.128 14.17H22.486L20.1788 11.0938H18.128V14.17Z'
          fill={fill}
        />
      </g>
    </svg>
  );
}
