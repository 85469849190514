import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import CsrAPI from '../../api/csrApi';
import { RootState } from '../../state/reducers';
import VendorAPI from '../../api/vendorApi';

const useFetchStations = () => {
  const { user } = useSelector((state: RootState) => state.userState);
  const { data: CSR_DATA } = useQuery(
    [`CSR_STATIONS`, user?.account_id],
    async (data) => {
      if (user?.role === 'fleetmax_csr') {
        return await CsrAPI.getStationCOdes();
      } else {
        return await VendorAPI.getRequestCompanies(user?.account_id);
      }
    },
    {
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );

  const totalShorts = useMemo(() => {
    return CSR_DATA?.data?.data;
  }, [CSR_DATA]);

  return { stations: totalShorts ?? [] };
};

export default useFetchStations;
