import moment from 'moment';
import CalenderSvg from '../../../assets/calender';
function AppointmentTime({
  item,
  noInfo,
  multiple,
}: {
  item: any;
  noInfo?: boolean;
  multiple?: boolean;
}) {
  const time = () => {
    if (multiple) {
      return (
        <div className='inline-flex items-center gap-3 pb-1 ml-2 whitespace-nowrap'>
          <CalenderSvg width='0.8rem' height='0.8rem' color='currentColor' />
          {moment(`${item?.date} ${item?.time}`, 'YYYY-MM-DD hh:mm:ss').format("DD MMM'YY")}
          <i className='pi pi-clock text-inherit text-sm'></i>
          {moment(`${item?.date} ${item?.time}`, 'YYYY-MM-DD hh:mm:ss').format('hh:mm a')}
        </div>
      );
    } else {
      if (!item?.appointments) return null;
      return (
        <div className='inline-flex items-center gap-2 ml-2 whitespace-nowrap'>
          <CalenderSvg width='0.8rem' height='0.8rem' color='currentColor' />
          {item?.appointments[0]?.date
            ? moment(
                `${item?.appointments[0]?.date} ${item?.appointments[0]?.time}`,
                'YYYY-MM-DD hh:mm:ss'
              ).format("DD MMM'YY")
            : 'N/A'}
          <i className='pi pi-clock text-inherit text-sm'></i>
          {item?.appointments[0]?.date
            ? moment(
                `${item?.appointments[0]?.date} ${item?.appointments[0]?.time}`,
                'YYYY-MM-DD hh:mm:ss'
              ).format('hh:mm a')
            : 'N/A'}
        </div>
      );
    }
  };
  return <div className='flex items-center'>{time()}</div>;
}

export default AppointmentTime;

export function UpdatedAtTime({
  item,
  noInfo,
  multiple,
}: {
  item: any;
  noInfo?: boolean;
  multiple?: boolean;
}) {
  const time = () => {
    return (
      <div className='inline-flex items-center gap-2 ml-2 whitespace-nowrap'>
        <CalenderSvg width='0.8rem' height='0.8rem' color='currentColor' />
        {moment(item?.updated_at).format("DD MMM'YY")}
        <i className='pi pi-clock text-inherit text-sm'></i>
        {moment(item?.updated_at).format('hh:mm a')}
      </div>
    );
  };
  return <div className='flex items-center'>{time()}</div>;
}
