export default function (props: { height?: string; width?: string; fill?: string }) {
  const height = props?.height || '2rem';
  const width = props?.width || '2rem';
  const fill = props?.fill || '#595959';
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 26 26'
      fill='none'>
      <mask id='mask0_11099_10751' maskUnits='userSpaceOnUse' x='0' y='0' width='26' height='26'>
        <rect x='0.0800781' width='25.8402' height='25.8402' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_11099_10751)'>
        <path
          d='M14.0793 9.69052V3.23047H22.6927V9.69052H14.0793ZM3.3125 13.9972V3.23047H11.9259V13.9972H3.3125ZM14.0793 22.6106V11.8439H22.6927V22.6106H14.0793ZM3.3125 22.6106V16.1506H11.9259V22.6106H3.3125ZM5.46585 11.8439H9.77255V5.38382H5.46585V11.8439ZM16.2326 20.4573H20.5393V13.9972H16.2326V20.4573ZM16.2326 7.53717H20.5393V5.38382H16.2326V7.53717ZM5.46585 20.4573H9.77255V18.3039H5.46585V20.4573Z'
          fill={fill}
        />
      </g>
    </svg>
  );
}
