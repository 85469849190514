import { combineReducers } from 'redux';
import userReducer from './userReducer';
import mainState from './mainReducer';

const reducers = combineReducers({
  userState: userReducer,
  mainState: mainState,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
