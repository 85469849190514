export default function (props: {
  height?: string;
  width?: string;
  active?: boolean;
  color?: string;
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1rem'
      height='1rem'
      viewBox='0 0 12 14'
      fill='none'>
      <path
        d='M10.5647 1.30667C10.4068 1.17873 10.2242 1.09445 10.0319 1.06067C9.83957 1.02688 9.64286 1.04455 9.45774 1.11222C8.96939 1.2666 8.46361 1.33997 7.9564 1.33C7.20683 1.28426 6.47188 1.07837 5.79088 0.723333C4.94959 0.285936 4.0396 0.040099 3.11338 0C1.11391 0 0.345942 0.777778 0.20757 0.886667C0.141365 0.959616 0.0888754 1.04678 0.0532201 1.14298C0.0175648 1.23918 -0.00052963 1.34245 1.1801e-05 1.44667V13.2222C1.1801e-05 13.4285 0.0729041 13.6263 0.202653 13.7722C0.332402 13.9181 0.508379 14 0.691872 14C0.875364 14 1.05134 13.9181 1.18109 13.7722C1.31084 13.6263 1.38373 13.4285 1.38373 13.2222V9.87778C1.93502 9.64049 2.52355 9.53199 3.11338 9.55889C3.86295 9.60463 4.5979 9.81052 5.2789 10.1656C6.12019 10.603 7.03018 10.8488 7.9564 10.8889C8.79044 10.9243 9.62014 10.7378 10.3779 10.3444C10.5821 10.2366 10.7553 10.0664 10.8776 9.85321C11 9.64005 11.0665 9.39253 11.0698 9.13889V2.41889C11.0695 2.20187 11.0236 1.98799 10.9359 1.7947C10.8481 1.60141 10.7209 1.43418 10.5647 1.30667ZM9.68605 9.01444C9.13455 9.25089 8.54619 9.35936 7.9564 9.33333C7.2059 9.28863 6.47035 9.07992 5.79088 8.71889C4.94799 8.28781 4.03866 8.04479 3.11338 8.00333C2.53049 7.99204 1.94885 8.06789 1.38373 8.22889V1.88222C1.93374 1.63904 2.52288 1.52777 3.11338 1.55556C3.86388 1.60026 4.59943 1.80897 5.2789 2.17C6.12179 2.60108 7.03112 2.8441 7.9564 2.88556C8.53964 2.89524 9.1214 2.81676 9.68605 2.65222V9.01444Z'
        fill={props?.active ? '#4378C8' : '#C0C0C0'}
      />
    </svg>
  );
}
