import { useEffect, useState } from 'react';
import ModalForm from '../../../../components/ModalForm';
import OverlayContainer from '../../../../components/OverlayContainer';
import useFetchCheckList from '../../../hooks/useFetchCheckList';
import ScrollableDiv from './ScrollDiv';

const BulkServices = ({ handleClose, item }: any) => {
  const [bulkSchedules, setBulkSchedules] = useState([] as any[]);
  const [selectedVehcile, setSelectedVehcile] = useState(null as any);
  const { checkList, getCheckList } = useFetchCheckList();
  const handleSelectedVechicle = (v: any) => {
    getCheckList(v);
    setSelectedVehcile(v);
  };

  useEffect(() => {
    if (item) {
      if (item?.preventive_request_id) {
        const prevArr = [...item.bulkSchedules];
        const selectedIndex: any = prevArr.findIndex(
          (d: any) => d.preventive_request_id === item?.preventive_request_id
        );
        const removed = prevArr.splice(selectedIndex, 1);
        handleSelectedVechicle(removed[0]);
        setBulkSchedules([...removed, ...prevArr]);
      } else {
        handleSelectedVechicle(item.bulkSchedules[0]);
        setBulkSchedules([...item.bulkSchedules]);
      }
    }
  }, [item]);

  return (
    <OverlayContainer show={true}>
      <ModalForm title={'Add CSR'} onClose={handleClose} width={'w-[60rem]'} noLabel>
        <div className='flex flex-col gap-4 h-[32rem]'>
          <p className='font-bold text-xl'>Services</p>
          <div className='flex flex-col gap-4 h-0 flex-1'>
            <ScrollableDiv
              value={bulkSchedules.map((el) => ({
                ...el,
                onClick: () => {
                  if (selectedVehcile?.preventive_request_id !== el.preventive_request_id) {
                    handleSelectedVechicle(el);
                  }
                },
              }))}
              itemCount={5}
              selectedVehcile={selectedVehcile}
            />
            <div className='grid grid-cols-2 gap-y-5 gap-x-32 overflow-y-auto pr-1'>
              {checkList.length > 0 &&
                checkList.map((item: any) => (
                  <div
                    className='px-2 pt-1 border border-solid border-slate-200 flex items-center rounded-lg cursor-pointer'
                    key={item?.id}>
                    <p className='p-2'>{item?.name || 'N/A'}</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </ModalForm>
    </OverlayContainer>
  );
};

export default BulkServices;
