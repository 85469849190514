import { Menu, MenuItem, Tooltip } from '@mui/material';
import * as React from 'react';
import Tootltip from '../../../../components/BarData/Tootltip';

function SelectBtnSmall(props: {
  classes?: string;
  svg?: any;
  title: string;
  value?: string;
  onClick: (e: any) => void;
  up: boolean;
  isInCorrect?: boolean;
  onlyTitle?: boolean;
  haveDivider?: boolean;
  readOnly: boolean;
}) {
  const { classes, svg, readOnly, title, value, onClick, up, isInCorrect, onlyTitle, haveDivider } =
    props;
  return (
    <div
      className={`py-1 flex flex-row ${
        isInCorrect || readOnly ? '' : 'bg-tableHead'
      } ${classes} items-center gap-4 rounded-lg px-2 transition duration-500 h-fit  ${
        readOnly ? '' : 'cursor-pointer '
      }`}
      onClick={(e: any) => {
        if (!readOnly) {
          onClick(e);
        }
      }}>
      {svg}
      <div className={`w-full  ${haveDivider && 'border-r-2'} pr-2`}>
        <div className='flex gap-1 items-center'>
          <p className={` text-primary ${onlyTitle ? 'text-lg' : 'text-sm'}`}>{title}</p>
          {value === 'MM/DD/YYYY' && !onlyTitle && (
            <Tooltip title={'Only Valid Date Will Be Consider To Schedule'} placement='top'>
              <i className='pi pi-info-circle hover:bg-blue-100 rounded-full text-[0.85rem] '></i>
            </Tooltip>
          )}
        </div>
        {!onlyTitle && (
          <Tootltip placeholder={value}>
            <p className='text-md whitespace-nowrap overflow-hidden text-ellipsis w-[7rem] text-left text-[0.9rem] capitalize'>
              {value}
            </p>
          </Tootltip>
        )}
      </div>
      {!readOnly && (
        <i
          className={`pi pi-angle-up ${
            up ? 'rotate-0' : 'rotate-180'
          } ease-in-out duration-200 text-primary`}
        />
      )}
    </div>
  );
}

export default function BasicPopover({
  title,
  value,
  options,
  toSchedule,
  readOnly,
}: {
  title: string;
  value: string;
  toSchedule?: string;
  options: any[];
  readOnly: boolean;
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <SelectBtnSmall
        title={title}
        onClick={handleClick}
        up={!!anchorEl}
        value={value}
        classes={'w-[11rem]'}
        readOnly={readOnly}
      />
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            minWidth: '11rem',
            marginTop: '0.5rem',
            boxShadow: '0px 1px 2px -1px rgba(0, 0, 0, 0.25)',
            border: '0.1rem solid #E4E4E4',
          },
        }}
        open={open}
        onClose={handleClose}
        defaultValue={toSchedule}>
        {options?.map((elem: { head: any; event: () => void }, index: number) => (
          <MenuItem
            key={elem.head}
            onClick={() => {
              handleClose();
              elem?.event && elem.event();
            }}
            sx={{
              backgroundColor: toSchedule === elem.head ? '#E8EEF8' : '',
            }}>
            {elem.head}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
