const ZoomOutIcon = (props: { height?: string; width?: string; active?: boolean }) => {
  const height = props?.height || '2.1rem';
  const width = props?.width || '3.3rem';
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 21 21'
      fill='none'>
      <mask
        id='mask0_8995_13339'
        // style='mask-type:alpha'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='21'
        height='21'>
        <rect width='21' height='21' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_8995_13339)'>
        <path
          d='M17.15 18.375L11.6375 12.8625C11.2 13.2125 10.6969 13.4896 10.1281 13.6938C9.55937 13.8979 8.95417 14 8.3125 14C6.72292 14 5.3776 13.4495 4.27656 12.3484C3.17552 11.2474 2.625 9.90208 2.625 8.3125C2.625 6.72292 3.17552 5.3776 4.27656 4.27656C5.3776 3.17552 6.72292 2.625 8.3125 2.625C9.90208 2.625 11.2474 3.17552 12.3484 4.27656C13.4495 5.3776 14 6.72292 14 8.3125C14 8.95417 13.8979 9.55937 13.6938 10.1281C13.4896 10.6969 13.2125 11.2 12.8625 11.6375L18.375 17.15L17.15 18.375ZM8.3125 12.25C9.40625 12.25 10.3359 11.8672 11.1016 11.1016C11.8672 10.3359 12.25 9.40625 12.25 8.3125C12.25 7.21875 11.8672 6.28906 11.1016 5.52344C10.3359 4.75781 9.40625 4.375 8.3125 4.375C7.21875 4.375 6.28906 4.75781 5.52344 5.52344C4.75781 6.28906 4.375 7.21875 4.375 8.3125C4.375 9.40625 4.75781 10.3359 5.52344 11.1016C6.28906 11.8672 7.21875 12.25 8.3125 12.25ZM6.125 9.1875V7.4375H10.5V9.1875H6.125Z'
          fill='white'
        />
      </g>
    </svg>
  );
};
export default ZoomOutIcon;
