import { GetInfoIconCSR } from './InfoIcon';
import OneSentSvg from '../../../../assets/InvoicingSvg/OneSent';
import { Tooltip } from '@mui/material';
import { BulkPriorityStatus } from '../../scheduleRequest/components/PriorityIcons';
export const checkInvoiceSent = (pmRequest: any) =>
  (['sent', 'updated'].includes(pmRequest?.dsp_invoice_status) &&
    !['sent', 'amount_submitted', 'amount_submitted_by_csr', 'updated'].includes(
      pmRequest?.vendor_invoice_status
    )) ||
  (['sent', 'amount_submitted', 'amount_submitted_by_csr', 'updated'].includes(
    pmRequest?.vendor_invoice_status
  ) &&
    !['sent', 'updated'].includes(pmRequest?.dsp_invoice_status));

export const PaymentIssue = (item: any) => {
  return vendorInvoiceAmountNotSame(item) ? <PaymentIssueComp /> : null;
};

export const vendorInvoiceAmountNotSame = (item: any) => {
  return ['raised', 'declined'].includes(item?.vendor_invoice_dispute);
};

const VechileInfo = ({ item, active, setSelectedVehicle }: any) => {
  return (
    <div className='h-auto  bg-white w-full'>
      <div
        className={`flex flex-col
      rounded-lg w-full border-[0.2rem] ${
        active ? ' border-[#E8EEF8] bg-tableHead' : 'border-white'
      }`}
        style={{
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
        }}>
        <div
          className='flex justify-between items-center cursor-pointer px-3 p-2'
          onClick={() => setSelectedVehicle(item)}>
          <div className='flex justify-between items-center gap-2'>
            <p className='flex justify-between items-center gap-2'>
              {item.vehicle.name}
              {!(item?.status_id === '1' && item.request_type === 'manual_dsp') && (
                <BulkPriorityStatus priority={item?.priority || '2'} />
              )}
              {+item.status_id === 3 && <p className='ml-2 mr-[-3rem] text-rose-500'>Rejected</p>}
              {vendorInvoiceAmountNotSame(item) && <PaymentIssueComp />}
              {checkInvoiceSent(item) && (
                <Tooltip title={'1 of 2 Invoices Sent'}>
                  <div className='-top-0.5 relative'>
                    <OneSentSvg />
                  </div>
                </Tooltip>
              )}
              {active && (
                <Tooltip title={'Selected Vehicle'}>
                  <i className='pi pi-circle-fill text-primary font-bold text-[0.4rem]'></i>
                </Tooltip>
              )}
            </p>
          </div>
          <div className='flex gap-2 items-center'>
            <div className='w-[3.5rem]'>
              <GetInfoIconCSR item={item} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PaymentIssueComp = () => (
  <Tooltip title={'Payment Discrepancy'}>
    <i className='pi pi-exclamation-circle mr-1 text-[#C55] font-bold text-[0.7rem]'></i>
  </Tooltip>
);

export default VechileInfo;
