import { Outlet } from 'react-router-dom';
import MaxLogo from '../../../assets/MaxLogo';
import FleetMax from '../../../assets/FleetMax';

export default function LayoutAuth() {
  return (
    <div className='auth-bg flex items-center h-auto lg:h-screen'>
      <div className='w-[40%] h-full lg:block hidden relative'>
        <img src='../images/Signbg.png' alt='auth bg' className='w-full object-cover h-screen' />
        <div className='absolute top-0 bottom-0 grid place-content-center w-full h-full text-center bg-light-bg'>
          <div className='relative bg-[url("/src/assets/svg/whiteblur.svg")] grid place-items-center'>
            {/* <img className='w-30 h-30 mx-auto z-10' src='../svg/LOGO.svg' alt='fleet logo' /> */}
            <div className='flex flex-col justify-center items-center ml-6 mt-[-3rem] z-10'>
              <MaxLogo width={'16.5rem'} height={'16.5rem'} />
              <div className='mt-[-2rem]'>
                <FleetMax height='8.5rem' width='21rem' />
              </div>
            </div>
            <div
              className='absolute rounded-full self-center'
              style={{
                boxShadow: '0px 0px 121px 183px white',
              }}></div>
          </div>
        </div>
      </div>
      <div className='w-full lg:w-3/5 mx-auto px-0 h-full flex items-start lg:items-center justify-center'>
        <div className='h-full flex items-center justify-content-start lg:justify-center flex-col lg:pb-0 max-h-screen overflow-y-auto pr-0 w-full'>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
