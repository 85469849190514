import { toast as realToast } from 'react-toastify';
import { call, put, takeEvery } from 'redux-saga/effects';
import ChatAPI from '../api/ChatAPI';
import InspectionAPI from '../api/csrInspection';

import { toast } from '../components/Toast/CustomToast';

import { getFinalIds } from '../pages/utils/getFinalIds';
import { ActionType } from '../state/action-types';
import CsrAPI from '../api/csrApi';
import PartnerApi from '../api/partnerSpecificApi';
import VendorAPI from '../api/vendorApi';

// Define an action type
const FETCH_CHAT_HEADS = 'FETCH_CHAT_HEADS';
const FETCH_CHAT_MESSAGES = 'FETCH_CHAT_MESSAGES';
const FETCH_CHATLIST_USERS = 'FETCH_CHATLIST_USERS';
const FETCH_CSR_PARTNER = 'FETCH_CSR_PARTNER';
const FETCH_VENDOR_PARTNER = 'FETCH_VENDOR_PARTNER';
const FETCH_TECHNICIANS_PARTNER = 'FETCH_TECHNICIANS_PARTNER';
const SET_CHAT_HEADS = 'SET_CHAT_HEADS';
const SET_CHAT_MESSAGES = 'SET_CHAT_MESSAGES';
const USER_LOADER = 'USER_LOADER';
const FETCH_CHATLIST_USERS_VENDOR = 'FETCH_CHATLIST_USERS_VENDOR';
const CHAT_LOADER = 'CHAT_LOADER';
const SET_UNREAD_MESSAGES_COUNT = 'SET_UNREAD_MESSAGES_COUNT';
const SET_GROUP_USERS_DETAILS = 'SET_GROUP_USERS_DETAILS';
const FETCH_INSPECION_DATA = 'FETCH_INSPECION_DATA';
const FETCH_INSPECION_DATA_VENDOR = 'FETCH_INSPECION_DATA_VENDOR';
const FETCH_VEHICLE_HISTORY = 'FETCH_VEHICLE_HISTORY';
const CHAT_HEADS_LOADER = 'CHAT_HEADS_LOADER';

function* fetchChatUserList(action: any): Generator<any, void, any> {
  try {
    yield put({ type: USER_LOADER, payload: true });
    const response: any = yield call(ChatAPI.getChatUserNames, action.payload);
    if (response.status === 200) {
      let formatedData: any[] = [];
      const data = response?.data?.data;
      if (data?.length > 0 && data?.[0]?.partner) {
        formatedData = data
          .map((element: any) => {
            if (element.partner) {
              return {
                user_name: element.partner.name,
                id: String(element.partner.id),
                type: 'fleetmax_partner',
                b_name: null,
              };
            }
            return null;
          })
          .filter((item: any) => item !== null);
      } else formatedData = data;
      yield put({ type: ActionType.SET_CHATLIST_USERS, payload: formatedData });
    } else {
      toast.error('Something went wrong');
    }
    yield put({ type: ActionType.USER_LOADER, payload: false });
  } catch (error) {
    toast.error('Something went wrong!');
    yield put({ type: ActionType.SET_CHATLIST_USERS, payload: [] });
    yield put({ type: ActionType.USER_LOADER, payload: false });
  }
}
function* fetchChatUserListVendor(action: any): Generator<any, void, any> {
  try {
    yield put({ type: USER_LOADER, payload: true });
    const response: any = yield call(ChatAPI.getChatUserNamesVendor, action.payload);
    if (response.status === 200) {
      yield put({ type: ActionType.SET_CHATLIST_USERS_VENDOR, payload: response?.data?.data });
    } else {
      toast.error('Something went wrong!');
    }
    yield put({ type: ActionType.USER_LOADER, payload: false });
  } catch (error) {
    toast.error('Something went wrong!');
    yield put({ type: ActionType.SET_CHATLIST_USERS_VENDOR, payload: [] });
    yield put({ type: ActionType.USER_LOADER, payload: false });
  }
}
function* fetchChatUserListPartnerVendor(action: any): Generator<any, void, any> {
  try {
    yield put({ type: USER_LOADER, payload: true });
    const response: any = yield call(PartnerApi.getAllVendor);
    if (response.status === 200) {
      yield put({
        type: ActionType.SET_CHATLIST_USERS,
        payload: response?.data?.data.map((item: any) => {
          return {
            b_name: '',
            user_name: item.vendor.name,
            id: item.vendor.id,
            type: 'fleetmax_vendor',
          };
        }),
      });
    } else {
      toast.error('Something went wrong!');
    }
    yield put({ type: ActionType.USER_LOADER, payload: false });
  } catch (error) {
    toast.error('Something went wrong!');
    yield put({ type: ActionType.SET_CHATLIST_USERS, payload: [] });
    yield put({ type: ActionType.USER_LOADER, payload: false });
  }
}
function* fetchChatUserListPartnerTechnicians(action: any): Generator<any, void, any> {
  try {
    yield put({ type: USER_LOADER, payload: true });
    const response: any = yield call(VendorAPI.getVendorsTechnician);
    if (response.status === 200) {
      yield put({
        type: ActionType.SET_CHATLIST_USERS,
        payload: response?.data?.data?.technicians_details.map((item: any) => {
          return {
            user_name: item?.technician_name,
            id: item?.technician_id,
            type: 'fleetmax_technician',
          };
        }),
      });
    } else {
      toast.error('Something went wrong!');
    }
    yield put({ type: ActionType.USER_LOADER, payload: false });
  } catch (error) {
    toast.error('Something went wrong!');
    yield put({ type: ActionType.SET_CHATLIST_USERS, payload: [] });
    yield put({ type: ActionType.USER_LOADER, payload: false });
  }
}
function* fetchChatUserListPartnerCsr(action: any): Generator<any, void, any> {
  try {
    yield put({ type: USER_LOADER, payload: true });
    const response: any = yield call(CsrAPI.get, { data: '' });
    if (response.status === 200) {
      const responseData = Object.keys(response.data.data).map((key) => {
        return {
          user_name: response?.data?.data[key]?.csr_name,
          id: response?.data?.data[key]?.csr_id,
          type: 'fleetmax_csr',
        };
      });
      yield put({
        type: ActionType.SET_CHATLIST_USERS,
        payload: responseData,
      });
    } else {
      toast.error('Something went wrong!');
    }
    yield put({ type: ActionType.USER_LOADER, payload: false });
  } catch (error) {
    toast.error('Something went wrong!');
    yield put({ type: ActionType.SET_CHATLIST_USERS, payload: [] });
    yield put({ type: ActionType.USER_LOADER, payload: false });
  }
}
function* fetchChatHeads(action: any): Generator<any, void, any> {
  // yield put({ type: ActionType.CHAT_HEADS_LOADER, payload: true });
  // yield put({ type: SET_CHAT_HEADS, payload: [] });
  try {
    yield put({ type: USER_LOADER, payload: action.payload?.loader || false });
    const response: any = yield call(ChatAPI.getChatHeads, action.payload?.data);
    if (response.status === 200) {
      yield put({ type: SET_CHAT_HEADS, payload: response?.data?.data?.data });
      const unreadMessagesCount = response?.data?.data?.metadata;
      if (action?.payload?.showNewMessge) {
        if (unreadMessagesCount) {
          const existingToast = realToast.isActive('info');
          if (existingToast) {
            realToast.update('info', {
              render: `(${+unreadMessagesCount?.allCount}) New Messages!!`,
            });
          } else {
            toast.info(`(${+unreadMessagesCount?.allCount}) New Messages!!`);
          }
        }
      }
      yield put({ type: SET_UNREAD_MESSAGES_COUNT, payload: unreadMessagesCount });
    } 
    yield put({ type: USER_LOADER, payload: false });
    // yield put({ type: ActionType.CHAT_HEADS_LOADER, payload: false });
  } catch (error) {
    toast.error('Something went wrong!');
    yield put({ type: SET_CHAT_HEADS, payload: [] });
    yield put({ type: SET_UNREAD_MESSAGES_COUNT, payload: [] });
    yield put({ type: USER_LOADER, payload: false });
    // yield put({ type: ActionType.CHAT_HEADS_LOADER, payload: false });
  }
}

function* fetchMessages(action: any): Generator<any, void, any> {
  try {
    yield put({ type: CHAT_LOADER, payload: action.payload?.loader || false });
    const response: any = yield call(ChatAPI.getMessages, action.payload?.data?.room_id);
    if (response.status === 200) {
      const messages = response?.data?.data;
      const membersIds: string[] = [];
      messages?.forEach((message: any) => {
        if (message?.type === 'broadcast_info') {
          membersIds.push(...message?.users?.mem?.map((d: any) => d?.toString()));
        }
      });
      const finalNewIds = getFinalIds(membersIds, action?.payload?.groupUsersDetails);
      if (finalNewIds?.length > 0) {
        const repsonse2 = yield call(ChatAPI.getMultipleUsersDetails, finalNewIds);
        if (repsonse2.status === 200) {
          const usersResponse = repsonse2?.data?.data;
          const groupUsersDetailsMap = action?.payload?.groupUsersDetails;
          usersResponse.forEach((user: { id: string; name: string }) => {
            groupUsersDetailsMap.set(+user.id, user?.name);
          });
          yield put({ type: SET_GROUP_USERS_DETAILS, payload: groupUsersDetailsMap });
        }
      }
      yield put({ type: SET_CHAT_MESSAGES, payload: messages });
    } else {
      toast.error('Something went wrong!');
    }

    yield put({ type: CHAT_LOADER, payload: false });
  } catch (error) {
    toast.error('Something went wrong!');
    yield put({ type: SET_CHAT_HEADS, payload: [] });
    yield put({ type: CHAT_LOADER, payload: false });
  }
}

function* fetchinspectionData(action: any): Generator<any, void, any> {
  try {
    yield put({ type: USER_LOADER, payload: true });
    const response: any = yield call(InspectionAPI.getInspectionData, action.payload);
    if (response.status === 200) {
      yield put({ type: ActionType.SET_INSPECION_DATA, payload: response?.data?.data });
    } else {
      toast.error('Something went wrong!');
    }
    yield put({ type: ActionType.USER_LOADER, payload: false });
  } catch (error) {
    toast.error('Something went wrong!');
    yield put({ type: ActionType.SET_INSPECION_DATA, payload: [] });
    yield put({ type: ActionType.USER_LOADER, payload: false });
  }
}
function* fetchinspectionDataVendor(action: any): Generator<any, void, any> {
  try {
    yield put({ type: USER_LOADER, payload: true });
    const response: any = yield call(InspectionAPI.getInspectionDataVedor, action.payload);
    if (response.status === 200) {
      yield put({ type: ActionType.SET_INSPECION_DATA_VENDOR, payload: response?.data?.data });
    } else {
      toast.error('Something went wrong!');
    }
    yield put({ type: ActionType.USER_LOADER, payload: false });
  } catch (error) {
    toast.error('Something went wrong!');
    yield put({ type: ActionType.SET_INSPECION_DATA_VENDOR, payload: [] });
    yield put({ type: ActionType.USER_LOADER, payload: false });
  }
}

function* fetchVehicleHistory(action: any): Generator<any, void, any> {
  try {
    yield put({ type: USER_LOADER, payload: true });
    const response: any = yield call(InspectionAPI.getHistory, action.payload);
    if (response.status === 200) {
      yield put({ type: ActionType.SET_VEHICLE_HISTORY, payload: response?.data?.data });
    } else {
      toast.error('Something went wrong!');
    }
    yield put({ type: ActionType.USER_LOADER, payload: false });
  } catch (error) {
    toast.error('Something went wrong!');
    yield put({ type: ActionType.SET_VEHICLE_HISTORY, payload: [] });
    yield put({ type: ActionType.USER_LOADER, payload: false });
  }
}

// Saga watcher function
function* watchFetchData() {
  yield takeEvery(FETCH_CHAT_HEADS, fetchChatHeads);
  yield takeEvery(FETCH_CHAT_MESSAGES, fetchMessages);
  yield takeEvery(FETCH_CHATLIST_USERS, fetchChatUserList);
  yield takeEvery(FETCH_CHATLIST_USERS_VENDOR, fetchChatUserListVendor);
  yield takeEvery(FETCH_INSPECION_DATA, fetchinspectionData);
  yield takeEvery(FETCH_INSPECION_DATA_VENDOR, fetchinspectionDataVendor);
  yield takeEvery(FETCH_VEHICLE_HISTORY, fetchVehicleHistory);
  yield takeEvery(FETCH_CSR_PARTNER, fetchChatUserListPartnerCsr);
  yield takeEvery(FETCH_VENDOR_PARTNER, fetchChatUserListPartnerVendor);
  yield takeEvery(FETCH_TECHNICIANS_PARTNER, fetchChatUserListPartnerTechnicians);
}

export default watchFetchData;
