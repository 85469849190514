import { Button, TextField } from '@mui/material';
export const DeclineBox = ({ setRejecting, noteRef, handleReject, noteInfo, btnInfo }: any) => (
  <div className='flex flex-col gap-5 items-center p-4 bg-white rounded-lg'>
    <p className='bg-primary-light text-primary p-2 rounded-xl  text-center'>
      {noteInfo
        ? noteInfo
        : 'Since you are declining the request, you need to provide a reason of the same'}
    </p>
    <TextField
      placeholder='Type your Reason'
      multiline
      className='w-full'
      inputRef={noteRef}
      sx={{
        '& .MuiOutlinedInput-root': {
          padding: '0.5rem',
          borderRadius: '0.8rem',
        },
        '& textarea': {
          height: '6rem !important',
        },
      }}
      inputProps={{
        maxLength: 200,
      }}></TextField>
    <div className='w-full flex justify-between gap-3'>
      {setRejecting && (
        <div className='w-1/3 border-[0.1rem] border-primary rounded-xl flex justify-center items-center'>
          <Button
            className='text-primary tracking-wider font-bold text-[1.02rem]'
            onClick={() => {
              setRejecting?.(false);
            }}>
            Cancel
          </Button>
        </div>
      )}
      <Button
        variant='contained'
        className={`${
          setRejecting ? 'w-2/3' : 'w-full'
        } tracking-wider flex font-bold text-[1.02rem]`}
        color='error'
        onClick={handleReject}>
        {btnInfo ? btnInfo : 'Submit & Decline'}
      </Button>
    </div>
  </div>
);
