import { Tooltip } from '@mui/material';
import { useRef, useState, useLayoutEffect } from 'react';
interface TooltipButtonProps {
  text: string;
  onClick?: () => void;
}
export const TwoLinesTooltip = ({ text, onClick }: TooltipButtonProps) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const hiddenSpanRef = useRef<HTMLSpanElement>(null);

  const checkOverflow = () => {
    const buttonElement = buttonRef.current;
    const hiddenSpanElement = hiddenSpanRef.current;

    if (buttonElement && hiddenSpanElement) {
      setIsOverflowing(hiddenSpanElement.scrollWidth > buttonElement.clientWidth);
    }
  };

  useLayoutEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, []);
  const buttonOnclick = onClick
    ? () => {
        onClick();
      }
    : () => {};
  const newText = text ? text : '';
  return (
    <div>
      <Tooltip title={isOverflowing ? newText : ''}>
        <button
          ref={buttonRef}
          className={`${
            onClick ? 'hover:bg-slate-100' : ''
          } p-2 rounded-lg border-none w-32 h-14 overflow-hidden text-center`}
          style={{
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            wordBreak: 'break-word',
          }}
          onClick={buttonOnclick}>
          {newText}
        </button>
      </Tooltip>

      <span
        ref={hiddenSpanRef}
        style={{
          visibility: 'hidden',
          position: 'absolute',
          top: '-2rem',
          whiteSpace: 'nowrap',
        }}>
        {newText}
      </span>
    </div>
  );
};
