import { Menu, MenuItem } from '@mui/material';
import { useState, MouseEvent } from 'react';

interface MonthSlotDropdownProps {
  setMonth: (month: string) => void;
  month: string;
}

export function MonthSlotDropdown({ setMonth, month }: MonthSlotDropdownProps) {
  const months = ['4 Months', '8 Months', '12 Months'];
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item: string) => {
    setMonth(item);
    handleClose();
  };

  return (
    <>
      <button
        className={`flex items-center ${
          month && 'border-primary'
        } border-[0.1rem] px-3 py-1 rounded-full w-fit justify-between hover:bg-primary-light hover:text-primary-dark transition-all capitalize`}
        style={{
          backgroundColor: month ? 'rgb(232,239,248)' : 'rgba(193, 193, 193, 0.12)',
        }}
        id='month-dropdown-button'
        aria-controls={open ? 'month-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <p
          className={`${
            month ? 'text-primary' : 'text-[#C9C9C9]'
          } font-bold text-[0.9rem] capitalize`}>
          {month || 'Select Month'}
        </p>
        <i
          className={`pi pi-angle-${
            open ? 'up' : 'down'
          } transition-transform duration-300 transform ml-2 ${
            month ? 'text-primary' : 'text-[#C9C9C9]'
          }`}
        />
      </button>

      <Menu
        id='month-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'month-dropdown-button' }}
        PaperProps={{ sx: { width: '7rem', marginTop: '0.5rem' } }}>
        {months.map((item) => (
          <MenuItem key={item} onClick={() => handleMenuItemClick(item)} selected={month === item}>
            {item}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
