import React from 'react';
import { CustomTooltip } from './Complete';
import { toast } from '../../../components/Toast/CustomToast';

interface CustomTooltipButtonProps {
  item: any;
  tooltipData: Record<string, any>;
  setBulkInfoModal: (modalState: { open: boolean; data: any }) => void;
  setRequestModal: (modalState: { open: boolean; data: any }) => void;
  fetchTooltipData: (bulkId: string) => Promise<void>;
  RequestViewBtn: (item: any, onClick: () => void, onMouseEnter: () => void) => JSX.Element;
}

const CustomTooltipButton: React.FC<CustomTooltipButtonProps> = ({
  item,
  tooltipData,
  setBulkInfoModal,
  setRequestModal,
  fetchTooltipData,
  RequestViewBtn,
}) => {
  const handleClick = () => {
    if (item?.bulk_id) {
      setBulkInfoModal({
        open: true,
        data: item,
      });
    } else {
      setRequestModal({
        open: true,
        data: item,
      });
    }
  };

  const handleMouseEnter = async () => {
    try {
      if (!tooltipData[item?.bulk_id] && item?.bulk_id) {
        await fetchTooltipData(item?.bulk_id);
      }
    } catch (error) {
      toast.error('Failed to fetch tooltip data');
    }
  };

  return (
    <CustomTooltip
      title={
        <div className='capitalize'>
          {tooltipData?.[item?.bulk_id]?.length > 0
            ? tooltipData[item?.bulk_id]?.map((dataItem: any) => (
                <div key={dataItem.vehicle_name}>{dataItem.vehicle_name}</div>
              ))
            : 'loading...'}
        </div>
      }>
      <div className='flex flex-col'>{RequestViewBtn(item, handleClick, handleMouseEnter)}</div>
    </CustomTooltip>
  );
};

export default CustomTooltipButton;
