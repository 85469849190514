import React from 'react'
import { Button } from 'primereact/button';


function PrimaranyBtn(props: any) {
  return (
    <Button {...props} />
  )
}

export default PrimaranyBtn