import AdapterDateFns from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDateRangePicker from '@mui/lab/StaticDateRangePicker';
import { Button, Popover, Select, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import FilterCalender from '../../assets/FilterCalender';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../state/reducers';
import HandleLoad from '../Loader/HandleLoad';
import { DateRangePickerDay } from '@mui/lab';

const Getweek = (dateString: any) => {
  const date = new Date(dateString);
  const timeZoneOffset =
    new Date(
      date.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })
    ).getTimezoneOffset() *
    60 *
    1000;
  const localDate = new Date(date.getTime() - timeZoneOffset);
  const year = localDate.getUTCFullYear();
  const weekNumber = Math.ceil(
    (localDate.getTime() - new Date(Date.UTC(year, 0, 1)).getTime()) / 604800000
  );

  const paddedWeekNumber = weekNumber.toString().length > 1 ? weekNumber : `0${weekNumber}`;
  const combinedString = year.toString() + paddedWeekNumber;
  const parsedValue = parseInt(combinedString);
  return parsedValue;
};

interface CustomCalenderProps {
  type?: string;
  auth?: any;
  report?: any;
  setRevenueType?: any;
  count?: any;
  setCount?: any;
  filterType?: any;
  setFilterType?: any;
  setCurrentPage?: any;
}
let type = 'daily';

const CustomCalender: React.FC<CustomCalenderProps> = (props) => {
  const {
    auth,
    report,
    setRevenueType,
    count,
    setCount,
    filterType,
    setFilterType,
    setCurrentPage,
  } = props;
  const [urlParams, setSearchParams]: any = useSearchParams();
  const theme: any = useTheme();
  const styles: any = useStyles(theme);
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState<any[]>([new Date(), new Date()]);
  const dispatch = useDispatch();
  // const { setFilterType } = bindActionCreators(actionCreators, dispatch);
  // const { filterType }: any = useSelector((state: RootState) => state.userState);
  const isInvoicePage =
    window.location.href.includes('invoice') ||
    window.location.href.includes('vendorRequestReport') ||
    window.location.href.includes('requestReport') ||
    window.location.href.includes('compareReport');
  const isVendorRequestReport = window.location.href.includes('vendorRequestReport');
  const [filterDate, setFilterDate] = useState<any>([null, null]);
  const { setMultiInvoiceItems } = bindActionCreators(actionCreators, dispatch);
  const loader = HandleLoad();
  const { multiSelectInvoiceItems }: any = useSelector((state: RootState) => state.userState);
  const getDateRange = (startOffset: number, endOffset: number): Date[] => {
    const startDate = new Date(new Date().setDate(new Date().getDate() - startOffset));
    const endDate = new Date(new Date().setDate(new Date().getDate() - endOffset));
    return [new Date(startDate), new Date(endDate)];
  };
  const getweek = (): Date[] => {
    const today = new Date();
    const sunday = new Date(today.setDate(today.getDate() - today.getDay())); // Get the Sunday of the current week
    const saturday = new Date(today.setDate(today.getDate() + 6)); // Get the Saturday of the current week
    return [sunday, saturday];
  };

  useEffect(() => {
    if (filterDate.length === 2 && filterDate[0] && filterDate[1]) {
      if (isInvoicePage) {
        urlParams.set('page_number', '1');
        setCurrentPage(1);
        if (filterType !== 'all') {
          urlParams.delete('date_type');
          setSearchParams(urlParams);
        }
        if (isInvoicePage && filterType === 'custom') {
          urlParams.set('date_type', 'custom');
        }
      } else {
        urlParams.set('offset', '1');
      }

      urlParams.set('start_date', moment(filterDate[0]).format('YYYY-MM-DD'));
      urlParams.set('end_date', moment(filterDate[1]).format('YYYY-MM-DD'));
      setSearchParams(urlParams);
    }
  }, [filterDate, isInvoicePage, isVendorRequestReport]);
  // useEffect(() => {
  //   if (isInvoicePage && filterType == '') {
  //     setFilterType('last_7_days');
  //     setMultiInvoiceItems([]);
  //     urlParams.delete('date_type');
  //     setSearchParams(urlParams);
  //   }
  //   if (isInvoicePage && filterType == 'last_7_days') {

  //   }
  // }, [filterType]);

  const filterTypes = useCallback((type: string) => {
    const today = new Date();
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    // const thisWeek = getDateRange(new Date().getDay(), 0);
    const thisWeek = getweek();
    const lastWeek = getDateRange(new Date().getDay() + 7, new Date().getDay() + 1);
    const last30Days = getDateRange(29, 0);

    const getLast7Days = () => {
      const endDate = new Date();
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 6); // Subtract 6 days to get a range of 7 days
      return [startDate, endDate];
    };
    const getAllDateData = () => {
      urlParams.set('date_type', 'all');
      urlParams.delete('start_date');
      urlParams.delete('end_date');
      setSearchParams(urlParams);
      return [null, null];
    };
    switch (type) {
      case 'today':
        return [today, today];
      case 'yesterday':
        return [yesterday, yesterday];
      case 'this_week':
        return thisWeek;
      case 'last_week':
        return lastWeek;
      case 'last_30_days':
        return last30Days;
      case 'last_7_days':
        return getLast7Days();
      case 'all':
        return [null, null];
      default:
        return [null, null];
    }
  }, []);

  // useEffect(() => {
  //   if (report?.reportList) {
  //     Report.getCustomReportFilter({
  //       sessionToken: auth.user.token,
  //       user: auth.user.account_id,
  //       query: `?filter_type=${
  //         report.reportList.report_type === 'daily' ? 'date_query' : 'week_query'
  //       }`,
  //     }).then((res: any) => {
  //       if (type === 'daily') {
  //         const start = res.data.data[0].dates.start;
  //         const end = res.data.data[0].dates.end;
  //         setDate([start, end]);
  //         dailydateType(start, end);
  //       } else {
  //         const start = res.data.data[0].weeks.start;
  //         const end = res.data.data[0].weeks.end;
  //         setDate([start, end]);

  //         //last week
  //         const startOfLastWeek = moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD');
  //         const endOfLastWeek = moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD');
  //         const isStartLastWeek = start === Getweek(startOfLastWeek);
  //         const isEndLastWeek = end === Getweek(endOfLastWeek);

  //         //this week
  //         const startOfWeek = moment().startOf('week').format('YYYY-MM-DD');
  //         const endOfWeek = moment().endOf('week').format('YYYY-MM-DD');
  //         const isStartCurrentWeek = start === Getweek(startOfWeek);
  //         const isEndCurrentWeek = end === Getweek(endOfWeek);

  //         if (isStartLastWeek && isEndLastWeek) {
  //           setFilterType('last_week');
  //         } else if (isStartCurrentWeek && isEndCurrentWeek) {
  //           setFilterType('this_week');
  //         } else {
  //           setFilterType('custom');
  //         }
  //       }
  //     });
  //   }
  // }, [auth.user.account_id, auth.user.token, report.reportList]);

  const dailydateType = (start: any, end: any) => {
    //today
    const today = moment().startOf('day');
    const isStartToday = moment(start).isSame(today, 'day');
    const isEndToday = moment(end).isSame(today, 'day');

    //yesterday
    const yesterday = moment().subtract(1, 'day').startOf('day');
    const isStartYesterday = moment(start).isSame(yesterday, 'day');
    const isEndYesterday = moment(end).isSame(yesterday, 'day');

    //this week
    const startOfWeek = moment().startOf('week');
    const endOfWeek = moment().endOf('week');
    const isStartCurrentWeek = moment(start).isBetween(startOfWeek, endOfWeek, 'day', '[]');
    const isEndCurrentWeek = moment(end).isBetween(startOfWeek, endOfWeek, 'day', '[]');

    //last week
    const startOfLastWeek = moment().subtract(1, 'week').startOf('week');
    const endOfLastWeek = moment().subtract(1, 'week').endOf('week');
    const isStartLastWeek = moment(start).isBetween(startOfLastWeek, endOfLastWeek, 'day', '[]');
    const isEndLastWeek = moment(end).isBetween(startOfLastWeek, endOfLastWeek, 'day', '[]');

    //last 30 days
    const startOfLast30Days = moment().subtract(30, 'days').startOf('day');
    const endOfToday = moment().endOf('day');

    const isStart30Days = moment(start).isBetween(startOfLast30Days, endOfToday, 'day', '[]');
    const isEnd30Days = moment(end).isBetween(startOfLast30Days, endOfToday, 'day', '[]');

    if (isStartToday && isEndToday) {
      setFilterType('today');
    } else if (isStartYesterday && isEndYesterday) {
      setFilterType('yesterday');
    } else if (isStartCurrentWeek && isEndCurrentWeek) {
      setFilterType('this_week');
    } else if (isStartLastWeek && isEndLastWeek) {
      setFilterType('last_week');
    } else if (isStart30Days && isEnd30Days) {
      setFilterType('last_30_days');
    } else {
      setFilterType('custom');
    }
  };

  useEffect(() => {
    const dates: Date[] | any[] = filterTypes(filterType);
    setFilterDate(dates);
  }, [filterType, filterTypes]);
  // useEffect(() => {
  //   if (isVendorDashboard) {
  //     setFilterType('this_week');
  //   }
  // }, []);
  const handleSaveDate = () => {
    const data: any = {};
    if (type === 'daily') {
      data.filter_type = 'date_query';
      data.field_name = 'date';
      data.start = moment(filterDate[0]).format('YYYY-MM-DD');
      data.end = moment(filterDate[1]).format('YYYY-MM-DD');
      const reportdate = report.reportFilter?.find(
        (item: any) => item.filter_type === 'date_query'
      );
      data.id = reportdate._id;
    } else {
      data.filter_type = 'week_query';
      data.field_name = 'week_number';
      data.start = Getweek(filterDate[0]);
      data.end = Getweek(filterDate[1]);
      const reportdate = report.reportFilter?.find(
        (item: any) => item.filter_type === 'week_query'
      );
      data.id = reportdate._id;
    }

    // Report.updateCustomReportFilter({
    //   sessionToken: auth.user.token,
    //   user: auth.user.account_id,
    //   data: data,
    // })
    //   .then((res: any) => {
    //     setOpen(false);
    //     if (type === 'daily') {
    //       const start = res.data.data.dates.start;
    //       const end = res.data.data.dates.end;
    //       setDate([start, end]);
    //     } else {
    //       const start = res.data.data.weeks.start;
    //       const end = res.data.data.weeks.end;
    //       setDate([start, end]);
    //     }
    //   })
    //   .catch((err: any) => {
    //     console.log(err);
    //   });
  };

  function generateYearOptions() {
    const currentYear = moment().year();
    const yearOptions = [];

    for (let year = currentYear - 10; year <= currentYear + 10; year++) {
      yearOptions.push(
        <option key={year} value={year}>
          {year}
        </option>
      );
    }

    return yearOptions;
  }

  const filterApplied = useMemo(
    () => filterDate.length === 2 && filterDate[0] && filterDate[1],
    [filterDate]
  );
  function convertStringToReadable(string: string) {
    const words = string.split('_');
    const readableString = words
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
    return readableString;
  }

  return (
    <>
      <Button
        // className={styles.calenderBtn}
        disabled={!urlParams.get('page_number') && isInvoicePage}
        onClick={(event: any) => {
          setAnchor(event.currentTarget);
          setOpen(true);
        }}
        sx={{
          backgroundColor: filterApplied ? (!isInvoicePage ? theme.palette.primary.main : '') : '',
          border: window.location.href.includes('compareReport') ? '0.3rem solid #DDDDDD' : '',

          '&:hover': {
            backgroundColor: filterApplied
              ? !isInvoicePage
                ? theme.palette.primary.main
                : window.location.href.includes('compareReport')
                ? ''
                : 'transparent'
              : window.location.href.includes('requestReport')
              ? 'transparent'
              : theme.palette.primary.light,
          },
          padding: '0.5rem 1rem',
          '& .MuiButton-endIcon': {
            marginLeft: 0,
          },
        }}
        // startIcon={<CalendarMonthIcon color={filterApplied && '#fff'} />}
        startIcon={
          <p className={`flex items-center gap-2 `}>
            <p
              className={`text-[1rem]  font-bold text-${
                filterApplied && `${!isInvoicePage ? 'white' : ''}`
              } ${filterApplied && ''}`}>
              {!isInvoicePage ? 'Filter By' : convertStringToReadable(filterType)}
            </p>
            <FilterCalender color={filterApplied && `${!isInvoicePage ? 'white' : ''}`} />
          </p>
        }
        endIcon={
          <i
            className={`pi pi-angle-up ${
              open ? 'rotate-0' : 'rotate-180'
            } ease-in-out duration-200 ${
              filterApplied ? `${!isInvoicePage ? 'text-white' : 'text-primary'}` : 'text-primary'
            } text-lg`}
          />
        }>
        {/* {type === 'daily' && (
          <>
            {typeof date[0] === 'string' ? (
              <Typography variant='subtitle2'>
                {moment(date[0])?.isSame(date[1])
                  ? moment(date[0])?.utc().format('MMM DD, YYYY')
                  : `${moment(date[0])?.utc().format('MMM DD, YYYY')} - ${moment(date[1])
                      ?.utc()
                      .format('MMM DD, YYYY')}`}
              </Typography>
            ) : (
              <Box className={styles.placeholderText}>MMM DD, YYYY</Box>
            )}
          </>
        )}
        {type === 'weekly' && (
          <>
            {typeof date[0] === 'number' ? (
              <Typography variant='subtitle2'>
                {date?.[0]?.toString() === date[1].toString()
                  ? `Week ${date[0]?.toString().slice(-2)}`
                  : `Week ${date[0]?.toString().slice(-2)} - Week ${date[1].toString().slice(-2)}`}
              </Typography>
            ) : (
              <Box className={styles.placeholderText}>Week No</Box>
            )}
          </>
        )} */}
      </Button>

      <Popover
        open={open}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => {
          setOpen(false);
          setAnchor(null);
        }}>
        <Stack direction='row' sx={{ p: 1 }}>
          <Stack spacing={2} className={styles.filterContainer}>
            {/* {type === 'daily' && (
              <Typography
                variant='subtitle1'
                className={styles.filterStyle}
                sx={
                  filterType === 'yesterday'
                    ? { color: theme.palette.primary.main }
                    : { color: theme.palette.grey[600] }
                }
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  setFilterType('yesterday');
                }}>
                Yesterday
              </Typography>
            )} */}
            {type === 'daily' && !isInvoicePage && (
              <Typography
                variant='subtitle1'
                className={styles.filterStyle}
                sx={
                  filterType === 'today'
                    ? {
                        color: theme.palette.primary.main,
                        backgroundColor: theme.palette.primary.light,
                      }
                    : { color: theme.palette.grey[800] }
                }
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  setFilterType('today');
                }}>
                Today
              </Typography>
            )}
            {!isInvoicePage && (
              <Typography
                variant='subtitle1'
                className={styles.filterStyle}
                sx={
                  filterType === 'this_week'
                    ? {
                        color: theme.palette.primary.main,
                        backgroundColor: theme.palette.primary.light,
                      }
                    : { color: theme.palette.grey[800] }
                }
                onClick={() => {
                  setFilterType('this_week');
                }}>
                This Week
              </Typography>
            )}
            {isInvoicePage && (
              <Typography
                variant='subtitle1'
                className={styles.filterStyle}
                sx={
                  filterType === 'all'
                    ? {
                        color: theme.palette.primary.main,
                        backgroundColor: theme.palette.primary.light,
                      }
                    : { color: theme.palette.grey[800] }
                }
                onClick={() => {
                  setFilterType('all');
                  if (isInvoicePage) {
                    urlParams.set('date_type', 'all');
                    urlParams.delete('start_date');
                    urlParams.delete('end_date');
                    setSearchParams(urlParams);
                    setMultiInvoiceItems([]);
                  }
                }}>
                All
              </Typography>
            )}
            {isInvoicePage && (
              <Typography
                variant='subtitle1'
                className={styles.filterStyle}
                sx={
                  filterType === 'last_7_days'
                    ? {
                        color: theme.palette.primary.main,
                        backgroundColor: theme.palette.primary.light,
                      }
                    : { color: theme.palette.grey[800] }
                }
                onClick={() => {
                  setFilterType('last_7_days');
                  if (isInvoicePage) {
                    if (urlParams.get('start_date')) {
                      setRevenueType('last_7_days');
                      setFilterType('last_7_days');
                      setMultiInvoiceItems([]);
                      urlParams.delete('date_type');
                      setSearchParams(urlParams);
                    }
                  }
                }}>
                Last 7 Days
              </Typography>
            )}
            {/* <Typography
              variant='subtitle1'
              className={styles.filterStyle}
              sx={
                filterType === 'last_week'
                  ? { color: theme.palette.primary.main }
                  : { color: theme.palette.grey[600] }
              }
              onClick={() => {
                setFilterType('last_week');
              }}>
              Last week
            </Typography> */}
            {type === 'daily' && (
              <Typography
                variant='subtitle1'
                className={styles.filterStyle}
                sx={
                  filterType === 'last_30_days'
                    ? {
                        color: theme.palette.primary.main,
                        backgroundColor: theme.palette.primary.light,
                      }
                    : { color: theme.palette.grey[800] }
                }
                onClick={() => {
                  setFilterType('last_30_days');
                  if (isInvoicePage) {
                    setRevenueType('last_30_days');
                    setMultiInvoiceItems([]);
                    urlParams.delete('date_type');
                    setSearchParams(urlParams);
                  }
                }}>
                Last 30 days
              </Typography>
            )}
            <Typography
              variant='subtitle1'
              className={styles.filterStyle}
              sx={
                filterType === 'custom'
                  ? {
                      color: theme.palette.primary.main,
                      backgroundColor: theme.palette.primary.light,
                    }
                  : { color: theme.palette.grey[800] }
              }
              onClick={() => {
                if (isInvoicePage) {
                  urlParams.delete('date_type');
                  setSearchParams(urlParams);
                  setMultiInvoiceItems([]);
                }

                setFilterType('custom');
              }}>
              Custom
            </Typography>
            <Typography
              variant='subtitle1'
              className={styles.filterStyle}
              sx={{
                color: theme.palette.error.main,
                '&:hover': {
                  backgroundColor: `${theme.palette.error.light} !important`,
                },
              }}
              onClick={() => {
                if (isInvoicePage) {
                  urlParams.delete('date_type');
                  setSearchParams(urlParams);
                  setRevenueType('last_7_days');
                  setOpen(false);
                  setMultiInvoiceItems([]);
                  setFilterType('last_7_days');
                } else {
                  urlParams.delete('start_date');
                  urlParams.delete('end_date');
                  setSearchParams(urlParams);
                  setFilterType('');
                }
              }}>
              Remove Filter
            </Typography>
          </Stack>
          <Stack spacing={1} className={styles.calendar}>
            {/* {filterType !== 'custom' && ( */}
            {/* <Stack spacing={1} pl={3} mt={2}>
              <Typography
                variant='subtitle2'
                sx={{ textTransform: 'uppercase', letterSpacing: '1px' }}>
                Select date range
              </Typography>
              {filterDate[0] && filterDate[1] && (
                <Typography variant='h6'>
                  {moment(filterDate[0]).format('MMM')}&nbsp;
                  {moment(filterDate[0]).date()} - {moment(filterDate[1]).format('MMM')}&nbsp;
                  {moment(filterDate[1]).date()}
                </Typography>
              )}
            </Stack> */}
            {/* )} */}

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticDateRangePicker
                // key={filterDate}
                showToolbar={false}
                // disableFuture={true}
                calendars={1}
                value={filterDate}
                // disabled={filterType === 'custom' ? false : true}
                readOnly={filterType === 'custom' ? false : true}
                disableAutoMonthSwitching={filterType === 'custom' ? false : true}
                onChange={(newValue: any) => {
                  if (newValue[0] && newValue[1]) {
                    setFilterDate([
                      moment(newValue[0]).format('YYYY-MM-DD'),
                      moment(newValue[1]).format('YYYY-MM-DD'),
                    ]);
                  }
                }}
                renderDay={(date: any, DateRangePickerDayProps: any) => {
                  const isSelected = DateRangePickerDayProps.selected;
                  const isCustomFilter = filterType !== 'custom';
                  const startDate = new Date(urlParams.get('start_date'));
                  const endDate = new Date(urlParams.get('end_date'));
                  const currentDate = new Date(date);
                  const isInRange = currentDate >= startDate && currentDate <= endDate;
                  return (
                    <DateRangePickerDay
                      {...DateRangePickerDayProps}
                      sx={{
                        color:
                          isInRange && isCustomFilter
                            ? 'white'
                            : isSelected && isCustomFilter
                            ? 'white'
                            : isCustomFilter
                            ? 'gray'
                            : 'inherit',
                        backgroundColor: isSelected && isCustomFilter ? 'gray' : 'inherit',
                      }}
                    />
                  );
                }}
                renderInput={(startProps: any, endProps: any) => (
                  <React.Fragment>
                    <Select
                      sx={{
                        marginBottom: '5rem',
                      }}
                      native
                      value={moment(filterDate[0]).month() + 1}
                      onChange={(e: any) => {
                        const selectedMonth = parseInt(e.target.value);
                        setFilterDate([
                          moment(filterDate[0])
                            .month(selectedMonth - 1)
                            .format('YYYY-MM-DD'),
                          moment(filterDate[1])
                            .month(selectedMonth - 1)
                            .format('YYYY-MM-DD'),
                        ]);
                      }}>
                      {moment.months().map((month, index) => {
                        return (
                          <option key={month} value={index + 1}>
                            {month}
                          </option>
                        );
                      })}
                    </Select>

                    <Select
                      sx={{
                        marginBottom: '5rem',
                      }}
                      native
                      value={moment(filterDate[0]).year()}
                      onChange={(e: any) => {
                        const selectedYear = parseInt(e.target.value);
                        setFilterDate([
                          moment(filterDate[0]).year(selectedYear).format('YYYY-MM-DD'),
                          moment(filterDate[1]).year(selectedYear).format('YYYY-MM-DD'),
                        ]);
                      }}>
                      {generateYearOptions()}
                    </Select>
                  </React.Fragment>
                )}
              />
            </LocalizationProvider>
          </Stack>
        </Stack>
        {/* <Stack direction='row' spacing={2} className={styles.btnBox}>
          <Typography
            variant='subtitle1'
            className={styles.cancelBtn}
            onClick={() => {
              setAnchor(null);
              setOpen(false);
            }}>
            Cancel
          </Typography>
          <Typography variant='subtitle1' className={styles.btn} onClick={handleSaveDate}>
            OK
          </Typography>
        </Stack> */}
      </Popover>
    </>
  );
};

const useStyles: any = makeStyles((theme: any, filterApplied?: boolean) => ({
  filterContainer: {
    width: '10rem',
    // alignItems: 'end',
    padding: '0.7rem',
    paddingTop: '1.4rem',
  },
  filterStyle: {
    cursor: 'pointer',
    width: '100%',
    borderRadius: '0.5rem',
    padding: '0.2rem 0.5rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  btnBox: {
    position: 'absolute',
    bottom: '0.5rem',
    right: '2rem',
  },
  btn: {
    padding: '1rem',
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  cancelBtn: {
    padding: '1rem',
    cursor: 'pointer',
    backgroundColor: '#fff !important',
    color: theme.palette.primary.main,
  },
  calendar: {
    border: `0.1rem solid #D3D3D3`,
    borderRadius: '0.6rem',
    padding: '0.3rem',
  },
  calenderBtn: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  placeholderText: {
    color: theme.palette.grey[400],
  },
}));

export default CustomCalender;
