import { Button, Tooltip } from '@mui/material';
import { useMemo } from 'react';
import UploadProof from '../../../../assets/JobCompletedOrUploadProofs';
import { viewInvoiceCheck } from '../../../vender/components/Approved';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/reducers';
import AcceptRejectHandler from '../csr/components/AcceptRejectHandler';
import ActionAccess from '../components/ActionAccess';
import InvoicingPopUp from '../../../common/requestDetail/InvoicingExpandPopUp';
import { getDspInvoiceStatus, getVendorInvoiceStatus } from '../../primaryComponents/Invoice';
import Logs from '../../../common/requestDetail/MaintainanceLogsModel';

const GetActions = ({ item }: any) => {
  const { requestServices, user }: any = useSelector((state: RootState) => state.userState);
  const checklistData = requestServices[item?.id];

  const hasProof = useMemo(() => {
    if (checklistData && checklistData.length > 0) {
      const AllProofs: any = [];
      checklistData?.forEach((el: any) => {
        if (el?.proofs?.length > 0) {
          AllProofs.push(...el.proofs);
        }
        if (el?.titles?.length > 0) {
          el?.titles?.forEach((obj: any) => {
            if (obj.proofs?.length > 0) {
              AllProofs.push(...obj.proofs);
            }
          });
        }
      });
      return AllProofs.length !== 0;
    }
    return false;
  }, [checklistData]);
  return (
    <>
      {/** to do */}
      {/* assign technician */}
      {item.status_id === '4' && +item?.sub_stage === 2 && (
        <Button
          variant='contained'
          color={'primary'}
          className='px-4 font-bold'
          onClick={() =>
            item?.setAssignTechnicianModal({
              open: true,
              data: item,
            })
          }>
          Assign Technician
        </Button>
      )}

      {/* {item.status_id === '4' && (
        <Button
          variant='contained'
          color={'primary'}
          startIcon={<i className='pi pi-cross'></i>}
          className='px-4 font-bold'
          onClick={() =>
            item?.setAssignTechnicianModal({
              open: true,
              data: { ...item, title: `Request-${item?.preventive_request_id}` },
            })
          }>
          Request Declined
        </Button>
      )} */}
      {/* {item.status_id === '5' && (
        <BtnPopUp
          options={[
            { head: 'Re-Assign', event: () => {} },
            { head: 'Un-Assign', event: () => {} },
          ]}
        />

        <div className='py-2 px-3 flex justify-between bg-slate-200 rounded-lg items-center'>
          <p>{item.technician.name}</p>
          <div className='flex justify-between  divide-x-2 divider-slate-500 items-center'>
            <Divider orientation='vertical' sx={{ borderWidth: '1px', height: '1rem' }} />
            <Tooltip title={'Remove'} placement='bottom'>
              <i
                className='pi pi-times-circle mx-2 text-red-800 cursor-pointer'
                onClick={() => {
                  item?.handleRemoveTechnician(item);
                }}></i>
            </Tooltip>
          </div>
        </div>
      )} */}
      {/* {item.status_id === '6' && (
        <Button variant='text' color={'primary'} className='px-4 font-bold cursor-auto'>
          Technician started working
        </Button>
      )} */}
      {[7, 8].includes(+item.status_id) && (
        <div
          className='py-1 px-3 flex  bg-[#E5F8BD] items-center rounded-xl pl-[-0.5rem] cursor-pointer'
          onClick={() =>
            item?.setImageModal({
              open: true,
              data: item,
            })
          }>
          {/* <ServicesDetails checklistData={checklistData} item={item} /> */}
          <div className='ml-[-0.8rem]'>
            <UploadProof height='2.2rem' />
          </div>
          <p className='text-[#3E9983]  text-sm font-bold ml-[-0.5rem]'>
            {hasProof ? 'Re Upload Proofs' : 'Upload Proofs'}
          </p>
        </div>
      )}

      {item.status_id === '9' && (
        <ActionAccess item={item}>
          <div className='w-full flex flex-col gap-2 '>
            {!item?.lease_plan && (
              <div className='py-2 px-3 flex justify-center bg-slate-200 items-center rounded-lg'>
                <span
                  className='text-blue-600 cursor-pointer  font-bold '
                  onClick={() =>
                    item?.setLeasePlanModal({
                      open: true,
                      data: item,
                    })
                  }>
                  {'Lease Plan Update'}
                </span>
              </div>
            )}
            {item?.lease_plan && (
              <>
                {/* <Button
                variant='outlined'
                color={'primary'}
                className='font-bold w-full mx-auto'
                onClick={() =>
                  item?.setLeasePlanModal({
                    open: true,
                    data: item,
                  })
                }>
                View Lease Plan
              </Button> */}
                <div className='w-full  bg-[#F3F3F3] flex justify-center p-2 rounded-lg pr-9'>
                  <div
                    className='font-bold text-primary   border-primary'
                    onClick={() =>
                      item?.setLeasePlanModal({
                        open: true,
                        data: item,
                      })
                    }>
                    <button className='border-transparent'> View Lease Plan </button>
                  </div>
                </div>
                <InvoicingPopUp
                  item={item}
                  handleClick={(onClicked: string) => {
                    item?.setInvoiceModal({
                      open: true,
                      data: {
                        ...item,
                        onClicked,
                      },
                    });
                  }}
                />
              </>
            )}
          </div>
        </ActionAccess>
      )}

      {item.status_id === '8' && (
        <ActionAccess item={item}>
          <AcceptRejectHandler item={item} hasProof={hasProof} />{' '}
        </ActionAccess>
      )}
      {user.role === 'fleetmax_partner' &&
        (getDspInvoiceStatus(item).text !== 'Sent' ||
          getVendorInvoiceStatus(item).text !== 'Sent') &&
        +item.status_id === 11 && (
          <ActionAccess item={item}>
            <Tooltip
              title={!item?.lease_plan ? 'Lease Plan Data Missing' : ''}
              placement='top'
              arrow>
              <span>
                <InvoicingPopUp
                  item={item}
                  handleClick={(onClicked: string) => {
                    item?.setInvoiceModal({
                      open: true,
                      data: {
                        ...item,
                        onClicked,
                      },
                    });
                  }}
                />
              </span>
            </Tooltip>
          </ActionAccess>
        )}
      {item.status_id === '10' && (
        <div className='flex justify-between bg-red-200 items-center rounded-lg text-red-600 my-1'>
          <div className='flex gap-3 items-center py-1 px-3'>
            <i className='pi pi-times-circle' style={{ fontSize: '1rem' }}></i>
            <p>Rejected</p>
          </div>
          <Button
            variant='contained'
            color={'error'}
            className='px-4 font-bold cursor-pointer h-[2.5rem] w-[8rem] rounded-l-none'
            onClick={() => {
              item?.setAssignTechnicianModal({
                open: true,
                data: item,
              });
            }}>
            Re-Assign
          </Button>
        </div>
      )}
      {viewInvoiceCheck(item) && (
        <div className='flex justify-between bg-slate-200 items-center rounded-lg text-primary my-1'>
          <div className='flex gap-3 items-center py-1 px-3'>
            <i className='pi pi-money-bill' style={{ fontSize: '1rem' }}></i>
            <p>
              Invoice {user.account_id === item?.invoice?.invoice_created_by ? 'Sent' : 'Received'}
            </p>
          </div>
          <Button
            variant='contained'
            color={'primary'}
            className='px-4 font-bold cursor-pointer h-[2.5rem] w-[8rem] rounded-l-none'
            onClick={() => {
              item?.setMakePayment({
                open: true,
                data: { ...item, title: `Request-${item?.preventive_request_id}` },
              });
            }}>
            View
          </Button>
        </div>
      )}
      <Logs type='partner_logs' item={item} />
    </>
  );
};

export default GetActions;
