import { useState } from 'react';
import CsrAPI from '../../api/csrApi';
import HandleLoad from '../../components/Loader/HandleLoad';

export interface SummaryItem {
  id: number;
  question: string;
  answer: string;
}

export const useFetchSummary = () => {
  const loader = HandleLoad();
  const [sendLoader, setSendLoader] = useState(false);
  const [summary, setSummary] = useState([] as SummaryItem[]);
  const filterData = (currentData: any) => {
    const finalData: SummaryItem[] = [];
    if (!currentData || currentData.length === 0) return finalData;
    const mainObject = currentData[0]['data'];
    Object.keys(mainObject)?.forEach((d: string) => {
      const question = mainObject[d];
      if (Object.keys(mainObject[d]).length > 0 && typeof mainObject[d] === 'object') {
        finalData.push({
          id: +question.id,
          question: question.name,
          answer: question.answer,
        });
      }
    });
    return finalData;
  };
  const getSummary = (data: { vehicle_id: string }) => {
    loader(true);
    setSendLoader(true);
    CsrAPI.getRtsInspectionSummary({ vehicle_id: data.vehicle_id })
      .then((res: any) => {
        if (res.status === 200) {
          setSummary(filterData(res?.data?.data));
        }
        setSendLoader(false);
        loader(false);
      })
      .catch((err) => console.log(err));
  };
  const getLatestInspectionSummary = (data: {
    vehicle_id: string;
    preventive_request_id: string;
  }) => {
    loader(true);
    setSendLoader(true);
    CsrAPI.getSelectedInspectionSummary({
      vehicle_id: data.vehicle_id,
      preventive_request_id: data.preventive_request_id,
    })
      .then((res: any) => {
        if (res.status === 200) {
          setSummary(filterData(res?.data?.data));
        }
        setSendLoader(false);
        loader(false);
      })
      .catch((err) => console.log(err));
  };
  return { getSummary, summary, getLatestInspectionSummary, sendLoader };
};
