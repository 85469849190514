import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/reducers';

function DropDown({
  selected,
  options,
  handlDropDownClick,
  dropBtnSx,
  mainBtnSx,
  name,
}: {
  selected: string;
  options: string[];
  handlDropDownClick: (item: string) => void;
  dropBtnSx?: string;
  mainBtnSx?: string;
  name: string;
}) {
  const { user }: any = useSelector((state: RootState) => state.userState);
  const isVendor: boolean = user.role === 'fleetmax_vendor';
  const isApprovedPage = window.location.href.includes('approved');
  const customOption = (item: any) => {
    if (isVendor && isApprovedPage && item === 'Payment Completed') {
      return 'Invoice Approved';
    } else return item;
  };
  return (
    <button
      className={`bg-blue-50 min-w-[10rem] group hover:rounded-t-lg hover:rounded-b-none rounded-lg hover:drop-shadow-md border-none ${mainBtnSx}`}>
      <div
        className={`flex items-center justify-evenly py-0.5 px-2 gap-1 ${dropBtnSx} rounded-lg group-hover:rounded-b-none w-full relative`}>
        <div className='flex-1 text-center w-full'>
          <p className='text-sm'>{name}</p>
          <p className='truncate w-[95%] text-center text-slate-600'>{customOption(selected)}</p>
        </div>
        <i className={`pi pi-angle-down group-hover:rotate-180 duration-200 absolute right-2`}></i>
      </div>
      <div
        id='modal'
        className={`hidden group-hover:block rounded-b-lg absolute top-13 left-0 bg-white z-11 max-h-[16rem] w-full overflow-y-auto drop-shadow-none`}>
        {options.map((item: string) => (
          <div
            key={item}
            className={`cursor-pointer child:text-gray-600 hover:bg-slate-100 ${
              selected === item && 'bg-slate-200'
            }`}
            onClick={async (e: any) => {
              handlDropDownClick(item);
            }}>
            <p className='p-1 py-1.5'>{customOption(item)}</p>
            <hr className='bg-slate-50' />
          </div>
        ))}
      </div>
    </button>
  );
}

export default DropDown;
