import { GoogleMap, InfoWindow, LoadScript, Marker, Polyline } from '@react-google-maps/api';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from '../../components/Toast/CustomToast';
import { RootState } from '../../state/reducers';
import { apiKey } from './ApiKey';
import { Button } from '@mui/material';

const svgString = `<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="Group 41036">
  <g id="Ellipse 726" filter="url(#filter0_d_6894_18760)">
  <circle cx="23" cy="23" r="14" fill="white"/>
  <circle cx="23" cy="23" r="13.5" stroke="#4378C8"/>
  </g>
  <g id="Group 41032">
  <path id="Vector" d="M20.0617 19.9723L18.2545 18.785C18.091 18.6776 17.8714 18.7231 17.7641 18.8868C17.6569 19.0506 17.7023 19.2704 17.8659 19.3778L19.6405 20.5438C19.7568 20.3224 19.8994 20.1307 20.0617 19.9723Z" fill="#4378C8"/>
  <path id="Vector_2" d="M23.8109 18.2765C24.0104 17.4188 23.4775 16.5615 22.6207 16.3618C21.7638 16.162 20.9075 16.6954 20.7079 17.5531C20.5084 18.4109 21.0413 19.2681 21.8982 19.4679C22.755 19.6677 23.6114 19.1343 23.8109 18.2765Z" fill="#4378C8"/>
  <path id="Vector_3" d="M20.5451 21.2746C20.3705 20.9248 20.5126 20.4981 20.8617 20.3236C20.9612 20.2737 21.0675 20.2484 21.1781 20.2484C21.4484 20.2484 21.6911 20.3987 21.8119 20.6407L22.1905 21.3987C22.4506 20.7374 22.2952 19.9132 21.7357 19.6887C21.1575 19.4565 20.2444 19.6622 19.7495 20.6417C19.0769 21.9926 18.8472 22.9005 18.8135 24.4857C18.8352 24.8818 18.9378 25.1687 19.0892 25.378L21.2546 22.6949L20.5451 21.2746ZM21.5119 23.504L19.6556 25.8042C19.8577 25.8844 20.0743 25.9248 20.2794 25.9504C20.449 25.9717 20.5943 25.9851 20.7181 25.9755C20.7811 25.9736 20.8447 25.9653 20.9084 25.9493L23.4568 25.3049L24.8836 28.3949C25.033 28.7187 25.3533 28.9095 25.6877 28.9095C25.8121 28.9095 25.9382 28.8832 26.0584 28.8276C26.5023 28.6223 26.6957 28.0957 26.4906 27.6514L24.7556 23.894C24.5771 23.5079 24.1473 23.3024 23.7354 23.4065L21.4035 23.996C21.4345 23.8315 21.4713 23.6671 21.5119 23.504ZM24.4784 20.6722L24.7509 20.056C24.84 19.8545 24.7492 19.619 24.5479 19.5298C24.3468 19.4407 24.1115 19.5317 24.0224 19.7331L23.6597 20.5535C23.7598 20.501 23.8717 20.473 23.9876 20.473C24.1729 20.4731 24.346 20.544 24.4784 20.6722ZM23.5325 22.811L23.6886 22.4579L23.0877 23.0401C23.2676 23.0774 23.4553 22.9859 23.5325 22.811ZM24.3162 21.8501L27.8072 23.3972L28.0581 22.83L24.6797 21.3327C24.6505 21.4685 24.582 21.5926 24.48 21.6915L24.3162 21.8501ZM27.186 27.9281L26.2156 29.4946C26.1195 29.6499 26.1672 29.8538 26.3222 29.9502C26.4774 30.0464 26.6811 29.9987 26.7773 29.8433L27.7477 28.2768C27.8438 28.1215 27.7961 27.9176 27.6409 27.8213C27.4859 27.725 27.2821 27.7728 27.186 27.9281Z" fill="#4378C8"/>
  <path id="Vector_4" d="M18.4045 27.2965H22.6415C22.9152 27.2965 23.1373 27.0743 23.1373 26.8003C23.1373 26.5262 22.9152 26.3041 22.6415 26.3041L19.2469 26.304L19.5038 25.9918C19.5041 25.9915 19.5043 25.9911 19.5045 25.9908C19.6281 25.8397 19.6063 25.6171 19.4558 25.4928C19.3049 25.3684 19.0819 25.3899 18.9574 25.5407L18.9573 25.5409L18.7555 25.786L17.9861 20.5835C17.946 20.3124 17.6938 20.1251 17.4231 20.1654C17.1523 20.2055 16.9653 20.4578 17.0054 20.7288L17.9141 26.873C17.9499 27.1159 18.1591 27.2965 18.4045 27.2965ZM21.6848 20.7039C21.545 20.4236 21.2046 20.31 20.9248 20.4502C20.645 20.5903 20.5316 20.9308 20.6715 21.211L21.6629 23.1961C21.7432 23.3571 21.8971 23.4712 22.0743 23.5015C22.1057 23.5069 22.1374 23.5095 22.1689 23.5095C22.3147 23.5095 22.4572 23.453 22.5635 23.35L24.3812 21.5895C24.6061 21.3718 24.6119 21.0126 24.3945 20.7875C24.1768 20.5624 23.8183 20.5565 23.5934 20.7744L22.3309 21.9971L21.6848 20.7039Z" fill="#4378C8"/>
  </g>
  </g>
  <defs>
  <filter id="filter0_d_6894_18760" x="0" y="0" width="46" height="46" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feMorphology radius="9" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_6894_18760"/>
  <feOffset/>
  <feComposite in2="hardAlpha" operator="out"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0.262745 0 0 0 0 0.470588 0 0 0 0 0.784314 0 0 0 0.21 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6894_18760"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6894_18760" result="shape"/>
  </filter>
  </defs>
  </svg>
  `;
const dummyPath = [
  {
    latitude: 26.83346296708894,
    longitude: 75.7437305651598,
  },
  {
    latitude: 26.83367,
    longitude: 75.74377,
  },
  {
    latitude: 26.83407,
    longitude: 75.74391,
  },
  {
    latitude: 26.83414,
    longitude: 75.74393,
  },
  {
    latitude: 26.83427,
    longitude: 75.74398,
  },
  {
    latitude: 26.8344,
    longitude: 75.74404,
  },
  {
    latitude: 26.83462,
    longitude: 75.74413,
  },
  {
    latitude: 26.83467,
    longitude: 75.74415,
  },
  {
    latitude: 26.8348,
    longitude: 75.74418,
  },
  {
    latitude: 26.83491,
    longitude: 75.7442,
  },
  {
    latitude: 26.83508,
    longitude: 75.74421,
  },
  {
    latitude: 26.8352,
    longitude: 75.74422,
  },
  {
    latitude: 26.8356,
    longitude: 75.74422,
  },
  {
    latitude: 26.83573,
    longitude: 75.7442,
  },
  {
    latitude: 26.83585,
    longitude: 75.74418,
  },
  {
    latitude: 26.83593,
    longitude: 75.74416,
  },
  {
    latitude: 26.83601,
    longitude: 75.74414,
  },
  {
    latitude: 26.83613,
    longitude: 75.7441,
  },
  {
    latitude: 26.83643,
    longitude: 75.74402,
  },
  {
    latitude: 26.83652,
    longitude: 75.74398,
  },
  {
    latitude: 26.83663,
    longitude: 75.74395,
  },
  {
    latitude: 26.83672,
    longitude: 75.74392,
  },
  {
    latitude: 26.83681,
    longitude: 75.7439,
  },
  {
    latitude: 26.8369,
    longitude: 75.74389,
  },
  {
    latitude: 26.83695,
    longitude: 75.74387,
  },
  {
    latitude: 26.83698,
    longitude: 75.74385,
  },
  {
    latitude: 26.83702,
    longitude: 75.74381,
  },
  {
    latitude: 26.83706,
    longitude: 75.74378,
  },
  {
    latitude: 26.83709,
    longitude: 75.74373,
  },
  {
    latitude: 26.83712,
    longitude: 75.74368,
  },
  {
    latitude: 26.83715,
    longitude: 75.74362,
  },
  {
    latitude: 26.83718,
    longitude: 75.74356,
  },
  {
    latitude: 26.83731,
    longitude: 75.74316,
  },
  {
    latitude: 26.83736,
    longitude: 75.74303,
  },
  {
    latitude: 26.83738,
    longitude: 75.743,
  },
  {
    latitude: 26.8374,
    longitude: 75.74296,
  },
  {
    latitude: 26.83742,
    longitude: 75.74293,
  },
  {
    latitude: 26.83745,
    longitude: 75.74292,
  },
  {
    latitude: 26.83746,
    longitude: 75.74291,
  },
  {
    latitude: 26.83746,
    longitude: 75.74291,
  },
  {
    latitude: 26.83746,
    longitude: 75.74288,
  },
  {
    latitude: 26.83746,
    longitude: 75.74283,
  },
  {
    latitude: 26.83746,
    longitude: 75.7428,
  },
  {
    latitude: 26.83746,
    longitude: 75.74277,
  },
  {
    latitude: 26.83746,
    longitude: 75.74273,
  },
  {
    latitude: 26.83762,
    longitude: 75.74234,
  },
  {
    latitude: 26.83762,
    longitude: 75.74234,
  },
  {
    latitude: 26.83764,
    longitude: 75.74223,
  },
  {
    latitude: 26.83836,
    longitude: 75.74249,
  },
  {
    latitude: 26.83907,
    longitude: 75.74268,
  },
  {
    latitude: 26.83952,
    longitude: 75.74281,
  },
  {
    latitude: 26.84021,
    longitude: 75.74304,
  },
  {
    latitude: 26.84054,
    longitude: 75.74314,
  },
  {
    latitude: 26.84054,
    longitude: 75.74314,
  },
  {
    latitude: 26.84065,
    longitude: 75.74318,
  },
  {
    latitude: 26.84077,
    longitude: 75.74324,
  },
  {
    latitude: 26.84084,
    longitude: 75.74326,
  },
  {
    latitude: 26.84106,
    longitude: 75.74334,
  },
  {
    latitude: 26.84107,
    longitude: 75.74335,
  },
  {
    latitude: 26.84122,
    longitude: 75.74348,
  },
  {
    latitude: 26.84144,
    longitude: 75.74377,
  },
  {
    latitude: 26.84185,
    longitude: 75.74434,
  },
  {
    latitude: 26.84203,
    longitude: 75.74453,
  },
  {
    latitude: 26.84241,
    longitude: 75.74507,
  },
  {
    latitude: 26.84265,
    longitude: 75.7454,
  },
  {
    latitude: 26.84276,
    longitude: 75.74557,
  },
  {
    latitude: 26.84286,
    longitude: 75.74573,
  },
  {
    latitude: 26.84286,
    longitude: 75.74573,
  },
  {
    latitude: 26.84292,
    longitude: 75.74569,
  },
  {
    latitude: 26.84303,
    longitude: 75.74561,
  },
  {
    latitude: 26.844,
    longitude: 75.74491,
  },
  {
    latitude: 26.84451,
    longitude: 75.74452,
  },
  {
    latitude: 26.84558,
    longitude: 75.74375,
  },
  {
    latitude: 26.84591,
    longitude: 75.7435,
  },
  {
    latitude: 26.84733,
    longitude: 75.74242,
  },
  {
    latitude: 26.84748,
    longitude: 75.74229,
  },
  {
    latitude: 26.84765,
    longitude: 75.74224,
  },
  {
    latitude: 26.84765,
    longitude: 75.74224,
  },
  {
    latitude: 26.84765,
    longitude: 75.74221,
  },
  {
    latitude: 26.84764,
    longitude: 75.74218,
  },
  {
    latitude: 26.84764,
    longitude: 75.74214,
  },
  {
    latitude: 26.84765,
    longitude: 75.74211,
  },
  {
    latitude: 26.84765,
    longitude: 75.74207,
  },
  {
    latitude: 26.84766,
    longitude: 75.74204,
  },
  {
    latitude: 26.84768,
    longitude: 75.74201,
  },
  {
    latitude: 26.8477,
    longitude: 75.74198,
  },
  {
    latitude: 26.84773,
    longitude: 75.74194,
  },
  {
    latitude: 26.84775,
    longitude: 75.74192,
  },
  {
    latitude: 26.84778,
    longitude: 75.7419,
  },
  {
    latitude: 26.8478,
    longitude: 75.74188,
  },
  {
    latitude: 26.84783,
    longitude: 75.74186,
  },
  {
    latitude: 26.84787,
    longitude: 75.74185,
  },
  {
    latitude: 26.8479,
    longitude: 75.74185,
  },
  {
    latitude: 26.84793,
    longitude: 75.74185,
  },
  {
    latitude: 26.84796,
    longitude: 75.74185,
  },
  {
    latitude: 26.84798,
    longitude: 75.74186,
  },
  {
    latitude: 26.848,
    longitude: 75.74186,
  },
  {
    latitude: 26.84803,
    longitude: 75.74188,
  },
  {
    latitude: 26.84806,
    longitude: 75.74189,
  },
  {
    latitude: 26.84821,
    longitude: 75.74178,
  },
  {
    latitude: 26.84844,
    longitude: 75.74162,
  },
  {
    latitude: 26.8487,
    longitude: 75.74143,
  },
  {
    latitude: 26.84899,
    longitude: 75.74122,
  },
  {
    latitude: 26.85102,
    longitude: 75.73972,
  },
  {
    latitude: 26.85239,
    longitude: 75.73871,
  },
  {
    latitude: 26.85249,
    longitude: 75.73865,
  },
  {
    latitude: 26.85356,
    longitude: 75.73786,
  },
  {
    latitude: 26.85527,
    longitude: 75.73658,
  },
  {
    latitude: 26.85557,
    longitude: 75.73636,
  },
  {
    latitude: 26.85586,
    longitude: 75.73613,
  },
  {
    latitude: 26.85868,
    longitude: 75.73403,
  },
  {
    latitude: 26.85884,
    longitude: 75.73391,
  },
  {
    latitude: 26.85895,
    longitude: 75.73384,
  },
  {
    latitude: 26.85916,
    longitude: 75.7337,
  },
  {
    latitude: 26.85973,
    longitude: 75.73328,
  },
  {
    latitude: 26.85975,
    longitude: 75.73327,
  },
  {
    latitude: 26.86008,
    longitude: 75.73302,
  },
  {
    latitude: 26.86009,
    longitude: 75.73301,
  },
  {
    latitude: 26.86038,
    longitude: 75.73281,
  },
  {
    latitude: 26.8606,
    longitude: 75.73264,
  },
  {
    latitude: 26.86076,
    longitude: 75.73251,
  },
  {
    latitude: 26.86103,
    longitude: 75.7323,
  },
  {
    latitude: 26.86117,
    longitude: 75.73219,
  },
  {
    latitude: 26.86117,
    longitude: 75.73219,
  },
  {
    latitude: 26.86117,
    longitude: 75.73215,
  },
  {
    latitude: 26.86116,
    longitude: 75.73213,
  },
  {
    latitude: 26.86116,
    longitude: 75.73212,
  },
  {
    latitude: 26.86116,
    longitude: 75.73209,
  },
  {
    latitude: 26.86117,
    longitude: 75.73206,
  },
  {
    latitude: 26.86117,
    longitude: 75.73203,
  },
  {
    latitude: 26.86118,
    longitude: 75.732,
  },
  {
    latitude: 26.86119,
    longitude: 75.73198,
  },
  {
    latitude: 26.86121,
    longitude: 75.73195,
  },
  {
    latitude: 26.86122,
    longitude: 75.73193,
  },
  {
    latitude: 26.86124,
    longitude: 75.73191,
  },
  {
    latitude: 26.86127,
    longitude: 75.73188,
  },
  {
    latitude: 26.8613,
    longitude: 75.73186,
  },
  {
    latitude: 26.86132,
    longitude: 75.73185,
  },
  {
    latitude: 26.86135,
    longitude: 75.73184,
  },
  {
    latitude: 26.86137,
    longitude: 75.73184,
  },
  {
    latitude: 26.8614,
    longitude: 75.73184,
  },
  {
    latitude: 26.86143,
    longitude: 75.73184,
  },
  {
    latitude: 26.86145,
    longitude: 75.73184,
  },
  {
    latitude: 26.86148,
    longitude: 75.73185,
  },
  {
    latitude: 26.8615,
    longitude: 75.73186,
  },
  {
    latitude: 26.86153,
    longitude: 75.73187,
  },
  {
    latitude: 26.86155,
    longitude: 75.73189,
  },
  {
    latitude: 26.86157,
    longitude: 75.73191,
  },
  {
    latitude: 26.86218,
    longitude: 75.73146,
  },
  {
    latitude: 26.86243,
    longitude: 75.73128,
  },
  {
    latitude: 26.8638,
    longitude: 75.73027,
  },
  {
    latitude: 26.86392,
    longitude: 75.73019,
  },
  {
    latitude: 26.86409,
    longitude: 75.73006,
  },
  {
    latitude: 26.86486,
    longitude: 75.72947,
  },
  {
    latitude: 26.86486,
    longitude: 75.72947,
  },
  {
    latitude: 26.86495,
    longitude: 75.72962,
  },
  {
    latitude: 26.86504,
    longitude: 75.72976,
  },
  {
    latitude: 26.86517,
    longitude: 75.72997,
  },
  {
    latitude: 26.86539,
    longitude: 75.73035,
  },
  {
    latitude: 26.86571,
    longitude: 75.73084,
  },
  {
    latitude: 26.86582,
    longitude: 75.73102,
  },
  {
    latitude: 26.86596,
    longitude: 75.73125,
  },
  {
    latitude: 26.86623,
    longitude: 75.73168,
  },
  {
    latitude: 26.86639,
    longitude: 75.73193,
  },
  {
    latitude: 26.86653,
    longitude: 75.73216,
  },
  {
    latitude: 26.8666,
    longitude: 75.73228,
  },
  {
    latitude: 26.8666,
    longitude: 75.73229,
  },
  {
    latitude: 26.86665,
    longitude: 75.73237,
  },
  {
    latitude: 26.86665,
    longitude: 75.73237,
  },
  {
    latitude: 26.86666,
    longitude: 75.73237,
  },
  {
    latitude: 26.86667,
    longitude: 75.73237,
  },
  {
    latitude: 26.86667,
    longitude: 75.73238,
  },
  {
    latitude: 26.86668,
    longitude: 75.73238,
  },
  {
    latitude: 26.86669,
    longitude: 75.73238,
  },
  {
    latitude: 26.8667,
    longitude: 75.73239,
  },
  {
    latitude: 26.86671,
    longitude: 75.73239,
  },
  {
    latitude: 26.86671,
    longitude: 75.7324,
  },
  {
    latitude: 26.86672,
    longitude: 75.7324,
  },
  {
    latitude: 26.86672,
    longitude: 75.73241,
  },
  {
    latitude: 26.86673,
    longitude: 75.73241,
  },
  {
    latitude: 26.86673,
    longitude: 75.73242,
  },
  {
    latitude: 26.86673,
    longitude: 75.73243,
  },
  {
    latitude: 26.86674,
    longitude: 75.73243,
  },
  {
    latitude: 26.86674,
    longitude: 75.73244,
  },
  {
    latitude: 26.8668,
    longitude: 75.73249,
  },
  {
    latitude: 26.86685,
    longitude: 75.73252,
  },
  {
    latitude: 26.8669,
    longitude: 75.73255,
  },
  {
    latitude: 26.86694,
    longitude: 75.73256,
  },
  {
    latitude: 26.86701,
    longitude: 75.73256,
  },
  {
    latitude: 26.86705,
    longitude: 75.73257,
  },
  {
    latitude: 26.86709,
    longitude: 75.73257,
  },
  {
    latitude: 26.86716,
    longitude: 75.73257,
  },
  {
    latitude: 26.86722,
    longitude: 75.73256,
  },
  {
    latitude: 26.86732,
    longitude: 75.73255,
  },
  {
    latitude: 26.86746,
    longitude: 75.73253,
  },
  {
    latitude: 26.86765,
    longitude: 75.73251,
  },
  {
    latitude: 26.86769,
    longitude: 75.7325,
  },
  {
    latitude: 26.86795,
    longitude: 75.73244,
  },
  {
    latitude: 26.86816,
    longitude: 75.7324,
  },
  {
    latitude: 26.86824,
    longitude: 75.73238,
  },
  {
    latitude: 26.86831,
    longitude: 75.73237,
  },
  {
    latitude: 26.86835,
    longitude: 75.73236,
  },
  {
    latitude: 26.86856,
    longitude: 75.73231,
  },
  {
    latitude: 26.86884,
    longitude: 75.73224,
  },
  {
    latitude: 26.86944,
    longitude: 75.73211,
  },
  {
    latitude: 26.86952,
    longitude: 75.73209,
  },
  {
    latitude: 26.87033,
    longitude: 75.73188,
  },
  {
    latitude: 26.87058,
    longitude: 75.73182,
  },
  {
    latitude: 26.87087,
    longitude: 75.73175,
  },
  {
    latitude: 26.8711,
    longitude: 75.73169,
  },
  {
    latitude: 26.87138,
    longitude: 75.73164,
  },
  {
    latitude: 26.87151,
    longitude: 75.73163,
  },
  {
    latitude: 26.87155,
    longitude: 75.73163,
  },
  {
    latitude: 26.87163,
    longitude: 75.73162,
  },
  {
    latitude: 26.87171,
    longitude: 75.73167,
  },
  {
    latitude: 26.87222,
    longitude: 75.73167,
  },
  {
    latitude: 26.87297,
    longitude: 75.73173,
  },
  {
    latitude: 26.87306,
    longitude: 75.73174,
  },
  {
    latitude: 26.87368,
    longitude: 75.73178,
  },
  {
    latitude: 26.87391,
    longitude: 75.7318,
  },
  {
    latitude: 26.87398,
    longitude: 75.7318,
  },
  {
    latitude: 26.87405,
    longitude: 75.7318,
  },
  {
    latitude: 26.87409,
    longitude: 75.73179,
  },
  {
    latitude: 26.87436,
    longitude: 75.73173,
  },
  {
    latitude: 26.87477,
    longitude: 75.73166,
  },
  {
    latitude: 26.875,
    longitude: 75.73163,
  },
  {
    latitude: 26.87536,
    longitude: 75.73158,
  },
  {
    latitude: 26.87565,
    longitude: 75.73153,
  },
  {
    latitude: 26.87568,
    longitude: 75.73152,
  },
  {
    latitude: 26.87578,
    longitude: 75.7315,
  },
  {
    latitude: 26.87585,
    longitude: 75.73147,
  },
  {
    latitude: 26.8759,
    longitude: 75.73143,
  },
  {
    latitude: 26.87595,
    longitude: 75.73139,
  },
  {
    latitude: 26.87605,
    longitude: 75.73127,
  },
  {
    latitude: 26.87622,
    longitude: 75.73109,
  },
  {
    latitude: 26.87653,
    longitude: 75.73073,
  },
  {
    latitude: 26.87665,
    longitude: 75.73066,
  },
  {
    latitude: 26.87665,
    longitude: 75.73066,
  },
  {
    latitude: 26.8768,
    longitude: 75.73059,
  },
  {
    latitude: 26.87704,
    longitude: 75.73084,
  },
  {
    latitude: 26.8775,
    longitude: 75.73132,
  },
  {
    latitude: 26.87821,
    longitude: 75.73205,
  },
  {
    latitude: 26.87827,
    longitude: 75.7321,
  },
  {
    latitude: 26.87849,
    longitude: 75.73232,
  },
  {
    latitude: 26.87865,
    longitude: 75.73251,
  },
  {
    latitude: 26.87878,
    longitude: 75.73266,
  },
  {
    latitude: 26.87891,
    longitude: 75.73283,
  },
  {
    latitude: 26.87908,
    longitude: 75.7331,
  },
  {
    latitude: 26.87924,
    longitude: 75.73341,
  },
  {
    latitude: 26.87946,
    longitude: 75.73387,
  },
  {
    latitude: 26.87947,
    longitude: 75.73389,
  },
  {
    latitude: 26.87956,
    longitude: 75.73413,
  },
  {
    latitude: 26.87966,
    longitude: 75.73436,
  },
  {
    latitude: 26.8797,
    longitude: 75.73445,
  },
  {
    latitude: 26.8797,
    longitude: 75.73446,
  },
  {
    latitude: 26.87971,
    longitude: 75.7345,
  },
  {
    latitude: 26.8798,
    longitude: 75.73472,
  },
  {
    latitude: 26.8799,
    longitude: 75.73501,
  },
  {
    latitude: 26.88004,
    longitude: 75.73543,
  },
  {
    latitude: 26.88012,
    longitude: 75.73567,
  },
  {
    latitude: 26.88016,
    longitude: 75.73584,
  },
  {
    latitude: 26.88019,
    longitude: 75.73597,
  },
  {
    latitude: 26.88022,
    longitude: 75.73614,
  },
  {
    latitude: 26.88024,
    longitude: 75.73624,
  },
  {
    latitude: 26.88024,
    longitude: 75.7363,
  },
  {
    latitude: 26.88024,
    longitude: 75.73637,
  },
  {
    latitude: 26.88024,
    longitude: 75.73646,
  },
  {
    latitude: 26.88024,
    longitude: 75.73663,
  },
  {
    latitude: 26.88023,
    longitude: 75.73685,
  },
  {
    latitude: 26.88021,
    longitude: 75.73704,
  },
  {
    latitude: 26.8802,
    longitude: 75.73713,
  },
  {
    latitude: 26.88018,
    longitude: 75.73738,
  },
  {
    latitude: 26.8801,
    longitude: 75.73834,
  },
  {
    latitude: 26.8801,
    longitude: 75.73834,
  },
  {
    latitude: 26.88021,
    longitude: 75.73829,
  },
  {
    latitude: 26.88074,
    longitude: 75.73796,
  },
  {
    latitude: 26.88103,
    longitude: 75.73776,
  },
  {
    latitude: 26.88105,
    longitude: 75.73774,
  },
  {
    latitude: 26.88209,
    longitude: 75.73699,
  },
  {
    latitude: 26.88209,
    longitude: 75.73699,
  },
  {
    latitude: 26.88211,
    longitude: 75.73702,
  },
  {
    latitude: 26.88236,
    longitude: 75.73754,
  },
  {
    latitude: 26.88237,
    longitude: 75.73758,
  },
  {
    latitude: 26.88242,
    longitude: 75.7377,
  },
  {
    latitude: 26.88242,
    longitude: 75.7377,
  },
  {
    latitude: 26.88308,
    longitude: 75.73742,
  },
  {
    latitude: 26.88326,
    longitude: 75.73735,
  },
].map((d: any) => ({ lat: d.latitude, lng: d.longitude }));

const calculateNewTime = (durationInMinutes: any) => {
  const duration = moment.duration(durationInMinutes, 'minutes');
  const newTime = moment().add(duration);
  return newTime;
};

const RouteRenderer = ({
  item,
  pathDetail,
  setPath,
  closeAndUpdate,
}: {
  item: any;
  pathDetail: any;
  setPath: (d: any) => void;
  closeAndUpdate: any;
}) => {
  const [center, setCenter] = useState(null as any);

  const { socketFleetMax: socket }: any = useSelector((state: RootState) => state.userState);
  const roomId = item?.pm_request_id || item?.preventive_request_id || item?.id;

  const joinRoom = () => {
    socket.emit('joinRoom', roomId, 'full', 'csr');
  };

  useEffect(() => {
    if (socket && Object.keys(socket).length > 0) {
      if (roomId) {
        joinRoom();
        socket.on('locationUpdate', (data: any) => {
          setPath(data);
        });
        socket.on('reachedDestination', (data: any) => {
          toast.success('Technical reached at destination');
          closeAndUpdate?.();
        });
      }
    }
  }, [roomId]);

  const MarkerA: {
    lat: any;
    lng: any;
  } | null = pathDetail?.position
    ? { lat: pathDetail?.position?.latitude, lng: pathDetail?.position?.longitude }
    : item?.startedLocation?.remark?.coordinates
    ? {
        lat: item.startedLocation?.remark?.coordinates?.latitude,
        lng: item.startedLocation?.remark?.coordinates?.longitude,
      }
    : null;

  const MarkerB: {
    lat: any;
    lng: any;
  } | null = pathDetail?.destination
    ? { lat: pathDetail?.destination?.latitude, lng: pathDetail?.destination?.longitude }
    : item?.destinationAddress && item?.destinationAddress?.coordinates?.lat
    ? item?.destinationAddress?.coordinates
    : null;

  const svgDataUrl = useMemo(() => `data:image/svg+xml;base64,${btoa(svgString)}`, [svgString]);
  const mapStyles = {
    height: '30rem',
    width: '100%',
  };
  const recenterMap = () => {
    if (MarkerA) {
      setCenter(MarkerA);
    }
  };

  useEffect(() => {
    if (!center) {
      setCenter(MarkerA);
    }
  }, [MarkerA]);
  return (
    <div className='w-full'>
      <div className='flex justify-end mb-2 text-end mr-2'>
        <Button onClick={recenterMap} variant='contained'>
          Recenter to Technician
        </Button>
      </div>
      <div className='flex justify-center h-full rounded-b-[2rem] overflow-auto'>
        <LoadScript googleMapsApiKey={apiKey}>
          <GoogleMap
            options={{
              zoomControl: false,
              mapTypeControl: false,
              center: center || {
                lat: 40.71427,
                lng: -74.00597, // USA coordinates
              },
            }}
            mapContainerStyle={mapStyles}
            zoom={MarkerB ? 20 : 5}>
            <Polyline
              path={pathDetail?.path?.map((d: any) => ({ lat: d.latitude, lng: d.longitude }))}
              options={{
                strokeColor: '#4378C8',
                strokeOpacity: 1.0,
                strokeWeight: 3,
              }}
            />
            {MarkerA && (
              <Marker
                position={MarkerA}
                icon={{
                  url: svgDataUrl,
                }}>
                {pathDetail?.distance && (
                  <InfoWindow position={MarkerA}>
                    <div className='flex flex-col'>
                      <div>{kmToMi(pathDetail?.distance)} miles</div>
                      <div>to go</div>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            )}

            {MarkerB && <Marker position={MarkerB}></Marker>}
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  );
};

export default RouteRenderer;

function kmToMi(kms: number) {
  return (kms * 0.621371).toFixed(2);
}
