export const getRequestType = (d: any) => {
  if (['1', '2', '3'].includes(d.status_id)) {
    return 'schedule';
  }
  if (d.status_id === '4') {
    if (+d?.sub_stage === 3) {
      return 'rejected';
    } else {
      return 'in-process';
    }
  }
  if (['5', '6', '7', '8', '9', '11'].includes(d.status_id)) {
    return 'in-process';
  }
  if (['12'].includes(d.status_id)) {
    return 'complete';
  }
  if (['10'].includes(d.status_id)) {
    return 'rejected';
  }
};
