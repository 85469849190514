const DisputeHistoryIcon = (props: { height?: string; width?: string; active?: boolean }) => {
  const height = props?.height || '10';
  const width = props?.width || '41';
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_13121_19457)'>
        <path
          d='M1.37959 0C1.0137 0 0.662797 0.1475 0.404073 0.410051C0.145349 0.672601 0 1.0287 0 1.4L0 14L2.75918 11.2H12.4163C12.7822 11.2 13.1331 11.0525 13.3918 10.7899C13.6506 10.5274 13.7959 10.1713 13.7959 9.8V1.4C13.7959 1.0287 13.6506 0.672601 13.3918 0.410051C13.1331 0.1475 12.7822 0 12.4163 0L1.37959 0ZM3.44898 6.342C3.25878 6.34478 3.07206 6.29008 2.91258 6.18487C2.7531 6.07966 2.62806 5.92868 2.55337 5.75115C2.47868 5.57362 2.45772 5.37756 2.49314 5.1879C2.52856 4.99825 2.61878 4.82357 2.7523 4.68609C2.88583 4.54861 3.05664 4.45454 3.243 4.41585C3.42935 4.37716 3.62284 4.39559 3.79885 4.4688C3.97485 4.54202 4.12542 4.66671 4.2314 4.827C4.33738 4.9873 4.39398 5.17597 4.394 5.369C4.39403 5.62465 4.2949 5.87004 4.11806 6.05211C3.94122 6.23419 3.70088 6.33832 3.44898 6.342ZM7.00833 6.342C6.81869 6.342 6.63331 6.28493 6.47564 6.17802C6.31796 6.07111 6.19507 5.91914 6.1225 5.74135C6.04993 5.56356 6.03094 5.36792 6.06793 5.17918C6.10493 4.99043 6.19625 4.81706 6.33034 4.68098C6.46443 4.54491 6.63528 4.45224 6.82127 4.4147C7.00726 4.37715 7.20005 4.39642 7.37525 4.47007C7.55045 4.54371 7.7002 4.66842 7.80555 4.82843C7.91091 4.98844 7.96714 5.17656 7.96714 5.369C7.96717 5.49856 7.9417 5.62683 7.89223 5.74627C7.84276 5.86571 7.77027 5.97392 7.67904 6.06455C7.5878 6.15518 7.47964 6.22641 7.36091 6.27405C7.24219 6.32169 7.11528 6.3448 6.98763 6.342H7.00833ZM10.5677 6.342C10.378 6.342 10.1927 6.28493 10.035 6.17802C9.87731 6.07111 9.75441 5.91914 9.68184 5.74135C9.60927 5.56356 9.59028 5.36792 9.62728 5.17918C9.66428 4.99043 9.7556 4.81706 9.88969 4.68098C10.0238 4.54491 10.1946 4.45224 10.3806 4.4147C10.5666 4.37715 10.7594 4.39642 10.9346 4.47007C11.1098 4.54371 11.2595 4.66842 11.3649 4.82843C11.4703 4.98844 11.5265 5.17656 11.5265 5.369C11.5265 5.49856 11.5011 5.62683 11.4516 5.74627C11.4021 5.86571 11.3296 5.97392 11.2384 6.06455C11.1471 6.15518 11.039 6.22641 10.9203 6.27405C10.8015 6.32169 10.6746 6.3448 10.547 6.342H10.5677Z'
          fill='#4378C8'
        />
      </g>
      <defs>
        <clipPath id='clip0_13121_19457'>
          <rect width='13.7959' height='14' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DisputeHistoryIcon;
