function emailAndPassWordRejex() {
  const passwordRules = /^(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{6,16}$/;
  const emailRules = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  return {
    passwordRules,
    emailRules,
  };
}

export default emailAndPassWordRejex;
