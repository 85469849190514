export default function (props: { height?: string, width?: string, active?: boolean }) {
  const height = props?.height || 22
  const width = props?.width || 30
  return (
    <svg width={width} height={height} viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.5982 12.7562C5.36486 13.454 4.61025 13.831 3.91206 13.5983C3.21348 13.3654 2.83592 12.6103 3.06878 11.9117C3.04449 11.9929 3.06977 11.9087 3.06977 11.9087C3.08452 11.865 3.10025 11.8216 3.11636 11.7784C3.1438 11.7046 3.18324 11.6028 3.23574 11.4768C3.34062 11.225 3.49822 10.8756 3.71702 10.461C4.15345 9.63408 4.84068 8.53435 5.85081 7.43237C7.89164 5.20603 11.2314 3 16.3337 3C21.436 3 24.7757 5.20603 26.8166 7.43237C27.8268 8.53435 28.514 9.63408 28.9502 10.461C29.1692 10.8756 29.3268 11.225 29.4316 11.4768C29.4841 11.6028 29.5236 11.7046 29.551 11.7784C29.5593 11.8004 29.5868 11.9399 29.6132 12.0739C29.6372 12.1958 29.6604 12.3131 29.6673 12.3333C29.6673 12.3333 29.89 13.2201 28.7553 13.5983C28.0582 13.8306 27.3049 13.4551 27.0702 12.7594L27.0692 12.7562L27.0681 12.7533L27.0518 12.7086C27.0358 12.6652 27.0086 12.5952 26.9701 12.5024C26.8927 12.3167 26.769 12.0411 26.592 11.7057C26.2368 11.0326 25.674 10.1323 24.8508 9.23429C23.225 7.46064 20.5648 5.66667 16.3337 5.66667C12.1026 5.66667 9.44241 7.46064 7.81657 9.23429C6.99337 10.1323 6.43062 11.0326 6.07537 11.7057C5.89836 12.0411 5.77468 12.3167 5.69726 12.5024C5.65862 12.5952 5.63166 12.6652 5.6155 12.7086L5.5982 12.7562Z" fill="#4378C8" />
      <path d="M11.0007 13.6665C11.0007 10.7209 13.3885 8.33313 16.3341 8.33313C19.2796 8.33313 21.6674 10.7209 21.6674 13.6665C21.6674 16.612 19.2796 18.9998 16.3341 18.9998C13.3885 18.9998 11.0007 16.612 11.0007 13.6665Z" fill="#4378C8" />
      <line x1="1.39057" y1="1.7425" x2="27.7112" y2="19.8384" stroke="#4378C8" stroke-width="2" stroke-linecap="round" />
    </svg>
  );
}