import { Tooltip } from '@mui/material';
import ApprovedSvg from '../../../../assets/Approved';
import UploadProof from '../../../../assets/JobCompletedOrUploadProofs';
import PendingApprovalSvg from '../../../../assets/PendingApproval';
import TechAssigned from '../../../../assets/TechAssigned';
import {
  invoiceReceivedCheck,
  invoiceSentCheck,
  paymentCompletedCheck,
  proofAccepetedCheck,
} from '../../../vender/components/Approved';
import OnTheWayIcon from '../../../../assets/OnTheWayIcon';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/reducers';

export const GetInfoIconVendor = ({ item }: any) => {
  const { user }: any = useSelector((state: RootState) => state.userState);
  return (
    <>
      {/* Track Location  */}
      {item.status_id === '5' && ['1', '2', '3'].includes(item?.sub_stage) && (
        <Tooltip title={item?.sub_stage === '1' ? 'Technion En-Route' : 'Technician Reached'}>
          <div className='w-[3.3rem] bg-[#3E9983] flex items-center justify-center h-[2rem] rounded-lg'>
            {+item?.sub_stage === 1 ? (
              <OnTheWayIcon fill='#E5F8BD' />
            ) : (
              <i className={`pi pi-map-marker text-white`}></i>
            )}{' '}
          </div>
        </Tooltip>
      )}
      {item.status_id === '5' && item?.sub_stage === undefined && (
        <Tooltip title='Assigned To Technician'>
          <div>
            <TechAssigned />
          </div>
        </Tooltip>
      )}
      {item.status_id === '6' && (
        <Tooltip title='Technician started working'>
          <div className='w-[3.5rem] bg-sky-200 flex items-center justify-center h-[2rem] rounded-lg'>
            <i className='pi pi-cog' style={{ fontSize: '1rem' }}></i>
          </div>
        </Tooltip>
      )}
      {/** to do */}
      {item.status_id === '7' && (
        <Tooltip title='Job Completed/ Upload Proof'>
          <div className=' bg-[#E5F8BD] flex items-center justify-center  rounded-lg w-full'>
            <UploadProof height='2.2rem' />
          </div>
        </Tooltip>
      )}
      {/** approval pending */}
      {item.status_id === '8' && (
        <Tooltip title='Approval Pending'>
          <div className='bg-[#F8F4E8] flex items-center justify-center rounded-lg py-0 w-full'>
            <PendingApprovalSvg />
          </div>
        </Tooltip>
      )}
      {/* Rejected */}
      {invoiceReceivedCheck(item, user) && (
        <Tooltip title='Invoice Received'>
          <div className=' bg-teal-200 flex items-center justify-center  rounded-lg w-full py-2'>
            <i className='pi pi-money-bill' style={{ fontSize: '1rem' }}></i>
          </div>
        </Tooltip>
      )}
      {invoiceSentCheck(item, user) && (
        <Tooltip title='Invoice Sent'>
          <div className='w-[3.3rem] bg-teal-200 flex items-center justify-center h-[2rem] rounded-full'>
            <i className='pi pi-money-bill' style={{ fontSize: '1rem' }}></i>
          </div>
        </Tooltip>
      )}
      {proofAccepetedCheck(item) && (
        <Tooltip title='Proofs Accepted'>
          <div className='flex items-center justify-center  rounded-lg w-full bg-[#EAF8E8]'>
            <ApprovedSvg />
          </div>
        </Tooltip>
      )}
      {item.status_id === '10' && (
        <Tooltip title='Rejected'>
          <div className=' bg-red-200 flex items-center justify-center  rounded-lg w-full py-2'>
            <i className='pi pi-times' style={{ fontSize: '1rem' }}></i>
          </div>
        </Tooltip>
      )}
      {paymentCompletedCheck(item) && (
        <Tooltip title='Invoice Approved'>
          <div className='w-full bg-green-200 flex items-center justify-center h-[2rem] rounded-lg'>
            <i className='pi pi-dollar' style={{ fontSize: '1rem' }}></i>
          </div>
        </Tooltip>
      )}
    </>
  );
};
