import { useEffect, useState } from 'react';
import { MonthSlotDropdown } from './MonthSlotDropdown';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { generateMonthRange } from '../../revenue/components/SelectTime';
import { PerfomanceGraph, PerfomanceGraphData } from './PerfomanceGraph';
import { ThreeDots } from 'react-loader-spinner';
import VendorAPI from '../../../../../api/vendorApi';
import { RootState } from '../../../../../state/reducers';
import { useSelector } from 'react-redux';
import BotomBarPerfomance from './BotomBarPerfomance';
interface OverViewdata {
  completed_count: number;
  incomplete_count: number;
  completion_rate: number;
}
function Perfomance() {
  const [months, setMonths] = useState<string>('4 Months');
  const [loader, setLoader] = useState<boolean>(false);
  const [urlParams] = useSearchParams();
  const vendorParam: string | null = urlParams?.get('vendor_ids');
  const isAllVendorSelected: boolean = !vendorParam;
  const initialState = {
    graphData: [],
    total_summary: {
      completed_count: 0,
      incomplete_count: 0,
      completion_rate: 0,
    },
  };
  const [allData, setAllData] = useState<{
    graphData: PerfomanceGraphData[];
    total_summary: OverViewdata;
  }>(initialState);
  const data = allData?.graphData;
  const { user }: any = useSelector((state: RootState) => state.userState);
  const handleMenuItemClick = async (time: string) => {
    setLoader(true);
    const now = dayjs();
    let durationInMonths = 0;
    switch (time) {
      case '4 Months':
        durationInMonths = 4;
        break;
      case '8 Months':
        durationInMonths = 8;
        break;
      case '12 Months':
        durationInMonths = 12;
        break;
      default:
        durationInMonths = 4;
    }
    const endDate = now.format('YYYY-MM');
    const startDate = now.subtract(durationInMonths - 1, 'month').format('YYYY-MM');

    const generatedMonths: string = JSON?.stringify(generateMonthRange(startDate, endDate));
    try {
      const res = await VendorAPI.getPerfomenceGraph({
        params: UseFetchPerfomanceQuery(user, generatedMonths),
      });
      if (res.status === 200) {
        setAllData({
          graphData: res.data.data?.monthly_data,
          total_summary: res.data.data?.total_summary,
        });
        setLoader(false);
      } else {
        setAllData(initialState);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      handleMenuItemClick(months);
    }, 100);
    return () => clearTimeout(timer);
  }, [months, vendorParam]);
  return (
    <div className='w-[70%] h-full p-1 border-[0.1rem] border-gray-300 rounded-[0.5rem] flex flex-col gap-2 shadow-md'>
      <p className=' text-[1rem] p-2 w-full flex justify-between'>
        <p className='flex gap-2 items-center'>
          Perfomance{' '}
          {isAllVendorSelected && (
            <p className='flex items-center bg-[#F6EFD3] text-[#AC9059] gap-2 py-1 px-2 ml-1 rounded-full text-[0.8rem]'>
              <i className='pi pi-info-circle text-[0.8rem]'></i> {'  '}
              "All" filter does not apply here! This data is my own.
            </p>
          )}
        </p>
        <MonthSlotDropdown month={months} setMonth={setMonths} />
      </p>
      {loader && (
        <div className='w-full h-full flex justify-center items-center '>
          <ThreeDots
            height='60'
            width='60'
            radius='9'
            color={'#4378C8'}
            ariaLabel='three-dots-loading'
            wrapperStyle={{}}
            visible={true}
          />
        </div>
      )}
      {!loader && (
        <>
          {data?.length > 0 ? (
            <div className='flex flex-col justify-between gap-2 overflow-auto p-2 bg-gray-50 rounded-[1rem] h-full mx-3 mb-3'>
              <div className='h-[80%] w-full border-b-[0.1rem] border-gray-200'>
                <PerfomanceGraph data={data} />
              </div>
              <div className='h-[20%] w-full flex flex-col '>
                <BotomBarPerfomance allData={allData} />
              </div>
            </div>
          ) : (
            <div className='flex justify-center items-center text-[1.1rem] text-gray h-[100%] rounded-l-[0.5rem] '>
              <i className='pi pi-info-circle mr-2'></i>
              No Data Found
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Perfomance;

const UseFetchPerfomanceQuery = (user?: any, generatedMonths?: string) => {
  const params = new URLSearchParams(window.location.search);
  const vendor_ids = params?.get('vendor_ids');
  let query = '';
  if (vendor_ids) {
    query += `&vendor_ids=${vendor_ids}`;
    const vendor_id_array = JSON.parse(vendor_ids);
    if (+vendor_id_array?.[0] === +user?.account_id) {
      query += `&partner_as_vendor=true`;
    }
  }
  if (!vendor_ids) {
    query += `&vendor_ids=${JSON.stringify([user?.account_id])}`;
    query += `&partner_as_vendor=true`;
  }
  if (generatedMonths) {
    query += `&months=${generatedMonths}`;
  }
  return query;
};
