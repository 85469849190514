import styled from "styled-components";
const color = "#187CB8"
export const TextFieldWrapperOne = styled.div`
& > div {
  width:100%;
}
& .MuiInputBase-root{
  // border-radius:7px;
  // height:3rem;
  // font-size:0.8rem;
}
& input{
  // height:1.8rem;
  // font-size:0.8rem;
}
// & label[data-shrink='true']{
//   top:0.5rem;
//   left:-0.2re;
// }
& .MuiFormLabel-filled, .Mui-focused{
  // top: 0;
}
& legend{
  // font-size: 0.76rem;
}
& input::placeholder{
  // font-size:0.8rem;
}

`

export const TextFieldWrapperTwo = styled.div`
width:100%;
& > div {
  width:100%;
}
& label{
  // top: -7px;
}
// & .MuiFormLabel-filled, .Mui-focused{
//   top: 0;
// }
`