const EditButton = (props: { height?: string; width?: string; active?: boolean }) => {
  const height = props?.height || '1.7rem';
  const width = props?.width || '1.7rem';
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <circle cx='10.5' cy='10.5' r='10.5' fill='#E8EEF8' />
      <path
        d='M6 13.2302V14.75C6 14.89 6.10998 15 6.24997 15H7.76975C7.83475 15 7.89974 14.975 7.94473 14.925L13.404 9.47077L11.5292 7.59603L6.07499 13.0503C6.025 13.1003 6 13.1603 6 13.2302ZM14.8538 8.02097C15.0487 7.826 15.0487 7.51104 14.8538 7.31607L13.6839 6.14623C13.489 5.95126 13.174 5.95126 12.979 6.14623L12.0642 7.0611L13.9389 8.93584L14.8538 8.02097Z'
        fill='#4378C8'
      />
    </svg>
  );
};
export default EditButton;
