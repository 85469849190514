import { Button } from '@mui/material';
import { useState } from 'react';
import EditIndex from '../../components/EditIndex';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../state/reducers';
import EditIndexNew from '../../components/EditIndexNew';

const ServicesOptedVendor = ({
  handelNextButtonvendor,
  item,
  services,
  setServices,
  predefinedServices,
  showVendor,
  note,
  setNote,
  DropDown,
  updateVendorInvoice,
  confirmNumber,
  invoiceDetails,
  invoices,
  propsNew,
}: {
  handelNextButtonvendor: any;
  item: any;
  services: any;
  setServices: any;
  predefinedServices: any;
  showVendor: any;
  setNote: any;
  note: any;
  DropDown: any;
  updateVendorInvoice: any;
  confirmNumber: number;
  invoiceDetails?: any;
  invoices: any;
  propsNew: any;
}) => {
  const props = {
    confirmNumber,
    DropDown,
    note,
    setNote,
    handelNextButtonvendor,
    invoiceDetails,
    invoices,
    ...propsNew,
  };
  return (
    <div className='w-full flex flex-col justify-between h-full'>
      <EditIndexNew
        item={item}
        services={services}
        setServices={setServices}
        predefinedServices={predefinedServices}
        showVendor={showVendor}
        updateVendorInvoice={updateVendorInvoice}
        props={props}
      />
    </div>
  );
};

export default ServicesOptedVendor;
