import { Tooltip } from '@mui/material';

function SekectBtn(props: {
  classes?: string;
  svg?: any;
  title: any;
  value?: any;
  onClick: (e: any) => void;
  up: boolean;
  isInCorrect?: boolean;
  onlyTitle?: boolean;
  haveDivider?: boolean;
}) {
  const { classes, svg, title, value, onClick, up, isInCorrect, onlyTitle, haveDivider } = props;
  return (
    <div
      className={`py-2 flex flex-row ${
        isInCorrect ? 'bg-rose-50' : 'bg-gray-50'
      } ${classes} items-center gap-4 rounded-xl px-4 transition duration-500 h-fit  cursor-pointer`}
      onClick={onClick}>
      {svg}
      <div className={`w-full  ${haveDivider && 'border-r-2'} pr-2`}>
        <div className='flex gap-1 items-center'>
          <p className={` text-primary font-bold ${onlyTitle ? 'text-lg' : 'text-sm'}`}>{title}</p>
          {value === 'MM/DD/YYYY' && !onlyTitle && (
            <Tooltip title={'Only Valid Date Will Be Consider To Schedule'} placement='top'>
              <i className='pi pi-info-circle hover:bg-blue-100 rounded-full text-[0.85rem]'></i>
            </Tooltip>
          )}
        </div>
        {!onlyTitle && <p className='font-bold text-md'>{value}</p>}
      </div>
      <i
        className={`pi pi-angle-up ${
          up ? 'rotate-0' : 'rotate-180'
        } ease-in-out duration-200 text-grey`}
      />
    </div>
  );
}

export default SekectBtn;
