import { useState } from 'react';
import CsrAPI from '../../api/csrApi';

export const useFetchNotesRemarks = () => {
  const [sendLoader, setSendLoader] = useState(false);
  const [remakrs, setRemarks] = useState([]);
  const getRemakrs = (data: { preventive_request_id: string; id: string }) => {
    setSendLoader(true);
    CsrAPI.getRemarks({ id: data?.preventive_request_id || data?.id })
      .then((res: any) => {
        if (res.status === 200) {
          setRemarks(res?.data?.data);
        }
        setSendLoader(false);
      })
      .catch((err) => console.log(err));
  };
  return { getRemakrs, remakrs, sendLoader };
};
export const fetchRemarks = async (item: any) => {
  if (item?.preventive_request_id || item?.[0]?.id) {
    try {
      const res = await CsrAPI.getRemarks({
        id: item?.preventive_request_id || item?.[0]?.id,
      });
      if (res.status === 200) {
        return res.data.data;
      } else {
        console.log('Failed to fetch remarks with status:', res.status);
        return null;
      }
    } catch (error) {
      console.error('Error fetching remarks:', error);
      return null;
    }
  } else {
    return null;
  }
};
