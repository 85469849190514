const Info = ({ item }: { item: any }) => {
  const { name, vin, plate } = item?.vehicle;
  return (
    <div className='flex flex-col gap-2'>
      <div
        className='container'
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className='text-lg' style={{ fontSize: '1rem', color: 'black' }}>
          <p className='text-lg'>{name || ''}</p>
          <p className='text-[#A0A0A0] text-md'>{vin || ''}</p>
        </div>
        <button
          className='border-1 border-primary text-primary'
          style={{
            borderRadius: '6px',
            padding: '0.2rem 1rem',
            fontSize: '1rem',
            fontWeight: 'normal',
            cursor: 'auto',
            marginBottom: '1.3rem',
          }}>
          {plate || ''}
        </button>
      </div>
    </div>
  );
};
export default Info;
