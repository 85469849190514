import { Tooltip } from '@mui/material';
import { toast } from '../../components/Toast/CustomToast';

const getBtnName = (item: any) => {
  const status_id = Number(item.status_id);
  const sub_stage = Number(item?.sub_stage);
  if (status_id === 10 || status_id === 3 || (status_id === 4 && sub_stage === 3)) {
    return 'View In Rejected';
  }
  if (status_id >= 1 && status_id <= 3) {
    return 'View In Schedule';
  }
  if (status_id >= 4 && status_id <= 11) {
    return 'View In Progress';
  }
  if (status_id === 12) {
    return 'View In Completed';
  }
};

export function GetVehicleName({ info }: any) {
  let nameArr: any[] = [];
  if (!Array.isArray(info.vehicle_name)) {
    nameArr = [info.vehicle_name];
  } else {
    nameArr = info.vehicle_name;
  }

  return (
    <>
      <Tooltip title={nameArr?.length > 0 ? '' : 'Vehicle Name'}>
        <span>
          Van{' '}
          <span
            className='text-primary break-words'
            style={{
              wordBreak: 'break-word',
            }}>
            {' '}
            {nameArr?.join(' , ')}{' '}
          </span>
        </span>
      </Tooltip>
    </>
  );
}

export interface AlertInspData {
  info_alert_id: string;
  alert_type: string;
  is_read: boolean;
  created_at: string; // You might want to use a Date type here if you parse the string into a Date object
  technician_name: string;
  vehicle_id: string;
  vehicle_name: string;
  company_id: string;
  health_checkup_id: string;
}

export const getInfoInspectionCSR = (notification: AlertInspData, notficationActions: any) => {
  if (notification.alert_type === 'checkup_completed') {
    return {
      ...notification,
      notificationMsg: (
        <p className='text-start'>
          Technician:{' '}
          <Tooltip title={'Technician'}>
            <span className='capitalize'>{notification.technician_name} </span>
          </Tooltip>{' '}
          has submitted an inspection report for vehicle <GetVehicleName info={notification} />
        </p>
      ),
      btnName: getBtnName(notification),
      onClick: (e: any) => {
        notficationActions['redirectToInspection'](e, notification);
      },
    };
  }

  return null;
};

export const getInfoCSR = (notification: any, notficationActions: any) => {
  if (notification?.health_checkup_id) {
    return getInfoInspectionCSR(notification, notficationActions);
  }

  const info = JSON.parse(notification?.message);
  const actionType = info?.action;
  const tempNotfication = {
    ...notification,
    messageInfo: info,
  };
  const infoName = info?.name;

  switch (actionType) {
    case 'update_lease_plan':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            Lease plan data of the
            <GetVehicleName info={info} />
            is incomplete
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          notficationActions['RedirectToRequest'](e, notification);
        },
      };
    case 'assigned_to_technician':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            <span className=' '>Request </span>
            has been assigned to technician by{' '}
            <Tooltip title={'Vendor'}>
              <span className='capitalize'>Vendor: {info.name} </span>
            </Tooltip>
            {' for'} <GetVehicleName info={info} />
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          if (tempNotfication?.bulk_id) {
            notficationActions['handleBulkRedirect'](e, notification);
          } else {
            notficationActions['RedirectToRequest'](e, notification);
          }
        },
      };
    case 'assign':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            Clients <span className=' '>({info.company_name?.join(' , ')})</span> has been assigned
            to you by{' '}
            <Tooltip title={'Supervisor'}>
              <span className=' capitalize'>Supervisor : {info.name}</span>
            </Tooltip>
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          notficationActions['default'](e, notification);
        },
      };
    case 'reject':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            <Tooltip title={'DSP'}>
              <span className='capitalize'>DSP: {info.name} </span>
            </Tooltip>{' '}
            has canceled schedule <span className=' '>Request </span>for{' '}
            <GetVehicleName info={info} />
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          notficationActions['RedirectToRequest'](e, notification);
        },
      };
    case 'accept':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            <Tooltip title={'DSP'}>
              <span className='capitalize'>DSP: {info.name} </span>
            </Tooltip>{' '}
            has selected schedule for <span className=' '>Request </span>{' '}
            <GetVehicleName info={info} />
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          notficationActions['RedirectToRequest'](e, notification);
        },
      };
    case 'working_by_technician':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            Technician:
            <Tooltip title={'Technician'}>
              <span className='capitalize'>{info.name} </span>
            </Tooltip>{' '}
            has started working for <GetVehicleName info={info} />
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          notficationActions['RedirectToRequest'](e, notification);
        },
      };
    case 'work_proof_submitted_vendor':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            <Tooltip title={'Vendor'}>
              <span className='capitalize'>Vendor: {info.name} </span>
            </Tooltip>{' '}
            {' has submitted work proof for '}
            <GetVehicleName info={info} />
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          notficationActions['RedirectToRequest'](e, notification);
        },
      };
    case 'work_proof_submitted_technician':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            <Tooltip title={'Technician'}>
              <span className='capitalize'>Technician: {info.name} </span>
            </Tooltip>{' '}
            has submitted work proof for <GetVehicleName info={info} />
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          notficationActions['RedirectToRequest'](e, notification);
        },
      };
    case 'payment_confirm':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            <Tooltip title={'Vendor'}>
              <span className='capitalize'>Vendor: {info.name} </span>
            </Tooltip>{' '}
            has confirmed payment for <GetVehicleName info={info} />
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          notficationActions['RedirectToRequest'](e, notification);
        },
      };
    case 'maintenance_ready':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            <Tooltip title={'DSP'}>
              <span className='capitalize'>DSP: {info.name} </span>
            </Tooltip>{' '}
            has assingned PM service of these{' '}
            <Tooltip title={'Vehicle Name'}>
              <span>
                {' '}
                Van
                {info.source === 'odometer' ? (
                  <span className='text-primary'>{info.vehicle_name.join(' , ')}</span>
                ) : (
                  <span className='text-primary'> {info.vehicle_name}</span>
                )}
              </span>
            </Tooltip>
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {},
      };

    case 'workproof due':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            Work proof due for <GetVehicleName info={info} />
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          notficationActions['RedirectToRequest'](e, notification);
        },
      };

    case 'schedule_by_dsp':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            <GetVehicleName info={info} />
            has been scheduled by dsp
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          notficationActions['handleBulkRedirect'](e, notification);
        },
      };

    case 'bulk_pm_complete':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            Bulk Request of <GetVehicleName info={info} />
            has been completed
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          notficationActions['handleBulkRedirect'](e, notification);
        },
      };

    case 'bulk_vendor_reject_request':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            Bulk Request of <GetVehicleName info={info} />
            has been rejected by vendor {infoName}
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          notficationActions['handleBulkRedirect'](e, notification);
        },
      };

    case 'bulk_vendor_accept_request':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            Bulk Request of <GetVehicleName info={info} />
            has been accepted by vendor {infoName}
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          notficationActions['handleBulkRedirect'](e, notification);
        },
      };

    case 'vendor_accept_request':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            Request of <GetVehicleName info={info} />
            has been accepted by vendor {infoName}
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          notficationActions['RedirectToRequest'](e, notification);
        },
      };

    case 'vendor_reject_request':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            Request of <GetVehicleName info={info} />
            has been rejected by vendor {infoName}
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          notficationActions['RedirectToRequest'](e, notification);
        },
      };

    case 'technician_reassigned':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            Technician has been reassigned for <GetVehicleName info={info} />
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          notficationActions['RedirectToRequest'](e, notification);
        },
      };
    case 'technician_removed':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            Technician has been removed for <GetVehicleName info={info} />
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          notficationActions['RedirectToRequest'](e, notification);
        },
      };

    case 'approval_pending':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            No action has been taken by the vendor {infoName} for
            {tempNotfication.bulk_id
              ? ` the Bulk Request id - ${tempNotfication?.bulk_id}`
              : ` the Request id - ${tempNotfication?.pm_request_id}`}
          </p>
        ),
        onClick: (e: any) => {
          if (tempNotfication?.bulk_id) {
            notficationActions['handleBulkRedirect'](e, notification);
          } else {
            notficationActions['RedirectToRequest'](e, notification);
          }
        },
      };

    case 'work_proof_skipped_vendor':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            <Tooltip title={'Vendor'}>
              <span className='capitalize'>Vendor: {info.name} </span>
            </Tooltip>{' '}
            {' has skipped work proof for'}
            <GetVehicleName info={info} />
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          notficationActions['RedirectToRequest'](e, notification);
        },
      };

    case 'lease_plan_approved_from_missing':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            {'Lease Plan approved from data is incomplete for these '}
            <GetVehicleName info={info} />
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          notficationActions['readNotification'](e, notification);
        },
      };
    case 'technician_started_journey':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            Technician <span className='capitalize'>{infoName}</span> has started journey for the
            maintenence of <GetVehicleName info={info} />
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          let notificationData = null;
          if (notification?.bulk_id) {
            const selectedOne = notification?.bulk_details?.find(
              (d: any) => d?.pm_request_id === notification?.pm_request_id
            );
            notificationData = {
              ...selectedOne,
              ...notification,
            };
          } else {
            notificationData = tempNotfication;
          }
          if (+notificationData?.sub_stage === 1 && +notificationData?.status_id === 5) {
            notficationActions['showLocationModel'](e, notificationData);
          } else {
            notficationActions['readNotification'](e, notificationData);
            toast.info('The technician has reached the service location');
          }
        },
      };
    case 'technician_reached_destination':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            Technician <span className='capitalize'>{infoName}</span> has reached to the destination
            for the maintenence of <GetVehicleName info={info} />
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          notficationActions['readNotification'](e, notification);
          toast.info('The technician has reached the service location');
        },
      };
    case 'mismatch_final_amount':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            {'Mismatch found in payment confirmation for '}
            <GetVehicleName info={info} /> from Vendor{' '}
            <span className='capitalize'>{infoName}</span>
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          if (tempNotfication?.bulk_id) {
            notficationActions['handleBulkRedirect'](e, notification);
          } else {
            notficationActions['RedirectToRequest'](e, notification);
          }
        },
      };
    default:
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            {info?.message_name} <span className='capitalize'>{infoName}</span> for{' '}
            <GetVehicleName info={info} />
          </p>
        ),
        // btnName: getBtnName(notification),
        onClick: (e: any) => {
          if (tempNotfication?.bulk_id) {
            notficationActions['handleBulkRedirect'](e, notification);
          } else {
            notficationActions['RedirectToRequest'](e, notification);
          }
        },
      };
  }
};
