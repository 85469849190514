import { Button } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../state/reducers';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state';
import PriorityIcons from './PriorityIcons';

export default function PrioritySelction({ data, disabled, onClick, width, height }: any) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { setBulkVehicles } = bindActionCreators(actionCreators, dispatch);
  const { bulkVehicles } = useSelector((state: RootState) => state.mainState);
  const { user }: any = useSelector((state: RootState) => state.userState);
  const isCSR: boolean = user?.role === 'fleetmax_csr';

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!disabled) setAnchorEl(event.currentTarget);
  };
  const onChange = (ele?: any) => {
    if (onClick) {
      onClick(ele);
    } else {
      setBulkVehicles({
        bulkVehicles: bulkVehicles.map((obj: any) => {
          if (
            (obj?.info?.vehicle?.id || obj?.vehicle?.id) ===
            (data?.info?.vehicle?.id || data?.vehicle?.id)
          ) {
            const tempObj = {
              ...obj,
            };
            tempObj['priority'] = ele;
            return tempObj;
          }
          return obj;
        }),
      });
    }
  };
  const handleMenuItemClick: (ele: string) => void = (ele: string) => {
    handleClose();
    onChange(ele);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const priorities = ['1', '2'];
  return (
    <>
      {isCSR ? (
        <Button
          variant='outlined'
          className={`flex justify-between items-center border-gray-300 rounded-[0.7rem] px-3 py-2 rounded-[0.3rem] w-[${
            width ? width : '14rem'
          }] h-[${
            height ? height : '3rem'
          }] justify-between hover:bg-primary-light hover:text-primary-dark transition-all shadow-md border-gray-100 border-[0.08rem] `}
          value={data?.priority}
          id='basic-button'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}>
          <p className='text-[#515151] font-bold flex gap-1'>
            <PriorityIcons
              iconType={data?.priority || '2'}
              disabled={disabled}
              height={data?.isSmall ? '1.5rem' : null}
              weidth={data?.isSmall ? '1.5rem' : null}
            />
            <p className='flex items-center gap-1'># {data?.priority || '2'} </p>
          </p>
          <i
            className={`pi pi-angle-${
              open ? 'up' : 'down'
            } transition-transform duration-300 transform ml-2  text-[#C9C9C9] `}></i>
        </Button>
      ) : (
        <div
          className={`flex justify-between items-center border-gray-400 rounded-[0.7rem] px-3 py-2 rounded-[0.3rem] w-[${
            width ? width : '14rem'
          }] justify-between  hover:text-primary-dark transition-all border-gray-100 border-[0.2rem] h-[3rem]`}>
          <p className='text-[#515151] font-bold flex gap-1'>
            <PriorityIcons iconType={data?.priority || '2'} disabled={disabled} />
            <p className='flex items-center gap-1'># {data?.priority || '2'} </p>
          </p>
        </div>
      )}

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          sx: {
            width: '8rem',
            marginTop: '0.5rem',
          },
        }}>
        {priorities.map((ele) => {
          return (
            <MenuItem
              key={ele}
              onClick={() => !disabled && handleMenuItemClick(ele)}
              className='flex gap-3'
              selected={data?.priority === ele}>
              <PriorityIcons iconType={ele || '2'} disabled={disabled} /> #{ele}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

export const getBulkPriorityStatus = (item: any) => {
  if (item?.bulkSchedules?.some((schedule: any) => schedule.priority === '1')) {
    return '1';
  } else if (item?.data?.some((schedule: any) => schedule.priority === '1')) {
    return '1';
  } else return '2';
};
