import { ThreeDots } from 'react-loader-spinner';

export const Loader = (props: { height?: string | null; width?: string | null }) => {
  const heightSx = props?.height ? props?.height : '40';
  const widthSx = props?.width ? props?.width : '40';
  return (
    <div className='w-full h-full flex justify-center items-center '>
      <ThreeDots
        height={heightSx}
        width={widthSx}
        radius='9'
        color={'#4378C8'}
        ariaLabel='three-dots-loading'
        wrapperStyle={{}}
        visible={true}
      />
    </div>
  );
};
export const withLoader = (
  isLoading: boolean,
  Component: JSX.Element,
  customLoader?: JSX.Element,
  height?: string,
  width?: string
) => {
  const loader = customLoader ? (
    customLoader
  ) : (
    <Loader height={height ? height : null} width={width ? width : null} />
  );
  return isLoading ? loader : Component;
};
