import { Tooltip } from '@mui/material';

function InvoiceAmount({ rawData, index, item }: { rawData: any; index: number; item?: any }) {
  return (
    <td className='px-[0.2rem]  py-4'>
      {' '}
      <div className='flex items-center justify-center min-w-[5rem] border-l-borderColor border-l-[0.2rem]'>
        <Tooltip
          title={
            <div>
              <div className='flex justify-between px-[0.2rem] py-[0.2rem] gap-4 text-[1rem]'>
                <div>Sub Total</div>
                <div className='text-tableLinkColor'>
                  ${parseFloat(rawData?.[index]?.invoice?.subtotal_amount || 0).toFixed(2)}
                </div>
              </div>
              <div className='flex justify-between px-[0.2rem] py-[0.2rem] gap-4 text-[1rem]'>
                <div>Sales Tax</div>
                <div className='text-tableLinkColor'>
                  ${parseFloat(rawData?.[index]?.invoice?.total_tax_amount || 0).toFixed(2)}
                </div>
              </div>
              <div className='flex justify-between px-[0.2rem] py-[0.2rem] gap-4 text-[1rem]'>
                <div>Estimated Total</div>
                <div className='text-tableLinkColor'>
                  ${parseFloat(rawData?.[index]?.invoice?.total_amount || 0).toFixed(2)}
                </div>
              </div>{' '}
            </div>
          }>
          <div className='flex text-wrap w-[7rem] text-center justify-center'>
            <div className='text-center mr-[0.5rem]'>
              {item?.amount === 'N/A' ? 'N/A' : `$${item?.amount}`}
            </div>
            <i className='pi pi-info-circle mt-0.5'></i>
          </div>
        </Tooltip>
      </div>
    </td>
  );
}

export default InvoiceAmount;
