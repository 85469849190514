import { Button, Tooltip } from '@mui/material';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import UploadProof from '../../../../assets/JobCompletedOrUploadProofs';
import CustomConfirm from '../../../../components/PopOver/CustomDelete';
import { RootState } from '../../../../state/reducers';
import InvoicingPopUp from '../../../common/requestDetail/InvoicingExpandPopUp';
import Logs from '../../../common/requestDetail/MaintainanceLogsModel';
import { UpdatedAtTime } from '../../primaryComponents/AppointmentTime';
import AcceptRejectHandler from './components/AcceptRejectHandler';

const GetActions = ({ item }: any) => {
  const { requestServices }: any = useSelector((state: RootState) => state.userState);
  const checklistData = requestServices[item?.id];
  const hasProof = useMemo(() => {
    if (checklistData && checklistData.length > 0) {
      const AllProofs: any = [];
      checklistData?.forEach((el: any) => {
        if (el?.proofs?.length > 0) {
          AllProofs.push(...el.proofs);
        }
        if (el?.titles?.length > 0) {
          el?.titles?.forEach((obj: any) => {
            if (obj.proofs?.length > 0) {
              AllProofs.push(...obj.proofs);
            }
          });
        }
      });
      return AllProofs.length !== 0;
    }
    return false;
  }, [checklistData]);
  const handleEdit = (onClicked: string) => {
    item?.setInvoiceModal?.({
      open: true,
      data: {
        ...item,
        onClicked,
        clickedFromViewInvoice: true,
      },
    });
    item?.setViewInvoiceModal({ open: false, data: {} });
  };
  return (
    <>
      {' '}
      {item.status_id === '4' && item?.sub_stage === '1' && (
        <div>
          <Button variant='text' color={'primary'} className='px-4  font-bold cursor-auto w-full'>
            Awaiting Acceptance
          </Button>
        </div>
      )}
      {item.status_id === '4' && (item?.sub_stage ? item?.sub_stage === '2' : true) && (
        <div>
          <Button variant='text' color={'primary'} className='px-4  font-bold cursor-auto w-full'>
            Assigned to Vendor
          </Button>
        </div>
      )}
      {item.status_id === '5' && (
        <div>
          <Button variant='text' color={'info'} className='px-4  font-bold cursor-auto w-full'>
            Assigned to Techinician
          </Button>
        </div>
      )}
      {item.status_id === '6' && (
        <div>
          <Button variant='text' color={'warning'} className='px-4  font-bold cursor-auto w-full'>
            Working by Techinician
          </Button>
        </div>
      )}
      {[7, 8].includes(+item.status_id) && (
        <div
          className='py-1 px-3 flex  bg-[#E5F8BD] items-center rounded-xl pl-[-0.5rem] cursor-pointer'
          onClick={() =>
            item?.setImageModal({
              open: true,
              data: item,
            })
          }>
          {/* <ServicesDetails checklistData={checklistData} item={item} /> */}
          <div className='ml-[-0.8rem]'>
            <UploadProof height='2.2rem' />
          </div>
          <p className='text-[#3E9983]  text-sm font-bold ml-[-0.5rem]'>{'Upload Proofs'}</p>
        </div>
      )}
      {/** approval pending */}
      {item.status_id === '8' && <AcceptRejectHandler item={item} hasProof={hasProof} />}
      {/** approved */}
      {item.status_id === '9' && (
        <div className='w-full flex flex-col gap-2 '>
          {!item?.lease_plan && (
            <div className='py-2 px-3 flex justify-center bg-slate-200 items-center rounded-lg'>
              <span
                className='text-blue-600 cursor-pointer  font-bold '
                onClick={() =>
                  item?.setLeasePlanModal({
                    open: true,
                    data: item,
                  })
                }>
                {'Lease Plan Update'}
              </span>
            </div>
          )}
          {item?.lease_plan && (
            <>
              {/* <Button
                variant='outlined'
                color={'primary'}
                className='font-bold w-full mx-auto'
                onClick={() =>
                  item?.setLeasePlanModal({
                    open: true,
                    data: item,
                  })
                }>
                View Lease Plan
              </Button> */}
              <div className='w-full  bg-[#F3F3F3] flex justify-center p-2 rounded-lg pr-9'>
                <div
                  className='font-bold text-primary   border-primary'
                  onClick={() =>
                    item?.setLeasePlanModal({
                      open: true,
                      data: item,
                    })
                  }>
                  <button className='border-transparent'> View Lease Plan </button>
                </div>
              </div>
              <InvoicingPopUp
                item={item}
                handleClick={(onClicked: string) => {
                  item?.setInvoiceModal({
                    open: true,
                    data: {
                      ...item,
                      onClicked,
                    },
                  });
                }}
              />
            </>
          )}
        </div>
      )}
      {/* Rejected */}
      {item.status_id === '10' && (
        <div className='py-1 px-3 flex justify-between bg-slate-200 items-center rounded-lg'>
          {/* <ServicesDetails checklistData={checklistData} item={item} /> */}
          <Button variant='text' color={'error'} className='px-4 font-bold'>
            Rejected
          </Button>
        </div>
      )}
      {item.status_id === '3' && (
        <div className={`px-3 py-2 bg-red-200 rounded-lg flex justify-between items-center`}>
          <p>Rejected by DSP</p>
          <p>
            <UpdatedAtTime item={item} />
          </p>
        </div>
      )}
      {(!['sent', 'updated'].includes(item?.dsp_invoice_status) ||
        !['amount_submitted'].includes(item?.vendor_invoice_status)) &&
        +item.status_id === 11 && (
          <Tooltip title={!item?.lease_plan ? 'Lease Plan Data Missing' : ''} placement='top' arrow>
            <span>
              <InvoicingPopUp
                item={item}
                handleClick={(onClicked: string) => {
                  item?.setInvoiceModal({
                    open: true,
                    data: {
                      ...item,
                      onClicked,
                    },
                  });
                }}
              />
            </span>
          </Tooltip>
        )}
      {+item?.status_id >= 11 && (
        <div>
          <Button
            variant='outlined'
            color={'primary'}
            className='font-bold w-full mx-auto'
            onClick={() =>
              item?.setViewInvoiceModal({
                open: true,
                data: { ...item, handleEdit },
              })
            }>
            View Invoice
          </Button>
        </div>
      )}
      {item.status_id === '12' && (
        <div className='py-1 px-3 flex justify-center bg-slate-200 items-center rounded-lg'>
          <Button variant='text' color={'primary'} className='px-4 font-bold'>
            Completed
          </Button>
        </div>
      )}
      {[1, 2, 3].includes(+item.status_id) && (
        <Button sx={{ background: '#F3F3F3' }}>
          <div className='flex justify-center w-full bg-transparent text-[1.1rem] pr-5'>
            {[1, 2, 3].includes(+item.status_id) && item.request_type !== 'manual_dsp' && (
              <div className='flex justify-center flex-col items-center'>
                <div className='flex justify-center'>
                  <CustomConfirm
                    comp={
                      <div className=' font-bold border-r-[0.1rem] px-4 border-r-primary text-[1rem]'>
                        Remove Vehicle
                      </div>
                    }
                    title={`Removing , ${item.vehicle.name}`}
                    info={`Are you sure, want to remove this vehicle from bulk schedule ?`}
                    acceptText='Yes , Remove'
                    accept={() => {
                      item?.handleRemoveVehicle({
                        id: item.id,
                        bulk_id: item.bulk_id,
                      });
                    }}
                  />
                </div>
              </div>
            )}
            <p className={`px-4 `}>
              <Logs type='bulk' item={item} />
            </p>
          </div>
        </Button>
      )}
      {![1, 2, 3].includes(+item.status_id) && (
        <Button className='w-full' sx={{ background: '#F3F3F3' }}>
          <Logs type='bulk' item={item} />
        </Button>
      )}
    </>
  );
};

export default GetActions;
