export function getActions({
  item,
  parentindex,
  array,
}: {
  item: any;
  parentindex: number;
  array: any[];
}) {
  const showDate =
    parentindex === 0 ||
    item?.appointments?.[0]?.date !== array[parentindex - 1]?.appointments?.[0]?.date;
  const isFirstEleofSameDate =
    showDate &&
    (parentindex === 0 ||
      array[parentindex - 1]?.appointments?.[0]?.date !== item?.appointments?.[0]?.date);
  const isLastEleofSameDate =
    array[parentindex + 1]?.appointments?.[0]?.date !== item?.appointments?.[0]?.date;
  return {
    showDate,
    isFirstEleofSameDate,
    isLastEleofSameDate,
  };
}
