import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import SelectTime from '../revenue/components/SelectTime';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { RootState } from '../../../../state/reducers';
import { useSelector } from 'react-redux';
import { SelectvendorDropdown } from './SelectVendor';

function Toggle(newSearchParams: any) {
  const { user }: any = useSelector((state: RootState) => state.userState);
  const [urlParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const isRevenuePage =
    window.location.href.includes('vendor/dashboard/revenue') ||
    window.location.href.includes('partner/dashboard/revenue');
  let pathname: any = location.pathname;
  const isPartner: boolean = user?.role === 'fleetmax_partner';
  const weekOfYear = (date: dayjs.Dayjs) => {
    const startOfYear = date.startOf('year');
    const weekNumber = Math.ceil(date.diff(startOfYear, 'week', true)) + 1;
    return weekNumber;
  };
  const handleMenuItemClick = (time: string) => {
    const durationMap: { [key: string]: number } = {
      '4 Weeks': 4,
      '8 Weeks': 8,
      '10 Weeks': 10,
      '12 Weeks': 12,
    };

    const durationInWeeks = durationMap[time] || 4;
    const endDate = dayjs().startOf('week');
    const startDate = endDate.subtract(durationInWeeks - 1, 'week');

    const selectedWeeks: string[] = [];
    let currentDate = endDate;

    while (currentDate.isAfter(startDate, 'day') || currentDate.isSame(startDate, 'day')) {
      const weekNumber = weekOfYear(currentDate);
      const year = currentDate.year();
      selectedWeeks.unshift(`W${weekNumber}-${year}`);
      currentDate = currentDate.subtract(1, 'week');
    }

    const newParams = new URLSearchParams();
    newParams.set('page_number', '1');
    newParams.set('revenue_sort', 'desc');
    newParams.set(
      'weeks',
      JSON.stringify(
        selectedWeeks.map((item) => {
          const [weekNumber, year] = item.split('-');
          return `${year.toLowerCase()}-${weekNumber}`;
        })
      )
    );
    if (isPartner && urlParams.get('vendor_ids')) {
      newParams?.set('vendor_ids', urlParams.get('vendor_ids') || '');
    }
    newParams.set('isWeek', 'true');
    const newUrl = isPartner
      ? `/partner/dashboard/revenue?${newParams.toString()}`
      : `/vendor/dashboard/revenue?${newParams.toString()}`;
    navigate(newUrl);
  };
  useEffect(() => {
    const weeks = urlParams.get('weeks');
    const months = urlParams.get('months');
    if (!weeks && !months && isRevenuePage) {
      handleMenuItemClick('10 Weeks');
    }
  }, []);
  return (
    <>
      {(pathname === '/vendor/dashboard' ||
        pathname === '/vendor/dashboard/revenue' ||
        pathname === '/partner/dashboard' ||
        pathname === '/partner/dashboard/revenue') && (
        <>
          <div className='h-full w-fit flex gap-4 font-bold ml-4'>
            <p
              className={`${
                pathname === '/vendor/dashboard' || pathname === '/partner/dashboard'
                  ? 'border-b-2 border-primary text-primary'
                  : ''
              }  px-3 h-full flex items-center cursor-pointer hover:bg-primary-lighter`}
              onClick={() => {
                if (isPartner) {
                  const vendor_ids = urlParams?.get('vendor_ids');
                  const newParams = new URLSearchParams();
                  if (vendor_ids) newParams.set('vendor_ids', vendor_ids || '');
                  newParams.set('type', 'requests');
                  newParams.set('offset', '1');
                  const newUrl = `/partner/dashboard?${newParams.toString()}`;
                  navigate(newUrl);
                } else {
                  navigate('/dashboard');
                }
              }}>
              Request
            </p>
            <p
              className={`${
                pathname === '/vendor/dashboard/revenue' ||
                pathname === '/partner/dashboard/revenue'
                  ? 'border-b-2 border-primary text-primary'
                  : ''
              }  px-3 h-full flex items-center cursor-pointer hover:bg-primary-lighter `}
              onClick={() => {
                if (
                  pathname !== '/vendor/dashboard/revenue' ||
                  pathname !== '/partner/dashboard/revenue'
                ) {
                  handleMenuItemClick('10 Weeks');
                }
              }}>
              Revenue
            </p>
          </div>
          <div className='flex items-center gap-2 mr-[1rem]'>
            {isRevenuePage && <SelectTime newSearchParams={newSearchParams} />}
            {isPartner && <SelectvendorDropdown />}
          </div>
        </>
      )}
    </>
  );
}

export default Toggle;
