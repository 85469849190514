import React, { useRef } from 'react';
import useFetchAlerts from './useFetchAlerts';
import OpenModel from '../../../openModel/OpenVendorModel';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../state/reducers';
import { Environment } from '../../../../../constants/environment';
import { io } from 'socket.io-client';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../../state';
import { actionText, getAlertsText } from './getAlertsText';
import { useSearchParams } from 'react-router-dom';
const socket = io(Environment.baseUrl, {
  path: '/socket-fleetmax',
  forceNew: true,
  transports: ['websocket'],
  reconnection: true,
});
const setupConnection = (socket: any) => {
  let user: any | null = null;
  const getItem: string | null = localStorage.getItem('user');
  if (getItem) user = JSON.parse(getItem);
  const account_id = user?.account_id;
  const token = user?.token;
  socket.auth = { account_id, token };
};
function Alerts() {
  const dispatch = useDispatch();
  const { setFetchData } = bindActionCreators(actionCreators, dispatch);
  const [urlParams] = useSearchParams();
  const scrollableDivRef: any = useRef(null);
  const { alertData, refetchAlert, offset, setOffset, setVendorIds, vendorIds }: any =
    useFetchAlerts();
  const {
    socketFleetMax: socket,
    user,
    refetchData,
  }: any = useSelector((state: RootState) => state.userState);
  const isPartner: boolean = user?.role === 'fleetmax_partner';
  const [showModel, setShowModel] = React.useState<any>({ open: false, data: {} });
  const vendor_ids = urlParams?.get('vendor_ids');
  React.useEffect(() => {
    const scrollableDiv = scrollableDivRef.current;
    const handleScroll = () => {
      if (scrollableDiv.scrollTop + scrollableDiv.clientHeight + 1 >= scrollableDiv.scrollHeight) {
        setOffset(offset + 10);
      }
    };
    scrollableDiv?.addEventListener('scroll', handleScroll);
    return () => {
      scrollableDiv?.removeEventListener('scroll', handleScroll);
    };
  }, [urlParams, offset, vendorIds]);
  React.useEffect(() => {
    if (!isPartner) {
      setOffset(0);
    }
  }, []);
  React.useEffect(() => {
    if (isPartner) {
      if (offset !== 0) setOffset(0);
      setVendorIds(vendor_ids || null);
    }
  }, [vendor_ids]);
  React.useEffect(() => {
    const timer = setTimeout(() => {
      refetchAlert(offset);
    }, 100);
    setFetchData({
      ...refetchData,
      fetchAlerts: () => {
        refetchAlert(offset);
      },
    });
    return () => clearTimeout(timer);
  }, [offset, vendorIds, vendor_ids]);
  React.useEffect(() => {
    if (user && user?.role !== 'fleetmax_supervisor' && socket && Object.keys(socket).length > 0) {
      setupConnection(socket);
      socket.connect();
      socket.on('connect', () => {
        socket.emit('joinRoomForNotification');
      });
      socket?.on('newAlert', (message: string) => {
        refetchAlert(offset);
      });
      socket.on('authentication_error', (error: any) => {
        console.error('Authentication error:', error);
      });
    }
  }, [socket]);
  return (
    <div className='w-[70%] h-full p-1 border-[0.1rem] border-gray-300 rounded-[0.5rem] flex flex-col gap-2 shadow-md'>
      <p className=' text-[1rem] p-2'>Alerts</p>
      <div className='flex flex-col gap-4 overflow-auto p-2' ref={scrollableDivRef}>
        {alertData?.length > 0 &&
          [...alertData]?.map((ele: any) => {
            const info = JSON.parse(ele?.message);
            return (
              <div
                key={ele?.info_alert_id}
                className='border-[0.1rem] border-[#C55] p-2 py-4 rounded-[0.5rem] w-full flex justify-between items-center bg-[#FCF5F5] gap-2'>
                <div className='flex items-center gap-2'>
                  <i className='pi pi-exclamation-triangle text-[#B6531C] text-[1.1rem]'></i>
                  <p>{getAlertsText(ele)}</p>
                </div>
                <p
                  className='hover:underline font-bold cursor-pointer whitespace-nowrap'
                  onClick={() => {
                    if (ele?.pm_request_id)
                      setShowModel({
                        show: true,
                        data: {
                          ...ele,
                          id: ele?.pm_request_id,
                        },
                      });
                  }}>
                  {actionText(info.action)}
                </p>
              </div>
            );
          })}
      </div>
      {alertData?.length === 0 && (
        <div className='w-full h-full flex justify-center items-center gap-2 text-gray-500 '>
          <i className='pi pi-info-circle mt-0.5'></i>
          No Alerts!
        </div>
      )}
      {showModel?.show && (
        <OpenModel
          item={showModel.data}
          handleClose={() => setShowModel({ show: false, data: {} })}
          refetch={() => {
            setOffset(0);
            refetchAlert(offset);
          }}
        />
      )}
    </div>
  );
}

export default Alerts;
