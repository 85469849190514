import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state';
import { RootState } from '../../../../state/reducers';
import HandleLoad from '../../../../components/Loader/HandleLoad';
import ChatAPI from '../../../../api/ChatAPI';
import { toast } from '../../../../components/Toast/CustomToast';

function useFetchHeads() {
  const dispatch = useDispatch();
  const loader = HandleLoad();
  const { setSelectedChat, setChatListType, setProfileDetails, setSocketInstance } =
    bindActionCreators(actionCreators, dispatch);
  const { chatListUsers, groupUsersDetails }: any = useSelector(
    (state: RootState) => state.userState
  );

  const fetchChatProfile = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const idFromUrl: any = searchParams?.get('id');
    if (searchParams?.get('id')) {
      setProfileDetails([]);
      ChatAPI.getChatProfileDetails(idFromUrl).then((res) => {
        if (res.status === 200) {
          const profileData = res?.data?.data;
          setProfileDetails(profileData);
        } else {
          toast.error('Something went wrong');
        }
      });
    }
  };

  const fetchUserDetails = async (id: number) => {
    loader(true);
    if (id) {
      const res = await ChatAPI.getUserDetails(id);
      loader(false);
      if (res.status === 200) {
        if (res?.data?.data?.length > 0) {
          return res?.data?.data[0];
        } else {
          return null;
        }
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  const fetchHeads = (selectedChatsCategory?: string, loader = false, showNewMessge = false) => {
    const searchParams = new URLSearchParams(window.location.search);
    dispatch({
      type: 'FETCH_CHAT_HEADS',
      payload: {
        data: selectedChatsCategory || searchParams?.get('type'),
        loader: loader,
        showNewMessge: showNewMessge,
      },
    });
  };

  const fetchChatUsers = (selectedUsersCategory = 'all') =>
    dispatch({
      type: 'FETCH_CHATLIST_USERS',
      payload: selectedUsersCategory,
    });
  const fetchChatVendorPartner = () =>
    dispatch({
      type: 'FETCH_VENDOR_PARTNER',
    });
  const fetchChatTechnicianPartner = () =>
    dispatch({
      type: 'FETCH_TECHNICIANS_PARTNER',
    });
  const fetchChatCsrPartner = () =>
    dispatch({
      type: 'FETCH_CSR_PARTNER',
    });

  const fetchChatUsersVendor = (selectedUsersCategory = 'All') =>
    dispatch({
      type: 'FETCH_CHATLIST_USERS_VENDOR',
      payload: selectedUsersCategory,
    });

  const fetchMessages = (loader = false) => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams?.get('id')) {
      dispatch({
        type: 'FETCH_CHAT_MESSAGES',
        payload: {
          data: {
            room_id: searchParams?.get('id'),
          },
          groupUsersDetails: groupUsersDetails,
          loader: loader,
        },
      });
    }
  };

  return {
    chatListUsers,
    fetchHeads,
    fetchMessages,
    fetchChatUsers,
    setSelectedChat,
    setChatListType,
    fetchChatUsersVendor,
    fetchChatProfile,
    fetchUserDetails,
    setSocketInstance,
    fetchChatVendorPartner,
    fetchChatCsrPartner,
    fetchChatTechnicianPartner,
  };
}

export default useFetchHeads;
