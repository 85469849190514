export default function (props: { height?: string, width?: string }) {
  const height = props?.height || 193
  const width = props?.width || 193
  return (
    <svg width={width} height={height} viewBox="0 0 193 111" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M111.986 30.9434C106.671 21.7852 109.797 10.091 118.968 4.82364L122.926 2.55035C132.098 -2.71703 143.841 0.437094 149.156 9.59528L189.693 79.4417C195.008 88.5999 191.883 100.294 182.711 105.561L178.753 107.835C169.582 113.102 157.839 109.948 152.524 100.79L111.986 30.9434Z" fill="#B2D2E3" />
      <path d="M48.4719 8.90353C54.3941 0.127465 66.3259 -2.22488 75.1225 3.64941L78.9026 6.17367C87.6992 12.048 90.0294 23.9244 84.1073 32.7005L38.9105 99.6783C32.9884 108.454 21.0565 110.807 12.2599 104.932L8.47986 102.408C-0.316751 96.5339 -2.64699 84.6574 3.27512 75.8814L48.4719 8.90353Z" fill="#187CB8" />
      <path d="M114.354 8.90353C120.276 0.127465 132.208 -2.22488 141.004 3.64941L144.784 6.17367C153.581 12.048 155.911 23.9244 149.989 32.7005L104.792 99.6783C98.8702 108.454 86.9384 110.807 78.1417 104.932L74.3617 102.408C65.5651 96.5339 63.2349 84.6574 69.157 75.8814L114.354 8.90353Z" fill="#187CB8" />
    </svg>

  );
}
