import { useEffect } from "react";
import { clarity } from "react-microsoft-clarity";
// @ts-ignore
import { gtag, initDataLayer, install } from "ga-gtag";
import {Environment} from "../constants/environment";

const Analytics = ({ children }:any) => {
  useEffect(() => {
    if (Environment.env === "production") {
      initDataLayer();
      gtag("consent", "default", {
        ad_storage: "denied",
        analytics_storage: "granted",
      });
      install("G-KNGNT1WX7M");
      clarity.init("m53vlkdf15");
    }
    // if (Environment.env === "staging") {
    //   clarity.init("lr8q2h6the");
    // }
  }, []);
  return <>{children}</>;
};
export default Analytics;

