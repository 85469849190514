import { toast } from 'react-toastify';
import HandleLoad from '../../components/Loader/HandleLoad';
import ModalForm from '../../components/ModalForm';
import OverlayContainer from '../../components/OverlayContainer';
import PreventiveAPI from '../../api/preventiveApi';
import useFetchCheckList from '../hooks/useFetchCheckList';

export const DeleteModel = ({
  handleClose,
  data,
  handleCloseImageModel,
  item,
}: {
  handleClose: any;
  data: any;
  handleCloseImageModel: any;
  item: any;
}) => {
  const loader = HandleLoad();
  const { getCheckList } = useFetchCheckList();
  const handleDeleteImage = () => {
    loader(true);
    PreventiveAPI.deleteImages({ url: data.file_url, id: data.checklist_id }).then((res: any) => {
      if (res.status === 200) {
        toast.success(`Proof removed successfully`);
        getCheckList(item, true);
      } else {
        toast.error('something went wrong');
      }
      handleClose();
      handleCloseImageModel();
      loader(false);
    });
  };
  return (
    <OverlayContainer show={true}>
      <ModalForm title={'Add CSR'} onClose={handleClose} width='10rem' noLabel>
        <>
          <div className='w-full p-3'>
            <p>Do you want to delete this proof ?</p>
            <div className='flex justify-between mt-4'>
              <button
                className='items-center text-[#C55] rounded-[0.5rem] p-3 border-transparent'
                style={{ background: 'rgba(204, 85, 85, 0.13)' }}
                onClick={() => {
                  handleDeleteImage();
                }}>
                Yes, Remove Proof
              </button>
              <button
                className='items-center text-primary rounded-[0.5rem] p-3 border-transparent bg-primary-light ml-4 font-bold px-8'
                onClick={handleClose}>
                No
              </button>
            </div>
          </div>
        </>
      </ModalForm>
    </OverlayContainer>
  );
};
