import { useState } from 'react';
import Element from '../../../../../assets/Element';
import { ViewApprpvedFrom } from '../../ApprpvedFrom';

const ViewExpand = ({ files }: any) => {
  const [showOtherAttachments, setShowOtherAttachments] = useState(false);

  return (
    <label className='w-full flex items-center justify-between gap-2 p-2 px-4 pl-3 border-none text-[#4378c8] bg-slate-50 rounded-lg cursor-pointer'>
      <div className='w-full'>
        <div className='overflow-auto max-h-[6rem]'>
          <div className='overflow-auto max-h-[6rem] flex justify-between '>
            {(files?.length === 0 || files[0] === 'null') && <p className='text-black'>N/A</p>}
            {files
              ?.slice(0, 1)
              ?.filter((file: any) => file !== 'null')
              ?.map((file: any, index: number) => {
                const url = file;
                const name = url?.substring(95)?.split('.');
                return (
                  <div
                    key={name || ''}
                    className='flex items-center  gap-2 p-[0.2rem] px-4 pl-3 border-none text-[#515151] bg-slate-50 rounded-lg text-[0.8rem] font-bold '>
                    <a href={file} target='_blank' rel='noreferrer'>
                      <p className='truncate text-ellipsis max-w-[9rem] overflow-hidden'>
                        {!showOtherAttachments && <p>{name[0] + '.' + name[1]}</p>}
                      </p>
                    </a>
                  </div>
                );
              })}

            {files?.length > 1 && (
              <button className='text-blue-500 flex justify-end border-transparent text-[0.9rem] font-bold  items-center pr-1 z-500'>
                {!showOtherAttachments ? (
                  <>
                    <div onClick={() => setShowOtherAttachments(!showOtherAttachments)}>
                      + {files?.length - 1} More
                    </div>
                    <i
                      className='pi pi-angle-down text-[1rem] ml-1'
                      onClick={() => setShowOtherAttachments(!showOtherAttachments)}></i>
                  </>
                ) : (
                  <i
                    className='pi pi-angle-up text-[1rem] ml-1'
                    onClick={() => setShowOtherAttachments(!showOtherAttachments)}></i>
                )}
              </button>
            )}
          </div>
          <div className={`${showOtherAttachments && 'mt-[-1rem]'}`}>
            {showOtherAttachments &&
              files
                ?.slice(0)
                ?.filter((file: any) => file !== 'null')
                ?.map((file: any, index: number) => {
                  const url = file;
                  const name = url?.substring(95)?.split('.');
                  return (
                    <div
                      key={name || ''}
                      className='flex items-center  gap-2 p-[0.2rem] px-4 pl-3 border-none text-[#515151] bg-slate-50 rounded-lg text-[0.8rem] font-bold '>
                      <a href={file} target='_blank' rel='noreferrer'>
                        <p className='truncate text-ellipsis'>{name[0] + '.' + name[1]}</p>
                      </a>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    </label>
  );
};

function View({
  item,
  setEdit,
  isMoveToComplete,
}: {
  item: any;
  setEdit: (value: boolean) => void;
  isMoveToComplete?: any;
}) {
  const { invoice_number, amount, approved_from, attachment, invoice_attachment } =
    item?.lease_plan;
  const approvedFrom = approved_from;
  const isMoveToCompleted = isMoveToComplete || false;
  return (
    <>
      {amount && (
        <>
          <div
            className={`${'w-full'} p-2 flex flex-col items-center justify-between h-full gap-4`}>
            <p className='text-lg'>Lease Plan Update</p>
            {/* body1 */}
            <div className='w-full child:py-2'>
              <div className='flex justify-between'>
                <label className='text-md text-slate-500' htmlFor='text1'>
                  Approved From
                </label>
                {isMoveToCompleted && (
                  <div className=' flex items-center justidy-center gap-2 bg-[#EAF8E8] text-[#59AC61] p-1 px-3 rounded-xl'>
                    {' '}
                    <i className='pi pi-check-circle'></i> Updated
                  </div>
                )}
              </div>
              {approvedFrom === 'lease_plan' && (
                <ViewApprpvedFrom
                  text={<strong>LeasePlan</strong>}
                  image={
                    <div className='bg-[url("/public/images/LeasePlan.png")] h-[1rem] w-[2rem]  bg-no-repeat bg-cover' />
                  }
                />
              )}
              {approvedFrom === 'element' && <ViewApprpvedFrom svg={<Element />} checked={true} />}
              {!approvedFrom && 'N/A'}
            </div>

            <div className='w-full child:py-2'>
              <div>
                <label className='text-md text-slate-500' htmlFor='text1'>
                  Invoice Number
                </label>
                <p className='font-bold'>{invoice_number || 'N/A'}</p>
              </div>
              <div>
                <label className='text-md text-slate-500 '>Amount</label>
                <p className='font-bold'>{amount ? `$ ${amount}` : 'N/A'}</p>
              </div>
            </div>
            {/* body2 */}
            <div className='w-full flex flex-col gap-4'>
              <ViewExpand files={attachment} />
              <ViewExpand files={invoice_attachment} />
            </div>
            {/* body1 */}
            {/* <ActionAccess item={item}> */}
            <div className='w-full text-center'>
              <button
                disabled={!amount || amount <= 0}
                className='py-2 border-1 border-primary text-primary bg-white rounded-lg w-1/2 disabled:bg-slate-300 '
                onClick={() => {
                  setEdit(true);
                }}>
                Edit Details
              </button>
            </div>
            {/* </ActionAccess> */}
          </div>
        </>
      )}
    </>
  );
}

export default View;
