const SVG = (props: { height?: string; width?: string; active?: boolean }) => {
  const height = props?.height || '1rem';
  const width = props?.width || '1rem';
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        id='Vector'
        d='M7.5 0C3.375 0 0 3.375 0 7.5C0 11.625 3.375 15 7.5 15C11.625 15 15 11.625 15 7.5C15 3.375 11.625 0 7.5 0ZM10.65 6.225L7.05 9.825C6.75 10.125 6.3 10.125 6 9.825L4.35 8.175C4.05 7.875 4.05 7.425 4.35 7.125C4.65 6.825 5.1 6.825 5.4 7.125L6.525 8.25L9.6 5.175C9.9 4.875 10.35 4.875 10.65 5.175C10.95 5.475 10.95 5.925 10.65 6.225Z'
        fill='#78AB67'
      />
    </svg>
  );
};
export default SVG;
