import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import Tootltip from '../../../../../components/BarData/Tootltip';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from '../../../../../state/reducers/index';
import { toast } from '../../../../../components/Toast/CustomToast';
import { actionCreators } from '../../../../../state/index';
import emailAndPassWordRejex from '../../../../common/requestDetail/EmailandPassWordRules';
import EditIcon from './EditIcon';
interface AddressProps {
  item?: any;
  address: {
    phoneNumber: string;
    email: string;
    address: string;
  };
  setAddress: React.Dispatch<React.SetStateAction<any>>;
  showVendor?: boolean;
  invoiceDetails?: any;
}

interface Changes {
  edit?: boolean;
  phoneNumber: string;
  email: string;
  address: string;
}

const Address: React.FC<{ props: AddressProps }> = ({ props }) => {
  const dispatch = useDispatch();
  const { emailRules } = emailAndPassWordRejex();
  const { userInfoInvoice: userValue } = useSelector((state: RootState) => state.userState);
  const { setuserInfoInvoice: setUserValue } = bindActionCreators(actionCreators, dispatch);
  const { item, address, setAddress, invoiceDetails, showVendor } = props;
  const [changes, setChanges] = useState<Changes>({
    edit: false,
    phoneNumber: '',
    email: '',
    address: '',
  });
  const [warning, setWarning] = useState({ email: false });
  const defaultDetails = {
    phoneNumber: '425-299-2247',
    email: 'fleet@lmdmax.com',
    address: 'Americas Repair Solutions 7 PATRIOT HILL DR - BASKING RIDGE, NJ 07920',
  };
  const isUserValuePresentVendor: boolean =
    userValue?.vendor?.address?.length > 0 ||
    userValue?.vendor?.email?.length > 0 ||
    userValue?.vendor?.phoneNumber?.length > 0;
  const isUserValuePresentDsp: boolean =
    userValue?.dsp?.address?.length > 0 ||
    userValue?.dsp?.email?.length > 0 ||
    userValue?.dsp?.phoneNumber?.length > 0;
  useEffect(() => {
    const details = {
      phoneNumber: invoiceDetails?.invoice?.phone || defaultDetails?.phoneNumber || '',
      email: invoiceDetails?.invoice?.email || defaultDetails?.email || '',
      address: invoiceDetails?.invoice?.address || defaultDetails?.address || '',
    };

    if (showVendor) {
      if (isUserValuePresentVendor) {
        setAddress(userValue?.vendor);
      } else {
        if (item?.vendor_invoice_status) setAddress({ ...details });
        else setAddress({ ...defaultDetails });
      }
    }
    if (!showVendor) {
      if (isUserValuePresentDsp) {
        setAddress(userValue?.dsp);
      } else {
        if (item?.dsp_invoice_status) setAddress({ ...details });
        else setAddress({ ...defaultDetails });
      }
    }
  }, [invoiceDetails, item, showVendor]);
  const handleInputChange =
    (field: keyof Changes) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const value = e.target.value;
      let message = '';

      if (field === 'address' && value.length > 150)
        message = 'Address should not be more than 150 characters';
      if (field === 'phoneNumber' && value.length > 15)
        message = 'Phone Number should not be more than 15 characters';
      else if (field === 'email') {
        if (value.length > 30) {
          message = 'Email should not be more than 30 characters';
        }
        if (!emailRules.test(value)) {
          setWarning({ ...warning, email: true });
        } else {
          setWarning({ ...warning, email: false });
        }
      }
      if (message) {
        toast.info(message);
      }
      if (field === 'phoneNumber' && /^[0-9-\s()+]*$/.test(value)) {
        setChanges({ ...changes, [field]: value });
      }
      if (field !== 'phoneNumber') {
        setChanges({ ...changes, [field]: value });
      }
    };

  const handleEditToggle = () => {
    setChanges({
      edit: true,
      phoneNumber: address.phoneNumber || '425-299-2247',
      email: address.email || 'fleet@lmdmax.com',
      address:
        address.address || 'Americas Repair Solutions 7 PATRIOT HILL DR - BASKING RIDGE, NJ 07920',
    });
  };

  const handleSave = () => {
    if (changes?.address?.length === 0) {
      toast.info('Address is necessary');
      return;
    }
    if (changes?.email?.length === 0) {
      toast.info('Email is necessary');
      return;
    }
    if (changes?.phoneNumber?.length === 0) {
      toast.info('PhoneNumber is necessary');
      return;
    }
    setAddress(changes);

    setChanges({ ...changes, edit: false });
    if (showVendor) {
      setUserValue({ ...userValue, vendor: changes });
    } else {
      setUserValue({ ...userValue, dsp: changes });
    }
  };
  const handleCancel = () => {
    setChanges({ ...changes, edit: false });
  };
  useEffect(() => {
    if (changes?.edit) {
      if (!emailRules.test(changes?.email)) {
        setWarning({ ...warning, email: true });
      }
    }
  }, [changes?.edit]);
  const disabled: boolean =
    changes.email.length > 30 ||
    changes.email.length! === 0 ||
    changes.phoneNumber.length > 15 ||
    changes.phoneNumber.length! == 0 ||
    changes.address.length > 150 ||
    changes.address.length! === 0 ||
    warning?.email;
  const getHeight = (feild: string) => {
    if (feild === 'address') {
      return 'h-[5rem]';
    } else return 'h-[2rem]';
  };
  return (
    <div className='flex text-[0.9rem] flex-col gap-1 border-[0.1rem] border-gray-300 text-gray-500 rounded-[0.5rem] w-[20rem] overflow-auto'>
      {changes.edit ? (
        <div className='flex flex-col gap-2 p-3 w-full'>
          {['address', 'phoneNumber', 'email'].map((field: any) => (
            <div key={field || ''} className='flex flex-col gap-2'>
              <p className='font-bold'>{placeholderText(field)}</p>
              <div className='relative w-full border-[0.1rem] border-gray-300 rounded-[0.5rem] p-1'>
                <textarea
                  className={`resize-none w-full ${getHeight(
                    field
                  )} rounded-[0.5rem] px-1 order transition-colors duration-300 outline-none focus:border-blue-500 border-gray-300`}
                  value={String(changes[field as keyof Changes])}
                  onChange={handleInputChange(field as keyof Changes)}
                  placeholder={`Enter ${placeholderText(field)}`}
                  rows={field === 'address' ? 5 : 3}
                />
              </div>
              {field === 'email' && warning?.email && (
                <p className='text-[0.8rem] text-[#C55]'>Please enter a valid Email</p>
              )}
            </div>
          ))}
          <div className='flex justify-between'>
            <Button className='text-[#C55] font-bold' onClick={handleCancel}>
              Cancel
            </Button>
            <Button className='font-bold' disabled={disabled} onClick={handleSave}>
              Save
            </Button>
          </div>
        </div>
      ) : (
        <>
          <p
            className='flex text-[0.9rem] border-b-[0.1rem] border-gray-300 text-gray-500 justify-between items-center p-1 px-2'
            style={{ fontWeight: 'bold' }}>
            BILL TO
            <div
              className='rounded-full flex justify-center items-center p-1 hover:bg-primary-light cursor-pointer'
              onClick={handleEditToggle}>
              <EditIcon />
            </div>
          </p>
          <div className='p-2'>
            {['address', 'phoneNumber', 'email'].map((field) => (
              <Tootltip
                key={field || ''}
                title={address[field as keyof typeof address]}
                placement='bottom'>
                <p className='text-[0.9rem] text-ellipsis overflow-hidden max-w-[18rem] text-black'>
                  {field !== 'address' && placeholderText(field)}
                  {field !== 'address' && ': '}
                  {address[field as keyof typeof address]}
                </p>
              </Tootltip>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Address;

function placeholderText(text: string) {
  switch (text) {
    case 'address':
      return 'Address ';
    case 'phoneNumber':
      return 'Phone no. ';
    case 'email':
      return 'Email ';
    default:
      return '';
  }
}
