import { Button, Popover } from '@mui/material';
import { useParentDimensions, usePopover } from './ExpandComps';
let parentWidth = 0;
let parentHeight = 0;

export default function StatusTrackerPopButton({
  item,
  icon,
  showOnExpand,
  title,
  variant,
  noPopUp,
}: {
  item: any;
  noPopUp?: boolean;
  variant?: string;
  icon?: JSX.Element;
  title: string;
  showOnExpand: JSX.Element;
}) {
  const parentRef = useParentDimensions(parentWidth, parentHeight);
  const { anchorEl, handleOpenPopup, handleClosePopup } = usePopover();
  const expandDown = variant === 'expandDown';
  const upDirection = expandDown ? !anchorEl : anchorEl;

  if (!item) return null;

  return (
    <div className='w-full'>
      <Button
        className={`bg-primary-light text-primary relative w-full  flex justify-between px-4 font-bold p-1 py-3 ${
          anchorEl && (upDirection ? 'rounded-t-none' : 'rounded-b-none')
        } h-[2.6rem]`}
        onClick={handleOpenPopup}
        ref={parentRef}>
        <div className='flex gap-2 items-center'>
          {icon}
          {title}
        </div>
        <i
          className={`pi pi-angle-down ${
            upDirection ? 'rotate-0' : 'rotate-180'
          } ease-in-out duration-200 text-grey text-primary`}></i>
      </Button>
      {noPopUp ? (
        anchorEl && (
          <div
            style={{
              width: parentRef.current?.clientWidth,
              boxShadow: 'none',
              borderRadius: 0,
              ...(expandDown
                ? {
                    borderBottomLeftRadius: '0.5rem',
                    borderBottomRightRadius: '0.5rem',
                  }
                : {
                    borderTopLeftRadius: '0.5rem',
                    borderTopRightRadius: '0.5rem',
                  }),
            }}>
            <div
              className='relative  w-full bg-primary-light p-1'
              style={{
                ...(expandDown
                  ? {
                      borderBottomLeftRadius: '0.5rem',
                      borderBottomRightRadius: '0.5rem',
                    }
                  : { borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem' }),
              }}>
              {showOnExpand}
            </div>
          </div>
        )
      ) : (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClosePopup}
          PaperProps={{
            sx: {
              width: parentRef.current?.clientWidth,
              boxShadow: 'none',
              borderRadius: 0,
              ...(expandDown
                ? {
                    borderBottomLeftRadius: '0.5rem',
                    borderBottomRightRadius: '0.5rem',
                  }
                : {
                    borderTopLeftRadius: '0.5rem',
                    borderTopRightRadius: '0.5rem',
                  }),
            },
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
            ...(expandDown && {
              vertical: 'bottom',
              horizontal: 'center',
            }),
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            ...(expandDown && {
              vertical: 'top',
              horizontal: 'center',
            }),
          }}>
          <div
            className='relative  w-full bg-primary-light p-1'
            style={{
              ...(expandDown
                ? {
                    borderBottomLeftRadius: '0.5rem',
                    borderBottomRightRadius: '0.5rem',
                  }
                : { borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem' }),
            }}>
            {showOnExpand}
          </div>
        </Popover>
      )}
    </div>
  );
}
