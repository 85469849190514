const GoBackButton = (props: { height?: string, width?: string, active?: boolean }) => {
    const height = props?.height || "1rem"
    const width = props?.width || "1rem"
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 8 15" fill="none">
  <path d="M7.64465 2.64541L2.9309 7.50626L7.64465 12.3671C8.11845 12.8557 8.11845 13.645 7.64465 14.1336C7.17084 14.6221 6.40547 14.6221 5.93166 14.1336L0.355353 8.38322C-0.118451 7.89463 -0.118451 7.10537 0.355353 6.61678L5.93166 0.866443C6.40547 0.377852 7.17084 0.377852 7.64465 0.866443C8.1063 1.35503 8.11845 2.15682 7.64465 2.64541Z" fill="#4378C8"/>
</svg>

    );
}
export default GoBackButton