import { Divider } from '@mui/material';
import { useEffect } from 'react';
import ModalForm from '../../../../components/ModalForm';
import OverlayContainer from '../../../../components/OverlayContainer';
import useFetchCheckList from '../../../hooks/useFetchCheckList';
import { GetMilesTillInspection } from '../../../common/GetMilesTillInspection';

const ViewChecklist = ({ handleClose, item }: any) => {
  const { checkList, getCheckList } = useFetchCheckList();
  useEffect(() => {
    if (item) {
      getCheckList(item);
    }
  }, [item]);

  return (
    <OverlayContainer show={true}>
      <ModalForm title={'Add CSR'} onClose={handleClose} width={'w-[60rem]'} noLabel>
        <div className='flex flex-col gap-4 h-[32rem] p-4'>
          <div className='flex flex-col gap-4 w-[30rem]'>
            <div className='flex gap-4'>
              <div className='flex flex-col flex-1'>
                <p className='text-lg font-bold'>Services</p>
                <p className='text-sm'>{item.vehicle.name}</p>
              </div>
              <div className='flex flex-col flex-1'>
                <p className='text-sm text-slate-400'>VIN Number</p>
                <p className='text-lg'>{item.vehicle.vin}</p>
              </div>
              <div className='flex flex-col flex-1'>
                <p className='text-sm text-slate-400'>Plate Number</p>
                <p className='text-lg'>{item.vehicle.plate}</p>
              </div>
            </div>
            <div className='flex gap-4 bg-blue-100 px-4 py-2 rounded-lg items-center'>
              <div className='flex flex-col flex-1'>
                <div>
                  <p className='text-sm text-slate-400'>Last PM Miles</p>
                  <p className='text-[#777676]'>{item.miles || '---'}</p>
                </div>
              </div>
              <Divider orientation='vertical' sx={{ borderWidth: '1px', height: '2rem' }} />
              <div className='flex flex-col flex-1'>
                <p className='text-sm text-slate-400'>Current Mileage</p>
                <p className='text-[#777676]'>{item.curr_miles || '---'}</p>
              </div>
              <Divider orientation='vertical' sx={{ borderWidth: '1px', height: '2rem' }} />
              <div className='flex flex-col flex-1'>
                <p className='text-sm text-slate-400'>Miles Till Inspection</p>
                {item.curr_miles && item.miles ? GetMilesTillInspection(item) : '---'}
              </div>
            </div>
          </div>
          <div className='grid grid-cols-2 gap-y-5 gap-x-32 overflow-y-auto'>
            {checkList.length > 0 &&
              checkList.map((item: any) => (
                <div
                  className='px-2 pt-1 border border-solid border-slate-200 flex items-center rounded-lg cursor-pointer'
                  key={item.id}>
                  <p className='p-2'>{item?.name || 'N/A'}</p>
                </div>
              ))}
          </div>
        </div>
      </ModalForm>
    </OverlayContainer>
  );
};

export default ViewChecklist;
