import { useEffect, useRef } from 'react';
import ExpandPopUp from './requestDetail/ExpandPopup';
import ServiceListIcon from '../../assets/ServiceListIcon';
let parentWidth = 0;
let parentHeight = 0;
export default function NotesPopup({ item, variant }: { item: any; variant?: string }) {
  const parentRef: any = useRef(null);
  const { checkList } = item;
  useEffect(() => {
    if (parentRef.current) {
      parentWidth = parentRef.current.clientWidth;
      parentHeight = parentRef.current.clientHeight;
    }
  }, [parentRef]);

  if (!item) return null;

  return (
    <div className='w-full'>
      <ExpandPopUp
        icon={
          <i>
            <ServiceListIcon />
          </i>
        }
        showOnExpand={
          <div
            className='container overflow-auto  mb-0.9 p-2 h-[10rem]'
            style={{
              backgroundColor: 'white',
              borderRadius: '0.4rem',
            }}>
            {checkList && checkList.length == 0 && <p>No Services available</p>}
            {checkList &&
              checkList.map((item: any, index: number) => (
                <div
                  className='w-full border-2 mb-1.5 p-2 br-1 rounded-[0.5rem] capitalize '
                  key={item?.name}>
                  {item?.name || 'N/A'}
                </div>
              ))}
          </div>
        }
        title='Service List'
        item={item}
        variant={variant}
      />
    </div>
  );
}
