import { Button } from '@mui/material';
import { BottomActionsProps } from './Types';

export const BottomActions = (props: BottomActionsProps) => {
  if (props?.type === 'remove') {
    return (
      <Button
        variant='text'
        className='font-bold'
        disabled={props?.disabled}
        onClick={props?.onClick}>
        Remove All
      </Button>
    );
  } else {
    return (
      <Button variant='contained' disabled={props?.disabled} onClick={props?.onClick}>
        Save
      </Button>
    );
  }
};
