export default function (props: {
  height?: string;
  width?: string;
  active?: boolean;
  fill?: string;
}) {
  const height = props?.height || '1.8rem';
  const width = props?.width || '1.8rem';
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <mask maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'>
        <rect width='24' height='24' fill={props.fill} />
      </mask>
      <g mask='url(#mask0_18792_26687)'>
        <path
          d='M18 23C16.6167 23 15.4375 22.5125 14.4625 21.5375C13.4875 20.5625 13 19.3833 13 18C13 16.6167 13.4875 15.4375 14.4625 14.4625C15.4375 13.4875 16.6167 13 18 13C19.3833 13 20.5625 13.4875 21.5375 14.4625C22.5125 15.4375 23 16.6167 23 18C23 19.3833 22.5125 20.5625 21.5375 21.5375C20.5625 22.5125 19.3833 23 18 23ZM7 9H17V7H7V9ZM11.675 21H5C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V11.7C20.5167 11.4667 20.0292 11.2917 19.5375 11.175C19.0458 11.0583 18.5333 11 18 11C17.8167 11 17.6458 11.0042 17.4875 11.0125C17.3292 11.0208 17.1667 11.0417 17 11.075V11H7V13H13.125C12.825 13.2833 12.5542 13.5917 12.3125 13.925C12.0708 14.2583 11.8583 14.6167 11.675 15H7V17H11.075C11.0417 17.1667 11.0208 17.3292 11.0125 17.4875C11.0042 17.6458 11 17.8167 11 18C11 18.55 11.05 19.0625 11.15 19.5375C11.25 20.0125 11.425 20.5 11.675 21ZM18 18C18.4167 18 18.7708 17.8542 19.0625 17.5625C19.3542 17.2708 19.5 16.9167 19.5 16.5C19.5 16.0833 19.3542 15.7292 19.0625 15.4375C18.7708 15.1458 18.4167 15 18 15C17.5833 15 17.2292 15.1458 16.9375 15.4375C16.6458 15.7292 16.5 16.0833 16.5 16.5C16.5 16.9167 16.6458 17.2708 16.9375 17.5625C17.2292 17.8542 17.5833 18 18 18ZM18 21C18.5 21 18.9667 20.8833 19.4 20.65C19.8333 20.4167 20.1917 20.0917 20.475 19.675C20.0917 19.4417 19.6917 19.2708 19.275 19.1625C18.8583 19.0542 18.4333 19 18 19C17.5667 19 17.1417 19.0542 16.725 19.1625C16.3083 19.2708 15.9083 19.4417 15.525 19.675C15.8083 20.0917 16.1667 20.4167 16.6 20.65C17.0333 20.8833 17.5 21 18 21Z'
          fill={props.fill}
        />
      </g>
    </svg>
  );
}
