import React from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import CustomTooltip from '../components/CustomTooltip';

const CustomTick = ({ x, y, payload }: any) => {
  const [chngedDate, yearSuffix] = payload.value.split(',');
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor='middle' fill='#929292' fontSize='11'>
        <tspan x='0' dy='1.5em' fontSize='10'>
          {yearSuffix}
        </tspan>{' '}
        <tspan x='0' dy='1.2em' fontSize='11'>
          {chngedDate}
        </tspan>
      </text>
    </g>
  );
};

interface RevenueTrendProps {
  data: { period: string; revenue: number }[];
  targetWeek: string;
  targetValue: number;
}

const RevenueTrend: React.FC<RevenueTrendProps> = ({ data }) => {
  return (
    <div className='' style={{ width: '58rem', height: '16.5rem' }}>
      <ResponsiveContainer width='100%' height='100%'>
        <AreaChart data={data} margin={{ top: 10, right: 15, bottom: 30, left: 8 }}>
          <defs>
            <linearGradient id='colorRevenue' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='0%' stopColor='rgba(0, 201, 0, 0.47)' />
              <stop offset='100%' stopColor='#FFFFFF' />
            </linearGradient>
          </defs>
          <XAxis
            dataKey='period'
            tickLine={false}
            interval={0}
            textAnchor='end'
            tick={CustomTick}
          />
          <YAxis
            tick={{ fill: 'rgba(148, 146, 148, 1)', fontSize: '0.9rem' }}
            tickLine={false}
            domain={[0, 'auto']}
            tickCount={6}
            interval={0}
          />
          <Tooltip content={<CustomTooltip />} />
          <Area
            type='monotone'
            dataKey='revenue'
            stroke='#00c900'
            fillOpacity={1}
            fill='url(#colorRevenue)'
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RevenueTrend;
