import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  TooltipProps,
} from 'recharts';
export interface PerfomanceGraphData {
  period: string;
  completed_count: number;
  incomplete_count: number;
  completion_rate: number;
}
export interface PerfomanceProps {
  data: PerfomanceGraphData[];
}
export const PerfomanceGraph: React.FC<PerfomanceProps> = ({ data }) => {
  const maxIncompleteCount = Math.max(...data.map((item) => item?.completed_count));
  const tickCount = maxIncompleteCount < 3 ? 3 : 6;
  return (
    <div style={{ width: '35rem', height: '20rem' }}>
      <ResponsiveContainer width='100%' height='100%'>
        <AreaChart data={data} margin={{ top: 10, right: 15, bottom: 30, left: 8 }}>
          <defs>
            <linearGradient id='colorBlue' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='0%' stopColor='#6CB4EE' />
              <stop offset='100%' stopColor='#FFFFFF' />
            </linearGradient>
          </defs>

          <CartesianGrid strokeDasharray='0 0' stroke='#E8E8E8' vertical={false} fontSize={10} />

          <XAxis
            dataKey='period'
            tickLine={false}
            interval={0}
            textAnchor='end'
            tick={CustomTick}
          />
          <YAxis
            tick={{ fill: 'rgba(148, 146, 148, 1)', fontSize: '0.9rem' }}
            tickLine={false}
            domain={[0, 'auto']}
            tickCount={tickCount}
            interval={0}
          />
          <Tooltip content={<CustomTooltip />} />

          <Area
            dot={{ fill: '#9DBBE8', strokeWidth: 2 }}
            type='monotone'
            dataKey='completed_count'
            stroke='#D6E6FF'
            fillOpacity={0.5}
            strokeWidth={2}
            fill='url(#colorBlue)'
            gradientUnits='1.5em'
            gradientTransform='#ccc'
          />
          <Area
            dot={{ fill: '#9DBBE8', strokeWidth: 2 }}
            type='monotone'
            dataKey='incomplete_count'
            stroke='#9DBBE8'
            strokeWidth={4}
            fill='url(#colorRevenue)'
            fillOpacity={0}
            gradientUnits='1.5em'
            gradientTransform='#ccc'
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
const CustomTooltip: React.FC<TooltipProps<any, any>> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const completed_count = payload[0].payload.completed_count || 0;
    const incomplete_count = payload[0].payload.incomplete_count || 0;
    const completion_rate = payload[0].payload.completion_rate;

    return (
      <div
        className='border-[0.01rem] border-gray-200 p-3 rounded-[0.7rem] shadow-md w-[11rem] text-[0.8rem] flex flex-col gap-2'
        style={{
          backgroundColor: 'white',
          color: 'black',
        }}>
        <p className='flex justify-between'>
          <span className='flex gap-2 items-center'>
            <span className='bg-primary-light w-[0.8rem] h-[0.5rem] rounded-[0.5rem]'></span>
            Complete
          </span>
          <span className='font-bold text-gray-500'>{completed_count}</span>
        </p>
        <p className='flex justify-between'>
          <span className='flex gap-2 items-center'>
            <span className='bg-[#6CB4EE] w-[0.8rem] h-[0.5rem] rounded-[0.5rem]'></span>
            Incomplete
          </span>
          <span className='font-bold text-gray-500'>{incomplete_count}</span>
        </p>
        <p className='flex justify-center bg-primary-light text-primary px-1 py-0.5 rounded-[0.2rem]'>
          Completion Rate
          <span className='font-bold ml-2'>{completion_rate}%</span>
        </p>
      </div>
    );
  }

  return null;
};
const CustomTick = ({ x, y, payload }: any) => {
  const [chngedDate, yearSuffix] = payload.value.split(',');
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor='middle' fill='#929292' fontSize='11'>
        <tspan x='0' dy='1.5em' fontSize='10'>
          {yearSuffix}
        </tspan>
        <tspan x='0' dy='1.2em' fontSize='11'>
          {chngedDate}
        </tspan>
      </text>
    </g>
  );
};
