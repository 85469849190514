import axios from 'axios';
import { Environment } from '../constants/environment';
import { UserModel } from '../models/UserModel';

import { toast } from 'react-toastify';
import { store } from '../state/store';

const moment = require('moment-timezone');

export const baseURL = Environment.baseUrl;

export const client = axios.create({
  baseURL,
});

export const request = async ({ ...options }) => {
  let user: UserModel | null = null;
  let partnerasVendor: UserModel | null = null;

  const getItem: string | null = localStorage.getItem('user');
  const getPartner: string | null = localStorage.getItem('partner');

  if (getItem) user = JSON.parse(getItem);
  if (getPartner) partnerasVendor = JSON.parse(getPartner);

  client.defaults.headers.common['app-type'] = '9';
  client.defaults.headers.common['x-access-token'] = user?.token || '';
  client.defaults.headers.common['x-access-user'] = user?.account_id || '';
  client.defaults.headers.common['client-time-zone'] = moment.tz.guess();
  if (partnerasVendor?.id) {
    client.defaults.headers.common['accessing-partner-id'] = partnerasVendor?.id;
  }

  const onSuccess = (response: any) => {
    if (response.headers['x-refresh-token']) {
      store.dispatch({
        type: 'SET_REFRESH_TOKEN',
        payload: { token: response.headers['x-refresh-token'] },
      });
    }
    return response;
  };
  const onError = (error: any) => {
    if (
      error?.response?.status === 401 &&
      error?.response?.data?.message.includes('Failed to authenticate token at Lv1')
    ) {
      toast.error('Your session has been expired, please log in again', {
        toastId: 'error',
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        onOpen: () => {
          setTimeout(() => {
            localStorage.clear();
            window.location.reload();
          }, 1000);
        },
      });
    } else {
      toast.error(error?.response?.data?.message || 'Something went wrong!', {
        toastId: 'error',
        pauseOnFocusLoss: false,
        pauseOnHover: false,
      });    }
    return error;
  };

  try {
    const response = await client(options);
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};
