import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import OverlayContainer from '../../../../../components/OverlayContainer';
import ModalForm from '../../../../../components/ModalForm';
import dayjs from 'dayjs';
import { Button } from '@mui/material';
export function showWarning(selected: any, type: string) {
  const isGreaterThan =
    selected?.length > 12 ? (
      <>
        {' '}
        <i className='pi pi-info-circle text-[#C55] font-bold text-[1rem] pr-[0.5rem] '></i>
        Minimum 2 {type} Select And Maximum 12 {type} Select
      </>
    ) : (
      ''
    );
  return (
    <div className='text-[#C55] text-[1rem] font-bold flex items-center w-full flex justify-end px-2 rounded-full bg-[#CC555521]'>
      {selected?.length < 2 ? (
        <>
          {' '}
          <i className='pi pi-info-circle text-[#C55] font-bold text-[1rem] pr-[0.5rem] '></i>
          Minimum 2 {type} Select And Maximum 12 {type} Select
        </>
      ) : (
        isGreaterThan
      )}
    </div>
  );
}
function AddCustom({ handleClose, newSearchParams }: any) {
  const [_, setSearchParams] = useSearchParams();
  const [selectedMonths, setSelectedMonths] = useState<string[]>([]);
  const [selectedYear, setSelectedYear] = useState(dayjs().year());
  const [allMonthsDisabled, setAllMonthsDisabled] = useState(false);

  const currentMonth = dayjs().month(); // Get the current month
  const currentYear = dayjs().year(); // Get the current year

  useEffect(() => {
    checkIfAllMonthsDisabled(selectedYear);
  }, [selectedYear]);

  const months = Array.from({ length: 12 }, (_, i) => {
    const isDisabled =
      (selectedYear === currentYear && i > currentMonth) || selectedYear > currentYear;
    return {
      value: dayjs().year(selectedYear).month(i).format('YYYY-MM'),
      name: dayjs().month(i).format('MMMM'),
      disabled: isDisabled,
    };
  });

  const checkIfAllMonthsDisabled = (year: number) => {
    const allDisabled = months.every((month) => month.disabled);
    setAllMonthsDisabled(allDisabled);
  };

  const handleMonthToggle = (month: string, disabled: boolean) => {
    if (disabled) return; // Prevent toggling if the month is disabled
    setSelectedMonths((prev) =>
      prev.includes(month) ? prev.filter((m) => m !== month) : [...prev, month]
    );
  };

  const handleOkClick = () => {
    newSearchParams.delete('weeks');
    newSearchParams.delete('isWeeksCustom');
    newSearchParams.delete('isWeek');
    newSearchParams.set('months', JSON.stringify(selectedMonths));
    newSearchParams.set('isMonthsCustom', 'true');
    setSearchParams(newSearchParams);
    handleClose();
  };

  const handlePrevYear = () => {
    setSelectedYear((prev) => prev - 1);
  };

  const handleNextYear = () => {
    if (!allMonthsDisabled && selectedYear < currentYear) {
      setSelectedYear((prev) => prev + 1);
    }
  };

  return (
    <OverlayContainer show={true}>
      <ModalForm title={'Add CSR'} onClose={handleClose} width={'w-[49vw] '} noLabel>
        <div className='flex flex-col gap-4 '>
          <div className='mb-4 flex justify-between gap-1 items-center w-full'>
            <div className='flex justify-start gap-1 items-center'>
              <Button className='text-[2rem]' onClick={handlePrevYear}>
                <i className='pi pi-chevron-left'></i>
              </Button>
              <span className='mx-4 text-xl'>{selectedYear}</span>
              <Button
                className={`text-[2rem] ${
                  allMonthsDisabled || selectedYear >= currentYear + 1
                    ? 'cursor-not-allowed opacity-50'
                    : ''
                }`}
                onClick={handleNextYear}
                disabled={allMonthsDisabled || selectedYear >= currentYear}>
                <i className='pi pi-chevron-right'></i>
              </Button>
              <div />
              <div className='w-fit mr-3'>{showWarning(selectedMonths, 'Months')}</div>
            </div>
          </div>

          <div className='grid grid-cols-3 gap-4'>
            {months.map((month) => (
              <div
                key={month.value}
                className={`p-2 border rounded cursor-pointer flex items-center justify-center hover:bg-primary-light hover:text-primary rounded-[0.5rem] ${
                  selectedMonths.includes(month.value) ? 'bg-blue-500 text-white' : 'bg-white'
                } ${month.disabled ? 'cursor-not-allowed opacity-50' : ''}`}
                onClick={() => handleMonthToggle(month.value, month.disabled)}>
                {month.name}
              </div>
            ))}
          </div>

          <div className='flex justify-end gap-3 mt-4'>
            <Button className='text-[#C55] px-4 py-2 rounded mr-2' onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant='contained'
              disabled={selectedMonths?.length < 2 || selectedMonths?.length > 12}
              onClick={handleOkClick}>
              Save
            </Button>
          </div>
        </div>
      </ModalForm>
    </OverlayContainer>
  );
}

export default AddCustom;
