import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { Suspense, lazy, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import io from 'socket.io-client';
import './App.css';
import RoleAcess, { getMainRoute } from './RoleAccess';
import Analytics from './analytics/Analytics';
import InputEventListener from './components/GlobalFix/InputEventListener';
import SimpleBackdrop from './components/Loader/backdropLoad';
import { toast } from './components/Toast/CustomToast';
import LayoutAuth from './components/layout/layout-auth/LayoutAuth';
import { Environment } from './constants/environment';
import Drawer from './drawer/Index';
import ForgotPassword from './pages/auth/forgot-password/ForgotPassword';
import ResetPassword from './pages/auth/reset-password/ResetPassword';
import Signin from './pages/auth/signin/Signin';
import Signup from './pages/auth/signup/Signup';
import useFetchHeads from './pages/csr/chat/components/useFetchHeads';
import ServiceList from './pages/csr/primaryComponents/servicelist/Index';
import InvoiceTable from './pages/partner/primaryComponents/InvoiceTable/Index';
import VendorDashboard from './pages/common/dashboard/requests';
import useFetchAlerts from './pages/common/dashboard/requests/alerts/useFetchAlerts';
import Revenue from './pages/common/dashboard/revenue';
import { RootState } from './state/reducers';
import './theme.css';
import useInactivityTimer from './pages/hooks/useInactivityTimer';
const BulkAddService = lazy(
  () => import('./pages/csr/primaryComponents/dspRequest/BulkAddService')
);
const ProfileCsr = lazy(() => import('./pages/csr/primaryComponents/profile'));
const CancelRequests = lazy(
  () => import('./pages/csr/primaryComponents/profile/components/CancelRequests')
);
const CSR = lazy(() => import('./pages/supervisor/CSR'));
const Vendor = lazy(() => import('./pages/supervisor/Vendor'));
const RequestReport = lazy(() => import('./pages/supervisor/Report/RequestReport'));
const Partner = lazy(() => import('./pages/supervisor/Partner'));
const AddVendor = lazy(() => import('./pages/supervisor/Vendor/AddVendor'));
const Technician = lazy(() => import('./pages/technician'));
const CompareReport = lazy(() => import('./pages/supervisor/CompareReport/Index'));
const Profile = lazy(() => import('./pages/vender/profile'));
const Vendor_ = lazy(() => import('./pages/vender'));
const AlertsAndNotifications = lazy(
  () => import('./pages/vender/profile/notifications/AlertsAndNotifications')
);
const LayoutCsr = lazy(() => import('./components/layout/layout-csr/LayoutCsr'));
const LayoutVendor = lazy(() => import('./components/layout/layout-vendor/LayoutVendor'));
const LayoutPartner = lazy(() => import('./components/layout/layout-partner/LayoutPartner'));
const LayoutWeb = lazy(() => import('./components/layout/layout-web/LayoutWeb'));
const Csr = lazy(() => import('./pages/csr'));
const PartnerPage = lazy(() => import('./pages/partner'));
const ScheduleRequest = lazy(() => import('./pages/csr/scheduleRequest'));
const ChatCSR = lazy(() => import('./pages/csr/chat'));
const MoveToComplete = lazy(() => import('./pages/csr/moveToComplete/Index'));
const InspectionQuestionnaire = lazy(
  () => import('./pages/csr/inspection/inspectionQuestionnaire')
);
const Inspect = lazy(() => import('./pages/csr/inspection/vehicleInspection'));
const InspectionPage = lazy(
  () => import('./pages/csr/inspection/vehicleInspection/inspectionPage/Index')
);
const AssignedDSPs = lazy(
  () => import('./pages/csr/primaryComponents/profile/components/AssignedDSPs')
);

const socket = io(Environment.messagingUrl, {
  forceNew: true,
  transports: ['websocket'],
  reconnection: true,
});

const socketFleetMax = io(Environment.baseUrl, {
  path: '/socket-fleetmax',
  forceNew: true,
  transports: ['websocket'],
  autoConnect: true,
});

const setupConnection = () => {
  let user: any | null = null;
  const getItem: string | null = localStorage.getItem('user');
  if (getItem) user = JSON.parse(getItem);
  const account_id = user?.account_id;
  const token = user?.token;

  socket.auth = { account_id, token };
};
function App() {
  const { fetchMessages, fetchHeads, fetchChatProfile, setSocketInstance } = useFetchHeads();
  const { user, loading }: any = useSelector((state: RootState) => state.userState);
  const isSingleSchedule = window.location.href.includes('singleSchedule');
  const isBulkSchedule = window.location.href.includes('scheduleRequest');
  const isMoveToComplete = window.location.href.includes('moveToComplete');
  const isVendorSchedule = window.location.href.includes('vendorSchedule');
  const { refetchAlert, offset } = useFetchAlerts();

  useInactivityTimer(() => {
    window.location.reload();
  }, 8 * 60 * 60 * 1000);

  useEffect(() => {
    if (user && user?.role !== 'fleetmax_supervisor') {
      setupConnection();
      socket.connect();
      socket.on('connect', () => {
        socket.emit('joinRoom');
      });
      socket.on('message', (message) => {
        if (window.location.pathname.includes('chat')) {
          fetchHeads();
          fetchMessages();
          if (message?.attachments) {
            fetchChatProfile();
          }
        } else {
          fetchHeads(undefined, false, true);
        }
      });
      socket.on('authentication_error', (error) => {
        console.error('Authentication error:', error);
      });
      socket.on('userAdded', (message) => {
        if (!window.location.pathname.includes('chat')) {
          toast.info('Added to a chat');
        }
      });
      socket.on('newAlert', (message) => {
        if (
          window.location.pathname.includes('vendor/dashboard') ||
          window.location.pathname.includes('partner/dashboard')
        ) {
          refetchAlert(offset);
        }
      });
      if (!window.location.pathname.includes('chat')) {
        // not in chat page
        fetchHeads('all');
      }
    }
  }, []);

  useEffect(() => {
    if (socketFleetMax) {
      setSocketInstance(socketFleetMax);
    }
  }, [socketFleetMax]);
  const notSupervisor = user?.role !== 'fleetmax_supervisor';

  const noDrawer =
    user &&
    notSupervisor &&
    !isBulkSchedule &&
    !isSingleSchedule &&
    !isVendorSchedule &&
    !isMoveToComplete;
  return (
    <Analytics>
      <div className='w-full h-screen'>
        <Suspense fallback={<SimpleBackdrop />}>
          <BrowserRouter>
            {loading && <SimpleBackdrop />}
            <InputEventListener />
            <div className='flex  justify-between h-full'>
              {user && noDrawer && <Drawer />}
              <div className={`${noDrawer ? 'w-[95%]' : 'w-full'}`}>
                <Routes>
                  {user ? (
                    <>
                      <Route element={<RoleAcess allowedRoles={['fleetmax_supervisor']} />}>
                        <Route path='supervisor' element={<LayoutWeb />}>
                          <Route path='vendor' element={<Vendor />} />
                          <Route path='requestReport' element={<RequestReport />} />
                          <Route path='partner/*' element={<Partner />} />
                          <Route path='csr' element={<CSR />} />
                          <Route path='compareReport' element={<CompareReport />} />
                        </Route>
                        <Route path='addVendor/*' element={<AddVendor />} />
                        <Route path='technician' element={<Technician />} />
                      </Route>
                      <Route element={<RoleAcess allowedRoles={['fleetmax_csr']} />}>
                        <Route path='csr' element={<LayoutCsr />}>
                          <Route path='dashboard' element={<Csr />} />
                          <Route path='profile' element={<ProfileCsr />}>
                            <Route path='assigned-dsps' element={<AssignedDSPs />} />
                          </Route>
                          <Route path='cancel-requests' element={<CancelRequests />} />
                          <Route path='serviceList' element={<ServiceList />} />
                          <Route
                            path='inspection-questionnaire'
                            element={<InspectionQuestionnaire />}
                          />
                          <Route path='vehicleChecklist' element={<InspectionQuestionnaire />} />
                          <Route path='inspect/' element={<Inspect />} />
                          <Route
                            path='inspect/:company_id/:vehicle_id?'
                            element={<InspectionPage />}
                          />
                          <Route path='chat' element={<ChatCSR />} />
                          <Route path='vendorRequestReport' element={<InvoiceTable />} />
                        </Route>
                        <Route path='vendorSchedule' element={<BulkAddService />} />
                        <Route path='moveToComplete' element={<MoveToComplete />} />
                      </Route>
                      <Route path='scheduleRequest' element={<ScheduleRequest />} />
                      <Route element={<RoleAcess allowedRoles={['fleetmax_vendor']} />}>
                        <Route path='vendor' element={<LayoutVendor />}>
                          <Route path='technician' element={<Technician />} />
                          <Route path='dashboard' element={<VendorDashboard />} />
                          <Route path='dashboard/revenue' element={<Revenue />} />
                          <Route path='vehicle' element={<Vendor_ />} />
                          <Route path='profile' element={<Profile />} />
                          <Route path='notifications' element={<AlertsAndNotifications />} />
                          <Route path='inspect/' element={<Inspect />} />
                          <Route path='vendorRequestReport' element={<InvoiceTable />} />
                          <Route path='invoice' element={<InvoiceTable />} />
                          <Route
                            path='inspect/:company_id/:vehicle_id?'
                            element={<InspectionPage />}
                          />
                          <Route path='chat' element={<ChatCSR />} />
                        </Route>
                      </Route>
                      <Route element={<RoleAcess allowedRoles={['fleetmax_partner']} />}>
                        <Route path='partner' element={<LayoutPartner />}>
                          <Route path='technician' element={<Technician />} />
                          {/* <Route path='dashboard' element={<PartnerPage />} /> */}
                          <Route path='dashboard' element={<VendorDashboard />} />
                          <Route path='dashboard/revenue' element={<Revenue />} />
                          <Route path='vehicle' element={<Vendor_ />} />
                          <Route path='invoice' element={<InvoiceTable />} />
                          <Route path='vendorRequestReport' element={<InvoiceTable />} />
                          <Route path='chat' element={<ChatCSR />} />
                          <Route path='inspect/' element={<Inspect />} />
                          <Route
                            path='inspect/:company_id/:vehicle_id?'
                            element={<InspectionPage />}
                          />
                        </Route>
                        <Route path='partner'>
                          <Route path='scheduleRequest' element={<ScheduleRequest />} />
                        </Route>
                      </Route>
                      <Route
                        path='*'
                        element={<Navigate replace to={getMainRoute(user?.role)} />}
                      />
                    </>
                  ) : (
                    <>
                      <Route path='*' element={<Navigate replace to='/auth/signin' />} />
                      <Route path='auth' element={<LayoutAuth />}>
                        <Route path='signin' element={<Signin />} />
                        <Route path='signup' element={<Signup />} />
                        <Route path='reset-password' element={<ResetPassword />} />
                      </Route>
                    </>
                  )}
                  <Route path='forgot-password' element={<ForgotPassword />} />
                </Routes>
              </div>
            </div>
          </BrowserRouter>
        </Suspense>
      </div>
    </Analytics>
  );
}

export default App;
