import { Button } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { toast } from '../../../../../components/Toast/CustomToast';
import ProceedWithoutProofs from '../../../primaryComponents/ProceedWithoutProofs';

function AcceptRejectHandler({ item, hasProof }: { item: any; hasProof: boolean }) {
  const [rejectField, setRejectField] = useState<any>(false);
  const { showModel, setShowModel } = item;
  const noteRef: any = useRef();
  const divRef: any = useRef();
  useEffect(() => {
    if (rejectField && noteRef) {
      noteRef?.current?.focus();
      if (divRef?.current) {
        divRef?.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [rejectField]);

  if (rejectField) {
    return (
      <div className='flex flex-col justify-between p-3  border-[0.3rem] border-primary-light rounded-xl divide-blue-300  rounded-lg gap-3'>
        <div className='flex justify-end'>
          <button
            className='border-none py-1 px-6  text-grey rounded-lg'
            onClick={() => setRejectField(false)}>
            Cancel
          </button>
        </div>
        <textarea
          ref={noteRef}
          className='outline-primary-light  border-2 resize-none rounded-lg p-2 focus:outline-primary'
          style={{
            borderRadius: '0.8rem',
            padding: '0.7rem',
          }}
          rows={5}
          placeholder='Reason for Rejection'
          autoFocus={false}
        />
        <div className='w-full flex justify-center flex-col items-center gap-2' ref={divRef}>
          <button
            className='py-1 px-6 hover:bg-stone-200 text-blue-600 border border-blue-600 rounded-lg w-full'
            onClick={() => {
              const reason = noteRef?.current?.value?.trim() || '';
              if (reason?.length === 0) {
                toast.info('Please enter reason of rejection ');
                return;
              }

              item?.handleMoveState(item.id, '10', reason);
            }}>
            <i className='pi pi-times-circle text-sm p-1'></i>
            Reject
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className='w-full flex justify-around gap-4 h-[2rem]'>
      <Button
        variant='text'
        color={'error'}
        className='px-5 py-1 font-bold bg-rose-100 hover:bg-rose-200'
        onClick={() => {
          setRejectField(true);
        }}>
        Reject
      </Button>
      <Button
        variant='contained'
        color={'primary'}
        className='font-bold'
        onClick={() => {
          if (!hasProof) {
            setShowModel({ show: true, data: '' });
          } else {
            item.handleMoveState(item.id, {
              status_id: 9,
              sub_stage: null,
            });
          }
        }}>
        Approve
      </Button>
      {showModel?.show && (
        <ProceedWithoutProofs
          props={{
            showModel,
            setShowModel,
            onClick: () => {
              item.handleMoveState(
                item.id,
                {
                  status_id: 9,
                  sub_stage: null,
                },
                showModel?.data
              );
            },
          }}
        />
      )}
    </div>
  );
}

export default AcceptRejectHandler;
