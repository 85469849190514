import { useState } from 'react';
import VendorAPI from '../../../../../api/vendorApi';
import { getQueryParamsInvoiceHistroy } from '../../../../vender/utils';
import { useSearchParams } from 'react-router-dom';
import { InvoicesRequetsType } from '../../types/types';
import { RootState } from '../../../../../state/reducers';
import { useSelector } from 'react-redux';
import { Counts } from './tabValues';

const useFetchInvoicesData = () => {
  const [loader, setLoader] = useState(false);
  const [offsetLoader, setOffsetLoader] = useState(false);
  const [selectedTab, setSelectedTab] = useState('all');
  const [urlParams]: any = useSearchParams();
  const [offset, setOffset] = useState(10);
  const [invoicesData, setInvoicesData] = useState<any>([]);
  const [counts, setCounts] = useState<Counts>({});
  const [prevTab, setPrevtab] = useState('all');
  const [timeoutActive, setTimeoutActive] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const { user }: any = useSelector((state: RootState) => state.userState);
  const refetchInvoices = async (offset: number, invoicesData: any[]) => {
    if (offset === 10) {
      setLoader(true);
    } else {
      setOffsetLoader(true);
    }
    const vendorParam: string | null = urlParams?.get('vendor_ids') || null;
    const vendor_id_array = vendorParam ? JSON?.parse(vendorParam) : [];
    const partnerAsVendor: string =
      +vendor_id_array?.[0] === +user?.account_id && user?.role === 'fleetmax_partner'
        ? 'partner_as_vendor=true'
        : '';
    const query: string = `${String(
      getQueryParamsInvoiceHistroy(urlParams, selectedTab, offset / 10)
    )}${partnerAsVendor}`;
    const response: { status: number; data: { data: InvoicesRequetsType } } =
      await VendorAPI.getInvoicesDashBoard({
        query: query,
      });
    if (response.status >= 200 && response.status <= 210) {
      const request_count = response.data?.data?.request_counts;
      const isSametab = prevTab === selectedTab;
      if (isSametab) {
        if (offset > 10) {
          const prevData = invoicesData || [];
          setInvoicesData([...prevData, ...response.data?.data?.requests]);
        } else {
          setInvoicesData([...response.data?.data?.requests]);
        }
      } else {
        setInvoicesData([...response.data?.data?.requests]);
      }
      setTotalPages(response.data?.data?.total_pages);
      setLoader(false);
      setOffsetLoader(false);
      setCounts({
        ...request_count,
        all:
          (+request_count?.pending || 0) +
          (+request_count?.received || 0) +
          (+request_count?.approved || 0) +
          (+request_count?.disputed || 0) +
          (+request_count?.payment_completed || 0),
      });
      setPrevtab(selectedTab);
    } else {
      setLoader(false);
      setOffsetLoader(false);
    }
  };
  const currentCount = () => {
    if (selectedTab === 'all') {
      return Number(counts?.all) || 0;
    }
    if (selectedTab === 'pending') {
      return Number(counts?.pending) || 0;
    }
    if (selectedTab === 'received') {
      return Number(counts?.received) || 0;
    }
    if (selectedTab === 'approved') {
      return Number(counts?.approved) || 0;
    }
    if (selectedTab === 'disputed') {
      return Number(counts?.disputed) || 0;
    } else return 0;
  };
  const totalItems = currentCount();
  return {
    loader,
    invoicesData,
    refetchInvoices,
    setLoader,
    counts,
    selectedTab,
    setSelectedTab,
    offset,
    setOffset,
    totalItems,
    offsetLoader,
    setInvoicesData,
    timeoutActive,
    setTimeoutActive,
    totalPages,
  };
};

export default useFetchInvoicesData;
