import { Button } from '@mui/material';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useFetchVendorSingleRequest from '../../../hooks/vendor/useFetchVendorSingleRequest';
import VendorAPI from '../../../../api/vendorApi';
import { useState } from 'react';
import OverlayContainer from '../../../../components/OverlayContainer';
import ModalForm from '../../../../components/ModalForm';
import { toast } from '../../../../components/Toast/CustomToast';
import HandleLoad from '../../../../components/Loader/HandleLoad';

export function BulkActionButton({ props }: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loader = HandleLoad();
  const { selectedServices, setSelectedServices, item, isButton, ele } = props;
  const { fetchRequestData: fetchRequestDetails }: any = useFetchVendorSingleRequest();
  const [showModel, setShowModel] = useState(false);
  const { setBulkVehicles } = bindActionCreators(actionCreators, dispatch);
  const getVehicleCheckList = async (data: any) => {
    const response = await VendorAPI.getVehicleCheckList({
      preventive_request_id: data?.id || data?.preventive_request_id || '',
    });
    if (response.status === 200) {
      return {
        checklist_questions: {
          question_answers: response?.data?.data?.[0]?.checklist_questions?.question_answers,
        },
      };
    }
  };
  const handleSchedule = async (item: any) => {
    loader(true);
    await fetchRequestDetails(item, true).then(async (res: any) => {
      if (res) {
        const data = {
          ...res,
          id: res?.preventive_request_id || res?.id,
          preventive_request_id: res?.preventive_request_id || res?.id,
          title: `Request-${res?.preventive_request_id || res?.id}`,
        };
        const services = isButton ? [ele?.name] : selectedServices?.selectedServices;
        setBulkVehicles({
          bulkVehicles: [
            {
              ...(await getVehicleCheckList(data)),
              ...data,
              hasAdded: true,
              services: services?.length > 0 ? services : [],
              customServices: services?.length > 0 ? services : [],
              timeSlots: [],
              priority: '2',
              selectedNewRequestServices: services,
              scheduleTo: 'Schedule to Technician',
            },
          ],
        });
        navigate('/scheduleRequest');
        loader(false);
      }
    });
  };

  return (
    <>
      {isButton ? (
        <p
          className='bg-[#EA7B5921] text-[#EA7B59] hover:text-white hover:bg-[#EA7B59] font-normal font-[0.9rem] cursor-pointer  px-2 rounded-full'
          onClick={() => {
            setShowModel(true);
          }}>
          Add as a new request
        </p>
      ) : (
        <div className='w-full flex justify-end gap-2'>
          {selectedServices?.show ? (
            <>
              <Button
                className='flex justify-between items-center gap-2 text-[1.1rem] font-bold text-primary h-[2.4rem] '
                onClick={() => {
                  if (selectedServices?.selectedServices?.length > 1) {
                    setShowModel(true);
                  } else {
                    toast.error('Please select at least 2 service!');
                  }
                }}>
                <div className='bg-primary rounded-[0.5rem] h-[1.5rem] w-[1.5rem] flex items-center justify-center'>
                  <i className='pi pi-plus-circle text-white'></i>
                </div>
                <div className='flex flex-col'>
                  <p>Bulk Action</p>
                  <p className='text-[0.7rem] font-normal flex mt-[-0.5rem]'>
                    {selectedServices?.selectedServices?.length || 0} Selected
                  </p>
                </div>
              </Button>
              <button
                className='text-[#C55] hover:text-white hover:bg-[#C55] h-[2.2rem] w-[2.2rem]  border-transparent rounded-full flex items-center justify-center'
                onClick={() => {
                  setSelectedServices({
                    show: false,
                    selectedServices: [],
                  });
                }}>
                <i className='pi pi-times-circle text-[1.5rem] mt-0.5'></i>
              </button>
            </>
          ) : (
            <Button
              className='flex justify-between items-center gap-2 text-[1.1rem] text-gray-500 font-bold  h-[2rem]'
              onClick={() => {
                setSelectedServices({
                  show: true,
                  selectedServices: [],
                });
              }}>
              <i className='pi pi-plus-circle mt-0.5'></i>
              Bulk Action
            </Button>
          )}
        </div>
      )}
      {showModel && (
        <OverlayContainer show={true}>
          <ModalForm
            title={'Add CSR'}
            onClose={() => {
              setShowModel(false);
            }}
            width={'w-[25vw]'}
            noLabel>
            <div className=' flex flex-col gap-3 justify-between h-[10rem]'>
              <p className='text-[1.1rem] font-normal w-full flwx items-center justify-center text-[1.3rem]'>
                New Request
              </p>
              <p className='text-[1.1rem] font-normal'>
                Are you sure you want to create a new request using existing services from this
                request?
              </p>
              <div className='flex justify-center gap-4'>
                <Button
                  variant='outlined'
                  className=' text-[#C55] border-[#C55] font-bold w-[8rem]'
                  onClick={() => {
                    setShowModel(false);
                  }}>
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  className=' font-bold w-[8rem] whitespace-nowrap'
                  onClick={() => {
                    handleSchedule(item);
                    setShowModel(false);
                  }}>
                  Yes, Proceed
                </Button>
              </div>
            </div>
          </ModalForm>
        </OverlayContainer>
      )}
    </>
  );
}

export function InputFeild({ props }: any) {
  const { selectedServices, ele } = props;
  return (
    <input
      className='cursor-pointer'
      type='checkbox'
      checked={selectedServices?.selectedServices.includes(ele?.name)}
    />
  );
}
