export const getQueryParams = (params: any) => {
  const type = params.get('type') || '';
  const request = params.get('request') || '';
  const search = params.get('search') || '';
  const start_date = params.get('start_date') || '';
  const end_date = params.get('end_date') || '';
  const companies: any = params.get('companies') || '';
  const vehicleType: any = params.get('vehicle_type') || '';
  const priority: any = params.get('priority') || '';
  let query = '';
  const offset = params.get('offset') || '1';

  if (start_date) {
    query += `start_date=${start_date}&`;
  }
  if (end_date) {
    query += `end_date=${end_date}&`;
  }
  if (companies) {
    query += `companies=${companies}&`;
  }
  if (vehicleType) {
    query += `vehicle_type=${vehicleType}&`;
  }
  if (priority) {
    query += `priority_ids=[%22${String(priority)}%22]&`;
  }
  if (search) {
    query += `search=${search}&`;
    if (['All', null].includes(params.get('request')) && !start_date && !end_date && !companies) {
      return query;
    }
  }

  switch (type) {
    case 'requests': {
      query += `request_type=Pending_request`;
      return query;
    }
    case 'accepted': {
      query += `request_type=accepted_request`;
      return query;
    }
    case 'assigned': {
      query += `offset=${offset}&`;
      switch (request) {
        case 'Assigned To Technician':
          query += `status_ids=${JSON.stringify([5])}`;
          return query;
        case 'Technician Started Working':
          query += `status_ids=${JSON.stringify([6])}`;
          return query;
        case 'Job Completed/ Upload Proof':
          query += `status_ids=${JSON.stringify([7])}`;
          return query;
        case 'Approval Pending':
          query += `status_ids=${JSON.stringify([8])}`;
          return query;
        default:
          query += `status_ids=${JSON.stringify([5, 6, 7, 8])}`;
          return query;
      }
    }
    case 'approved': {
      query += `offset=${offset}&`;
      switch (request) {
        case 'Proofs Accepted':
          query += `request_type=Proofs Accepted`;
          return query;
        case 'Invoice Received':
          query += `request_type=Invoice Received`;
          return query;
        case 'Payment Completed':
          query += `request_type=Payment Completed`;
          return query;
        default:
          query += `request_type=approved`;
          return query;
      }
    }
    case 'dispute': {
      query += `offset=${offset}&`;
      query += `request_type=dispute`;
      return query;
    }
    case 'rejected': {
      switch (request) {
        case 'Declined by you':
          query += `rejected=true`;
          return query;

        case 'Rejected':
          query += `status_ids=${JSON.stringify([10])}`;
          return query;

        default:
          query += `status_ids=${JSON.stringify([10])}&rejected=true`;
          return query;
      }
    }

    case 'paymentConfirmed': {
      query += `offset=${offset}&`;
      query += `request_type=paymentProcessed`;
      return query;
    }
    default:
      query += `request_type=Pending_request`;
      return query;
  }
};
export const getQueryParamsPartner = (params: any) => {
  const type = params.get('type') || '';
  const request = params.get('request') || '';
  const search = params.get('search') || '';
  const start_date = params.get('start_date') || '';
  const end_date = params.get('end_date') || '';
  const companies: any = params.get('companies') || '';
  const vehicleType: any = params.get('vehicle_type') || '';
  const vendor_ids: any = params.get('vendor_ids') || '';
  const csr_ids: any = params.get('csr_ids') || '';
  const technician_ids: any = params.get('technician_ids') || '';
  const only_single: any = params.get('only_single') || '';
  const priority: any = params.get('priority') || '';
  let query = '';
  const offset = params.get('offset') || '1';
  query += `offset=${offset}&`;
  if (only_single) {
    query += `only_single=${only_single}&`;
  }
  if (vendor_ids) {
    query += `vendor_ids=${vendor_ids}&`;
  }
  if (technician_ids) {
    query += `technician_ids=${technician_ids}&`;
  }
  if (csr_ids) {
    query += `csr_ids=${csr_ids}&`;
  }
  if (priority) {
    query += `priority_ids=[%22${String(priority)}%22]&`;
  }
  if (start_date) {
    query += `start_date=${start_date}&`;
  }
  if (end_date) {
    query += `end_date=${end_date}&`;
  }
  if (companies) {
    query += `companies=${companies}&`;
  }
  if (vehicleType) {
    query += `vehicle_type=${vehicleType}&`;
  }
  if (search) {
    query += `search=${search}&`;
  }

  switch (type) {
    case 'requests': {
      query += `request_type=Pending_request`;
      return query;
    }
    case 'accepted': {
      query += `request_type=accepted_request`;
      return query;
    }
    case 'assigned': {
      switch (request) {
        case 'Assigned To Vendor':
          query += `request_type=assigned_to_vendor`;
          return query;
        case 'Assigned To Technician':
          query += `status_ids=${JSON.stringify([5])}`;
          return query;
        case 'Technician Started Working':
          query += `status_ids=${JSON.stringify([6])}`;
          return query;
        case 'Job Completed/ Upload Proof':
          query += `status_ids=${JSON.stringify([7])}`;
          return query;
        case 'Approval Pending':
          query += `status_ids=${JSON.stringify([8])}`;
          return query;
        default:
          query += `request_type=assigned`;
          return query;
      }
    }
    case 'approved': {
      switch (request) {
        case 'Proofs Accepted':
          query += `request_type=Proofs Accepted`;
          return query;
        case 'Invoice Sent':
          query += `request_type=Invoice Sent`;
          return query;
        case 'Invoice Received':
          query += `request_type=Invoice Received`;
          return query;
        case 'Payment Completed':
          query += `request_type=Payment Completed`;
          return query;
        default:
          query += `request_type=approved`;
          return query;
      }
    }
    case 'dispute': {
      query += `request_type=dispute`;
      return query;
    }
    case 'rejected': {
      switch (request) {
        case 'Declined by you':
          query += `request_type=rejected_by_you`;
          return query;

        case 'Rejected by CSR':
          query += `request_type=rejected_by_csr`;
          return query;

        case 'Rejected by Vendor':
          query += `request_type=rejected_by_vendor`;
          return query;

        default:
          query += `request_type=all_rejected`;
          return query;
      }
    }
    case 'paymentConfirmed': {
      query += `request_type=paymentProcessed`;
      return query;
    }
    default:
      query += `status_ids=${JSON.stringify([4])}&sub_stage=1`;
      return query;
  }
};
export const getQueryParamsRevenue = (params: any) => {
  const search = params.get('search') || '';
  const months = params.get('months') || '';
  const weeks = params.get('weeks') || '';
  const vehicleType: any = params.get('vehicle_type') || '';
  const technician_ids: any = params.get('technician_ids') || '';
  const services: any = params.get('services') || '';
  const station_ids: any = params.get('station_ids') || '';
  const sort_type: any = params.get('sort_type') || '';
  const revenue_sort: any = params.get('revenue_sort') || '';
  const customWeek: any = params.get('isWeeksCustom') || '';
  const customMonth: any = params.get('isMonthsCustom') || '';
  const page_number: any = params.get('page_number') || '';
  const vendor_ids = params.get('vendor_ids') || '';

  let query = '';

  if (page_number) {
    query += `page_number=${page_number}&`;
  }
  if (customWeek || customMonth) {
    query += `custom=true&`;
  }
  if (technician_ids) {
    query += `technician_ids=${technician_ids}&`;
  }
  if (station_ids) {
    query += `station_ids=${station_ids}&`;
  }
  if (services) {
    query += `services=${services}&`;
  }

  if (months) {
    query += `months=${months}&`;
  }
  if (weeks) {
    query += `weeks=${weeks}&`;
  }
  if (sort_type) {
    query += `sort_type=${sort_type}&`;
  }
  if (vehicleType) {
    query += `vehicle_type=${vehicleType}&`;
  }
  if (revenue_sort) {
    query += `revenue_sort=${revenue_sort}&`;
  }
  if (search) {
    query += `search=${search}&`;
  }
  if (vendor_ids) {
    query += `vendor_ids=${vendor_ids}&`;
  }
  return query;
};
export const getQueryParamsGraph = (params: any) => {
  const months = params.get('months') || '';
  const weeks = params.get('weeks') || '';
  let query = '';
  if (months) {
    query += `months=${months}&`;
  }
  if (weeks) {
    query += `weeks=${weeks}&`;
  }
  return query;
};
export const getQueryParamsInvoiceHistroy = (
  params: any,
  selectedTab?: string,
  currentPage?: any
) => {
  const months = params.get('months') || '';
  const weeks = params.get('weeks') || '';
  const vendor_ids = params.get('vendor_ids') || '';

  let query = '?';
  if (currentPage) {
    query += `page_number=${currentPage}&`;
  }
  if (months) {
    query += `months=${months}&`;
  }
  if (weeks) {
    query += `weeks=${weeks}&`;
  }
  if (vendor_ids) {
    query += `vendor_ids=${vendor_ids}&`;
  }
  if (selectedTab) {
    if (selectedTab !== 'all') {
      query += `request_type=${selectedTab}&`;
    }
  }
  return query;
};

export const getTime = (time: any) => {
  if (!time) {
    return '1 Hour';
  } else {
    let timeString = '';
    Object.entries(time).forEach((item) => {
      const [unit, duration] = item;
      timeString += duration + ' ' + unit + ' ';
    });
    return timeString;
  }
};
export const getTimeSecond = (time: any) => {
  if (!time) {
    return '30 Minutes';
  } else {
    let timeString = '';
    Object.entries(time).forEach((item) => {
      const [unit, duration] = item;
      timeString += duration + ' ' + unit + ' ';
    });
    return timeString;
  }
};
export function sortByProperty(arr: any[], propertyName: string) {
  return arr.slice().sort((a, b) => {
    const propertyA = a[propertyName].toUpperCase();
    const propertyB = b[propertyName].toUpperCase();

    if (propertyA < propertyB) {
      return -1;
    }
    if (propertyA > propertyB) {
      return 1;
    }
    return 0;
  });
}
