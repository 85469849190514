import dayjs, { Dayjs } from 'dayjs';
import VendorAPI from '../../../../../api/vendorApi';
import moment from 'moment-timezone';
import 'dayjs/locale/en';
import { useEffect, useState } from 'react';
import { DateDetails } from './DateDetails';
import SelectRange from './SelectRange';
import ChangeTime from './ChangeTime';
import { ColoredToolTip } from '../../../../../components/BarData/ColoredToolTip';
import { useSearchParams } from 'react-router-dom';
import { UpcomingRequests } from '../../types/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../state/reducers';

const initialValue = dayjs();

export function DateCalendarServerRequest() {
  const [urlParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [highlightedDays, setHighlightedDays] = useState<number[]>([]);
  const [allData, setAllData] = useState<any[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<any>(initialValue);
  const [selectedDate, setSelectedDate] = useState<any>(initialValue || null);
  const [selectRange, setSelectRange] = useState<any>({ show: false, year: null, month: null });
  const vendorParam: string | null = urlParams?.get('vendor_ids') || null;
  const { user }: any = useSelector((state: RootState) => state.userState);
  const fetchHighlightedDays = async (date: Dayjs) => {
    const dateToUse = date || dayjs();
    const monthYear = dateToUse?.format('MM-YYYY');
    const vendor_id_array = vendorParam ? JSON?.parse(vendorParam) : [];
    const partnerAsVendor: string =
      +vendor_id_array?.[0] === +user?.account_id && user?.role === 'fleetmax_partner'
        ? '&partner_as_vendor=true'
        : '';
    const vendors = vendorParam ? `${String(vendorParam)}${partnerAsVendor}` : null;
    const response: { status: number; data: { data: UpcomingRequests[] } } =
      await VendorAPI?.getHighlightedDates(monthYear, vendors);
    if (response.status === 200) {
      const data = response?.data?.data;
      const daysToHighlight =
        data?.length > 0
          ? data?.map((item: any) => moment?.utc(item?.date || item?.requests?.[0]?.date).date())
          : [];
      setHighlightedDays(daysToHighlight);
      setAllData(data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setHighlightedDays([]);
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      fetchHighlightedDays(initialValue);
    }, 100);
    return () => clearTimeout(timer);
  }, [vendorParam]);
  const handleMonthChange = (date: Dayjs) => {
    setIsLoading(true);
    setHighlightedDays([]);
    fetchHighlightedDays(date);
    setSelectedMonth(date);
  };
  const handleDateChange = (date: Dayjs) => {
    setSelectedDate(date);
  };
  const renderDays = () => {
    const daysInMonth = selectedMonth?.daysInMonth();
    const firstDayOfMonth = selectedMonth?.startOf('month').day();
    const daysArray = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
      daysArray.push(<div key={`empty-${i}`} className='w-10 h-10'></div>);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      const currentDay = selectedMonth.date(i).day();
      const isSelected =
        selectedDate?.date() === i && selectedDate?.month() === selectedMonth?.month();
      const isHighlighted = highlightedDays?.includes(i);
      const dayData = allData?.find(
        (item: any) => moment?.utc(item.date || item.requests?.[0]?.date).date() === i
      );
      const requestCount = dayData?.request_count || 0;
      const isWeekend = currentDay === 0 || currentDay === 6;
      const isInitialDay =
        initialValue.date() === i && initialValue.month() === selectedMonth?.month();
      const textHoverColor = isWeekend ? 'hover:text-[#C55]' : 'hover:text-primary';
      const InitialDay = isInitialDay
        ? 'bg-gray-100'
        : `hover:bg-primary-lighter ${textHoverColor}`;
      const loadColor = isLoading ? 'bg-primary-light' : '';
      const isWeekEnd = isWeekend ? 'text-[#C55]' : '';
      const className = `relative w-[2.6rem] h-[2.6rem] flex items-center justify-center rounded-full cursor-pointer ${
        isSelected ? 'bg-blue-500 text-white' : InitialDay
      } ${loadColor} ${isWeekEnd}`;

      daysArray.push(
        <ColoredToolTip title={isHighlighted ? `${requestCount} New Request` : ''}>
          <div
            key={i}
            className={className}
            onClick={() => handleDateChange(selectedMonth?.date(i))}>
            {!isLoading && (
              <>
                {i}
                {isHighlighted && (
                  <i className='absolute bottom-1 pi pi-circle-fill text-[0.4rem] text-primary'></i>
                )}
              </>
            )}
          </div>
        </ColoredToolTip>
      );
    }

    return daysArray;
  };
  const isSameMonth = (date1: Dayjs, date2: Dayjs) => {
    return date1?.month() === date2?.month() && date1?.year() === date2?.year();
  };
  const isDateHighlighted = highlightedDays.includes(selectedDate?.date());
  return (
    <div className='flex flex-col gap-2 rounded-lg border border-gray-300 w-full h-full p-1 shadow-md'>
      <p className='px-3 pt-1'>Upcoming Requests</p>
      <div className='w-full h-full p-2  rounded-lg flex gap-2 border border-gray-300'>
        {selectRange?.show && (
          <SelectRange props={{ selectRange, setSelectRange, handleMonthChange }} />
        )}
        {!selectRange?.show && (
          <div className='flex flex-col w-[37%] p-3 border-[0.1rem] border-gray-200 rounded-[0.5rem]'>
            <ChangeTime props={{ selectedMonth, setSelectRange, handleMonthChange }} />
            <div className='grid grid-cols-7 gap-2 '>
              {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
                <div key={day} className={`font-bold text-center  text-gray-500`}>
                  {day}
                </div>
              ))}
              {renderDays()}
            </div>
          </div>
        )}
        <DateDetails
          data={
            allData.find(
              (item: any) =>
                moment.utc(item?.date || item?.requests?.[0]?.date).date() === selectedDate.date()
            ) || {}
          }
          selectedDate={
            selectedDate &&
            selectedMonth &&
            isSameMonth(selectedDate, selectedMonth) &&
            selectedDate
          }
          refetch={() => {
            handleMonthChange(selectedDate);
          }}
          isSameMonth={selectedDate && selectedMonth && isSameMonth(selectedDate, selectedMonth)}
          loader={isLoading}
          ishighlighted={isDateHighlighted}
        />
      </div>
    </div>
  );
}
