const BackButton = (props: { height?: string; width?: string; active?: boolean }) => {
    const height = props?.height || '1rem';
    const width = props?.width || '1.1rem';
    return (

        <svg width={width} height={height} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group 40933">
                <path id="Vector" d="M19 8H1" stroke="#4378C8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path id="Vector_2" d="M8 15L1 8L8 1" stroke="#4378C8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
        </svg>


    );
};
export default BackButton;
