import { Admin, AdminListProps, DSPData, DSPListProps, DataType, VehicleType } from './Types';
import React from 'react';

export const compare = (arr1: DSPData[], arr2: DSPData[]) => {
  if (arr1.length !== arr2.length) return false;

  return arr1.every((item1) => {
    const matchingItem = arr2.find((item2) => item1.id === item2.id);
    if (!matchingItem) return false;

    return (
      item1.dsp === matchingItem.dsp &&
      JSON.stringify(item1.admin) === JSON.stringify(matchingItem.admin)
    );
  });
};

export const getFilteredResponseData = (
  apiResponse: DataType[] | undefined,
  vehicles: { dsp: { id: string | number } }[]
): DSPData[] => {
  if (!apiResponse) return [];
  const responseData: DSPData[] = apiResponse.map((item) => {
    const dspName = item?.dsp?.dsp_name || 'Unknown DSP';
    const companyId = item?.dsp?.company_id;
    const dspId = item?.dsp?.id;

    const modifiedAdminList: Admin[] = [
      {
        name: dspName,
        id: dspId,
      },
      ...(item?.admin_list?.map((admin: Admin) => ({
        name: admin.admin_name || '',
        id: admin.id || '',
      })) || []),
    ];

    return {
      dsp: dspName,
      id: companyId,
      admin: modifiedAdminList,
    };
  });

  const vehicleDSPIds = vehicles
    .map((vehicle) => +(vehicle as { dsp: { id: string | number } })?.dsp?.id)
    .filter(Boolean);

  const filteredResponseData = responseData.filter((data) => vehicleDSPIds.includes(+data.id));
  return filteredResponseData || [];
};
export const formatAdmins = (item: DataType): DSPData => {
  const dspName = item?.dsp?.dsp_name || 'Unknown DSP';
  const companyId = item?.dsp?.company_id;

  const modifiedAdminList: Admin[] =
    item?.admin_list?.map((admin: Admin) => ({
      name: admin.admin_name || '',
      id: `${admin.id || ''}`,
    })) || [];

  return {
    dsp: dspName,
    id: `${companyId}`,
    admin: modifiedAdminList,
  };
};
export const MapVehicles = ({ vehicles }: { vehicles: VehicleType[] }) => {
  return (
    <React.Fragment>
      <div className='mt-[1rem] mx-[1rem]'>
        {vehicles.map((elem) => (
          <p
            key={elem?.vehicle?.vin}
            className='flex flex-col items-left ml-2 justify-start uppercase mb-[0.5rem]'>
            <p className='flex gap-1 items-center'>
              <i className='pi pi-circle-fill text-[0.3rem] mr-[0.3rem]'></i>{' '}
              <span className='text-[0.9rem]'>{elem?.vehicle?.name}</span>
            </p>
            <p className='flex gap-1 items-center'>
              <i className='pi pi-circle-fill text-[0.3rem] text-white mr-[0.3rem]'></i>
              <span className='text-[0.9rem]'>({elem?.vehicle?.vin})</span>
            </p>
          </p>
        ))}
      </div>
    </React.Fragment>
  );
};

export const DSPList: React.FC<DSPListProps> = ({ data, selectedDSP, onSelectDSP }) => {
  return (
    <React.Fragment>
      <div className='mt-[1rem] mx-[1rem] '>
        {data.map((dsp) => (
          <button
            key={dsp.id}
            className={`py-2 px-4 w-full rounded-[0.5rem] mb-[0.5rem] ${
              data.length > 1 ? 'cursor-pointer' : 'cursor-default'
            }  ${selectedDSP === dsp.id ? 'bg-primary-light' : ''}`}
            onClick={() => onSelectDSP(dsp)}>
            {dsp.dsp}
          </button>
        ))}
      </div>
    </React.Fragment>
  );
};
export const AdminList: React.FC<AdminListProps> = ({
  selectedDSP,
  data,
  allSelected,
  disabled,
  onSelectAdmin,
}) => {
  if (!selectedDSP) return null;

  const selectedData = data.find((d) => d.id === selectedDSP);

  if (!selectedData) return null;

  return (
    <React.Fragment>
      <div className='mt-[0.9rem]'>
        {selectedDSP &&
          data
            .find((d) => d.id === selectedDSP)
            ?.admin.map((admin) => (
              // JSX with inline CSS for styled admin list:

              <div
                onClick={() => {
                  if (!disabled) onSelectAdmin(admin);
                }}
                key={admin.id}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  padding: '8px 16px',
                  width: '100%',
                  gap: '4px',
                  textTransform: 'capitalize',
                  cursor: 'pointer',
                  fontFamily: 'Arial, sans-serif',
                  color: 'rgba(0, 0, 0, 1)',
                }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.4rem' }}>
                  <input
                    disabled={disabled}
                    type='checkbox'
                    checked={allSelected.some(
                      (d) => d.id === selectedDSP && d.admin.some((a) => a.id === admin.id)
                    )}
                    style={{
                      cursor: 'pointer',
                    }}
                  />
                  <span>
                    {data.find((d) => d.id === selectedDSP)?.dsp === admin.name ? (
                      <>
                        {admin.name}{' '}
                        <span
                          style={{
                            fontStyle: 'italic',
                            color: '#666',
                            fontSize: '0.9rem',
                          }}>
                          (Owner)
                        </span>
                      </>
                    ) : (
                      <>
                        {admin.name}{' '}
                        <span
                          style={{
                            fontStyle: 'italic',
                            color: '#666',
                            fontSize: '0.9rem',
                          }}>
                          (Admin)
                        </span>
                      </>
                    )}
                  </span>
                </div>
              </div>
            ))}
      </div>
    </React.Fragment>
  );
};
