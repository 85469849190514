import React, { useState } from 'react';
import usePartnerAsVendor from '../../../../hooks/usePartnerAsVendor';
import Tootltip from '../../../../../components/BarData/Tootltip';
import getNameAlias from '../../../NameAlias';

interface Props {
  name: string;
  phone: string;
  address: string;
  newRequests: number;
  accepted: number;
  assigned: number;
  disputed: number;
  key: number;
  account_id: string;
  email: string;
}
const TooltipContent: React.FC<{ text: string }> = ({ text }) => (
  <Tootltip placement='bottom'>
    <p className='text-md whitespace-nowrap overflow-hidden text-ellipsis w-[15rem] text-left'>
      {text}
    </p>
  </Tootltip>
);

const Tabs: React.FC<{ value: number; label: string; colorClass: string }> = ({
  value,
  label,
  colorClass,
}) => (
  <div className={`text-center font-bold ${colorClass}`}>
    <div>{value}</div>
    <div className='text-[0.9rem] whitespace-nowrap'>{label}</div>
  </div>
);
const UserPartnerCard: React.FC<Props> = (props) => {
  const { goToVendorProfile } = usePartnerAsVendor();
  const {
    name,
    email,
    phone,
    address,
    newRequests,
    accepted,
    assigned,
    disputed,
    account_id,
    key,
  } = props;
  const [showTooltip, setShowTooltip] = useState(false);

  const tabsData = [
    { value: newRequests, label: 'New Requests', colorClass: 'text-primary' },
    { value: accepted, label: 'Accepted', colorClass: 'text-acceptedColor' },
    { value: assigned, label: 'Assigned', colorClass: 'text-assignedColor' },
    { value: disputed, label: 'Disputed', colorClass: 'text-disputedColor' },
  ];
  return (
    <div
      className='w-[48%] bg-white border border-borderColor rounded-lg shadow cursor-pointer hover:shadow-md'
      key={key}
      onClick={() => {
        goToVendorProfile({ email, account_id });
      }}>
      <div
        className='flex p-[1.2rem]'
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}>
        <div>
          <div className='inline-block text-center flex items-center justify-center  rounded-full bg-avatarColor text-white w-[3rem] h-[3rem]'>
            <span className='text-[1.8rem] capitalize'>{getNameAlias(name)}</span>
          </div>
        </div>

        <div className='pl-[1rem]'>
          <div className='flex'>
            <Tootltip title={name} placement={'bottom'}>
              <p
                className={`${'max-w-[20rem]'} text-ellipsis whitespace-nowrap overflow-hidden capitalize text-xl font-bold-400 capitalize`}>
                {name}
              </p>
            </Tootltip>
            <div className='tooltip relative inline-block'>
              <img
                src='../../svg/RedirectIcon.svg'
                alt='Background Image'
                className='inline-block h-5 w-5 pl-[0.5rem]'
              />

              {showTooltip && (
                <span
                  className='tooltiptext bg-toolTipColor text-white px-2 pb-6 rounded-md text-center absolute bottom-0 left-1/2 top-10 transform -translate-x-1/2 opacity-100 pointer-events-auto whitespace-nowrap'
                  onClick={() => {
                    goToVendorProfile({ email, account_id });
                  }}>
                  Go to Profile
                </span>
              )}
            </div>
          </div>
          <TooltipContent text={phone} />
          <TooltipContent text={address} />
          <TooltipContent text={email} />
        </div>
      </div>
      <div className='border-b-[0.15rem] border-borderColor mx-[1rem]'></div>
      <div className='flex py-[1.2rem] px-[1.5rem] gap-x-5 justify-between'>
        {tabsData.map(({ value, label, colorClass }) => (
          <Tabs key={label} value={value} label={label} colorClass={colorClass} />
        ))}
      </div>
    </div>
  );
};

export default UserPartnerCard;
