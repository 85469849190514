import { useEffect, useMemo, useState } from 'react';
import ChatAPI from '../../../../../../api/ChatAPI';
import CsrAPI from '../../../../../../api/csrApi';
import { Admin, DataType, DSPData, UseFetchDataProps, Vehicle } from './Types';
import { formatAdmins, getFilteredResponseData } from './Functions';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../state/reducers';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../../../state';

export const useLogic = ({
  vehicles,
  isSaved,
  setSelectedDSP,
  setAllData,
  selectedDSP,
  data,
  setAdminList,
  handleClose,
  setIsSaved,
}: UseFetchDataProps) => {
  const dispatch = useDispatch();
  const { setSelectedData } = bindActionCreators(actionCreators, dispatch);
  const { selectedData } = useSelector((state: RootState) => state.userState);
  const [allSelected, setAllSelected] = useState<DSPData[]>(selectedData || []);
  const [loader, setLoader] = useState<boolean>(false);
  const fetchApis = async () => {
    setLoader(true);
    await ChatAPI.getChatUserNames('dsp')
      .then((res: { status: number; data?: { data?: DataType[] } }) => {
        if (res.status === 200) {
          const filteredResponseData = getFilteredResponseData(
            res.data?.data,
            vehicles as { dsp: { id: string | number } }[]
          );
          if (filteredResponseData?.length > 0) {
            setAllData(filteredResponseData);
            setSelectedDSP(filteredResponseData?.[0]?.id || null);
          }
        } else {
          setAllData([]);
        }
      })
      .catch((err) => console.log(err));

    if (!isSaved) {
      const preventiveRequestIds = vehicles
        .map((item) => item?.preventive_request_id)
        .filter((id): id is string => !!id);
      if (preventiveRequestIds.length > 0) {
        await CsrAPI.getAdminList({ ids: preventiveRequestIds })
          .then((res: { status: number; data?: { data?: DataType[] } }) => {
            if (res.status === 200) {
              const responseData: DSPData[] = (res?.data?.data || []).map(formatAdmins);
              setSelectedData(responseData);
              setAllSelected(responseData);
            } else {
              setSelectedData([]);
            }
          })
          .catch((err) => console.log(err));
      }
    }
    setLoader(false);
  };
  const handleSelectAdmin = (admin: Admin) => {
    const dspIndex = allSelected.findIndex((d) => d.id === selectedDSP);
    if (dspIndex !== -1) {
      const updatedAdmins = allSelected[dspIndex].admin.some((a) => a.id === admin.id)
        ? allSelected[dspIndex].admin.filter((a) => a.id !== admin.id)
        : [...allSelected[dspIndex].admin, admin];

      const updatedSelectedData = [...allSelected];
      updatedSelectedData[dspIndex] = { ...updatedSelectedData[dspIndex], admin: updatedAdmins };

      if (updatedAdmins.length === 0) {
        updatedSelectedData.splice(dspIndex, 1);
      }

      setAllSelected(updatedSelectedData);
    } else {
      const dsp = data.find((d) => d.id === selectedDSP);
      if (dsp) {
        setAllSelected([...allSelected, { dsp: dsp.dsp, id: dsp.id, admin: [admin] }]);
      }
    }
  };
  useEffect(() => {
    fetchApis();
  }, []);
  const filteredVehicles = useMemo(() => {
    return vehicles.filter(
      (vehicle: Vehicle) => selectedDSP && vehicle?.dsp?.id && +vehicle?.dsp?.id === +selectedDSP
    );
  }, [vehicles, selectedDSP]);
  const onSave = () => {
    setSelectedData(allSelected);
    setAdminList(allSelected);
    handleClose();
    setIsSaved(true);
  };
  const onRemove = () => {
    setAllSelected([]);
  };
  return {
    loader,
    allSelected,
    handleSelectAdmin,
    filteredVehicles,
    onSave,
    onRemove,
  };
};
