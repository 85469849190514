import { request } from './base';
import { IAssignVenor, IUnAssignVenor, IQuestionnire } from './inspectionTypes';

export default class CsrAPI {
  static getInspectionData(data?: any) {
    return request({
      url: `/csr_inspection/v1/assigned_companies`,
      method: 'get',
    });
  }

  static getQuestionnire(data?: any) {
    return request({
      url: `/csr_inspection/v1/unassigned/questionnarie`,
      method: 'get',
    });
  }

  static assignVendor(data: IAssignVenor) {
    return request({
      url: `/csr_inspection/v1/assign_vendor`,
      method: 'post',
      data: data,
    });
  }

  static unAssignVendor(data: IUnAssignVenor) {
    return request({
      url: `/csr_inspection/v1/unassign_vendor`,
      method: 'patch',
      data: data,
    });
  }

  static assignQuestionnire(data: IQuestionnire) {
    return request({
      url: `/csr_inspection/v1/assign_questionnare`,
      method: 'post',
      data: data,
    });
  }

  static unAssignQuestionnire(data: IQuestionnire) {
    return request({
      url: `/csr_inspection/v1/unassign_questionnare`,
      method: 'patch',
      data: data,
    });
  }

  static getVehicles(data: { company_id: string; sortType: string }) {
    return request({
      url: `/csr_inspection/v1/all_vehicles/${data?.company_id}?${data?.sortType}`,
      method: 'get',
    });
  }

  static getInspectionDataVedor(data?: any) {
    return request({
      url: `/vendor_inspection/v1/inspection_list`,
      method: 'get',
    });
  }
  static getHistory(data: { vehicle_id: string }) {
    return request({
      url: `/csr_inspection/v1/get_vehicle_history?id=${data?.vehicle_id}`,
      method: 'get',
    });
  }
  static getHistoryVendor(data: { vehicle_id: string }) {
    return request({
      url: `/vendor_inspection/v1/get_vehicle_history?id=${data?.vehicle_id}`,
      method: 'get',
    });
  }

  static downloadReport(data: any) {
    return request({
      url: `/tech_inspection/v1/download_report/${data.id || data?.health_checkup_id}`,
      method: 'get',
    });
  }

  static getReport(data: any) {
    return request({
      url: `/tech_inspection/v1/get_report/${data.id || data?.health_checkup_id}`,
      method: 'get',
    });
  }

  static approveInspection(data: { health_checkup_id: string }) {
    return request({
      url: `/csr_inspection/v1/approve_inspection/${data.health_checkup_id}`,
      method: 'patch',
    });
  }
  static getVehiclesVendor(data: { company_id: string; sortType: string }) {
    return request({
      url: `/vendor_inspection/v1/all_vehicles/${data?.company_id}?${data?.sortType}`,
      method: 'get',
    });
  }
}
