import { request } from './base';
import {
  IAcceptCSRequest,
  IAcceptCSRequestBulk,
  IRejectCSRequest,
  IRejectCSRequestBulk,
  IPreventiveAssignTechnicians,
} from './types';

export default class VendorAPI {
  static getVendorDetails(data: any) {
    return request({
      url: `/vendor/v1/get/id/?id=${data.id}`,
      method: 'get',
      data: data,
    });
  }

  static getUnassignedTechnician(data: any) {
    return request({
      url: '/technicians/v1/unassigned_technicians',
      method: 'get',
      data: data,
    });
  }

  static assignTechnician(data: any) {
    return request({
      url: `/technicians/v1/assign_technician_vendor`,
      method: 'post',
      data: data,
    });
  }

  static getVendorDetail(data: any) {
    return request({
      url: 'users/v1/repair_shop_owners',
      method: 'get',
      data: data,
    });
  }

  static getVendorFullDetail(data: any) {
    return request({
      url: `/vendor/v1/get_all_details/${data?.id || ''}`,
      method: 'get',
      data: data,
    });
  }
  static getFullDetail(data: any) {
    return request({
      url: `/vendor/v1/fleetmax_vendor/${data.id}`,
      method: 'patch',
      data: data,
    });
  }
  static getAllData(data: any) {
    return request({
      url: '/vendor/v1/get_all_vendors',
      method: 'get',
      data: data,
    });
  }
  static getAllVendorUnderPartner(data: any) {
    return request({
      url: `/vendor/v1/get_all_vendors?partner_account_id=${data?.id}`,
      method: 'get',
      data: data,
    });
  }
  static add(data: any) {
    return request({
      url: 'vendor/v1/insert_vendor',
      method: 'post',
      data: data,
    });
  }

  static updateVendor(data: any) {
    return request({
      url: `vendor/v1/fleetmax_vendor/${data.id}`,
      method: 'patch',
      data: data.payload,
    });
  }

  static removeTehcnician(data: any) {
    return request({
      url: `/technicians/v1/unassign_technician/${data.id}`,
      method: 'delete',
    });
  }

  static UploadDoc(data: any) {
    return request({
      url: `/vendor/v1/upload_w9`,
      method: 'put',
      data: data,
    });
  }

  static delete(data: any) {
    return request({
      url: `vendor/v1/vendor_delete/${data.id}?email=${data.email}&phone=${data.phone}`,
      method: 'patch',
    });
  }

  static getVendorsTechnician() {
    return request({
      url: `vendor/v1/get/id`,
      method: 'get',
    });
  }
  static getVendorTechnicianbyCSR(data: any) {
    return request({
      url: `vendor/v1/get/id/?id=${data?.id}`,
      method: 'get',
    });
  }

  static addTechnicianFromVendor(data: any) {
    return request({
      url: `technicians/v1/signup_technician`,
      method: 'post',
      data: data,
    });
  }

  static getCheckList(data: any) {
    return request({
      url: `/pm-request/v1/preventive/checklist?preventive_request_id=${data.preventive_request_id}`,
      method: 'get',
      data: data,
    });
  }

  static checkUserExist(data: any) {
    return request({
      url: `vendor/v1/exist/email/${data.email}/phone/${data.phone}`,
      method: 'get',
      data: data,
    });
  }

  static acceptCsrRequest(data: IAcceptCSRequest) {
    return request({
      url: `/vendor_actions/v1/accept_request`,
      method: 'patch',
      data: data,
    });
  }

  static acceptCsrRequestBulk(data: IAcceptCSRequestBulk) {
    return request({
      url: `/vendor_actions/v1/accept_request_bulk`,
      method: 'patch',
      data: data,
    });
  }

  static rejectCsrRequest(data: IRejectCSRequest) {
    return request({
      url: `/vendor_actions/v1/reject_request`,
      method: 'patch',
      data: data,
    });
  }

  static rejectCsrRequestBulk(data: IRejectCSRequestBulk) {
    return request({
      url: `/vendor_actions/v1/reject_request_bulk`,
      method: 'patch',
      data: data,
    });
  }

  static reAssignTechnician(data: IPreventiveAssignTechnicians) {
    return request({
      url: `/vendor_actions/v1/reassign_pm_technician`,
      method: 'patch',
      data: data,
    });
  }
  static getAlertsDetails(data: any) {
    return request({
      url: `/vendor_actions/v1/technician_alerts`,
      method: 'get',
    });
  }
  static updateAlertsDetails(data: any) {
    return request({
      url: `/vendor_actions/v1/update_service_alerts`,
      method: 'patch',
      data: data,
    });
  }
  static updateTechnicianAlertDetail(data: any) {
    return request({
      url: `/vendor_actions/v1/insert_technician_alerts`,
      method: 'post',
      data: data,
    });
  }

  static getRequestCompanies(data: any) {
    return request({
      url: `/vendor_actions/v1/assigned_stations`,
      method: 'get',
    });
  }
  static getVendorServices(data: any) {
    return request({
      url: `/vendor_actions/v1/service_list/${data.id}`,
      method: 'get',
      data: data,
    });
  }
  static getInvoiceDetails(data: any) {
    return request({
      url: `/csr/v1/get_invoice?ids_arr=[${JSON.stringify(data.id)}]`,
      method: 'get',
      data: data,
    });
  }
  static getInvoiceDetailsArray(data: any) {
    return request({
      url: `/csr/v1/get_invoice?ids_arr=${data.id}`,
      method: 'get',
      data: data,
    });
  }
  static getDisputeHistory(data: any) {
    return request({
      url: `/vendor_actions/v1/dispute_history/${data.id}`,
      method: 'get',
      data: data,
    });
  }
  static createDispute(data: any) {
    return request({
      url: `/vendor_actions/v1/dispute`,
      method: 'post',
      data: data,
    });
  }
  static declineDisputeCsr(data: any) {
    return request({
      url: `/vendor_actions/v1/dispute`,
      method: 'patch',
      data: data,
    });
  }
  static DisputeIdHistory(data: any) {
    return request({
      url: `/vendor_actions/v1/disputed_services/${data?.id}`,
      method: 'get',
      data: data,
    });
  }
  static settleDispute(data: any) {
    return request({
      url: `/vendor_actions/v1/settle_dispute`,
      method: 'patch',
      data: data,
    });
  }
  static addDisputeDoc(data: FormData) {
    return request({
      url: `/vendor_actions/v1/dispute_doc`,
      method: 'post',
      data: data,
    });
  }

  static deleteDisputeDoc(data: { url: string }) {
    return request({
      url: `/vendor_actions/v1/dispute_doc?url=${data.url}`,
      method: 'delete',
      data: data,
    });
  }
  static getRequestInfo(data: any) {
    return request({
      url: `/partner/v1/request_info?${data?.id}`,
      method: 'get',
    });
  }
  static getVehicleCheckList(data: any) {
    return request({
      url: `/csr_inspection/v1/preventive/question_answers?preventive_request_id=${data.preventive_request_id}`,
      method: 'get',
      data: data,
    });
  }
  static deleteServices(data: any) {
    return request({
      url: `/vendor/v1/delete_service?ids=${data}`,
      method: 'patch',
      data: data,
    });
  }
  static getHighlightedDates(data: any, vendorIDs?: any) {
    return request({
      // url: `/vendor_actions/v1/upcoming_request?month=${data}${
      url: `/dashboard/v1/upcoming_request?month=${data}${
        vendorIDs ? `&vendor_ids=${vendorIDs}` : ''
      }`,
      method: 'get',
      data: data,
    });
  }
  static getAllRevenueData(data: any) {
    return request({
      url: `/dashboard/v1/revenue_data?${data.params}`,
      // url: `/vendor_actions/v1/revenue_data?${data.params}`,
      method: 'get',
    });
  }
  static getAllGraphData(data: any) {
    return request({
      url: `/dashboard/v1/graph_data?${data.params}`,
      // url: `/vendor_actions/v1/graph_data?${data.params}`,
      method: 'get',
    });
  }
  static getAllInvoiceHistroyData(data: any) {
    return request({
      url: `/dashboard/v1/invoice_history?${data.params}`,
      // url: `/vendor_actions/v1/invoice_history?${data.params}`,
      method: 'get',
    });
  }
  static getInvoicesDashBoard(data: { query?: string }) {
    return request({
      url: `/dashboard/v1/invoice_history${data?.query}`,
      // url: `/vendor_actions/v1/invoice_history${data?.query}`,
      method: 'get',
    });
  }
  static remind(data: any) {
    return request({
      url: `/dashboard/v1/remind_csr`,
      // url: `/vendor_actions/v1/remind_csr`,
      method: 'patch',
      data: data,
    });
  }
  static requestPerDate(data: any, vendorIDs?: any) {
    return request({
      url: `/dashboard/v1/request_per_date?date=${data}${
        vendorIDs ? `&vendor_ids=${vendorIDs}` : ''
      }`,
      method: 'get',
      data: data,
    });
  }
  static getPerfomenceGraph(data: any) {
    return request({
      url: `/partner/v1/performance?${data.params}`,
      method: 'get',
    });
  }
  static getRevenueSources(data: any) {
    return request({
      url: `/partner/v1/revenue_sources?${data.params}`,
      method: 'get',
    });
  }
}
