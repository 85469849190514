import { useState, useCallback } from 'react';
import CsrAPI from '../../../api/csrApi';
import { toast } from '../../../components/Toast/CustomToast';

interface TooltipData {
  [key: string]: any;
}

interface UseTooltipDataReturn {
  tooltipData: TooltipData;
  fetchTooltipData: (id: string) => Promise<void>;
}

const useTooltipData = (): UseTooltipDataReturn => {
  const [tooltipData, setTooltipData] = useState<TooltipData>({});

  const fetchTooltipData = useCallback(async (id: string) => {
    try {
      const res = await CsrAPI.getTooltipData({ id });
      if (res.status === 200) {
        setTooltipData((prevData) => ({
          ...prevData,
          [id]: res.data.data,
        }));
      } else {
        toast.warn('Something went wrong');
      }
    } catch (error) {
      toast.error('Failed to fetch tooltip data');
    }
  }, []);

  return { tooltipData, fetchTooltipData };
};

export default useTooltipData;
