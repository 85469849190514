export default function (props: {
  height?: string;
  width?: string;
  active?: boolean;
  fill?: string;
  color?: string;
}) {
  const height = props?.height || '1.5rem';
  const width = props?.width || '1.5rem';
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.2857 1.71429H13.7143V1.10714C13.7143 0.742857 13.4857 0.5 13.1429 0.5C12.8 0.5 12.5714 0.742857 12.5714 1.10714V1.71429H10.2857V1.10714C10.2857 0.742857 10.0571 0.5 9.71429 0.5C9.37143 0.5 9.14286 0.742857 9.14286 1.10714V1.71429H6.85714V1.10714C6.85714 0.742857 6.62857 0.5 6.28571 0.5C5.94286 0.5 5.71429 0.742857 5.71429 1.10714V1.71429H3.42857V1.10714C3.42857 0.742857 3.2 0.5 2.85714 0.5C2.51429 0.5 2.28571 0.742857 2.28571 1.10714V1.71429H1.71429C0.742857 1.71429 0 2.50357 0 3.53571V15.6786C0 16.7107 0.742857 17.5 1.71429 17.5H14.2857C15.2571 17.5 16 16.7107 16 15.6786V3.53571C16 2.50357 15.2571 1.71429 14.2857 1.71429ZM1.71429 2.92857H2.28571V3.53571C2.28571 3.9 2.51429 4.14286 2.85714 4.14286C3.2 4.14286 3.42857 3.9 3.42857 3.53571V2.92857H5.71429V3.53571C5.71429 3.9 5.94286 4.14286 6.28571 4.14286C6.62857 4.14286 6.85714 3.9 6.85714 3.53571V2.92857H9.14286V3.53571C9.14286 3.9 9.37143 4.14286 9.71429 4.14286C10.0571 4.14286 10.2857 3.9 10.2857 3.53571V2.92857H12.5714V3.53571C12.5714 3.9 12.8 4.14286 13.1429 4.14286C13.4857 4.14286 13.7143 3.9 13.7143 3.53571V2.92857H14.2857C14.6286 2.92857 14.8571 3.17143 14.8571 3.53571V5.35714H1.14286V3.53571C1.14286 3.17143 1.37143 2.92857 1.71429 2.92857ZM14.2857 16.2857H1.71429C1.37143 16.2857 1.14286 16.0429 1.14286 15.6786V6.57143H14.8571V15.6786C14.8571 16.0429 14.6286 16.2857 14.2857 16.2857Z'
        fill={props.color ? props.color : '#4378C8'}
      />
    </svg>
  );
}
