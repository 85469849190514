import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import AddCustom from './AddCustom';
import AddCustomWeek from './AddCustomWeek';
function SelectTime({ newSearchParams }: any) {
  return (
    <>
      <WeekButton newSearchParams={newSearchParams} />
      <MonthsButton newSearchParams={newSearchParams} />
    </>
  );
}

export default SelectTime;
const isoWeek = (date: dayjs.Dayjs) => {
  const startOfYear = date.startOf('year');
  const firstThursdayOfYear = startOfYear.day() <= 4 ? startOfYear.day(4) : startOfYear.day(11);
  const dayOfYear = date.diff(firstThursdayOfYear, 'day') + 1;
  let weekNumber = Math.ceil(dayOfYear / 7);
  if (weekNumber === 0) {
    weekNumber = isoWeek(date.subtract(1, 'year'));
  }
  if (weekNumber > 52) {
    weekNumber = 1;
  }
  return weekNumber;
};

function WeekButton({ newSearchParams }: any) {
  const [urlParams, setSearchParams] = useSearchParams();
  const times = ['4 Weeks', '8 Weeks', '10 Weeks', '12 Weeks'];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedTime, setSelectedTime] = React.useState('');
  const open = Boolean(anchorEl);
  const [showCustomMonths, setShowCustomMonth] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const weekOfYear = (date: dayjs.Dayjs) => {
    const startOfYear = date.startOf('year');
    const weekNumber = Math.ceil(date.diff(startOfYear, 'week', true)) + 1;
    return weekNumber;
  };

  const handleMenuItemClick = (time: string, isFirstRender?: boolean) => {
    setSelectedTime(time);

    let durationInWeeks = 0;
    switch (time) {
      case '4 Weeks':
        durationInWeeks = 4;
        break;
      case '8 Weeks':
        durationInWeeks = 8;
        break;
      case '10 Weeks':
        durationInWeeks = 10;
        break;
      case '12 Weeks':
        durationInWeeks = 12;
        break;
      default:
        durationInWeeks = 4;
    }

    const endDate = dayjs().startOf('week');
    const startDate = endDate.subtract(durationInWeeks - 1, 'week');

    const selectedWeeks = [];
    let currentDate = endDate;

    while (currentDate.isAfter(startDate, 'day') || currentDate.isSame(startDate, 'day')) {
      const weekNumber = weekOfYear(currentDate);
      const year = currentDate.year();
      selectedWeeks.unshift(`W${weekNumber}-${year}`);
      currentDate = currentDate.subtract(1, 'week');
    }

    newSearchParams.delete('months');
    newSearchParams.delete('offset');
    newSearchParams.delete('page_number');
    newSearchParams.delete('isMonthsCustom');
    newSearchParams.delete('isWeeksCustom');
    newSearchParams.set('page_number', '1');
    if (isFirstRender) {
      newSearchParams.set('revenue_sort', 'desc');
    }
    newSearchParams.set(
      'weeks',
      JSON.stringify(
        selectedWeeks.map((item) => {
          let weekNumber = item.split('-')?.[0];
          let year = item.split('-')?.[1]?.toLowerCase();
          return `${year}-${weekNumber}`;
        })
      )
    );
    newSearchParams.set('isWeek', true);
    setSearchParams(newSearchParams);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  React.useEffect(() => {
    const weeks = urlParams.get('weeks');
    if (weeks) {
      const parsedWeeks = JSON.parse(weeks);
      if (parsedWeeks.length > 0) {
        const duration = parsedWeeks.length;
        const durationInWeeks = `${duration} Weeks`;
        setSelectedTime(durationInWeeks);
      } else {
        setSelectedTime('Custom');
      }
    } else {
      handleMenuItemClick('10 Weeks', true);
    }
  }, []);
  const isCustom: boolean = urlParams?.get('isWeeksCustom') ? true : false;
  return (
    <>
      <button
        className={`flex items-center ${
          urlParams?.get('weeks') && 'border-primary'
        } border-[0.1rem] px-3 py-1 rounded-full w-[7rem] justify-between hover:bg-primary-light hover:text-primary-dark transition-all capatilize`}
        style={{
          backgroundColor: `${
            urlParams?.get('weeks') ? `rgb(232,239,248)` : 'rgba(193, 193, 193, 0.12)'
          }`,
        }}
        value={selectedTime}
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <p
          className={` ${
            urlParams?.get('weeks') ? `text-primary` : 'text-[#C9C9C9]'
          } font-normal text-[0.9rem] font-bold capatalize`}>
          {isCustom ? 'Custom' : <>{urlParams?.get('isWeek') ? selectedTime : '10 Weeks'}</>}
        </p>
        <i
          className={`pi pi-angle-${
            open ? 'up' : 'down'
          } transition-transform duration-300 transform ml-2 ${
            urlParams?.get('weeks') ? `text-primary` : 'text-[#C9C9C9]'
          }`}></i>
      </button>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          sx: {
            width: '7rem',
            marginTop: '0.5rem',
          },
        }}>
        {times.map((item) => (
          <MenuItem
            key={item}
            onClick={() => handleMenuItemClick(item)}
            selected={isCustom ? false : selectedTime === item}>
            {item}
          </MenuItem>
        ))}
        <MenuItem
          // key={item}

          onClick={() => {
            setShowCustomMonth(true);
            handleClose();
          }}
          selected={isCustom}
          className='text-primary'>
          Select Week
        </MenuItem>
      </Menu>
      {showCustomMonths && (
        <AddCustomWeek
          newSearchParams={newSearchParams}
          handleClose={() => {
            setShowCustomMonth(false);
          }}
        />
      )}
    </>
  );
}
export const generateMonthRange = (start: string, end: string) => {
  const startDate = dayjs(start, 'YYYY-MM');
  const endDate = dayjs(end, 'YYYY-MM');
  const months: string[] = [];

  let currentDate = endDate;
  while (currentDate.isAfter(startDate) || currentDate.isSame(startDate, 'month')) {
    months.unshift(currentDate.format('YYYY-MM'));
    currentDate = currentDate.subtract(1, 'month');
  }

  return months;
};
function MonthsButton({ newSearchParams }: any) {
  const [urlParams, setSearchParams] = useSearchParams();
  const times = ['2 Months', '4 Months', '6 Months', '8 Months', '10 Months', '12 Months'];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedTime, setSelectedTime] = React.useState(''); // Default to 4 months
  const open = Boolean(anchorEl);
  const [_, setMonths] = React.useState<string[]>([]);
  const [showCustomMonths, setShowCustomMonth] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (time: string) => {
    setSelectedTime(time);

    const now = dayjs();
    let durationInMonths = 0;
    switch (time) {
      case '2 Months':
        durationInMonths = 2;
        break;
      case '4 Months':
        durationInMonths = 4;
        break;
      case '6 Months':
        durationInMonths = 6;
        break;
      case '8 Months':
        durationInMonths = 8;
        break;
      case '10 Months':
        durationInMonths = 10;
        break;
      case '12 Months':
        durationInMonths = 12;
        break;
      default:
        durationInMonths = 4; // Default to 4 months if none matches
    }

    const endDate = now.format('YYYY-MM'); // Current month and year
    const startDate = now.subtract(durationInMonths - 1, 'month').format('YYYY-MM'); // Calculate start month and year

    const generatedMonths = generateMonthRange(startDate, endDate);
    setMonths(generatedMonths);
    newSearchParams.delete('weeks');
    newSearchParams.delete('page_number');
    newSearchParams.delete('isWeek');
    newSearchParams.delete('offset');
    newSearchParams.delete('isWeeksCustom');
    newSearchParams.delete('isMonthsCustom');
    newSearchParams.set('page_number', '1');
    newSearchParams.set('months', JSON.stringify(generatedMonths));
    setSearchParams(newSearchParams);
    handleClose();
  };

  const isCustom: boolean = urlParams?.get('isMonthsCustom') ? true : false;

  React.useEffect(() => {
    const monthsParam = urlParams.get('months');
    if (monthsParam) {
      const parsedMonths = JSON.parse(monthsParam);
      const duration = parsedMonths.length;
      const durationInMonths = `${duration} Months`;
      setSelectedTime(durationInMonths);
    } else {
      setSelectedTime('Custom');
    }
  }, []);
  return (
    <>
      <button
        className={`flex items-center ${
          urlParams?.get('months') && 'border-primary'
        } border-[0.1rem] px-3 py-1 rounded-full w-[8rem] justify-between hover:bg-primary-light hover:text-primary-dark transition-all  capatilize`}
        style={{
          backgroundColor: `${
            urlParams?.get('months') ? `rgb(232,239,248)` : 'rgba(193, 193, 193, 0.12)'
          }`,
        }}
        value={selectedTime}
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <p
          className={` ${
            urlParams?.get('months') ? `text-primary` : 'text-[#C9C9C9]'
          } font-normal text-[0.9rem] font-bold`}>
          {isCustom ? 'Custom' : <>{urlParams?.get('months') ? selectedTime : '4 Months'}</>}
        </p>

        <i
          className={`pi pi-angle-${
            open ? 'up' : 'down'
          } transition-transform duration-300 transform ml-2 ${
            urlParams?.get('months') ? `text-primary` : 'text-[#C9C9C9]'
          }`}></i>
      </button>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          sx: {
            width: '8rem',
            marginTop: '0.5rem',
          },
        }}>
        {times.map((item) => (
          <MenuItem
            key={item}
            onClick={() => handleMenuItemClick(item)}
            selected={isCustom ? false : selectedTime === item}>
            {item}
          </MenuItem>
        ))}
        <MenuItem
          onClick={() => {
            setShowCustomMonth(true);
            handleClose();
          }}
          selected={isCustom}
          className='text-primary'>
          Select Month
        </MenuItem>
      </Menu>
      {showCustomMonths && (
        <AddCustom
          newSearchParams={newSearchParams}
          handleClose={() => {
            setShowCustomMonth(false);
          }}
        />
      )}
    </>
  );
}
