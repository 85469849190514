import moment from 'moment-timezone';

export function getFormattedDateTable(createdAt: any) {
  const today = moment();
  const appointmentDate = moment.utc(createdAt).format('MMM Do YY');
  const todayDate = today.format('MMM Do YY');
  const previousDate = today.clone().subtract(1, 'day').format('MMM Do YY');
  const nextDate = today.clone().add(1, 'day').format('MMM Do YY');
  if (appointmentDate === todayDate) {
    return 'Today';
  } else if (appointmentDate === previousDate) {
    return 'Yesterday';
  } else if (appointmentDate === nextDate) {
    return 'Tomorrow';
  } else {
    return appointmentDate;
  }
}

export function getFormattedDate(createdAt: any) {
  const today = moment.tz(moment(), 'local');
  const messageDate = moment.utc(createdAt);
  if (messageDate.isSame(today, 'day')) {
    return 'Today';
  } else if (messageDate.isSame(today.clone().subtract(1, 'day'), 'day')) {
    return 'Yesterday';
  } else if (messageDate.isSame(today.clone().add(1, 'day'), 'day')) {
    return 'Tomorrow';
  } else {
    return moment(createdAt).format('LL');
  }
}
