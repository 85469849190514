import Tooltip from '@mui/material/Tooltip';

export const GetMilesTillInspection = (item: any) => {
  const miles = Number(item?.miles?.toString()?.replaceAll(/,/gi, ''));
  const curr_miles = Number(item?.curr_miles?.toString()?.replaceAll(/,/gi, ''));

  if (curr_miles > miles + 5000) {
    return <span className={`text-rose-300 ml-1`}>{curr_miles - (miles + 5000)}m</span>;
  }

  if (curr_miles >= miles + 3000 && curr_miles <= miles + 5000) {
    return <span className={`text-amber-400 ml-1`}>{miles + 5000 - curr_miles}m</span>;
  }

  return (
    <Tooltip
      arrow
      title={
        <p className='text-center'>
          No value falls in the <span className={`text-amber-400 font-bold`}> In-due</span> and
          <span className={`text-rose-300 font-bold`}> Overdue</span> as per the Last Preventive and
          Current Mileage
        </p>
      }>
      <p className=''>{'----'}</p>
    </Tooltip>
  );
};
