import { useEffect, useState } from 'react';
import Map from './Map';

function App({
  defaultCoordinates,
  saveLocationData,
}: {
  defaultCoordinates: any;
  saveLocationData: any;
}) {
  const [selectedLocation, setSelectedLocation] = useState<any | null>(null);
  const handleLocationSelect = (coordinates: any) => {
    setSelectedLocation(coordinates);
  };

  useEffect(() => {
    setSelectedLocation(defaultCoordinates);
  }, [defaultCoordinates]);
  return selectedLocation ? (
    <Map
      coordinates={selectedLocation}
      onLocationSelect={handleLocationSelect}
      onLocationInfo={saveLocationData}
    />
  ) : (
    <div className='text-md font-bold flex text-center  mt-5'>
      Not a valid location please input valid address
    </div>
  );
}

export default App;
