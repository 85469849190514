import { Button, Checkbox } from '@mui/material';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { toast } from '../../../../../components/Toast/CustomToast';
import { actionCreators } from '../../../../../state';
import { RootState } from '../../../../../state/reducers';
import useFetchSingleRequest from '../../../../hooks/csr/useFetchSingleRequest';

const SelectVehicle = ({
  current,
  selected,
  setSelected,
}: {
  current: any;
  selected: number[];
  setSelected: (number: any) => void;
}) => {
  return (
    <div className='flex gap-2 justify-start w-full items-center'>
      <Checkbox
        // style={{
        //   height: '0.8rem',
        //   width: '0.8rem',
        // }}
        size="small"
        className='p-0'
        checked={selected.includes(+current.id)}
        onClick={() => {
          const found = selected?.find((d: any) => +d === +current.id);
          if (found) {
            setSelected(selected?.filter((d: any) => +d !== +current.id));
          } else {
            setSelected([...selected, +current.id]);
          }
        }}
      />
      <p className='text-primary'>{current?.vehicle?.name}</p>
    </div>
  );
};

function SelectMoveToComplete() {
  const dispatch = useDispatch();
  const [selectVehicles, setSelectVehicle] = React.useState<any>([]);
  const { bulkRequestDetails }: any = useSelector((state: RootState) => state.userState);
  const { setCompleteData } = bindActionCreators(actionCreators, dispatch);
  const {checkIfReadyToMove}=useFetchSingleRequest()


  const navigate = useNavigate();

  const filteredData = useMemo(() => {
    return bulkRequestDetails?.bulkSchedules?.filter((d: any) => [9, 11].includes(+d.status_id));
  }, [bulkRequestDetails]);

  return (
    <form
      className='flex flex-col items-center p-2 gap-3'
      onSubmit={async (e) => {
        e.preventDefault();
        const finalSelected = filteredData.filter((d: any) => selectVehicles.includes(+d?.id));
        if (finalSelected?.length > 0) {
          const is_ready = await checkIfReadyToMove(finalSelected.map((d: any) => d.id));
          if(is_ready){
          navigate('/moveToComplete');
          setCompleteData({
            selected: finalSelected[0],
            bulkSchedules: finalSelected,
          });
        }
        } else {
          toast?.info('Please select vehicles to proceed');
        }
      }}>
      <h1 className=''>Select vehicle(s) you wish to move</h1>
      <div className='flex flex-col overflow-auto items-start justify-start w-full max-h-[15rem] gap-2'>
        {filteredData?.map((d: any) => (
          <SelectVehicle
            key={d?.id}
            current={d}
            selected={selectVehicles}
            setSelected={setSelectVehicle}
          />
        ))}
      </div>
      <Button type='submit' variant='contained' className='px-6 py-1'>
        Proceed
      </Button>
    </form>
  );
}

export default SelectMoveToComplete;
