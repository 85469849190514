import { useEffect, useState } from 'react';
import GoBackButton from '../../../../../../assets/GoBackButton';
import InvoiceTemplateDSPNew from '../../invoiceTemplate/InvoiceTemplateDSP';
import { ThreeDots } from 'react-loader-spinner';
import DownloadDSPInvoice from './DownloadDSPInvoice';
const ShowInvoicesDSP = ({
  services,
  GoBack,
  handelNextButtonvendor,
  dspStatus,
  item,
  invoices,
  invoiceDetails,
  fetchInvoiceDetails,
  invoiceLoader,
  vendorStatus,
}: {
  services: any;
  GoBack: any;
  handelNextButtonvendor: any;
  dspStatus: any;
  vendorStatus?: any;
  item: any;
  invoices: any;
  invoiceDetails?: any;
  fetchInvoiceDetails?: any;
  invoiceLoader: boolean;
}): any => {
  const dspInvoices: any = services;
  const [selectedInvoice, setSelectedInvoice] = useState(null as any);
  useEffect(() => {
    setSelectedInvoice(services?.[0]);
  }, [services]);
  useEffect(() => {
    if (!selectedInvoice?.id) {
      return;
    } else {
      fetchInvoiceDetails(String(selectedInvoice?.id));
    }
  }, [selectedInvoice?.id]);
  return (
    <div className='flex-1 flex flex-col h-0 gap-5'>
      <div className='flex-1 flex h-0 '>
        <div className='flex justify-between flex-1'>
          <div className='w-full flex justify-between flex-1 h-full'>
            <div className='w-[20rem]  items-center h-full'>
              <div className='flex flex-col gap-2 h-full overflow-auto pr-2'>
                {dspInvoices?.map((ele: any, index: React.Key | null | undefined) => (
                  <button
                    className={`p-3 mb-3 rounded-[0.5rem] capitalize  text-left ${
                      ele?.id === selectedInvoice?.id && 'bg-primary-light'
                    }
                      hover:bg-primary-light
                      `}
                    onClick={() => setSelectedInvoice(ele)}
                    key={index}>
                    {ele?.service_name || ele?.service_detail?.service_name}
                  </button>
                ))}
              </div>
            </div>
            <div className='w-0 flex-1 items-center h-full'>
              {invoiceLoader ? (
                <div className='w-[45rem] h-[30rem] flex justify-center items-center'>
                  <ThreeDots
                    height='30'
                    width='50'
                    radius='9'
                    color={'#4378C8'}
                    ariaLabel='three-dots-loading'
                    wrapperStyle={{}}
                    visible={true}
                  />
                </div>
              ) : (
                <InvoiceTemplateDSPNew
                  invoiceDetails={invoiceDetails}
                  item={item}
                  invoices={invoices}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='Below Contanier flex justify-between'>
        <div>
          {['generated'].includes(item?.dsp_invoice_status) && (
            <button
              className='py-3 px-7 rounded-[0.7rem] border-[#4378C8] text-[#4378C8] flex justify-between items-center text-600'
              style={{ fontWeight: 'bold' }}
              onClick={GoBack}>
              <GoBackButton />
              <p className='ml-3'>Go Back</p>
            </button>
          )}
          {['sent', 'updated'].includes(item?.dsp_invoice_status) &&
            !['amount_submitted', 'amount_submitted_by_csr'].includes(
              item?.vendor_invoice_status
            ) && (
              <button
                className='py-3 px-7 rounded-[0.7rem] border-[#4378C8] text-[#4378C8] flex justify-between items-center text-600'
                style={{ fontWeight: 'bold' }}
                onClick={GoBack}>
                <i className='pi pi-pencil'></i>
                <p className='ml-3'>Edit Invoice</p>
              </button>
            )}
        </div>
        <div className='flex justify-between'>
          <DownloadDSPInvoice selectedInvoice={selectedInvoice} invoices={services} />
          {['generated'].includes(item?.dsp_invoice_status) && (
            <button
              className='py-3 px-20 ml-5 rounded-[0.7rem] border-[#4378C8] bg-[#4378C8] text-[#FFF] text-bold'
              style={{ fontWeight: 'bold' }}
              onClick={handelNextButtonvendor}>
              Send Invoice
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
export default ShowInvoicesDSP;
