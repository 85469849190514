import { Divider, IconButton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useMemo, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components';
import Tootltip from '../../../../components/BarData/Tootltip';
const StyleDiv = styled.div.attrs((props: { width: string }) => props)`
& > div {
  width: ${(props) => props.width};
}
}
`;

function ScrollDIv(props: any) {
  const { value, itemCount, selectedVehcile } = props;
  const [scrollPos, setScrollPos] = useState(0 as number);
  const theme: any = useTheme();
  const matches = useMediaQuery('(max-width:1400px)');
  useEffect(() => {
    setScrollPos(0);
  }, [matches]);
  const handleClick = (type: number) => {
    if (type === 1) {
      setScrollPos((prev) => prev + 1);
    }
    if (type === -1) {
      setScrollPos((prev) => prev - 1);
    }
  };
  const { arrObj, All }: any = useMemo(() => {
    let temp: any = {};
    let i = 0;
    let intialpos = 0;
    while (i < value.length) {
      let tempArr: any = [];
      for (let j = 0; j < itemCount; j++) {
        if (i < value.length) {
          tempArr.push(
            <div className='flex items-center w-full'>
              <div
                key={i}
                className='rounded-xl p-2 w-0 flex-1 mx-1'
                style={{
                  backgroundColor:
                    selectedVehcile?.preventive_request_id === value[i].preventive_request_id
                      ? `${theme.palette.primary.main}`
                      : '',
                  cursor: 'pointer',
                  display: 'flex',
                }}
                onClick={value[i].onClick}>
                <Tootltip placement={"bottom"}>
                  <p
                    className='text-ellipsis whitespace-nowrap w-full overflow-hidden'
                    style={{
                      textAlign: 'center',
                      margin: 'auto',
                      color:
                        selectedVehcile?.preventive_request_id === value[i].preventive_request_id
                          ? '#fff'
                          : '#000',
                    }}>
                    {value[i].vehicle.name}
                  </p>
                </Tootltip>
              </div>
              {
                j!==itemCount-1 && <Divider orientation='vertical' sx={{ borderWidth: '1px', height: '2rem' }} />
              }
            </div>
          );
        }
        i++;
      }
      temp[intialpos] = (
        <StyleDiv
          style={{
            display: 'grid',
            width: '100%',
            gridTemplateColumns: `repeat(${itemCount}, minmax(0, 1fr))`,
          }}>
          {tempArr}
        </StyleDiv>
      );
      intialpos++;
    }
    return { arrObj: temp, All: intialpos };
  }, [value]);

  const handleChangeIndex = (index: number) => {
    setScrollPos(index);
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        border: '1px solid #E6E6E6',
      }}
      className='bg-barColor rounded-xl py-1'>
      {
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
          }}>
          {scrollPos > 0 && (
            <div className='flex text-[#A0A0A0] items-center p-2 gap-1 font-bold cursor-pointer' onClick={() => handleClick(-1)}>
              <IconButton
                
                sx={{
                  background: 'transparent',
                  padding: '0 !important',
                }}
                disableRipple
                disableFocusRipple>
                <i className='pi pi-angle-left text-xl text-[#A0A0A0]'></i>
              </IconButton>
              +{itemCount * scrollPos}
            </div>
          )}
          <div
            className='w-0 flex-1 '
            style={{
              width: 0,
              flex: 1,
            }}>
            <SwipeableViews
              index={scrollPos}
              onChangeIndex={handleChangeIndex}
              style={{ margin: value.length === 0 ? 'auto' : 0 }}>
              {new Array(All).fill(0).map((a, index) => arrObj[scrollPos])}
            </SwipeableViews>
            {value.length === 0 && (
              <div className='flex w-full h-full'>
                <p className='pl-7 text-gray-400 my-auto'>No {props.dataName} Assigned</p>
              </div>
            )}
          </div>
          {value.length - (itemCount * scrollPos + itemCount) > 0 && <div className='flex text-[#A0A0A0] items-center p-2 gap-1 font-bold cursor-pointer'  onClick={() => handleClick(1)}>
            {value.length - (itemCount * scrollPos + itemCount) > 0
              ? `+${value.length - (itemCount * scrollPos + itemCount)}`
              : null}
            <IconButton
             
              sx={{
                visibility:
                  itemCount * scrollPos + itemCount < value.length
                    ? 'visibilty !important'
                    : 'hidden !important',
                background: 'transparent',
                padding: '0 !important',
              }}
              disableRipple
              disableFocusRipple>
              <i className='pi pi-angle-right text-xl text-[#A0A0A0]'></i>
            </IconButton>
          </div>}
        </div>
      }
    </div>
  );
}

export default ScrollDIv;
