/* eslint-disable import/no-anonymous-default-export */
export const RELEASE_VERSION = '1.0.0';

export const _Environments: { [key: string]: any } = {
  production: {
    env: 'production',
    baseUrl: 'https://production-api.fleet.lmdmax.com/',
    messagingUrl: 'https://production-messaging.fleet.lmdmax.com',
    release: RELEASE_VERSION,
  },
  staging: {
    env: 'staging',
    baseUrl: 'https://staging-api.fleet.lmdmax.com/',
    messagingUrl: 'https://staging-messaging.fleet.lmdmax.com',
    release: RELEASE_VERSION,
    logs: true,
  },
  development: {
    env: 'development',
    baseUrl: 'https://development-api.fleet.lmdmax.com/',
    messagingUrl: 'https://development-messaging.fleet.lmdmax.com',
    release: RELEASE_VERSION,
    logs: true,
  },
  local: {
    env: 'local',
    // baseUrl: 'http://localhost:3002',
    // baseUrl: 'https://staging-api.fleet.lmdmax.com/',
    baseUrl: 'https://development-api.fleet.lmdmax.com/',
    // messageingUrl: 'http://localhost:3002',
    messagingUrl: 'https://development-messaging.fleet.lmdmax.com',
    // messagingUrl: 'https://staging-messaging.fleet.lmdmax.com',
    // messagingUrl: 'http://localhost:3002',
    release: RELEASE_VERSION,
    logs: true,
  },
};

function getEnvironment() {
  let env;
  if (window.location.href.includes('localhost')) {
    env = 'local';
  } else if (window.location.href.includes('development')) {
    env = 'development';
  } else if (window.location.href.includes('staging')) {
    env = 'staging';
  } else {
    env = 'production';
  }
  return _Environments[env];
}

const Environment = getEnvironment();
export { Environment };
