import ModalForm from '../../../components/ModalForm';
import OverlayContainer from '../../../components/OverlayContainer';
import { ViewCheckType } from '../types';

function ViewCheckList({ checkedItems, handleView }: ViewCheckType) {
  const handleClose = () => {
    handleView({});
  };

  if (!checkedItems) return null;

  return (
    <OverlayContainer show={true}>
      <ModalForm onClose={handleClose} width={'w-[40vw]'} noLabel>
        <div className='flex flex-col gap-4 h-[32rem]'>
          <p className='text-lg'>Services</p>
          <div className='p-2 grid grid-cols-2 gap-y-5 gap-x-32 overflow-y-auto'>
            {checkedItems.length === 0 && <p>No Services available</p>}
            {checkedItems.length > 0 &&
              checkedItems.map((item: any) => (
                <div
                  className='px-2 pt-1 border border-solid border-slate-200 flex items-center rounded-lg cursor-pointer'
                  key={item.id}>
                  <p className='p-3'>{item?.name || 'N/A'}</p>
                </div>
              ))}
          </div>
        </div>
      </ModalForm>
    </OverlayContainer>
  );
}

export default ViewCheckList;
