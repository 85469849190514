import { Button } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import PriorityIcons from '../csr/scheduleRequest/components/PriorityIcons';
import { useSearchParams } from 'react-router-dom';

export default function PriorityDropDown() {
  const [urlParams, setSearchParams]: any = useSearchParams();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selection, setSelection] = React.useState('');
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClearAll = () => {
    urlParams?.delete('priority');
    urlParams?.set('offset', '1');
    setSearchParams(urlParams);
    handleClose();
  };
  const onChange = (ele?: any) => {
    if (ele === 'All') {
      handleClearAll();
    } else {
      urlParams?.set('priority', ele);
      urlParams?.set('offset', '1');
      setSearchParams(urlParams);
      handleClose();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const selectedPriority = urlParams?.get('priority') || 'All';
  const priorities = ['All', '1', '2'];
  React.useEffect(() => {
    if (anchorEl !== null) {
      setSelection(selectedPriority);
    }
  }, [anchorEl, urlParams]);
  return (
    <>
      <Button
        className='flex justify-between items-center rounded-[0.7rem] px-3 py-2 rounded-full w-[10rem] justify-between transition-all hover:shadow-md bg-primary-light h-[2.5rem]'
        value={selectedPriority}
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <p className='w-[70%] text-primary flex items-center gap-1 text-[1.1rem] font-bold'>
          Priority{' '}
          <i
            className={`pi pi-angle-${
              open ? 'up' : 'down'
            } transition-transform duration-300 transform ml-2  text-primary `}></i>
        </p>
        <div className='w-[0.1rem] h-full bg-white'></div>
        <p className='w-[29%]  text-black flex items-center justify-center gap-1 text-[1.1rem]'>
          {['1', '2']?.includes(urlParams.get('priority')) && '# '}
          {selectedPriority || 'All'}
        </p>
      </Button>
      <Menu
        id='basic-menu'
        className='z-0'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          sx: {
            width: '14rem',
            marginTop: '0.5rem',
          },
        }}>
        {priorities.map((ele) => {
          return (
            <MenuItem
              key={ele}
              onClick={() => onChange(ele)}
              selected={selection === ele}
              className='flex gap-3 text-[1.1rem]'>
              {ele === 'All' && <div className='w-[2rem] h-[2rem]'></div>}
              <PriorityIcons iconType={ele || '2'} /> # {ele}{' '}
              {['1', '2']?.includes(ele) && 'Priority '}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
