const SVG = (props: { height?: string; width?: string; active?: boolean }) => {
    const height = props?.height || '5rem';
    const width = props?.width || '5rem';
    return (
        <svg 
         width={width}
         height={height}
         viewBox="0 0 94 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group 40940">
        <path id="Vector" d="M31.2047 18.217C30.2707 18.217 29.6114 18.9303 29.4466 19.9728H32.743C32.7979 18.8754 32.1936 18.217 31.2047 18.217ZM34.9405 21.5091H29.4466C29.4466 23.1552 30.2707 23.9782 31.9189 23.9782C32.7979 23.9782 33.7319 23.7587 34.501 23.4295L34.7208 25.1853C33.7868 25.5694 32.6331 25.734 31.5343 25.734C28.7324 25.734 27.1392 24.3074 27.1392 21.1799C27.1392 18.4913 28.6225 16.4612 31.3145 16.4612C33.8967 16.4612 35.0504 18.217 35.0504 20.4117C35.0504 20.7958 34.9955 21.125 34.9405 21.5091Z" fill="#192E44"/>
        <path id="Vector_2" d="M41.3142 24.1431V25.5697H36.04V24.1431C36.4796 24.0333 37.0839 23.9785 37.5783 23.9236V14.3215C37.1388 14.2667 36.5345 14.1569 36.04 14.1569V12.7303C37.1938 12.5657 38.5672 12.5109 39.8858 12.5657V23.9785C40.3802 23.9785 40.8747 24.0882 41.3142 24.1431Z" fill="#192E44"/>
        <path id="Vector_3" d="M46.2032 18.217C45.2692 18.217 44.61 18.9303 44.4451 19.9728H47.7415C47.7415 18.8754 47.1372 18.217 46.2032 18.217ZM49.9391 21.5091H44.4451C44.4451 23.1552 45.2692 23.9782 46.9174 23.9782C47.7964 23.9782 48.7304 23.7587 49.4996 23.4295L49.7193 25.1853C48.7853 25.5694 47.6316 25.734 46.5328 25.734C43.7309 25.734 42.1377 24.3074 42.1377 21.1799C42.1377 18.4913 43.6211 16.4612 46.3131 16.4612C48.8952 16.4612 50.0489 18.217 50.0489 20.4117C49.994 20.7958 49.994 21.125 49.9391 21.5091Z" fill="#192E44"/>
        <path id="Vector_4" d="M63.3989 19.9728C63.3989 19.04 63.1792 18.4365 62.3551 18.4365C61.3662 18.4365 60.3223 19.5887 60.3223 21.3445V23.9233C60.7618 23.9782 61.2563 24.0331 61.6409 24.1428V25.6243H58.0698V19.9728C58.0698 19.0949 57.905 18.4365 57.026 18.4365C55.9821 18.4365 54.9932 19.6984 54.9932 21.3445V23.9233C55.4327 23.9782 55.9272 24.0331 56.3118 24.1428V25.6243H51.1475V24.1977C51.587 24.0879 52.1913 24.0331 52.6858 23.9782V18.4913C52.2462 18.3816 51.6419 18.3267 51.1475 18.2719V16.8453C52.2462 16.6807 53.6747 16.6258 54.8284 16.6807C54.8284 17.0647 54.7735 17.778 54.7185 18.2719C55.2679 17.1745 56.3118 16.4612 57.6303 16.4612C59.2235 16.4612 59.8828 17.394 60.1026 18.2719C60.4871 17.394 61.476 16.4612 63.0143 16.4612C64.7175 16.4612 65.7064 17.394 65.7064 19.4241V23.8685C66.2008 23.9233 66.7502 23.9782 67.1348 24.0879V25.5145H63.3989V19.9728Z" fill="#192E44"/>
        <path id="Vector_5" d="M72.189 18.217C71.2551 18.217 70.5958 18.9303 70.431 19.9728H73.7273C73.7273 18.8754 73.123 18.217 72.189 18.217ZM75.9249 21.5091H70.431C70.431 23.1552 71.2551 23.9782 72.9033 23.9782C73.7823 23.9782 74.7162 23.7587 75.4854 23.4295L75.7051 25.1853C74.7712 25.5694 73.6175 25.734 72.5187 25.734C69.7168 25.734 68.1235 24.3074 68.1235 21.1799C68.1235 18.4913 69.6069 16.4612 72.2989 16.4612C74.8811 16.4612 76.0348 18.217 76.0348 20.4117C75.9798 20.7958 75.9799 21.125 75.9249 21.5091Z" fill="#192E44"/>
        <path id="Vector_6" d="M84.112 19.9175C84.112 18.9847 83.8374 18.436 83.0133 18.436C81.8595 18.436 80.9256 19.7529 80.9256 21.2892V23.9229C81.3651 23.9778 81.8595 24.0327 82.2441 24.1424V25.6238H77.1348V24.1973C77.5743 24.0875 78.1786 24.0327 78.6731 23.9778V18.4909C78.2336 18.436 77.6292 18.3263 77.1348 18.3263V16.8997C78.2336 16.7351 79.662 16.6802 80.8157 16.7351C80.8157 17.1192 80.7608 17.8873 80.7058 18.3263C81.2552 17.2838 82.2991 16.5156 83.7275 16.5156C85.7053 16.5156 86.3646 17.7776 86.3646 19.3688V23.9229C86.859 23.9778 87.4084 24.0327 87.8479 24.1424V25.569H84.112V19.9175Z" fill="#192E44"/>
        <path id="Vector_7" d="M92.1322 25.79C90.1543 25.79 89.55 25.0767 89.55 22.9917V18.4924H88.0117V16.7366H89.55V13.9932L91.8575 13.3896V16.7366H94.0001V18.4924H91.8575V22.3881C91.8575 23.5404 92.1322 23.8696 92.9013 23.8696C93.2859 23.8696 93.6705 23.8147 94.0001 23.705V25.5705C93.4507 25.6803 92.7914 25.79 92.1322 25.79Z" fill="#192E44"/>
        <path id="Vector_8" d="M7.85579 11.303C5.16377 11.303 2.9662 9.10822 2.9662 6.41965C2.9662 3.73108 5.16377 1.53633 7.85579 1.53633C10.5478 1.53633 12.7454 3.73108 12.7454 6.41965C12.6904 9.10822 10.5478 11.303 7.85579 11.303ZM11.5917 0H4.01005L0.219238 6.52939L4.01005 13.0588H11.5917L15.3825 6.52939L11.5917 0Z" fill="#82C341"/>
        <path id="Vector_9" d="M20.6556 18.4912C17.9636 18.4912 15.766 16.2965 15.766 13.6079C15.766 10.9193 17.9636 8.72456 20.6556 8.72456C23.3476 8.72456 25.5452 10.9193 25.5452 13.6079C25.5452 16.2965 23.3476 18.4912 20.6556 18.4912ZM24.3915 7.18823H16.8099L13.019 13.7176L16.8099 20.247H24.3915L28.1823 13.7176L24.3915 7.18823Z" fill="#82C341"/>
        <path id="Vector_10" d="M7.63656 26.008C4.94453 26.008 2.74696 23.8132 2.74696 21.1247C2.74696 18.4361 4.94453 16.2413 7.63656 16.2413C10.3286 16.2413 12.5261 18.4361 12.5261 21.1247C12.5261 23.8132 10.3286 26.008 7.63656 26.008ZM11.3724 14.6501H3.79081L0 21.1795L3.79081 27.7089H11.3724L15.1632 21.1795L11.3724 14.6501Z" fill="#82C341"/>
        <path id="Vector_11" d="M20.4923 33.0872C17.8002 33.0872 15.6027 30.8924 15.6027 28.2038C15.6027 25.5153 17.8002 23.3205 20.4923 23.3205C23.1843 23.3205 25.3819 25.5153 25.3819 28.2038C25.3819 30.8924 23.1843 33.0872 20.4923 33.0872ZM24.2831 21.7842H16.7015L12.9106 28.3136L16.7015 34.843H24.2831L28.0739 28.3136L24.2831 21.7842Z" fill="#82C341"/>
        </g>
        </svg>
    );
  };
  export default SVG;