import { Dispatch } from 'react';
import { UserModel } from '../../models/UserModel';
import { ActionType } from '../action-types';
import { Action } from '../actions';

export const setUser = (user: UserModel) => {
  localStorage.setItem('user', JSON.stringify(user));
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_USER,
      payload: user,
    });
  };
};

export const deleteUsers = () => {
  localStorage.removeItem('user');
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.DELETE_USER,
    });
  };
};

export const handleLoader = (loading: boolean) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.USER_LOADER,
      payload: loading,
    });
  };
};

export const setScreenType = (type: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: ActionType.SET_VIEW_SCREEN,
      payload: type,
    });
  };
};

export const setClients = (clients: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_CLIENTS,
      payload: clients,
    });
  };
};

export const setCsrClients = (clients: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: ActionType.SET_CSR_CLIENTS,
      payload: clients,
    });
  };
};

export const setVendorTechnician = (technician: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: ActionType.SET_VENDOR_TECHNICIAN,
      payload: technician,
    });
  };
};

export const setCsrData = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_CSR_DATA,
      payload: data,
    });
  };
};

export const setCsrDataInfo = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_CSR_DATA_INFO,
      payload: data,
    });
  };
};
export const setVendorDispatch = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_VENDOR_DATA,
      payload: data,
    });
  };
};
export const setVendorTechnicianData = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_VENDOR_ASSIGN_TECHNICIAN_DATA,
      payload: data,
    });
  };
};
export const setDspVendorData = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_DSP_ASSIGN_VENDOR_DATA,
      payload: data,
    });
  };
};

export const setBulkVehicles = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_BULK_VEHICLES,
      payload: data,
    });
  };
};

export const setAddVendorPageData = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_ADD_VENDOR_PAGE_DATA,
      payload: data,
    });
  };
};

export const redirectRequest = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_REDIRECT_REQUEST,
      payload: data,
    });
  };
};

export const setBulkAddService = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_ADD_SERVICE_BULK,
      payload: data,
    });
  };
};

export const setVendorDataInfo = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_VENDOR_DATA_INFO,
      payload: data,
    });
  };
};

export const setServicesInfo = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_SERVICES_INFO,
      payload: data,
    });
  };
};

export const setSelectedChat = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_SELECTED_CHAT,
      payload: data,
    });
  };
};

export const setChatListType = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_CHATLIST_TYPE,
      payload: data,
    });
  };
};
export const setcatagoryChats = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_CATAGORY_CHAT,
      payload: data,
    });
  };
};

export const setProfileDetails = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_PROFILE_DETAILS,
      payload: data,
    });
  };
};
export const setAppointment = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_APPOINTMENT_TIME,
      payload: data,
    });
  };
};

export const setSocketInstance = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_SOCKET_INSTANCE,
      payload: data,
    });
  };
};
export const setFetchData = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_DATA,
      payload: data,
    });
  };
};
export const setCompleteData = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_COMPLETE_DATA,
      payload: data,
    });
  };
};

export const setGroupUsersDetails = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_GROUP_USERS_DETAILS,
      payload: data,
    });
  };
};

export const setBulkRequestDetails = (data: { bulkSchedules: any[] }) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_BULK_REQUEST_DETAILS,
      payload: data,
    });
  };
};

export const setSelectedQuestionnaire = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_SELECTED_QUESTIONNAIRE,
      payload: data,
    });
  };
};
export const setNewQuestion = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_NEW_QUESTION,
      payload: data,
    });
  };
};

export const setInspectRefetch = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_INSPECT_REFETCH,
      payload: data,
    });
  };
};

export const setCurrentSortType = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_CURRENT_SORT_TYPE,
      payload: data,
    });
  };
};

export const setLayoutContents = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_LAYOUT_CONTENTS,
      payload: data,
    });
  };
};

export const setMoveToConfirmed = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_MOVE_TO_CONFIRMED,
      payload: data,
    });
  };
};

export const setAssignTechBulk = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_ASSIGN_TECH_BULK,
      payload: data,
    });
  };
};

export const setServicesList = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_SERVICES_LIST,
      payload: data,
    });
  };
};

export const setVendorDetails = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_VENDOR_DETAILS,
      payload: data,
    });
  };
};
export const setInvoiceView = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_INVOICE_VIEW,
      payload: data,
    });
  };
};
export const setDownloadView = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_DOWNLOAD_VIEW,
      payload: data,
    });
  };
};
export const setCustomizeView = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_CUSTOMIZE_VIEW,
      payload: data,
    });
  };
};

export const setDispute = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_DISPUTE,
      payload: data,
    });
  };
};

export const setAttachments = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_ATTACHMENTS,
      payload: data,
    });
  };
};

export const setSingleVehicle = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_SINGLE_SCHEDULE_VEHICLE,
      payload: data,
    });
  };
};

export const setSelectedAssignVendor = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_SELECTED_ASSIGN_VENDOR,
      payload: data,
    });
  };
};

export const setSelectedAssignTech = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_SELECTED_ASSIGN_TECH,
      payload: data,
    });
  };
};

export const setSelectedAssignPartner = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_SELECTED_ASSIGN_PARTNER,
      payload: data,
    });
  };
};
export const setSelectedData = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_SELECTED_DATA,
      payload: data,
    });
  };
};

export const setRequestModal = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_REQUEST_MODAL_DATA,
      payload: data,
    });
  };
};

export const setMultReq = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_MULTI_SELECT_REQ,
      payload: data,
    });
  };
};
export const setMultiInvoiceItems = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_MULTI_SELECT_INVOICE_ITEMS,
      payload: data,
    });
  };
};

export const setBulkReqData = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_BULK_REQUEST_DATA,
      payload: data,
    });
  };
};

export const setPartner = (data: any) => {
  localStorage.setItem('partner', JSON.stringify(data));
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_PARTNER,
      payload: data,
    });
  };
};

export const deletePartner = () => {
  localStorage.removeItem('partner');
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_PARTNER,
      payload: null,
    });
  };
};

export const setSelectedDSPSlot = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_SELECTED_DSP_SLOT,
      payload: data,
    });
  };
};

export const setAcceptedDataVendor = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_ACCEPTED_DATA_VENDOR,
      payload: data,
    });
  };
};
export const setuserInfoInvoice = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_USER_INFO_INVOICE,
      payload: data,
    });
  };
};
export const setAltertsOffset = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_ALERTS_OFFSET,
      payload: data,
    });
  };
};
export const setFilterType = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_FILTER_TYPE,
      payload: data,
    });
  };
};

export const setPendingVehicles = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_PENDING_VEHICLES,
      payload: data,
    });
  };
};

export const setRequestLoader = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_REQUEST_LOADER,
      payload: data,
    });
  };
};
export const setAllBulkVehicles = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_ALL_BULK_VEHICLES,
      payload: data,
    });
  };
};

export const setInvoicesLoader = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_INVOICE_LOADER,
      payload: data,
    });
  };
};
export const setPartnerVendors = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_PARTNER_VENDORS,
      payload: data,
    });
  };
};
export const setShowModel = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_SHOW_MODEL,
      payload: data,
    });
  };
};
