import { useSelector } from 'react-redux';
import { RootState } from '../../state/reducers';
import { useSearchParams } from 'react-router-dom';
import PriorityDropDown from './PriorityDropDown';
const PmAndRepairBtn = (
  isPMReqSelected: any,
  isRepairSelected: any,
  handleRequestTypeChange: any
) => {
  return (
    <>
      <div
        className={`${
          isPMReqSelected ? 'bg-primary-light text-primary font-bold' : 'bg-gray-100 text-gray-500'
        } rounded-full w-[10rem] flex justify-center items-center gap-2 px-2 py-1 text-[1.1rem] whitespace-nowrap cursor-pointer z-9`}
        onClick={() => handleRequestTypeChange('pm_request')}>
        {isPMReqSelected && <i className='pi pi-check'></i>}
        PM Request
      </div>
      <div
        className={`${
          isRepairSelected ? 'bg-primary-light text-primary font-bold' : 'bg-gray-100 text-gray-500'
        } rounded-full w-[10.5rem] flex justify-center items-center gap-2 px-2 py-1 text-[1.1rem] whitespace-nowrap cursor-pointer z-9`}
        onClick={() => handleRequestTypeChange('repair')}>
        {isRepairSelected && <i className='pi pi-check'></i>}
        Repair Request
      </div>
    </>
  );
};
export default function RequestSelectionRow() {
  const [urlParams, setSearchParams]: any = useSearchParams();
  const { user }: any = useSelector((state: RootState) => state.userState);
  const headingLayout = ['', '', '', '', '', '', '', '', ''];
  const isVendor: boolean = user.role === 'fleetmax_vendor';
  const isCSR: boolean = user.role === 'fleetmax_csr';
  const isPartner: boolean = user.role === 'fleetmax_partner';
  const isPMReqSelected =
    !window.location.href.includes('vehicle_type') || window.location.href.includes('pm_request');
  const isRepairSelected =
    !window.location.href.includes('vehicle_type') || window.location.href.includes('repair');
  const reqTypeInParms = urlParams.get('vehicle_type');
  const handleRequestTypeChange = (requestType: string) => {
    const oppositeRequestType = `${requestType === 'pm_request' ? 'repair' : 'pm_request'}`; // to unselect the request type
    if (requestType === reqTypeInParms) {
      return;
    }
    // if user clicks on the other type then delete the params
    if (urlParams.get('vehicle_type')) {
      urlParams.delete('vehicle_type');
      urlParams.set('offset', '1');
      setSearchParams(urlParams);
      return;
    }
    const newSearchParams = new URLSearchParams(urlParams);
    newSearchParams.set('vehicle_type', oppositeRequestType);
    newSearchParams.set('offset', '1');
    setSearchParams(newSearchParams);
  };
  const viewPriorityCheck: boolean =
    ['pending', 'requests', 'accepted']?.includes(urlParams.get('type')) ||
    window.location.href.includes('/partner/dashboard');
  return (
    <>
      {!viewPriorityCheck && (
        <tr
          className={`absolute ${
            isVendor || isPartner ? 'top-1.5' : 'top-0'
          } ${'left-0'} w-fit h-[3.2rem]  flex gap-2 items-center py-2 cursor-pointer`}>
          <PriorityDropDown />
        </tr>
      )}
      {isCSR && (
        <tr className='absolute top-0 right-[18rem] w-[2rem] h-[3.2rem]  flex gap-2 items-center py-2 cursor-pointer'>
          {PmAndRepairBtn(isPMReqSelected, isRepairSelected, handleRequestTypeChange)}
        </tr>
      )}
      {headingLayout.map((item: any, index: number, array: any[]) => {
        return (
          <>
            {index === 0 && (
              <>
                {isCSR ? (
                  <th className='relative w-[2rem] h-[3.2rem]'>{/* createing space */}</th>
                ) : (
                  <th className=''></th>
                )}
              </>
            )}
            {index !== 0 && index !== array.length - 1 && <th className={` w-fit `}></th>}
            {index === array.length - 1 && (
              <>
                {isVendor || isPartner ? (
                  <th className='w-fit'>
                    <div className='relative '>
                      <div className='absolute top-2 right-0 flex gap-2 items-center py-2 cursor-pointer  z-0'>
                        {PmAndRepairBtn(isPMReqSelected, isRepairSelected, handleRequestTypeChange)}
                      </div>
                    </div>
                  </th>
                ) : (
                  <th className=''></th>
                )}
              </>
            )}
          </>
        );
      })}
    </>
  );
}
