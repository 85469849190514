import { Button, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../state/reducers';

export function CreateInvoiceButtons({ props }: { props: any }) {
  const {
    disabledCondition,
    handelNextButtonvendor,
    handleFormSubmit,
    updateVendorInvoice,
    showCreate,
    setShowCreate,
    showEdit,
    services,
    note,
    serviceValidation,
    showVendor,
    item,
    estimatedTotal,
    confirmNumber,
  } = props;
  const isMoveToComplete = window.location.href.includes('moveToComplete');
  const { invalidDescription } = serviceValidation;
  const isMOveToCompletepage = window.location.href.includes('moveToComplete');
  const { moveToCompleteData }: any = useSelector((state: RootState) => state.mainState);
  const hasInvalidDescription = services?.some(
    (service: any) => service?.description?.length > 100
  );
  const hasEmptyDate = services?.some((service: any) => !service.date);
  const hasNoService = services?.length === 0;

  const isNextButtonDisabled = hasNoService || hasInvalidDescription || hasEmptyDate;
  const renderTitle = () => {
    if (hasNoService) {
      return 'Add minimum 1 service';
    }
    if (hasInvalidDescription) {
      return 'Description cannot e more than 50 charecters. ';
    }

    if (hasEmptyDate) {
      return 'Date cannot be empty';
    }

    const totalMismatch = Number(estimatedTotal) !== Number(confirmNumber);
    if (showVendor && totalMismatch && isMOveToCompletepage) {
      return 'Confirmation Value should be equal to Estimated Total';
    } else return null;
  };
  const renderTitleNext = () => {
    if (hasNoService) {
      return 'Add minimum 1 service';
    }
    if (note?.length > 100) {
      return 'Note should be more than 100 Charecters';
    }
    if (invalidDescription) {
      return 'Description cannot e more than 50 charecters. ';
    }
    if (hasEmptyDate) {
      return 'Date cannot be empty';
    } else return null;
  };
  const name = () => {
    if (item?.vendor_invoice_status && showVendor) {
      return 'Update Invoice';
    }
    if (item?.dsp_invoice_status && !showVendor) {
      return 'Update Invoice';
    } else return 'Create Invoice';
  };
  return (
    <div className='mt-3'>
      <div className='flex justify-between gap-3'>
        {!showCreate && (
          <Tooltip title={renderTitleNext()} placement={isMoveToComplete ? 'top' : 'bottom'}>
            <div className='w-full'>
              <Button
                className='rounded-[0.5rem] bg-[#4378C8] w-full h-10 text-[#FFF] text-bold disabled:bg-gray-200 mt-[7.5rem]'
                style={{ fontWeight: 'bold' }}
                disabled={note?.length > 100 || invalidDescription || hasEmptyDate || hasNoService}
                onClick={() => {
                  handleFormSubmit();
                  setShowCreate(true);
                }}>
                {'Next'}
              </Button>
            </div>
          </Tooltip>
        )}
        {showCreate && (
          <div className='w-full flex justify-between gap-3'>
            <Button
              variant='outlined'
              className='text-[1.05rem] flex gap-2 h-10'
              disabled={showEdit?.show}
              style={{ fontWeight: 'bold' }}
              onClick={() => {
                setShowCreate(false);
              }}>
              <i className='pi pi-chevron-left'></i>
              {'Edit'}
            </Button>
            {updateVendorInvoice || moveToCompleteData?.selected ? (
              <>
                {isMOveToCompletepage ? (
                  <>
                    <Tooltip title={renderTitle()} placement={isMoveToComplete ? 'top' : 'bottom'}>
                      <button
                        disabled={disabledCondition()}
                        className='rounded-[0.5rem] bg-[#4378C8] w-full  text-[#FFF] text-bold disabled:bg-gray-200'
                        style={{ fontWeight: 'bold' }}
                        type='submit'
                        onClick={handelNextButtonvendor}>
                        {name()}
                      </button>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip title={renderTitle()} placement={isMoveToComplete ? 'top' : 'bottom'}>
                      <button
                        disabled={isNextButtonDisabled}
                        className='rounded-[0.5rem] bg-[#4378C8] w-full  text-[#FFF] text-bold disabled:bg-gray-200'
                        style={{ fontWeight: 'bold' }}
                        type='submit'
                        onClick={handelNextButtonvendor}>
                        {name()}
                      </button>
                    </Tooltip>
                  </>
                )}
              </>
            ) : (
              <Tooltip title={renderTitle()} placement={isMoveToComplete ? 'top' : 'bottom'}>
                <button
                  disabled={isNextButtonDisabled}
                  className='rounded-[0.5rem] bg-[#4378C8] w-full text-[#FFF] text-bold disabled:bg-gray-200'
                  style={{ fontWeight: 'bold' }}
                  onClick={handelNextButtonvendor}>
                  {name()}
                </button>
              </Tooltip>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
