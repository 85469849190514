import { useState, useCallback } from 'react';
import { toast } from '../../../../../components/Toast/CustomToast';
import VendorAPI from '../../../../../api/vendorApi';
import { getQueryParamsGraph } from '../../../../vender/utils';
import { useSearchParams } from 'react-router-dom';
import { RevenueTrend } from '../../types/types';

const useFetchGraph = () => {
  const [loader, setLoader] = useState(false);
  const [urlParams] = useSearchParams();
  const [revenueGraphData, setRevenueGraphData] = useState<any>([]);
  const fetchAllGraphData = useCallback(async () => {
    setLoader(true);
    try {
      const res: { status: number; data: { data: { requests: RevenueTrend[] } } } =
        await VendorAPI.getAllGraphData({
          params: getQueryParamsGraph(urlParams),
        });
      if (res.status === 200) {
        setRevenueGraphData(res.data.data.requests);
      } else {
        toast.error('Failed to fetch graph data');
      }
    } catch (error) {
      toast.error('Error fetching graph data');
    }
    setLoader(false);
  }, [urlParams]);

  return { loader, revenueGraphData, fetchAllGraphData };
};

export default useFetchGraph;
