import { toast as Toast } from 'react-toastify';


export const toast = {
  success: (msg: string) => {
    Toast.success(msg, { toastId: "success", pauseOnFocusLoss: false, pauseOnHover: false })
  },
  error: (msg: string) => {
    Toast.error(msg, { toastId: "error", pauseOnFocusLoss: false, pauseOnHover: false })
  },
  warn: (msg: string) => {
    Toast.warn(msg, { toastId: "warn", pauseOnFocusLoss: false, pauseOnHover: false })
  },
  info: (msg: string) => {
    Toast.info(msg, { toastId: "info", pauseOnFocusLoss: false, pauseOnHover: false, })
  }
}



