import { useEffect, useRef } from 'react';
import { Popover } from '@mui/material';
let parentWidth = 0;
let parentHeight = 0;
function ExpandPopUpService({
  item,
  icon,
  showOnExpand,
  title,
  variant,
  noPopUp,
  sx,
  onOpen,
  defaultOpen,
  anchorEl,
  setAnchorEl,
}: {
  item: any;
  noPopUp?: boolean;
  variant?: string | 'expandDown';
  icon?: JSX.Element;
  title: string;
  showOnExpand: JSX.Element;
  sx?: string;
  onOpen?: () => void;
  defaultOpen?: boolean;
  anchorEl: any;
  setAnchorEl: any;
}) {
  const parentRef: any = useRef(null);
  useEffect(() => {
    if (parentRef.current) {
      parentWidth = parentRef.current.clientWidth;
      parentHeight = parentRef.current.clientHeight;
    }
  }, [parentRef]);

  const handleOpenPopup = (event: any) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
      onOpen?.();
    }
  };

  useEffect(() => {
    if (defaultOpen && parentRef.current) {
      setAnchorEl(parentRef.current);
    }
  }, [defaultOpen, parentRef.current]);

  const handleClosePopup = () => {
    setAnchorEl(null);
  };

  const expandDown = variant === 'expandDown';

  const upDirection = expandDown ? !anchorEl : anchorEl;

  if (!item) return null;

  return (
    <div className='w-full'>
      <button
        className={`w-[70%] bg-white ${
          item?.length > 0 || !item || anchorEl !== null
            ? 'border-[0.05rem] border-gray-400'
            : 'border-transparent'
        }  relative  flex justify-between px-4 font-bold p-1.5  rounded-[0.5rem] flex items-center   h-[2.5rem] ${sx}`}
        onClick={handleOpenPopup}
        ref={parentRef}>
        {item?.length > 0 || !item || anchorEl !== null ? (
          <>
            <div className='flex gap-2 items-center text-gray-500 font-normal '>{title}</div>
            <i
              className={`pi pi-angle-down ${
                upDirection ? 'rotate-0' : 'rotate-180'
              } ease-in-out duration-200 text-grey`}></i>
          </>
        ) : (
          <p className='text-primary font-normal'>
            NA{' '}
            <span className='border-b-primary border-b-[0.1rem] pb-[0.1rem]'>
              (Add Service Image)
            </span>
          </p>
        )}
      </button>
      {noPopUp ? (
        anchorEl && <></>
      ) : (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClosePopup}
          PaperProps={{
            sx: {
              width: parentRef.current?.clientWidth,
              boxShadow: '1',
              borderRadius: 0,
              ...(expandDown
                ? {
                    borderBottomLeftRadius: '0.5rem',
                    borderBottomRightRadius: '0.5rem',
                  }
                : {
                    borderTopLeftRadius: '0.5rem',
                    borderTopRightRadius: '0.5rem',
                  }),
            },
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
            ...(expandDown && {
              vertical: 'bottom',
              horizontal: 'center',
            }),
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            ...(expandDown && {
              vertical: 'top',
              horizontal: 'center',
            }),
          }}>
          <div
            className='relative  w-full  p-1 shadow-lg'
            style={{
              ...(expandDown
                ? {
                    borderRadius: '0.5rem',
                  }
                : { borderRadius: '0.5rem' }),
            }}>
            {showOnExpand}
          </div>
        </Popover>
      )}
    </div>
  );
}

export default ExpandPopUpService;
