import { Tooltip, TooltipProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ColoredToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    componentsProps={{
      tooltip: {
        sx: {
          bgcolor: '#706F6E',
          color: 'white',
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
          maxHeight: '80vh',
          maxWidth: '20vw',
          overflow: 'auto',
          fontFamily: 'inherit',
        },
      },
      arrow: {
        sx: {
          color: 'white',
        },
      },
    }}
  />
))``;

export const ColoredToolTipWithoutOverflow = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    componentsProps={{
      tooltip: {
        sx: {
          bgcolor: '#706F6E',
          color: 'white',
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
          maxHeight: '80vh',
          maxWidth: '20vw',
          overflow: 'hidden',
          fontFamily: 'inherit',
        },
      },
      arrow: {
        sx: {
          color: 'white',
        },
      },
    }}
  />
))``;
