import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import FilterDrawer from '../components/FilterDrawerRevenue';
import SortButton from '../components/SortButton';
import { Tooltip } from '@mui/material';
import moment from 'moment-timezone';
import useFetchRevenue from './useFetchRevenue';
import { ThreeDots } from 'react-loader-spinner';

function RevenueHistory({ props }: any) {
  const { setShowModel } = props;
  const [urlParams, setSearchParams]: any = useSearchParams();
  const newSearchParams = new URLSearchParams(urlParams);
  const [count, setCount] = useState(0);
  const [showGrossPopover, setShowGrossPopover] = useState(false);

  const isPMReqSelected =
    !window.location.href.includes('vehicle_type') || window.location.href.includes('pm_request');
  const isRepairSelected =
    !window.location.href.includes('vehicle_type') || window.location.href.includes('repair');
  const reqTypeInParms = urlParams.get('vehicle_type');
  const handleRequestTypeChange = (requestType: string) => {
    const oppositeRequestType = `${requestType === 'pm_request' ? 'repair' : 'pm_request'}`; // to unselect the request type
    if (requestType === reqTypeInParms) {
      return;
    }
    // if user clicks on the other type then delete the params
    if (urlParams.get('vehicle_type')) {
      urlParams.delete('vehicle_type');
      urlParams.set('page_number', '1');
      setSearchParams(urlParams);
      return;
    }

    newSearchParams.set('vehicle_type', oppositeRequestType);
    newSearchParams.set('page_number', '1');
    setSearchParams(newSearchParams);
  };
  const {
    loader: revenueLoader,
    fetchAllRevenueData,
    revenueHistoryData,
    totalItems,
    requests,
    offsetLoader,
    timeoutActive,
    setTimeoutActive,
  } = useFetchRevenue();

  const search = newSearchParams.get('search') || '';
  const months = newSearchParams.get('months') || '';
  const weeks = newSearchParams.get('weeks') || '';
  const vehicleType: any = newSearchParams.get('vehicle_type') || '';
  const technician_ids: any = newSearchParams.get('technician_ids') || '';
  const services: any = newSearchParams.get('services') || '';
  const station_ids: any = newSearchParams.get('station_ids') || '';
  const sort_type: any = newSearchParams.get('sort_type') || '';
  const revenue_sort: any = newSearchParams.get('revenue_sort') || '';
  const vendor_ids: any = newSearchParams.get('vendor_ids') || '';
  useEffect(() => {
    const timer = setTimeout(() => {
      if (urlParams?.get('weeks')?.length > 0 || urlParams?.get('months')?.length > 0) {
        urlParams?.set('page_number', '1');
        setSearchParams(urlParams);
        fetchAllRevenueData(true);
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [
    search,
    months,
    weeks,
    vehicleType,
    technician_ids,
    services,
    station_ids,
    sort_type,
    revenue_sort,
    vendor_ids,
  ]);

  const page_number: any = newSearchParams.get('page_number') || '';
  useEffect(() => {
    if (page_number && timeoutActive) {
      if (urlParams?.get('weeks')?.length > 0 || urlParams?.get('months')?.length > 0) {
        const number = newSearchParams.get('page_number');
        const updatedNumber = Number(number) * 10;
        if (+totalItems + 9 >= +updatedNumber) fetchAllRevenueData();
      }
    }
  }, [timeoutActive]);
  const handleScroll = (e: any) => {
    const div = e.target;
    const atBottom =
      Math.floor(div.scrollHeight - div.scrollTop) <=
      (Math.floor(div.clientHeight + 1) || Math.floor(div.clientHeight));
    if (atBottom && !revenueLoader && !timeoutActive && !offsetLoader) {
      setTimeoutActive(true);
      const number = newSearchParams.get('page_number');
      const updatedNumber = Number(number) + 1;
      newSearchParams.set('page_number', updatedNumber.toString());
      setSearchParams(newSearchParams);
      setTimeout(() => {
        setTimeoutActive(false);
      }, 2000);
    }
  };
  return (
    <div className='relative flex flex-col justify-between  border-[0.2rem] border-gray-200 rounded-[0.5rem] h-80  mt-[1.2rem] shadow-md'>
      <div className='flex justify-between'>
        <p
          className='p-2 pl-[0.8rem] font-[500] mt-[0.4rem]'
          style={{
            color: 'rgba(81, 81, 81, 1)',
            fontSize: '1.2rem',
          }}>
          Revenue History
        </p>
        <div className='flex items-center pl-[1rem] cursor-pointer border-[0.2rem] m-[0.4rem] rounded-full  bg-primary-lighter py-[0.2rem]'>
          <div className='text-tableTextColor font-bold pr-[0.4rem] text-[1rem]'>
            My Revenue{' '}
            {showGrossPopover && (
              <div className='absolute  bg-revenueModelBgColor text-black p-2 rounded shadow-lg mt-3 text-[0.9rem] text-tableTextColor z-20'>
                <div>
                  <div className='flex justify-between px-[0.2rem] py-[0.2rem] gap-4 text-white'>
                    <div>Sub Total</div>
                    <div>
                      {`$ ${parseFloat(revenueHistoryData?.revenue?.subtotal_amount || 0).toFixed(
                        2
                      )}`}
                    </div>
                  </div>{' '}
                  <div className='flex justify-between px-[0.2rem] py-[0.2rem] gap-4 text-white'>
                    <div>Sales Tax</div>
                    <div>
                      {`$ ${parseFloat(revenueHistoryData?.revenue?.total_tax_amount || 0).toFixed(
                        2
                      )}`}
                    </div>
                  </div>{' '}
                  <div className='flex justify-between px-[0.2rem] py-[0.2rem] gap-4 text-white whitespace-nowrap'>
                    <div>Estimated Total</div>
                    <div>
                      {`$ ${parseFloat(revenueHistoryData?.revenue?.total_amount || 0).toFixed(2)}`}
                    </div>
                  </div>{' '}
                  {revenueHistoryData?.previous_revenue > 0 && (
                    <div className='flex justify-between px-[0.2rem] py-[0.2rem] gap-4 text-white'>
                      <div>Previous Revenue</div>
                      <div>
                        {`$ ${parseFloat(revenueHistoryData?.previous_revenue || 0).toFixed(2)}`}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className='text-tableTextColor font-bold pr-[0.3rem] text-[1.2rem]'>
            $ {parseFloat(revenueHistoryData?.revenue?.total_amount || 0).toFixed(2)}
          </div>
          {parseInt(revenueHistoryData?.growth?.replace('%', '').trim()) > 0 ? (
            <div className='text-[1rem] text-paymentSuccesfulColor font-bold px-[0.5rem] py-[0.2rem] bg-profitBgColor rounded-full mx-[0.4rem] '>
              <img
                src='../../svg/profitArrow.svg'
                alt='InfoIcon'
                className='inline-block h-[1.1rem] w-[1.1rem] pr-[0.3rem] pb-[0.1rem]'
                onMouseEnter={() => setShowGrossPopover(true)}
                onMouseLeave={() => setShowGrossPopover(false)}
              />

              {revenueHistoryData?.growth}
            </div>
          ) : (
            parseInt(revenueHistoryData?.growth?.replace('%', '').trim()) < 0 && (
              <div className='text-[1rem] text-red-600 font-bold px-[0.5rem] py-[0.2rem] bg-red-200 rounded-full mx-[0.4rem] '>
                <img
                  src='../../svg/lossArrow.svg'
                  alt='InfoIcon'
                  className='inline-block h-[1.1rem] w-[1.1rem] pr-[0.3rem] pb-[0.1rem]'
                  onMouseEnter={() => setShowGrossPopover(true)}
                  onMouseLeave={() => setShowGrossPopover(false)}
                />
                {Math?.abs(parseFloat(revenueHistoryData?.growth?.replace('%', '').trim()))}%
              </div>
            )
          )}
          <div>
            <div className='flex items-center'>
              <img
                src='../../svg/info.svg'
                alt='infoIcon'
                className='inline-block h-[1.5rem] w-[1.7rem] pr-[0.5rem] pb-[0.1rem]'
                onMouseEnter={() => setShowGrossPopover(true)}
                onMouseLeave={() => setShowGrossPopover(false)}
              />
            </div>
          </div>
        </div>{' '}
      </div>
      <div className='flex items-center gap-2 px-2 justify-between mb-[0.2rem]'>
        <div className='flex'>
          <div
            className={`${
              isPMReqSelected
                ? 'bg-primary-light text-primary font-bold'
                : 'bg-gray-100 text-gray-500'
            } rounded-full w-[10rem] flex justify-center items-center gap-2 px-2 py-1 text-[1.1rem] whitespace-nowrap cursor-pointer z-9 mr-[0.5rem]`}
            onClick={() => handleRequestTypeChange('pm_request')}>
            {isPMReqSelected && <i className='pi pi-check'></i>}
            PM Request
          </div>
          <div
            className={`${
              isRepairSelected
                ? 'bg-primary-light text-primary font-bold'
                : 'bg-gray-100 text-gray-500'
            } rounded-full w-[10.5rem] flex justify-center items-center gap-2 px-2 py-1 text-[1.1rem] whitespace-nowrap cursor-pointer z-9`}
            onClick={() => handleRequestTypeChange('repair')}>
            {isRepairSelected && <i className='pi pi-check'></i>}
            Repair Request
          </div>
        </div>
        <div className='flex'>
          <FilterDrawer count={count} setCount={setCount} />
          <SortButton newSearchParams={newSearchParams} />
        </div>
      </div>
      <div className='w-full h-full'>
        {revenueLoader ? (
          <div className='h-full w-full flex justify-center items-center'>
            <ThreeDots
              height='10'
              width='50'
              radius='9'
              color={'#4378C8'}
              ariaLabel='three-dots-loading'
              wrapperStyle={{}}
              visible={true}
            />
          </div>
        ) : (
          <div
            className=' flex flex-col gap-4 max-h-[12rem] overflow-auto p-1 '
            onScroll={handleScroll}>
            {requests?.length > 0 ? (
              requests?.map((ele: any) => {
                return (
                  <p
                    className='flex justify-between border-[0.1rem] border-gray-200 rounded-[0.5rem] flex flex-col'
                    key={ele?.preventive_request_id || ''}>
                    <p className='p-2 flex justify-between  rounded-[0.5rem] '>
                      <div className='flex flex-col'>
                        <div className='flex items-center'>
                          <div className='flex items-center gap-3 border-r-[0.2rem] border-gray-300 p-1 pr-3'>
                            <p className='text-primary text-[0.9rem] font-bold  px-1 flex gap-2 items-center text-primary'>
                              <span
                                className='cursor-pointer hover:underline '
                                onClick={() => {
                                  if (ele?.preventive_request_id) {
                                    setShowModel({
                                      show: true,
                                      data: {
                                        ...ele,
                                        id: ele?.preventive_request_id,
                                      },
                                      isIndex: true,
                                    });
                                  }
                                }}>
                                Request {ele.preventive_request_id}
                              </span>
                            </p>
                            <i className='pi pi-circle-fill text-[0.4rem] text-primary'></i>
                            <p className='font-bold text-black text-[0.9rem] text-primary'>
                              {ele?.vehicle_name}
                            </p>
                          </div>
                          <div className='flex items-center gap-3  p-1 pr-3'>
                            <p className=' text-[0.9rem] font-bold  px-1 flex gap-2 items-center '>
                              <p>{ele?.station_code}</p>
                            </p>
                            {ele?.technician_name !== null ? (
                              <>
                                <i className='pi pi-circle-fill text-[0.4rem] '></i>
                                <p className='font-bold  text-[0.9rem] capitalize'>
                                  {ele?.technician_name === null ? '' : ele?.technician_name}
                                </p>
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        <div>
                          <p className='px-2 text-[0.9rem] w-[8rem]'>
                            {ele?.invoice?.services?.length > 0 && (
                              <Tooltip
                                title={
                                  <div>
                                    {ele?.invoice?.services?.map((item: any) => (
                                      <div
                                        key={item?.name || ''}
                                        className='flex justify-between px-[0.2rem] py-[0.2rem] gap-4 text-[0.9rem] capitalize'>
                                        <div>{item?.name}</div>
                                        <div className='text-tableLinkColor '>
                                          {item?.amount === 'N/A'
                                            ? 'N/A'
                                            : `$${parseFloat(item?.amount || 0).toFixed(2)}`}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                }
                                placement='bottom-start'>
                                <div className='text-[0.9rem] text-tableTextColor-600 flex'>
                                  <div className='ml-[0.3rem]'>
                                    {ele.invoice?.services?.length} Services{' '}
                                  </div>
                                  <div>
                                    <i
                                      className={`pi pi-angle-down text-[#4378C8] mt-[0.2rem] ml-[0.2rem]`}></i>
                                  </div>
                                </div>
                              </Tooltip>
                            )}
                          </p>
                        </div>
                      </div>
                      <div className='pr-[0.5rem] flex items-end flex-col justify-center'>
                        <p
                          style={{
                            color: '#4378C8',
                          }}>
                          $ {parseFloat(ele?.invoice?.total_amount || 0).toFixed(2)}
                        </p>
                        <p className='text-gray-300'>
                          {moment(ele?.log_date).format('DD MMM YYYY')}
                        </p>
                      </div>
                    </p>
                  </p>
                );
              })
            ) : (
              <>
                {!urlParams?.get('weeks') && !urlParams?.get('months') ? (
                  <div className='flex justify-center items-center h-full'>
                    {' '}
                    <img
                      src='../../svg/info.svg'
                      alt='infoIcon'
                      className='inline-block h-[1.5rem] w-[1.7rem] pr-[0.5rem] pb-[0.1rem]'
                    />
                    Please select a time range
                  </div>
                ) : (
                  <>
                    <div className='flex justify-center items-center h-full'>
                      {!revenueLoader && !offsetLoader && (
                        <>
                          <img
                            src='../../svg/info.svg'
                            alt='infoIcon'
                            className='inline-block h-[1.5rem] w-[1.7rem] pr-[0.5rem] pb-[0.1rem]'
                          />
                          No data Found
                        </>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
            {offsetLoader && (
              <div className='h-[5rem] w-full flex justify-center items-center'>
                <ThreeDots
                  height='10'
                  width='50'
                  radius='9'
                  color={'#4378C8'}
                  ariaLabel='three-dots-loading'
                  wrapperStyle={{}}
                  visible={true}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default RevenueHistory;
