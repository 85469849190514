import { useEffect, useMemo, useState } from 'react';
import InvoiceButtonForDSPCompleted from '../../../assets/InvoiceButtonForDSPCompleted';
import InvoiceButtonForVedorCOmpleted from '../../../assets/InvoiceButtonForVedorCOmpleted';
import ModalForm from '../../../components/ModalForm';
import OverlayContainer from '../../../components/OverlayContainer';
import useFetchInvoiceDetails from './Invoice/components/useFetchInvoiceDetails';
import DownloadInvoiceButton from '../../../assets/DownloadInvoiceButton';
import { Environment } from '../../../constants/environment';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../state/reducers';
import InvoiceTemplateDSPNew from './Invoice/invoiceTemplate/InvoiceTemplateDSP';
import InvoiceTemplateVendorNew from './Invoice/invoiceTemplate/InvoiceTemplateVendor';
import DownloadDSPInvoice from './Invoice/dsp/components/DownloadDSPInvoice';
import { toast } from '../../../components/Toast/CustomToast';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../state';

const Invoice = ({ handleClose, item }: any) => {
  const dispatch = useDispatch();
  const { setInvoicesLoader } = bindActionCreators(actionCreators, dispatch);
  const baseUrl = Environment.baseUrl;
  const { user, invoiceLoader }: any = useSelector((state: RootState) => state.userState);
  const { invoiceDetails, fetchInvoiceDetails, invoices, fetchInvoices } = useFetchInvoiceDetails();
  const [_, setServices] = useState([] as any);
  const [invoiceType, setInvoiceType] = useState('dsp');
  const activecss = 'bg-primary-light text-primary border-primary-light ';
  const [selectedInvoice, setSelectedInvoice] = useState(null as any);
  const downloadReport = () => {
    if (!invoiceLoader) {
      setInvoicesLoader(true);
      toast.info('Downloading PDF!');
      axios
        .get(
          `${baseUrl}csr/v1/download_invoice?ids_arr=${JSON.stringify([vendorInvoices?.[0]?.id])}`,
          {
            responseType: 'arraybuffer',
            headers: {
              'x-access-token': user?.token || '',
              'x-access-user': user?.account_id || '',
              'app-type': '9',
            },
          }
        )
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
          var link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Invoice.pdf`);
          document.body.appendChild(link);
          link.click();
          setInvoicesLoader(false);
        });
    }
  };
  useEffect(() => {
    if (invoiceType === 'dsp') {
      const saved_data: any = invoices?.[0]?.invoices?.filter((d: any) => d.recipient === 'dsp');
      if (saved_data && saved_data?.length > 0) {
        const servicesArr: any = [];
        saved_data.forEach((d: any) => {
          servicesArr.push({
            name: d.service_name,
            pdf: d.invoice_url,
          });
        });
        setServices(servicesArr);
      }
    }
  }, [item, invoiceType]);
  useEffect(() => {
    if (!item) {
      return;
    } else {
      fetchInvoices(item);
    }
  }, []);
  const vendorInvoices = useMemo(() => {
    if (!invoices) return null;
    return invoices?.[0]?.invoices?.filter((invoices: any) => invoices.recipient === 'vendor');
  }, [invoices, invoiceType]);
  const dspInvoices = useMemo(() => {
    if (!invoices) return null;
    return invoices?.[0]?.invoices?.filter((d: any) => d.recipient === 'dsp');
  }, [invoices, invoiceType]);
  useEffect(() => {
    if (!item) {
      return;
    } else {
      if (invoiceType === 'vendor') {
        fetchInvoiceDetails(vendorInvoices?.[0]?.id);
      } else if (invoiceType === 'dsp') {
        fetchInvoiceDetails(selectedInvoice?.id);
      }
    }
  }, [invoices, selectedInvoice, invoiceType]);
  useEffect(() => {
    if (dspInvoices?.length > 0) {
      setSelectedInvoice(dspInvoices[0]);
    }
  }, [dspInvoices]);
  const { handleEdit } = item;
  return (
    <OverlayContainer show={true}>
      <ModalForm title={'Add CSR'} onClose={handleClose} width={'w-[82vw]'} noLabel>
        <div className='w-full flex justify-between h-[40rem] '>
          <div className='left-container w-1/6 border-r-[0.2rem]'>
            <div className=''>
              <button
                className={`text-[1.2rem] cursor-pointer px-4 py-3 mx-6 my-5 rounded-[1.3rem] ${
                  invoiceType === 'dsp' && activecss
                } w-[10rem]`}
                onClick={() => {
                  setInvoiceType('dsp');
                }}>
                <div className='ml-5'>
                  <InvoiceButtonForDSPCompleted invoiceType={invoiceType} />
                </div>
                Invoice for DSP
              </button>
              <button
                className={`text-[1.2rem] font-normal cursor-pointer px-4 py-3 mx-6 my-5 rounded-[1.3rem] ${
                  invoiceType === 'vendor' && activecss
                }`}
                onClick={() => {
                  setInvoiceType('vendor');
                }}>
                <div className='ml-5'>
                  <InvoiceButtonForVedorCOmpleted invoiceType={invoiceType} />
                </div>
                Invoice for Vendor
              </button>
            </div>
          </div>
          <div className=' w-5/6 mx-4 h-full '>
            <div className='h-[90%]'>
              {/* container for dsp  */}
              {invoiceType === 'dsp' && (
                <div className='w-full flex justify-between flex-1 h-full gap-3 '>
                  <div className='flex flex-col p-2 items-center h-full'>
                    <div className='flex justify-start w-full'>
                      <div className='flex justify-start flex-col'>
                        <div className='text-[1.5rem] text-[#4378C8] mb-2'>Request {item?.id}</div>
                        <div className='text-[1.2rem] text-[#515151] font-bold'>
                          {item?.vehicle?.name || '----'}
                        </div>
                        <div className='text-[1.1rem] text-[#A0A0A0] mb-5'>
                          {item?.vehicle?.vin || '----'}
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col gap-2 h-0 overflow-auto pr-2 flex-1'>
                      {dspInvoices?.map((ele: any, index: React.Key | null | undefined) => (
                        <button
                          className={`p-3 mb-3 rounded-[0.5rem] w-[15rem] text-left ${
                            ele?.id === selectedInvoice?.id && 'bg-primary-light'
                          }
                      hover:bg-primary-light
                      `}
                          onClick={() => setSelectedInvoice(ele)}
                          key={ele?.id || ''}>
                          {ele?.service_name || ele?.service_detail?.service_name}
                        </button>
                      ))}
                      {dspInvoices?.length === 0 && <p>No Invoice Available</p>}
                    </div>
                  </div>
                  <div className='flex flex-col  h-full justify-between'>
                    <div className='w-[50rem] p-2 items-center h-full pr-[3rem]'>
                      {invoiceDetails && dspInvoices?.length > 0 ? (
                        <InvoiceTemplateDSPNew
                          invoiceDetails={invoiceDetails}
                          item={item}
                          height='36rem'
                        />
                      ) : (
                        <div className='h-[36rem]'></div>
                      )}
                    </div>
                    {selectedInvoice?.id && (
                      <div className='flex justify-end mt-[1.5rem] mr-7 gap-3'>
                        {['sent', 'updated'].includes(item?.dsp_invoice_status) &&
                          !['amount_submitted', 'amount_submitted_by_csr'].includes(
                            item?.vendor_invoice_status
                          ) &&
                          !['raised', 'declined'].includes(item?.vendor_invoice_dispute) && (
                            <button
                              className='py-3 px-10  rounded-[0.7rem] border-[#4378C8] disabled:border-[#808080] disabled:text-[#808080] text-[#4378C8] flex justify-between items-center text-bold'
                              style={{ fontWeight: 'bold' }}
                              onClick={() => {
                                handleEdit('DSP');
                              }}>
                              <i className='pi pi-pencil'></i>
                              <p className='ml-3'>Edit Invoice</p>
                            </button>
                          )}
                        <DownloadDSPInvoice
                          selectedInvoice={selectedInvoice}
                          invoices={dspInvoices}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {/* container for vendor  */}
              {invoiceType === 'vendor' && (
                <div className='flex justify-center w-[100%] h-full p-4 flex flex-col'>
                  <div className='flex justify-start w-full h-[20%]'>
                    <div className='flex justify-start flex-col'>
                      <div className='text-[1.5rem] text-[#4378C8] mb-2'>Request {item?.id}</div>
                      <div className='text-[1.2rem] text-[#515151] font-bold'>
                        {item?.vehicle?.name || '----'}
                      </div>
                      <div className='text-[1.1rem] text-[#A0A0A0] mb-5'>
                        {item?.vehicle?.vin || '----'}
                      </div>
                    </div>
                  </div>
                  <div className='w-[100%] h-[80%]'>
                    {vendorInvoices?.length > 0 ? (
                      invoiceDetails && (
                        <div className='flex flex-col h-full'>
                          <div className='w-[55rem] p-2 flex justify-center items-center mx-auto h-[58rem]'>
                            <InvoiceTemplateVendorNew
                              invoiceDetails={invoiceDetails}
                              item={item}
                              height='28rem'
                            />
                          </div>
                          {vendorInvoices?.[0]?.id && (
                            <div className='flex justify-end gap-3'>
                              {![
                                'amount_submitted',
                                'amount_submitted_by_csr',
                                'generated',
                              ].includes(item?.vendor_invoice_status) &&
                                !['raised'].includes(item?.vendor_invoice_dispute) &&
                                !['raised', 'declined'].includes(item?.vendor_invoice_dispute) && (
                                  <button
                                    className='py-3 px-10  rounded-[0.7rem] border-[#4378C8] disabled:border-[#808080] disabled:text-[#808080] text-[#4378C8] flex justify-between items-center text-bold'
                                    style={{ fontWeight: 'bold' }}
                                    onClick={() => {
                                      handleEdit('Vendor');
                                    }}>
                                    <i className='pi pi-pencil'></i>
                                    <p className='ml-3'>Edit Invoice</p>
                                  </button>
                                )}
                              <button
                                disabled={invoiceLoader}
                                className={`py-3 px-6  rounded-[0.7rem] border-[#4378C8] disabled:border-[#808080] disabled:text-[#808080] text-[#4378C8] flex justify-between text-bold`}
                                style={{ fontWeight: 'bold' }}
                                onClick={downloadReport}>
                                <DownloadInvoiceButton active={!invoiceLoader} />
                                <p className='ml-3'>Download Invoice</p>
                              </button>
                            </div>
                          )}
                        </div>
                      )
                    ) : (
                      <p>No Invoice Available</p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalForm>
    </OverlayContainer>
  );
};

export default Invoice;
