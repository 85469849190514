import { ThemeProvider } from '@mui/material/styles';
import ReactDOM from 'react-dom/client';
import 'react-modal-video/css/modal-video.min.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { store, persistor } from './state';
import { PersistGate } from 'redux-persist/integration/react';
import { outerTheme } from './theme/Theme';
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <ThemeProvider theme={outerTheme}>
      <PersistGate persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <App />
          <ToastContainer
            pauseOnFocusLoss={false}
            newestOnTop={true}
            closeOnClick
            pauseOnHover={false}
          />
        </QueryClientProvider>
      </PersistGate>
    </ThemeProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
