import { useState } from 'react';
import ModalForm from '../../../../components/ModalForm';
import OverlayContainer from '../../../../components/OverlayContainer';
import ImagePreview from '../../../common/ImageModal';
import NotesPopup from '../../../vender/PrimaryComponents/requestComponents/vehicle/NotesPopUp';

const ImageModal = ({ handleClose, data }: any) => {
  const [preview, setPreview] = useState<{ open: boolean; data: any[] }>({ open: false, data: [] });
  const { checklistData, item } = data;

  return (
    <>
      <OverlayContainer show={true}>
        <ModalForm title={'Add CSR'} onClose={handleClose} width={'w-[56vw]'} noLabel>
          <div className='flex flex-col gap-4 h-[35rem]'>
            <div>
              <p className='text-blue-500 font-semibold text-xl'>{'Work Proofs'}</p>
            </div>
            <div className='flex h-0 flex-1 w-full'>
              <section className='w-1/2 p-1 pr-2 flex flex-col gap-2 h-full flex-1 overflow-y-auto'>
                {checklistData?.length > 0 &&
                  checklistData?.map((elem: any) => (
                    <div className='border rounded-lg border-slate-100 p-2' key={elem?.id}>
                      <p className='text-sm py-1 font-bold'>{elem.name}</p>
                      {elem?.proofs?.length > 0 ? (
                        <div className='grid grid-cols-5'>
                          {elem?.proofs?.map((img: { file_url: string; checklist_id: string }) => (
                            <div className='relative' key={img?.checklist_id}>
                              <img
                                src={img.file_url}
                                onClick={() => setPreview({ open: true, data: [img?.file_url] })}
                                className='w-[4rem] h-[4rem] rounded-xl object-contain overflow-hidden mx-auto cursor-pointer'
                                alt={img.file_url}></img>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p className='text-[#A9A9A9]'>No Proof Uploaded</p>
                      )}
                    </div>
                  ))}
              </section>
              <section className='w-1/2 p-1 pr-2 flex flex-col gap-2 h-full flex-1 overflow-y-auto'>
                <NotesPopup
                  item={item}
                  variant={'expandDown'}
                  size={'large'}
                  noPopUp
                  notesRemarks={[
                    'proof_rejected',
                    'moved_to_approval_by_partner',
                    'proofs_skipped_by_technician',
                    'moved_to_approval_by_technician',
                    'moved_to_approval_by_vendor',
                    'moved_to_approval_by_csr',
                  ]}
                />
              </section>
            </div>
          </div>
        </ModalForm>
      </OverlayContainer>
      {preview.open && (
        <ImagePreview
          imgArr={preview.data}
          handleClose={() => setPreview({ open: false, data: [] })}
        />
      )}
    </>
  );
};

export default ImageModal;
