import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as yup from 'yup';
import { LoginWithEmail } from '../../../api/types';
import AuthAPI from '../../../api/usersApi';
import { TextFieldWrapperOne } from '../../../components/CustomTextField/InputFieldwrapper';
import PasswordField from '../../../components/Password/LoginPassword';
import { toast } from '../../../components/Toast/CustomToast';
import { actionCreators } from '../../../state';
import { useNavigate } from 'react-router';
import { getMainRoute } from '../../../RoleAccess';

interface ISigninFormInputs {
  email: string;
  password: string;
}

const schema = yup
  .object({
    email: yup
      .string()
      .min(5, 'Email must be at least 5 characters')
      .email('Invalid email format')
      .required(),
    password: yup.string().min(8, 'Password must be at least 8 characters').required(),
  })
  .required();

export default function Signin(props: any) {
  const [checked, setChecked] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setUser, deletePartner } = bindActionCreators(actionCreators, dispatch);

  const LoginAPI = useMutation(
    'loginpwithemail',
    async (data: LoginWithEmail) => await AuthAPI.loginWithEmail(data),
    {
      onSuccess: (response: any) => {
        if (response.status >= 200 && response.status <= 210) {
          if (
            ['fleetmax_supervisor', 'fleetmax_csr', 'fleetmax_vendor', 'fleetmax_partner'].includes(
              response.data.data.role
            )
          ) {
            const capitalizedVendorName = response.data.data.name
              .toLowerCase()
              .split(' ')
              .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');
            toast.success(`${capitalizedVendorName} is Logged In`);
            const userData = response.data.data;
            navigate(getMainRoute(userData?.role));
            setUser(userData);
            deletePartner();
          } else {
            toast.error(`unauthorized`);
          }
        }
      },
      onError: (error: any) => {
        console.log(error);
      },
    }
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ISigninFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmitHandler = (data: any) => {
    LoginAPI.mutate(data);
  };

  useEffect(() => {
    document.getElementById('SignIN')?.addEventListener('keydown', function (event: any) {
      if (event.keyCode === 13) {
        handleSubmit(onSubmitHandler)();
      }
    });
  }, []);

  return (
    <div
      className='w-full h-full flex px-10 bg-[url("/public/images/bgReducedSize.jpg")] bg-no-repeat bg-cover bg-right'
      id='SignIN'>
      {/* <InvoiceModal
          handleClose={() => {}}
          item={{}}
          handleMoveStage={()=>{}}
        /> */}
      {/* <EditableServices /> */}
      <div className='max-w-lg w-full auth-form signin bg-light-sign-in m-auto shadow-customShadow rounded-3xl px-10 py-16 backdrop-blur-sm flex flex-col gap-[4rem]'>
        <div className='hidden lg:block'>
          <h3 className=' text-3xl font-bold text-text-color'>Login</h3>
        </div>
        <form className='px-4 lg:px-0' onSubmit={handleSubmit(onSubmitHandler)}>
          <div className='mb-8 form-group'>
            <div className='w-full p-input-icon-right'>
              <Controller
                control={control}
                name='email'
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextFieldWrapperOne>
                    <TextField
                      id='outlined-basic'
                      label='Email'
                      variant='outlined'
                      autoFocus
                      size='small'
                      autoComplete='off'
                      helperText={errors.email?.message}
                      onChange={onChange}
                      value={value}
                      error={!!errors.email?.message}
                      onBlur={onBlur}
                      sx={{ borderRadius: '0.9rem' }}
                      onDragStart={(event) => {
                        event.preventDefault();
                      }}
                      inputProps={{
                        maxLength: '65',
                        draggable: false,
                        form: {
                          autocomplete: 'off',
                        },
                        style: {
                          userSelect: 'none',
                        },
                      }}
                    />
                  </TextFieldWrapperOne>
                )}
              />
            </div>
          </div>
          <div className='mb-8 form-group'>
            <div className='w-full'>
              <Controller
                control={control}
                name='password'
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextFieldWrapperOne>
                    <PasswordField
                      onPaste={(event: any) => {
                        event.preventDefault();
                      }}
                      id='outlined-basic'
                      size='small'
                      label='Password'
                      variant='outlined'
                      helperText={errors.password?.message}
                      onChange={onChange}
                      value={value}
                      type='password'
                      error={!!errors.password?.message}
                      onBlur={onBlur}
                      sx={{
                        borderRadius: '0.9rem',
                      }}
                      inputProps={{
                        maxLength: '16',
                        form: {
                          autocomplete: 'off',
                        },
                      }}
                    />
                  </TextFieldWrapperOne>
                )}
              />
            </div>
          </div>
          <div className='flex w-full justify-between items-center mb-[4rem]'>
            <div className='flex gap-3 items-center'>
              <Checkbox
                onChange={(e) => setChecked(e.target.checked)}
                checked={checked}
                disableRipple
                sx={{ padding: 0 }}
              />
              <p>Keep me logged in</p>
            </div>
            <a
              href='/forgot-password'
              className='block text-base text-right text-primary font-[600]'>
              Forgot Password ?
            </a>
          </div>
          <div className='flex justify-center'>
            <Button
              label={LoginAPI.isLoading ? '' : 'Login'}
              type='submit'
              onClick={handleSubmit(onSubmitHandler)}
              loading={LoginAPI.isLoading}
              className='w-[40%] bg-primary rounded-lg font-bold border-none py-2'
            />
          </div>
        </form>

        <div className='flex justify-center items-center gap-3'>
          <a
            className='text-primary font-bold w-1/2 flex justify-end'
            href='https://www.lmdmax.com/termsandcondition'>
            Terms and Conditions{' '}
          </a>
          <Divider layout='vertical' className='bg-primary w-[0.2rem] p-0 m-0 h-1/2' />
          <a className='text-primary font-bold w-1/2' href='https://www.lmdmax.com/privacypolicy'>
            Privacy Policy{' '}
          </a>
        </div>
      </div>
    </div>
  );
}
