const Content = ({
  heading,
  subHeading,
  textWeight,
}: {
  heading: any;
  subHeading: string;
  textWeight: any;
}) => {
  return (
    <div>
      <p className='text-[#A0A0A0] text-[0.9rem]'>{heading}</p>
      <p className={`max-w-[15rem] overflow-auto break-all font-${textWeight}`}>
        {`${subHeading ? subHeading : '----'}`}
      </p>
    </div>
  );
};

const VanType = ({ item, fontWeight }: { item: any; fontWeight?: string }) => {
  const textWeight = fontWeight || 'normal';
  return (
    <>
      <div
        style={{
          marginBottom: '',
        }}>
        <div
          className='w-full rounded-t-[1rem] mt-1'
          style={{
            border: '0.06rem solid #E4E4E4',
          }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 2fr)',
              gridGap: '0.438rem',
              padding: '0.625rem',
              alignItems: 'center',
              borderBottom: '0.063rem solid #E4E4E4',
            }}>
            <Content
              heading={'Van Type'}
              subHeading={item.vehicle.vehicle_type}
              textWeight={textWeight}
            />
            <Content
              heading={'Registered State type'}
              subHeading={item.vehicle.state}
              textWeight={textWeight}
            />
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 2fr)',
              gridGap: '0.438rem',
              padding: '0.625rem',
              alignItems: 'center',
              borderBottom: '0.063rem solid #E4E4E4',
            }}>
            <Content
              heading={'Vehicle Model'}
              subHeading={item.vehicle.model}
              textWeight={textWeight}
            />
            <Content
              heading={'Van Sub-Type'}
              subHeading={item.vehicle.vehicle_sub_type}
              textWeight={textWeight}
            />
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)',
              gridGap: '0.438rem',
              padding: '0.625rem',
              alignItems: 'center',
            }}>
            <Content heading={'Year'} subHeading={item.vehicle.year} textWeight={textWeight} />
            <Content heading={'Trim'} subHeading={item.vehicle?.trim} textWeight={textWeight} />
            <Content heading={'Make'} subHeading={item.vehicle.make} textWeight={textWeight} />
          </div>
        </div>
      </div>
    </>
  );
};
export default VanType;
