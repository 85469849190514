const DownloadInvoiceButton = (props: { height?: string; width?: string; active?: boolean }) => {
  const height = props?.height || '1.2rem';
  const width = props?.width || '1.2rem';
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 18 19'
      fill='none'>
      <path
        d='M17.1 11.3C16.8613 11.3 16.6324 11.3948 16.4636 11.5636C16.2948 11.7324 16.2 11.9613 16.2 12.2V15.8C16.2 16.0387 16.1052 16.2676 15.9364 16.4364C15.7676 16.6052 15.5387 16.7 15.3 16.7H2.7C2.46131 16.7 2.23239 16.6052 2.0636 16.4364C1.89482 16.2676 1.8 16.0387 1.8 15.8V12.2C1.8 11.9613 1.70518 11.7324 1.5364 11.5636C1.36761 11.3948 1.13869 11.3 0.9 11.3C0.661305 11.3 0.432387 11.3948 0.263604 11.5636C0.0948211 11.7324 0 11.9613 0 12.2V15.8C0 16.5161 0.284464 17.2028 0.790812 17.7092C1.29716 18.2155 1.98392 18.5 2.7 18.5H15.3C16.0161 18.5 16.7028 18.2155 17.2092 17.7092C17.7155 17.2028 18 16.5161 18 15.8V12.2C18 11.9613 17.9052 11.7324 17.7364 11.5636C17.5676 11.3948 17.3387 11.3 17.1 11.3ZM8.361 12.839C8.44659 12.9209 8.54752 12.9852 8.658 13.028C8.76573 13.0756 8.88222 13.1002 9 13.1002C9.11778 13.1002 9.23427 13.0756 9.342 13.028C9.45248 12.9852 9.55341 12.9209 9.639 12.839L13.239 9.239C13.4085 9.06953 13.5037 8.83967 13.5037 8.6C13.5037 8.36033 13.4085 8.13047 13.239 7.961C13.0695 7.79153 12.8397 7.69632 12.6 7.69632C12.3603 7.69632 12.1305 7.79153 11.961 7.961L9.9 10.031V1.4C9.9 1.16131 9.80518 0.932387 9.6364 0.763604C9.46761 0.594821 9.23869 0.5 9 0.5C8.7613 0.5 8.53239 0.594821 8.3636 0.763604C8.19482 0.932387 8.1 1.16131 8.1 1.4V10.031L6.039 7.961C5.95509 7.87709 5.85546 7.81052 5.74582 7.76511C5.63618 7.71969 5.51867 7.69632 5.4 7.69632C5.28133 7.69632 5.16382 7.71969 5.05418 7.76511C4.94454 7.81052 4.84491 7.87709 4.761 7.961C4.67709 8.04491 4.61052 8.14454 4.56511 8.25418C4.51969 8.36381 4.49632 8.48133 4.49632 8.6C4.49632 8.71867 4.51969 8.83618 4.56511 8.94582C4.61052 9.05546 4.67709 9.15509 4.761 9.239L8.361 12.839Z'
        fill={props?.active ? '#1C81CA' : '#808080'}
      />
    </svg>
  );
};
export default DownloadInvoiceButton;
