import React from 'react';
import { TooltipProps } from 'recharts';

const CustomTooltip: React.FC<TooltipProps<any, any>> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: 'rgba(112, 111, 110, 1)',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
        }}>
        <p>{`${label?.startsWith('W', 0) ? 'Week' : 'Month'}: ${label}`}</p>
        <p>{`Revenue: $ ${payload?.[0]?.value}`}</p>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
