import { request } from './base';
import { IAcceptCSRequestBulk, IRejectPartnerRequest, ISchduleVendorTechnician } from './types';
export default class PartnerApi {
  static getAllVendor(data?: any) {
    return request({
      url: `/partner/v1/vendors`,
      method: 'get',
    });
  }

  static getAllRequestDetails(data: any) {
    return request({
      url: `/partner/v1/requests?${data.params}`,
      method: 'get',
    });
  }
  static acceptPartnerRequest(data: IAcceptCSRequestBulk) {
    return request({
      url: `/partner/v1/accept_request`,
      method: 'patch',
      data: data,
    });
  }
  static rejectPartnerRequest(data: IRejectPartnerRequest) {
    return request({
      url: `/partner/v1/reject_request`,
      method: 'patch',
      data: data,
    });
  }
  static getAllDataParticularId(query: string) {
    return request({
      url: `/partner/v1/request_info?${query}`,
      method: 'get',
    });
  }

  static bulkScheduleVendorTechnician(data: ISchduleVendorTechnician) {
    return request({
      url: `/partner/v1/assign`,
      method: 'patch',
      data: data,
    });
  }
  static setInvoiceView(data: any) {
    return request({
      url: `/partner/v1/invoice_management_view`,
      method: 'patch',
      data: data.data,
    });
  }

  static getAllInvoiceView(data?: any) {
    return request({
      url: `/partner/v1/invoice_management_view${data?.params ? data?.params : ''}`,
      method: 'get',
    });
  }

  static getAllTechnician(data?: any) {
    return request({
      url: `/partner/v1/all_technicians`,
      method: 'get',
    });
  }

  static getVendorAccess(data?: {
    vendor: {
      email: string;
      vendor_account_id: string;
    };
  }) {
    return request({
      url: `/users/v1/get_vendor_access_for_partner`,
      method: 'post',
      data: data,
    });
  }
  //   static unassignInspectionDSP(data?: any) {
  //     return request({
  //       url: `/csr_inspection/v1/unassign_dsp/${data?.id}`,
  //       method: 'patch',
  //     });
  //   }

  //   static deleteQuestion(data?: any) {
  //     return request({
  //       url: `/csr_inspection/v1/delete_question/${data?.id}`,
  //       method: 'delete',
  //     });
  //   }
}
