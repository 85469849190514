import StatusTrackerPopButton from '../../../../common/requestDetail/StatusTrackerPopButton';
import VerticalLinearStepper from './VerticalLinearStepper';

export default function StatusTrackerPopUp({
  item,
  variant,
  size,
  noPopUp,
  notesValue,
  type,
}: {
  item?: any;
  variant?: string;
  size?: string;
  noPopUp?: boolean;
  notesRemarks?: any;
  notesValue?: JSX.Element;
  type?: string;
}) {
  if (+item?.status_id < 5) return null;

  const getTitle = () => {
    const { status_id } = item;
    if (+status_id === 5) {
      return 'To-Do';
    } else if (+status_id === 6) {
      return 'Service Started';
    } else if (+status_id === 7 || +status_id === 8) {
      return 'Upload Work Proof';
    } else if (+status_id === 9) {
      return 'Approved';
    } else if (+status_id === 10) {
      return 'Rejected';
    } else if (+status_id === 11) {
      return 'Approved';
    } else if (+status_id === 12) {
      return 'Completed';
    } else {
      return 'Completed';
    }
  };

  return (
    <div className='w-full'>
      <StatusTrackerPopButton
        noPopUp={noPopUp}
        showOnExpand={
          <div
            className={`container overflow-auto  mb-2 p-2  ${
              size === 'large' ? 'h-[20rem]' : 'h-[23rem]'
            }`}
            style={{
              backgroundColor: 'white',
              borderRadius: '0.6rem',
            }}>
            {notesValue ? notesValue : <VerticalLinearStepper item={item} />}
          </div>
        }
        variant={variant}
        title={getTitle()}
        item={item}
      />
    </div>
  );
}
