import moment from 'moment';

export const SortCreated = (data: any) => {
  if (data && data.length > 0) {
    return data?.sort(
      (a: any, b: any) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf()
    );
  }
  return [];
};

export const SortUpdated = (data: any) => {
  if (data && data.length > 0) {
    return data?.sort(
      (a: any, b: any) =>
        moment(b?.updated_at || b?.created_at).valueOf() -
        moment(a?.updated_at || a?.created_at).valueOf()
    );
  }
  return [];
};

export const checkSortCode = (elem: any, shortCode?: string) => {
  if (shortCode === 'All') {
    return true;
  } else if (elem.dsp.dsp_short_code === shortCode) {
    return true;
  } else {
    return false;
  }
};

export const checkExist = (target: string, item: any) => {
  const { dsp, vehicle, miles, curr_miles } = item;
  if (
    dsp?.dsp_short_code?.toLowerCase()?.includes(target) ||
    vehicle?.name?.toLowerCase()?.includes(target) ||
    vehicle?.vin?.toLowerCase()?.includes(target) ||
    vehicle?.plate?.toLowerCase()?.includes(target) ||
    miles?.toLowerCase()?.includes(target) ||
    curr_miles?.toString()?.toLowerCase()?.includes(target)
  ) {
    return item;
  }
};

export const updateModalData = (map: any, model: any, setModel: any) => {
  if (model?.open) {
    const data = map.get(model.data.id);
    if (!data) {
      setModel({ ...model, open: false });
      return;
    }
    if (data) {
      setModel({ ...model, data: data });
    }
  }
};
export const updateData = (map: any, model: any, setModel: any) => {
  if (model) {
    const data = map.get(model?.id);
    if (!data) {
      setModel(model);
      return;
    }
    if (data) {
      setModel(model);
    }
  }
};

export const updateBulkModalData = (
  map: any,
  model: any,
  setModel: any,
  singleReqMap: any = null,
  setSingleModal: any = null
) => {
  if (model?.open) {
    const data = map.get(model?.data?.bulk_id);
    if (!data) {
      setModel({ ...model, open: false });
    }
    if (data) {
      setModel({ ...model, data: data });
    }
    if (singleReqMap && setSingleModal && model?.data?.bulkSchedules) {
      const currentReqInBulkData = model?.data?.bulkSchedules;
      let found = null;
      currentReqInBulkData?.forEach((d: any) => {
        const data = singleReqMap.get(d?.id || d?.preventive_request_id);
        if (data) {
          found = data;
        }
      });
      if (found) {
        setSingleModal({ open: true, data: found });
      }
    }
  }
};
