import { Tooltip } from '@mui/material';

function PriorityIcons({ iconType, disabled, weidth, height, noTooltip }: any) {
  const w = weidth ? weidth : '2rem';
  const h = height ? height : '2rem';

  if (iconType === '1') {
    return (
      <Tooltip title={noTooltip ? '' : 'Priority #1'}>
        <div
          className={`w-[${w}] h-[${h}] rounded-full  flex justify-center items-center ${
            !disabled ? 'text-[#FF5630]' : 'text-gray-400 bg-gray-100'
          }   `}
          style={!disabled ? { background: 'rgba(242, 209, 91, 0.11)' } : {}}>
          <i className='pi pi-chevron-up text-[1rem] font-bold'></i>
        </div>
      </Tooltip>
    );
  } else if (iconType === '2') {
    return (
      <Tooltip title={noTooltip ? '' : 'Priority #2'}>
        <div
          className={`w-[${w}] h-[${h}] rounded-full flex justify-center items-center text-[1.5rem] ${
            !disabled ? 'text-[#F2D15B]' : 'text-gray-400 bg-gray-100'
          }  font-bold`}
          style={!disabled ? { background: 'rgba(242, 209, 91, 0.11)' } : {}}>
          =
        </div>
      </Tooltip>
    );
  } else return null;
}

export default PriorityIcons;

export function BulkPriorityStatus({ priority, width, height }: any) {
  const w = width ? width : '3rem';
  const h = height ? height : '1.5rem';

  if (priority === '1') {
    return (
      <Tooltip title={'Priority #1'}>
        <div
          className={`w-[${w}] h-[${h}] rounded-[0.4rem] flex justify-center items-center text-[#FF5630] border-[0.1rem] border-[#FF5630] gap-2`}
          style={{ background: 'rgba(242, 209, 91, 0.11)' }}>
          <i className='pi pi-chevron-up text-[0.8rem] font-bold'></i>
          <p className='text-black font-normal text-[0.8rem]'>#1</p>
        </div>
      </Tooltip>
    );
  } else if (priority === '2') {
    return (
      <Tooltip title={'Priority #2'}>
        <div
          className={`w-[${w}] h-[${h}] rounded-[0.4rem] flex justify-center items-center text-[1rem] text-[#F2D15B] font-bold border-[0.1rem] border-[#F2D15B] gap-2`}
          style={{ background: 'rgba(242, 209, 91, 0.11)' }}>
          =<p className='text-black font-normal text-[0.8rem]'>#2</p>
        </div>
      </Tooltip>
    );
  } else return null;
}

export function SinglePriorityStatus({ priority, width, height }: any) {
  const w = width ? width : '6rem';
  const h = height ? height : '1.5rem';

  if (priority === '1') {
    return (
      <Tooltip title={'Priority #1'}>
        <div
          className={`w-[${w}] h-[${h}] rounded-[0.4rem] flex justify-center items-center text-[#FF5630] border-[0.1rem] border-[#FF5630] gap-2`}
          style={{ background: 'rgba(242, 209, 91, 0.11)' }}>
          <i className='pi pi-chevron-up text-[0.8rem] font-bold'></i>
          <p className='text-black font-normal text-[0.8rem]'>#1 Priority</p>
        </div>
      </Tooltip>
    );
  } else if (priority === '2') {
    return (
      <Tooltip title={'Priority #2'}>
        <div
          className={`w-[${w}] h-[${h}] rounded-[0.4rem] flex justify-center items-center text-[1rem] text-[#F2D15B] font-bold border-[0.1rem] border-[#F2D15B] gap-2`}
          style={{ background: 'rgba(242, 209, 91, 0.11)' }}>
          =<p className='text-black font-normal text-[0.8rem]'>#2 Priority</p>
        </div>
      </Tooltip>
    );
  } else return null;
}
