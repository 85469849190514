import { Menu, MenuItem } from '@mui/material';
import * as React from 'react';
import SelectBtn from '../../../scheduleRequest/components/SelectBtn';
import Tootltip from '../../../../../components/BarData/Tootltip';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../state/reducers';

export default function BasicPopover({
  invoiceData,
  getStatus,
  setItem,
  item,
  selected,
  handleClose: handleCloseInvoiceModel,
}: any) {
  const { user } = useSelector((state: RootState) => state.userState);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [vehicles, setVehciles] = React.useState(null as any);

  const filterForInvoicing = (vehicles: any[]) => {
    const vendorMustBeDifferent = (item: any) =>
      item?.vendor?.account_id !== item?.partner?.account_id;
    const mustBeEligibleForInvoicing = (d: any) => ['9', '11'].includes(d.status_id);

    return vehicles.filter((d) => {
      if (user?.role === 'fleetmax_partner') {
        return mustBeEligibleForInvoicing(d) && vendorMustBeDifferent(d) && d?.lease_plan;
      } else {
        return mustBeEligibleForInvoicing(d) && d?.lease_plan;
      }
    });
  };

  React.useEffect(() => {
    if (invoiceData?.bulkSchedules) {
      setVehciles(filterForInvoicing(invoiceData.bulkSchedules));
    }
  }, [invoiceData]);

  React.useEffect(() => {
    if (vehicles) {
      if (item) {
        const found = vehicles.find((d: any) => d.id === item.id);
        if (found) {
          setItem(found);
        } else if (vehicles?.length > 0) {
          setItem(vehicles[0]);
        } else {
          handleCloseInvoiceModel();
        }
      } else {
        setItem(selected);
      }
    }
  }, [vehicles]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (!invoiceData.bulkSchedules) {
    return null;
  }

  return (
    <div>
      <SelectBtn
        title={getStatus(item)}
        onClick={handleClick}
        up={!!anchorEl}
        value={
          <Tootltip placement={'bottom'}>
            <p className='text-ellipsis whitespace-nowrap overflow-hidden w-[8rem]'>
              {item?.vehicle?.name}
            </p>
          </Tootltip>
        }
        classes={'w-[21rem]'}
      />
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            minWidth: '21rem',
            marginTop: '0.5rem',
          },
        }}
        open={open}
        onClose={handleClose}>
        {vehicles?.map((elem: any, index: number) => (
          <MenuItem
            key={elem?.id}
            onClick={() => {
              setItem(elem);
              handleClose();
            }}
            sx={{
              backgroundColor: item?.id === elem?.id ? '#E8EEF8' : '',
            }}>
            <div className='flex justify-between w-full'>
              <Tootltip placement={'bottom'}>
                <p className='text-ellipsis whitespace-nowrap overflow-hidden w-[8rem] font-bold'>
                  {elem?.vehicle?.name}
                </p>
              </Tootltip>
              {getStatus(elem)}
            </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
