import React, { useMemo } from 'react';
import OverlayContainer from '../../../../../../components/OverlayContainer';
import ModalForm from '../../../../../../components/ModalForm';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../state/reducers';
import { DSPData, ModelProps, VehicleType } from './Types';
import { AdminList, compare, DSPList, MapVehicles } from './Functions';
import { Section } from './Headings';
import { BottomActions } from './BottomActions';
import { useLogic } from './useLogic';
import { Loader } from './Loader';

export const Model = (props: ModelProps) => {
  const { selectedData } = useSelector((state: RootState) => state.userState);
  const { handleClose, disabled, data, selectedDSP, setSelectedDSP } = props;
  const { loader, allSelected, handleSelectAdmin, filteredVehicles, onSave, onRemove } =
    useLogic(props);
  const handleSelectDSP = (dsp: DSPData) => {
    setSelectedDSP(dsp.id);
  };
  const isSaveDisabled = useMemo(
    () => compare(selectedData || [], allSelected),
    [selectedData, allSelected]
  );
  const DSPs = <DSPList data={data} selectedDSP={selectedDSP} onSelectDSP={handleSelectDSP} />;
  const Vehicles = <MapVehicles vehicles={filteredVehicles as VehicleType[]} />;
  const Admins = (
    <AdminList
      selectedDSP={selectedDSP}
      data={data}
      allSelected={allSelected}
      disabled={disabled}
      onSelectAdmin={handleSelectAdmin}
    />
  );
  const disable: boolean = allSelected.length < 1;
  const removeBtn = <BottomActions type='remove' disabled={disable} onClick={onRemove} />;
  const saveBtn = <BottomActions disabled={isSaveDisabled} onClick={onSave} />;
  return (
    <OverlayContainer show={true}>
      <ModalForm title={''} onClose={handleClose} width={'w-[60vw]'} noLabel>
        <div className='h-[40rem] flex flex-col justify-between mt-6 '>
          {loader ? (
            <Loader />
          ) : (
            <React.Fragment>
              <div className='h-[90%] flex justify-between'>
                <Section type='DSP'>{DSPs}</Section>
                <Section type='Vehicles'>{Vehicles}</Section>
                <Section type='Admins'>{Admins}</Section>
              </div>
              <div className='flex justify-end w-full gap-5'>
                {removeBtn}
                {saveBtn}
              </div>
            </React.Fragment>
          )}
        </div>
      </ModalForm>
    </OverlayContainer>
  );
};
