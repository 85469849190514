export default function (props: { height?: string; width?: string; active?: boolean }) {
  const height = props?.height || 16;
  const width = props?.width || 27;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 28 36'
      fill='none'>
      <path
        d='M20.0817 0.0878906H20.0719L27.2325 7.55438V35.9107H0.503906V35.9993H27.3211V7.64297L20.0817 0.0878906Z'
        fill='#949494'
      />
      <path d='M19.9827 0H0.414062V35.9114H27.232V7.55508L19.9827 0Z' fill='#F1F1F1' />
      <path d='M9.72295 15H4V17H9.72295H22V15H9.72295Z' fill='#808080' />
      <path d='M9.72295 19H4V21H9.72295H22V19H9.72295Z' fill='#808080' />
      <path d='M9.72295 23H4V25H9.72295H22V23H9.72295Z' fill='#808080' />
      <path d='M19.8828 0.0976562V7.65274H27.1327L19.8828 0.0976562Z' fill='#949494' />
      <path d='M19.9844 0V7.55508H27.2336L19.9844 0Z' fill='#F1F1F1' />
    </svg>
  );
}
