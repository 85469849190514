const SVG = (props: { height?: string; width?: string; active?: boolean }) => {
  const height = props?.height || '1.3rem';
  const width = props?.width || '2.3rem';
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g id='Group 40980'>
        <g id='Group 39558'>
          <path
            id='Vector'
            d='M6.08139 1C7.33701 1 8.45993 2.12283 8.45993 3.37958C8.45993 4.63634 7.33701 5.75916 6.08139 5.75916C4.82576 5.75916 3.70284 4.63634 3.70284 3.37958C3.70284 2.12283 4.82576 1 6.08139 1ZM5.91246 9.11099C7.15804 9.11099 8.35191 9.64576 9.25524 10.7663C10.1674 11.8978 10.8249 13.6852 10.8249 16.222C10.8249 16.5175 10.596 16.9634 9.59188 17.3827C8.64262 17.7792 7.30569 18 5.91246 18C4.51922 18 3.18229 17.7792 2.23303 17.3827C1.22888 16.9634 1 16.5175 1 16.222C1 13.6852 1.65756 11.8978 2.56967 10.7663C3.473 9.64576 4.66688 9.11099 5.91246 9.11099Z'
            stroke='#AC9059'
            stroke-width='2'
          />
        </g>
        <path
          id='Vector_2'
          d='M14.8617 0C12.4316 0 10.4434 1.98826 10.4434 4.41835C10.4434 6.84845 12.4316 8.83671 14.8617 8.83671C17.2918 8.83671 19.2801 6.84845 19.2801 4.41835C19.2801 1.98826 17.2918 0 14.8617 0ZM15.7454 6.05314C15.5245 6.18569 15.2594 6.09733 15.1268 5.87641L14.4641 4.63927C14.4199 4.5509 14.4199 4.50672 14.4199 4.41835V2.20918C14.4199 1.94408 14.5966 1.76734 14.8617 1.76734C15.1268 1.76734 15.3035 1.94408 15.3035 2.20918V4.2858L15.9221 5.43457C16.0105 5.65549 15.9663 5.92059 15.7454 6.05314Z'
          fill='#AC9059'
        />
      </g>
    </svg>
  );
};
export default SVG;
