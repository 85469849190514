import { useRef } from 'react';
import { GetInfoIconVendor } from './InfoIcon';
import { BulkPriorityStatus } from '../../scheduleRequest/components/PriorityIcons';
import { Tooltip } from '@mui/material';

const VechileInfo = ({ item, active, setSelectedVehicle }: any) => {
  const ref: any = useRef();

  return (
    <div className='h-auto  bg-white w-full' ref={ref} style={{}}>
      <div
        className={`flex flex-col
      rounded-lg w-full border-[0.2rem] ${
        active ? ' border-[#E8EEF8] bg-tableHead' : 'border-white'
      }`}
        style={{
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
        }}>
        <div
          className='flex justify-between items-center cursor-pointer px-3 p-2'
          onClick={() => setSelectedVehicle(item)}>
          <p className='flex justify-between items-center gap-2'>
            {item.vehicle.name} <BulkPriorityStatus priority={item?.priority || '2'} />
            {active && (
              <Tooltip title={'Selected Vehicle'}>
                <i className='pi pi-circle-fill text-primary font-bold text-[0.4rem]'></i>
              </Tooltip>
            )}
          </p>
          <div className='flex gap-2 items-center'>
            <div className='w-[3.5rem]'>
              <GetInfoIconVendor item={item} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VechileInfo;
