import PartnerApi from '../../../../api/partnerSpecificApi';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state';
import HandleLoad from '../../../../components/Loader/HandleLoad';
import { PartnerVendorData } from '../types/types';

const useFetchPartnerVendors = () => {
  const loader = HandleLoad();
  const dispatch = useDispatch();
  const { setPartnerVendors } = bindActionCreators(actionCreators, dispatch);

  const formatNumber = (num: number) => {
    if (num < 10) {
      return `0${num}`;
    } else if (num % 1 !== 0) {
      return num.toFixed(10);
    } else {
      return num;
    }
  };
  const fetchPartnerVendors = async () => {
    loader(true);
    PartnerApi.getAllVendor().then(
      (res: { status: number; data: { data: PartnerVendorData[] } }) => {
        if (res.status === 200) {
          setPartnerVendors(res?.data?.data);
        }
        loader(false);
      }
    );
  };

  return { formatNumber, fetchPartnerVendors };
};

export default useFetchPartnerVendors;
