const SVG = (props: { height?: string; width?: string; active?: boolean }) => {
  const height = props?.height || '2rem';
  const width = props?.width || '3.5rem';
  return (
    <div
      className='bg-[#EAF8E8] flex items-center rounded-[0.6rem] justify-center'
      style={{
        height: height,
        width: width,
      }}>
      <i className='pi pi-check-circle text-[1.1rem] text-[#59AC61]'></i>
    </div>
  );
};
export default SVG;
