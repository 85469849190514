const RTSInspectionIcon = (props: { height?: string; width?: string; active?: boolean }) => {
  const height = props?.height || '2.5rem';
  const width = props?.width || '2rem';
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 44'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.7004 18.3322V17.4515C14.7004 17.3708 14.6382 17.3047 14.5621 17.3047H7.92406C7.848 17.3047 7.78577 17.3708 7.78577 17.4515V18.3322C7.78577 18.413 7.848 18.479 7.92406 18.479H14.5621C14.6382 18.479 14.7004 18.413 14.7004 18.3322ZM7.92406 19.9469C7.848 19.9469 7.78577 20.013 7.78577 20.0937V20.9744C7.78577 21.0552 7.848 21.1212 7.92406 21.1212H11.1048C11.1809 21.1212 11.2431 21.0552 11.2431 20.9744V20.0937C11.2431 20.013 11.1809 19.9469 11.1048 19.9469H7.92406ZM10.4133 28.2405H6.40284V15.3231H16.0833V21.635C16.0833 21.7157 16.1456 21.7818 16.2216 21.7818H17.1897C17.2657 21.7818 17.328 21.7157 17.328 21.635V14.5891C17.328 14.2643 17.0808 14.002 16.7748 14.002H5.71137C5.4054 14.002 5.1582 14.2643 5.1582 14.5891V28.9744C5.1582 29.2992 5.4054 29.5616 5.71137 29.5616H10.4133C10.4894 29.5616 10.5516 29.4955 10.5516 29.4148V28.3873C10.5516 28.3065 10.4894 28.2405 10.4133 28.2405ZM18.118 29.1854L16.5051 27.4735C16.8906 26.9322 17.1205 26.257 17.1205 25.5249C17.1205 23.7414 15.7583 22.2955 14.0781 22.2955C12.3978 22.2955 11.0356 23.7414 11.0356 25.5249C11.0356 27.3084 12.3978 28.7542 14.0781 28.7542C14.6969 28.7542 15.2709 28.5579 15.7514 28.2221L17.3885 29.9598C17.4161 29.9891 17.4507 30.002 17.4853 30.002C17.5198 30.002 17.5561 29.9873 17.5821 29.9598L18.118 29.3909C18.1307 29.3775 18.1408 29.3615 18.1477 29.3438C18.1546 29.3262 18.1582 29.3073 18.1582 29.2882C18.1582 29.2691 18.1546 29.2502 18.1477 29.2326C18.1408 29.2149 18.1307 29.1989 18.118 29.1854ZM14.0781 27.5799C13.008 27.5799 12.142 26.6607 12.142 25.5249C12.142 24.3891 13.008 23.4698 14.0781 23.4698C15.1481 23.4698 16.0142 24.3891 16.0142 25.5249C16.0142 26.6607 15.1481 27.5799 14.0781 27.5799Z'
        fill='#4378C8'
      />
    </svg>
  );
};

export default RTSInspectionIcon;
