import { useEffect } from 'react';
import UserPartnerCard from './UserPartnerCard';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../state/reducers';
import useFetchPartnerVendors from '../../hooks/useFetchPartnerVendors';
import { useSearchParams } from 'react-router-dom';

function PartnersVendors() {
  const { partnerVendors }: any = useSelector((state: RootState) => state.userState);
  const { fetchPartnerVendors, formatNumber } = useFetchPartnerVendors();
  const [urlParams]: any = useSearchParams();
  useEffect(() => {
    fetchPartnerVendors();
  }, []);
  return (
    <div className='flex flex-col gap-2 rounded-lg border border-gray-300 w-full h-full p-1 shadow-md'>
      <p className='px-3 pt-1 flex items-center'>
        Vendors (
        {partnerVendors?.length === undefined ? '00' : formatNumber(partnerVendors?.length)})
        {urlParams?.get('vendor_ids') && (
          <p className='flex items-center bg-[#F6EFD3] text-[#AC9059] gap-2 py-1 px-2 ml-2 rounded-full text-[0.8rem]'>
            <i className='pi pi-info-circle'></i> {'  '}
            "Vendor" filter does not apply here!
          </p>
        )}
      </p>
      <div className='w-full h-[98%] pl-[1.2rem]  overflow-y-auto'>
        <div className='w-full flex flex-wrap pt-[1.0rem] gap-x-[1rem] gap-y-[1.5rem] '>
          {partnerVendors?.length > 0
            ? [...partnerVendors].map((item: any) => (
                <UserPartnerCard
                  name={item?.vendor?.name}
                  phone={item?.vendor?.phone}
                  address={item?.vendor?.address}
                  account_id={item?.vendor?.account_id}
                  email={item?.vendor?.email}
                  newRequests={parseInt(item?.count?.new_requests)}
                  accepted={parseInt(item?.count?.accepted)}
                  assigned={parseInt(item?.count?.assigned)}
                  disputed={parseInt(item?.count?.disputed)}
                  key={item?.vendor?.account_id || ''}
                />
              ))
            : ''}
        </div>
      </div>
    </div>
  );
}

export default PartnersVendors;
