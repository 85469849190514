import { useCallback, useState } from 'react';
import HandleLoad from '../../../../../components/Loader/HandleLoad';
import VendorAPI from '../../../../../api/vendorApi';
import CsrAPI from '../../../../../api/csrApi';

interface AdditionalInfo {
  service: 'string';
  price: 'string';
  custom?: boolean;
  name?: string;
  vendor_price?: string;
  id: any;
}

interface Invoice {
  number: string;
  additional_info: AdditionalInfo[];
  note: string;
  services: null; // Assuming services is always null based on the provided JSON
  total_amount: number;
}

interface Vendor {
  name: string;
  email: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  phone: string;
}

interface Dsp {
  name: string;
  short_code: string;
  station_address: string;
  station_code: string;
}

interface Vehicle {
  make: string;
  model: string;
  vin: string;
  license_plate: string;
  van_name: string;
}

interface Coordinates {
  lat: number;
  lng: number;
}

interface StationAddress {
  locationInfo: string;
  zipCode: string;
  stationCode: string;
  coordinates: Coordinates;
}

interface InvoiceData {
  invoice_date: string;
  service_date: string;
  invoice: Invoice;
  vendor: Vendor;
  dsp: Dsp;
  vehicle: Vehicle;
  station_address: StationAddress;
}

const emptyData: InvoiceData = {
  invoice_date: '',
  service_date: '',
  invoice: {
    number: '',
    additional_info: [],
    note: '',
    services: null,
    total_amount: 0,
  },
  vendor: {
    name: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zipcode: '',
    phone: '',
  },
  dsp: {
    name: '',
    short_code: '',
    station_address: '',
    station_code: '',
  },
  vehicle: {
    make: '',
    model: '',
    vin: '',
    license_plate: '',
    van_name: '',
  },
  station_address: {
    locationInfo: '',
    zipCode: '',
    stationCode: '',
    coordinates: {
      lat: 0,
      lng: 0,
    },
  },
};

const useFetchInvoiceDetails = () => {
  const loader = HandleLoad();
  const [invoiceLoader, setInvoiceLoader] = useState<boolean>(false);
  const [invoiceDetails, setInvoiceDetails] = useState<InvoiceData | any>(null);
  const [disputeHistory, setDisputeHistory] = useState<any>([]);
  const [disputeIdHistory, setDisputeIdHistory] = useState<any>([]);
  const [invoices, setInvoices] = useState<any>([]);
  const fetchInvoiceDetails = useCallback((data: any) => {
    if (!data) return;
    loader(true);
    VendorAPI.getInvoiceDetails({ id: String(data) }).then((res: any) => {
      if (res.status === 200) {
        setInvoiceDetails(res?.data?.data?.[0]);
      }
      loader(false);
    });
  }, []);
  const fetchDisputeHistory = useCallback((data: any) => {
    loader(true);
    VendorAPI.getDisputeHistory({
      id: String(data?.preventive_request_id || data?.id || data),
    }).then((res: any) => {
      if (res.status === 200) {
        setDisputeHistory(res?.data?.data);
      }
      loader(false);
    });
  }, []);
  const fetchDisputeIdHistory = useCallback((data: any) => {
    loader(true);
    VendorAPI.DisputeIdHistory({ id: String(data) }).then((res: any) => {
      if (res.status === 200) {
        setDisputeIdHistory(res?.data?.data);
      }
      loader(false);
    });
  }, []);
  const fetchInvoices = useCallback(async (data: any, noLoader?: boolean) => {
    if (data) {
      if (!noLoader) {
        loader(true);
      }
      CsrAPI.getAllInvoices({ id: String(data?.preventive_request_id || data?.id) }).then(
        (res: any) => {
          if (res.status === 200) {
            setInvoices(res?.data?.data);
          }
          if (!noLoader) {
            loader(false);
          }
        }
      );
    }
  }, []);

  return {
    invoiceDetails,
    disputeHistory,
    disputeIdHistory,
    invoices,
    fetchInvoiceDetails,
    fetchDisputeHistory,
    fetchDisputeIdHistory,
    fetchInvoices,
    invoiceLoader,
    setInvoiceLoader,
  };
};

export default useFetchInvoiceDetails;
