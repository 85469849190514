import * as React from 'react';
import { Divider, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Controller } from 'react-hook-form';
import EyeClose from '../../assets/PasswordClose';
import EyeOpen from '../../assets/PasswordOpen';

export default function InputAdornments(props: any) {
  const [showPassword, setShowPassword] = React.useState(false);
  const { control, errors, boxes, boxSx, noplabel } = props;
  return (
    <>
      {boxes.map((box: any) => {
        const { label } = box;
        return (
          <>
            {label === 'Password*' && !noplabel && (
              <div className='w-full flex items-center gap-3'>
                <p className='text-[#9B9B9B] font-[500]'>Create a Password</p>
                <div className='flex-1'>
                  <Divider />
                </div>
              </div>
            )}
            <div className={'flex w-full flex-col ' + boxSx}>
              {!noplabel && <p className='text-left w-full'>{label}</p>}
              <Controller
                control={control}
                name={label}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    sx={{ width: '100%' }}
                    id='outlined-basic'
                    size='small'
                    variant='outlined'
                    helperText={errors[label]?.message}
                    onChange={onChange}
                    value={value}
                    error={!!errors[label]?.message}
                    onBlur={onBlur}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={
                      ['Password*', 'New Password'].includes(label)
                        ? {
                            autoComplete: 'new-password',
                            endAdornment: (
                              <InputAdornment position='end' sx={{ marginLeft: 0 }}>
                                <IconButton
                                  aria-label='toggle password visibility'
                                  onClick={() => setShowPassword(!showPassword)}
                                  edge='end'
                                  sx={{ backgroundColor: 'transparent !important', paddingLeft: 0 }}
                                  disableRipple>
                                  {!showPassword ? (
                                    <EyeClose height={'1rem'} width={'2rem'} />
                                  ) : (
                                    <EyeOpen height={'0.7rem'} width={'2rem'} />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }
                        : {}
                    }
                    label={noplabel ? label : ''}
                  />
                )}
              />
            </div>
          </>
        );
      })}
    </>
  );
}
