export default function (props: { height?: string, width?: string, active?: boolean }) {
    const height = props?.height || 17
    const width = props?.width || 11
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 30 18" fill="none">
        <path d="M17.4721 4.82778C16.6428 3.39892 17.1305 1.5744 18.5614 0.752582L19.1789 0.397904C20.6098 -0.423909 22.442 0.0681951 23.2713 1.49705L29.5958 12.3944C30.4251 13.8233 29.9374 15.6478 28.5065 16.4696L27.889 16.8243C26.4581 17.6461 24.6259 17.154 23.7966 15.7252L17.4721 4.82778Z" fill="#B2D2E3"/>
        <path d="M7.56257 1.38912C8.48653 0.0198869 10.3481 -0.347124 11.7206 0.569379L12.3103 0.963212C13.6828 1.87972 14.0463 3.73267 13.1224 5.10191L6.07079 15.5518C5.14683 16.921 3.28522 17.288 1.91278 16.3715L1.32302 15.9777C-0.0494193 15.0612 -0.412982 13.2082 0.510983 11.839L7.56257 1.38912Z" fill="#187CB8"/>
        <path d="M17.8414 1.38912C18.7654 0.0198869 20.627 -0.347124 21.9994 0.569379L22.5892 0.963212C23.9616 1.87972 24.3252 3.73267 23.4012 5.10191L16.3496 15.5518C15.4257 16.921 13.5641 17.288 12.1916 16.3715L11.6019 15.9777C10.2294 15.0612 9.86587 13.2082 10.7898 11.839L17.8414 1.38912Z" fill="#187CB8"/>
      </svg>
    );
  }
  