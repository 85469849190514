import React, { useState } from 'react';
import ModalForm from '../../../components/ModalForm';
import OverlayContainer from '../../../components/OverlayContainer';
import { Button, Menu, MenuItem } from '@mui/material';
import PreventiveAPI from '../../../api/preventiveApi';
import { toast } from '../../../components/Toast/CustomToast';
import HandleLoad from '../../../components/Loader/HandleLoad';
import Tootltip from '../../../components/BarData/Tootltip';

function CancelRequestModel({ item, refetch }: { item: any; refetch?: any }) {
  const [showModel, setShowModel] = useState(false);
  const handleClose = () => {
    setShowModel(!showModel);
  };
  return (
    <div>
      {item &&
        !['3', '10', '12'].includes(item.status_id) &&
        !(['4'].includes(item.status_id) && ['3'].includes(item.sub_stage)) && (
          <>
            <div
              className='px-2 rounded-[0.5rem] hover:cursor hover:bg-gray-200'
              style={{ color: 'red', cursor: 'pointer' }}
              onClick={handleClose}>
              Cancel Request
            </div>
            {showModel && (
              <ModelContainerCancel handleClose={handleClose} item={item} refetch={refetch} />
            )}
          </>
        )}
    </div>
  );
}

export default CancelRequestModel;

export function ModelContainerCancel({
  handleClose,
  item,
  refetch,
}: {
  handleClose: () => void;
  item: any;
  refetch?: any;
}) {
  const loader = HandleLoad();
  const [notesValue, SetnotesValue] = useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedOption, SetSelectedOption] = React.useState('');
  const open = Boolean(anchorEl);
  const [firstPage, setFirstPage] = useState(true);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuItemClick: (time: string) => void = (time: string) => {
    setAnchorEl(null);
    SetSelectedOption(time);
  };

  const handleCloses = () => {
    setAnchorEl(null);
  };
  const dropdown = [
    'PM has been completed by another vendor',
    'PM is no longer required',
    'DSP is not interested ',
    'Other ',
  ];
  const isSchedulesTab: boolean =
    ['1', '2'].includes(item.status_id) ||
    (['4'].includes(item.status_id) && ['1'].includes(item?.sub_stage));

  const pendingCasePayload = {
    vehicle_data: {
      vehicle: item?.vehicle?.id.toString(),
      company: item?.vehicle?.company.toString(),
      preventive_maintenance_id: +item?.id,
    },
    note: notesValue?.trim(),
    reason: selectedOption,
  };
  const scheduledOrInProgressPayload = {
    preventive_request_id: +item?.id,
    note: notesValue?.trim(),
    reason: selectedOption,
    cancel_from: `${isSchedulesTab ? 'scheduled' : 'inprogress'}`,
  };
  const cancelRequest = (payload: any) => {
    loader(true);
    PreventiveAPI.postCancelRequest(payload)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Request cancelled Successfully');
          refetch();
          handleClose();
        } else {
          toast.error('Something Went Wrong');
        }
        loader(false);
      })
      .catch((err) => console.error(err));
  };
  const handleChange = (e: any) => {
    const inputValue = e.target.value;

    if (inputValue.length <= 250) {
      SetnotesValue(inputValue);
    }
  };
  return (
    <OverlayContainer show={true}>
      <ModalForm title={'Cancel Request'} onClose={handleClose} width={'w-[28rem]'} noLabel>
        <div className='w-full'>
          <div className='flex justify-center text-[2rem] mb-[1rem]'>Cancel Request</div>
          <div className='flex justify-center text-primary font-bold'>
            <div className='border-r-[0.2rem] border-primary pr-[1.5rem]'>Request {item?.id}</div>
            <div className='pl-[1.5rem]'>Vehicle {item?.vehicle?.name}</div>
          </div>
          {firstPage && (
            <>
              {' '}
              <div className='flex justify-center items-center w-full mt-[2rem]'>
                <button
                  className='border-transparent px-3 py-2 rounded-[0.3rem] w-full justify-between hover:bg-primary-light hover:text-primary-dark transition-all'
                  style={{
                    backgroundColor: 'rgba(193, 193, 193, 0.12)',
                  }}
                  value={selectedOption}
                  id='basic-button'
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}>
                  <p
                    className={`flex text-primary font-bold ${
                      !selectedOption && 'pt-2 mb-[-2rem]'
                    } ${!selectedOption ? 'text-[1rem]' : 'text-[0.8rem]'}`}>
                    {' '}
                    Select a Reason for Cancellation
                  </p>
                  <div className='flex justify-between items-center'>
                    <p
                      className={`text-[#515151] font-bold ${
                        selectedOption && 'mb-[-1rem] mt-[-0.8rem]'
                      }`}>
                      {selectedOption}
                    </p>

                    <i
                      className={`pi pi-angle-${
                        open ? 'up' : 'down'
                      } transition-transform duration-300 transform ml-2  text-[#C9C9C9] mb-[1rem] ${
                        !selectedOption ? 'mt-[0.6rem]' : 'mb-[1rem]'
                      } `}></i>
                  </div>
                </button>
                <Menu
                  id='basic-menu'
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleCloses}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  PaperProps={{
                    sx: {
                      width: '25rem',
                      marginTop: '0.5rem',
                    },
                  }}>
                  {dropdown.map((duration) => {
                    return (
                      <MenuItem
                        key={duration}
                        onClick={() => handleMenuItemClick(duration)}
                        // onClick={() => SetSelectedOption(duration)}
                        selected={selectedOption === duration}>
                        {duration}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </div>
              <div>
                <textarea
                  style={{ resize: 'none' }}
                  className='border px-4 py-2 rounded-[0.5rem] focus:border-primary  focus:outline-none w-full h-[10rem] mt-3 border-[#E3E3E3]'
                  placeholder='Add Notes'
                  value={notesValue}
                  // disabled={notesValue.length >= 250}
                  onChange={handleChange}
                />
                <p className='text-gray-400 font-bold'>{notesValue.length}/250</p>
                {notesValue.length >= 250 && (
                  <p className='text-[0.9rem]' style={{ color: 'red' }}>
                    You have reached the maximum limit
                  </p>
                )}
              </div>
              <Button
                variant='contained'
                className='w-full  text-white rounded-[0.5rem] font-normal py-[1rem] text-[1.1rem] mt-[5rem] '
                onClick={() => {
                  setFirstPage(!firstPage);
                }}
                disabled={notesValue?.trim() === '' || selectedOption === ''}>
                Proceed to Cancel
              </Button>
            </>
          )}
          {!firstPage && (
            <>
              <div>
                <div className='rounded-[0.5rem] bg-primary-light text-primary text-[1rem] p-4 mt-4'>
                  <p>
                    Once the request is cancelled, it will be moved to your Profile section and can
                    not be retrieved.
                  </p>{' '}
                  <br />
                  <p>Are you sure that you want to cancel this request?</p>
                </div>
                <div className='border-[0.1rem] rounded-[0.5rem] mt-4'>
                  <div className='border-b-[0.1rem] p-3 '>
                    <p className='text-primary text-[0.8rem] font-bold'>Reason For Cancellation</p>
                    <p className='text-[1.1rem]'>{selectedOption}</p>
                  </div>
                  <div className=' p-3'>
                    <p className='text-primary text-[0.8rem] font-bold'>Notes</p>
                    <Tootltip title={notesValue} placement={'bottom'}>
                      <p className='text-[1.1rem] max-w-[25rem] text-ellipsis overflow-hidden'>
                        {notesValue || 'N/A'}
                      </p>
                    </Tootltip>
                  </div>
                </div>
                <div className='flex justify-between'>
                  <button
                    className='flex items-center py-[0.7rem] pr-[1.5rem] pl-[0.7rem] border-primary text-primary rounded-[0.5rem] font-bold text-[1rem] mt-[5rem] hover:bg-primary-light hover:border-transparent'
                    onClick={() => {
                      setFirstPage(!firstPage);
                    }}>
                    <i className='pi pi-angle-left flex items-center text-[1.2rem] mr-2'></i>
                    Go Back
                  </button>
                  <button
                    className='py-[0.7rem] px-[2rem] border-[#CC5555] text-white rounded-[0.5rem] font-normal mt-[5rem] bg-[#CC5555] text-[1.1rem] hover:bg-rose-500'
                    onClick={() => {
                      if (item.hasOwnProperty('status_id')) {
                        cancelRequest(scheduledOrInProgressPayload);
                      } else {
                        cancelRequest(pendingCasePayload);
                      }
                    }}>
                    Yes, Cancel Request
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </ModalForm>
    </OverlayContainer>
  );
}
