import { IconButton } from '@mui/material';
import imageCompression from 'browser-image-compression';
import { useEffect, useMemo, useState } from 'react';
import PreventiveAPI from '../../../api/preventiveApi';
import HandleLoad from '../../../components/Loader/HandleLoad';
import ModalForm from '../../../components/ModalForm';
import OverlayContainer from '../../../components/OverlayContainer';
import { toast } from '../../../components/Toast/CustomToast';
import ImagePreview from '../../common/ImageModal';
import useFetchCheckList from '../../hooks/useFetchCheckList';
import { findProofArrayById, getFilteredImages } from '../bulkInfoModal/components/WorkProofData';
export const PlayVideoSVg = (props: { height?: string; width?: string; active?: boolean }) => {
  const height = props?.height || '1.3rem';
  const width = props?.width || '1.3rem';
  return (
    <div
      style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}
      className='w-fit h-fit rounded-full'>
      <svg
        width={width}
        height={height}
        viewBox='0 0 15 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M7.5 0C3.35625 0 0 3.35625 0 7.5C0 11.6438 3.35625 15 7.5 15C11.6438 15 15 11.6438 15 7.5C15 3.35625 11.6438 0 7.5 0ZM6 10.875V4.125L10.5 7.5L6 10.875Z'
          fill='white'
        />
      </svg>
    </div>
  );
};
const ImageModal = ({ handleClose, item, handleMoveStage }: any) => {
  const loader = HandleLoad();
  const [sendLoader, setSendLoader] = useState<any>({
    show: false,
    file: [],
    elemServiceId: 0,
    index: 0,
  });
  const [note, setNote] = useState<string>('');
  const { checkList: checklists, getCheckList } = useFetchCheckList();
  const [proofsData, setProofsData] = useState<any>({
    open: false,
    data: [],
    selected_id: null,
  });

  useEffect(() => {
    getCheckList(item, true);
  }, [item]);

  const compressImage = async (file: any) => {
    try {
      const options = {
        maxSizeMB: 0.3,
        useWebWorker: true,
        maxWidthOrHeight: 1000,
      };
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error('Image compression error:', error);
      return file;
    }
  };
  const handleImageUpload = async (files: any, id: any, elemServiceId: any) => {
    loader(true);
    const formData = new FormData();
    for (const file of files) {
      loader(true);
      const compressedFile = await compressImage(file);
      formData.append('file', compressedFile);
      formData.append('id', id);
      if (elemServiceId !== null) {
        formData.append('service_title_id', elemServiceId);
      }
    }
    if (files?.length > 0) {
      PreventiveAPI.uploadImages(formData).then((res) => {
        if (res.status === 201 || res.status === 200) {
          toast.success(`${files[0]?.name} uploaded`);
          getCheckList(item, true);
        } else {
          toast.error('something went wrong');
        }
        loader(false);
        setSendLoader({ show: false, file: [] });
      });
    }
  };
  const handleVideoUpload = async (files: any, id: any, elemServiceId: any) => {
    const formData = new FormData();
    for (const file of files) {
      const compressedFile = await compressImage(file);
      formData.append('file', compressedFile);
      formData.append('id', id);
      if (elemServiceId !== null) {
        formData.append('service_title_id', elemServiceId);
      }
    }
    if (files?.length > 0) {
      PreventiveAPI.uploadImages(formData).then((res) => {
        if (res.status === 201 || res.status === 200) {
          toast.success(`${files[0]?.name} uploaded`);
          getCheckList(item, true);
        } else {
          toast.error('something went wrong');
        }
        setSendLoader({ show: false, file: [], elemServiceId: elemServiceId, index: 0 });
      });
    }
  };
  const handleDeleteImage = (url: string, id: string) => {
    loader(true);
    PreventiveAPI.deleteImages({ url, id }).then((res: any) => {
      if (res.status === 200) {
        toast.success(`Image removed successfully`);
        getCheckList(item, true);
      } else {
        toast.error('something went wrong');
      }
      loader(false);
    });
  };

  const totalProofs = useMemo(() => {
    return getFilteredImages(checklists);
  }, [checklists]);
  const selectedProofsGroup = useMemo(() => {
    const checklistData = checklists;
    return findProofArrayById(checklistData, proofsData?.selected_id);
  }, [checklists]);
  const uploadingVideoDesign = () => (
    <div className='relative flex items-center justify-center '>
      <div className='relative'>
        <div className='absolute top-[0.5rem] right-[0.78rem] flex justify-center items-center z-50'>
          <i className='pi pi-spin pi-spinner text-white' style={{ fontSize: '2rem' }}></i>
        </div>
        <div className='absolute top-[0.66rem] right-[0.95rem] h-[1.7rem] w-[1.7rem] rounded-full bg-[#262424A3] flex justify-center items-center z-50'>
          <div>
            <PlayVideoSVg height='1.1rem' width='1.1rem' />
          </div>
        </div>
        <div
          className='w-[3.5rem] bg-black h-[3rem] rounded-xl z-0'
          style={{ filter: 'brightness(0.5)' }}></div>
      </div>
    </div>
  );
  const handleFileInputChange = (e: any, elemId: string, index: number, elemServiceId: any) => {
    const file = e.target.files[0];
    const sizeInMB = file.size / (1024 * 1024);
    if (sizeInMB > 50) {
      toast.error('Please choose a file smaller than 50 MB');
      return;
    }
    const isImage = file.type.startsWith('image/');
    if (isImage) {
      handleImageUpload(e?.target?.files, elemId, elemServiceId);
    } else if (!isImage) {
      if (file) {
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = () => {
          const duration = video.duration;
          if (duration > 120.99) {
            toast.error('Please choose a video shorter than 2 minutes');
            return;
          }
          if (duration <= 120.99) {
            setSendLoader({ show: true, file: file, index: index, elemServiceId: elemServiceId });
            handleVideoUpload(e?.target?.files, elemId, elemServiceId);
          }
        };
        video.src = URL.createObjectURL(file);
      }
    }
  };

  useEffect(() => {
    if (proofsData?.open) {
      setProofsData((prev: any) => ({ ...prev, data: selectedProofsGroup }));
    }
  }, [totalProofs]);

  const requestInfo = useMemo(
    () => ({
      item,
    }),
    [item]
  );
  return (
    <>
      <OverlayContainer show={true}>
        <ModalForm title={'Add CSR'} onClose={handleClose} width={'w-[50vw]'} noLabel>
          <div className='flex flex-col gap-4 h-[30rem]'>
            <div>
              <p className='text-blue-500 font-semibold text-sm capitalize'>{item.title}</p>
            </div>
            <div className='divide-x-2 divide-slate-100 flex h-full'>
              <section className='w-1/2 p-1 pr-2 flex flex-col gap-2 h-[25rem] overflow-y-auto'>
                {checklists?.length > 0 &&
                  checklists?.map((elem: any, index: number) =>
                    !(elem.titles.length > 0) ? (
                      <div className='border rounded-lg border-gray-200 ' key={elem?.id}>
                        <p className='text-sm py-1 font-bold capitalize border-b-[0.1rem] border-gray-200 p-2 py-3'>
                          {elem.name}
                        </p>
                        <div className='flex flex-wrap gap-2 p-2'>
                          {elem?.proofs?.length > 0 &&
                            elem?.proofs?.map(
                              (img: {
                                file_url: string;
                                checklist_id: string;
                                proof_id: string;
                                proof_type: string;
                              }) => (
                                <>
                                  {img.proof_type === 'Image' ? (
                                    <div className='relative' key={img.file_url}>
                                      <i
                                        className='pi pi-times absolute top-0 right-0 rounded-full bg-blue-100 hover:bg-rose-300 p-[2px] cursor-pointer'
                                        style={{ fontSize: '0.7rem' }}
                                        onClick={() =>
                                          handleDeleteImage(img.file_url, img.checklist_id)
                                        }></i>
                                      <img
                                        src={img.file_url}
                                        onClick={() =>
                                          setProofsData({
                                            open: true,
                                            data: totalProofs,
                                            selected_id: img?.proof_id,
                                          })
                                        }
                                        className='w-[3rem] h-[3rem] rounded-xl object-contain overflow-hidden'></img>
                                    </div>
                                  ) : (
                                    <div className='relative flex items-center justify-center z-30'>
                                      <div className='relative bg-gray-200 rounded-xl'>
                                        <i
                                          className='pi pi-times absolute top-0 right-0 rounded-full bg-blue-100 hover:bg-rose-300 p-[2px] cursor-pointer z-50'
                                          style={{ fontSize: '0.7rem' }}
                                          onClick={() =>
                                            handleDeleteImage(img.file_url, img.checklist_id)
                                          }></i>
                                        <div
                                          className='absolute top-[0.85rem] right-[0.9rem] flex justify-center items-center cursor-pointer z-40'
                                          onClick={() =>
                                            setProofsData({
                                              open: true,
                                              data: totalProofs,
                                              selected_id: img?.proof_id,
                                            })
                                          }>
                                          <PlayVideoSVg />
                                        </div>
                                        <video
                                          src={img.file_url}
                                          // poster={img.file_url}
                                          onClick={() =>
                                            setProofsData({
                                              open: true,
                                              data: totalProofs,
                                              selected_id: img?.proof_id,
                                            })
                                          }
                                          controls={false}
                                          className='w-[3rem] h-[3rem] rounded-xl object-contain overflow-hidden z-50'
                                          style={{ filter: 'brightness(0.5)' }}></video>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )
                            )}

                          {sendLoader.show && sendLoader.elemServiceId === null && (
                            <>{sendLoader.index === index && uploadingVideoDesign()}</>
                          )}
                          <>
                            <IconButton
                              color='primary'
                              aria-label='upload picture'
                              component='label'
                              className={`w-[2.8rem] h-[2.8rem] border border-dashed ${
                                sendLoader.show
                                  ? 'border-gray-300 text-gray-400'
                                  : 'border-blue-100 text-blue-600'
                              }   rounded-xl flex items-center justify-center cursor-pointer`}>
                              {!sendLoader.show && (
                                <>
                                  <input
                                    hidden
                                    type='file'
                                    accept='image/png, image/jpeg, image/jpg, video/*'
                                    onClick={(e: any) => {
                                      if (!sendLoader.show) {
                                        e.target.value = '';
                                      }
                                    }}
                                    onChange={(e: any) => {
                                      if (!sendLoader.show) {
                                        handleFileInputChange(e, elem.id, index, null);
                                      }
                                    }}></input>
                                </>
                              )}

                              <i className='pi pi-plus text-lg'></i>
                            </IconButton>
                          </>
                        </div>
                      </div>
                    ) : (
                      <div className='border rounded-lg border-gray-200' key={elem?.id}>
                        <p className='text-sm py-1 border-b-[0.1rem] border-gray-200 mb-[0.5rem]'>
                          <div className='pl-3 text-sm font-bold py-[0.4rem] capitalize'>
                            {elem.name}
                          </div>
                        </p>
                        {elem.titles.map((elem1: any, index: number) => {
                          return (
                            <div className='mb-[1rem]' key={elem1?.title || ''}>
                              <div className='pl-3 text-sm font-bold capitalize'>{elem1.title}</div>
                              <div className='flex flex-wrap gap-2 pl-3 pt-3'>
                                {elem1?.proofs?.length > 0 &&
                                  elem1?.proofs?.map(
                                    (img: {
                                      file_url: string;
                                      checklist_id: string;
                                      proof_id: string;
                                      proof_type: string;
                                    }) => (
                                      <>
                                        {img.proof_type === 'Image' ? (
                                          <div className='relative' key={img.file_url}>
                                            <i
                                              className='pi pi-times absolute top-0 right-0 rounded-full bg-blue-100 hover:bg-rose-300 p-[2px] cursor-pointer'
                                              style={{ fontSize: '0.7rem' }}
                                              onClick={() =>
                                                handleDeleteImage(img.file_url, img.checklist_id)
                                              }></i>
                                            <img
                                              src={img.file_url}
                                              onClick={() =>
                                                setProofsData({
                                                  open: true,
                                                  data: elem1?.proofs,
                                                  selected_id: img?.proof_id,
                                                })
                                              }
                                              className='w-[3rem] h-[3rem] rounded-xl object-contain overflow-hidden'></img>
                                          </div>
                                        ) : (
                                          <div className='relative flex items-center justify-center z-30'>
                                            <div className='relative bg-gray-200 rounded-xl'>
                                              <i
                                                className='pi pi-times absolute top-0 right-0 rounded-full bg-blue-100 hover:bg-rose-300 p-[2px] cursor-pointer z-50'
                                                style={{ fontSize: '0.7rem' }}
                                                onClick={() =>
                                                  handleDeleteImage(img.file_url, img.checklist_id)
                                                }></i>
                                              <div
                                                className='absolute top-[0.85rem] right-[0.9rem] flex justify-center items-center cursor-pointer z-40'
                                                onClick={() =>
                                                  setProofsData({
                                                    open: true,
                                                    data: elem1?.proofs,
                                                    selected_id: img?.proof_id,
                                                  })
                                                }>
                                                <PlayVideoSVg />
                                              </div>
                                              <video
                                                src={img.file_url}
                                                // poster={img.file_url}
                                                onClick={() =>
                                                  setProofsData({
                                                    open: true,
                                                    data: elem1?.proofs,
                                                    selected_id: img?.proof_id,
                                                  })
                                                }
                                                controls={false}
                                                className='w-[3rem] h-[3rem] rounded-xl object-contain overflow-hidden z-50'
                                                style={{ filter: 'brightness(0.5)' }}></video>
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    )
                                  )}
                                {sendLoader.show && (
                                  <>
                                    {sendLoader.elemServiceId === elem1.id &&
                                      uploadingVideoDesign()}
                                  </>
                                )}
                                <>
                                  <IconButton
                                    color='primary'
                                    aria-label='upload picture'
                                    component='label'
                                    className={`w-[2.8rem] h-[2.8rem] border border-dashed ${
                                      sendLoader.show
                                        ? 'border-gray-300 text-gray-400'
                                        : 'border-blue-100 text-blue-600'
                                    }   rounded-xl flex items-center justify-center cursor-pointer`}>
                                    {!sendLoader.show && (
                                      <>
                                        <input
                                          hidden
                                          type='file'
                                          accept='image/png, image/jpeg, image/jpg, video/*'
                                          onClick={(e: any) => {
                                            if (!sendLoader.show) {
                                              e.target.value = '';
                                            }
                                          }}
                                          onChange={(e: any) => {
                                            if (!sendLoader.show) {
                                              handleFileInputChange(e, elem.id, index, elem1.id);
                                            }
                                          }}></input>
                                      </>
                                    )}

                                    <i className='pi pi-plus text-lg'></i>
                                  </IconButton>
                                </>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )
                  )}
              </section>
              <section className='w-1/2 p-1 pl-5 h-full'>
                <div className=' w-full h-full'>
                  <textarea
                    value={note}
                    onChange={(e: { target: { value: string } }) => setNote(e.target.value)}
                    className='w-full h-1/2 rounded-lg p-2 border-2 border-slate-100 resize-none'
                    placeholder='Add Notes'></textarea>
                </div>
              </section>
            </div>
            <div className='text-center'>
              <button
                className='border-none py-1.5 px-6 bg-blue-500 text-white rounded-lg disabled:bg-slate-200 disabled:text-slate-400'
                onClick={() =>
                  handleMoveStage(item.id || item?.preventive_request_id, '8', note, true)
                }>
                Submit
              </button>
            </div>
          </div>
        </ModalForm>
      </OverlayContainer>
      {proofsData.open && (
        <ImagePreview
          imagesDataObj={proofsData}
          handleClose={() => setProofsData({ open: false, data: [] })}
          requestInfo={requestInfo}
        />
      )}
    </>
  );
};

export default ImageModal;
