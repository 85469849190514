import { useRef } from 'react';

interface SearchProps {
  inputRef: React.RefObject<HTMLInputElement>;
  handleSearch: (value: string) => void;
}

function useSearchConfiguration() {
  const isPartnerInvoice = window.location.href.includes('/partner/invoice');
  const isVendorInvoice = window.location.href.includes('/vendor/invoice');
  const isVendorRequestReport = window.location.href.includes('/vendorRequestReport');

  const isInvoicePage = isPartnerInvoice || isVendorInvoice || isVendorRequestReport;
  const isRevenuePage =
    window.location.href.includes('/vendor/dashboard/revenue') ||
    window.location.href.includes('/partner/dashboard/revenue');
  const isPendingPage = window.location.href.includes('/csr/dashboard?type=pending');

  const getSearchPlaceholder = () => {
    if (isPendingPage) {
      return 'Search By VIN No';
    }

    if (!isInvoicePage) {
      return 'Search By VIN No, Request Id';
    }

    if (isVendorRequestReport) {
      return 'Search By VIN No, Station Code, Request Id....';
    }

    return 'Search By VIN No, Station Code, Invoice Id, Request Id....';
  };

  const getInputWidth = () => {
    if (isInvoicePage) {
      return isVendorRequestReport ? 'w-[23rem]' : 'w-[26rem]';
    }
    return 'w-[16rem]';
  };

  return { isRevenuePage, getSearchPlaceholder, getInputWidth };
}

function SearchBar({ inputRef, handleSearch }: SearchProps) {
  const searchRef = useRef<HTMLDivElement>(null);
  const { isRevenuePage, getSearchPlaceholder, getInputWidth } = useSearchConfiguration();

  const handleDivClick = () => {
    inputRef.current?.focus();
  };

  const placeholder: string = getSearchPlaceholder();
  const inputWidth: string = getInputWidth();

  return (
    <>
      {!isRevenuePage && (
        <div
          ref={searchRef}
          id='searchbar'
          className='relative bg-white flex items-center border-solid border-[0.13rem] p-2.5 rounded-full right-0 cursor-pointer hover:border-primary focus-within:border-primary'
          onClick={handleDivClick}>
          <i className='pi pi-search text-[1.2rem] font-bold'></i>
          <input
            ref={inputRef}
            type='text'
            placeholder={placeholder}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleSearch(e.target.value);
            }}
            className={`pl-2 focus:outline-none border-none transition ease-in duration-300 ${inputWidth}`}
          />
        </div>
      )}
    </>
  );
}

export function SearchInvoice(props: SearchProps) {
  return <SearchBar {...props} />;
}

export function SearchReport(props: SearchProps) {
  return <SearchBar {...props} />;
}
