import { useEffect } from 'react';
import useFetchSingleRequest from '../../hooks/csr/useFetchSingleRequest';
import { RequestDetail } from './RequestDetail';

const Request = ({ handleClose, item, type, body5, body6, status, refetch, fullbody2 }: any) => {
  const { getFullRequestInfo }: any = useFetchSingleRequest();
  useEffect(() => {
    if (item.id) {
      getFullRequestInfo();
    }
  }, [item.id]);
  return (
    <RequestDetail
      {...{
        handleClose,
        item: { ...item, ...getFullRequestInfo },
        type,
        body5,
        body6,
        status,
        refetch,
        fullbody2,
      }}
    />
  );
};

export default Request;
