import { useMemo } from 'react';
import DownloadInvoiceButton from '../../../../../assets/DownloadInvoiceButton';
import GoBackButton from '../../../../../assets/GoBackButton';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../state/reducers';
import HandleLoad from '../../../../../components/Loader/HandleLoad';
import { Environment } from '../../../../../constants/environment';
import axios from 'axios';
import InvoiceTemplateVendorNew from '../invoiceTemplate/InvoiceTemplateVendor';
import { ThreeDots } from 'react-loader-spinner';
import { toast } from '../../../../../components/Toast/CustomToast';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../../state';

const ShowInvoiceVendor = ({
  selectedPDF,
  GoBack,
  handelNextButtonvendor,
  vendorStatus,
  updateVendorInvoice,
  invoiceDetails,
  fetchInvoiceDetails,
  item,
  invoices,
  invoiceLoader,
}: {
  item: any;
  GoBack: any;
  selectedPDF: any;
  handelNextButtonvendor: any;
  vendorStatus: any;
  updateVendorInvoice: any;
  fetchInvoiceDetails: any;
  invoiceDetails: any;
  invoices: any;
  invoiceLoader: boolean;
}) => {
  const loader = HandleLoad();
  const baseUrl = Environment.baseUrl;
  const dispatch = useDispatch();
  const { setInvoicesLoader } = bindActionCreators(actionCreators, dispatch);
  const { user, invoiceLoader: isDownloading }: any = useSelector(
    (state: RootState) => state.userState
  );
  const vendorInvoices = useMemo(() => {
    if (!invoices?.[0]?.invoices) return null;
    return invoices?.[0]?.invoices?.filter((invoices: any) => invoices?.recipient === 'vendor');
  }, [invoices]);

  const downloadReport = () => {
    if (!isDownloading) {
      setInvoicesLoader(true);
      toast.info('Downloading PDF!');
      axios
        .get(
          `${baseUrl}csr/v1/download_invoice?ids_arr=${JSON.stringify([vendorInvoices?.[0]?.id])}`,
          {
            responseType: 'arraybuffer',
            headers: {
              'x-access-token': user?.token || '',
              'x-access-user': user?.account_id || '',
              'app-type': '9',
            },
          }
        )
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
          var link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Invoice.pdf`);
          document.body.appendChild(link);
          link.click();
          setInvoicesLoader(false);
        });
    } else {
      toast.error('Please Wait until the previous is downloading');
    }
  };
  return (
    <div className='parentComponent flex flex-col gap-2 flex-1 w-full'>
      <div className='flex flex-1'>
        <div className='flex justify-between flex-1'>
          <div className='w-full   flex justify-between flex-1'>
            <div className='w-[50rem] p-2 flex justify-center items-center mx-auto'>
              {invoiceLoader ? (
                <div className='w-full h-full flex justify-center items-center'>
                  <ThreeDots
                    height='30'
                    width='50'
                    radius='9'
                    color={'#4378C8'}
                    ariaLabel='three-dots-loading'
                    wrapperStyle={{}}
                    visible={true}
                  />
                </div>
              ) : (
                <InvoiceTemplateVendorNew
                  invoiceDetails={invoiceDetails}
                  item={item}
                  invoices={invoices}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='Below Contanier flex justify-between mt-5'>
        <div>
          {['generated'].includes(item?.vendor_invoice_status) && (
            <button
              className='py-3 px-7 rounded-[0.7rem] border-[#4378C8] text-[#4378C8] flex justify-between items-center text-600'
              style={{ fontWeight: 'bold' }}
              onClick={GoBack}>
              <GoBackButton />
              <p className='ml-3'>Go Back</p>
            </button>
          )}
          {!['amount_submitted', 'amount_submitted_by_csr', 'generated'].includes(
            item?.vendor_invoice_status
          ) &&
            !['raised'].includes(item?.vendor_invoice_dispute) && (
              <button
                className='py-3 px-7 rounded-[0.7rem] border-[#4378C8] text-[#4378C8] flex justify-between items-center text-600'
                style={{ fontWeight: 'bold' }}
                onClick={GoBack}>
                <i className='pi pi-pencil'></i>
                <p className='ml-3'>Edit Invoice</p>
              </button>
            )}
        </div>
        <div className='flex justify-between'>
          <button
            disabled={isDownloading}
            className={`py-3 px-6 mr-3 rounded-[0.7rem] border-[#4378C8] text-[#4378C8] flex justify-between disabled:border-[#808080] disabled:text-[#808080] text-bold`}
            style={{ fontWeight: 'bold' }}
            onClick={downloadReport}>
            <DownloadInvoiceButton active={!isDownloading} />
            <p className='ml-3'>Download Invoice</p>
          </button>
          {['Generated'].includes(vendorStatus?.text) && (
            <button
              className='py-3 px-20 ml-5 rounded-[0.7rem] border-[#4378C8] bg-[#4378C8] text-[#FFF] text-bold'
              style={{ fontWeight: 'bold' }}
              onClick={handelNextButtonvendor}>
              Send Invoice
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
export default ShowInvoiceVendor;
