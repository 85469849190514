export const getRequestType = (d: any) => {
  if (d.status_id === '-1' && d.sub_stage === '1'){
    return 'requests';
  }
  if (d.status_id === '4') {
    if (+d?.sub_stage === 3) {
      return 'rejected';
    } else if (+d?.sub_stage === 2) {
      return 'accepted';
    } else {
      return 'requests';
    }
  }
  if (['5', '6', '7', '8'].includes(d.status_id)) {
    return 'assigned';
  }
  if (['9', '11', '12'].includes(d.status_id)) {
    return 'approved';
  }
  if (['10'].includes(d.status_id)) {
    return 'rejected';
  }
};
