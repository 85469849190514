export default function (props: { height?: string; width?: string; fill?: string }) {
  const height = props?.height || '1.5rem';
  const width = props?.width || '1.5rem';
  const fill = props?.fill || '#595959';
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2 18.8848C1.45 18.8848 0.979167 18.6889 0.5875 18.2973C0.195833 17.9056 0 17.4348 0 16.8848V2.88477C0 2.33477 0.195833 1.86393 0.5875 1.47227C0.979167 1.0806 1.45 0.884766 2 0.884766H18C18.55 0.884766 19.0208 1.0806 19.4125 1.47227C19.8042 1.86393 20 2.33477 20 2.88477V16.8848C20 17.4348 19.8042 17.9056 19.4125 18.2973C19.0208 18.6889 18.55 18.8848 18 18.8848H2ZM2 16.8848H18V2.88477H2V16.8848ZM3 14.8848H8V12.8848H3V14.8848ZM12.55 12.8848L17.5 7.93477L16.075 6.50977L12.55 10.0598L11.125 8.63477L9.725 10.0598L12.55 12.8848ZM3 10.8848H8V8.88477H3V10.8848ZM3 6.88477H8V4.88477H3V6.88477Z'
        fill={fill}
      />
    </svg>
  );
}
