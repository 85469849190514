import { Stack, Tooltip } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import BackButton from '../../../../assets/BackButton';
import ImagePreview from '../../../common/ImageModal';
import NotesPopup from '../../../vender/PrimaryComponents/requestComponents/vehicle/NotesPopUp';
import { PlayVideoSVg } from '../../primaryComponents/SubmitProofModel';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { BulkActionButton, InputFeild } from './WorkProofComponents';
import Tootltip from '../../../../components/BarData/Tootltip';
import moment from 'moment';
import RequestPart from '../../primaryComponents/RequestPart';
import { RootState } from '../../../../state/reducers';
import { useSelector } from 'react-redux';

export const getFilteredImages = (checklistData: any[]) => {
  let tempProofs: any[] = [];
  checklistData?.forEach((item: any) => {
    if (item?.proofs) {
      tempProofs.push(
        ...(item?.proofs && item?.proofs?.length > 0
          ? item.proofs.map((d: any) => ({
              ...d,
              heading: item?.name,
              ...(item?.proof_name && { name: item?.proof_name }),
            }))
          : [])
      );
    }
  });
  return tempProofs;
};
export const findProofArrayById = (checklistData: any, selected_id: any) => {
  for (const item of checklistData) {
    if (item.proofs && item.proofs.some((proof: any) => proof.proof_id === selected_id)) {
      return item.proofs;
    }
    if (item.titles) {
      for (const title of item.titles) {
        if (title.proofs && title.proofs.some((proof: any) => proof.proof_id === selected_id)) {
          return title.proofs;
        }
      }
    }
  }
  return getFilteredImages(checklistData);
};
const ImageModal = ({
  data,
  setWorkProofData,
  handleClose,
}: {
  data: any;
  setWorkProofData?: (item: boolean) => void;
  handleClose?: any;
}) => {
  const [showNotes, setShowNotes] = useState<{ [key: number]: boolean }>({});
  const { user }: any = useSelector((state: RootState) => state.userState);
  const [proofsData, setProofsData] = useState<any>({
    open: false,
    data: [],
    selected_id: null,
  });
  const [selectedServices, setSelectedServices] = useState<any>({
    show: false,
    selectedServices: [],
  });
  const { checklistData, item }: any = data;
  const totalProofs = useMemo(() => {
    return getFilteredImages(checklistData);
  }, [checklistData]);
  const selectedProofs = useMemo(() => {
    return findProofArrayById(checklistData, proofsData?.selected_id);
  }, [checklistData]);
  const isCSR: boolean = user?.role === 'fleetmax_csr';
  useEffect(() => {
    if (proofsData?.open) {
      setSelectedServices({
        show: false,
        selectedServices: [],
      });
      setProofsData((prev: any) => ({ ...prev, data: selectedProofs }));
    }
  }, [totalProofs]);
  const toggleNotes = (index: number) => {
    setShowNotes((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  const onClickInput = (ele: any) => {
    if (selectedServices?.show && !ele.child_request?.is_exist) {
      const isSelected = selectedServices.selectedServices.includes(ele?.name);
      setSelectedServices({
        ...selectedServices,
        selectedServices: isSelected
          ? selectedServices.selectedServices.filter((s: any) => s !== ele?.name)
          : [...selectedServices.selectedServices, ele?.name],
      });
    }
  };
  const compProps = { selectedServices, setSelectedServices, setWorkProofData, item };
  const isServiceMakingEnebled: boolean =
    (Number(item?.status_id) < 11 ||
      (Number(item?.status_id) === 11 &&
        !['amount_submitted', 'updated']?.includes(item?.vendor_invoice_status))) &&
    isCSR;
  const unassignedServicesCount = useMemo(
    () =>
      checklistData.reduce(
        (count: number, elem: any) => (elem?.child_request?.is_exist ? count : count + 1),
        0
      ),
    [checklistData]
  );
  const titleObj = (elem1: any, index: number) => {
    return (
      <div className={`mb-[1rem] ${index === 0 && 'mt-2'}`} key={elem1.title}>
        <div className='pl-3 text-sm font-bold capitalize'>{elem1.title}</div>
        <div className='flex flex-wrap gap-2 pl-3 mt-[0.2rem]'>
          {elem1?.proofs?.length > 0 ? (
            <div className='grid grid-cols-5 gap-2'>
              {elem1?.proofs?.map(
                (img: {
                  file_url: string;
                  checklist_id: string;
                  proof_id: string;
                  name?: string;
                  proof_type: string;
                  proof_name?: string;
                }) => (
                  <>
                    {img.proof_type === 'Image' ? (
                      <div className='relative' key={img?.checklist_id}>
                        <Tooltip title={`${img.name || img?.proof_name || 'image'}`}>
                          <div>
                            <img
                              src={img.file_url}
                              onClick={() => {
                                setSelectedServices({
                                  show: false,
                                  selectedServices: [],
                                });
                                setProofsData({
                                  open: true,
                                  data: elem1?.proofs,
                                  selected_id: img?.proof_id,
                                });
                              }}
                              className='w-[4rem] h-[4rem] rounded-xl object-contain overflow-hidden mx-auto cursor-pointer'
                              alt={img.file_url}></img>
                          </div>
                        </Tooltip>
                      </div>
                    ) : (
                      <div className='relative flex items-center justify-center z-30'>
                        <Tooltip title={`${img.name || img?.proof_name || 'image'}`}>
                          <div className='relative bg-gray-200 rounded-xl'>
                            <div
                              className='absolute top-[0.85rem] right-[0.9rem] flex justify-center items-center cursor-pointer z-40'
                              onClick={() => {
                                setSelectedServices({
                                  show: false,
                                  selectedServices: [],
                                });
                                setProofsData({
                                  open: true,
                                  data: elem1?.proofs,
                                  selected_id: img?.proof_id,
                                });
                              }}>
                              <PlayVideoSVg />
                            </div>
                            <video
                              src={img.file_url}
                              // poster={img.file_url}
                              onClick={() => {
                                setSelectedServices({
                                  show: false,
                                  selectedServices: [],
                                });
                                setProofsData({
                                  open: true,
                                  data: elem1?.proofs,
                                  selected_id: img?.proof_id,
                                });
                              }}
                              controls={false}
                              className='w-[3rem] h-[3rem] rounded-xl object-contain overflow-hidden z-50'
                              style={{ filter: 'brightness(0.5)' }}></video>
                          </div>
                        </Tooltip>
                      </div>
                    )}
                  </>
                )
              )}
            </div>
          ) : (
            <p className='text-[#A9A9A9] '>No Proof Uploaded</p>
          )}
        </div>
      </div>
    );
  };
  const proofsObj = (elem: any, index: number) => {
    return (
      <div className='grid grid-cols-5 gap-2 p-2'>
        {elem?.proofs?.map(
          (img: {
            file_url: string;
            checklist_id: string;
            proof_id: string;
            name?: string;
            proof_type: string;
            proof_name?: string;
          }) => (
            <>
              {img.proof_type === 'Image' ? (
                <div className='relative' key={img?.checklist_id}>
                  <Tooltip title={`${img.name || img?.proof_name || 'image'}`}>
                    <div>
                      <img
                        src={img.file_url}
                        onClick={() => {
                          setSelectedServices({
                            show: false,
                            selectedServices: [],
                          });
                          setProofsData({
                            open: true,
                            data: totalProofs,
                            selected_id: img?.proof_id,
                          });
                        }}
                        className='w-[4rem] h-[4rem] rounded-xl object-contain overflow-hidden mx-auto cursor-pointer'
                        alt={img.file_url}></img>
                    </div>
                  </Tooltip>
                </div>
              ) : (
                <div className='relative flex items-center justify-center z-30'>
                  <Tooltip title={`${img.name || img?.proof_name || 'image'}`}>
                    <div className='relative bg-gray-200 rounded-xl'>
                      <div
                        className='absolute top-[0.85rem] right-[0.9rem] flex justify-center items-center cursor-pointer z-40'
                        onClick={() => {
                          setSelectedServices({
                            show: false,
                            selectedServices: [],
                          });
                          setProofsData({
                            open: true,
                            data: totalProofs,
                            selected_id: img?.proof_id,
                          });
                        }}>
                        <PlayVideoSVg />
                      </div>
                      <video
                        src={img.file_url}
                        // poster={img.file_url}
                        onClick={() => {
                          setSelectedServices({
                            show: false,
                            selectedServices: [],
                          });

                          setProofsData({
                            open: true,
                            data: totalProofs,
                            selected_id: img?.proof_id,
                          });
                        }}
                        controls={false}
                        className='w-[3rem] h-[3rem] rounded-xl object-contain overflow-hidden z-50'
                        style={{ filter: 'brightness(0.5)' }}></video>
                    </div>
                  </Tooltip>
                </div>
              )}
            </>
          )
        )}
      </div>
    );
  };
  return (
    <>
      <div className='flex flex-col gap-4 h-[35rem]'>
        <div className='flex items-center gap-2'>
          <p className='text-blue-500 font-semibold text-xl'>
            <button
              className='border-transparent mr-8 mt-0.1'
              onClick={() => {
                setWorkProofData?.(false);
              }}>
              <BackButton />
            </button>
            {'Work Proofs'}
          </p>
        </div>
        <div className='flex h-0 flex-1 w-full'>
          <section className='w-1/2 p-1 pr-2 flex flex-col gap-2 h-full flex-1 overflow-y-auto'>
            {isServiceMakingEnebled && +unassignedServicesCount > 1 && (
              <BulkActionButton props={compProps} />
            )}
            {checklistData?.length > 0 &&
              checklistData?.map((elem: any, index: number) =>
                !(elem.titles.length > 0) ? (
                  <div className='border rounded-lg border-gray-200' key={elem?.id}>
                    <div className=''>
                      <p
                        className={`flex justify-between text-sm py-1 pl-3 pr-3 font-bold  py-[0.8rem] border-b-[0.1rem] border-gray-200 ${
                          selectedServices?.show &&
                          !elem?.child_request?.is_exist &&
                          'hover:bg-primary-lighter cursor-pointer'
                        }`}
                        onClick={() => {
                          onClickInput(elem);
                        }}>
                        <p className='capitalize flex items-center gap-2'>
                          {selectedServices?.show && !elem?.child_request?.is_exist && (
                            <InputFeild props={{ ...compProps, ele: elem }} />
                          )}
                          <Tootltip title={elem.name}>
                            <p
                              className={`max-w-[12rem] text-ellipsis whitespace-nowrap overflow-hidden capitalize `}>
                              {elem.name}
                            </p>
                          </Tootltip>
                        </p>
                        {isServiceMakingEnebled && (
                          <>
                            {!selectedServices?.show && !elem?.child_request?.is_exist && (
                              <BulkActionButton
                                props={{ ...compProps, isButton: true, ele: elem }}
                              />
                            )}
                          </>
                        )}
                        {elem?.child_request?.is_exist && (
                          <p className='font-normal text-[0.8rem] w-[12rem]'>
                            Added as{' '}
                            <RequestPart
                              item={elem?.child_request}
                              isRequestName={true}
                              handleClose={() => {
                                handleClose?.();
                                setWorkProofData?.(false);
                              }}
                            />{' '}
                            on {moment(elem?.child_request?.created_at).format('ll')} at{' '}
                            {moment(elem?.child_request?.created_at).format('LT')}
                          </p>
                        )}
                      </p>
                      {elem?.proofs?.length > 0 ? (
                        <>{proofsObj(elem, index)}</>
                      ) : (
                        <p className='text-[#A9A9A9] p-2'>No Proof Uploaded</p>
                      )}
                      {elem?.titles?.length > 0 &&
                        elem?.titles?.map((elem1: any, index: number) => {
                          return <>{titleObj(elem1, index)}</>;
                        })}
                    </div>
                    {elem?.remarks &&
                      (elem?.remarks?.skip_reason ||
                        elem?.remarks?.comments ||
                        elem?.remarks?.voice_notes?.length > 0) && (
                        <div className='w-full p-2 border-t-[0.1rem] border-gray-200'>
                          <div className='w-full'>
                            <div
                              className={`text-primary cursor-pointer flex gap-1 items-center hover:bg-primary-light w-fit p-1 rounded-[0.5rem] px-2 ${
                                showNotes[index] && 'bg-primary-light'
                              }`}
                              onClick={() => toggleNotes(index)}>
                              Notes
                              <i
                                className={`pi pi-angle-up ${
                                  showNotes[index] ? 'rotate-0' : 'rotate-180'
                                }
                        } transition-transform duration-300 transform  flex justify-between items-center   text-primary `}></i>
                            </div>
                            {showNotes[index] && (
                              <>
                                <div className='flex flex-col text-[0.8rem] p-2 pl-0'>
                                  <p className='text-[0.9rem] break-words text-[#777676]'>
                                    {elem?.remarks?.comments}
                                  </p>
                                  {elem?.remarks?.skip_reason && (
                                    <>
                                      <div className='text-primary '>Proofs Skipped (Reason)</div>
                                      <p className='text-[0.9rem] break-words text-[#777676]'>
                                        {elem?.remarks?.skip_reason}
                                      </p>
                                    </>
                                  )}
                                  {elem?.remarks?.voice_notes && (
                                    <VoiceNotes voice_notes={elem?.remarks?.voice_notes} />
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                ) : (
                  <div className='border rounded-lg border-gray-200' key={elem?.id}>
                    <div>
                      <p
                        className={`text-sm py-[0.8rem] border-b-[0.1rem] border-gray-200 ${
                          selectedServices?.show &&
                          !elem?.child_request?.is_exist &&
                          'hover:bg-primary-lighter cursor-pointer'
                        }`}
                        onClick={() => {
                          onClickInput(elem);
                        }}>
                        <div className='flex justify-between pl-3 pr-3 text-sm font-bold '>
                          <p className='capitalize flex items-center gap-2'>
                            {selectedServices?.show && !elem?.child_request?.is_exist && (
                              <InputFeild props={{ ...compProps, ele: elem }} />
                            )}
                            <Tootltip title={elem.name}>
                              <p
                                className={`max-w-[12rem] text-ellipsis whitespace-nowrap overflow-hidden capitalize `}>
                                {elem.name}
                              </p>
                            </Tootltip>
                          </p>
                          {isServiceMakingEnebled && (
                            <>
                              {!selectedServices?.show && !elem?.child_request?.is_exist && (
                                <BulkActionButton
                                  props={{ ...compProps, isButton: true, ele: elem }}
                                />
                              )}
                            </>
                          )}
                          {elem?.child_request?.is_exist && (
                            <p className='font-normal text-[0.8rem] w-[12rem]'>
                              Added as{' '}
                              <RequestPart
                                item={elem?.child_request}
                                isRequestName={true}
                                handleClose={() => {
                                  handleClose?.();
                                  setWorkProofData?.(false);
                                }}
                              />{' '}
                              on {moment(elem?.child_request?.created_at).format('ll')} at{' '}
                              {moment(elem?.child_request?.created_at).format('LT')}
                            </p>
                          )}
                        </div>
                      </p>
                      {elem?.proofs?.length > 0 ? <>{proofsObj(elem, index)}</> : <></>}
                      {elem?.titles?.length > 0 &&
                        elem?.titles?.map((elem1: any, index: number) => {
                          return <>{titleObj(elem1, index)}</>;
                        })}
                    </div>
                    {elem?.remarks &&
                      (elem?.remarks?.skip_reason ||
                        elem?.remarks?.comments ||
                        elem?.remarks?.voice_notes?.length > 0) && (
                        <div className='w-full p-2 border-t-[0.1rem] border-gray-200'>
                          <div className='w-full'>
                            <div
                              className={`text-primary cursor-pointer flex gap-1 items-center hover:bg-primary-light w-fit p-1 rounded-[0.5rem] px-2 ${
                                showNotes[index] && 'bg-primary-light'
                              }`}
                              onClick={() => toggleNotes(index)}>
                              Notes
                              <i
                                className={`pi pi-angle-up ${
                                  showNotes[index] ? 'rotate-0' : 'rotate-180'
                                }
                      } transition-transform duration-300 transform  flex justify-between items-center   text-primary `}></i>
                            </div>
                            {showNotes[index] && (
                              <>
                                <div className='flex flex-col text-[0.8rem] p-2 pl-0'>
                                  <p className='text-[0.9rem] break-words text-[#777676]'>
                                    {elem?.remarks?.comments}
                                  </p>
                                  {elem?.remarks?.skip_reason && (
                                    <>
                                      <div className='text-primary '>Proofs Skipped (Reason)</div>
                                      <p className='text-[0.9rem] break-words text-[#777676]'>
                                        {elem?.remarks?.skip_reason}
                                      </p>
                                    </>
                                  )}
                                  {elem?.remarks?.voice_notes && (
                                    <VoiceNotes voice_notes={elem?.remarks?.voice_notes} />
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                )
              )}
          </section>
          <section className='w-1/2 p-1 pr-2 flex flex-col gap-2 h-full flex-1 overflow-y-auto'>
            <NotesPopup
              item={item}
              variant={'expandDown'}
              size={'large'}
              noPopUp
              notesRemarks={[
                'proof_rejected',
                'proofs_skipped_by_technician',
                'moved_to_approval_by_technician',
                'moved_to_approval_by_partner',
                'moved_to_approval_by_vendor',
                'moved_to_approval_by_csr',
              ]}
            />
          </section>
        </div>
      </div>

      {proofsData.open && (
        <ImagePreview
          handleClose={() => setProofsData({ open: false, data: [] })}
          imagesDataObj={proofsData}
          requestInfo={data}
        />
      )}
    </>
  );
};

export const VoiceNotes = ({ voice_notes }: { voice_notes: { url: string }[] }) => {
  return (
    <div className='flex gap-2 flex-col w-full'>
      {voice_notes?.map((voice_note: { url: string }) => (
        <Stack
          key={voice_note?.url}
          sx={{
            '&>div': {
              padding: '0.2rem',
            },
            '& .rhap_controls-section': {
              display: 'none',
            },
            '& .iconify': {
              color: '#4378C8',
              width: '1.5rem',
              height: '1.5rem',
            },
            '& .rhap_button-clear': {
              color: '#4378C8',
              width: '2rem',
              height: '2rem',
            },
            '& .rhap_progress-indicator': {
              backgroundColor: '#4378C8',
              width: '1.2rem',
              height: '1.2rem',
              top: '-0.4rem',
            },
            '& .rhap_progress-filled': {
              backgroundColor: '#4378C8',
            },
            '& .rhap_time': {
              fontSize: '1rem',
              minWidth: '3rem',
            },
            '& .rhap_volume-bar-area': {
              width: '3rem',
            },
            '& .rhap_volume-button': {
              flex: '0 0 1.5rem',
            },
            '& .rhap_progress-container': {
              height: '1.2rem',
              width: '10rem',
              margin: '0 1rem',
            },
            '& .rhap_volume-indicator': {
              width: '1.2rem',
              height: '1.2rem',
              top: '-0.45rem',
            },
            '& .rhap_progress-bar': {
              height: '0.4rem',
            },
            '& .rhap_volume-bar': {
              height: '0.4rem',
            },
            '& .rhap_progress-section': {
              gap: '0.1rem',
            },
          }}>
          <AudioPlayer
            src={voice_note.url}
            onPlay={() => {
              var audioElements = document.querySelectorAll('audio');
              audioElements.forEach(function (audio) {
                if (audio.src !== voice_note.url) {
                  audio.pause();
                }
              });
            }}
            customAdditionalControls={[]}
            showJumpControls={false}
            customVolumeControls={[]}
            customProgressBarSection={[
              RHAP_UI.MAIN_CONTROLS,
              RHAP_UI.CURRENT_TIME,
              RHAP_UI.PROGRESS_BAR,
              RHAP_UI.DURATION,
              <div key={voice_note.url || ''}>{'  '}</div>,
              // RHAP_UI.VOLUME,
            ]}
          />
        </Stack>
      ))}
    </div>
  );
};

export default ImageModal;
