export function getAlertsText(ele: any) {
  const info = JSON.parse(ele?.message);
  const action = info?.action;
  if (action === 'your_action_pending_on_scheduled_request') {
    return (
      <span>
        No action has been taken on <span className='font-bold'>Request {ele?.pm_request_id}</span>.
      </span>
    );
  } else if (action === 'technician_not_assigned') {
    return (
      <span>
        <span className='font-bold'>Request {ele?.pm_request_id}</span> is pending assignment to a
        technician.
      </span>
    );
  } else if (action === 'technician_not_working') {
    return (
      <span>
        Technician has not started working on{' '}
        <span className='font-bold'>Request {ele?.pm_request_id}.</span>
      </span>
    );
  } else if (action === 'vendor_not_assigned') {
    return (
      <span>
        Vendor not assigned fot the Request{' '}
        <span className='font-bold'>Request {ele?.pm_request_id}.</span>
      </span>
    );
  } else if (action === 'your_action_pending_on_received_invoice') {
    return (
      <span>
        An invoice has been received for{' '}
        <span className='font-bold'>Request {ele?.pm_request_id}</span>, but no action has been
        taken within 24 hours.
      </span>
    );
  } else if (action === 'your_action_pending') {
    return (
      <span>
        Your action is pending for the{' '}
        <span className='font-bold'>Request {ele?.pm_request_id}.</span>
      </span>
    );
  } else return null;
}

export const actionText = (info: any) => {
  if (info === 'your_action_pending_on_scheduled_request') {
    return 'Take Action';
  }
  if (info === 'technician_not_assigned') {
    return 'View';
  }
  if (info === 'technician_not_working') {
    return 'Assign Technician';
  }
  if (info === 'vendor_not_assigned') {
    return 'Assign Vendor';
  }
  if (info === 'your_action_pending_on_received_invoice') {
    return 'Take Action';
  } else return 'View';
};
