const FleetMax = (props: { height?: string; width?: string; active?: boolean }) => {
  const height = props?.height || '1rem';
  const width = props?.width || '1rem';
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 258 41'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M24.5718 7.57607H8.58813V17.0521H24.1151V24.5872H8.58813V40H0.710485V0.0409278H24.5718V7.57607ZM38.1895 32.4649H53.431V40H30.3119V0.0409278H38.1895V32.4649ZM65.784 32.4649H82.6239V40H57.9064V0.0409278H82.3385V7.57607H65.784V16.0816H80.9114V23.5026H65.784V32.4649ZM96.0543 32.4649H112.894V40H88.1767V0.0409278H112.609V7.57607H96.0543V16.0816H111.182V23.5026H96.0543V32.4649ZM144.877 0.0409278V7.57607H134.088V40H126.211V7.57607H115.479V0.0409278H144.877ZM188.378 0.0409278V40H180.557V14.0837L169.311 32.6361H168.398L157.152 14.1408V40H149.275V0.0409278H157.381L168.855 18.9359L180.272 0.0409278H188.378ZM217.173 11.4578H224.537V40H217.173V36.632C214.965 39.4101 211.864 40.7992 207.868 40.7992C204.062 40.7992 200.789 39.353 198.049 36.4608C195.347 33.5304 193.996 29.9531 193.996 25.7289C193.996 21.5047 195.347 17.9464 198.049 15.0541C200.789 12.1238 204.062 10.6586 207.868 10.6586C211.864 10.6586 214.965 12.0477 217.173 14.8258V11.4578ZM203.587 31.5515C205.071 33.0357 206.955 33.7778 209.238 33.7778C211.521 33.7778 213.405 33.0357 214.889 31.5515C216.412 30.0293 217.173 28.0884 217.173 25.7289C217.173 23.3694 216.412 21.4476 214.889 19.9634C213.405 18.4411 211.521 17.68 209.238 17.68C206.955 17.68 205.071 18.4411 203.587 19.9634C202.102 21.4476 201.36 23.3694 201.36 25.7289C201.36 28.0884 202.102 30.0293 203.587 31.5515ZM257.53 40H249.024L242.859 31.3232L236.637 40H228.132L238.578 25.4435L228.588 11.4578H237.094L242.859 19.5067L248.625 11.4578H257.073L247.084 25.3864L257.53 40Z'
        fill='url(#paint0_linear_2_60)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_2_60'
          x1='-38.5909'
          y1='7.50846e-06'
          x2='250.801'
          y2='185.516'
          gradientUnits='userSpaceOnUse'>
          <stop stop-color='#2597BF' />
          <stop offset='1' stop-color='#174A8D' />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default FleetMax;
