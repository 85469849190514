const ServiceLocation = (item: any) => {
  const addressAsString: boolean =
    typeof item?.service_location === 'string' ? item?.service_location : '';
  if (item?.service_location?.locationInfo) {
    return item?.service_location?.locationInfo;
  } else if (addressAsString) {
    return item?.service_location;
  } else return '---';
};
export default ServiceLocation;
