import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PreventiveAPI from '../../../api/preventiveApi';
import HandleLoad from '../../../components/Loader/HandleLoad';
import { toast } from '../../../components/Toast/CustomToast';
import { RootState } from '../../../state/reducers';
import { bindActionCreators } from 'redux';
import PartnerApi from '../../../api/partnerSpecificApi';
import { actionCreators } from '../../../state';
import { makeBulkScheduleData } from '../../vender/PrimaryComponents/requestComponents/AcceptRejectBtn';
import { useNavigate } from 'react-router';

const useFetchSingleRequest = () => {
  const handleLoad = HandleLoad();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setRequestModal, setBulkVehicles } = bindActionCreators(actionCreators, dispatch);
  const { user, requestModal }: any = useSelector((state: RootState) => state.userState);
  const [data, setData] = useState(null as any);
  const fetchRequestData = async (data: any) => {
    if (!data?.id && !data?.preventive_request_id) return null;

    handleLoad(true);
    // const res = await PreventiveAPI.getVendorRequestData({
    //   vendor_account_id: user?.account_id,
    //   params: `&request_id=${data?.preventive_request_id || data?.id}`,
    // });
    const res = await PartnerApi.getAllDataParticularId(
      `preventive_request_id=${data?.preventive_request_id || data?.id}${
        data?.all_users ? '&all_users=true' : ''
      }`
    );
    handleLoad(false);
    if (res.status === 200) {
      // console.log(res.data?.data);
      // const tempData = res.data?.data?.requests;
      const tempData = res.data?.data;
      if (tempData && tempData?.length > 0) {
        const finalRequestObj: any = { ...tempData[0] };
        const id = finalRequestObj?.id || finalRequestObj?.preventive_request_id;

        if (tempData[0]?.bulk_id) {
          finalRequestObj['title'] = `Bulk-${tempData[0]?.bulk_id}/${id}`;
        } else {
          finalRequestObj['title'] = `Request-${id}`;
        }
        setData(finalRequestObj);
        return finalRequestObj;
      } else {
        toast.error('No data found');
        return null;
      }
    } else {
      toast.error('Something went wrong');
    }
  };

  const getFullRequestInfo = async (query: string = '') => {
    handleLoad(true);
    const params: string =
      query ||
      `preventive_request_id=${requestModal.data?.preventive_request_id || requestModal.data?.id}`;
    const res = await PartnerApi.getAllDataParticularId(params);
    if (res.status === 200) {
      const resultData = res.data.data;
      if (query) {
        if (resultData?.length === 0) {
          toast.error('No data found');
        }
        return resultData?.length > 0 ? resultData : [];
      }
      if (resultData?.length > 0) {
        setRequestModal({
          open: requestModal?.open || true,
          data: { ...requestModal.data, ...resultData[0] },
        });
      } else {
        toast.error('No data found');
        setRequestModal({ open: false, data: null });
      }
    } else {
      toast.error('Something Went wrong');
    }
    handleLoad(false);
  };

  const handleAssignVednor = async (item: any) => {
    const query = item?.bulk_id
      ? `bulk_id=${item?.bulk_id}`
      : `preventive_request_id=${item?.preventive_request_id}`;
    const data = await getFullRequestInfo(query);
    const structuredData = await makeBulkScheduleData(data);
    if (structuredData?.length > 0) {
      setBulkVehicles({
        bulkVehicles: structuredData,
      });
      navigate('/scheduleRequest');
    }
  };

  return { data, setData, fetchRequestData, handleAssignVednor, requestModal, setRequestModal };
};

export default useFetchSingleRequest;
