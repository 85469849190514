import { TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import EyeClose from "../../assets/PasswordClose";
import EyeOpen from "../../assets/PasswordOpen";

import * as React from 'react';

export default function InputAdornments(props: any) {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    // <FormControl sx={{ width: '25ch', ...props?.sx }} variant="outlined">
    <TextField
      id="outlined-adornment-password"
      {...props}
      type={showPassword ? 'text' : "password"}
      InputProps={{
        autoComplete: "new-password",
        endAdornment: (
          <InputAdornment position="end" sx={{ marginLeft: 0 }}>
            <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword(!showPassword)} edge="end" sx={{ backgroundColor: "transparent !important", paddingLeft: 0 }} disableRipple>
              {!showPassword ? <EyeClose height={props?.size === "small" ? "15" : ""} /> : <EyeOpen height={props?.size === "small" ? "11" : ""} />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      label={props.label}
    />
    // </FormControl>
  );
}