import { useState } from 'react';
import PreventiveAPI from '../../../api/preventiveApi';
import HandleLoad from '../../../components/Loader/HandleLoad';
import { toast } from '../../../components/Toast/CustomToast';
import { useDispatch, useSelector } from 'react-redux';
import PartnerApi from '../../../api/partnerSpecificApi';
import { RootState } from '../../../state/reducers';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../state';
import { makeBulkScheduleData } from '../../vender/PrimaryComponents/requestComponents/AcceptRejectBtn';
import { useNavigate } from 'react-router';
import CsrAPI from '../../../api/csrApi';

const useFetchSingleRequest = () => {
  const handleLoad = HandleLoad();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState(null as any);
  const { requestModal, user }: any = useSelector((state: RootState) => state.userState);
  const { setRequestModal, setBulkVehicles } = bindActionCreators(actionCreators, dispatch);
  const isValidUser = ['fleetmax_partner', 'fleetmax_vendor'].includes(user?.role);
  const fetchRequestData = async (data: any) => {
    if (!data?.id && !data?.preventive_request_id) return null;

    handleLoad(true);
    const res = await PreventiveAPI.getScheduleForCsr(
      `&request_id=${data?.pm_request_id || data?.preventive_request_id || data?.id}`
    );
    handleLoad(false);
    if (res.status === 200) {
      const tempData = res.data?.data?.requests;
      if (tempData && tempData?.length > 0) {
        const finalRequestObj: any = { ...tempData[0] };
        const id = finalRequestObj?.id || finalRequestObj?.preventive_request_id;
        if (tempData[0]?.bulk_id) {
          finalRequestObj['title'] = `Bulk-${tempData[0]?.bulk_id}/${id}`;
        } else {
          finalRequestObj['title'] = `Request-${id}`;
        }
        setData(finalRequestObj);

        return finalRequestObj;
      } else {
        toast.error('No data found');
        return null;
      }
    } else {
      toast.error('Something went wrong');
    }
  };

  const getFullRequestInfo = async (query: string = '') => {
    if (!requestModal.data?.preventive_request_id && !requestModal.data?.id && !query) return;
    handleLoad(true);
    const params: string =
      query ||
      `preventive_request_id=${requestModal.data?.preventive_request_id || requestModal.data?.id}`;

    const res = await PartnerApi.getAllDataParticularId(params);
    if (res.status === 200) {
      const resultData = res.data.data;
      const isSameIdinRejected = CheckisSameID(user, resultData?.[0]);
      if (query) {
        if (resultData?.length === 0) {
          toast.error('No data found');
        }
        return resultData?.length > 0 ? resultData : [];
      }
      if (resultData?.length > 0) {
        setRequestModal({
          open: true,
          data: {
            ...requestModal.data,
            sub_stage: null,
            ...resultData[0],
            ...(!isSameIdinRejected &&
              isValidUser && {
                status_id: user?.role === 'fleetmax_partner' ? '-1' : '4',
                sub_stage: '3',
                vendor_invoice_dispute: null,
                vendor_invoice_status: null,
                invoice: null,
                final_payment: null,
              }),
          },
        });
      } else {
        toast.error('No data found');
        setRequestModal({ open: false, data: null });
      }
    } else {
      toast.error('Something Went wrong');
    }
    handleLoad(false);
  };

  const checkIfReadyToMove = async (id_arr: string[]) => {
    if (!id_arr) return;
    handleLoad(true);
    const params: string = `request_ids=${JSON.stringify(id_arr)}`;
    const res = await CsrAPI.checkIfReadyToMove(params);
    handleLoad(false);
    if (res?.status === 200) {
      return true;
    }
    return false;
  };

  const handleAssignVednor = async (item: any) => {
    const query = item?.bulk_id
      ? `bulk_id=${item?.bulk_id}`
      : `preventive_request_id=${item?.preventive_request_id}`;
    const data = await getFullRequestInfo(query);
    const structuredData = await makeBulkScheduleData(data);
    if (structuredData?.length > 0) {
      setBulkVehicles({
        bulkVehicles: structuredData,
      });
      navigate('/scheduleRequest');
    }
  };

  return {
    data,
    setData,
    fetchRequestData,
    getFullRequestInfo,
    handleAssignVednor,
    checkIfReadyToMove,
  };
};

export default useFetchSingleRequest;
export const CheckisSameID = (user: any, data: any) => {
  if (user?.role === 'fleetmax_partner') {
    return +data?.partner?.account_id === +user?.account_id;
  } else if (user?.role === 'fleetmax_vendor') {
    return +data?.vendor?.account_id === +user?.account_id;
  } else {
    return false;
  }
};
