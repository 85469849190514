import { Button } from '@mui/base';
import { Popover } from '@mui/material';
import { useParentDimensions, usePopover } from './ExpandComps';
let parentWidth = 0;
let parentHeight = 0;

export default function ExpandPopupAppointment({
  item,
  icon,
  showOnExpand,
  title,
  variant,
  noPopUp,
}: {
  item: any;
  noPopUp?: boolean;
  variant?: string;
  icon?: JSX.Element;
  title: string;
  showOnExpand: JSX.Element;
}) {
  const parentRef = useParentDimensions(parentWidth, parentHeight);
  const { anchorEl, handleOpenPopup, handleClosePopup } = usePopover();
  const expandDown = variant === 'expandDown';
  const upDirection = expandDown ? !anchorEl : anchorEl;

  if (!item) return null;

  return (
    <div className='w-full'>
      <Button
        className={` text-primary relative w-full border-transparent flex justify-between px-4 font-bold p-1.5 ${
          upDirection && 'rounded-b-[1rem]'
        } text-[1rem] ${
          anchorEl && (upDirection ? 'rounded-t-none' : 'rounded-b-none')
        } h-[2.5rem]`}
        onClick={handleOpenPopup}
        ref={parentRef}>
        <div className='ml-[-1rem]'>{title}</div>
        <i
          className={`pi pi-angle-down ${
            upDirection ? 'rotate-0' : 'rotate-180'
          } ease-in-out duration-200 text-grey text-primary`}></i>
      </Button>
      {noPopUp ? (
        anchorEl && (
          <div
            style={{
              width: +parentRef.current?.clientWidth,
              boxShadow: 'none',
              borderRadius: 0,
              ...(expandDown
                ? {
                    borderBottomLeftRadius: '0.5rem',
                    borderBottomRightRadius: '0.5rem',
                  }
                : {
                    borderTopLeftRadius: '0.5rem',
                    borderTopRightRadius: '0.5rem',
                  }),
            }}>
            <div
              className='relative  w-full bg-primary-light'
              style={{
                ...(expandDown
                  ? {
                      borderBottomLeftRadius: '0.5rem',
                      borderBottomRightRadius: '0.5rem',
                    }
                  : { borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem' }),
              }}>
              {showOnExpand}
            </div>
          </div>
        )
      ) : (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClosePopup}
          PaperProps={{
            sx: {
              width: parentRef.current?.clientWidth + 3,
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
              borderRadius: 0,
              ...(expandDown
                ? {
                    borderBottomLeftRadius: '0.5rem',
                    borderBottomRightRadius: '0.5rem',
                  }
                : {
                    borderTopLeftRadius: '0.5rem',
                    borderTopRightRadius: '0.5rem',
                  }),
            },
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
            ...(expandDown && {
              vertical: 'bottom',
              horizontal: 'center',
            }),
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            ...(expandDown && {
              vertical: 'top',
              horizontal: 'center',
            }),
          }}>
          <div
            className='relative border-l-[0.1rem] border-r-[0.1rem] border-b-[0.1rem]  w-full '
            style={{
              ...(expandDown
                ? {
                    borderBottomLeftRadius: '0.5rem',
                    borderBottomRightRadius: '0.5rem',
                  }
                : { borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem' }),
              width: '100%',
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            }}>
            {showOnExpand}
          </div>
        </Popover>
      )}
    </div>
  );
}
