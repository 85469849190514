import { ThreeDots } from 'react-loader-spinner';
import RTSInspectionIcon from '../../../assets/RTSInspectionIcon';
import { getAnswer } from '../../csr/scheduleRequest/components/AddSummary';
import { useFetchSummary } from '../../hooks/useFetchSummary';
import ExpandPopUp from './ExpandPopup';

export default function RTSInspectionSummary({
  item,
  variant,
}: {
  item: { preventive_request_id?: string; id: string; vehicle: { id: string }; questions: any[] };
  variant?: string;
}) {
  const { summary, getLatestInspectionSummary, sendLoader } = useFetchSummary();
  if (!item || !item?.questions) return null;
  return (
    <div className='w-full'>
      <ExpandPopUp
        icon={
          <i>
            <RTSInspectionIcon />
          </i>
        }
        onOpen={() => {
          getLatestInspectionSummary({
            vehicle_id: item.vehicle.id,
            preventive_request_id: item?.preventive_request_id || item.id,
          });
        }}
        showOnExpand={
          <div
            className='container overflow-auto  mb-0.9 p-2 h-[10rem]'
            style={{
              backgroundColor: 'white',
              borderRadius: '0.4rem',
            }}>
            {sendLoader && (
              <div className='flex items-center justify-center w-full h-[8%]'>
                <ThreeDots
                  height='10'
                  width='50'
                  radius='9'
                  color={'#4378C8'}
                  ariaLabel='three-dots-loading'
                  wrapperStyle={{}}
                  visible={true}
                />
              </div>
            )}
            {!summary[0] && !sendLoader && (
              <div className='flex items-center justify-center w-full h-[8%] text-gray-400 mt-4'>
                <p>Inspection not conducted for the vehicle</p>
              </div>
            )}

            {!sendLoader &&
              summary?.length > 0 &&
              summary.map((item: any, index: number) => (
                <div key={item} className='flex gap-2 items-center justify-between w-full p-2 px-5'>
                  <div className='flex gap-2 items-center'>
                    <p className='select-none text-gray-400'>{item?.question}</p>
                  </div>
                  {getAnswer(item, true)}
                </div>
              ))}
          </div>
        }
        title='RTS Inspection Summary'
        item={item}
        variant={variant}
      />
    </div>
  );
}
