import { Button, Popover } from '@mui/material';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import CsrAPI from '../../api/csrApi';
import VendorAPI from '../../api/vendorApi';
import DisputeHistoryIcon from '../../assets/DisputeHistoryIcon';
import HandleLoad from '../../components/Loader/HandleLoad';
import { toast } from '../../components/Toast/CustomToast';
import { actionCreators } from '../../state';
import { RootState } from '../../state/reducers';
import useFetchInvoiceDetails from '../csr/primaryComponents/Invoice/components/useFetchInvoiceDetails';
import FileAttach from '../vender/PrimaryComponents/invoice/components/FileAttach';
import FileList from '../vender/PrimaryComponents/invoice/components/FileList';
import { UserStateModel } from '../../models/UserModel';
let parentWidth = 0;
let parentHeight = 0;

type Note = {
  note: string;
  created_by: string;
  user_type: string;
  created_at: string;
};

type Dispute = {
  dispute_id: string;
  notes: Note[];
  created_at: string;
};

export default function DisputeHistoryPopup({
  item,
  refetch,
  variant,
}: {
  item: any;
  refetch?: any;
  variant?: string;
}) {
  const { loading, attachments }: UserStateModel = useSelector(
    (state: RootState) => state.userState
  );
  const dispatch = useDispatch();
  const handleLoad = HandleLoad();
  const { disputeHistory, invoiceDetails, fetchDisputeHistory, fetchInvoiceDetails } =
    useFetchInvoiceDetails();
  const [anchorEl, setAnchorEl] = useState(null);
  const parentRef: any = useRef(null);
  const { invoices } = item;
  const { setDispute, setAttachments } = bindActionCreators(actionCreators, dispatch);
  const [decline, setDecline] = useState<any>({ show: false, reason: '', isAccept: false });
  const getChecklistName = new Map();
  item?.checklists?.forEach((checklist: any) =>
    getChecklistName.set(checklist?.name, checklist?.id)
  );
  const vendorInvoices = useMemo(() => {
    if (!invoices) return null;
    return invoices?.[0]?.invoices?.filter((invoices: any) => invoices.recipient === 'vendor');
  }, [invoices]);
  const createDispute = () => {
    handleLoad(true);
    const payload = {
      note: decline.reason?.trim(),
      invoice_id: Number(vendorInvoices?.[0]?.id),
      dispute_id: Number(disputeHistory?.[0]?.dispute_id),
      ...(attachments.length > 0 && { attachments }),
    };
    VendorAPI.declineDisputeCsr(payload).then((res) => {
      if (res.status === 200) {
        toast.success('Invoice Declined');
        setShowDecline(false);
        setReason('');
        fetchDisputeHistory(item);
        refetch?.();
        setAttachments([]);
      }
      handleLoad(false);
    });
  };
  const handleAccept = async () => {
    handleLoad(true);
    const firstDispute: Dispute = disputeHistory?.[0];
    const latestDispute = await VendorAPI.DisputeIdHistory({ id: firstDispute?.dispute_id });
    const disputedServices = latestDispute?.data?.data;
    const disputeServiceAmountMap = new Map();
    disputedServices.forEach(
      (d: { id: string; name: string; disputed_amount: number; csr_amount: number }) => {
        disputeServiceAmountMap.set(+d?.id, d?.disputed_amount?.toString());
      }
    );
    const transformedServices = invoiceDetails?.invoice?.additional_info?.map((service: any) => {
      return {
        service_name: service?.service?.toString(),
        price: service?.price ? String(service?.price) : '0',
        ...(!isNaN(disputeServiceAmountMap.get(+service?.id)) && {
          price: disputeServiceAmountMap.get(+service?.id),
        }),
        ...(service.checklist_id && { checklist_id: service.checklist_id.toString() }),
      };
    });

    const payload = {
      preventive_request_id: item.preventive_request_id || item.id || '',
      invoice_number: invoiceDetails.invoice.number,
      services: transformedServices,
    };

    CsrAPI.generateVendorInvoice(payload).then((res) => {
      if (res.status === 200) {
        toast.success('Invoice Accepted');
        fetchDisputeHistory(item);
        fetchInvoiceDetails(vendorInvoices?.[0]?.id);
        refetch?.();
        setShowDecline(false);
        setReason('');
      }
      handleLoad(false);
    });
  };
  useEffect(() => {
    if (vendorInvoices?.[0]?.id && anchorEl !== null) {
      fetchDisputeHistory(item);
      fetchInvoiceDetails(vendorInvoices?.[0]?.id);
    }
  }, [anchorEl]);
  useEffect(() => {
    if (parentRef.current) {
      parentWidth = parentRef.current.clientWidth;
      parentHeight = parentRef.current.clientHeight;
    }
  }, [parentRef]);

  const handleOnClick = (index: number) => {
    setDispute({ open: true, allData: [...disputeHistory], clickedIndex: index });
  };
  const setShowDecline = (value: any, isAccept?: boolean) => {
    setDecline((prevState: any) => ({
      ...prevState,
      show: value,
      isAccept: isAccept !== undefined ? isAccept : prevState.isAccept,
    }));
  };
  const setReason = (value: any) => {
    setDecline((prevState: any) => ({
      ...prevState,
      reason: value,
    }));
  };
  const defaultHistory = (ele: any, parentIndex: any) => {
    return (
      <div className='py-3 px-2 flex flex-col gap-1 '>
        <p className='w-full flex justify-between'>
          <p className='flex gap-1 items-center font-bold text-primary text-[0.9rem]'>
            <p className='text-gray-400 text-[0.9rem] font-bold bg-gray-100 rounded-[0.5rem] px-1'>
              # {parentIndex + 1}
            </p>
            <p className='font-bold text-primary text-[0.9rem]'>Vendor</p>{' '}
          </p>
          <p className='text-gray-400 text-[0.8rem] font-bold '>
            {moment(ele?.created_at).format('l')} {moment(ele?.created_at).format('LT')}
          </p>
        </p>
        <p className='w-full flex justify-between'>
          <p className='font-bold font-bold text-gray-500'>N/A</p>{' '}
          <p
            className='text-[0.8rem] text-primary font-bold cursor-pointer'
            onClick={() => {
              handleOnClick(parentIndex);
            }}>
            Details
          </p>
        </p>
      </div>
    );
  };
  if (!item || !window.location.href.includes('dispute')) return null;
  return (
    <div className='w-full'>
      <ExpandPopUp
        icon={
          <i>
            <DisputeHistoryIcon />
          </i>
        }
        showOnExpand={
          <div
            className={`container overflow-auto flex flex-col gap-4  mb-0.9 p-2 ${
              item?.bulk_id ? ' h-[21rem]' : ' h-[23rem]'
            }`}
            style={{
              backgroundColor: 'white',
              borderRadius: '0.4rem',
            }}>
            {decline.show ? (
              <div className='h-full w-full flex flex-col justify-between gap-1'>
                <div className='flex flex-col gap-3 h-[90%]'>
                  <p className=' flex gap-2 items-center w-fit text-gray-400 text-[1.1rem] font-bold rounded-[0.5rem] px-1'>
                    # {disputeHistory?.length}
                    <p className='text-primary'>Dispute</p>
                  </p>
                  <div className='relative w-full  border-gray-300 border-[0.1rem] rounded-[1rem]'>
                    <textarea
                      className={`w-full ${
                        item?.bulk_id ? 'min-h-[3rem] p-2 px-3' : ' min-h-[6rem] p-5'
                      }  rounded-[1rem]   order transition-colors duration-300 outline-none focus:border-blue-500 border-1 resize-none`}
                      value={decline?.reason}
                      onChange={(e) => {
                        setReason(e.target.value);
                      }}
                      placeholder='Notes'
                    />
                  </div>
                  <FileAttach />
                </div>
                <div className='h-[10%] flex justify-between items-center'>
                  <Button
                    className='font-bold w-[30%] hover:bg-primary-light'
                    onClick={() => {
                      setShowDecline(false);
                      setReason('');
                      setAttachments([]);
                    }}>
                    Cancel
                  </Button>
                  <Button
                    className='font-bold w-[30%]'
                    disabled={!decline?.reason?.trim() || loading}
                    variant='contained'
                    onClick={() => {
                      if (decline?.isAccept) {
                        handleAccept();
                        return;
                      }
                      createDispute();
                    }}>
                    {decline?.isAccept ? 'Accept' : 'Submit'}
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <div className='h-[90%] w-full  flex flex-col gap-2 overflow-auto pr-1'>
                  {disputeHistory?.map((ele: any, parentIndex: number) => {
                    return (
                      <div
                        key={ele?.dispute_id || ''}
                        className='flex flex-col gap-2 border-gray-200 rounded-[0.8rem] border-[0.11rem] p-2 divide-y-2'>
                        {ele?.dispute_id ? (
                          <>
                            <div className='flex justify-between items-center'>
                              <p className='text-gray-400 text-[1.1rem] font-bold rounded-[0.5rem] px-1'>
                                # {disputeHistory?.length - parentIndex}
                              </p>
                              <p
                                className='text-[0.9rem] text-primary font-bold cursor-pointer'
                                onClick={() => {
                                  handleOnClick(parentIndex);
                                }}>
                                Details
                              </p>
                            </div>
                            {ele?.notes?.length > 0 ? (
                              <>
                                {ele?.notes?.map((child: any, index: number) => {
                                  return (
                                    <div key={child} className='py-3 px-2 flex flex-col gap-1 '>
                                      <p className='w-full flex justify-between'>
                                        <p className='flex gap-1 items-center font-bold text-primary text-[0.9rem]'>
                                          <p className='font-bold text-primary text-[0.9rem]'>
                                            {['fleetmax_vendor', 'fleetmax_partner'].includes(
                                              child.user_type
                                            )
                                              ? 'Vendor'
                                              : 'You'}
                                          </p>{' '}
                                        </p>
                                        <p className='flex gap-2 items-center text-gray-400 text-[0.8rem] font-bold '>
                                          <p>{moment(child?.created_at).format('l')}</p>
                                          <p>{moment(child?.created_at).format('LT')}</p>
                                        </p>
                                      </p>
                                      <p className='w-full flex justify-between'>
                                        <p className='font-bold font-bold text-gray-500'>
                                          {child.note}
                                        </p>{' '}
                                      </p>
                                      {child.attachments && (
                                        <FileList attachments={child.attachments} />
                                      )}
                                    </div>
                                  );
                                })}
                              </>
                            ) : (
                              <>{defaultHistory(ele, parentIndex)}</>
                            )}
                          </>
                        ) : (
                          <p>No notes available</p>
                        )}
                      </div>
                    );
                  })}
                </div>
                {disputeHistory?.length > 0 && (
                  <div className='h-[10%] flex justify-between items-center mx-3'>
                    <Button
                      className='font-bold bg-gray-100 w-[30%] text-[#C55] disabled:text-gray-500 hover:bg-gray-200'
                      disabled={item?.vendor_invoice_dispute !== 'raised'}
                      onClick={() => {
                        setShowDecline(true);
                        setReason('');
                      }}>
                      Decline
                    </Button>
                    <Button
                      className='font-bold w-[30%] disabled:text-gray-500 disabled:bg-gray-100'
                      variant='contained'
                      disabled={!['raised', 'declined'].includes(item?.vendor_invoice_dispute)}
                      onClick={async () => {
                        setAnchorEl(null);
                        item?.setInvoiceModal?.({
                          open: true,
                          data: {
                            ...item,
                            ...(refetch && { refetch: refetch }),
                            updateVendorInvoice: true,
                            disputeUpdate: true,
                          },
                        });
                      }}>
                      Accept
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        }
        title='Dispute History'
        item={item}
        variant={variant}
        setAnchorEl={setAnchorEl}
        anchorEl={anchorEl}
      />
    </div>
  );
}
function ExpandPopUp({
  item,
  icon,
  showOnExpand,
  title,
  variant,
  noPopUp,
  setAnchorEl,
  anchorEl,
}: {
  item: any;
  noPopUp?: boolean;
  variant?: string;
  icon?: JSX.Element;
  title: string;
  showOnExpand: JSX.Element;
  anchorEl?: any;
  setAnchorEl?: any;
}) {
  const parentRef: any = useRef(null);
  useEffect(() => {
    if (parentRef.current) {
      parentWidth = parentRef.current.clientWidth;
      parentHeight = parentRef.current.clientHeight;
    }
  }, [parentRef]);

  const handleOpenPopup = (event: any) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
  };

  const expandDown = variant === 'expandDown';

  const upDirection = expandDown ? !anchorEl : anchorEl;

  if (!item) return null;

  return (
    <div className='w-full'>
      <Button
        className={`bg-primary-light text-primary relative w-full  flex justify-between px-4 font-bold p-1.5  ${
          anchorEl && (upDirection ? 'rounded-t-none' : 'rounded-b-none')
        } h-[2.5rem]`}
        onClick={handleOpenPopup}
        ref={parentRef}>
        <div className='flex gap-2 items-center'>
          {icon}
          {title}
        </div>
        <i
          className={`pi pi-angle-down ${
            upDirection ? 'rotate-0' : 'rotate-180'
          } ease-in-out duration-200 text-grey text-primary`}></i>
      </Button>
      {noPopUp ? (
        anchorEl && <></>
      ) : (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClosePopup}
          sx={{ zIndex: 60 }}
          PaperProps={{
            sx: {
              width: parentRef.current?.clientWidth,
              boxShadow: 'none',
              borderRadius: 0,
            },
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
            ...(expandDown && {
              vertical: 'bottom',
              horizontal: 'center',
            }),
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            ...(expandDown && {
              vertical: 'top',
              horizontal: 'center',
            }),
          }}>
          <div
            className={`w-full border-r-[0.20rem] border-l-[0.20rem] ${
              item?.bulk_id
                ? 'border-t-[0.20rem] rounded-t-[0.5rem]'
                : 'border-b-[0.20rem] rounded-b-[0.5rem]'
            }  border-primary-light p-1 bg-transparent  `}>
            {showOnExpand}
          </div>
        </Popover>
      )}
    </div>
  );
}
