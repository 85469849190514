const CrossButtonsCalender = (props: { height?: string; width?: string; active?: boolean }) => {
  const height = props?.height || 25;
  const width = props?.width || 25;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <circle cx='12.5' cy='12.5' r='12.5' fill='#8B8B8B' fill-opacity='0.13' />
      <path
        d='M13.6231 13.0547L16.6725 10C17.1092 9.5625 17.1092 8.85547 16.6725 8.41797C16.2357 7.98047 15.5299 7.98047 15.0932 8.41797L12.0458 11.4727L8.9068 8.32812C8.47007 7.89062 7.76428 7.89062 7.32755 8.32812C6.89082 8.76562 6.89082 9.47266 7.32755 9.91016L10.4666 13.0547L7.43478 16.0918C6.99805 16.5293 6.99805 17.2363 7.43478 17.6738C7.65315 17.8906 7.9378 18 8.22441 18C8.51102 18 8.79567 17.8906 9.01404 17.6719L12.0458 14.6348L14.9879 17.582C15.2063 17.8008 15.4909 17.9102 15.7775 17.9102C16.0641 17.9102 16.3488 17.8008 16.5672 17.582C17.0039 17.1445 17.0039 16.4375 16.5672 16L13.6231 13.0547Z'
        fill='#A0A0A0'
      />
    </svg>
  );
};
export default CrossButtonsCalender;
