const SVG = (props: { height?: string; width?: string; active?: boolean }) => {
  const height = props?.height || '2.5rem';
  const width = props?.width || '3.3rem';
  return (
    <div className='w-[3.5rem] h-[2rem] bg-[#E5F8BD] flex items-center justify-center rounded-[0.6rem]'>
      <svg
        width={width}
        height={height}
        viewBox='0 0 61 34'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        {/* <rect width={61} height={34} rx='17' fill='#E5F8BD' /> */}
        <path
          d='M34.8504 6C36.5487 6 38.0561 7.50738 38.0561 9.20693C38.0561 10.9065 36.5487 12.4139 34.8504 12.4139C33.1521 12.4139 31.6448 10.9065 31.6448 9.20693C31.6448 7.50738 33.1521 6 34.8504 6ZM34.6401 16.0966C36.266 16.0966 37.8218 16.7969 38.9919 18.2484C40.1708 19.7108 41 21.9946 41 25.1933C41 25.7155 40.5902 26.3313 39.3147 26.8641C38.094 27.3739 36.3952 27.6514 34.6401 27.6514C32.8851 27.6514 31.1863 27.3739 29.9656 26.8641C28.6901 26.3313 28.2803 25.7155 28.2803 25.1933C28.2803 21.9946 29.1095 19.7108 30.2884 18.2484C31.4585 16.7969 33.0143 16.0966 34.6401 16.0966Z'
          stroke='#3E9983'
          stroke-width='2'
        />
        <path
          d='M25.0689 8.29376C24.9648 8.20352 24.7982 8.27784 24.7948 8.41653L24.7414 10.6996C24.7371 10.8704 24.6874 11.0369 24.5976 11.1822C24.5077 11.3275 24.3809 11.4464 24.2301 11.5266L23.0943 12.1307C22.9482 12.209 22.7843 12.2481 22.6186 12.2441C22.4529 12.2401 22.291 12.1932 22.1489 12.108L21.0498 11.45C20.749 11.2733 20.572 10.9487 20.5788 10.5992L20.634 8.31471C20.6389 8.17486 20.476 8.0923 20.3662 8.17889C19.5511 8.83237 19.0157 9.82683 18.9886 10.9536C18.9568 12.2652 19.6174 13.4287 20.6361 14.1L20.4433 22.1047C20.4138 23.3197 21.3314 24.1299 22.3329 24.1541C23.3494 24.1785 24.2873 23.3833 24.3195 22.2581L24.5122 14.1905C25.0469 13.8746 25.4924 13.4278 25.8066 12.892C26.1208 12.3563 26.2933 11.7494 26.308 11.1285C26.3212 10.5942 26.2171 10.0634 26.003 9.57366C25.7889 9.0839 25.4701 8.64691 25.0689 8.29376ZM23.1862 21.9844C23.1838 22.0898 23.1606 22.1936 23.1179 22.29C23.0753 22.3863 23.0141 22.4734 22.9378 22.5461C22.8615 22.6188 22.7717 22.6757 22.6733 22.7136C22.575 22.7516 22.4702 22.7697 22.3649 22.7671C22.1525 22.7611 21.9511 22.6714 21.8045 22.5175C21.6579 22.3637 21.578 22.1582 21.5822 21.9458C21.5929 21.5023 21.9602 21.1523 22.4035 21.1631C22.8469 21.1739 23.1969 21.5409 23.1862 21.9844ZM22.7593 15.4821L22.6701 19.1732C22.6699 19.202 22.6639 19.2305 22.6524 19.257C22.641 19.2835 22.6243 19.3074 22.6034 19.3273C22.5825 19.3472 22.5578 19.3627 22.5308 19.3729C22.5038 19.3831 22.4751 19.3877 22.4462 19.3865C22.4177 19.3859 22.3896 19.3796 22.3635 19.3681C22.3374 19.3566 22.3138 19.34 22.2941 19.3193C22.2744 19.2987 22.259 19.2743 22.2488 19.2477C22.2385 19.2211 22.2336 19.1927 22.2343 19.1642L22.3236 15.4698C22.3242 15.4413 22.3305 15.4132 22.342 15.3871C22.3536 15.3611 22.3701 15.3375 22.3908 15.3179C22.4114 15.2982 22.4357 15.2828 22.4623 15.2725C22.4889 15.2623 22.5173 15.2573 22.5458 15.2581C22.6036 15.2597 22.6585 15.2841 22.6985 15.326C22.7384 15.3679 22.7601 15.4239 22.759 15.4818L22.7593 15.4821Z'
          fill='#3E9983'
        />
      </svg>
    </div>
  );
};
export default SVG;
