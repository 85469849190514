import { Avatar, Typography } from '@mui/material';
import { Button } from 'primereact/button';
import { useState } from 'react';
import PreventiveAPI from '../../../../api/preventiveApi';
import Modal from '../../../../components/OverlayContainer/Model';
import { toast } from '../../../../components/Toast/CustomToast';
import CsrAPI from '../../../../api/csrApi';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../state/reducers';
import { actionCreators } from '../../../../state';
import { bindActionCreators } from 'redux';
import HandleLoad from '../../../../components/Loader/HandleLoad';
import { MainStateModel } from '../../../../models/MainStateModel';
import useFetchSingleRequest from '../../../hooks/vendor/useFetchSingleRequest';

const SubmitAssignModal = ({ handleClose, item, handleBack, vendorData, handleDone }: any) => {
  const dispatch = useDispatch();
  const loader = HandleLoad();
  const [notes, setNotes] = useState('');
  const { singleScheduleVehicle }: MainStateModel = useSelector(
    (state: RootState) => state.mainState
  );
  const { fetchRequestData }: any = useFetchSingleRequest();
  const { appointmentTime, user }: any = useSelector((state: RootState) => state.userState);
  const { setAppointment, setSingleVehicle } = bindActionCreators(actionCreators, dispatch);
  const isPartnerReassign: boolean = item?.isPartnerReassign;
  const isTechReassign: boolean = item?.isTechnicianReassign;
  const assignDspRequstToVendor = () => {
    loader(true);
    PreventiveAPI.addTimeAndChecklist({
      pm_request_id: vendorData.id,
      appointment_id: vendorData.selectedSlot?.id,
      name: vendorData.selectedServices,
      vendor_account_id: item.vendor_account_id,
      ...(notes?.length > 0 && { note: notes }),
      ...(singleScheduleVehicle['selectedInspections'] &&
        singleScheduleVehicle['selectedInspections'].length && {
          question_ids: singleScheduleVehicle['selectedInspections'],
        }),
    }).then((res) => {
      loader(false);
      if (res.status === 200) {
        handleDone();
        handleClose();
        setSingleVehicle(() => ({
          ...singleScheduleVehicle,
          selectedInspections: [],
        }));
      } else {
        toast.error(res?.data?.message || 'Somethig Went Wrong');
      }
    });
  };
  const reAssignVendor = () => {
    loader(true);
    if (vendorData?.bulk_id) {
      CsrAPI.reAssignVendorBulk({
        bulk_requests: vendorData?.bulkSchedules.map((el: any) => ({
          preventive_request_id: el.id,
        })),
        vendor_account_id: vendorData.vendor.account_id,
        notes: {
          vendor: notes,
        },
        bulk_id: vendorData.bulk_id,
        new_vendor_account_id: item.vendor_account_id,
      }).then((res) => {
        if (res.status === 200) {
          toast.success(`Bulk Vendor Assignnment Successful`);
          handleDone();
          handleClose();
        } else {
          toast.error(res?.data?.message || 'Somethig Went Wrong');
        }
        loader(false);
      });
    } else {
      CsrAPI.reAssignVendorBulk({
        bulk_requests: [
          {
            preventive_request_id: vendorData.id,
          },
        ],
        vendor_account_id: vendorData.vendor.account_id,
        notes: {
          vendor: notes,
        },
        new_vendor_account_id: item.vendor_account_id,
      }).then((res) => {
        if (res.status === 200) {
          toast.success(`${vendorData.vehicle.name} Assigned to  ${item.vendor_name} `);
          handleDone();
          handleClose();
        } else {
          toast.error(res?.data?.message || 'Somethig Went Wrong');
        }
        loader(false);
      });
    }
  };
  const assignDSPPendingRequestToVendor = () => {
    CsrAPI.assignVendor({
      preventive_maintenance_id: Number(vendorData?.preventive_maintenance_id),
      preventive_request_id: Number(vendorData.id),
      vendor_account_id: item.vendor_account_id,
      time: appointmentTime?.time,
      date: appointmentTime?.date,
      note: notes,
    }).then((res) => {
      if (res.status === 200) {
        toast.success(`Vendor Assigned Successfully`);
        handleDone();
        handleClose();
      } else {
        toast.error(res?.data?.message || 'Somethig Went Wrong');
      }
      loader(false);
    });
  };
  const assignDSPPendingRequestToVendorBulk = (payload: any) => {
    CsrAPI.assignVendorBulk(payload).then((res) => {
      if (res.status === 200) {
        toast.success(`Vendor Assigned Successfully`);
        handleDone();
        handleClose();
      } else {
        toast.error(res?.data?.message || 'Somethig Went Wrong');
      }
      loader(false);
    });
  };
  const defaultVendorAssignment = () => {
    if (vendorData?.bulk_id) {
      PreventiveAPI.assignVendorsBulk({
        bulk_requests: vendorData?.bulkSchedules
          ?.filter(
            (el: any) => el.status_id === '2' || (el.status_id === '3' && el?.sub_stage === '2')
          )
          .map((el: any) => ({ id: el.id })),
        vendor_account_id: item.vendor_account_id,
        note: notes,
        bulk_id: vendorData.bulk_id,
        status_id: '4',
      }).then((res) => {
        if (res.status === 200) {
          toast.success(`Bulk Vendor Assignnment Successful`);
          handleDone();
          handleClose();
        } else {
          toast.error(res?.data?.message || 'Somethig Went Wrong');
        }
        loader(false);
      });
    } else {
      PreventiveAPI.assignVendors({
        preventive_request_id: vendorData.id,
        vendor_account_id: item.vendor_account_id,
        note: notes,
      }).then((res) => {
        if (res.status === 200) {
          toast.success(`${vendorData.vehicle.name} Assigned to  ${item.vendor_name} `);
          handleDone();
          handleClose();
        } else {
          toast.error(res?.data?.message || 'Somethig Went Wrong');
        }
        loader(false);
      });
    }
  };
  const handleSubmit = () => {
    // reassign
    if (vendorData?.vendor?.account_id) {
      loader(true);
      reAssignVendor();
      return;
    }
    if (+vendorData.status_id === 1) {
      // when request assigned by dsp
      if (vendorData?.request_type === 'manual_dsp') {
        loader(true);
        assignDspRequstToVendor();
        return;
      } else {
        // assign dsp pending request to vendor bulk
        if (vendorData?.bulk_id) {
          // for common appointment
          const payload = {
            accept_appintments: appointmentTime?.appointments,
            bulk_id: Number(vendorData.bulk_id),
            vendor_account_id: String(item.vendor_account_id),
            note: notes,
            common_slot: appointmentTime.common_slots,
          };
          loader(true);
          assignDSPPendingRequestToVendorBulk(payload);
          return;
        }
        // assign dsp pending request to vendor single
        loader(true);
        assignDSPPendingRequestToVendor();
        return;
      }
    }
    //when dsp accepts request
    defaultVendorAssignment();
    setAppointment([]);
  };
  const reassignPartner = () => {
    loader(true);
    CsrAPI.reassignPartner({
      ...(item?.bulk_id
        ? {
            bulk_requests: item?.bulkSchedules?.map((d: any) => ({
              preventive_request_id: +d?.id,
            })),
          }
        : { bulk_requests: [{ preventive_request_id: +item?.id }] }),
      ...(notes?.length > 0 && { note: notes }),
      new_partner_account_id: String(item?.partnerDetails?.new_partner_account_id || ''),
      partner_account_id: String(item?.partnerDetails?.previous_partner_account_id || ''),
      ...(item?.bulk_id && { bulk_id: String(item?.bulk_id) }),
    }).then((res) => {
      if (res.status === 200) {
        handleDone();
        handleClose();
        toast.success(`Partner Reassigned successfully`);
      } else {
        toast.error('someting went wrong');
      }
      loader(false);
    });
  };
  const reassignTech = () => {
    loader(true);
    CsrAPI.reassignTech({
      ...(item?.bulk_id
        ? {
            bulk_requests: item?.bulkSchedules?.map((d: any) => ({
              preventive_request_id: +d?.id,
            })),
          }
        : {
            bulk_requests: [{ preventive_request_id: +item?.id || +item?.preventive_request_id }],
          }),
      ...(notes?.length > 0 && { note: notes }),
      vendor_account_id: String(item?.vendorDetailsForTech?.account_id || ''),
      new_technician_account_id: String(item?.technicianDetails?.new_technician_account_id || ''),
      vendor_id: String(item?.vendorDetailsForTech?.id || ''),
      technician_account_id: String(item?.technicianDetails?.technician_account_id || ''),
      ...(item?.bulk_id && { bulk_id: String(item?.bulk_id) }),
    }).then((res) => {
      if (res.status === 200) {
        handleDone();
        handleClose();
        toast.success(`Technician Reassigned successfully`);
      } else {
        toast.error('someting went wrong');
      }
      loader(false);
    });
  };
  const assignTechnician = () => {
    loader(true);
    PreventiveAPI.assignTechniciansBulk({
      single_requests: [
        {
          preventive_request_id: String(item?.preventive_request_id || item?.id),
        },
      ],
      technician_account_id: item?.technicianDetails?.new_technician_account_id,
      ...(user.role === 'fleetmax_partner' && {
        vendor_account_id: item?.vendor?.account_id,
        vendor_id: String(item?.vendor?.id),
      }),
      ...(notes?.length > 0 && { note: notes }),
    }).then((res) => {
      if (res.status === 200) {
        if (user?.role === 'fleetmax_partner') {
          fetchRequestData?.();
        }
        handleDone();
        handleClose();
        item?.handleClose();
      } else {
        toast.error('Something Went Wrong');
      }
      loader(false);
    });
  };
  const assignTechBulk = () => {
    loader(true);
    let payload: any;
    payload = {
      ...(notes?.length > 0 && { note: notes }),
      technician_account_id: item?.technicianDetails?.new_technician_account_id,
      bulk_ids: item.bulkSchedules?.map((item: any) => parseInt(item.bulk_id)),
      ...(user.role === 'fleetmax_partner' && {
        vendor_account_id: item?.vendor?.account_id,
        vendor_id: item?.vendor?.id,
      }),
    };
    PreventiveAPI.assignTechniciansBulk(payload).then((res) => {
      if (res.status === 200) {
        handleDone();
        handleClose();
      } else {
        toast.error('Something Went Wrong');
      }
      loader(false);
    });
  };
  const submitText = isPartnerReassign ? 'Partner' : isTechReassign ? 'Technician' : 'Vendor';
  return (
    <Modal widthclass='w-[45rem]' heightClass='' handleClose={() => handleBack()} noLabel>
      <div className='flex flex-col justify-between gap-5'>
        <div className='flex flex-col gap-5'>
          <div className='text-lg'>Submit {submitText}</div>
          <div className='flex divide-x-2 gap-3 divide-slate-100 h-[27rem]'>
            {isPartnerReassign && (
              <>
                <div className='flex flex-col gap-3'>
                  <p className='text-slate-600'>Assigned Partner</p>
                  <section
                    className={`relative flex flex-col justify-center items-center gap-1 w-[10rem] h-[7rem] p-2 rounded-lg cursor-pointer border-transparent border-2 hover:border-slate-50 shadow-sm `}>
                    <Avatar>{item.partnerDetails?.name?.slice(0, 2).toUpperCase()}</Avatar>
                    <Typography className='w-full text-center' noWrap>
                      {item.partnerDetails?.name}
                    </Typography>
                  </section>
                </div>
              </>
            )}
            {isTechReassign && (
              <>
                <div className='flex flex-col gap-3'>
                  <p className='text-slate-600'>Assigned Technician</p>
                  <section
                    className={`relative flex flex-col justify-center items-center gap-1 w-[10rem] h-[7rem] p-2 rounded-lg cursor-pointer border-transparent border-2 hover:border-slate-50 shadow-sm `}>
                    <Avatar>{item.technicianDetails?.name?.slice(0, 2).toUpperCase()}</Avatar>
                    <Typography className='w-full text-center' noWrap>
                      {item.technicianDetails?.name}
                    </Typography>
                  </section>
                </div>
              </>
            )}
            {!isPartnerReassign && !isTechReassign && (
              <>
                <div className='flex flex-col gap-3'>
                  <p className='text-slate-600'>Assigned Vendor</p>
                  <section
                    className={`relative flex flex-col justify-center items-center gap-1 w-[10rem] h-[7rem] p-2 rounded-lg cursor-pointer border-transparent border-2 hover:border-slate-50 shadow-sm `}>
                    <Avatar>{item.vendor_name.slice(0, 2).toUpperCase()}</Avatar>
                    <Typography className='w-full text-center' noWrap>
                      {item.vendor_name}
                    </Typography>
                  </section>
                </div>
              </>
            )}

            <div className='pl-3 w-full'>
              <textarea
                value={notes}
                onChange={(e: { target: { value: string } }) => setNotes(e.target.value)}
                className='w-full h-1/2 rounded-lg p-2 border-2 border-slate-100 resize-none'
                placeholder='Add Notes'></textarea>
            </div>
          </div>
        </div>

        <div className='flex justify-between'>
          <button
            className='border-transparent border-2 px-3 py-2 text-blue-400 bg-slate-50 rounded-lg hover:border-slate-100'
            onClick={handleBack}>
            Back
          </button>
          <Button
            label='Submit'
            className='border-transparent border-2 px-3 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-500 transition duration-200 w-[12rem]'
            onClick={() => {
              if (isTechReassign) {
                reassignTech();
                return;
              }
              if (!item?.technician?.email && isTechReassign) {
                if (item?.bulk_id) {
                  assignTechBulk();
                } else {
                  assignTechnician();
                }
              } else {
                if (isPartnerReassign) {
                  reassignPartner();
                } else if (isTechReassign) {
                  reassignTech();
                } else {
                  handleSubmit();
                }
              }
            }}></Button>
        </div>
      </div>
    </Modal>
  );
};

export default SubmitAssignModal;
