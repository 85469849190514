const ApprpvedFrom = (props: {
  text?: JSX.Element;
  svg?: JSX.Element;
  checked?: boolean;
  image?: JSX.Element;
  onClick?: () => void;
}) => {
  return (
    <div
      className=' flex direction row  items-row justify-center gap-2 flex-1 h-[3.2rem] flex-shrink-0 rounded-lg  border-gray-300 bg-white shadow-md hover:shadow-leasPlanshadow border-[0.2rem] hover:border-[#E8EEF8] cursor-pointer'
      onClick={props.onClick}>
      <label className='display flex items-start justify-between  relative left-0'>
        <input
          type='radio'
          className='form-radio text-indigo-600 h-[1.2rem] w-[1.2rem] '
          checked={props.checked}
          style={{ width: '1rem', accentColor: '#4378C8' }}
        />
      </label>
      {props.image && (
        <div className='ml-1 flex gap-1 items-center'>
          {props.image} {props.text}
        </div>
      )}
      {props.svg && <div className='ml-1'>{props.svg}</div>}
    </div>
  );
};

export const ViewApprpvedFrom = (props: {
  text?: JSX.Element;
  svg?: JSX.Element;
  checked?: boolean;
  image?: JSX.Element;
}) => {
  return (
    <div className=' flex direction row  items-row justify-center gap-2 w-1/2 h-[3.2rem] flex-shrink-0 rounded-lg border-2 border-gray-300 bg-white shadow-md '>
      {props.image && (
        <div className='ml-1 flex gap-1 items-center'>
          {props.image} {props.text}
        </div>
      )}
      {props.svg && <div className='ml-1'>{props.svg}</div>}
    </div>
  );
};

export default ApprpvedFrom;
