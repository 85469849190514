import { ButtonBase, Tooltip, useTheme } from '@mui/material';
import Badge from '@mui/material/Badge';
import Popover from '@mui/material/Popover';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import io from 'socket.io-client';
import NotificationApi from '../../api/notification';
import NotificationSVG from '../../assets/Notification';
import HandleLoad from '../../components/Loader/HandleLoad';
import { toast } from '../../components/Toast/CustomToast';
import { Environment } from '../../constants/environment';
import { actionCreators } from '../../state';
import { RootState } from '../../state/reducers';
import { Model } from '../csr/primaryComponents/TrackLocation';
import { AlertInspData, getInfoCSR } from './GetCsrNotification';
import { getInfoVENDOR } from './GetVendorNotification';
import { getInfoPARTNER } from './GetPartnerNotification';
const socket = io(Environment.baseUrl, {
  path: '/socket-fleetmax',
  forceNew: true,
  transports: ['websocket'],
  reconnection: true,
});
const TypeNotification = ({
  notficationArr,
  handleDelete,
  handleSeen,
}: {
  notficationArr: any[];
  type?: string;
  handleDelete: (id: string) => void;
  handleSeen: (id: string) => void;
}) => {
  const theme: any = useTheme();
  return (
    <div className='flex h-auto flex-col pr-2'>
      <div className='flex items-center gap-4 flex-col w-full'>
        {notficationArr.map((el: any) => (
          <ButtonBase
            key={el?.id}
            className='flex  border-[0.063rem] items-center rounded-lg w-full cursor-pointer min-h-[4rem]'
            onClick={el?.onClick}
            sx={{
              padding: '0 !important',
              background: !el?.is_read && theme.palette.secondary.main,
            }}>
            <div
              className={`flex border-[#DFDFDF] border-[0.13rem] items-center rounded-lg w-full h-full overflow-hidden flex flex-col ${
                !el?.is_read && 'border-notification-border'
              } hover:border-notification-border group text-[#515151]`}>
              <div className='py-1 px-2 flex items-center rounded-lg w-full h-full text-[1rem]'>
                <div className='flex gap-0 flex-1 flex-col items-start'>{el?.notificationMsg}</div>
                <div className='flex flex-col h-full gap-1 w-[4rem]'>
                  <p className='text-[#BFBFBF] self-end text-sm'>
                    {moment(el?.created_at).format('LT')}
                  </p>
                  <div className='flex justify-between w-full h-[2rem]'>
                    {!el?.is_read ? (
                      <Tooltip title={'Seen'}>
                        <i
                          className='pi pi-eye w-1/2 text-[#BFBFBF] hidden group-hover:block  rounded-full w-1/2 cursor-pointer w-fit mx-auto text-xl'
                          onClick={(e: any) => {
                            handleSeen(el);
                            e.stopPropagation();
                          }}></i>
                      </Tooltip>
                    ) : (
                      <>
                        <div className='w-1/2'></div>
                      </>
                    )}

                    <Tooltip title={'Delete'}>
                      <i
                        className='pi pi-times-circle text-[#BFBFBF] hidden group-hover:block w-1/2 rounded-full cursor-pointer w-fit mx-auto text-xl flex justify-end'
                        onClick={(e: any) => {
                          handleDelete(el?.info_alert_id);
                          e.stopPropagation();
                        }}></i>
                    </Tooltip>
                  </div>
                </div>
              </div>
              {el?.btnName && (
                <p className='w-full flex p-2 border-t-[0.1rem] border-notification-border cursor-pointer text-primary '>
                  <p>{el?.btnName}</p>
                </p>
              )}
            </div>
          </ButtonBase>
        ))}
      </div>
    </div>
  );
};
const setupConnection = (socket: any) => {
  let user: any | null = null;
  const getItem: string | null = localStorage.getItem('user');
  if (getItem) user = JSON.parse(getItem);
  const account_id = user?.account_id;
  const token = user?.token;
  socket.auth = { account_id, token };
};

type countType = {
  [key: string]: {
    value: number;
    type: string;
    show?: boolean;
  };
};

const CountBox = ({
  type,
  selection,
  setSelection,
  counts,
}: {
  type: string;
  counts: countType;
  selection: string;
  setSelection: (d: string) => void;
}) => {
  if (!counts[type].show) return null;
  const count = counts[type].value;
  const isSelected = counts[type].type === selection;
  return (
    <button
      // variant='outlined'
      className={`${
        isSelected
          ? 'border-primary text-primary bg-primary-light'
          : 'border-gray-400 text-gray-500'
      } px-3 hover:bg-primary-light border-[0.05rem] h-[2.3rem] rounded-full flex gap-1 items-center text-[0.9rem] whitespace-nowrap`}
      onClick={() => {
        setSelection(counts[type].type);
      }}>
      {counts[type].type}
      {!!count && (
        <p className='bg-[#C55] rounded-full text-white font-bold w-[1.5rem] h-[1.2rem] text-[0.7rem] flex items-center justify-center'>
          {count > 99 ? '99+' : count}
        </p>
      )}
    </button>
  );
};

const Notification = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [notficationObj, setNotificationObj] = React.useState({} as any);
  const [loader1, setLoader] = React.useState({} as any);
  const [showLocation, setShowLocation] = React.useState({ open: false, data: {} } as any);
  const [selection, setSelection] = React.useState('PM Request');
  const dispatch = useDispatch();
  const { redirectRequest } = bindActionCreators(actionCreators, dispatch);
  const { user }: any = useSelector((state: RootState) => state.userState);
  const [showUndoDiv, setShowUndoDiv] = React.useState<any>({ show: false, data: {} });
  const { socketFleetMax: socket }: any = useSelector((state: RootState) => state.userState);
  const [offset, setOffset] = useState(0);
  const [counts, setCounts] = React.useState({
    prevent_count: {
      value: 0,
      type: 'PM Request',
      show: true,
    },
    repair_count: {
      value: 0,
      type: 'Repair Request',
      show: true,
    },
    inspection_count: {
      value: 0,
      type: 'Inspection',
      show: true,
    },
  } as countType);
  const loader = HandleLoad();
  const selectionRef = useRef(selection);
  useEffect(() => {
    selectionRef.current = selection;
  }, [selection]);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (user && user?.role !== 'fleetmax_supervisor' && socket && Object.keys(socket).length > 0) {
      setupConnection(socket);
      socket.connect();
      socket.on('connect', () => {
        console.log('joinRoomForNotification');
        socket.emit('joinRoomForNotification');
      });
      socket?.on('newNotification', (message: string) => {
        console.log('newNotification');
        refetch(offset);
        getCounts();
      });
      socket.on('authentication_error', (error: any) => {
        console.error('Authentication error:', error);
      });
    }
  }, [socket]);
  const changeStatus = (type: string) => {
    const isVendor = user?.role === 'fleetmax_vendor' ? '/vendor/vehicle' : '/csr/dashboard';
    navigate({
      pathname: `${user?.role === 'fleetmax_partner' ? '/partner/vehicle' : isVendor}`,
      search: `?${createSearchParams(
        new URLSearchParams({
          type: `${type}`,
        })
      )}`,
    });
  };
  const RedirectToRequestVendor = (item: any) => {
    if (['raised', 'declined'].includes(item?.vendor_invoice_dispute)) {
      changeStatus('dispute');
    } else if (!['raised', 'declined'].includes(item?.vendor_invoice_dispute)) {
      if (item.status_id === '4') {
        if (+item?.sub_stage === 3) {
          changeStatus('rejected');
        } else if (+item?.sub_stage === 2) {
          changeStatus('accepted');
        } else {
          changeStatus('requests');
        }
      }
      if (['5', '6', '7', '8'].includes(item.status_id)) {
        changeStatus('assigned');
      }
      if (['9', '11'].includes(item.status_id) || (item?.status_id === '12' && !item?.sub_stage)) {
        changeStatus('approved');
      }
      if (['10'].includes(item.status_id)) {
        changeStatus('rejected');
      }
      if (item?.status_id === '12' && item?.sub_stage === '1') {
        changeStatus('paymentConfirmed');
      }
    }
    redirectRequest({
      ...item,
      preventive_request_id: item.pm_request_id,
    });
    setTimeout(() => {
      redirectRequest(null);
    }, 2000);
  };
  const RedirectToRequestPartner = (item: any) => {
    if (['raised', 'declined'].includes(item?.vendor_invoice_dispute)) {
      changeStatus('dispute');
    } else if (!['raised', 'declined'].includes(item?.vendor_invoice_dispute)) {
      if (item?.status_id === '-1') {
        if (item?.sub_stage === '1') {
          changeStatus('requests');
        }
        if (item?.sub_stage === '2') {
          changeStatus('accepted');
        }
        if (item?.sub_stage === '3') {
          changeStatus('rejected');
        }
      }
      if (item.status_id === '4') {
        if (+item?.sub_stage === 3) {
          changeStatus('rejected');
        } else {
          changeStatus('assigned');
        }
      }
      if (['5', '6', '7', '8'].includes(item.status_id)) {
        changeStatus('assigned');
      }
      if (['9', '11'].includes(item.status_id) || (item?.status_id === '12' && !item?.sub_stage)) {
        changeStatus('approved');
      }
      if (['10'].includes(item.status_id)) {
        changeStatus('rejected');
      }
      if (item?.status_id === '12' && item?.sub_stage === '1') {
        changeStatus('paymentConfirmed');
      }
    }
    redirectRequest({
      ...item,
      preventive_request_id: item.pm_request_id,
    });
    setTimeout(() => {
      redirectRequest(null);
    }, 2000);
  };
  const RedirectToRequestCSR = (item: any) => {
    const status_id = Number(item.status_id);
    if (['raised', 'declined'].includes(item?.vendor_invoice_dispute)) {
      changeStatus('dispute');
    } else if (!['raised', 'declined'].includes(item?.vendor_invoice_dispute)) {
      if (status_id >= -1 && status_id <= 3) {
        changeStatus('schedule');
      }
      if (item.status_id === '4') {
        if (+item?.sub_stage === 3) {
          changeStatus('rejected');
        } else if (+item?.sub_stage === 2) {
          changeStatus('in-process');
        } else {
          changeStatus('schedule');
        }
      }
      if (['10'].includes(item.status_id)) {
        changeStatus('rejected');
      }

      if (['5', '6', '7', '8', '9', '11'].includes(item.status_id)) {
        changeStatus('in-process');
      }
      if (status_id === 12) {
        changeStatus('complete');
      }
    }

    redirectRequest({
      ...item,
      preventive_request_id: item.pm_request_id,
    });
    setTimeout(() => {
      redirectRequest(null);
    }, 2000);
  };

  const RedirectToRequest = useCallback(
    (notification: any) => {
      !notification.is_read && ReadNotification(notification?.info_alert_id);
      handleClose();
      if (user.role === 'fleetmax_vendor') {
        return RedirectToRequestVendor(notification);
      } else if (user.role === 'fleetmax_partner') {
        return RedirectToRequestPartner(notification);
      } else {
        return RedirectToRequestCSR(notification);
      }
    },
    [user]
  );
  const getUserName = () => {
    if (user.role === 'fleetmax_vendor') {
      return 'vendor';
    }
    if (user.role === 'fleetmax_csr') {
      return 'csr';
    } else return 'partner';
  };
  const notficationActions: {
    RedirectToRequest: (e: any, notification: any) => void;
    default: (e: any, notification: any) => void;
    handleBulkRedirect: (e: any, notification: any) => void;
    readNotification: (e: any, notification: any) => void;
    showLocationModel: (e: any, notification: any) => void;
    redirectToInspection: (e: any, notification: any) => void;
  } = {
    RedirectToRequest: (e: any, notification: any) => {
      RedirectToRequest(notification);
      e.stopPropagation();
    },
    handleBulkRedirect: (e: any, notification: any) => {
      let latestOne = {};
      if (notification?.pm_request_id) {
        latestOne = notification?.bulk_details.find(
          (el: any) => el?.pm_request_id == notification?.pm_request_id
        );
      }
      if (Object.keys(latestOne)?.length === 0) {
        latestOne = notification?.bulk_details?.sort(
          (a: any, b: any) => a.status_id > b.status_id
        )[0];
      }

      RedirectToRequest({
        ...notification,
        ...latestOne,
        bulk_modal: true,
      });
      e.stopPropagation();
    },
    showLocationModel: (e: any, notification: any) => {
      !notification.is_read && ReadNotification(notification.info_alert_id);
      setShowLocation({ open: true, data: notification });
      e.stopPropagation();
    },
    readNotification: (e: any, notification: any) => {
      !notification.is_read && ReadNotification(notification.info_alert_id);
    },
    redirectToInspection: (e: any, notification: AlertInspData) => {
      !notification.is_read && ReadNotification(notification.info_alert_id);
      navigate(
        `/${getUserName()}/inspect/${notification.company_id}/${
          notification.vehicle_id
        }?inspectionID=${notification.health_checkup_id}`
      );
      handleClose();
      e.stopPropagation();
    },
    default: (e: any, notification: any) => {
      !notification.is_read && ReadNotification(notification.info_alert_id);
      e.stopPropagation();
    },
  };

  const getCounts = (id?: string) => {
    NotificationApi.getCounts()
      .then((response: any) => {
        if (response.status >= 200 && response.status <= 210) {
          const countData: {
            prevent_count: number;
            repair_count: number;
            inspection_count: number;
          } = response?.data?.data;
          setCounts({
            prevent_count: {
              ...counts.prevent_count,
              value: countData.prevent_count,
              type: 'PM Request',
            },
            repair_count: {
              ...counts.repair_count,
              value: countData.repair_count,
              type: 'Repair Request',
            },
            inspection_count: {
              ...counts.inspection_count,
              value: countData.inspection_count,
              type: 'Inspection',
            },
          });
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const undoNotificationPayload = useMemo(
    () => ({
      id: showUndoDiv?.data?.info_alert_id,
      payload: {
        read: false,
      },
    }),
    [showUndoDiv?.data]
  );

  const ReadNotification = (id?: string) => {
    const finalData: any = id ? { id: id } : { ...undoNotificationPayload };
    const isRepair = selectionRef.current === 'Repair Request' ? 'repair' : 'pm_request';
    NotificationApi.readNotfication({
      ...finalData,
      payload: {
        ...(finalData?.payload || {}),
        request_type: selectionRef.current === 'Inspection' ? 'inspection' : isRepair,
      },
    })
      .then((response: any) => {
        if (response.status >= 200 && response.status <= 210) {
          refetch(offset);
          getCounts();
        } else {
          toast.error(response?.data?.message || 'Something went wrong');
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const updateData = (notificationArr: any[]) => {
    let tempNotificationObj: any = {};
    if (notificationArr && notificationArr.length > 0) {
      notificationArr
        ?.sort(
          (a: any, b: any) => moment(b?.created_at).valueOf() - moment(a?.created_at).valueOf()
        )
        .forEach((notification: any) => {
          const date: string = moment(notification.created_at).format('MM-DD-YYYY');
          const notificationData = getInfo(notification);
          if (notificationData) {
            if (tempNotificationObj[date]) {
              const prevData = tempNotificationObj[date];
              tempNotificationObj[date] = [...prevData, notificationData];
            } else {
              tempNotificationObj[date] = [notificationData];
            }
          }
        });
      setNotificationObj(tempNotificationObj);
    } else {
      setNotificationObj(null);
    }
  };

  const refetch = async (offset = 0) => {
    setLoader(true);
    const response: any =
      selectionRef.current !== 'Inspection'
        ? await NotificationApi.getNotification({
            query: `${
              selectionRef.current === 'Repair Request'
                ? `?request_type=repair`
                : '?request_type=pm_request'
            }&offset=0&limit=${10 + offset}`,
          })
        : await NotificationApi.getInspNotification({ query: `?offset=0&limit=${10 + offset}` });
    if (response.status >= 200 && response.status <= 210) {
      updateData(response.data.data);
    }
    setLoader(false);
  };

  const DeleteNotification = (id: string) => {
    loader(true);
    NotificationApi.deleteNotification({
      id: id,
      payload: {
        request_type: selectionRef.current === 'Inspection' ? 'inspection' : 'maintenance',
      },
    })
      .then((response: any) => {
        if (response.status >= 200 && response.status <= 210) {
          refetch(offset);
          getCounts();
        } else {
          toast.error(response?.data?.message || 'Something went wrong');
        }
        loader(false);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const SeenNotification = (obj: any) => {
    setShowUndoDiv({ show: true, data: obj });
    setTimeout(() => {
      setShowUndoDiv({ show: false, data: {} });
    }, 4000);
    ReadNotification(obj?.info_alert_id);
  };
  const getInfo = useCallback(
    (notification: any) => {
      if (user.role === 'fleetmax_csr') {
        return getInfoCSR(notification, notficationActions);
      } else if (user.role === 'fleetmax_vendor') {
        return getInfoVENDOR(notification, notficationActions);
      } else {
        return getInfoPARTNER(notification, notficationActions);
      }
    },
    [user]
  );

  const unreadNotifications =
    counts.prevent_count.value + counts.repair_count.value + counts.inspection_count.value;

  useEffect(() => {
    getCounts();
  }, []);

  useEffect(() => {
    setOffset(0);
    setNotificationObj({});
    refetch();
  }, [selection]);

  const handleScroll = (e: any) => {
    const div = e.target;
    const atBottom = div.scrollHeight - div.scrollTop <= div.clientHeight + 1;
    if (atBottom) {
      refetch(offset + 10);
      setOffset((prev) => {
        return prev + 10;
      });
    }
  };

  return (
    <>
      <Tooltip title={`Notification`}>
        <div
          className='bg-primary-lighter p-[0.8rem] rounded-xl flex items-center cursor-pointer gap-2 relative h-[inherit]'
          onClick={handleClick}>
          <Badge
            badgeContent={unreadNotifications}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              color: 'red',
              '& .MuiBadge-badge': {
                backgroundColor: '#CC5555',
                color: 'white',
                boxShadow: '0px 1.26667px 2.53333px rgba(0, 0, 0, 0.25)',
                width: '1.4rem',
                height: '1.4rem',
                padding: '0 0.3rem',
              },
            }}
            max={99}>
            <NotificationSVG />
          </Badge>
          <i
            className={`pi pi-angle-down ml-2 text-primary ${
              anchorEl ? 'rotate-180' : 'rotate-0'
            } ease-in-out duration-200 `}></i>
        </div>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{
          zIndex: 29,
        }}
        PaperProps={{
          style: {
            boxShadow: '0px 20px 36px -13px rgba(0, 0, 0, 0.25)',
            marginTop: '0.5rem',
            paddingTop: '1rem',
            marginLeft: '0.4rem',
          },
        }}>
        <>
          <div className='w-[27rem] flex gap-2 justify-around'>
            {Object.keys(counts).map((type: string) => (
              <CountBox
                setSelection={setSelection}
                type={type}
                selection={selection}
                counts={counts}
              />
            ))}
          </div>
          <div className='w-[27rem] h-[30rem] p-4 pr-2'>
            {unreadNotifications > 0 && (
              <div className='flex justify-end mb-[-1.5rem]'>
                {Object.keys(notficationObj || {}).length > 0 && (
                  <button
                    className='p-1 mr-2 border-[0.1rem] border-gray-300 text-primary rounded-[0.5rem] px-3 font-bold z-20'
                    onClick={() => {
                      ReadNotification('all');
                    }}>
                    Mark All as Read
                  </button>
                )}
              </div>
            )}

            <div
              className='w-full h-full overflow-auto  flex flex-col gap-5'
              onScroll={handleScroll}>
              {Object.keys(notficationObj || {}).map((key) => (
                <>
                  <p className='text-primary font-bold sticky top-0 bg-white z-10'>
                    {moment(key, 'MM-DD-YYYY').calendar(null, {
                      sameDay: '[Today], DD MMM',
                      lastDay: '[Yesterday], DD MMM',
                      lastWeek: 'LL',
                      sameElse: 'LL',
                    })}
                  </p>
                  <TypeNotification
                    notficationArr={notficationObj[key]}
                    handleDelete={DeleteNotification}
                    handleSeen={SeenNotification}
                  />
                </>
              ))}
              {!loader1 && Object.keys(notficationObj || {}).length === 0 && (
                <p className='m-auto'>No data found</p>
              )}
              {loader1 && (
                <div className='m-auto'>
                  <ThreeDots
                    height='60'
                    width='60'
                    radius='9'
                    color={'#4378C8'}
                    ariaLabel='three-dots-loading'
                    wrapperStyle={{}}
                    visible={true}
                  />
                </div>
              )}
              {showUndoDiv?.show && (
                <div
                  className='w-full absolute flex justify-between items-center bottom-8 transform translate-y-1/2 left-1/2 -translate-x-1/2 text-white px-4 rounded-xl z-20'
                  style={{ transition: 'opacity 1s ease' }}>
                  <div className='w-[25rem] absolute flex justify-between items-center bottom-8 transform translate-y-1/2 left-1/2 -translate-x-1/2 bg-[#515151] text-white p-4 rounded-xl z-20'>
                    <p className='italic'>Notification Marked as Read</p>
                    <p
                      className='font-bold cursor-pointer'
                      onClick={() => {
                        ReadNotification();
                        setShowUndoDiv({ show: false, data: {} });
                      }}>
                      Undo
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      </Popover>
      {showLocation?.open && (
        <Model
          item={showLocation?.data}
          handleShowLocation={() => {
            setShowLocation(!showLocation);
          }}
        />
      )}
    </>
  );
};

export default Notification;
