import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../state/reducers';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { actionCreators } from '../../../state';
import { bindActionCreators } from 'redux';
import useFetchVendorSingleRequest from '../../hooks/vendor/useFetchVendorSingleRequest';
import { CheckisSameID } from '../../hooks/csr/useFetchSingleRequest';
import { useState } from 'react';
import OpenModel from '../../common/openModel/OpenVendorModel';

function RequestPart({ item, isRequestName, handleClose }: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { user }: any = useSelector((state: RootState) => state.userState);
  const { redirectRequest } = bindActionCreators(actionCreators, dispatch);
  const { fetchRequestData: fetchRequestDetails }: any = useFetchVendorSingleRequest();
  const [showModel, setShowModel] = useState<any>({ open: false, data: {} });
  const [urlParams]: any = useSearchParams();
  const changeStatus = (type: string) => {
    const currentType = urlParams?.get('type');
    const ifVendor = user?.role === 'fleetmax_vendor' ? '/vendor/vehicle' : location.pathname;
    if (currentType !== type) {
      navigate({
        pathname: `${user?.role === 'fleetmax_partner' ? '/partner/vehicle' : ifVendor}`,
        search: `?${createSearchParams(
          new URLSearchParams({
            type: `${type}`,
          })
        )}`,
      });
    }
    handleClose?.();
  };
  const RedirectToRequestVendor = (item: any) => {
    if (['raised', 'declined'].includes(item?.vendor_invoice_dispute)) {
      changeStatus('dispute');
    } else if (!['raised', 'declined'].includes(item?.vendor_invoice_dispute)) {
      if (item.status_id === '4') {
        if (+item?.sub_stage === 3) {
          changeStatus('rejected');
        } else if (+item?.sub_stage === 2) {
          changeStatus('accepted');
        } else {
          changeStatus('requests');
        }
      }
      if (['5', '6', '7', '8'].includes(item.status_id)) {
        changeStatus('assigned');
      }
      if (['9', '11'].includes(item.status_id) || (item?.status_id === '12' && !item?.sub_stage)) {
        changeStatus('approved');
      }
      if (['10'].includes(item.status_id)) {
        changeStatus('rejected');
      }
      if (item?.status_id === '12' && item?.sub_stage === '1') {
        changeStatus('paymentConfirmed');
      }
    }
    redirectRequest({
      ...item,
      preventive_request_id: item.preventive_request_id || item?.id || '',
      ...(item?.bulk_id && { bulk_modal: true }),
    });
    setTimeout(() => {
      redirectRequest(null);
    }, 2000);
  };
  const RedirectToRequestPartner = (item: any) => {
    if (['raised', 'declined'].includes(item?.vendor_invoice_dispute)) {
      changeStatus('dispute');
    } else if (!['raised', 'declined'].includes(item?.vendor_invoice_dispute)) {
      if (item?.status_id === '-1') {
        if (item?.sub_stage === '1') {
          changeStatus('requests');
        }
        if (item?.sub_stage === '2') {
          changeStatus('accepted');
        }
        if (item?.sub_stage === '3') {
          changeStatus('rejected');
        }
      }
      if (item.status_id === '4') {
        if (+item?.sub_stage === 3) {
          changeStatus('rejected');
        } else {
          changeStatus('assigned');
        }
      }
      if (['5', '6', '7', '8'].includes(item.status_id)) {
        changeStatus('assigned');
      }
      if (['9', '11'].includes(item.status_id) || (item?.status_id === '12' && !item?.sub_stage)) {
        changeStatus('approved');
      }
      if (['10'].includes(item.status_id)) {
        changeStatus('rejected');
      }
      if (item?.status_id === '12' && item?.sub_stage === '1') {
        changeStatus('paymentConfirmed');
      }
    }
    redirectRequest({
      ...item,
      preventive_request_id: item.preventive_request_id || item?.id || '',
      ...(item?.bulk_id && { bulk_modal: true }),
    });
    setTimeout(() => {
      redirectRequest(null);
    }, 2000);
  };
  const RedirectToRequestCSR = (item: any) => {
    const status_id = Number(item.status_id);
    if (['raised', 'declined'].includes(item?.vendor_invoice_dispute)) {
      changeStatus('dispute');
    } else if (!['raised', 'declined'].includes(item?.vendor_invoice_dispute)) {
      if (status_id >= -1 && status_id <= 3) {
        changeStatus('schedule');
      }
      if (item.status_id === '4') {
        if (+item?.sub_stage === 3) {
          changeStatus('rejected');
        } else if (+item?.sub_stage === 2) {
          changeStatus('in-process');
        } else {
          changeStatus('schedule');
        }
      }
      if (['10'].includes(item.status_id)) {
        changeStatus('rejected');
      }

      if (['5', '6', '7', '8', '9', '11'].includes(item.status_id)) {
        changeStatus('in-process');
      }
      if (status_id === 12) {
        changeStatus('complete');
      }
    }

    redirectRequest({
      ...item,
      preventive_request_id: item.preventive_request_id || item?.id || '',
      ...(item?.bulk_id && { bulk_modal: true }),
    });
    setTimeout(() => {
      redirectRequest(null);
    }, 2000);
  };
  const redirect = (item: any) => {
    if (user.role === 'fleetmax_vendor') {
      return RedirectToRequestVendor(item);
    } else if (user.role === 'fleetmax_partner') {
      return RedirectToRequestPartner(item);
    } else {
      return RedirectToRequestCSR(item);
    }
  };
  const fetchDetails = () => {
    const newPayload = {
      id: isRequestName ? item?.preventive_request_id : item?.parent_request_id,
      preventive_request_id: isRequestName ? item?.preventive_request_id : item?.parent_request_id,
      ...(user?.role !== 'fleetmax_csr' && { all_users: true }),
    };
    fetchRequestDetails(newPayload).then((res: any) => {
      if (res) {
        const item = {
          ...res,
          id: res?.preventive_request_id || res?.id,
          preventive_request_id: res?.preventive_request_id || res?.id,
          title: `Request-${res?.preventive_request_id || res?.id}`,
        };
        if (user.role === 'fleetmax_csr') {
          // if csr then no need to check if the IDs are diffrent
          redirect(item);
        } else {
          // if not csr then check if the IDs are diffrent
          if (!CheckisSameID(user, item)) {
            setShowModel({ show: true, data: { ...item, all_users: true } });
          } else {
            redirect(item);
          }
        }
      }
    });
  };
  if (isRequestName) {
    if (!item?.preventive_request_id) {
      return null;
    } else
      return (
        <>
          <span
            className='text-primary cursor-pointer font-bold hover:underline'
            onClick={() => {
              fetchDetails();
            }}>
            New Request {item?.preventive_request_id || ''}
          </span>
          {showModel?.show && (
            <OpenModel
              item={showModel.data}
              handleClose={() => setShowModel({ show: false, data: {} })}
              refetch={() => {}}
            />
          )}
        </>
      );
  } else if (!isRequestName) {
    if (!item?.parent_request_id) return null;
    else
      return (
        <>
          <div className=' bg-primary-light border-primary border-[0.1rem] px-3 p-1 rounded-full text-[0.8rem]'>
            Main{' '}
            <span
              className='hover:underline text-primary cursor-pointer'
              onClick={() => {
                fetchDetails();
              }}>
              Request-{item?.parent_request_id || item?.preventive_request_id}
            </span>
          </div>
          {showModel?.show && (
            <OpenModel
              item={showModel.data}
              handleClose={() => setShowModel({ show: false, data: {} })}
              refetch={() => {}}
            />
          )}
        </>
      );
  } else return null;
}

export default RequestPart;
