export default function (props: {
  height?: string;
  width?: string;
  fill?: string;
  active?: boolean;
}) {
  const fill = props?.fill || '#4378C8';
  return (
    <svg width='15' height='18' viewBox='0 0 15 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.93687 5.10744L1.61332 3.58088C1.40318 3.44279 1.12084 3.5014 0.982811 3.71186C0.845055 3.92241 0.903417 4.20498 1.11374 4.34307L3.39541 5.8422C3.54491 5.55763 3.72819 5.31107 3.93687 5.10744Z'
        fill={fill}
      />
      <path
        d='M8.75674 2.92694C9.01326 1.82415 8.32814 0.721948 7.22646 0.465114C6.12479 0.20828 5.02375 0.894068 4.76722 1.99686C4.51069 3.09966 5.19582 4.20186 6.29749 4.45869C7.39917 4.71553 8.50021 4.02974 8.75674 2.92694Z'
        fill={fill}
      />
      <path
        d='M4.55737 6.78168C4.33293 6.33186 4.51567 5.78333 4.96445 5.55892C5.09237 5.49484 5.22903 5.46221 5.37134 5.46221C5.71878 5.46221 6.03089 5.65554 6.18613 5.96663L6.67297 6.94119C7.0073 6.09096 6.80754 5.03135 6.08825 4.74268C5.34475 4.44417 4.17087 4.70859 3.53453 5.9679C2.66975 7.70475 2.37439 8.8721 2.33105 10.9102C2.35901 11.4195 2.49094 11.7883 2.68551 12.0575L5.46958 8.60776L4.55737 6.78168ZM5.80045 9.64805L3.4138 12.6055C3.67365 12.7086 3.95208 12.7604 4.21575 12.7934C4.43391 12.8208 4.62074 12.838 4.7798 12.8257C4.86083 12.8232 4.94268 12.8126 5.02454 12.792L8.301 11.9635L10.1354 15.9363C10.3276 16.3527 10.7394 16.598 11.1694 16.598C11.3293 16.598 11.4914 16.5642 11.6459 16.4926C12.2166 16.2286 12.4654 15.5516 12.2016 14.9805L9.97092 10.1495C9.74148 9.65306 9.18881 9.38882 8.65928 9.52263L5.66106 10.2806C5.70094 10.0691 5.74828 9.85769 5.80045 9.64805ZM9.61446 6.0071L9.96491 5.21483C10.0794 4.95588 9.96263 4.65308 9.70387 4.53841C9.4453 4.42375 9.14284 4.54078 9.0283 4.79974L8.56186 5.85451C8.6906 5.78697 8.83455 5.75106 8.9835 5.75106C9.22168 5.75115 9.44429 5.8423 9.61446 6.0071ZM8.39833 8.75707L8.59909 8.30305L7.82646 9.05157C8.05772 9.09951 8.29909 8.98193 8.39833 8.75707ZM9.40596 7.52154L13.8944 9.51069L14.217 8.78149L9.87331 6.85633C9.8358 7.03098 9.74776 7.19058 9.61656 7.31764L9.40596 7.52154ZM13.0957 15.3361L11.8481 17.3502C11.7245 17.5499 11.7858 17.8121 11.9852 17.936C12.1847 18.0597 12.4466 17.9983 12.5703 17.7986L13.8179 15.7845C13.9414 15.5849 13.8802 15.3227 13.6806 15.1988C13.4813 15.075 13.2193 15.1365 13.0957 15.3361Z'
        fill={fill}
      />
      <path
        d='M1.8058 14.5242H7.2534C7.6053 14.5242 7.89083 14.2386 7.89083 13.8863C7.89083 13.5338 7.6053 13.2483 7.2534 13.2483L2.88891 13.2481L3.21923 12.8467C3.2196 12.8464 3.21978 12.8458 3.22005 12.8454C3.37893 12.6512 3.35098 12.365 3.15741 12.2052C2.96348 12.0453 2.67677 12.073 2.5167 12.2668L2.51652 12.267L2.25712 12.5822L1.26779 5.89323C1.21626 5.54468 0.892036 5.30386 0.543959 5.35564C0.19579 5.40723 -0.0445777 5.73163 0.00695565 6.08009L1.17529 13.9797C1.22136 14.2921 1.49022 14.5242 1.8058 14.5242ZM6.02334 6.04801C5.84352 5.6876 5.40594 5.54158 5.04621 5.72187C4.68648 5.90198 4.54062 6.33977 4.72053 6.70009L5.9952 9.25235C6.09845 9.45935 6.29621 9.60601 6.5241 9.64493C6.56453 9.65186 6.60523 9.65523 6.64574 9.65523C6.83321 9.65523 7.0164 9.58258 7.15307 9.45024L9.49009 7.18664C9.77926 6.90672 9.78673 6.44496 9.50721 6.15547C9.22733 5.86607 8.76635 5.85851 8.47718 6.1387L6.85406 7.71075L6.02334 6.04801Z'
        fill={fill}
      />
    </svg>
  );
}
