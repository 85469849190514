import { useEffect } from 'react';
function InputEventListener() {
  useEffect(() => {
    const handleInputChange = (event: any) => {
      if (event.target.type === 'number') {
        let inputValue = event.target.value;
        inputValue = inputValue.replace(/\D/g, '');
        if (inputValue.startsWith('0')) {
          inputValue = inputValue.slice(1);
        }
        event.target.value = inputValue;
      }
    };
    const attachEventListeners = () => {
      document.addEventListener('input', handleInputChange);
      document.addEventListener('keydown', (e: any) => {
        if (e.target.type === 'number') {
          ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
        }
      });
    };
    const removeEventListeners = () => {
      document.removeEventListener('input', handleInputChange);
    };
    attachEventListeners();
    return () => {
      removeEventListeners();
    };
  }, []);

  return null;
}

export default InputEventListener;
