import { useSelector } from 'react-redux';
import { RootState } from '../../../../../state/reducers';
import AppointmentTime from '../../../../csr/primaryComponents/AppointmentTime';
import TrackLocation from '../../../../csr/primaryComponents/TrackLocation';
import ServiceLocation from '../../../../csr/primaryComponents/ServiceLocation';
import { useEffect, useMemo, useState } from 'react';
import RejectBox from '../RejectBox';
import AssignUnAssignPopUp from '../../AssignUnAssignPopUp';

const Scheduled = ({
  item,
  setshowAssignTechnician,
  setShowGetAction,
}: {
  item: any;
  setshowAssignTechnician?: any;
  setShowGetAction?: any;
}) => {
  const { user } = useSelector((state: RootState) => state.userState);
  const [rejecting, setRejecting] = useState(false);
  const updatedItem = useMemo(() => ({ ...item, setRejecting }), [item]);
  useEffect(() => {
    if (setShowGetAction) {
      setShowGetAction(rejecting);
    }
  }, [rejecting]);
  const showDeclineRequest =
    (item?.id || item?.preventive_request_id) &&
    ((user?.role === 'fleetmax_partner' && +item?.status_id === -1 && +item?.sub_stage === 2) ||
      (user?.role === 'fleetmax_vendor' && +item?.status_id === 4 && +item?.sub_stage === 2));
  return (
    <>
      {rejecting ? (
        <>
          <div className='border-[0.3rem] border-primary-light rounded-xl'>
            <RejectBox item={updatedItem} />
          </div>
        </>
      ) : (
        <>
          <div className='' style={{ justifyContent: 'center' }}>
            <div
              className={`border-[0.1rem] ${
                showDeclineRequest ? 'rounded-t-[0.5rem]' : 'rounded-xl'
              } border-[#B6B6B6] p-2`}>
              <div
                className='row-container flex flex-col gap-1'
                style={{ marginBottom: '0.3rem', borderBottom: '0.7px solid #B6B6B6' }}>
                <div className='label text-primary text-sm ' style={{ fontWeight: 'normal' }}>
                  Scheduled For
                </div>
                <div
                  className='date-time text-primary'
                  style={{ fontWeight: 'bold', paddingBottom: '10px' }}>
                  <AppointmentTime item={item} />
                </div>
              </div>
              <div
                className='row-container text-primary flex flex-col gap-1'
                style={{ marginBottom: '0.3rem', borderBottom: '1px solid #B6B6B6' }}>
                <div className='label text-primary text-sm' style={{ fontWeight: 'normal' }}>
                  Service Location
                </div>
                <div
                  className='date-time text-[#515151] max-h-[3rem] overflow-auto'
                  style={{ paddingBottom: '10px' }}>
                  {ServiceLocation(item)}
                </div>
              </div>
              <div className='grid grid-cols-2 content-center gap-2 mt-2 mb-[-0.2rem]'>
                <div className='row-container flex flex-col gap-1 justify-center'>
                  <div className='label text-primary text-sm' style={{ fontWeight: 'normal' }}>
                    CSR
                  </div>
                  <div className='location-name'>{item?.csr?.name || user?.name}</div>
                </div>
                {!item.bulk_id && +item?.status_id === 4 && user?.role === 'fleetmax_partner' && (
                  <AssignUnAssignPopUp item={{ ...item }} type='Vendor' />
                )}
                {(item?.id || item?.preventive_request_id) &&
                  +item?.status_id === 5 &&
                  !item?.sub_stage && <AssignUnAssignPopUp item={{ ...item }} type='Technician' />}
              </div>
              <TrackLocation item={item} hide={true} />
            </div>
            {showDeclineRequest && (
              <div
                className='w-full text-[#CC5555] flex bg-[#F8E8E8] items-center p-3 rounded-b-xl cursor-pointer'
                onClick={() => {
                  setRejecting(true);
                  if (setshowAssignTechnician) {
                    setshowAssignTechnician(true);
                  }
                }}>
                <i className='pi pi-plus-circle' style={{ transform: 'rotate(45deg)' }}></i>
                <p className='ml-2 text-[1.05rem]'>Decline Request</p>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
export default Scheduled;
