import { request } from './base';
import { requestChat } from './baseChat';
import {
  AddDspPayload,
  CreateGroupPayload,
  DeleteMessage,
  DeleteRoomPayload,
  IsPinned,
  RemoveUser,
  SeenMessage,
  UpdateMessage,
} from './types';

export default class ChatAPI {
  static getChatUserNames(data: string) {
    return request({
      url: `csr/v1/chat/user_list?list_type=${data}`,
      method: 'get',
    });
  }
  static getChatUserNamesVendor(data: string) {
    return request({
      url: `/vendor_actions/v1/chat/user_list?list_type=${data}`,
      method: 'get',
    });
  }
  static getChatHeads(data: string) {
    return requestChat({
      url: `/chathead/v1/chat_head/${data}`,
      method: 'get',
    });
  }
  static postCreateGroup(data: CreateGroupPayload) {
    return requestChat({
      url: `/chatroom/v1/create_chatroom`,
      method: 'post',
      data: data,
    });
  }
  static deleteRoom(data: DeleteRoomPayload) {
    return requestChat({
      url: `/chatroom/v1/delete_chatroom`,
      method: 'patch',
      data: data,
    });
  }
  static postAddUser(data: AddDspPayload) {
    return requestChat({
      url: `/chatroom/v1/add_user`,
      method: 'post',
      data: data,
    });
  }

  static getMessages(data: any) {
    return requestChat({
      // url: `chat/v1/get_messages/?chat_room_id=${data?.room_id}`,
      url: `chathead/v1/get_messages/${data}`,
      method: 'get',
    });
  }

  static sendMessage(data: any) {
    // static sendMessage(data: any) {
    return requestChat({
      url: `chat/v1/send_message`,
      method: 'post',
      data: data,
    });
  }

  static updateMessage(data: UpdateMessage) {
    return requestChat({
      url: `chat/v1/update_message`,
      method: 'patch',
      data: data,
    });
  }

  static deleteMessage(data: DeleteMessage) {
    return requestChat({
      url: `chat/v1/delete_message`,
      method: 'patch',
      data: data,
    });
  }

  static seenMessage(data: SeenMessage) {
    return requestChat({
      url: `chat/v1/seen_message`,
      method: 'post',
      data: data,
    });
  }
  static removeUser(data: RemoveUser) {
    return requestChat({
      url: `/chatroom/v1/remove_user`,
      method: 'patch',
      data: data,
    });
  }
  static isPinned(data: IsPinned) {
    return requestChat({
      url: `chatroom/v1/pin_chatroom`,
      method: 'patch',
      data: data,
    });
  }
  static getDSPAdmins() {
    return request({
      url: `csr/v1/chat/dsp_admin`,
      method: 'get',
    });
  }

  static getChatProfileDetails(id: number) {
    return requestChat({
      url: `chathead/v1/get_chat_info/${id}`,
      method: 'get',
    });
  }

  static getUserDetails(id: number) {
    return request({
      url: `/users/v1/get_user/${id}`,
      method: 'get',
    });
  }

  static getMultipleUsersDetails(ids: number[]) {
    return request({
      url: `/users/v1/user_details?ids=${JSON.stringify(ids)}`,
      method: 'get',
    });
  }

  static renameGroup(data: { name: string; chat_room_id: number }) {
    return requestChat({
      url: `/chatroom/v1/rename_group`,
      method: 'patch',
      data: data,
    });
  }
}
