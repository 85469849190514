import { SearchIcon } from '@heroicons/react/solid';
import { Avatar, Typography } from '@mui/material';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import PreventiveAPI from '../../../../api/preventiveApi';
import HandleLoad from '../../../../components/Loader/HandleLoad';
import Modal from '../../../../components/OverlayContainer/Model';
import { toast } from '../../../../components/Toast/CustomToast';
import useFetchTechnician from '../../../hooks/useFetchTechnician';
import AddTechnician from '../../../technician/AddTechnician';
import VendorAPI from '../../../../api/vendorApi';
import ServiceLocation from '../../../csr/primaryComponents/ServiceLocation';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/reducers';
import { Technician } from './Technician';

const AssignTechnician = ({ handleClose, item, fetchRequestData }: any) => {
  const { user }: any = useSelector((state: RootState) => state.userState);
  const [selectedTechnician, setSelectedTechnician] = useState<any>({});
  const [addTechnician, setAddTechnician] = useState(false);
  const [notes, setNotes] = useState('');
  const [assignTechinicanModal, setAssignTechinicanModal] = useState({
    open: false,
    data: {},
  });
  const loader = HandleLoad();
  const { technicians, fetchTechnicians, handleSearch } = useFetchTechnician();

  const assignTechnician = () => {
    loader(true);
    PreventiveAPI.assignTechniciansBulk({
      single_requests: [{ preventive_request_id: item?.preventive_request_id }],
      technician_account_id: selectedTechnician.technician_account_id,
      ...(notes?.length > 0 && { note: notes }),
      ...(user.role === 'fleetmax_partner' && {
        vendor_account_id: item?.vendor?.account_id,
        vendor_id: String(item?.vendor?.id),
      }),
    }).then((res) => {
      if (res.status === 200) {
        fetchRequestData();
        handleClose();
        if (user?.role === 'fleetmax_partner') {
          fetchTechnicians(item);
        } else {
          fetchTechnicians();
        }
      } else {
        toast.error('Something Went Wrong');
      }
      loader(false);
    });
  };

  const reAssignTechnician = () => {
    loader(true);
    VendorAPI.reAssignTechnician({
      preventive_request_id: Number(item.preventive_request_id),
      technician_account_id: selectedTechnician.technician_account_id,
      note: notes,
      ...(user.role === 'fleetmax_partner' && {
        vendor_account_id: item?.vendor?.account_id,
        vendor_id: String(item?.vendor?.id),
      }),
    }).then((res) => {
      if (res.status === 200) {
        fetchRequestData();
        handleClose();
        if (user?.role === 'fleetmax_partner') {
          fetchTechnicians(item);
        } else {
          fetchTechnicians();
        }
      } else {
        toast.error('Something Went Wrong');
      }
      loader(false);
    });
  };
  useEffect(() => {
    if (user?.role === 'fleetmax_partner') {
      fetchTechnicians(item);
    }
  }, []);
  return (
    <>
      <Modal
        widthclass={`${assignTechinicanModal.open ? 'w-[50vw]' : 'w-[76vw]'}`}
        noLabel
        heightClass=''
        handleClose={handleClose}>
        <div className='flex flex-col gap-5 h-[35rem]'>
          <div>
            <p className='text-blue-500 font-semibold text-lg py-1.5 px-3'>{item?.title}</p>
          </div>
          {!assignTechinicanModal.open && (
            <div className='flex flex-col justify-between h-full'>
              <div className='flex flex-col gap-5'>
                <div className='flex justify-between items-center'>
                  <div className='relative flex items-center w-[22rem]'>
                    <SearchIcon
                      style={{ width: '17px', position: 'absolute', marginLeft: '8px' }}
                    />
                    <input
                      type='text'
                      placeholder='Search...'
                      onChange={handleSearch}
                      className='border-solid border-2 border-gray p-1.5 pl-8 pt-2 rounded-xl w-full focus:outline-none focus:border-stone-400 transition ease-in duration-300 '
                    />
                  </div>
                  <button
                    className='border-blue-400 border-2 py-2 px-4 bg-slate-50 text-blue-400 rounded-lg hover:bg-slate-100 disabled:text-slate-300 disabled:border-slate-300'
                    disabled={!selectedTechnician.technician_id}
                    onClick={() =>
                      setAssignTechinicanModal({ open: true, data: selectedTechnician })
                    }>
                    Assign Technician
                  </button>
                </div>
                <div className='flex flex-wrap gap-5 overflow-y-auto max-h-[24rem]'>
                  {technicians.length > 0 ? (
                    technicians.map((elem: any) => (
                      <div className='p-1' id={elem?.technician_id} key={elem?.technician_id}>
                        <Technician
                          handleSelected={(id: string) => {
                            if (selectedTechnician?.technician_id === id) {
                              setSelectedTechnician({});
                            } else {
                              setSelectedTechnician(elem);
                            }
                          }}
                          selectedTechnician={selectedTechnician}
                          item={elem}
                        />
                      </div>
                    ))
                  ) : (
                    <div className='w-full h-full grid place-items-center'>
                      <p>no data found</p>
                    </div>
                  )}
                </div>
              </div>
              <div className='flex justify-end'>
                <button
                  className='border-none py-2 px-7 bg-slate-50 text-blue-400 rounded-lg disabled:text-slate-300 hover:bg-slate-100'
                  onClick={() => setAddTechnician(true)}>
                  Add Technician
                </button>
              </div>
            </div>
          )}
          {assignTechinicanModal.open && (
            <div className='flex flex-col justify-between gap-5 flex-1 px-5 py-6 w-full mx-auto'>
              <div className='flex flex-col gap-5'>
                <div className='flex gap-8 divide-slate-100'>
                  <div className='flex flex-col gap-3'>
                    <p className='text-slate-600'>Assign Technician</p>
                    <section
                      className={`relative flex flex-col justify-center items-center gap-1 w-[10rem] h-[7rem] p-2 rounded-lg cursor-pointer border-transparent border-2 hover:border-slate-50 shadow-sm capitalize`}>
                      <Avatar>
                        {selectedTechnician.technician_name.slice(0, 2).toUpperCase()}
                      </Avatar>
                      <Typography className='w-full text-center' noWrap>
                        {selectedTechnician.technician_name}
                      </Typography>
                    </section>
                  </div>
                  <div className='pl-3 w-full'>
                    <div className='p-5 rounded-[1rem] border-[0.1rem] border-[#AAAAAA] mb-4'>
                      <div className='flex text-primary font-bold items-center mb-2'>
                        <i className='pi pi-map-marker mr-1 pb-[0.1rem]'></i>
                        Station Address{' '}
                      </div>
                      <p className='ml-4 max-h-[3rem] overflow-auto'>{ServiceLocation(item)}</p>
                    </div>
                    <textarea
                      value={notes}
                      onChange={(e: { target: { value: string } }) => setNotes(e.target.value)}
                      className='w-full h-[15rem] rounded-lg p-2 border-[0.1rem] border-[#A0A0A0] resize-none transition-colors duration-300 outline-none focus:border-blue-500'
                      placeholder='Add Notes'></textarea>
                  </div>
                </div>
              </div>

              <div className='flex justify-between'>
                <button
                  className='border-transparent border-2 px-3 py-2 text-blue-400 bg-slate-50 rounded-lg hover:border-slate-100'
                  onClick={() => {
                    setAssignTechinicanModal({
                      open: false,
                      data: {},
                    });
                  }}>
                  Back
                </button>
                <Button
                  label='Submit'
                  className='border-transparent border-2 px-3 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-500 transition duration-200 w-[12rem]'
                  onClick={() => {
                    if (item?.technician?.email) {
                      reAssignTechnician();
                    } else {
                      assignTechnician();
                    }
                  }}></Button>
              </div>
            </div>
          )}
        </div>
      </Modal>
      {addTechnician && (
        <AddTechnician
          handleClose={() => setAddTechnician(false)}
          refetch={() => {
            if (user?.role === 'fleetmax_partner') {
              fetchTechnicians(item);
            } else {
              fetchTechnicians();
            }
          }}
        />
      )}
    </>
  );
};

export default AssignTechnician;
