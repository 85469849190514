import VendorAPI from '../../../../api/vendorApi';
import HandleLoad from '../../../../components/Loader/HandleLoad';
import { useRef } from 'react';
import PartnerApi from '../../../../api/partnerSpecificApi';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/reducers';
import CsrAPI from '../../../../api/csrApi';
import useFetchSingleRequest from '../../../hooks/vendor/useFetchSingleRequest';
import { DeclineBox } from './DeclineBox';
import { toast } from '../../../../components/Toast/CustomToast';
function RejectBox({ item }: { item: any }) {
  const loader = HandleLoad();
  const { user }: any = useSelector((state: RootState) => state.userState);
  const { getFullRequestInfo }: any = useFetchSingleRequest();
  const { setRejecting } = item;
  const noteRef: any = useRef();
  const declineRequest = () => {
    if (!noteRef?.current?.value?.trim()) {
      toast.warn('Please add note');
      return;
    }
    loader(true);
    VendorAPI.rejectCsrRequest({
      preventive_request_id: item.preventive_request_id,
      ...(noteRef?.current?.value?.trim() && { note: noteRef?.current?.value?.trim() }),
    }).then((res) => {
      if (res.status === 200) {
        item?.fetchRequestData?.();
        item?.refetch?.();
        setRejecting?.(false);
        if (
          window.location.pathname.includes('vendor/dashboard') ||
          window.location.pathname.includes('partner/dashboard')
        ) {
          item?.handleClose?.();
          item?.handleCloseAll?.();
        }
        toast.success(`Request declined successfully`);
      } else {
        toast.error('someting went wrong');
      }
      loader(false);
    });
  };
  console.log(item);
  const declineRequestBulk = () => {
    if (!noteRef?.current?.value?.trim()) {
      toast.warn('Please add note');
      return;
    }
    let payload;
    if (item.bulkSchedules && item?.bulkSchedules?.length > 0) {
      payload = {
        bulk_requests: item.bulkSchedules.map((d: any) => ({
          preventive_request_id: d.preventive_request_id || d.id,
        })),
        ...(noteRef?.current?.value?.trim() && { note: noteRef?.current?.value?.trim() }),
        bulk_id: item.bulk_id,
      };
    } else {
      payload = {
        bulk_requests: [{ preventive_request_id: item?.preventive_request_id }],
        ...(item?.bulk_id && { bulk_id: item?.bulk_id }),
        ...(noteRef?.current?.value?.trim() && { note: noteRef?.current?.value?.trim() }),
        ...(item?.bulk_id && { reject_individual_vehicle_from_bulk: true }),
      };
    }
    loader(true);
    if (user?.role === 'fleetmax_partner') {
      PartnerApi.rejectPartnerRequest(payload).then((res) => {
        if (res.status === 200) {
          if (item?.fetchRequestData) {
            item?.fetchRequestData?.();
          }
          item?.refetch?.();
          setRejecting?.(false);
          toast.success(`Request declined successfully`);
          if (
            window.location.pathname.includes('vendor/dashboard') ||
            window.location.pathname.includes('partner/dashboard')
          ) {
            item?.handleClose?.();
            item?.handleCloseAll?.();
          }
        } else {
          toast.error('someting went wrong');
        }
        loader(false);
      });
    } else {
      VendorAPI.rejectCsrRequestBulk(payload).then((res) => {
        if (res.status === 200) {
          if (item?.fetchRequestData) {
            item?.fetchRequestData();
          }
          item?.refetch?.();
          setRejecting?.(false);
          if (
            window.location.pathname.includes('vendor/dashboard') ||
            window.location.pathname.includes('partner/dashboard')
          ) {
            item?.handleClose?.();
            item?.handleCloseAll?.();
          }
          toast.success(`Request declined successfully`);
        } else {
          toast.error('someting went wrong');
        }
        loader(false);
      });
    }
  };
  const removeVendor = () => {
    if (!noteRef?.current?.value?.trim()) {
      toast.warn('Please add note');
      return;
    }
    loader(true);
    CsrAPI.removeVendorBulk({
      bulk_requests: [{ preventive_request_id: item.id || item?.preventive_request_id }],
      ...(noteRef?.current?.value?.trim() && { note: noteRef?.current?.value?.trim() }),
      vendor_account_id: item.vendor.account_id,
    }).then((res) => {
      setRejecting?.(false);
      if (res.status === 200) {
        if (user?.role === 'fleetmax_partner') {
          getFullRequestInfo?.();
        }
        if (
          +item.status_id === 3 ||
          ((+item.status_id === 4 || +item.sub_stage === 3) && item.refetchPm)
        ) {
          item.refetchPm();
        }
        item.refetch?.();
        toast.success(`Vendor Removed successfully`);
      } else {
        toast.error('someting went wrong');
      }
      loader(false);
    });
  };

  const removeVendorBulk = () => {
    if (!noteRef?.current?.value?.trim()) {
      toast.warn('Please add note');
      return;
    }
    loader(true);
    CsrAPI.removeVendorBulk({
      bulk_requests: item.bulkSchedules.map((d: any) => ({
        preventive_request_id: d.id,
      })),
      ...(noteRef?.current?.value?.trim() && { note: noteRef?.current?.value?.trim() }),
      vendor_account_id: item.vendor.account_id,
      bulk_id: item.bulk_id,
    }).then((res) => {
      setRejecting?.(false);
      if (res.status === 200) {
        item.refetch();
        if (
          +item.status_id === 3 ||
          ((+item.status_id === 4 || +item.sub_stage === 3) && item.refetchPm)
        ) {
          item.refetchPm();
        }
        toast.success(`Vendor Removed successfully`);
      } else {
        toast.error('someting went wrong');
      }
      loader(false);
    });
  };
  const vendorRemove = () => {
    if (item?.bulk_id) {
      removeVendorBulk();
    } else {
      removeVendor();
    }
  };
  const decline = () => {
    // condition for decline request
    if (user?.role === 'fleetmax_partner') {
      // using one API for partneer
      declineRequestBulk();
    } else {
      if (item?.bulk_id) {
        declineRequestBulk();
      } else {
        declineRequest();
      }
    }
  };
  const handleReject = () => {
    if (user?.role === 'fleetmax_vendor') {
      if (+item?.status_id === 4) {
        decline();
      }
    } else if (user?.role === 'fleetmax_partner') {
      if (+item?.status_id === -1) {
        decline();
      } else {
        vendorRemove();
      }
    }
  };

  return <DeclineBox {...{ setRejecting, noteRef, handleReject }} />;
}

export default RejectBox;
