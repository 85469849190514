const SVG = (props: { height?: string; width?: string; active?: boolean }) => {
    const height = props?.height || '1rem';
    const width = props?.width || '1rem';
    return (
        <svg 
         width={width}
         height={height}
         viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path id="Vector" d="M7.95898 0C3.83398 0 0.458984 3.375 0.458984 7.5C0.458984 11.625 3.83398 15 7.95898 15C12.084 15 15.459 11.625 15.459 7.5C15.459 3.375 12.084 0 7.95898 0ZM10.734 9.225C11.034 9.525 11.034 9.975 10.734 10.275C10.434 10.575 9.98398 10.575 9.68398 10.275L7.95898 8.55L6.23398 10.275C5.93398 10.575 5.48398 10.575 5.18398 10.275C4.88398 9.975 4.88398 9.525 5.18398 9.225L6.90898 7.5L5.18398 5.775C4.88398 5.475 4.88398 5.025 5.18398 4.725C5.48398 4.425 5.93398 4.425 6.23398 4.725L7.95898 6.45L9.68398 4.725C9.98398 4.425 10.434 4.425 10.734 4.725C11.034 5.025 11.034 5.475 10.734 5.775L9.00898 7.5L10.734 9.225Z" fill="#CC5555"/>
        </svg>
    );
  };
  export default SVG;
  