import styled from 'styled-components';
import { v4 } from 'uuid';
import { TimeType } from '../../models/TimeModel';
const StyleDiv = styled.div.attrs((props: { width: string }) => props)`
  height: 30vh;
  & div {
    ::-webkit-scrollbar {
      width: 5px !important;
    }
  }
`;

function Timepicker({ time, setTime }: { time: TimeType; setTime: (obj: TimeType) => void }) {
  const hoursArr = new Array(12).fill('hours');
  const minsArr = new Array(60).fill('mins');
  const mainBox = 'w-1/3 flex flex-col overflow-auto items-center gap-2';
  const select = `hover:bg-primary hover:text-white p-1 py-0 rounded-sm cursor-pointer font-bold focus:outline-none`;
  const selected = 'bg-primary text-white';

  const { hr, min, period } = time;

  const onClick = (value: any) => {
    const { id, innerText } = value.target;
    setTime({
      ...time,
      [id]: innerText,
    });
  };

  const pad = (index: number) => {
    return String(index).padStart(2, '0');
  };

  return (
    <StyleDiv>
      <div className='w-[10rem] flex flex-col h-full p-2 rounded-xl shadow-barShadow'>
        <div className='w-full flex flex-row mb-1'>
          <p className='w-1/3 text-center text-primary text-sm font-bold'>Hrs.</p>
          <p className='w-1/3 text-center  text-primary text-sm font-bold'>Mins.</p>
        </div>
        <div className='w-full flex flex-row flex-1 h-0'>
          <div className={mainBox}>
            {hoursArr.map((hour, index) => (
              <p
                className={`${select} ${pad(index + 1) === hr && selected}`}
                onClick={onClick}
                id={'hr'}
                key={v4()}>
                {pad(index + 1)}
              </p>
            ))}
          </div>
          <div className={mainBox}>
            {minsArr.map((hour, index) => (
              <p
                className={`${select} ${pad(index) === min && selected}`}
                onClick={onClick}
                id={'min'}
                key={v4()}
                onFocus={onClick}>
                {pad(index)}
              </p>
            ))}
          </div>
          <div className={mainBox}>
            <p
              className={`${select} ${'AM' === period && selected}`}
              onClick={onClick}
              autoCapitalize={'words'}
              id={'period'}>
              AM
            </p>
            <p
              className={`${select} ${'PM' === period && selected}`}
              onClick={onClick}
              id={'period'}>
              PM
            </p>
          </div>
        </div>
      </div>
    </StyleDiv>
  );
}

export default Timepicker;
