import TextField from '@mui/material/TextField';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Controller } from 'react-hook-form';
import PrimaryButton from '../Buttons/PrimaranyBtn';

const DataBox = (props: any) => {
  return (
    <>
      <p className='text-left w-full'>{props.lable}</p>
      <TextField
        sx={{
          width: '100%',
          '& .MuiInputBase-root': {
            '& input': {
              padding: '0.5rem 0.8rem',
            },
          },
        }}
        size={'small'}
        {...props}
        autoComplete={'off'}
        {...(props?.type === 'number' && {
          onKeyDown: (e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault(),
        })}
      />
    </>
  );
};

const MainBox = (props: any) => {
  const { control, errors, name, type } = props;
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value } }) => (
        <DataBox
          lable={name}
          {...{
            onChange,
            onBlur,
            value,
            helperText: errors[name]?.message,
            error: !!errors[name]?.message,
            type,
          }}
        />
      )}
    />
  );
};

function AddDetails({ control, errors, lable, handleSubmit, fieldDetails, edit }: any) {
  const outerTheme = createTheme({
    typography: {
      fontFamily: 'inherit',
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-root': {
              fontSize: '1.2rem',
              '& fieldset': {
                borderColor: 'rgba(221, 221, 221, 0.62)',
              },
              // '&:hover fieldset': {
              //   borderColor: 'white',
              // },
              // '&.Mui-focused fieldset': {
              //   borderColor: 'yellow',
              // },
            },
            '& input': {
              padding: '0.5rem 0.8rem',
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={outerTheme}>
      <div className='flex flex-col gap-y-4 justify-center items-center w-full'>
        <h1 className='text-center flex-1 text-xl'>{lable}</h1>
        {fieldDetails?.map(
          (field: {
            label: string;
            type: string;
            boxType?: any;
            box: JSX.Element;
            key: string;
          }) => {
            const { boxType } = field;
            if (['Password'].includes(boxType)) {
              return field.box;
            }
            return (
              <MainBox
                name={field.label}
                control={control}
                errors={errors}
                type={field?.type}
                key={field?.key}
              />
            );
          }
        )}
        <PrimaryButton
          label={edit ? 'Save Changes' : lable}
          className={'bg-primary w-1/2 rounded-lg text-sm my-3'}
          onClick={handleSubmit}
        />
      </div>
    </ThemeProvider>
  );
}

export default AddDetails;
