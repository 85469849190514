import { Button, Tooltip } from '@mui/material';
import { PartnerButtonPopUp, TechnicianButtonPopUp, VendorButtonPopUp } from '../ScheduledInfo';
import AssignUnAssignPopUp from '../../../../vender/PrimaryComponents/AssignUnAssignPopUp';

export const invoiceAndVendorAmountCheck = (item: any) =>
  item?.final_payment &&
  ['sent', 'updated', 'amount_submitted', 'amount_submitted_by_csr'].includes(
    item?.vendor_invoice_status
  );
function VendorPaymentsInfo({ invoiceDetails }: { invoiceDetails: any }) {
  const totalAmount = invoiceDetails?.invoice?.additional_info?.services?.reduce(
    (total: any, data: any) => total + Number(data?.vendor_price || data?.price),
    0
  );
  return (
    <>
      <div className='w-full flex flex-col  rounded-xl border-[0.2rem] border-gray-200 max-h-[10rem] overflow-auto '>
        {invoiceDetails?.invoice?.additional_info?.services?.length > 0 &&
          invoiceDetails?.invoice?.additional_info?.services.map((ele: any, index: number) => {
            return (
              <div
                key={ele?.service || ele?.vendor_price || ele?.price || ''}
                className={`w-full flex justify-between p-3 h-[3rem] items-center ${'border-b-[0.2rem] border-gray-200'}`}>
                <p className='text-[0.9rem] flex gap-2 items-center '>
                  {ele.service}
                  {ele?.vendor_price && (
                    <Tooltip title={`${'Payment Discrepancy'}`}>
                      <i className='pi pi-exclamation-circle mr-1 font-bold text-[0.7rem] text-[#C55]'></i>
                    </Tooltip>
                  )}
                </p>
                <p className='text-[0.9rem] flex gap-2 items-center'>
                  ${ele?.vendor_price || ele?.price}
                </p>
              </div>
            );
          })}
        {invoiceDetails?.invoice?.additional_info?.services?.length > 0 && (
          <div className={`w-full flex justify-between p-2 h-[3rem] items-center `}>
            <p className='text-[0.9rem]'>TOTAL</p>
            <p className='text-[0.9rem]'>${totalAmount}</p>
          </div>
        )}
      </div>
    </>
  );
}
const getInvoiceIdForVendorRecipient = (item: any) => {
  const invoices = item?.invoices || [];

  for (const invoice of invoices) {
    if (invoice?.recipient === 'vendor') {
      return invoice.id;
    }
  }

  return '';
};
export const MismatchPaymentInformation = ({ selectedRequest, item, user }: any) => {
  return (
    <>
      <div
        className='grid grid-cols-2 content-center w-full h-full'
        style={{ marginBottom: '0.3rem' }}>
        <div className='row-container flex flex-col gap-1 justify-center'>
          <div className='label text-primary text-sm' style={{ fontWeight: 'normal' }}>
            CSR
          </div>
          <div className='location-name'>{item?.csr?.name || user?.name}</div>
        </div>
        {item?.id &&
          +item?.status_id >= 4 &&
          !(+item?.status_id === 10 && +item?.sub_stage === 1) && (
            <AssignUnAssignPopUp item={{ ...item }} type='Vendor' />
          )}
      </div>
    </>
  );
};

const getTotalAmount = (total: any) => <p className='text-[1rem]'>${Number(total).toFixed(2)}</p>;

const getInvoiceAmount = (vendorInvoice: any, total: any) => {
  const amount = vendorInvoice?.total_amount || total || 0;
  return getTotalAmount(amount);
};

const getVendorEnteredAmount = (selectedRequest: any, item: any) => {
  const amount = selectedRequest?.final_payment || item?.final_payment || 0;
  return getTotalAmount(amount);
};

function checkPaymentConfirmation(data: any) {
  const isInspectPage = window.location.href.includes('inspect');
  return +data?.status_id === 12 && +data?.sub_stage === 1 && !isInspectPage;
}
function isConfirmedMoveable(data: any) {
  const isInspectPage = window.location.href.includes('inspect');
  return +data?.status_id === 12 && !data?.sub_stage && !isInspectPage;
}
export function VendorPaymentInfo({ selectedRequest, item, user, refetch }: any) {
  const isInspectPage = window.location.href.includes('inspect');
  return (
    <>
      <div
        className='grid grid-cols-2 content-center w-full h-full'
        style={{
          marginBottom: '0.3rem',
          borderBottom:
            invoiceAndVendorAmountCheck(selectedRequest || item) &&
            !isInspectPage &&
            '1px solid #B6B6B6',
        }}>
        <div className='row-container flex flex-col gap-1 justify-center'>
          <div className='label text-primary text-sm' style={{ fontWeight: 'normal' }}>
            CSR
          </div>
          <div className='location-name'>{item?.csr?.name || user?.name}</div>
        </div>
        {item?.id && +item?.status_id === 5 && !item?.sub_stage && !selectedRequest ? (
          <TechnicianButtonPopUp item={item} />
        ) : (
          <>
            {' '}
            {item?.id &&
              +item?.status_id === 4 &&
              !selectedRequest &&
              !(+item?.status_id === 10 && +item?.sub_stage === 1) && (
                <VendorButtonPopUp item={item} />
              )}
            {item?.id &&
              !selectedRequest &&
              +item?.status_id === -1 &&
              !(+item?.status_id === 10 && +item?.sub_stage === 1) && (
                <PartnerButtonPopUp item={item} />
              )}
            {+item?.status_id === 5 && selectedRequest && (
              <TechnicianButtonPopUp
                item={{ ...selectedRequest, bulkSchedules: [selectedRequest] }}
              />
            )}
          </>
        )}
      </div>
      {invoiceAndVendorAmountCheck(selectedRequest || item) && (
        <div className='grid grid-cols-2 content-center h-full text-[#515151]'>
          <div className='flex  gap-1 justify-start flex-col h-full'>
            <div className='flex-col flex  justify-start text-primary'>Invoice Value</div>
            {/* <p className='flex'>{getInvoiceAmount(vendorInvoice, total)}</p> */}
            <p className='flex'>
              ${' '}
              {Number((selectedRequest || item)?.vendor_invoice_amount)?.toFixed(2) ||
                getVendorEnteredAmount(selectedRequest, item) ||
                '---'}
            </p>
          </div>
          <div className='flex  gap-1 justify-end flex-col h-full'>
            <div className='flex-col flex  justify-end text-primary'>Vendor Value</div>
            <p className='flex'>{getVendorEnteredAmount(selectedRequest, item)}</p>
            {/* <p className='flex'>{getInvoiceAmount(vendorInvoice, total)}</p> */}
            <p className='text-[0.8rem]'>(Payment Confirmed)</p>
          </div>
        </div>
      )}
    </>
  );
}
export const DSPVendorPaymentDetails = ({ DSPtotalPrice, selectedRequest, item }: any) => (
  <div
    className='grid grid-cols-2 content-center w-full h-full'
    style={{ marginBottom: '0.3rem', borderBottom: '1px solid #B6B6B6' }}>
    <div className='h-full'>
      <div className='text-primary text-[1.2rem] '>DSP Cost</div>
      <p className='text-[1.1rem] font-bold text-primary'>${DSPtotalPrice || '---'}</p>
    </div>
    <div className='h-full'>
      <div className='text-primary text-[1.2rem] '>Vendor Cost</div>
      <p className='text-[1.1rem] font-bold text-primary'>
        {getVendorEnteredAmount(selectedRequest, item)}
      </p>
    </div>
  </div>
);
export const UpdateButton = ({ selectedRequest, item }: any) => (
  <div className='w-full flex flex-col justify-center gap-1 items-center '>
    <div className='w-full flex justify-center '>
      <p className='w-fit flex justify-center items-center bg-[#CC555514] text-[#C55] py-1 rounded-[0.5rem] text-[0.8rem] px-3 '>
        {' '}
        <i className='pi pi-exclamation-circle mr-2 font-bold text-[0.7rem]'></i>Mismatch found in
        Invoice Value & Vendor Payment
      </p>
    </div>
    <Button
      className='w-full text-[0.8rem] font-bold px-[0.8rem] py-1'
      onClick={() => {
        if (selectedRequest) {
          selectedRequest?.setInvoiceModal?.({
            open: true,
            data: {
              ...selectedRequest,
              updateVendorInvoice: true,
            },
          });
        } else {
          item?.setInvoiceModal?.({
            open: true,
            data: {
              ...item,
              updateVendorInvoice: true,
            },
          });
        }
      }}>
      Edit Invoice
    </Button>
  </div>
);
