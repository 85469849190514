import { SectionType } from './Types';

const borderDencity = '500';
const border = 'border-r border-r-500';
const commonClass = 'flex flex-col w-full';
const scroll = 'overflow-auto';

const headings = {
  dsp: (
    <div
      className={`h-[3rem] text-[1.2rem] flex items-center justify-center border-b border-b-${borderDencity} border-r border-r-${borderDencity}`}>
      DSP
    </div>
  ),
  vehicles: (
    <div
      className={`h-[3rem] text-[1.2rem] flex items-center justify-center border-b border-b-${borderDencity} border-r border-r-${borderDencity}`}>
      Vehicles
    </div>
  ),
  admins: (
    <div
      className={`h-[3.25rem] text-[1.2rem] flex items-center justify-center border-b border-b-${borderDencity}`}>
      Select Owner/Admin
    </div>
  ),
};

export const Section = ({ type, children }: SectionType) => {
  if (type === 'DSP') {
    return (
      <div className={commonClass}>
        {headings.dsp}
        <div className={`h-[90%] ${border} flex flex-col gap-2 mt-1 px-1 ${scroll}`}>
          {children}
        </div>
      </div>
    );
  } else if (type === 'Vehicles') {
    return (
      <div className={commonClass}>
        {headings.vehicles}
        <div className={`h-[90%] ${border} text-gray-400 p-1 flex flex-col gap-1 ${scroll}`}>
          {children}
        </div>
      </div>
    );
  } else if (type === 'Admins') {
    return (
      <div className={commonClass}>
        {headings.admins}
        <div className={`h-[100%] flex flex-col ${scroll}`}>{children}</div>
      </div>
    );
  } else return null;
};
