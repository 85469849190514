export interface Counts {
  all?: number;
  pending?: number;
  received?: number;
  approved?: number;
  disputed?: number;
  payment_completed?: number;
}

export const generateTabs = (counts: Counts) => {
  const tabsConfig = [
    {
      key: 'all',
      label: 'All',
      bgColor: 'bg-gray-200',
      textColor: 'text-gray-500',
      borderColor: 'border-gray-500',
      count: counts?.all || 0,
    },
    {
      key: 'pending',
      label: 'Pending',
      bgColor: 'bg-primary-light',
      textColor: 'text-primary',
      borderColor: 'border-primary',
      count: counts?.pending || 0,
    },
    {
      key: 'received',
      label: 'Received',
      bgColor: 'bg-[#F6EFD3]',
      textColor: 'text-[#AC9059]',
      borderColor: 'border-[#AC9059]',
      count: counts?.received || 0,
    },
    {
      key: 'approved',
      label: 'Approved',
      bgColor: 'bg-[#F8F0FF]',
      textColor: 'text-[#7B58DE]',
      borderColor: 'border-[#7B58DE]',
      count: counts?.approved || 0,
    },
    {
      key: 'disputed',
      label: 'Disputed',
      bgColor: 'bg-[#FFE7E7]',
      textColor: 'text-[#CC5555]',
      borderColor: 'border-[#CC5555]',
      count: counts?.disputed || 0,
    },
    {
      key: 'payment_completed',
      label: 'Payment Confirmed',
      bgColor: 'bg-[#EBFFDB]',
      textColor: 'text-[#51994F]',
      borderColor: 'border-[#51994F]',
      count: counts?.payment_completed || 0,
    },
  ];

  return tabsConfig;
};
