import { useState } from 'react';
import VendorAPI from '../../api/vendorApi';
import HandleLoad from '../../components/Loader/HandleLoad';

const useFetchVehicleChecklist = () => {
  const handleLoad = HandleLoad();
  const [vehicleChecklist, setVehicleChecklist] = useState<any>([]);
  const getVehicleCheckList = async (data: any, noLoader?: boolean) => {
    if (!noLoader) {
      handleLoad(true);
    }
    const response = await VendorAPI.getVehicleCheckList({
      preventive_request_id: data?.id || data?.preventive_request_id || '',
    });
    if (response.status === 200) {
      setVehicleChecklist(response?.data?.data?.[0]);
      if (!noLoader) {
        handleLoad(false);
      }
    } else {
      setVehicleChecklist([]);
      if (!noLoader) {
        handleLoad(false);
      }
    }
  };

  return { vehicleChecklist, setVehicleChecklist, getVehicleCheckList };
};

export default useFetchVehicleChecklist;
