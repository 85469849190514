import { useState } from 'react';
import ViewInvoiceModal from '../csr/primaryComponents/ViewInvoice';

function ViewInvoice({ item, component }: any) {
  const [invoiceModalView, setInvoiceModalView] = useState({ open: false, data: {} });
  if (+item?.status_id < 11) return null;
  const handleEdit = (onClicked: string) => {
    item?.setInvoiceModal?.({
      open: true,
      data: {
        ...item,
        onClicked,
        clickedFromViewInvoice: true,
      },
    });
    setInvoiceModalView({ open: false, data: {} });
  };
  return (
    <>
      <div onClick={() => setInvoiceModalView({ open: true, data: item })}>{component}</div>
      {invoiceModalView.open && (
        <ViewInvoiceModal
          handleClose={() => setInvoiceModalView({ open: false, data: {} })}
          item={{ ...invoiceModalView.data, handleEdit }}
        />
      )}
    </>
  );
}

export default ViewInvoice;
