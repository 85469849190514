import { useCallback } from 'react';
import VendorAPI from '../../api/vendorApi';
import HandleLoad from '../../components/Loader/HandleLoad';
import { SingleVendorType } from '../../models/SingleVendor';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../state/reducers';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state';

const useFetchVendorServices = () => {
  const loader = HandleLoad();
  const dispatch = useDispatch();
  const { vendorDetails } = useSelector((state: RootState) => state.userState);
  const { setVendorDetails } = bindActionCreators(actionCreators, dispatch);
  const { services, allDetail } = vendorDetails;

  const fetchVenderServices = useCallback((vendor: SingleVendorType) => {
    loader(true);
    VendorAPI.getVendorFullDetail({ id: vendor?.vendor_id || vendor?.id || '' }).then(
      (res: any) => {
        if (res.status === 200) {
          const fullDetails: any = res && Object.values(res?.data.data || {})[0];
          setVendorDetails({
            services: Array.from(
              new Set([
                ...fullDetails?.services
                  ?.filter((service: any) => service?.is_enabled)
                  .map((service: any) => service.name),
              ])
            ).reverse(),
            allDetail: fullDetails,
          });
        } else {
          setVendorDetails({
            services: [],
            allDetail: {},
          });
        }
        loader(false);
      }
    );
  }, []);

  return { services, allDetail, fetchVenderServices };
};

export default useFetchVendorServices;
