import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { RootState } from './state/reducers';
import dayjs from 'dayjs';
const weekOfYear = (date: dayjs.Dayjs) => {
  const startOfYear = date.startOf('year');
  const weekNumber = Math.ceil(date.diff(startOfYear, 'week', true)) + 1;
  return weekNumber;
};
const handleMenuItemClick = (time: string) => {
  const durationMap: { [key: string]: number } = {
    '4 Weeks': 4,
    '8 Weeks': 8,
    '10 Weeks': 10,
    '12 Weeks': 12,
  };

  const durationInWeeks = durationMap[time] || 4;
  const endDate = dayjs().startOf('week');
  const startDate = endDate.subtract(durationInWeeks - 1, 'week');

  const selectedWeeks: string[] = [];
  let currentDate = endDate;

  while (currentDate.isAfter(startDate, 'day') || currentDate.isSame(startDate, 'day')) {
    const weekNumber = weekOfYear(currentDate);
    const year = currentDate.year();
    selectedWeeks.unshift(`W${weekNumber}-${year}`);
    currentDate = currentDate.subtract(1, 'week');
  }

  const newParams = new URLSearchParams();
  newParams.set('page_number', '1');
  newParams.set('revenue_sort', 'desc');
  newParams.set(
    'weeks',
    JSON.stringify(
      selectedWeeks.map((item) => {
        const [weekNumber, year] = item.split('-');
        return `${year.toLowerCase()}-${weekNumber}`;
      })
    )
  );
  newParams.set('isWeek', 'true');
  const newUrl = `/partner/dashboard/revenue?${newParams.toString()}`;
  return newUrl;
};
export const getMainRoute = (role: string, account_id?: string) => {
  switch (role) {
    case 'fleetmax_csr':
      return '/csr/dashboard?type=pending&offset=1';
    case 'fleetmax_supervisor':
      return '/supervisor/csr';
    case 'fleetmax_vendor':
      return '/vendor/dashboard?type=requests&offset=1';
    case 'fleetmax_partner':
      return '/partner/dashboard?type=requests&offset=1';

    // can remove this once dashboard page is implemented
    // return handleMenuItemClick('10 Weeks');
    default:
      return '/supervisor/csr';
  }
};

const RoleAcess = ({ allowedRoles }: { allowedRoles: string[] }) => {
  const { user: userInState }: any = useSelector((state: RootState) => state.userState);
  const currentUser: string | null = localStorage.getItem('user');
  const user = currentUser && JSON.parse(currentUser);
  const isRoleAllowed = allowedRoles.find((role) => user?.role.includes(role));
  let destination: any;
  if (userInState?.role !== user?.role) {
    window?.location?.reload();
  }
  if (isRoleAllowed) {
    destination = <Outlet />;
  } else if (user?.role) {
    destination = <Navigate to={getMainRoute(user?.role)} replace />;
  } else {
    destination = <Navigate to='/signin' replace />;
  }

  return destination;
};

export default RoleAcess;
