import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Badge } from '@mui/material';
import VendorAPI from '../../../../../api/vendorApi';
import Tootltip from '../../../../../components/BarData/Tootltip';
import { RootState } from '../../../../../state/reducers';

export default function FilterDrawer({ count, setCount }: any) {
  const [open, setOpen] = React.useState(false);
  const [urlParams, setSearchParams]: any = useSearchParams();
  const { user }: any = useSelector((state: RootState) => state.userState);
  const [isOpenServices, setIsOpenServices] = useState(false);
  const [isOpenStationCode, setIsOpenStationCode] = useState(false);
  const [isOpenTechnician, setIsOpenTechnician] = useState(false);
  const [checkServicesData, setCheckServicesData] = useState({});
  const [checkStationCode, setCheckStationCode] = useState({});
  const [checkTechnicianData, setCheckTechnicianData] = useState({});
  const [allServicesData, setAllServicesData] = useState<any>([]);
  const [allStationCodeData, setAllStationCodeData] = useState<any>([]);
  const [allTechnicianData, setAllTechniciationData] = useState<any>([]);
  const isVendorDashboard =
    window.location.href.includes('vendor/dashboard') ||
    window.location.href.includes('partner/dashboard');

  useEffect(() => {
    VendorAPI.getVendorFullDetail({ id: '' }).then((res: any) => {
      if (res.status === 200) {
        const responseData = res.data?.data[Object.keys(res.data?.data)[0]]['services'].reduce(
          (acc: any, currentItem: any) => {
            if (!acc.find((item: any) => item.name === currentItem.name)) {
              acc.push({
                id: currentItem.id,
                name: currentItem.name,
              });
            }
            return acc;
          },
          []
        );
        setAllServicesData(responseData);
      }
    });

    VendorAPI.getVendorsTechnician()
      .then((res: any) => {
        if (res.status === 200) {
          const responseData = res.data.data.technicians_details.map((item: any) => {
            return {
              user_name: item?.technician_name,
              id: item?.technician_id,
            };
          });

          setAllTechniciationData(responseData);
        } else {
          setAllTechniciationData([]);
        }
      })
      .catch((err) => console.log(err));

    VendorAPI.getRequestCompanies(user?.account_id)
      .then((res: any) => {
        if (res.status === 200) {
          const responseDspCode: any = res?.data?.data.map((item: any) => {
            return {
              user_dsp_code: item?.station_code,
              user_company_id: item?.company_details?.map((item: any) => item.id),
            };
          });
          setAllStationCodeData(responseDspCode);
        } else {
          setAllStationCodeData([]);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    if (count === 0) {
      handleCancelFilter();
    }
  }, [count, urlParams]);
  const handleApplyFilter = () => {
    let filteredTechnicianObject = Object.fromEntries(
      Object.entries(checkTechnicianData).filter(([key, value]) => value === true)
    );
    let filteredServicesObject = Object.fromEntries(
      Object.entries(checkServicesData).filter(([key, value]) => value === true)
    );
    let filteredStationCodesObject = Object.fromEntries(
      Object.entries(checkStationCode).filter(([key, value]) => value === true)
    );

    if (Object.keys(filteredTechnicianObject).length !== 0) {
      urlParams.set('technician_ids', JSON.stringify(Object.keys(filteredTechnicianObject)));
    }

    if (Object.keys(filteredServicesObject).length !== 0) {
      urlParams.set('services', JSON.stringify(Object.keys(filteredServicesObject)));
    }
    if (Object.keys(filteredStationCodesObject).length !== 0) {
      urlParams.set('station_ids', JSON.stringify([...Object.keys(filteredStationCodesObject)]));
    }
    if (Object.keys(filteredServicesObject).length === 0) {
      urlParams.delete('services');
    }
    if (Object.keys(filteredStationCodesObject).length === 0) {
      urlParams.delete('station_ids');
    }

    if (Object.keys(filteredTechnicianObject).length === 0) {
      urlParams.delete('technician_ids');
    }

    setSearchParams(urlParams);
    setCount(
      Object.keys(filteredTechnicianObject).length +
        Object.keys(filteredServicesObject).length +
        Object.keys(filteredStationCodesObject).length
    );
    setOpen(false);
  };
  const handleCancelFilter = () => {
    urlParams.delete('technician_ids');
    urlParams.delete('services');
    urlParams.delete('station_ids');
    setSearchParams(urlParams);
    setCheckServicesData({});
    setCheckTechnicianData({});
    setCheckStationCode({});
    setCount(0);
  };
  const toggleDrawer = (newOpen: boolean, outside?: any) => () => {
    setOpen(newOpen);
    if (
      !(
        urlParams.get('technician_ids') ||
        urlParams.get('services') ||
        urlParams.get('station_ids')
      )
    ) {
      setCheckServicesData({});
      setCheckTechnicianData({});
      setCheckStationCode({});
      setCount(0);
    }
  };

  const handleServicesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckServicesData({ ...checkServicesData, [event.target.name]: event.target.checked });
  };
  const handleTechnicianChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckTechnicianData({ ...checkTechnicianData, [event.target.name]: event.target.checked });
  };

  const handleStationCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckStationCode({ ...checkStationCode, [event.target.name]: event.target.checked });
  };
  function capitalizeFirstLetter(sentence: any) {
    let words = sentence?.split(' ') || [];
    let capitalizedWords = words.map((word: any) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    let capitalizedSentence = capitalizedWords.join(' ');
    return capitalizedSentence;
  }

  return (
    <div>
      <button
        className={`flex justify-start items-center rounded-full ${
          isVendorDashboard ? ' h-[2.5rem]' : ' h-[3rem]'
        } border-[0.15rem] border-gray px-[0.5rem] w-[10rem] ml-[7rem] bg-primary-lighter `}
        onClick={toggleDrawer(true)}>
        <Badge
          badgeContent={count}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            color: 'red',
            '& .MuiBadge-badge': {
              backgroundColor: '#CC5555',
              color: 'white',
              boxShadow: '0px 1.26667px 2.53333px rgba(0, 0, 0, 0.25)',
              width: '1.4rem',
              height: '1.4rem',
              padding: '0 0.3rem',
            },
          }}
          max={99}>
          <div>
            <img
              src='../../svg/filter.svg'
              alt='DownArrowBtn'
              className='inline-block h-[1.1rem] w-[1.1rem] ml-[1rem]  pb-[0.1rem]'
            />
          </div>
        </Badge>

        <div className={`text-gray-600 font-bold-700 text-[1.1rem] pl-[1rem] `}>All Filters</div>
      </button>

      <Drawer anchor={'right'} open={open} onClose={toggleDrawer(false, true)}>
        <div className='px-[1rem] text-gray-400 font-bold text-[1.1rem] p-[0.5rem]'>
          All Filters
        </div>
        <div className='flex flex-col justify-between h-[100%] w-[20rem]'>
          <div>
            <div>
              <div
                className='flex justify-between px-[1rem] pt-[0.3rem] text-[1.2rem] font-bold pb-[0.3rem] border-b-[0.15rem] cursor-pointer'
                onClick={() => {
                  setIsOpenTechnician(!isOpenTechnician);
                }}>
                <div>Technician Name</div>
                <i
                  className={`pi pi-angle-up ${
                    isOpenTechnician ? 'rotate-0' : 'rotate-180'
                  } ease-in-out duration-200 text-[1.2rem] pt-[0.3rem] font-bold`}
                />
              </div>
              {isOpenTechnician && (
                <div className='pt-2 mx-4 max-h-[8rem] overflow-y-auto  scrollbar-medium'>
                  {allTechnicianData?.length > 0 ? (
                    allTechnicianData?.map((item: any) => (
                      <label
                        key={item?.user_name}
                        className='flex items-center mb-2 text-[1.1rem] wrap cursor-pointer'>
                        <input
                          type='checkbox'
                          name={item?.id}
                          checked={(checkTechnicianData as any)[item?.id] || false}
                          onChange={handleTechnicianChange}
                          className='mr-2'
                        />
                        <Tootltip
                          title={`${capitalizeFirstLetter(item?.user_name)}`}
                          placement={'bottom'}>
                          <p
                            className={`${'max-w-[12rem]'} text-ellipsis whitespace-nowrap overflow-hidden `}>
                            {capitalizeFirstLetter(item?.user_name)}
                          </p>
                        </Tootltip>
                      </label>
                    ))
                  ) : (
                    <p className={`text-center`}>
                      <img
                        src='../../svg/info.svg'
                        alt='infoIcon'
                        className='inline-block h-[1.5rem] w-[1.7rem] pr-[0.5rem] pb-[0.1rem]'
                      />
                      No Data Found
                    </p>
                  )}
                </div>
              )}
            </div>

            {/* services  */}
            <div>
              <div
                className='flex justify-between px-[1rem] pt-[0.3rem]  text-[1.2rem] font-bold pb-[0.3rem] border-b-[0.15rem] cursor-pointer'
                onClick={() => {
                  setIsOpenServices(!isOpenServices);
                }}>
                <div>Services</div>
                <i
                  className={`pi pi-angle-up ${
                    isOpenServices ? 'rotate-0' : 'rotate-180'
                  } ease-in-out duration-200 text-[1.2rem] pt-[0.3rem] font-bold`}
                />
              </div>
              {isOpenServices && (
                <div className=' pt-2 mx-4 max-h-[8rem] overflow-y-auto scrollbar-medium'>
                  {allServicesData?.length > 0 ? (
                    allServicesData?.map((item: any) => (
                      <label
                        key={item?.id}
                        className='flex items-center mb-2 text-[1.1rem] wrap cursor-pointer'>
                        <input
                          type='checkbox'
                          name={item?.id}
                          checked={(checkServicesData as any)[item?.id] || false}
                          onChange={handleServicesChange}
                          className='mr-2'
                        />
                        <Tootltip
                          title={`${capitalizeFirstLetter(item?.name)}`}
                          placement={'bottom'}>
                          <p
                            className={`${'max-w-[12rem]'} text-ellipsis whitespace-nowrap overflow-hidden `}>
                            {capitalizeFirstLetter(item?.name)}
                          </p>
                        </Tootltip>
                      </label>
                    ))
                  ) : (
                    <p className={`text-center`}>
                      <img
                        src='../../svg/info.svg'
                        alt='infoIcon'
                        className='inline-block h-[1.5rem] w-[1.7rem] pr-[0.5rem] pb-[0.1rem]'
                      />
                      No Data Found
                    </p>
                  )}
                </div>
              )}
            </div>
            {/* Station code */}
            <div>
              <div
                className='flex justify-between px-[1rem] pt-[0.3rem]  text-[1.2rem] font-bold pb-[0.3rem] border-b-[0.15rem] cursor-pointer'
                onClick={() => {
                  setIsOpenStationCode(!isOpenStationCode);
                }}>
                <div>Station Codes</div>
                <i
                  className={`pi pi-angle-up ${
                    isOpenStationCode ? 'rotate-0' : 'rotate-180'
                  } ease-in-out duration-200 text-[1.2rem] pt-[0.3rem] font-bold`}
                />
              </div>
              {isOpenStationCode && (
                <div className=' pt-2 mx-4 max-h-[8rem] overflow-y-auto scrollbar-medium'>
                  {allStationCodeData?.length > 0 ? (
                    allStationCodeData?.map((item: any) => (
                      <label
                        key={item?.user_dsp_code}
                        className='flex items-center mb-2 text-[1.1rem] wrap cursor-pointer'>
                        <input
                          type='checkbox'
                          name={item?.user_company_id}
                          checked={(checkStationCode as any)[item?.user_company_id] || false}
                          onChange={handleStationCodeChange}
                          className='mr-2'
                        />
                        <Tootltip
                          title={`${capitalizeFirstLetter(item?.user_dsp_code)}`}
                          placement={'bottom'}>
                          <p
                            className={`${'max-w-[12rem]'} text-ellipsis whitespace-nowrap overflow-hidden `}>
                            {capitalizeFirstLetter(item?.user_dsp_code)}
                          </p>
                        </Tootltip>
                      </label>
                    ))
                  ) : (
                    <p className={`text-center`}>
                      <img
                        src='../../svg/info.svg'
                        alt='infoIcon'
                        className='inline-block h-[1.5rem] w-[1.7rem] pr-[0.5rem] pb-[0.1rem]'
                      />
                      No Data Found
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className='w-full px-4 py-2 flex gap-2 justify-between mt-3'>
            <Button
              disabled={count === 0}
              onClick={handleCancelFilter}
              className={`px-4 py-2 w-[40%]  rounded-lg font-bold whitespace-nowrap hover:bg-primary-light ${
                count === 0 ? 'text-gray-400' : 'text-primary'
              }`}>
              Clear All
            </Button>
            <Button
              onClick={handleApplyFilter}
              className='px-4 py-2 w-[40%] bg-primary text-white rounded-lg font-bold whitespace-nowrap'>
              Apply Filter
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
