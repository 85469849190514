import { GetMilesTillInspection } from './GetMilesTillInspection';
import ExpandPopUpMiles from './requestDetail/ExpandPopUpMiles';

export default function MilageListExpand({ item, variant }: { item: any; variant?: string }) {
  if (!item) return null;

  return (
    <div className='w-full'>
      <ExpandPopUpMiles
        showOnExpand={
          <div className='  mb-0.3 p-2 h-[8rem] rounded-b-[0.5rem] bg-[#E8EEF8] mt-[] pl-[]'>
            <div
              className='overflow-hidden hover:overflow-auto'
              style={{
                display: 'grid',
                justifyContent: 'center',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: '.3rem',
                borderRadius: '1.563rem',
                border: '0.063rem solid #E4E4E4',
                paddingLeft: '0.625rem',
                height: '7.3rem',
                maxHeight: '8rem',
                backgroundColor: 'white',
                marginTop: '-0.5rem',
              }}>
              <div
                className='item'
                style={{
                  color: '#A0A0A0',
                  fontSize: '0.92rem',
                  fontWeight: 'normal',
                  borderRightColor: 'black',
                  borderRight: '0.063rem solid #E4E4E4',
                  paddingTop: '0.625rem',
                  paddingLeft: '0.5rem',
                }}>
                Last Preventive Maintainance
                <br />
                <span
                  className='item'
                  style={{ color: '#B6B6B6', fontWeight: 'bold', display: 'block' }}>
                  <p style={{ color: '#A0A0A0', fontWeight: 'bold', fontSize: '1.1rem' }}>
                    {item.miles || '---'}
                  </p>
                  <p
                    style={{
                      color: '#A0A0A0',
                      fontWeight: 'normal',
                      paddingBottom: '0.15rem',
                      fontSize: '0.92rem',
                    }}>
                    miles
                  </p>
                </span>
              </div>
              <div
                className='item'
                style={{
                  color: '#A0A0A0',
                  fontSize: '0.92rem',
                  fontWeight: 'normal',
                  borderRight: '0.063rem solid #E4E4E4',
                  marginRight: '0.625rem',
                  paddingTop: '0.625rem',
                  paddingLeft: '0.8rem',
                }}>
                Miles till Inspection
                <br />
                <span
                  className=''
                  style={{
                    color: '#777676',
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                    marginTop: '0.1rem',
                  }}>
                  {GetMilesTillInspection(item)}
                  <p
                    style={{
                      color: '#A0A0A0',
                      fontWeight: 'normal',
                      paddingBottom: '0.15rem',
                      fontSize: '0.92rem',
                    }}>
                    miles
                  </p>
                </span>
              </div>
              <div
                className='item'
                style={{
                  color: '#A0A0A0',
                  fontSize: '0.92rem',
                  fontWeight: 'normal',
                  paddingBottom: '0.313rem',
                  paddingTop: '0.625rem',
                }}>
                Current <br /> Mileage
                <br />
                <span
                  className='item'
                  style={{
                    color: '#777676',
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                    marginTop: '0.1rem',
                  }}>
                  {item.curr_miles || '---'}{' '}
                  <p
                    style={{
                      color: '#A0A0A0',
                      fontWeight: 'normal',
                      paddingBottom: '0.15rem',
                      fontSize: '0.92rem',
                    }}>
                    miles
                  </p>
                </span>
              </div>
            </div>
          </div>
        }
        title='Show More Info'
        item={item}
        variant={variant}
      />
    </div>
  );
}
