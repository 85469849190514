import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';
import Calender from '../../../../../assets/calender';
import { Dayjs } from 'dayjs';
import moment from 'moment';

function ButtonField(props: any) {
  const { setOpen, InputProps: { ref } = { ref: null } }: any = props;
  return (
    <div
      className={
        'w-fit cursor-pointer flex justify-between items-center text-primary bg-white  rounded-[0.5rem]  '
      }
      ref={ref}>
      <div className='flex' onClick={() => setOpen?.((prev: any) => !prev)}>
        <Calender />
      </div>
    </div>
  );
}
function ButtonDatePicker(
  props: Omit<DatePickerProps<Dayjs>, 'open' | 'onOpen' | 'onClose'> & {
    onSetNull: () => void;
    index: number;
  }
) {
  const { onSetNull, index, ...otherProps } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <DatePicker
        disableFuture
        slots={{ field: ButtonField, ...props.slots }}
        slotProps={{
          field: { setOpen, onSetNull, open, index } as any,
          popper: {
            placement: 'right',
          },
        }}
        {...otherProps}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      />
    </>
  );
}
export function PickerWithButtonField({ index, value, onChange, onSetNull }: any) {
  const visibleDate = moment(value).format('MM/DD/YYYY');
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ButtonDatePicker
        index={index}
        label={`${!value ? 'MM/DD/YYYY' : String(visibleDate)}`}
        value={value}
        onChange={(newValue) => {
          onChange({ index, date: newValue });
        }}
        onSetNull={onSetNull}
      />
    </LocalizationProvider>
  );
}
