import ModalForm from '../ModalForm';
import OverlayContainer from './index';

const Model = (props: {
  title?: string;
  noLabel?: boolean;
  nothing?: boolean;
  handleClose: () => void;
  heightClass: string;
  widthclass: string;
  titleComp?: any;
  children: any;
}) => {
  const modelStyle = {
    title: props?.title || '',
    titleComp: props?.titleComp || '',
    noLabel: props?.noLabel || false,
    nothing: props?.nothing || false,
  };
  return (
    <OverlayContainer show={true}>
      <ModalForm onClose={props?.handleClose} width={props.widthclass} {...modelStyle}>
        <div className={` ${props.heightClass} flex flex-col`}>{props.children}</div>
      </ModalForm>
    </OverlayContainer>
  );
};

export default Model;
