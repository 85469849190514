const SVG = (props: { height?: string; width?: string; active?: boolean }) => {
  const height = props?.height || '1.2rem';
  const width = props?.width || '1.5rem';
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.5 7.44647H0.833333C0.612319 7.44647 0.400358 7.3593 0.244078 7.20412C0.0877975 7.04894 0 6.83847 0 6.61902V2.48175C0 2.2623 0.0877975 2.05183 0.244078 1.89665C0.400358 1.74147 0.612319 1.6543 0.833333 1.6543H17.5C17.721 1.6543 17.933 1.74147 18.0892 1.89665C18.2455 2.05183 18.3333 2.2623 18.3333 2.48175V6.61902C18.3333 6.83847 18.2455 7.04894 18.0892 7.20412C17.933 7.3593 17.721 7.44647 17.5 7.44647ZM1.66667 5.79157H16.6667V3.3092H1.66667V5.79157Z'
        fill={props?.active?"#fff":'#4378C8'}
      />
      <path
        d='M14.1668 4.96472C13.9458 4.96472 13.7338 4.87755 13.5776 4.72237C13.4213 4.56719 13.3335 4.35672 13.3335 4.13727V0.827454C13.3335 0.608 13.4213 0.397533 13.5776 0.242356C13.7338 0.0871779 13.9458 0 14.1668 0C14.3878 0 14.5998 0.0871779 14.7561 0.242356C14.9124 0.397533 15.0002 0.608 15.0002 0.827454V4.13727C15.0002 4.35672 14.9124 4.56719 14.7561 4.72237C14.5998 4.87755 14.3878 4.96472 14.1668 4.96472ZM4.16683 4.96472C3.94582 4.96472 3.73385 4.87755 3.57757 4.72237C3.42129 4.56719 3.3335 4.35672 3.3335 4.13727V0.827454C3.3335 0.608 3.42129 0.397533 3.57757 0.242356C3.73385 0.0871779 3.94582 0 4.16683 0C4.38784 0 4.5998 0.0871779 4.75608 0.242356C4.91236 0.397533 5.00016 0.608 5.00016 0.827454V4.13727C5.00016 4.35672 4.91236 4.56719 4.75608 4.72237C4.5998 4.87755 4.38784 4.96472 4.16683 4.96472Z'
        fill={props?.active?"#fff":'#4378C8'}
      />
      <path
        d='M9.99999 19.8583H2.5C1.83696 19.8583 1.20107 19.5967 0.732233 19.1312C0.263392 18.6657 0 18.0343 0 17.3759V2.48175C0 2.2623 0.0877975 2.05183 0.244078 1.89665C0.400358 1.74147 0.612319 1.6543 0.833333 1.6543H17.5C17.721 1.6543 17.933 1.74147 18.0892 1.89665C18.2455 2.05183 18.3333 2.2623 18.3333 2.48175V11.5837C18.3333 11.8032 18.2455 12.0137 18.0892 12.1688C17.933 12.324 17.721 12.4112 17.5 12.4112C17.279 12.4112 17.067 12.324 16.9107 12.1688C16.7545 12.0137 16.6667 11.8032 16.6667 11.5837V3.3092H1.66667V17.3759C1.66667 17.5954 1.75446 17.8058 1.91074 17.961C2.06702 18.1162 2.27898 18.2034 2.5 18.2034H9.99999C10.221 18.2034 10.433 18.2906 10.5892 18.4457C10.7455 18.6009 10.8333 18.8114 10.8333 19.0308C10.8333 19.2503 10.7455 19.4607 10.5892 19.6159C10.433 19.7711 10.221 19.8583 9.99999 19.8583Z'
        fill={props?.active?"#fff":'#4378C8'}
      />
      <path
        d='M14.1666 21.5131C13.0129 21.5131 11.885 21.1734 10.9258 20.5369C9.96647 19.9005 9.2188 18.9958 8.77729 17.9375C8.33578 16.8791 8.22026 15.7145 8.44534 14.5909C8.67042 13.4673 9.22599 12.4353 10.0418 11.6252C10.8576 10.8152 11.897 10.2635 13.0286 10.04C14.1601 9.81652 15.333 9.93122 16.3989 10.3696C17.4648 10.808 18.3758 11.5504 19.0168 12.5029C19.6578 13.4554 19.9999 14.5753 19.9999 15.7209C19.9999 17.2571 19.3853 18.7303 18.2914 19.8166C17.1974 20.9028 15.7137 21.5131 14.1666 21.5131ZM14.1666 11.5836C13.3425 11.5836 12.5369 11.8263 11.8517 12.2809C11.1665 12.7355 10.6325 13.3816 10.3171 14.1376C10.0017 14.8936 9.91921 15.7255 10.08 16.528C10.2408 17.3306 10.6376 18.0678 11.2203 18.6464C11.803 19.225 12.5455 19.619 13.3537 19.7787C14.162 19.9383 14.9997 19.8564 15.7611 19.5432C16.5225 19.2301 17.1732 18.6998 17.631 18.0194C18.0889 17.3391 18.3332 16.5392 18.3332 15.7209C18.3332 14.6236 17.8943 13.5713 17.1129 12.7954C16.3315 12.0195 15.2717 11.5836 14.1666 11.5836Z'
        fill={props?.active?"#fff":'#4378C8'}
      />
      <path
        d='M16.6666 16.5489H14.1666C13.9456 16.5489 13.7336 16.4617 13.5773 16.3065C13.421 16.1514 13.3333 15.9409 13.3333 15.7214V12.4116H14.9999V14.894H16.6666V16.5489Z'
        fill={props?.active?"#fff":'#4378C8'}
      />
    </svg>
  );
};
export default SVG;
